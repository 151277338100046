import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Paper,
  Card,
  CardContent,
  IconButton,
  Divider,
  Chip,
  Container,
  Dialog,
  DialogContent,
  Tooltip,
  InputAdornment,
  Tabs,
  Tab,
  Menu,
  ListItemText,
  Fade,
  AppBar,
  Toolbar,
  Snackbar,
  Alert
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FolderIcon from '@mui/icons-material/Folder';
import DownloadIcon from '@mui/icons-material/Download';
import SortIcon from '@mui/icons-material/Sort';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import RefreshIcon from '@mui/icons-material/Refresh';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MoneyIcon from '@mui/icons-material/Money';
import PaymentsIcon from '@mui/icons-material/Payments';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import { formatImageUrl } from '../utils/imageUtils';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

// Componentes con estilos
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 12,
  overflow: 'hidden',
  transition: 'transform 0.3s, box-shadow 0.3s',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 16px 30px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledCardMedia = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingTop: '75%', // Aspect ratio 4:3
  overflow: 'hidden',
  cursor: 'pointer',
  backgroundColor: '#f5f5f5',
}));

const CodeChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  bottom: 8,
  right: 8,
  backgroundColor: 'rgba(46, 125, 50, 0.9)',
  color: 'white',
  fontFamily: 'monospace',
  fontWeight: 'bold',
  fontSize: '0.75rem',
  boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
}));

const OriginChip = styled(Chip)(({ theme, origin }) => {
  const colors = {
    salida: { bg: 'rgba(211, 47, 47, 0.7)', color: 'white' },
    cambio: { bg: 'rgba(46, 125, 50, 0.7)', color: 'white' },
    cobro: { bg: 'rgba(245, 124, 0, 0.7)', color: 'white' },
    default: { bg: 'rgba(97, 97, 97, 0.7)', color: 'white' }
  };

  const style = colors[origin] || colors.default;

  return {
    position: 'absolute',
    top: 8,
    left: 8,
    backgroundColor: style.bg,
    color: style.color,
    fontSize: '0.7rem',
    fontWeight: 'bold',
    boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
  };
});

const ImagePreviewDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  },
  '& .MuiPaper-root': {
    borderRadius: 12,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
}));

const TitleBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'white',
  color: theme.palette.text.primary,
  boxShadow: 'none',
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
}));

const DownloadButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 10,
  right: 10,
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  color: '#2e7d32',
  '&:hover': {
    backgroundColor: 'white',
    transform: 'scale(1.1)',
  },
  boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
  zIndex: 5,
  padding: 8,
}));

const FilterChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: '#e8f5e9',
  color: '#2e7d32',
  '&:hover': {
    backgroundColor: '#c8e6c9',
  },
  '& .MuiChip-deleteIcon': {
    color: '#2e7d32',
    '&:hover': {
      color: '#1b5e20',
    },
  },
}));

// Define los tipos de origen de facturas
const FACTURA_ORIGINS = {
  SALIDA: 'salida',
  CAMBIO: 'cambio',
  COBRO: 'cobro'
};

// Mapeo de iconos para orígenes
const originIcons = {
  [FACTURA_ORIGINS.SALIDA]: <PaymentsIcon />,
  [FACTURA_ORIGINS.CAMBIO]: <MoneyIcon />,
  [FACTURA_ORIGINS.COBRO]: <AccountBalanceIcon />,
};

// Componente para previsualización de imágenes
const ImagePreview = ({
  src,
  alt = 'Imagen',
  onClick,
  width = '100%',
  height = '100%',
  showZoom = true,
  thumbSize = 'medium' // 'small', 'medium', 'large'
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  // Mapeo de tamaños
  const sizes = {
    small: { width: 40, height: 40, iconSize: 20 },
    medium: { width: 80, height: 80, iconSize: 36 },
    large: { width: 120, height: 120, iconSize: 48 }
  };

  const size = sizes[thumbSize] || sizes.medium;

  useEffect(() => {
    if (!src) {
      setError(true);
      return;
    }

    setLoaded(false);
    setError(false);

    const img = new Image();

    img.onload = () => {
      setLoaded(true);
      setError(false);
    };

    img.onerror = () => {
      // Intentar URL directa sin parámetros de caché
      if (src.includes('?')) {
        const directUrl = src.split('?')[0];

        const directImg = new Image();
        directImg.onload = () => {
          setLoaded(true);
          setError(false);
        };
        directImg.onerror = () => {
          setError(true);
          setLoaded(false);
        };
        directImg.src = directUrl;
      } else {
        setError(true);
        setLoaded(false);
      }
    };

    img.src = src;

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src, retryCount]);

  const handleRetry = (e) => {
    e.stopPropagation();
    setRetryCount(prev => prev + 1);
  };

  if (error) {
    return (
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      >
        <Box
          sx={{
            width: width !== '100%' ? width : size.width,
            height: height !== '100%' ? height : size.height,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f5f5f5',
            borderRadius: 1,
            border: '1px solid #e0e0e0',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <ReceiptIcon sx={{ color: '#9e9e9e', fontSize: size.iconSize }} />
          <Typography variant="caption" sx={{ fontSize: '10px', textAlign: 'center', mt: 0.5 }}>
            Error
          </Typography>

          <Tooltip title="Reintentar cargar imagen">
            <IconButton
              size="small"
              onClick={handleRetry}
              sx={{
                position: 'absolute',
                top: 2,
                right: 2,
                background: 'rgba(255,255,255,0.8)',
                width: 16,
                height: 16
              }}
            >
              <RefreshIcon sx={{ fontSize: 12 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </motion.div>
    );
  }

  if (!loaded) {
    return (
      <Box
        sx={{
          width: width !== '100%' ? width : size.width,
          height: height !== '100%' ? height : size.height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f5f5f5',
          borderRadius: 1,
          border: '1px solid #e0e0e0'
        }}
      >
        <CircularProgress size={size.iconSize / 2} sx={{ color: '#2e7d32' }} />
      </Box>
    );
  }

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      style={{ cursor: 'pointer', position: 'relative' }}
    >
      <Box
        sx={{
          width: width !== '100%' ? width : size.width,
          height: height !== '100%' ? height : size.height,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 1,
          border: '1px solid #e0e0e0'
        }}
        onClick={onClick}
      >
        <Box
          component="img"
          src={src}
          alt={alt}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 0.3s ease',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        />

        {showZoom && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 4,
              right: 4,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 24,
              height: 24,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              }
            }}
          >
            <ZoomInIcon sx={{ fontSize: 16, color: '#2e7d32' }} />
          </Box>
        )}
      </Box>
    </motion.div>
  );
};

const BuscadorFacturas = () => {
  const [cajasOrigen, setCajasOrigen] = useState([]);
  const [nombreFacturas, setNombreFacturas] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [facturas, setFacturas] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' o 'list'
  const [tabValue, setTabValue] = useState(0); // 0: Todos, 1: Recientes, 2: Por obra, 3: Por caja, 4: Por nombre
  const [selectedFolder, setSelectedFolder] = useState(null);

  // Estados para filtros activos
  const [activeFilters, setActiveFilters] = useState([]);

  // Estados para menús y sort
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState('date_desc');

  const [filters, setFilters] = useState({
    codigo: '',
    proyecto: '',
    caja_origen: '',
    proveedor: '',
    nombre_factura: '',
    fecha_desde: null,
    fecha_hasta: null,
    codigo_factura: '',
    origen: '', // Nuevo filtro para el origen
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Referencia para la animación del scroll
  const listRef = useRef(null);

  useEffect(() => {
    fetchCajasOrigen();
    fetchNombreFacturas();
    fetchProveedores();
    fetchProyectos();
    // Ejecutar búsqueda inicial para mostrar las últimas facturas
    handleSearchFacturas();
  }, []);

  // Efecto para cambiar búsqueda según tab seleccionado
  useEffect(() => {
    if (tabValue === 0) {
      // Todos las facturas
      handleSearchFacturas();
    } else if (tabValue === 1) {
      // Recientes (últimos 30 días)
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      setFilters({
        ...filters,
        fecha_desde: thirtyDaysAgo,
        fecha_hasta: new Date(),
      });

      handleSearchFacturas();
    }
  }, [tabValue]);

  const fetchCajasOrigen = async () => {
    const { data, error } = await supabase.from('cajas_origen').select('caja_origen');
    if (error) console.error('Error:', error);
    else setCajasOrigen(data.map((caja) => caja.caja_origen) || []);
  };

  const fetchNombreFacturas = async () => {
    const { data, error } = await supabase.from('facturas_a_nombre_de_salidas').select('nombre_factura');
    if (error) console.error('Error:', error);
    else setNombreFacturas(data.map((item) => item.nombre_factura) || []);
  };

  const fetchProveedores = async () => {
    const { data, error } = await supabase.from('proveedores_de_servicios').select('proveedor_de_servicios');
    if (error) console.error('Error:', error);
    else setProveedores(data.map((prov) => prov.proveedor_de_servicios) || []);
  };

  const fetchProyectos = async () => {
    const { data, error } = await supabase.from('proyectos').select('codigo_de_proyecto');
    if (error) {
      console.error('Error fetching proyectos:', error);
      // Usar los valores disponibles en salidas si no hay tabla de proyectos
      const { data: salidaData } = await supabase
        .from('salidas')
        .select('codigo_de_proyecto_salida')
        .not('codigo_de_proyecto_salida', 'is', null);

      if (salidaData) {
        // Extraer valores únicos
        const uniqueProyectos = [...new Set(salidaData.map(item => item.codigo_de_proyecto_salida))];
        setProyectos(uniqueProyectos.filter(p => p)); // Filtrar valores nulos o undefined
      }
    } else {
      setProyectos(data.map((item) => item.codigo_de_proyecto) || []);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setSelectedFolder(null); // Resetear carpeta seleccionada al cambiar tabs
  };

  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleSortSelect = (sortOption) => {
    setSortBy(sortOption);
    setSortAnchorEl(null);

    // Reordenar los resultados existentes
    let sortedFacturas = [...facturas];

    switch (sortOption) {
      case 'date_desc':
        sortedFacturas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
        break;
      case 'date_asc':
        sortedFacturas.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
        break;
      case 'amount_desc':
        sortedFacturas.sort((a, b) => parseFloat(b.monto) - parseFloat(a.monto));
        break;
      case 'amount_asc':
        sortedFacturas.sort((a, b) => parseFloat(a.monto) - parseFloat(b.monto));
        break;
      default:
        break;
    }

    setFacturas(sortedFacturas);
  };

  // Función para normalizar datos de diferentes orígenes
  const normalizeFacturaData = (data, origen) => {
    let normalizedData = [];

    switch (origen) {
      case FACTURA_ORIGINS.SALIDA:
        normalizedData = data.map(item => ({
          id: item.id,
          origen: FACTURA_ORIGINS.SALIDA,
          codigo: item.codigo_de_factura_salida,
          fecha: item.fecha_salida,
          fecha_formateada: item.fecha_salida ? format(new Date(item.fecha_salida), 'dd/MM/yyyy') : '',
          monto: item.monto_salida,
          moneda: item.moneda_salida,
          monto_formateado: formatCurrency(item.monto_salida, item.moneda_salida),
          imagen: item.imagen_factura_salida ? formatImageUrl(item.imagen_factura_salida) : null,
          caja: item.caja_origen_salida,
          concepto: item.concepto_salidas,
          proyecto: item.codigo_de_proyecto_salida,
          proveedor: item.proveedor_salida,
          nombre_factura: item.factura_a_nombre_de_salida,
          // Campos originales para mantener compatibilidad
          ...item
        }));
        break;

      case FACTURA_ORIGINS.CAMBIO:
        normalizedData = data.map(item => ({
          id: item.id,
          origen: FACTURA_ORIGINS.CAMBIO,
          codigo: item.codigo_de_factura_cambio || item.codigo_de_comprobante_cambio,
          fecha: item.fecha_cambio,
          fecha_formateada: item.fecha_cambio ? format(new Date(item.fecha_cambio), 'dd/MM/yyyy') : '',
          monto: item.monto_inicial_cambio,
          moneda: item.moneda_inicial_cambio,
          monto_formateado: formatCurrency(item.monto_inicial_cambio, item.moneda_inicial_cambio),
          imagen: item.imagen_factura_cambio ? formatImageUrl(item.imagen_factura_cambio) : null,
          caja: item.caja_cambio,
          concepto: `Cambio ${item.moneda_inicial_cambio} → ${item.moneda_final_cambio}`,
          proyecto: null, // Los cambios de moneda pueden no tener proyecto
          proveedor: "Casa de cambio",
          nombre_factura: item.factura_a_nombre_de_cambio || "Empresa",
          // Campos originales para mantener compatibilidad
          ...item
        }));
        break;

      case FACTURA_ORIGINS.COBRO:
        normalizedData = data.map(item => ({
          id: item.id,
          origen: FACTURA_ORIGINS.COBRO,
          codigo: item.codigo_de_factura_cobro || item.codigo_de_comprobante_cobro,
          fecha: item.fecha_cobro,
          fecha_formateada: item.fecha_cobro ? format(new Date(item.fecha_cobro), 'dd/MM/yyyy') : '',
          monto: item.monto_cobro,
          moneda: item.moneda_cobro,
          monto_formateado: formatCurrency(item.monto_cobro, item.moneda_cobro),
          imagen: item.imagen_factura_cobro ? formatImageUrl(item.imagen_factura_cobro) : null,
          caja: item.caja_origen_cobro,
          concepto: item.concepto_cobro || "Cobro a socio",
          proyecto: item.codigo_de_proyecto_cobro,
          proveedor: item.nombre_socio || "Socio",
          nombre_factura: item.factura_a_nombre_de_cobro || "Empresa",
          // Campos originales para mantener compatibilidad
          ...item
        }));
        break;

      default:
        break;
    }

    return normalizedData;
  };

  const handleSearchFacturas = async () => {
    setLoading(true);
    try {
      let allFacturas = [];

      // Filtro de origen
      const origensABuscar = filters.origen
        ? [filters.origen]
        : [FACTURA_ORIGINS.SALIDA, FACTURA_ORIGINS.CAMBIO, FACTURA_ORIGINS.COBRO];

      for (const origen of origensABuscar) {
        let data = [];

        if (origen === FACTURA_ORIGINS.SALIDA) {
          // Buscar facturas en salidas
          let query = supabase
            .from('salidas')
            .select('*')
            .eq('tiene_factura_salida', true);

          // Aplicar filtros específicos para salidas
          if (filters.codigo) {
            query = query.ilike('codigo_de_factura_salida', `%${filters.codigo}%`);
          }
          if (filters.proyecto) {
            query = query.eq('codigo_de_proyecto_salida', filters.proyecto);
          }
          if (filters.caja_origen) {
            query = query.eq('caja_origen_salida', filters.caja_origen);
          }
          if (filters.proveedor) {
            query = query.eq('proveedor_salida', filters.proveedor);
          }
          if (filters.nombre_factura) {
            query = query.eq('factura_a_nombre_de_salida', filters.nombre_factura);
          }
          if (filters.fecha_desde) {
            const fechaDesdeFmt = format(new Date(filters.fecha_desde), 'yyyy-MM-dd');
            query = query.gte('fecha_salida', fechaDesdeFmt);
          }
          if (filters.fecha_hasta) {
            const fechaHasta = new Date(filters.fecha_hasta);
            fechaHasta.setDate(fechaHasta.getDate() + 1);
            const fechaHastaFmt = format(fechaHasta, 'yyyy-MM-dd');
            query = query.lt('fecha_salida', fechaHastaFmt);
          }

          // Ordenar según la selección actual
          switch (sortBy) {
            case 'date_desc':
              query = query.order('fecha_salida', { ascending: false });
              break;
            case 'date_asc':
              query = query.order('fecha_salida', { ascending: true });
              break;
            case 'amount_desc':
              query = query.order('monto_salida', { ascending: false });
              break;
            case 'amount_asc':
              query = query.order('monto_salida', { ascending: true });
              break;
            default:
              query = query.order('fecha_salida', { ascending: false });
          }

          const { data: salidasData, error } = await query.limit(100);
          if (error) {
            console.error('Error fetching facturas from salidas:', error.message);
          } else {
            data = salidasData;
          }

          // Normalizar y agregar a la lista total
          const normalizedData = normalizeFacturaData(data, FACTURA_ORIGINS.SALIDA);
          allFacturas = [...allFacturas, ...normalizedData];
        }

        if (origen === FACTURA_ORIGINS.CAMBIO) {
          // Buscar facturas en cambios de moneda (si existe la tabla y tiene facturas)
          try {
            let query = supabase
              .from('cambios_de_moneda')
              .select('*')
              .eq('tiene_factura_cambio', true);

            // Aplicar filtros específicos para cambios
            if (filters.codigo) {
              query = query.ilike('codigo_de_factura_cambio', `%${filters.codigo}%`);
            }
            if (filters.caja_origen) {
              query = query.eq('caja_cambio', filters.caja_origen);
            }
            if (filters.fecha_desde) {
              const fechaDesdeFmt = format(new Date(filters.fecha_desde), 'yyyy-MM-dd');
              query = query.gte('fecha_cambio', fechaDesdeFmt);
            }
            if (filters.fecha_hasta) {
              const fechaHasta = new Date(filters.fecha_hasta);
              fechaHasta.setDate(fechaHasta.getDate() + 1);
              const fechaHastaFmt = format(fechaHasta, 'yyyy-MM-dd');
              query = query.lt('fecha_cambio', fechaHastaFmt);
            }

            // Ordenar según la selección actual
            switch (sortBy) {
              case 'date_desc':
                query = query.order('fecha_cambio', { ascending: false });
                break;
              case 'date_asc':
                query = query.order('fecha_cambio', { ascending: true });
                break;
              case 'amount_desc':
                query = query.order('monto_inicial_cambio', { ascending: false });
                break;
              case 'amount_asc':
                query = query.order('monto_inicial_cambio', { ascending: true });
                break;
              default:
                query = query.order('fecha_cambio', { ascending: false });
            }

            const { data: cambiosData, error } = await query.limit(100);
            if (error) {
              console.error('Error fetching facturas from cambios:', error.message);
            } else {
              data = cambiosData;
            }

            // Normalizar y agregar a la lista total
            const normalizedData = normalizeFacturaData(data, FACTURA_ORIGINS.CAMBIO);
            allFacturas = [...allFacturas, ...normalizedData];
          } catch (error) {
            console.error('Error with cambios query:', error);
          }
        }

        if (origen === FACTURA_ORIGINS.COBRO) {
          // Buscar facturas en cobros de socios (si existe la tabla y tiene facturas)
          try {
            let query = supabase
              .from('cobros_socios')
              .select('*')
              .eq('tiene_factura_cobro', true);

            // Aplicar filtros específicos para cobros
            if (filters.codigo) {
              query = query.ilike('codigo_de_factura_cobro', `%${filters.codigo}%`);
            }
            if (filters.proyecto) {
              query = query.eq('codigo_de_proyecto_cobro', filters.proyecto);
            }
            if (filters.caja_origen) {
              query = query.eq('caja_origen_cobro', filters.caja_origen);
            }
            if (filters.fecha_desde) {
              const fechaDesdeFmt = format(new Date(filters.fecha_desde), 'yyyy-MM-dd');
              query = query.gte('fecha_cobro', fechaDesdeFmt);
            }
            if (filters.fecha_hasta) {
              const fechaHasta = new Date(filters.fecha_hasta);
              fechaHasta.setDate(fechaHasta.getDate() + 1);
              const fechaHastaFmt = format(fechaHasta, 'yyyy-MM-dd');
              query = query.lt('fecha_cobro', fechaHastaFmt);
            }

            // Ordenar según la selección actual
            switch (sortBy) {
              case 'date_desc':
                query = query.order('fecha_cobro', { ascending: false });
                break;
              case 'date_asc':
                query = query.order('fecha_cobro', { ascending: true });
                break;
              case 'amount_desc':
                query = query.order('monto_cobro', { ascending: false });
                break;
              case 'amount_asc':
                query = query.order('monto_cobro', { ascending: true });
                break;
              default:
                query = query.order('fecha_cobro', { ascending: false });
            }

            const { data: cobrosData, error } = await query.limit(100);
            if (error) {
              console.error('Error fetching facturas from cobros:', error.message);
            } else {
              data = cobrosData;
            }

            // Normalizar y agregar a la lista total
            const normalizedData = normalizeFacturaData(data, FACTURA_ORIGINS.COBRO);
            allFacturas = [...allFacturas, ...normalizedData];
          } catch (error) {
            console.error('Error with cobros query:', error);
          }
        }
      }

      // Ordenar todos las facturas juntas según el criterio seleccionado
      switch (sortBy) {
        case 'date_desc':
          allFacturas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
          break;
        case 'date_asc':
          allFacturas.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
          break;
        case 'amount_desc':
          allFacturas.sort((a, b) => parseFloat(b.monto) - parseFloat(a.monto));
          break;
        case 'amount_asc':
          allFacturas.sort((a, b) => parseFloat(a.monto) - parseFloat(b.monto));
          break;
        default:
          allFacturas.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
      }

      setFacturas(allFacturas);

    } catch (error) {
      console.error('Error searching facturas:', error);
      setSnackbar({
        open: true,
        message: 'Error al procesar la búsqueda',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const formatCurrency = (amount, currency) => {
    if (!amount) return '';
    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currency === 'USD' ? 'USD' : 'ARS',
      minimumFractionDigits: 2
    });
    return formatter.format(amount);
  };

  const handleDownload = async (e, factura) => {
    // Detener evento completamente
    e.preventDefault();
    e.stopPropagation();

    try {
      // URL de la imagen
      const imageUrl = factura.imagen.split('?')[0];

      // Nombre del archivo - evitamos la duplicación de prefijos
      let fileName;

      // Si el código ya contiene el prefijo del proyecto, lo usamos directamente
      if (factura.codigo && factura.proyecto && factura.codigo.startsWith(factura.proyecto)) {
        fileName = `${factura.codigo}.jpg`;
      } else {
        // Si no, lo añadimos
        const projectCode = factura.proyecto || "GEN";
        fileName = `${projectCode}_${factura.codigo || format(new Date(), 'yyyyMMdd')}.jpg`;
      }

      // Añadir origen al nombre para claridad
      fileName = `${factura.origen}_${fileName}`;

      // Usar fetch para obtener los datos como blob
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Usar saveAs de file-saver
      saveAs(blob, fileName);

      // Notificar éxito
      setSnackbar({
        open: true,
        message: 'Imagen descargada exitosamente',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error descargando imagen:', error);
      setSnackbar({
        open: true,
        message: 'Error al descargar la imagen',
        severity: 'error'
      });
    }

    return false;
  };

  const handleShowImage = (factura) => {
    // Asegurarnos de que el código tenga el prefijo del proyecto correcto
    const projectCode = factura.proyecto || "GEN";
    let codigoMostrar = factura.codigo;

    if (!codigoMostrar) {
      codigoMostrar = `${projectCode}-${format(new Date(), 'yyyyMMdd')}-${Math.floor(Math.random() * 100000)}`;
    }

    const origenLabels = {
      [FACTURA_ORIGINS.SALIDA]: 'Salida',
      [FACTURA_ORIGINS.CAMBIO]: 'Cambio de Moneda',
      [FACTURA_ORIGINS.COBRO]: 'Cobro Socios',
    };

    setCurrentImage({
      url: factura.imagen,
      codigo: codigoMostrar,
      fecha: factura.fecha_formateada,
      concepto: factura.concepto,
      monto: factura.monto_formateado,
      caja: factura.caja,
      proveedor: factura.proveedor,
      proyecto: factura.proyecto,
      nombre_factura: factura.nombre_factura,
      origen: origenLabels[factura.origen] || 'Desconocido'
    });
    setOpenPreview(true);
  };

  const resetFilters = () => {
    setFilters({
      codigo: '',
      proyecto: '',
      caja_origen: '',
      proveedor: '',
      nombre_factura: '',
      fecha_desde: null,
      fecha_hasta: null,
      codigo_factura: '',
      origen: '',
    });
  };

  const addFilter = () => {
    const newFilters = [];

    if (filters.origen) {
      newFilters.push({ type: 'origen', value: filters.origen, label: `Origen: ${filters.origen}` });
    }

    if (filters.caja_origen) {
      newFilters.push({ type: 'caja_origen', value: filters.caja_origen, label: `Caja: ${filters.caja_origen}` });
    }

    if (filters.proveedor) {
      newFilters.push({ type: 'proveedor', value: filters.proveedor, label: `Proveedor: ${filters.proveedor}` });
    }

    if (filters.proyecto) {
      newFilters.push({ type: 'proyecto', value: filters.proyecto, label: `Proyecto: ${filters.proyecto}` });
    }

    if (filters.nombre_factura) {
      newFilters.push({ type: 'nombre_factura', value: filters.nombre_factura, label: `A nombre de: ${filters.nombre_factura}` });
    }

    if (filters.codigo_factura) {
      newFilters.push({ type: 'codigo_factura', value: filters.codigo_factura, label: `Código: ${filters.codigo_factura}` });
    }

    if (filters.fecha_desde) {
      newFilters.push({ type: 'fecha_desde', value: filters.fecha_desde, label: `Desde: ${format(new Date(filters.fecha_desde), 'dd/MM/yyyy')}` });
    }

    if (filters.fecha_hasta) {
      newFilters.push({ type: 'fecha_hasta', value: filters.fecha_hasta, label: `Hasta: ${format(new Date(filters.fecha_hasta), 'dd/MM/yyyy')}` });
    }

    setActiveFilters([...activeFilters, ...newFilters]);

    // Resetear los filtros
    setFilters({
      codigo: '',
      proyecto: '',
      caja_origen: '',
      proveedor: '',
      nombre_factura: '',
      fecha_desde: null,
      fecha_hasta: null,
      codigo_factura: '',
      origen: '',
    });

    setShowFilters(false);
  };

  const removeFilter = (index) => {
    const updatedFilters = [...activeFilters];
    updatedFilters.splice(index, 1);
    setActiveFilters(updatedFilters);
  };

  const removeAllFilters = () => {
    setActiveFilters([]);
  };

  const applyFilters = () => {
    if (activeFilters.length === 0) return;

    // Aplicar filtros activos a las facturas
    let filtered = [...facturas];

    activeFilters.forEach(filter => {
      if (filter.type === 'fecha_desde' && filter.value) {
        filtered = filtered.filter(factura =>
          new Date(factura.fecha) >= new Date(filter.value)
        );
      } else if (filter.type === 'fecha_hasta' && filter.value) {
        filtered = filtered.filter(factura =>
          new Date(factura.fecha) <= new Date(filter.value)
        );
      } else if (filter.type === 'codigo_factura' && filter.value) {
        filtered = filtered.filter(factura =>
          factura.codigo &&
          factura.codigo.toLowerCase().includes(filter.value.toLowerCase())
        );
      } else if (filter.type === 'origen' && filter.value) {
        filtered = filtered.filter(factura => factura.origen === filter.value);
      } else if (filter.value) {
        const fieldMap = {
          caja_origen: 'caja',
          proveedor: 'proveedor',
          proyecto: 'proyecto',
          nombre_factura: 'nombre_factura',
        };
        const field = fieldMap[filter.type];
        if (field) {
          filtered = filtered.filter(factura => factura[field] === filter.value);
        }
      }
    });

    setFacturas(filtered);
  };

  // Efecto para aplicar filtros activos
  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFilters]);

  const getFoldersByType = (type) => {
    // Obtiene carpetas únicas basadas en el tipo (proyecto, caja o nombre)
    const uniqueFolders = [];

    if (type === 'proyecto') {
      facturas.forEach(comp => {
        if (comp.proyecto && !uniqueFolders.includes(comp.proyecto)) {
          uniqueFolders.push(comp.proyecto);
        }
      });
    } else if (type === 'caja') {
      facturas.forEach(comp => {
        if (comp.caja && !uniqueFolders.includes(comp.caja)) {
          uniqueFolders.push(comp.caja);
        }
      });
    } else if (type === 'nombre') {
      facturas.forEach(comp => {
        if (comp.nombre_factura && !uniqueFolders.includes(comp.nombre_factura)) {
          uniqueFolders.push(comp.nombre_factura);
        }
      });
    }

    return uniqueFolders.sort();
  };

  const handleFolderSelect = (folder, type) => {
    setSelectedFolder(folder);

    if (type === 'proyecto') {
      setFilters({
        ...filters,
        proyecto: folder,
        caja_origen: '',
        nombre_factura: ''
      });
    } else if (type === 'caja') {
      setFilters({
        ...filters,
        caja_origen: folder,
        proyecto: '',
        nombre_factura: ''
      });
    } else if (type === 'nombre') {
      setFilters({
        ...filters,
        nombre_factura: folder,
        proyecto: '',
        caja_origen: ''
      });
    }

    handleSearchFacturas();
  };

  // Función para descargar todas las imágenes filtradas
  const downloadAllImages = async () => {
    const filteredFacturas = [...facturas];

    // Aplicar filtros si hay una carpeta seleccionada
    let facturasToDownload = filteredFacturas;
    if (selectedFolder && tabValue === 2) {
      facturasToDownload = filteredFacturas.filter(
        comp => comp.proyecto === selectedFolder
      );
    } else if (selectedFolder && tabValue === 3) {
      facturasToDownload = filteredFacturas.filter(
        comp => comp.caja === selectedFolder
      );
    } else if (selectedFolder && tabValue === 4) {
      facturasToDownload = filteredFacturas.filter(
        comp => comp.nombre_factura === selectedFolder
      );
    }

    if (facturasToDownload.length === 0) {
      setSnackbar({
        open: true,
        message: 'No hay facturas para descargar',
        severity: 'info'
      });
      return;
    }

    try {
      setLoading(true);

      // Crear un nuevo ZIP
      const zip = new JSZip();
      const imagesFolder = zip.folder("facturas");

      // Contador para tracking de progreso
      let completedCount = 0;

      // Función para actualizar la UI cuando se completa todo
      const checkCompletion = () => {
        completedCount++;
        if (completedCount === facturasToDownload.length) {
          zip.generateAsync({ type: "blob" })
            .then(content => {
              saveAs(content, `facturas_${format(new Date(), 'yyyyMMdd')}.zip`);
              setLoading(false);
              setSnackbar({
                open: true,
                message: `${completedCount} facturas descargadas exitosamente`,
                severity: 'success'
              });
            });
        }
      };

      // Procesar cada factura
      for (const factura of facturasToDownload) {
        if (!factura.imagen) {
          completedCount++;
          continue;
        }

        try {
          // Limpiar la URL (quitar parámetros de cache si existen)
          const cleanUrl = factura.imagen.split('?')[0];

          // Obtener la imagen
          const response = await fetch(cleanUrl);
          if (!response.ok) {
            console.error(`Error al descargar imagen: ${cleanUrl}`);
            checkCompletion();
            continue;
          }

          const blob = await response.blob();

          // Generar un nombre de archivo descriptivo
          let fileName = `${factura.codigo || 'factura'}.jpg`;

          // Si tiene proyecto, añadirlo al nombre
          if (factura.proyecto) {
            fileName = `${factura.proyecto}_${fileName}`;
          }

          // Añadir origen al nombre para claridad
          fileName = `${factura.origen}_${fileName}`;

          // Añadir al ZIP
          imagesFolder.file(fileName, blob);
          checkCompletion();
        } catch (error) {
          console.error(`Error procesando factura ${factura.id}:`, error);
          checkCompletion();
        }
      }
    } catch (error) {
      console.error('Error al crear el archivo ZIP:', error);
      setSnackbar({
        open: true,
        message: 'Error al descargar las facturas',
        severity: 'error'
      });
      setLoading(false);
    }
  };

  // Renderiza la vista de carpetas
  const renderFolders = () => {
    let folders = [];
    let folderType = '';

    if (tabValue === 2) { // Por obra
      folders = getFoldersByType('proyecto');
      folderType = 'proyecto';
    } else if (tabValue === 3) { // Por caja
      folders = getFoldersByType('caja');
      folderType = 'caja';
    } else if (tabValue === 4) { // Por nombre de factura
      folders = getFoldersByType('nombre');
      folderType = 'nombre';
    } else {
      return null;
    }

    return (
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {folders.map((folder) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={folder}>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Card
                sx={{
                  cursor: 'pointer',
                  backgroundColor: folder === selectedFolder ? '#e8f5e9' : 'white',
                  transition: 'all 0.3s ease',
                  boxShadow: folder === selectedFolder ? '0 4px 20px rgba(46, 125, 50, 0.2)' : '0 2px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
                  }
                }}
                onClick={() => handleFolderSelect(folder, folderType)}
              >
                <CardContent sx={{ textAlign: 'center', p: 2 }}>
                  <FolderIcon sx={{ fontSize: 60, color: '#2e7d32', mb: 1 }} />
                  <Typography variant="subtitle1" noWrap fontWeight={folder === selectedFolder ? 'bold' : 'regular'}>
                    {folder}
                  </Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    );
  };
  // Renderiza la vista de grid o lista según la selección
  const renderFacturas = () => {
    // Si está en vista de carpetas y hay una carpeta seleccionada, mostrar solo facturas de esa carpeta
    let filteredFacturas = [...facturas];

    if (selectedFolder && tabValue === 2) {
      filteredFacturas = filteredFacturas.filter(
        comp => comp.proyecto === selectedFolder
      );
    } else if (selectedFolder && tabValue === 3) {
      filteredFacturas = filteredFacturas.filter(
        comp => comp.caja === selectedFolder
      );
    } else if (selectedFolder && tabValue === 4) {
      filteredFacturas = filteredFacturas.filter(
        comp => comp.nombre_factura === selectedFolder
      );
    }

    if (viewMode === 'grid') {
      return (
        <Grid container spacing={3} ref={listRef}>
          {filteredFacturas.map((factura) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={`${factura.origen}-${factura.id}`}>
              <AnimatePresence>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  <StyledCard>
                    <StyledCardMedia>
                      {factura.imagen ? (
                        <>
                          <Box
                            component="img"
                            src={factura.imagen}
                            alt={`Factura ${factura.codigo || ""}`}
                            sx={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                            onClick={() => handleShowImage(factura)}
                          />
                          {/* Etiqueta de origen */}
                          <OriginChip
                            label={factura.origen}
                            size="small"
                            icon={originIcons[factura.origen]}
                            origin={factura.origen}
                          />
                          {/* Botón de descarga */}
                          <DownloadButton
                            onClick={(e) => {
                              handleDownload(e, factura);
                              // Estas líneas adicionales garantizan que no haya propagación
                              if (e && e.stopPropagation) e.stopPropagation();
                              if (e && e.preventDefault) e.preventDefault();
                              return false;
                            }}
                            size="medium"
                          >
                            <DownloadIcon fontSize="medium" />
                          </DownloadButton>
                        </>
                      ) : (
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            backgroundColor: '#f5f5f5'
                          }}
                          onClick={() => handleShowImage(factura)}
                        >
                          <ReceiptIcon sx={{ fontSize: 60, color: '#9e9e9e' }} />
                          {/* Etiqueta de origen incluso sin imagen */}
                          <OriginChip
                            label={factura.origen}
                            size="small"
                            icon={originIcons[factura.origen]}
                            origin={factura.origen}
                          />
                        </Box>
                      )}
                      {factura.codigo && (
                        <CodeChip
                          label={factura.codigo}
                          size="small"
                        />
                      )}
                    </StyledCardMedia>
                    <CardContent>
                      <Typography variant="subtitle1" fontWeight="bold" gutterBottom noWrap>
                        {factura.proveedor || 'Sin proveedor'}
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                        <Typography variant="body2" color="text.secondary">
                          {factura.fecha_formateada}
                        </Typography>
                        <Typography variant="body2" fontWeight="bold" color="primary">
                          {factura.monto_formateado}
                        </Typography>
                      </Box>
                      <Divider sx={{ my: 1 }} />
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Tooltip title={`Caja: ${factura.caja || "No especificada"}`}>
                          <Typography variant="caption" color="text.secondary" noWrap sx={{ maxWidth: '45%' }}>
                            {factura.caja || "No especificada"}
                          </Typography>
                        </Tooltip>

                        <Tooltip title={`Proyecto: ${factura.proyecto || "No especificado"}`}>
                          <Chip
                            label={factura.proyecto || "Sin proyecto"}
                            size="small"
                            sx={{ fontSize: '0.6rem', height: 20 }}
                          />
                        </Tooltip>
                      </Box>
                    </CardContent>
                  </StyledCard>
                </motion.div>
              </AnimatePresence>
            </Grid>
          ))}
        </Grid>
      );
    } else {
      // Vista de lista
      return (
        <Box ref={listRef}>
          {filteredFacturas.map((factura) => (
            <motion.div
              key={`${factura.origen}-${factura.id}`}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Paper
                elevation={0}
                sx={{
                  mb: 2,
                  border: '1px solid rgba(0,0,0,0.08)',
                  borderRadius: 2,
                  overflow: 'hidden',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                    borderColor: 'rgba(0,0,0,0.12)',
                  }
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={3} md={2} sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        height: '100%',
                        position: 'relative',
                        cursor: 'pointer',
                        minHeight: { xs: 140, sm: 'auto' }
                      }}
                    >
                      {factura.imagen ? (
                        <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                          <Box
                            component="img"
                            src={factura.imagen}
                            alt={`Factura ${factura.codigo || ""}`}
                            sx={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              minHeight: { xs: 140, sm: '100%' },
                            }}
                            onClick={() => handleShowImage(factura)}
                          />
                          {/* Etiqueta de origen */}
                          <OriginChip
                            label={factura.origen}
                            size="small"
                            icon={originIcons[factura.origen]}
                            origin={factura.origen}
                          />
                          {/* Botón de descarga para lista */}
                          <DownloadButton
                            onClick={(e) => {
                              handleDownload(e, factura);
                              // Estas líneas adicionales garantizan que no haya propagación
                              if (e && e.stopPropagation) e.stopPropagation();
                              if (e && e.preventDefault) e.preventDefault();
                              return false;
                            }} size="medium"
                          >
                            <DownloadIcon fontSize="medium" />
                          </DownloadButton>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            minHeight: { xs: 140, sm: '100%' },
                            backgroundColor: '#f5f5f5',
                          }}
                          onClick={() => handleShowImage(factura)}
                        >
                          <ReceiptIcon sx={{ fontSize: 60, color: '#9e9e9e' }} />
                          {/* Etiqueta de origen incluso sin imagen */}
                          <OriginChip
                            label={factura.origen}
                            size="small"
                            icon={originIcons[factura.origen]}
                            origin={factura.origen}
                          />
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={9} md={10}>
                    <Box sx={{ p: 2 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Typography variant="h6" fontWeight="medium">
                          {factura.proveedor || 'Sin proveedor'}
                        </Typography>
                        <Typography variant="h6" fontWeight="bold" color="primary">
                          {factura.monto_formateado}
                        </Typography>
                      </Box>

                      <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        <Chip
                          icon={<FolderIcon />}
                          label={factura.proyecto || "Sin proyecto"}
                          size="small"
                          color={factura.proyecto ? "primary" : "default"}
                          variant={factura.proyecto ? "filled" : "outlined"}
                        />

                        <Chip
                          label={factura.caja}
                          size="small"
                          variant="outlined"
                        />

                        <Chip
                          label={factura.fecha_formateada}
                          size="small"
                          variant="outlined"
                        />

                        <Chip
                          label={factura.codigo}
                          size="small"
                          color="success"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(factura.codigo);
                            setSnackbar({
                              open: true,
                              message: 'Código copiado al portapapeles',
                              severity: 'success'
                            });
                          }}
                          sx={{
                            fontFamily: 'monospace',
                            cursor: 'pointer',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </motion.div>
          ))}
        </Box>
      );
    }
  };

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <TitleBar position="static">
          <Toolbar>
            <Typography variant="h4" fontWeight="bold" color="#2e7d32" sx={{ flexGrow: 1 }}>
              Buscador de Facturas
            </Typography>

            <Box sx={{ display: 'flex', gap: 1 }}>
              <Tooltip title="Cambiar vista">
                <IconButton
                  color="primary"
                  onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
                >
                  {viewMode === 'grid' ?
                    <ViewListIcon /> :
                    <ViewModuleIcon />
                  }
                </IconButton>
              </Tooltip>

              <Tooltip title="Ordenar">
                <IconButton
                  color="primary"
                  onClick={handleSortClick}
                >
                  <SortIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Descargar todas las facturas">
                <IconButton
                  color="primary"
                  onClick={downloadAllImages}
                  disabled={loading || facturas.length === 0}
                >
                  <FileDownloadIcon />
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={sortAnchorEl}
                open={Boolean(sortAnchorEl)}
                onClose={handleSortClose}
                TransitionComponent={Fade}
              >
                <MenuItem
                  onClick={() => handleSortSelect('date_desc')}
                  selected={sortBy === 'date_desc'}
                >
                  <ListItemText>Fecha (más reciente)</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => handleSortSelect('date_asc')}
                  selected={sortBy === 'date_asc'}
                >
                  <ListItemText>Fecha (más antigua)</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => handleSortSelect('amount_desc')}
                  selected={sortBy === 'amount_desc'}
                >
                  <ListItemText>Monto (mayor)</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => handleSortSelect('amount_asc')}
                  selected={sortBy === 'amount_asc'}
                >
                  <ListItemText>Monto (menor)</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              mb: 2,
              px: 2,
              '& .MuiTab-root': {
                minWidth: 'auto',
                fontWeight: 'medium',
                px: 3,
              },
              '& .Mui-selected': {
                color: '#2e7d32',
                fontWeight: 'bold',
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#2e7d32',
                height: 3,
                borderRadius: '3px 3px 0 0',
              },
            }}
          >
            <Tab label="Todos" />
            <Tab label="Recientes" />
            <Tab label="Por Obra" />
            <Tab label="Por Caja" />
            <Tab label="Por Nombre" />
          </Tabs>
        </TitleBar>

        <Paper elevation={0} sx={{ p: 3, borderRadius: 2, mb: 3, mt: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
            <TextField
              placeholder="Buscar por código..."
              value={filters.codigo}
              onChange={(e) => setFilters({ ...filters, codigo: e.target.value })}
              sx={{
                flex: 1,
                minWidth: 250,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8,
                  '&:hover fieldset': {
                    borderColor: '#2e7d32',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#2e7d32' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<FilterListIcon />}
              onClick={() => setShowFilters(!showFilters)}
              sx={{
                borderRadius: 8,
                borderColor: '#2e7d32',
                color: '#2e7d32',
                '&:hover': {
                  borderColor: '#1b5e20',
                  backgroundColor: 'rgba(46, 125, 50, 0.04)',
                },
              }}
            >
              Filtros
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearchFacturas}
              disabled={loading}
              sx={{
                borderRadius: 8,
                backgroundColor: '#2e7d32',
                '&:hover': { backgroundColor: '#1b5e20' },
                px: 3,
              }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Buscar'}
            </Button>
          </Box>

          {showFilters && (
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Origen</InputLabel>
                    <Select
                      value={filters.origen}
                      onChange={(e) => setFilters({ ...filters, origen: e.target.value })}
                      label="Origen"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      <MenuItem value={FACTURA_ORIGINS.SALIDA}>Salidas</MenuItem>
                      <MenuItem value={FACTURA_ORIGINS.CAMBIO}>Cambios de Moneda</MenuItem>
                      <MenuItem value={FACTURA_ORIGINS.COBRO}>Cobros Socios</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Proyecto</InputLabel>
                    <Select
                      value={filters.proyecto}
                      onChange={(e) => setFilters({ ...filters, proyecto: e.target.value })}
                      label="Proyecto"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {proyectos.map((proyecto) => (
                        <MenuItem key={proyecto} value={proyecto}>{proyecto}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Caja Origen</InputLabel>
                    <Select
                      value={filters.caja_origen}
                      onChange={(e) => setFilters({ ...filters, caja_origen: e.target.value })}
                      label="Caja Origen"
                    >
                      <MenuItem value="">Todas</MenuItem>
                      {cajasOrigen.map((caja) => (
                        <MenuItem key={caja} value={caja}>{caja}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Proveedor</InputLabel>
                    <Select
                      value={filters.proveedor}
                      onChange={(e) => setFilters({ ...filters, proveedor: e.target.value })}
                      label="Proveedor"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {proveedores.map((prov) => (
                        <MenuItem key={prov} value={prov}>{prov}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>A Nombre De</InputLabel>
                    <Select
                      value={filters.nombre_factura}
                      onChange={(e) => setFilters({ ...filters, nombre_factura: e.target.value })}
                      label="A Nombre De"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {nombreFacturas.map((nombre) => (
                        <MenuItem key={nombre} value={nombre}>{nombre}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Código de factura"
                    value={filters.codigo_factura}
                    onChange={(e) => setFilters({ ...filters, codigo_factura: e.target.value })}
                    placeholder="Buscar por código"
                  />
                </Grid>
              </Grid>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6} md={3}>
                    <DatePicker
                      label="Desde"
                      value={filters.fecha_desde}
                      onChange={(newValue) => setFilters({ ...filters, fecha_desde: newValue })}
                      renderInput={(params) => <TextField size="small" {...params} fullWidth />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <DatePicker
                      label="Hasta"
                      value={filters.fecha_hasta}
                      onChange={(newValue) => setFilters({ ...filters, fecha_hasta: newValue })}
                      renderInput={(params) => <TextField size="small" {...params} fullWidth />}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>

              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={resetFilters}
                  sx={{ mr: 2, color: '#757575' }}
                >
                  Limpiar filtros
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={addFilter}
                  sx={{
                    backgroundColor: '#2e7d32',
                    '&:hover': { backgroundColor: '#1b5e20' },
                  }}
                >
                  Aplicar filtros
                </Button>
              </Box>
            </Box>
          )}

          {/* Chips de filtros activos */}
          {activeFilters.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', pt: 2 }}>
              {activeFilters.map((filter, index) => (
                <FilterChip
                  key={index}
                  label={filter.label}
                  onDelete={() => removeFilter(index)}
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
              {activeFilters.length > 1 && (
                <Button
                  variant="text"
                  size="small"
                  onClick={removeAllFilters}
                  sx={{
                    color: '#2e7d32',
                    '&:hover': { backgroundColor: 'rgba(46, 125, 50, 0.04)' },
                  }}
                >
                  Limpiar todos
                </Button>
              )}
            </Box>
          )}
        </Paper>

        {tabValue === 2 || tabValue === 3 || tabValue === 4 ? renderFolders() : null}

        {facturas.length > 0 ? (
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'medium', color: '#666' }}>
              {
                selectedFolder
                  ? `Facturas de ${tabValue === 2 ? 'proyecto' : tabValue === 3 ? 'caja' : 'nombre'}: ${selectedFolder}`
                  : `${facturas.length} factura${facturas.length !== 1 ? 's' : ''} encontrada${facturas.length !== 1 ? 's' : ''}`
              }
            </Typography>

            {renderFacturas()}
          </Box>
        ) : loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ textAlign: 'center', py: 6 }}>
            <ReceiptIcon sx={{ fontSize: 80, color: '#e0e0e0', mb: 2 }} />
            <Typography variant="h6" color="text.secondary">
              No se encontraron facturas
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Intenta con otros filtros o realiza una nueva búsqueda
            </Typography>
          </Box>
        )}
      </motion.div>

      {/* Diálogo para vista previa de imagen */}
      <ImagePreviewDialog
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        maxWidth="lg"
        onClick={() => setOpenPreview(false)}
      >
        <DialogContent sx={{ p: 0, overflow: 'hidden', position: 'relative' }}>
          {currentImage && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.02)',
                pb: 2,
              }}
            >
              <Box
                component="img"
                src={currentImage.url}
                alt="Factura"
                sx={{
                  maxWidth: '100%',
                  maxHeight: '70vh',
                  objectFit: 'contain',
                  borderRadius: 1,
                  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
                  mb: 2
                }}
                onError={(e) => {
                  console.error('Error cargando imagen en diálogo:', e);
                  e.target.src = 'https://via.placeholder.com/400x300?text=Imagen+no+disponible';
                }}
              />

              <Box sx={{
                p: 3,
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                borderRadius: 2,
                width: '100%',
                maxWidth: 500,
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary" fontWeight="bold">
                      Código
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" sx={{ fontFamily: 'monospace', mr: 1 }}>
                        {currentImage.codigo}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(currentImage.codigo);
                          setSnackbar({
                            open: true,
                            message: 'Código copiado al portapapeles',
                            severity: 'success'
                          });
                        }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary" fontWeight="bold">
                      Fecha
                    </Typography>
                    <Typography variant="body2">
                      {currentImage.fecha}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary" fontWeight="bold">
                      Proyecto
                    </Typography>
                    <Typography variant="body2">
                      {currentImage.proyecto || "Sin proyecto"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary" fontWeight="bold">
                      Caja
                    </Typography>
                    <Typography variant="body2">
                      {currentImage.caja}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary" fontWeight="bold">
                      A Nombre De
                    </Typography>
                    <Typography variant="body2">
                      {currentImage.nombre_factura || "No especificado"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary" fontWeight="bold">
                      Proveedor
                    </Typography>
                    <Typography variant="body2">
                      {currentImage.proveedor || "No especificado"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary" fontWeight="bold">
                      Origen
                    </Typography>
                    <Typography variant="body2">
                      {currentImage.origen}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary" fontWeight="bold">
                      Monto
                    </Typography>
                    <Typography variant="body1" fontWeight="bold" color="primary">
                      {currentImage.monto}
                    </Typography>
                  </Grid>
                </Grid>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      const link = document.createElement('a');
                      link.href = currentImage.url.split('?')[0]; // Quitar parámetros de caché
                      link.download = `${currentImage.origen}_${currentImage.proyecto || 'factura'}_${currentImage.codigo}.jpg`;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);

                      setSnackbar({
                        open: true,
                        message: 'Factura descargada exitosamente',
                        severity: 'success'
                      });
                      return false;
                    }}
                    sx={{
                      backgroundColor: '#2e7d32',
                      '&:hover': { backgroundColor: '#1b5e20' }
                    }}
                  >
                    Descargar factura
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={() => setOpenPreview(false)}
                    sx={{ borderColor: '#2e7d32', color: '#2e7d32' }}
                  >
                    Cerrar
                  </Button>
                </Box>
              </Box>

              <IconButton
                size="small"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                }}
                onClick={() => setOpenPreview(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </DialogContent>
      </ImagePreviewDialog>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default BuscadorFacturas;