import React, { useEffect, useState, useRef } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Typography,
  IconButton,
  Container,
  Paper,
  Tooltip,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  InputAdornment,
  Collapse,
  Alert,
  Snackbar,
  Divider,
  CircularProgress,
  FormControlLabel,
  Switch,
  Autocomplete,
  Card,
  CardContent,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import DownloadIcon from '@mui/icons-material/Download';
import MoneyIcon from '@mui/icons-material/Money';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AssignmentIcon from '@mui/icons-material/Assignment';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';
import { uploadImage, formatImageUrl } from '../utils/imageUtils';

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 16,
  overflow: 'hidden',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.12)',
    transition: 'box-shadow 0.3s ease-in-out',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
  },
  '&:hover': {
    backgroundColor: 'rgba(46, 125, 50, 0.04)',
    transition: 'background-color 0.2s ease',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
  cursor: 'pointer',
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '12px 16px',
  fontSize: '0.875rem',
  textAlign: 'center',
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#f8f9fa',
  padding: '14px 16px',
  fontSize: '0.875rem',
  fontWeight: 'bold',
  color: '#2e7d32',
  borderBottom: '2px solid #e8f5e9',
  textAlign: 'center',
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: 'rgba(46, 125, 50, 0.1)',
  },
}));

const AddSalidaDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiPaper-root': {
    borderRadius: 20,
    padding: theme.spacing(2),
    width: '600px',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
}));

// Estilo mejorado para los inputs con bordes más redondeados
const StyledTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 12,
    '&:hover fieldset': {
      borderColor: '#2e7d32',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2e7d32',
    },
  },
  '& .MuiInputLabel-outlined': {
    backgroundColor: 'white',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 5,
  },
}));

// Estilo para Select con bordes redondeados
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    borderRadius: 12,
    '&:hover fieldset': {
      borderColor: '#2e7d32',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2e7d32',
    },
  },
  '& .MuiInputLabel-outlined': {
    backgroundColor: 'white',
    paddingLeft: 5,
    paddingRight: 5,
    borderRadius: 5,
  },
}));

const ImagePreviewDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
  '& .MuiPaper-root': {
    borderRadius: 8,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
}));

const FilterChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: '#e8f5e9',
  color: '#2e7d32',
  '&:hover': {
    backgroundColor: '#c8e6c9',
  },
  '& .MuiChip-deleteIcon': {
    color: '#2e7d32',
    '&:hover': {
      color: '#1b5e20',
    },
  },
}));

const ExportButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#2e7d32',
  color: 'white',
  padding: '8px 16px',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: '#1b5e20',
  },
  transition: 'background-color 0.3s ease',
}));

const AddButton = styled(IconButton)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#2e7d32',
  width: 40,
  height: 40,
  boxShadow: '0 4px 10px rgba(46, 125, 50, 0.3)',
  '&:hover': {
    backgroundColor: '#1b5e20',
    boxShadow: '0 6px 15px rgba(46, 125, 50, 0.4)',
  },
  transition: 'all 0.3s ease',
}));

const FileUploadBox = styled(Box)(({ theme }) => ({
  border: '2px dashed #c8e6c9',
  borderRadius: 12, // Más redondeado
  padding: theme.spacing(3),
  textAlign: 'center',
  backgroundColor: '#f1f8e9',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    borderColor: '#2e7d32',
    backgroundColor: '#e8f5e9',
  },
}));

const DetailCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
  overflow: 'hidden',
  marginBottom: theme.spacing(2),
  transition: 'all 0.3s ease',
  '&:hover': {
    boxShadow: '0 12px 40px rgba(0, 0, 0, 0.15)',
  },
}));

const DetailCardContent = styled(CardContent)(({ theme }) => ({
  padding: theme.spacing(3),
}));

const MontoChip = styled(Chip)(({ theme }) => ({
  fontWeight: 'bold',
  borderRadius: 16,
  padding: theme.spacing(1, 0),
}));

const DetailDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiDialog-paper': {
    borderRadius: 20, // Más redondeado
    maxWidth: '90vw',
    width: '800px',
    maxHeight: '90vh',
    padding: theme.spacing(2),
    background: 'linear-gradient(to bottom right, #ffffff, #f9fff9)',
  },
}));

const ReadOnlyTextField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: theme.palette.primary.main,
    fontWeight: 'medium',
    backgroundColor: 'rgba(46, 125, 50, 0.04)',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 12, // Más redondeado
  },
}));

// Botón guardar más compacto
const SaveButton = styled(Button)(({ theme }) => ({
  borderRadius: 20,
  backgroundColor: '#2e7d32',
  '&:hover': {
    backgroundColor: '#1b5e20',
    boxShadow: '0 6px 15px rgba(46, 125, 50, 0.4)',
  },
  boxShadow: '0 4px 10px rgba(46, 125, 50, 0.3)',
  padding: '10px 30px',
  fontWeight: 'bold',
  transition: 'all 0.3s ease',
  minWidth: '180px',
  maxWidth: '240px',
  margin: '0 auto'
}));

// Componente para previsualización de imágenes
const ImagePreview = ({
  src,
  alt = 'Imagen',
  onClick,
  width = '100%',
  height = '100%',
  showZoom = true,
  thumbSize = 'medium' // 'small', 'medium', 'large'
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  // Mapeo de tamaños
  const sizes = {
    small: { width: 40, height: 40, iconSize: 20 },
    medium: { width: 80, height: 80, iconSize: 36 },
    large: { width: 120, height: 120, iconSize: 48 }
  };

  const size = sizes[thumbSize] || sizes.medium;

  useEffect(() => {
    if (!src) {
      setError(true);
      return;
    }

    setLoaded(false);
    setError(false);

    const img = new Image();

    img.onload = () => {
      setLoaded(true);
      setError(false);
    };

    img.onerror = () => {
      // Intentar URL directa sin parámetros de caché
      if (src.includes('?')) {
        const directUrl = src.split('?')[0];

        const directImg = new Image();
        directImg.onload = () => {
          setLoaded(true);
          setError(false);
        };
        directImg.onerror = () => {
          setError(true);
          setLoaded(false);
        };
        directImg.src = directUrl;
      } else {
        setError(true);
        setLoaded(false);
      }
    };

    img.src = src;

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src, retryCount]);

  const handleRetry = (e) => {
    e.stopPropagation();
    setRetryCount(prev => prev + 1);
  };

  if (error) {
    return (
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      >
        <Box
          sx={{
            width: width !== '100%' ? width : size.width,
            height: height !== '100%' ? height : size.height,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f5f5f5',
            borderRadius: 1,
            border: '1px solid #e0e0e0',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <ReceiptIcon sx={{ color: '#9e9e9e', fontSize: size.iconSize }} />
          <Typography variant="caption" sx={{ fontSize: '10px', textAlign: 'center', mt: 0.5 }}>
            Error
          </Typography>

          <Tooltip title="Reintentar cargar imagen">
            <IconButton
              size="small"
              onClick={handleRetry}
              sx={{
                position: 'absolute',
                top: 2,
                right: 2,
                background: 'rgba(255,255,255,0.8)',
                width: 16,
                height: 16
              }}
            >
              <RefreshIcon sx={{ fontSize: 12 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </motion.div>
    );
  }

  if (!loaded) {
    return (
      <Box
        sx={{
          width: width !== '100%' ? width : size.width,
          height: height !== '100%' ? height : size.height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f5f5f5',
          borderRadius: 1,
          border: '1px solid #e0e0e0'
        }}
      >
        <CircularProgress size={size.iconSize / 2} sx={{ color: '#2e7d32' }} />
      </Box>
    );
  }

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      style={{ cursor: 'pointer', position: 'relative' }}
    >
      <Box
        sx={{
          width: width !== '100%' ? width : size.width,
          height: height !== '100%' ? height : size.height,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 1,
          border: '1px solid #e0e0e0'
        }}
        onClick={onClick}
      >
        <Box
          component="img"
          src={src}
          alt={alt}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 0.3s ease',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        />

        {showZoom && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 4,
              right: 4,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 24,
              height: 24,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              }
            }}
          >
            <ZoomInIcon sx={{ fontSize: 16, color: '#2e7d32' }} />
          </Box>
        )}
      </Box>
    </motion.div>
  );
};

const Salidas = () => {
  const [salidas, setSalidas] = useState([]);
  const [filteredSalidas, setFilteredSalidas] = useState([]);
  const [cajasOrigen, setCajasOrigen] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [proveedoresInfo, setProveedoresInfo] = useState([]); // New state for provider info
  const [proyectos, setProyectos] = useState([]);
  const [conceptos, setConceptos] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [subetapas, setSubetapas] = useState([]);
  const [openAddSalida, setOpenAddSalida] = useState(false);
  const [openEditSalida, setOpenEditSalida] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [loading, setLoading] = useState(false);
  const [openDetailDialog, setOpenDetailDialog] = useState(false);
  const [selectedSalida, setSelectedSalida] = useState(null);
  const [tiposIndexacion, setTiposIndexacion] = useState(['Dolar Oficial Venta', 'Custom']);

  // Nuevo estado para manejar nuevos proveedores
  const [nuevoProveedor, setNuevoProveedor] = useState('');
  // Nuevo estado para manejar nuevas subetapas
  const [nuevaSubetapa, setNuevaSubetapa] = useState('');

  // Estados para comprobantes
  const [tieneComprobante, setTieneComprobante] = useState(false);
  const [comprobanteImagen, setComprobanteImagen] = useState(null);
  const [comprobantePreview, setComprobantePreview] = useState('');
  const [codigoComprobante, setCodigoComprobante] = useState('');
  const comprobanteInputRef = useRef(null);

  // Estados para facturas
  const [tieneFactura, setTieneFactura] = useState(false);
  const [facturaImagen, setFacturaImagen] = useState(null);
  const [facturaPreview, setFacturaPreview] = useState('');
  const [codigoFactura, setCodigoFactura] = useState('');
  const [facturaANombreDe, setFacturaANombreDe] = useState('');
  const [nombresFacturas, setNombresFacturas] = useState([]);
  const [nuevoNombreFactura, setNuevoNombreFactura] = useState('');
  const [addingNewNombre, setAddingNewNombre] = useState(false);
  const facturaInputRef = useRef(null);

  // Estados para indexación
  const [tieneIndexacion, setTieneIndexacion] = useState(false);
  const [tipoIndexacion, setTipoIndexacion] = useState('');
  const [nuevoTipoIndexacion, setNuevoTipoIndexacion] = useState('');
  const [tcIndexado, setTcIndexado] = useState('');
  const [montoIndexado, setMontoIndexado] = useState('');
  const [dolarOficialVenta, setDolarOficialVenta] = useState('');

  // Paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);

  // Filtros
  const [filters, setFilters] = useState({
    caja_origen: '',
    proveedor: '',
    proyecto: '',
    concepto: '',
    etapa: '',
    fecha_desde: null,
    fecha_hasta: null,
    tiene_comprobante: null,
    tiene_factura: null,
    codigo_comprobante: '',
    codigo_factura: '',
    tiene_indexacion: null,
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilters, setActiveFilters] = useState([]);

  // Estados para nuevas salidas
  const [fechaSalida, setFechaSalida] = useState(new Date());
  const [cajaOrigen, setCajaOrigen] = useState('');
  const [proveedor, setProveedor] = useState('');
  const [codigoProyecto, setCodigoProyecto] = useState('');
  const [concepto, setConcepto] = useState('');
  const [etapa, setEtapa] = useState('');
  const [subetapa, setSubetapa] = useState('');
  const [tipoCambio, setTipoCambio] = useState('');
  const [moneda, setMoneda] = useState('ARS');
  const [monto, setMonto] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [filaEditando, setFilaEditando] = useState(null);

  // Notificación
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    const fetchDolarBlue = async () => {
      try {
        const response = await fetch('https://dolarapi.com/v1/dolares/blue');
        if (!response.ok) throw new Error('Error al obtener el dólar blue');
        const data = await response.json();
        setTipoCambio(data.compra.toString());
      } catch (error) {
        console.error('Error fetching dólar blue:', error.message);
        setTipoCambio('');
      }
    };

    const fetchDolarOficial = async () => {
      try {
        const response = await fetch('https://dolarapi.com/v1/dolares/oficial');
        if (!response.ok) throw new Error('Error al obtener el dólar oficial');
        const data = await response.json();
        setDolarOficialVenta(data.venta.toString());
        setTcIndexado(data.venta.toString());
      } catch (error) {
        console.error('Error fetching dólar oficial:', error.message);
        setDolarOficialVenta('');
      }
    };

    fetchDolarBlue();
    fetchDolarOficial();
    fetchSalidas();
    fetchCajasOrigen();
    fetchProveedores();
    fetchProyectos();
    fetchConceptos();
    fetchEtapas();
    fetchNombresFacturas();
    fetchTiposIndexacion();
    fetchProveedoresInfo(); // Added this function call
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salidas, searchTerm, activeFilters]);

  // Calcular monto indexado cuando cambian los valores relevantes
  useEffect(() => {
    calcularMontoIndexado();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tieneIndexacion, monto, moneda, tipoCambio, tcIndexado]);

  // Nueva función para buscar y establecer el proyecto por defecto cuando se cambia la caja de origen
  useEffect(() => {
    if (cajaOrigen) {
      buscarProyectoPorCajaOrigen(cajaOrigen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cajaOrigen]);

  const buscarProyectoPorCajaOrigen = async (caja) => {
    try {
      // Primero buscar en caja_proyecto_mo
      let { data, error } = await supabase
        .from('proyectos')
        .select('codigo_de_proyecto')
        .eq('caja_proyecto_mo', caja)
        .limit(1);

      if (error) throw error;

      // Si no se encuentra, buscar en caja_proyecto
      if (!data || data.length === 0) {
        const response = await supabase
          .from('proyectos')
          .select('codigo_de_proyecto')
          .eq('caja_proyecto', caja)
          .limit(1);

        if (response.error) throw response.error;
        data = response.data;
      }

      // Si aún no se encuentra, buscar en apellido_del_cliente
      if (!data || data.length === 0) {
        const response = await supabase
          .from('proyectos')
          .select('codigo_de_proyecto')
          .eq('apellido_del_cliente', caja)
          .limit(1);

        if (response.error) throw response.error;
        data = response.data;
      }

      // Si se encontró un proyecto, establecerlo como predeterminado
      if (data && data.length > 0) {
        setCodigoProyecto(data[0].codigo_de_proyecto);

        // Regenerar códigos si hay imágenes
        if (comprobanteImagen) {
          generateCodigoComprobante(data[0].codigo_de_proyecto);
        }
        if (facturaImagen) {
          generateCodigoFactura(data[0].codigo_de_proyecto);
        }
      }
    } catch (error) {
      console.error('Error al buscar proyecto por caja origen:', error);
    }
  };

  // Add utility function to get provider info
  const getProveedorInfo = (proveedorName) => {
    return proveedoresInfo.find(p => p.proveedor_salida === proveedorName) || null;
  };

  // Add function to autocomplete fields based on provider
  const autocompletarCamposPorProveedor = (proveedorSeleccionado) => {
    const info = getProveedorInfo(proveedorSeleccionado);
    if (info) {
      if (info.concepto_mas_usado && !concepto) {
        setConcepto(info.concepto_mas_usado);
      }
      if (info.etapa_mas_usada && !etapa) {
        setEtapa(info.etapa_mas_usada);
        fetchSubetapas(info.etapa_mas_usada);

        // If there's a most used sub-stage for this provider, select it
        if (info.subetapa_mas_usada) {
          setTimeout(() => {
            setSubetapa(info.subetapa_mas_usada);
          }, 300); // Small delay to ensure subetapas are loaded
        }
      }
    }
  };

  const calcularMontoIndexado = () => {
    if (tieneIndexacion && monto && tcIndexado && !isNaN(tcIndexado) && parseFloat(tcIndexado) > 0) {
      const montoARS = moneda === 'USD' ? parseFloat(monto) * parseFloat(tipoCambio) : parseFloat(monto);
      const indexado = montoARS / parseFloat(tcIndexado);
      setMontoIndexado(indexado.toFixed(2));
    } else {
      setMontoIndexado('');
    }
  };

  const fetchSalidas = async () => {
    try {
      // Ordenar por fecha descendente (más reciente primero)
      const { data, error } = await supabase
        .from('salidas')
        .select('*')
        .order('fecha_salida', { ascending: false });

      if (error) console.error('Error fetching salidas:', error.message);
      else {
        // Formatear fechas para mejor visualización y aplicar formatImageUrl
        const formattedData = data.map(item => ({
          ...item,
          fecha_formateada: item.fecha_salida ? format(new Date(item.fecha_salida), 'dd/MM/yyyy') : '',
          monto_formateado: formatCurrency(item.monto_salida, item.moneda_salida),
          imagen_comprobante_salida: item.imagen_comprobante_salida ?
            formatImageUrl(item.imagen_comprobante_salida) : null,
          imagen_factura_salida: item.imagen_factura_salida ?
            formatImageUrl(item.imagen_factura_salida) : null
        }));
        setSalidas(formattedData || []);
        setFilteredSalidas(formattedData || []);
      }
    } catch (error) {
      console.error('Error en fetchSalidas:', error);
    }
  };

  const fetchTiposIndexacion = async () => {
    try {
      const { data, error } = await supabase
        .from('tipos_indexacion_salidas')
        .select('tipo_indexacion');

      if (error) {
        console.error('Error fetching tipos de indexación:', error.message);
      } else if (data && data.length > 0) {
        const tipos = data.map(item => item.tipo_indexacion);
        // Asegurarse de que 'Dolar Oficial Venta' esté siempre en la lista
        if (!tipos.includes('Dolar Oficial Venta')) {
          tipos.unshift('Dolar Oficial Venta');
        }
        setTiposIndexacion(tipos);
      }
    } catch (error) {
      console.error('Error en fetchTiposIndexacion:', error);
    }
  };

  const formatCurrency = (amount, currency) => {
    if (!amount) return '';
    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currency === 'USD' ? 'USD' : 'ARS',
      minimumFractionDigits: 2
    });
    return formatter.format(amount);
  };

  const fetchCajasOrigen = async () => {
    try {
      const { data, error } = await supabase.from('cajas_origen_activas').select('caja_origen');
      if (error) throw error;
      setCajasOrigen(data.map((caja) => caja.caja_origen) || []);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const fetchProveedores = async () => {
    const { data, error } = await supabase.from('proveedores_de_servicios').select('proveedor_de_servicios');
    if (error) console.error('Error:', error);
    else setProveedores(data.map((prov) => prov.proveedor_de_servicios) || []);
  };

  // New function to fetch provider info
  const fetchProveedoresInfo = async () => {
    try {
      const { data, error } = await supabase.from('proveedor_frecuencia').select('*');
      if (error) throw error;
      setProveedoresInfo(data || []);
    } catch (error) {
      console.error('Error fetching provider info:', error);
    }
  };

  const fetchProyectos = async () => {
    const { data, error } = await supabase.from('proyectos').select('codigo_de_proyecto');
    if (error) console.error('Error:', error);
    else setProyectos(data.map((proy) => proy.codigo_de_proyecto) || []);
  };

  const fetchConceptos = async () => {
    const { data, error } = await supabase.from('conceptos_de_salidas').select('concepto_de_salida');
    if (error) console.error('Error:', error);
    else setConceptos(data.map((conc) => conc.concepto_de_salida) || []);
  };

  const fetchEtapas = async () => {
    const { data, error } = await supabase.from('etapas_de_obra').select('etapa_de_obra');
    if (error) console.error('Error:', error);
    else setEtapas([...new Set(data.map((etapa) => etapa.etapa_de_obra))] || []);
  };

  const fetchNombresFacturas = async () => {
    const { data, error } = await supabase.from('facturas_a_nombre_de_salidas').select('nombre_factura');
    if (error) console.error('Error:', error);
    else setNombresFacturas(data.map((item) => item.nombre_factura) || []);
  };

  const fetchSubetapas = async (etapaSeleccionada) => {
    const { data, error } = await supabase
      .from('etapas_de_obra')
      .select('subetapa_de_obra')
      .eq('etapa_de_obra', etapaSeleccionada);
    if (error) console.error('Error:', error);
    else setSubetapas(data.map((sub) => sub.subetapa_de_obra) || []);
  };

  // Función para agregar un nuevo proveedor a la base de datos
  const handleAgregarProveedor = async (nuevoProveedorNombre) => {
    if (!nuevoProveedorNombre || nuevoProveedorNombre.trim() === '') {
      setSnackbar({
        open: true,
        message: 'Por favor, ingrese un nombre de proveedor válido',
        severity: 'error'
      });
      return;
    }

    try {
      // Verificar si ya existe
      const { data, error } = await supabase
        .from('proveedores_de_servicios')
        .select('proveedor_de_servicios')
        .eq('proveedor_de_servicios', nuevoProveedorNombre.trim());

      if (error) throw error;

      // Si no existe, agregarlo
      if (!data || data.length === 0) {
        const { error: insertError } = await supabase
          .from('proveedores_de_servicios')
          .insert([{ proveedor_de_servicios: nuevoProveedorNombre.trim() }]);

        if (insertError) throw insertError;

        // Actualizar la lista local
        setProveedores([...proveedores, nuevoProveedorNombre.trim()]);
        // Establecer el proveedor seleccionado
        setProveedor(nuevoProveedorNombre.trim());

        setSnackbar({
          open: true,
          message: 'Proveedor agregado con éxito',
          severity: 'success'
        });
      } else {
        // Si ya existe, solo seleccionarlo
        setProveedor(nuevoProveedorNombre.trim());
      }

      setNuevoProveedor('');
    } catch (error) {
      console.error('Error al agregar proveedor:', error);
      setSnackbar({
        open: true,
        message: 'Error al agregar proveedor: ' + error.message,
        severity: 'error'
      });
    }
  };

  // Función para agregar una nueva subetapa
  const handleAgregarSubetapa = async (nuevaSubetapaNombre) => {
    if (!etapa) {
      setSnackbar({
        open: true,
        message: 'Por favor, seleccione primero una etapa',
        severity: 'error'
      });
      return;
    }

    if (!nuevaSubetapaNombre || nuevaSubetapaNombre.trim() === '') {
      setSnackbar({
        open: true,
        message: 'Por favor, ingrese un nombre de subetapa válido',
        severity: 'error'
      });
      return;
    }

    try {
      // Verificar si ya existe
      const { data, error } = await supabase
        .from('etapas_de_obra')
        .select('*')
        .eq('etapa_de_obra', etapa)
        .eq('subetapa_de_obra', nuevaSubetapaNombre.trim());

      if (error) throw error;

      // Si no existe, agregarlo
      if (!data || data.length === 0) {
        const { error: insertError } = await supabase
          .from('etapas_de_obra')
          .insert([{
            etapa_de_obra: etapa,
            subetapa_de_obra: nuevaSubetapaNombre.trim()
          }]);

        if (insertError) throw insertError;

        // Actualizar la lista local
        setSubetapas([...subetapas, nuevaSubetapaNombre.trim()]);
        // Establecer la subetapa seleccionada
        setSubetapa(nuevaSubetapaNombre.trim());

        setSnackbar({
          open: true,
          message: 'Subetapa agregada con éxito',
          severity: 'success'
        });
      } else {
        // Si ya existe, solo seleccionarla
        setSubetapa(nuevaSubetapaNombre.trim());
      }

      setNuevaSubetapa('');
    } catch (error) {
      console.error('Error al agregar subetapa:', error);
      setSnackbar({
        open: true,
        message: 'Error al agregar subetapa: ' + error.message,
        severity: 'error'
      });
    }
  };

  const handleComprobanteChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificar tipo de archivo
      if (!file.type.match('image.*')) {
        setSnackbar({
          open: true,
          message: 'Por favor, selecciona un archivo de imagen válido',
          severity: 'error'
        });
        return;
      }

      // Verificar tamaño (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setSnackbar({
          open: true,
          message: 'La imagen debe ser menor a 5MB',
          severity: 'error'
        });
        return;
      }

      setComprobanteImagen(file);
      setTieneComprobante(true);

      // Crear URL temporal para vista previa
      const previewURL = URL.createObjectURL(file);
      setComprobantePreview(previewURL);

      // Generar código automático si hay un proyecto seleccionado
      generateCodigoComprobante();
    }
  };

  const handleFacturaChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificar tipo de archivo
      if (!file.type.match('image.*')) {
        setSnackbar({
          open: true,
          message: 'Por favor, selecciona un archivo de imagen válido',
          severity: 'error'
        });
        return;
      }

      // Verificar tamaño (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setSnackbar({
          open: true,
          message: 'La imagen debe ser menor a 5MB',
          severity: 'error'
        });
        return;
      }

      setFacturaImagen(file);
      setTieneFactura(true);

      // Crear URL temporal para vista previa
      const previewURL = URL.createObjectURL(file);
      setFacturaPreview(previewURL);

      // Generar código automático si hay un proyecto seleccionado
      generateCodigoFactura();
    }
  };

  const generateCodigoComprobante = (proyectoSeleccionado = null) => {
    const proyecto = proyectoSeleccionado || codigoProyecto;
    if (!proyecto) return;

    const dateStr = format(new Date(), 'yyyyMMdd');
    const randomId = Math.floor(Math.random() * 100000).toString().padStart(5, '0');
    setCodigoComprobante(`${proyecto}-COM-${dateStr}-${randomId}`);
  };

  const generateCodigoFactura = (proyectoSeleccionado = null) => {
    const proyecto = proyectoSeleccionado || codigoProyecto;
    if (!proyecto) return;

    const dateStr = format(new Date(), 'yyyyMMdd');
    const randomId = Math.floor(Math.random() * 100000).toString().padStart(5, '0');
    setCodigoFactura(`${proyecto}-FAC-${dateStr}-${randomId}`);
  };

  const handleComprobanteUpload = () => {
    comprobanteInputRef.current.click();
  };

  const handleFacturaUpload = () => {
    facturaInputRef.current.click();
  };

  const removeComprobante = () => {
    setComprobanteImagen(null);
    setComprobantePreview('');
    setCodigoComprobante('');

    // Limpiar el input de archivo
    if (comprobanteInputRef.current) {
      comprobanteInputRef.current.value = '';
    }
  };

  const removeFactura = () => {
    setFacturaImagen(null);
    setFacturaPreview('');
    setCodigoFactura('');
    setFacturaANombreDe('');

    // Limpiar el input de archivo
    if (facturaInputRef.current) {
      facturaInputRef.current.value = '';
    }
  };

  const handleAddTipoIndexacion = async () => {
    if (!nuevoTipoIndexacion.trim()) return;

    try {
      // Primero verificar si ya existe
      const { data, error } = await supabase
        .from('tipos_indexacion_salidas')
        .select('tipo_indexacion')
        .eq('tipo_indexacion', nuevoTipoIndexacion.trim());

      if (error) throw error;

      // Si no existe, agregar
      if (!data || data.length === 0) {
        const { error: insertError } = await supabase
          .from('tipos_indexacion_salidas')
          .insert([{ tipo_indexacion: nuevoTipoIndexacion.trim() }]);

        if (insertError) throw insertError;
      }

      // Actualizar lista local
      if (!tiposIndexacion.includes(nuevoTipoIndexacion.trim())) {
        setTiposIndexacion([...tiposIndexacion, nuevoTipoIndexacion.trim()]);
      }

      // Establecer como tipo seleccionado
      setTipoIndexacion(nuevoTipoIndexacion.trim());
      setNuevoTipoIndexacion('');

    } catch (error) {
      console.error('Error al agregar tipo de indexación:', error);
      setSnackbar({
        open: true,
        message: 'Error al agregar tipo de indexación: ' + error.message,
        severity: 'error'
      });
    }
  };

  const handleAgregarSalida = async () => {
    // Validar campos obligatorios
    if (!fechaSalida || !cajaOrigen || !proveedor || !codigoProyecto || !concepto || !etapa || !subetapa || !tipoCambio || !moneda || !monto) {
      setSnackbar({
        open: true,
        message: 'Por favor, completa todos los campos obligatorios',
        severity: 'error'
      });
      return;
    }

    // Validar factura si tiene factura
    if (tieneFactura && !facturaImagen) {
      setSnackbar({
        open: true,
        message: 'Por favor, sube una imagen de la factura',
        severity: 'error'
      });
      return;
    }

    // Validar nombre de factura si tiene factura
    if (tieneFactura && !facturaANombreDe) {
      setSnackbar({
        open: true,
        message: 'Por favor, selecciona a nombre de quién está la factura',
        severity: 'error'
      });
      return;
    }

    // Validar comprobante si tiene comprobante
    if (tieneComprobante && !comprobanteImagen) {
      setSnackbar({
        open: true,
        message: 'Por favor, sube una imagen del comprobante',
        severity: 'error'
      });
      return;
    }

    // Validar indexación si tiene indexación
    if (tieneIndexacion && (!tipoIndexacion || tipoIndexacion === '')) {
      setSnackbar({
        open: true,
        message: 'Por favor, selecciona o ingresa un tipo de indexación',
        severity: 'error'
      });
      return;
    }

    // Validar indexación si tiene indexación
    if (tieneIndexacion && (!tcIndexado || parseFloat(tcIndexado) <= 0)) {
      setSnackbar({
        open: true,
        message: 'Por favor, ingresa un tipo de cambio válido para la indexación',
        severity: 'error'
      });
      return;
    }

    try {
      setLoading(true);

      let imageDataComprobante = null;
      let imageDataFactura = null;

      // Subir comprobante si existe
      if (tieneComprobante && comprobanteImagen) {
        try {
          imageDataComprobante = await uploadImage(comprobanteImagen, {
            tipo: 'comprobante',
            referencia: codigoProyecto,
            concepto: concepto
          });
        } catch (uploadError) {
          console.error('Error al subir el comprobante:', uploadError);
          setSnackbar({
            open: true,
            message: 'Error al subir el comprobante: ' + uploadError.message,
            severity: 'error'
          });
          setLoading(false);
          return;
        }
      }

      // Subir factura si existe
      if (tieneFactura && facturaImagen) {
        try {
          imageDataFactura = await uploadImage(facturaImagen, {
            tipo: 'factura',
            referencia: codigoProyecto,
            concepto: concepto
          });
        } catch (uploadError) {
          console.error('Error al subir la factura:', uploadError);
          setSnackbar({
            open: true,
            message: 'Error al subir la factura: ' + uploadError.message,
            severity: 'error'
          });
          setLoading(false);
          return;
        }
      }

      // Crear la salida con todos los datos
      const { error } = await supabase.from('salidas').insert([
        {
          fecha_salida: fechaSalida.toISOString(),
          caja_origen_salida: cajaOrigen,
          proveedor_salida: proveedor,
          codigo_de_proyecto_salida: codigoProyecto,
          concepto_salidas: concepto,
          etapa_de_obra_salidas: etapa,
          subetapa_de_obra_salidas: subetapa,
          tipo_de_cambio_salida: tipoCambio,
          moneda_salida: moneda,
          monto_salida: monto,
          descripcion_salida: descripcion,

          // Campos de comprobante
          tiene_comprobante_salida: tieneComprobante,
          imagen_comprobante_salida: imageDataComprobante ? imageDataComprobante.url : null,
          codigo_de_comprobante_salida: imageDataComprobante ? imageDataComprobante.codigo || codigoComprobante : null,
          ruta_archivo_comprobante_salida: imageDataComprobante ? imageDataComprobante.ruta : null,

          // Campos de factura
          tiene_factura_salida: tieneFactura,
          imagen_factura_salida: imageDataFactura ? imageDataFactura.url : null,
          codigo_de_factura_salida: imageDataFactura ? imageDataFactura.codigo || codigoFactura : null,
          ruta_archivo_factura_salida: imageDataFactura ? imageDataFactura.ruta : null,
          factura_a_nombre_de_salida: tieneFactura ? facturaANombreDe : null,

          // Campos de indexación
          tiene_indexacion: tieneIndexacion,
          tipo_indexacion: tieneIndexacion ? tipoIndexacion : null,
          tc_indexado: tieneIndexacion ? tcIndexado : null,
          monto_indexado: tieneIndexacion ? montoIndexado : null
        },
      ]);

      if (error) {
        throw new Error('Error al agregar salida: ' + error.message);
      }

      // Si se agregó un nuevo tipo de indexación, guardarlo en la base de datos
      if (tieneIndexacion && tipoIndexacion && !tiposIndexacion.includes(tipoIndexacion)) {
        try {
          await supabase.from('tipos_indexacion_salidas').insert([
            { tipo_indexacion: tipoIndexacion }
          ]);
        } catch (error) {
          console.error('Error al guardar tipo de indexación:', error);
        }
      }

      fetchSalidas();
      setOpenAddSalida(false);
      resetForm();
      setSnackbar({
        open: true,
        message: 'Salida agregada con éxito',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error adding salida:', error.message);
      setSnackbar({
        open: true,
        message: error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOpenDetailDialog = (salida) => {
    setSelectedSalida(salida);
    setOpenDetailDialog(true);
  };

  const handleEditarSalida = (fila, event) => {
    event.stopPropagation(); // Evitar que se abra el diálogo de detalles

    // Configurar estados básicos
    setFilaEditando({ ...fila });
    fetchSubetapas(fila.etapa_de_obra_salidas);

    // Configurar estados de comprobante
    setTieneComprobante(fila.tiene_comprobante_salida || false);
    setCodigoComprobante(fila.codigo_de_comprobante_salida || '');
    setComprobantePreview(fila.imagen_comprobante_salida || '');

    // Configurar estados de factura
    setTieneFactura(fila.tiene_factura_salida || false);
    setCodigoFactura(fila.codigo_de_factura_salida || '');
    setFacturaPreview(fila.imagen_factura_salida || '');
    setFacturaANombreDe(fila.factura_a_nombre_de_salida || '');

    // Configurar estados de indexación
    setTieneIndexacion(fila.tiene_indexacion || false);
    setTipoIndexacion(fila.tipo_indexacion || '');
    setTcIndexado(fila.tc_indexado || '');
    setMontoIndexado(fila.monto_indexado || '');

    // Configurar descripción
    setDescripcion(fila.descripcion_salida || '');

    // Abrir modal de edición
    setOpenEditSalida(true);
  };

  const handleShowImage = (imageInfo, event) => {
    event.stopPropagation(); // Evitar que se abra el diálogo de detalles

    // Si es un objeto completo, extraer la información necesaria
    if (typeof imageInfo === 'object' && imageInfo !== null && !Array.isArray(imageInfo)) {
      let imageUrl, imageCode, imageType;

      // Determinar si es un comprobante o una factura
      if (imageInfo.tipo === 'comprobante') {
        imageUrl = imageInfo.imagen_comprobante_salida;
        imageCode = imageInfo.codigo_de_comprobante_salida;
        imageType = 'Comprobante';
      } else if (imageInfo.tipo === 'factura') {
        imageUrl = imageInfo.imagen_factura_salida;
        imageCode = imageInfo.codigo_de_factura_salida;
        imageType = 'Factura';
      } else {
        // Si no se especifica el tipo, intentar determinar por el contenido
        if (imageInfo.imagen_comprobante_salida) {
          imageUrl = imageInfo.imagen_comprobante_salida;
          imageCode = imageInfo.codigo_de_comprobante_salida;
          imageType = 'Comprobante';
        } else if (imageInfo.imagen_factura_salida) {
          imageUrl = imageInfo.imagen_factura_salida;
          imageCode = imageInfo.codigo_de_factura_salida;
          imageType = 'Factura';
        }
      }

      setCurrentImage({
        url: imageUrl,
        codigo: imageCode,
        tipo: imageType,
        fecha: imageInfo.fecha_formateada,
        concepto: imageInfo.concepto_salidas
      });
    } else {
      // Si es solo la URL, usarla directamente
      setCurrentImage({ url: imageInfo });
    }

    setOpenImagePreview(true);
  };

  const handleActualizarSalida = async () => {
    if (!filaEditando) {
      return;
    }

    try {
      setLoading(true);

      let imageDataComprobante = null;
      let imageDataFactura = null;

      // Variables para mantener los datos existentes o actualizarlos
      let imagen_comprobante_salida = filaEditando.imagen_comprobante_salida;
      let ruta_archivo_comprobante_salida = filaEditando.ruta_archivo_comprobante_salida;
      let codigo_de_comprobante_salida = filaEditando.codigo_de_comprobante_salida;

      let imagen_factura_salida = filaEditando.imagen_factura_salida;
      let ruta_archivo_factura_salida = filaEditando.ruta_archivo_factura_salida;
      let codigo_de_factura_salida = filaEditando.codigo_de_factura_salida;

      // Verificar si hay una nueva imagen de comprobante
      if (tieneComprobante && comprobanteImagen) {
        // Eliminar el comprobante anterior si existe
        if (imagen_comprobante_salida && ruta_archivo_comprobante_salida) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([ruta_archivo_comprobante_salida]);
          } catch (deleteError) {
            console.error('Error eliminando comprobante anterior:', deleteError);
            // Continuamos aunque falle la eliminación
          }
        }

        try {
          imageDataComprobante = await uploadImage(comprobanteImagen, {
            tipo: 'comprobante',
            referencia: filaEditando.codigo_de_proyecto_salida,
            concepto: filaEditando.concepto_salidas
          });

          imagen_comprobante_salida = imageDataComprobante.url;
          ruta_archivo_comprobante_salida = imageDataComprobante.ruta;
          codigo_de_comprobante_salida = imageDataComprobante.codigo || codigoComprobante;
        } catch (uploadError) {
          console.error('Error al subir el comprobante:', uploadError);
          setSnackbar({
            open: true,
            message: 'Error al subir el comprobante: ' + uploadError.message,
            severity: 'error'
          });
          setLoading(false);
          return;
        }
      } else if (!tieneComprobante) {
        // Si ya no tiene comprobante, eliminarlo
        if (imagen_comprobante_salida && ruta_archivo_comprobante_salida) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([ruta_archivo_comprobante_salida]);
          } catch (deleteError) {
            console.error('Error eliminando comprobante:', deleteError);
            // Continuamos aunque falle la eliminación
          }
        }

        imagen_comprobante_salida = null;
        ruta_archivo_comprobante_salida = null;
        codigo_de_comprobante_salida = null;
      }

      // Verificar si hay una nueva imagen de factura
      if (tieneFactura && facturaImagen) {
        // Eliminar la factura anterior si existe
        if (imagen_factura_salida && ruta_archivo_factura_salida) {
          try {
            await supabase.storage
              .from('Facturas')
              .remove([ruta_archivo_factura_salida]);
          } catch (deleteError) {
            console.error('Error eliminando factura anterior:', deleteError);
            // Continuamos aunque falle la eliminación
          }
        }

        try {
          imageDataFactura = await uploadImage(facturaImagen, {
            tipo: 'factura',
            referencia: filaEditando.codigo_de_proyecto_salida,
            concepto: filaEditando.concepto_salidas
          });

          imagen_factura_salida = imageDataFactura.url;
          ruta_archivo_factura_salida = imageDataFactura.ruta;
          codigo_de_factura_salida = imageDataFactura.codigo || codigoFactura;
        } catch (uploadError) {
          console.error('Error al subir la factura:', uploadError);
          setSnackbar({
            open: true,
            message: 'Error al subir la factura: ' + uploadError.message,
            severity: 'error'
          });
          setLoading(false);
          return;
        }
      } else if (!tieneFactura) {
        // Si ya no tiene factura, eliminarla
        if (imagen_factura_salida && ruta_archivo_factura_salida) {
          try {
            await supabase.storage
              .from('Facturas')
              .remove([ruta_archivo_factura_salida]);
          } catch (deleteError) {
            console.error('Error eliminando factura:', deleteError);
            // Continuamos aunque falle la eliminación
          }
        }

        imagen_factura_salida = null;
        ruta_archivo_factura_salida = null;
        codigo_de_factura_salida = null;
        filaEditando.factura_a_nombre_de_salida = null;
      }

      // Actualizar la salida con todos los datos
      const { error } = await supabase
        .from('salidas')
        .update({
          fecha_salida: filaEditando.fecha_salida,
          caja_origen_salida: filaEditando.caja_origen_salida,
          proveedor_salida: filaEditando.proveedor_salida,
          codigo_de_proyecto_salida: filaEditando.codigo_de_proyecto_salida,
          concepto_salidas: filaEditando.concepto_salidas,
          etapa_de_obra_salidas: filaEditando.etapa_de_obra_salidas,
          subetapa_de_obra_salidas: filaEditando.subetapa_de_obra_salidas,
          tipo_de_cambio_salida: filaEditando.tipo_de_cambio_salida,
          moneda_salida: filaEditando.moneda_salida,
          monto_salida: filaEditando.monto_salida,
          descripcion_salida: descripcion,

          // Campos de comprobante
          tiene_comprobante_salida: tieneComprobante,
          imagen_comprobante_salida,
          codigo_de_comprobante_salida,
          ruta_archivo_comprobante_salida,

          // Campos de factura
          tiene_factura_salida: tieneFactura,
          imagen_factura_salida,
          codigo_de_factura_salida,
          ruta_archivo_factura_salida,
          factura_a_nombre_de_salida: tieneFactura ? facturaANombreDe || filaEditando.factura_a_nombre_de_salida : null,

          // Campos de indexación
          tiene_indexacion: tieneIndexacion,
          tipo_indexacion: tieneIndexacion ? tipoIndexacion : null,
          tc_indexado: tieneIndexacion ? tcIndexado : null,
          monto_indexado: tieneIndexacion ? montoIndexado : null
        })
        .eq('id', filaEditando.id);

      if (error) {
        throw new Error('Error al actualizar salida: ' + error.message);
      }

      // Si se agregó un nuevo tipo de indexación, guardarlo en la base de datos
      if (tieneIndexacion && tipoIndexacion && !tiposIndexacion.includes(tipoIndexacion)) {
        try {
          await supabase.from('tipos_indexacion_salidas').insert([
            { tipo_indexacion: tipoIndexacion }
          ]);
          // Actualizar la lista local
          setTiposIndexacion([...tiposIndexacion, tipoIndexacion]);
        } catch (error) {
          console.error('Error al guardar tipo de indexación:', error);
        }
      }

      fetchSalidas();
      setOpenEditSalida(false);
      setSnackbar({
        open: true,
        message: 'Salida actualizada con éxito',
        severity: 'success'
      });
    }
    catch (error) {
      console.error('Error updating salida:', error.message);
      setSnackbar({
        open: true,
        message: error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEliminarSalida = async (id, rutaComprobante, rutaFactura, event) => {
    event.stopPropagation(); // Evitar que se abra el diálogo de detalles

    if (window.confirm('¿Estás seguro de que deseas eliminar esta salida?')) {
      try {
        // Eliminar los archivos de almacenamiento si existen
        if (rutaComprobante) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([rutaComprobante]);
          } catch (deleteError) {
            console.error('Error eliminando archivo de comprobante:', deleteError);
            // Continuamos aunque falle la eliminación
          }
        }

        if (rutaFactura) {
          try {
            await supabase.storage
              .from('Facturas')
              .remove([rutaFactura]);
          } catch (deleteError) {
            console.error('Error eliminando archivo de factura:', deleteError);
            // Continuamos aunque falle la eliminación
          }
        }

        // Eliminar el registro
        const { error } = await supabase.from('salidas').delete().eq('id', id);

        if (error) {
          console.error('Error deleting salida:', error.message);
          setSnackbar({
            open: true,
            message: 'Error al eliminar salida: ' + error.message,
            severity: 'error'
          });
        } else {
          fetchSalidas();
          setSnackbar({
            open: true,
            message: 'Salida eliminada con éxito',
            severity: 'success'
          });
        }
      } catch (error) {
        console.error('Error en eliminación:', error);
        setSnackbar({
          open: true,
          message: 'Error al procesar la solicitud',
          severity: 'error'
        });
      }
    }
  };

  const handleAgregarNuevoNombre = async () => {
    if (!nuevoNombreFactura.trim()) {
      setSnackbar({
        open: true,
        message: 'Por favor, ingrese un nombre válido',
        severity: 'error'
      });
      return;
    }

    try {
      const { data, error } = await supabase.from('facturas_a_nombre_de_salidas').insert([
        { nombre_factura: nuevoNombreFactura.trim() }
      ]);

      if (error) {
        throw error;
      }

      fetchNombresFacturas();
      setFacturaANombreDe(nuevoNombreFactura.trim());
      setNuevoNombreFactura('');
      setAddingNewNombre(false);

      setSnackbar({
        open: true,
        message: 'Nombre agregado con éxito',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error al agregar nuevo nombre:', error.message);
      setSnackbar({
        open: true,
        message: 'Error al agregar nuevo nombre: ' + error.message,
        severity: 'error'
      });
    }
  };

  const adjustTipoCambio = (amount) => {
    const currentValue = parseFloat(tipoCambio) || 0;
    const newValue = Math.max(0, currentValue + amount);
    setTipoCambio(newValue.toString());
  };

  const resetForm = () => {
    setFechaSalida(new Date());
    setCajaOrigen('');
    setProveedor('');
    setCodigoProyecto('');
    setConcepto('');
    setEtapa('');
    setSubetapa('');
    setTipoCambio('');
    setMoneda('ARS');
    setMonto('');
    setDescripcion('');

    // Resetear estados de comprobante
    setTieneComprobante(false);
    setComprobanteImagen(null);
    setComprobantePreview('');
    setCodigoComprobante('');
    if (comprobanteInputRef.current) {
      comprobanteInputRef.current.value = '';
    }

    // Resetear estados de factura
    setTieneFactura(false);
    setFacturaImagen(null);
    setFacturaPreview('');
    setCodigoFactura('');
    setFacturaANombreDe('');
    setNuevoNombreFactura('');
    setAddingNewNombre(false);
    if (facturaInputRef.current) {
      facturaInputRef.current.value = '';
    }

    // Resetear estados de indexación
    setTieneIndexacion(false);
    setTipoIndexacion('');
    setNuevoTipoIndexacion('');
    setTcIndexado('');
    setMontoIndexado('');

    // Resetear nuevos estados
    setNuevoProveedor('');
    setNuevaSubetapa('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const applyFilters = () => {
    let filtered = [...salidas];

    // Búsqueda global
    if (searchTerm) {
      filtered = filtered.filter(salida =>
        Object.values(salida).some(value =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Aplicar filtros activos
    activeFilters.forEach(filter => {
      if (filter.type === 'fecha_desde' && filter.value) {
        filtered = filtered.filter(salida =>
          new Date(salida.fecha_salida) >= new Date(filter.value)
        );
      } else if (filter.type === 'fecha_hasta' && filter.value) {
        filtered = filtered.filter(salida =>
          new Date(salida.fecha_salida) <= new Date(filter.value)
        );
      } else if (filter.type === 'tiene_comprobante' && filter.value !== null) {
        filtered = filtered.filter(salida =>
          salida.tiene_comprobante_salida === filter.value
        );
      } else if (filter.type === 'tiene_factura' && filter.value !== null) {
        filtered = filtered.filter(salida =>
          salida.tiene_factura_salida === filter.value
        );
      } else if (filter.type === 'tiene_indexacion' && filter.value !== null) {
        filtered = filtered.filter(salida =>
          salida.tiene_indexacion === filter.value
        );
      } else if (filter.type === 'codigo_comprobante' && filter.value) {
        filtered = filtered.filter(salida =>
          salida.codigo_de_comprobante_salida &&
          salida.codigo_de_comprobante_salida.toLowerCase().includes(filter.value.toLowerCase())
        );
      } else if (filter.type === 'codigo_factura' && filter.value) {
        filtered = filtered.filter(salida =>
          salida.codigo_de_factura_salida &&
          salida.codigo_de_factura_salida.toLowerCase().includes(filter.value.toLowerCase())
        );
      } else if (filter.value) {
        filtered = filtered.filter(salida => {
          const fieldMap = {
            caja_origen: 'caja_origen_salida',
            proveedor: 'proveedor_salida',
            proyecto: 'codigo_de_proyecto_salida',
            concepto: 'concepto_salidas',
            etapa: 'etapa_de_obra_salidas',
          };
          const field = fieldMap[filter.type];
          return salida[field] === filter.value;
        });
      }
    });

    setFilteredSalidas(filtered);
  };

  const addFilter = () => {
    const newFilters = [];

    if (filters.caja_origen) {
      newFilters.push({ type: 'caja_origen', value: filters.caja_origen, label: `Caja: ${filters.caja_origen}` });
    }

    if (filters.proveedor) {
      newFilters.push({ type: 'proveedor', value: filters.proveedor, label: `Proveedor: ${filters.proveedor}` });
    }

    if (filters.proyecto) {
      newFilters.push({ type: 'proyecto', value: filters.proyecto, label: `Proyecto: ${filters.proyecto}` });
    }

    if (filters.concepto) {
      newFilters.push({ type: 'concepto', value: filters.concepto, label: `Concepto: ${filters.concepto}` });
    }

    if (filters.etapa) {
      newFilters.push({ type: 'etapa', value: filters.etapa, label: `Etapa: ${filters.etapa}` });
    }

    if (filters.fecha_desde) {
      newFilters.push({ type: 'fecha_desde', value: filters.fecha_desde, label: `Desde: ${format(new Date(filters.fecha_desde), 'dd/MM/yyyy')}` });
    }

    if (filters.fecha_hasta) {
      newFilters.push({ type: 'fecha_hasta', value: filters.fecha_hasta, label: `Hasta: ${format(new Date(filters.fecha_hasta), 'dd/MM/yyyy')}` });
    }

    if (filters.tiene_comprobante !== null) {
      newFilters.push({
        type: 'tiene_comprobante',
        value: filters.tiene_comprobante,
        label: `Comprobante: ${filters.tiene_comprobante ? 'Con comprobante' : 'Sin comprobante'}`
      });
    }

    if (filters.tiene_factura !== null) {
      newFilters.push({
        type: 'tiene_factura',
        value: filters.tiene_factura,
        label: `Factura: ${filters.tiene_factura ? 'Con factura' : 'Sin factura'}`
      });
    }

    if (filters.tiene_indexacion !== null) {
      newFilters.push({
        type: 'tiene_indexacion',
        value: filters.tiene_indexacion,
        label: `Indexación: ${filters.tiene_indexacion ? 'Con indexación' : 'Sin indexación'}`
      });
    }

    if (filters.codigo_comprobante) {
      newFilters.push({
        type: 'codigo_comprobante',
        value: filters.codigo_comprobante,
        label: `Código Comprobante: ${filters.codigo_comprobante}`
      });
    }

    if (filters.codigo_factura) {
      newFilters.push({
        type: 'codigo_factura',
        value: filters.codigo_factura,
        label: `Código Factura: ${filters.codigo_factura}`
      });
    }

    setActiveFilters([...activeFilters, ...newFilters]);

    // Resetear los filtros
    setFilters({
      caja_origen: '',
      proveedor: '',
      proyecto: '',
      concepto: '',
      etapa: '',
      fecha_desde: null,
      fecha_hasta: null,
      tiene_comprobante: null,
      tiene_factura: null,
      tiene_indexacion: null,
      codigo_comprobante: '',
      codigo_factura: '',
    });

    setOpenFilters(false);
  };

  const removeFilter = (index) => {
    const updatedFilters = [...activeFilters];
    updatedFilters.splice(index, 1);
    setActiveFilters(updatedFilters);
  };

  const removeAllFilters = () => {
    setActiveFilters([]);
    setSearchTerm('');
  };

  const exportToCSV = () => {
    // Preparar los datos para exportación
    const csvContent = [
      // Encabezados
      ['ID', 'Fecha', 'Caja Origen', 'Proveedor', 'Proyecto', 'Etapa', 'Subetapa', 'Tipo de Cambio', 'Moneda', 'Monto', 'Descripción', 'Tiene Comprobante', 'Código Comprobante', 'Tiene Factura', 'Código Factura', 'Factura a nombre de', 'Tiene Indexación', 'Tipo Indexación', 'TC Indexado', 'Monto Indexado'],
      // Datos
      ...filteredSalidas.map(salida => [
        salida.id,
        salida.fecha_formateada,
        salida.caja_origen_salida,
        salida.proveedor_salida,
        salida.codigo_de_proyecto_salida,
        salida.etapa_de_obra_salidas,
        salida.subetapa_de_obra_salidas,
        salida.tipo_de_cambio_salida,
        salida.moneda_salida,
        salida.monto_salida,
        salida.descripcion_salida || '',
        salida.tiene_comprobante_salida ? 'Sí' : 'No',
        salida.codigo_de_comprobante_salida || '',
        salida.tiene_factura_salida ? 'Sí' : 'No',
        salida.codigo_de_factura_salida || '',
        salida.factura_a_nombre_de_salida || '',
        salida.tiene_indexacion ? 'Sí' : 'No',
        salida.tipo_indexacion || '',
        salida.tc_indexado || '',
        salida.monto_indexado || ''
      ])
    ].map(row => row.join(',')).join('\n');

    // Crear un blob y descargar
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `salidas_${format(new Date(), 'yyyyMMdd')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" fontWeight="bold" color="#2e7d32">
              Salidas
            </Typography>
            <Tooltip title="Agregar nueva salida">
              <AddButton
                onClick={() => setOpenAddSalida(true)}
                sx={{ ml: 2 }}
              >
                <AddIcon />
              </AddButton>
            </Tooltip>
          </Box>
          <Tooltip title="Exportar a CSV">
            <ExportButton
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={exportToCSV}
            >
              Exportar
            </ExportButton>
          </Tooltip>
        </Box>

        {/* Barra de búsqueda y filtros */}
        <StyledPaper elevation={0} sx={{ mb: 3, p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
            <TextField
              placeholder="Buscar salidas..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                flex: 1,
                minWidth: 250,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 12,
                  '&:hover fieldset': {
                    borderColor: '#2e7d32',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#2e7d32' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<FilterListIcon />}
              onClick={() => setOpenFilters(!openFilters)}
              sx={{
                borderRadius: 12,
                borderColor: '#2e7d32',
                color: '#2e7d32',
                '&:hover': {
                  borderColor: '#1b5e20',
                  backgroundColor: 'rgba(46, 125, 50, 0.04)',
                },
              }}
            >
              Filtros
            </Button>
            {activeFilters.length > 0 && (
              <Button
                variant="text"
                size="small"
                onClick={removeAllFilters}
                sx={{
                  color: '#2e7d32',
                  '&:hover': { backgroundColor: 'rgba(46, 125, 50, 0.04)' },
                }}
              >
                Limpiar filtros
              </Button>
            )}
          </Box>

          <Collapse in={openFilters}>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StyledFormControl fullWidth size="small">
                    <InputLabel>Caja Origen</InputLabel>
                    <Select
                      value={filters.caja_origen}
                      onChange={(e) => setFilters({ ...filters, caja_origen: e.target.value })}
                      label="Caja Origen"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {cajasOrigen.map((caja) => (
                        <MenuItem key={caja} value={caja}>{caja}</MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StyledFormControl fullWidth size="small">
                    <InputLabel>Proveedor</InputLabel>
                    <Select
                      value={filters.proveedor}
                      onChange={(e) => setFilters({ ...filters, proveedor: e.target.value })}
                      label="Proveedor"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {proveedores.map((prov) => (
                        <MenuItem key={prov} value={prov}>{prov}</MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StyledFormControl fullWidth size="small">
                    <InputLabel>Proyecto</InputLabel>
                    <Select
                      value={filters.proyecto}
                      onChange={(e) => setFilters({ ...filters, proyecto: e.target.value })}
                      label="Proyecto"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {proyectos.map((proy) => (
                        <MenuItem key={proy} value={proy}>{proy}</MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StyledFormControl fullWidth size="small">
                    <InputLabel>Etapa</InputLabel>
                    <Select
                      value={filters.etapa}
                      onChange={(e) => setFilters({ ...filters, etapa: e.target.value })}
                      label="Etapa"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {etapas.map((etapa) => (
                        <MenuItem key={etapa} value={etapa}>{etapa}</MenuItem>
                      ))}
                    </Select>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StyledFormControl fullWidth size="small">
                    <InputLabel>Comprobante</InputLabel>
                    <Select
                      value={filters.tiene_comprobante === null ? '' : filters.tiene_comprobante}
                      onChange={(e) => {
                        const value = e.target.value === '' ? null : e.target.value === 'true';
                        setFilters({ ...filters, tiene_comprobante: value });
                      }}
                      label="Comprobante"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      <MenuItem value="true">Con comprobante</MenuItem>
                      <MenuItem value="false">Sin comprobante</MenuItem>
                    </Select>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StyledFormControl fullWidth size="small">
                    <InputLabel>Factura</InputLabel>
                    <Select
                      value={filters.tiene_factura === null ? '' : filters.tiene_factura}
                      onChange={(e) => {
                        const value = e.target.value === '' ? null : e.target.value === 'true';
                        setFilters({ ...filters, tiene_factura: value });
                      }}
                      label="Factura"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      <MenuItem value="true">Con factura</MenuItem>
                      <MenuItem value="false">Sin factura</MenuItem>
                    </Select>
                  </StyledFormControl>
                </Grid>
              </Grid>

              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StyledFormControl fullWidth size="small">
                    <InputLabel>Indexación</InputLabel>
                    <Select
                      value={filters.tiene_indexacion === null ? '' : filters.tiene_indexacion}
                      onChange={(e) => {
                        const value = e.target.value === '' ? null : e.target.value === 'true';
                        setFilters({ ...filters, tiene_indexacion: value });
                      }}
                      label="Indexación"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      <MenuItem value="true">Con indexación</MenuItem>
                      <MenuItem value="false">Sin indexación</MenuItem>
                    </Select>
                  </StyledFormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StyledTextField
                    fullWidth
                    size="small"
                    label="Código de comprobante"
                    value={filters.codigo_comprobante || ''}
                    onChange={(e) => setFilters({ ...filters, codigo_comprobante: e.target.value })}
                    placeholder="Buscar por código"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <StyledTextField
                    fullWidth
                    size="small"
                    label="Código de factura"
                    value={filters.codigo_factura || ''}
                    onChange={(e) => setFilters({ ...filters, codigo_factura: e.target.value })}
                    placeholder="Buscar por código"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={addFilter}
                    sx={{
                      height: '40px',
                      borderRadius: 12,
                      backgroundColor: '#2e7d32',
                      '&:hover': { backgroundColor: '#1b5e20' },
                    }}
                  >
                    Aplicar Filtros
                  </Button>
                </Grid>
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Desde"
                      value={filters.fecha_desde}
                      onChange={(newValue) => setFilters({ ...filters, fecha_desde: newValue })}
                      renderInput={(params) => <StyledTextField size="small" {...params} fullWidth />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Hasta"
                      value={filters.fecha_hasta}
                      onChange={(newValue) => setFilters({ ...filters, fecha_hasta: newValue })}
                      renderInput={(params) => <StyledTextField size="small" {...params} fullWidth />}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </Box>
          </Collapse>

          {/* Chips de filtros activos */}
          {activeFilters.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', pt: 2 }}>
              {activeFilters.map((filter, index) => (
                <FilterChip
                  key={index}
                  label={filter.label}
                  onDelete={() => removeFilter(index)}
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
            </Box>
          )}
        </StyledPaper>

        {/* Tabla de salidas */}
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <StyledPaper elevation={0}>
              <TableContainer sx={{ maxHeight: 'calc(100vh - 250px)' }}>
                <Table stickyHeader aria-label="tabla de salidas">
                  <TableHead>
                    <TableRow>
                      <StyledTableHeaderCell>Fecha</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Caja Origen</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Proveedor</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Proyecto</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Etapa</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Monto</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Comprobante</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Factura</StyledTableHeaderCell>
                      <StyledTableHeaderCell align="center">Acciones</StyledTableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredSalidas
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((salida) => (
                        <StyledTableRow
                          key={salida.id}
                          onClick={() => handleOpenDetailDialog(salida)}
                        >
                          <StyledTableCell>
                            {salida.fecha_formateada}
                          </StyledTableCell>
                          <StyledTableCell>{salida.caja_origen_salida}</StyledTableCell>
                          <StyledTableCell>{salida.proveedor_salida}</StyledTableCell>
                          <StyledTableCell>{salida.codigo_de_proyecto_salida}</StyledTableCell>
                          <StyledTableCell>{salida.etapa_de_obra_salidas}</StyledTableCell>
                          <StyledTableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <MoneyIcon sx={{ color: salida.moneda_salida === 'USD' ? '#2e7d32' : '#1976d2', mr: 1, fontSize: '1rem' }} />
                              <Typography sx={{ fontWeight: 'medium' }}>
                                {salida.monto_formateado}
                              </Typography>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            {salida.tiene_comprobante_salida ? (
                              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <ImagePreview
                                  src={salida.imagen_comprobante_salida}
                                  onClick={(e) => handleShowImage({ ...salida, tipo: 'comprobante' }, e)}
                                  thumbSize="medium"
                                  alt={`Comprobante ${salida.codigo_de_comprobante_salida || ""}`}
                                  sx={{ mb: 1 }}
                                />
                              </Box>
                            ) : (
                              <Typography variant="body2" color="text.secondary">
                                No
                              </Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {salida.tiene_factura_salida ? (
                              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <ImagePreview
                                  src={salida.imagen_factura_salida}
                                  onClick={(e) => handleShowImage({ ...salida, tipo: 'factura' }, e)}
                                  thumbSize="medium"
                                  alt={`Factura ${salida.codigo_de_factura_salida || ""}`}
                                  sx={{ mb: 1 }}
                                />
                              </Box>
                            ) : (
                              <Typography variant="body2" color="text.secondary">
                                No
                              </Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <Tooltip title="Editar">
                                <ActionButton
                                  size="small"
                                  onClick={(e) => handleEditarSalida(salida, e)}
                                >
                                  <EditIcon fontSize="small" />
                                </ActionButton>
                              </Tooltip>
                              <Tooltip title="Eliminar">
                                <ActionButton
                                  size="small"
                                  onClick={(e) => handleEliminarSalida(
                                    salida.id,
                                    salida.ruta_archivo_comprobante_salida,
                                    salida.ruta_archivo_factura_salida,
                                    e
                                  )}
                                  sx={{ color: '#d32f2f' }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </ActionButton>
                              </Tooltip>
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={filteredSalidas.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página:"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                sx={{
                  borderTop: '1px solid rgba(224, 224, 224, 1)',
                  '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                    color: '#424242'
                  },
                  '.MuiTablePagination-select': {
                    color: '#2e7d32'
                  }
                }}
              />
            </StyledPaper>
          </motion.div>
        </AnimatePresence>
      </motion.div>

      {/* Diálogo de detalles de salida */}
      <DetailDialog
        open={openDetailDialog}
        onClose={() => setOpenDetailDialog(false)}
        maxWidth="md"
      >
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2
        }}>
          <Typography variant="h5" fontWeight="bold" color="#2e7d32">
            Detalles de la Salida
          </Typography>
          <IconButton onClick={() => setOpenDetailDialog(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        {selectedSalida && (
          <Box>
            <DetailCard>
              <DetailCardContent>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <CalendarTodayIcon sx={{ color: '#2e7d32', mr: 1 }} />
                      <Typography variant="subtitle1" fontWeight="bold">
                        Fecha
                      </Typography>
                    </Box>
                    <Typography variant="body1">{selectedSalida.fecha_formateada}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <AccountBalanceIcon sx={{ color: '#2e7d32', mr: 1 }} />
                      <Typography variant="subtitle1" fontWeight="bold">
                        Caja Origen
                      </Typography>
                    </Box>
                    <Typography variant="body1">{selectedSalida.caja_origen_salida}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                      <InfoIcon sx={{ color: '#2e7d32', mr: 1 }} />
                      <Typography variant="subtitle1" fontWeight="bold">
                        Proveedor
                      </Typography>
                    </Box>
                    <Typography variant="body1">{selectedSalida.proveedor_salida}</Typography>
                  </Grid>
                </Grid>
              </DetailCardContent>
            </DetailCard>

            <DetailCard>
              <DetailCardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <AssignmentIcon sx={{ color: '#2e7d32', mr: 1 }} />
                  <Typography variant="subtitle1" fontWeight="bold">
                    Información del Proyecto
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" color="text.secondary">Código de Proyecto</Typography>
                    <Typography variant="body1" fontWeight="medium">{selectedSalida.codigo_de_proyecto_salida}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" color="text.secondary">Etapa</Typography>
                    <Typography variant="body1">{selectedSalida.etapa_de_obra_salidas}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" color="text.secondary">Subetapa</Typography>
                    <Typography variant="body1">{selectedSalida.subetapa_de_obra_salidas}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" color="text.secondary">Concepto</Typography>
                    <Typography variant="body1">{selectedSalida.concepto_salidas}</Typography>
                  </Grid>
                  {selectedSalida.descripcion_salida && (
                    <Grid item xs={12}>
                      <Typography variant="body2" color="text.secondary">Descripción</Typography>
                      <Typography variant="body1" sx={{ whiteSpace: 'pre-wrap' }}>{selectedSalida.descripcion_salida}</Typography>
                    </Grid>
                  )}
                </Grid>
              </DetailCardContent>
            </DetailCard>

            <DetailCard>
              <DetailCardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <AttachMoneyIcon sx={{ color: '#2e7d32', mr: 1 }} />
                  <Typography variant="subtitle1" fontWeight="bold">
                    Información Financiera
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" color="text.secondary">Moneda</Typography>
                    <MontoChip
                      label={selectedSalida.moneda_salida}
                      color={selectedSalida.moneda_salida === 'USD' ? 'success' : 'primary'}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" color="text.secondary">Tipo de Cambio</Typography>
                    <Typography variant="body1" fontWeight="medium">{selectedSalida.tipo_de_cambio_salida}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="body2" color="text.secondary">Monto</Typography>
                    <MontoChip
                      label={selectedSalida.monto_formateado}
                      color={selectedSalida.moneda_salida === 'USD' ? 'success' : 'primary'}
                      sx={{ fontWeight: 'bold' }}
                    />
                  </Grid>
                </Grid>

                {/* Información de Indexación */}
                {selectedSalida.tiene_indexacion && (
                  <Box sx={{ mt: 3, p: 2, backgroundColor: 'rgba(46, 125, 50, 0.05)', borderRadius: 2 }}>
                    <Typography variant="subtitle2" fontWeight="bold" color="#2e7d32" gutterBottom>
                      Indexación
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body2" color="text.secondary">Tipo de Indexación</Typography>
                        <Typography variant="body1">{selectedSalida.tipo_indexacion}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body2" color="text.secondary">TC Indexado</Typography>
                        <Typography variant="body1">{selectedSalida.tc_indexado}</Typography>
                      </Grid>
                      <Grid item xs={12} sm={4}>
                        <Typography variant="body2" color="text.secondary">Monto Indexado</Typography>
                        <MontoChip
                          label={`$${parseFloat(selectedSalida.monto_indexado).toFixed(2)}`}
                          color="warning"
                        />
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </DetailCardContent>
            </DetailCard>

            <Grid container spacing={2} sx={{ mt: 2 }}>
              {/* Comprobante */}
              <Grid item xs={12} md={6}>
                <DetailCard>
                  <DetailCardContent>
                    <Typography variant="subtitle1" fontWeight="bold" color="#2e7d32" gutterBottom>
                      Comprobante
                    </Typography>

                    {selectedSalida.tiene_comprobante_salida ? (
                      <Box sx={{ textAlign: 'center' }}>
                        <Box
                          sx={{
                            cursor: 'pointer',
                            mb: 2,
                            maxHeight: 300,
                            overflow: 'hidden',
                            borderRadius: 2,
                            border: '1px solid #e0e0e0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          onClick={(e) => handleShowImage({ ...selectedSalida, tipo: 'comprobante' }, e)}
                        >
                          <motion.img
                            src={selectedSalida.imagen_comprobante_salida}
                            alt="Comprobante"
                            style={{ maxWidth: '100%', maxHeight: 300, objectFit: 'contain' }}
                            whileHover={{ scale: 1.02 }}
                          />
                        </Box>

                        {selectedSalida.codigo_de_comprobante_salida && (
                          <Box sx={{
                            p: 2,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}>
                            <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                              {selectedSalida.codigo_de_comprobante_salida}
                            </Typography>
                            <Tooltip title="Copiar código">
                              <IconButton
                                size="small"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  navigator.clipboard.writeText(selectedSalida.codigo_de_comprobante_salida);
                                  setSnackbar({
                                    open: true,
                                    message: 'Código copiado al portapapeles',
                                    severity: 'success'
                                  });
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box sx={{
                        p: 3,
                        textAlign: 'center',
                        backgroundColor: '#f5f5f5',
                        borderRadius: 2
                      }}>
                        <Typography variant="body1" color="text.secondary">
                          No hay comprobante disponible
                        </Typography>
                      </Box>
                    )}
                  </DetailCardContent>
                </DetailCard>
              </Grid>

              {/* Factura */}
              <Grid item xs={12} md={6}>
                <DetailCard>
                  <DetailCardContent>
                    <Typography variant="subtitle1" fontWeight="bold" color="#2e7d32" gutterBottom>
                      Factura
                    </Typography>

                    {selectedSalida.tiene_factura_salida ? (
                      <Box sx={{ textAlign: 'center' }}>
                        <Box
                          sx={{
                            cursor: 'pointer',
                            mb: 2,
                            maxHeight: 300,
                            overflow: 'hidden',
                            borderRadius: 2,
                            border: '1px solid #e0e0e0',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                          }}
                          onClick={(e) => handleShowImage({ ...selectedSalida, tipo: 'factura' }, e)}
                        >
                          <motion.img
                            src={selectedSalida.imagen_factura_salida}
                            alt="Factura"
                            style={{ maxWidth: '100%', maxHeight: 300, objectFit: 'contain' }}
                            whileHover={{ scale: 1.02 }}
                          />
                        </Box>

                        <Grid container spacing={2}>
                          {selectedSalida.codigo_de_factura_salida && (
                            <Grid item xs={12}>
                              <Box sx={{
                                p: 2,
                                backgroundColor: '#f5f5f5',
                                borderRadius: 2,
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center'
                              }}>
                                <Typography variant="body2" sx={{ fontFamily: 'monospace' }}>
                                  {selectedSalida.codigo_de_factura_salida}
                                </Typography>
                                <Tooltip title="Copiar código">
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      navigator.clipboard.writeText(selectedSalida.codigo_de_factura_salida);
                                      setSnackbar({
                                        open: true,
                                        message: 'Código copiado al portapapeles',
                                        severity: 'success'
                                      });
                                    }}
                                  >
                                    <ContentCopyIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Grid>
                          )}

                          {selectedSalida.factura_a_nombre_de_salida && (
                            <Grid item xs={12}>
                              <Typography variant="body2" color="text.secondary">
                                A nombre de:
                              </Typography>
                              <Typography variant="body1" fontWeight="medium">
                                {selectedSalida.factura_a_nombre_de_salida}
                              </Typography>
                            </Grid>
                          )}
                        </Grid>
                      </Box>
                    ) : (
                      <Box sx={{
                        p: 3,
                        textAlign: 'center',
                        backgroundColor: '#f5f5f5',
                        borderRadius: 2
                      }}>
                        <Typography variant="body1" color="text.secondary">
                          No hay factura disponible
                        </Typography>
                      </Box>
                    )}
                  </DetailCardContent>
                </DetailCard>
              </Grid>
            </Grid>
          </Box>
        )}
      </DetailDialog>

      {/* Diálogo para agregar nueva salida */}
      <AddSalidaDialog open={openAddSalida} onClose={() => setOpenAddSalida(false)}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2
        }}>
          <Typography variant="h5" fontWeight="bold" color="#2e7d32">
            Agregar Salida
          </Typography>
          <IconButton onClick={() => setOpenAddSalida(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent sx={{ p: 0 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DatePicker
                  label="Fecha"
                  value={fechaSalida}
                  onChange={(newValue) => setFechaSalida(newValue)}
                  renderInput={(params) => <StyledTextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledFormControl fullWidth>
                  <InputLabel>Caja Origen</InputLabel>
                  <Select
                    value={cajaOrigen}
                    onChange={(e) => setCajaOrigen(e.target.value)}
                    label="Caja Origen"
                  >
                    {cajasOrigen.map((caja) => (
                      <MenuItem key={caja} value={caja}>
                        {caja}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={proveedor}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setProveedor(newValue);
                      autocompletarCamposPorProveedor(newValue); // Auto-fill fields based on provider history
                    }
                  }}
                  inputValue={nuevoProveedor}
                  onInputChange={(event, newInputValue) => {
                    setNuevoProveedor(newInputValue);
                  }}
                  options={proveedores}
                  freeSolo
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      label="Proveedor"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {nuevoProveedor && !proveedores.includes(nuevoProveedor) && (
                              <Tooltip title="Agregar nuevo proveedor">
                                <IconButton
                                  size="small"
                                  onClick={() => handleAgregarProveedor(nuevoProveedor)}
                                  sx={{ mr: 1 }}
                                >
                                  <AddIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledFormControl fullWidth>
                  <InputLabel>Proyecto</InputLabel>
                  <Select
                    value={codigoProyecto}
                    onChange={(e) => {
                      setCodigoProyecto(e.target.value);

                      // Regenerar códigos si hay imágenes
                      if (comprobanteImagen) {
                        generateCodigoComprobante(e.target.value);
                      }
                      if (facturaImagen) {
                        generateCodigoFactura(e.target.value);
                      }
                    }}
                    label="Proyecto"
                  >
                    {proyectos.map((proy) => (
                      <MenuItem key={proy} value={proy}>
                        {proy}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <StyledFormControl fullWidth>
                  <InputLabel>Concepto</InputLabel>
                  <Select
                    value={concepto}
                    onChange={(e) => setConcepto(e.target.value)}
                    label="Concepto"
                  >
                    {conceptos.map((conc) => (
                      <MenuItem key={conc} value={conc}>
                        {conc}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <StyledFormControl fullWidth>
                  <InputLabel>Etapa</InputLabel>
                  <Select
                    value={etapa}
                    onChange={(e) => {
                      setEtapa(e.target.value);
                      fetchSubetapas(e.target.value);
                      setSubetapa(''); // Reset subetapa when etapa changes
                    }}
                    label="Etapa"
                  >
                    {etapas.map((etapa) => (
                      <MenuItem key={etapa} value={etapa}>
                        {etapa}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={subetapa}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setSubetapa(newValue);
                    }
                  }}
                  inputValue={nuevaSubetapa}
                  onInputChange={(event, newInputValue) => {
                    setNuevaSubetapa(newInputValue);
                  }}
                  options={subetapas}
                  freeSolo
                  disabled={!etapa}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      label="Subetapa"
                      fullWidth
                      disabled={!etapa}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {nuevaSubetapa && etapa && !subetapas.includes(nuevaSubetapa) && (
                              <Tooltip title="Agregar nueva subetapa">
                                <IconButton
                                  size="small"
                                  onClick={() => handleAgregarSubetapa(nuevaSubetapa)}
                                  sx={{ mr: 1 }}
                                >
                                  <AddIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <StyledTextField
                    label="Tipo de Cambio"
                    value={tipoCambio || ''}
                    onChange={(e) => setTipoCambio(e.target.value)}
                    fullWidth
                    type="number"
                    inputProps={{ step: 5 }}
                    onKeyDown={(e) => {
                      if (e.key === 'ArrowUp') {
                        e.preventDefault();
                        adjustTipoCambio(5);
                      } else if (e.key === 'ArrowDown') {
                        e.preventDefault();
                        adjustTipoCambio(-5);
                      }
                    }}
                  />
                  <Button
                    onClick={() => adjustTipoCambio(5)}
                    variant="outlined"
                    sx={{ ml: 1, minWidth: '48px', color: '#2e7d32', borderColor: '#2e7d32', borderRadius: 2 }}
                  >
                    +$5
                  </Button>
                  <Button
                    onClick={() => adjustTipoCambio(-5)}
                    variant="outlined"
                    sx={{ ml: 1, minWidth: '48px', color: '#d32f2f', borderColor: '#d32f2f', borderRadius: 2 }}
                  >
                    -$5
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <StyledFormControl fullWidth>
                  <InputLabel>Moneda</InputLabel>
                  <Select
                    value={moneda}
                    onChange={(e) => setMoneda(e.target.value)}
                    label="Moneda"
                  >
                    <MenuItem value="ARS">ARS</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  label="Monto"
                  value={monto}
                  onChange={(e) => setMonto(e.target.value)}
                  fullWidth
                  type="number"
                />
              </Grid>

              <Grid item xs={12}>
                <StyledTextField
                  label="Descripción (opcional)"
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                  fullWidth
                  multiline
                  rows={3}
                  placeholder="Ingrese una descripción detallada de la salida..."
                />
              </Grid>

              {/* Sección de Comprobante */}
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }}>
                  <Chip label="Comprobante" color="primary" />
                </Divider>
                <FormControl component="fieldset">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="subtitle2">¿Tiene comprobante?</Typography>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={tieneComprobante}
                            onChange={(e) => setTieneComprobante(e.target.checked)}
                            color="primary"
                          />
                        }
                        label={tieneComprobante ? "Sí" : "No"}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>

              {tieneComprobante && (
                <>
                  <Grid item xs={12}>
                    <input
                      ref={comprobanteInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleComprobanteChange}
                      style={{ display: 'none' }}
                    />

                    {!comprobantePreview ? (
                      <FileUploadBox onClick={handleComprobanteUpload}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <UploadFileIcon sx={{ fontSize: 40, color: '#2e7d32', mb: 1 }} />
                          <Typography variant="body1" gutterBottom>
                            Haz clic para subir una imagen de comprobante
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            JPG, PNG o PDF (máx. 5MB)
                          </Typography>
                        </Box>
                      </FileUploadBox>
                    ) : (
                      <Box sx={{ position: 'relative' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            width: '100%',
                            height: 200,
                            backgroundColor: '#f8f9fa',
                            borderRadius: 12,
                            overflow: 'hidden',
                            border: '1px solid #e0e0e0',
                          }}
                        >
                          <Box
                            component="img"
                            src={comprobantePreview}
                            alt="Vista previa del comprobante"
                            sx={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain',
                            }}
                          />
                          <IconButton
                            size="small"
                            sx={{
                              position: 'absolute',
                              top: 8,
                              right: 8,
                              backgroundColor: 'rgba(255, 255, 255, 0.8)',
                              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                            }}
                            onClick={removeComprobante}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Box>

                        {codigoComprobante && (
                          <Box sx={{
                            mt: 1,
                            p: 1,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#f1f8e9',
                            borderRadius: 8
                          }}>
                            <Typography variant="caption" sx={{ fontFamily: 'monospace', mr: 1 }}>
                              {codigoComprobante}
                            </Typography>
                            <Tooltip title="Copiar código">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigator.clipboard.writeText(codigoComprobante);
                                  setSnackbar({
                                    open: true,
                                    message: 'Código copiado al portapapeles',
                                    severity: 'success'
                                  });
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Grid>
                </>
              )}

              {/* Sección de Factura */}
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }}>
                  <Chip label="Factura" color="primary" />
                </Divider>
                <FormControl component="fieldset">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="subtitle2">¿Tiene factura?</Typography>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={tieneFactura}
                            onChange={(e) => setTieneFactura(e.target.checked)}
                            color="primary"
                          />
                        }
                        label={tieneFactura ? "Sí" : "No"}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>

              {tieneFactura && (
                <>
                  <Grid item xs={12}>
                    <input
                      ref={facturaInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleFacturaChange}
                      style={{ display: 'none' }}
                    />

                    {!facturaPreview ? (
                      <FileUploadBox onClick={handleFacturaUpload}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <UploadFileIcon sx={{ fontSize: 40, color: '#2e7d32', mb: 1 }} />
                          <Typography variant="body1" gutterBottom>
                            Haz clic para subir una imagen de factura
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            JPG, PNG o PDF (máx. 5MB)
                          </Typography>
                        </Box>
                      </FileUploadBox>
                    ) : (
                      <Box sx={{ position: 'relative' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            width: '100%',
                            height: 200,
                            backgroundColor: '#f8f9fa',
                            borderRadius: 12,
                            overflow: 'hidden',
                            border: '1px solid #e0e0e0',
                          }}
                        >
                          <Box
                            component="img"
                            src={facturaPreview}
                            alt="Vista previa de la factura"
                            sx={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain',
                            }}
                          />
                          <IconButton
                            size="small"
                            sx={{
                              position: 'absolute',
                              top: 8,
                              right: 8,
                              backgroundColor: 'rgba(255, 255, 255, 0.8)',
                              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                            }}
                            onClick={removeFactura}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Box>

                        {codigoFactura && (
                          <Box sx={{
                            mt: 1,
                            p: 1,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#f1f8e9',
                            borderRadius: 8
                          }}>
                            <Typography variant="caption" sx={{ fontFamily: 'monospace', mr: 1 }}>
                              {codigoFactura}
                            </Typography>
                            <Tooltip title="Copiar código">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigator.clipboard.writeText(codigoFactura);
                                  setSnackbar({
                                    open: true,
                                    message: 'Código copiado al portapapeles',
                                    severity: 'success'
                                  });
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <StyledFormControl fullWidth>
                      <InputLabel>A nombre de</InputLabel>
                      {addingNewNombre ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                          <StyledTextField
                            value={nuevoNombreFactura}
                            onChange={(e) => setNuevoNombreFactura(e.target.value)}
                            label="Nuevo nombre"
                            fullWidth
                            size="small"
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAgregarNuevoNombre}
                            sx={{ ml: 1, minWidth: 'auto', borderRadius: 8 }}
                          >
                            <CheckIcon />
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => setAddingNewNombre(false)}
                            sx={{ ml: 1, minWidth: 'auto', borderRadius: 8 }}
                          >
                            <CloseIcon />
                          </Button>
                        </Box>
                      ) : (
                        <>
                          <Select
                            value={facturaANombreDe}
                            onChange={(e) => setFacturaANombreDe(e.target.value)}
                            disabled={addingNewNombre}
                            label="A nombre de"
                          >
                            <MenuItem value="">
                              <em>Seleccione un nombre</em>
                            </MenuItem>
                            {nombresFacturas.map((nombre) => (
                              <MenuItem key={nombre} value={nombre}>
                                {nombre}
                              </MenuItem>
                            ))}
                          </Select>
                          <Box sx={{ mt: 1, textAlign: 'right' }}>
                            <Button
                              size="small"
                              startIcon={<AddIcon />}
                              onClick={() => setAddingNewNombre(true)}
                              sx={{ borderRadius: 8 }}
                            >
                              Agregar nuevo nombre
                            </Button>
                          </Box>
                        </>
                      )}
                    </StyledFormControl>
                  </Grid>
                </>
              )}

              {/* Sección de Indexación - Movida al final */}
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }}>
                  <Chip label="Indexación" color="primary" />
                </Divider>
                <FormControl component="fieldset">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="subtitle2">¿Tiene indexación?</Typography>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={tieneIndexacion}
                            onChange={(e) => setTieneIndexacion(e.target.checked)}
                            color="primary"
                          />
                        }
                        label={tieneIndexacion ? "Sí" : "No"}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>

              {tieneIndexacion && (
                <>
                  <Grid item xs={6}>
                    <Autocomplete
                      value={tipoIndexacion}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setTipoIndexacion(newValue);
                          if (newValue === 'Dolar Oficial Venta') {
                            setTcIndexado(dolarOficialVenta);
                          }
                        }
                      }}
                      inputValue={nuevoTipoIndexacion}
                      onInputChange={(event, newInputValue) => {
                        setNuevoTipoIndexacion(newInputValue);
                      }}
                      options={tiposIndexacion}
                      freeSolo
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          label="Tipo de Indexación"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {nuevoTipoIndexacion && !tiposIndexacion.includes(nuevoTipoIndexacion) && (
                                  <Tooltip title="Agregar nuevo tipo">
                                    <IconButton
                                      size="small"
                                      onClick={handleAddTipoIndexacion}
                                      sx={{ mr: 1 }}
                                    >
                                      <AddIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <StyledTextField
                        label="TC Indexado"
                        value={tcIndexado}
                        onChange={(e) => setTcIndexado(e.target.value)}
                        fullWidth
                        type="number"
                        inputProps={{ step: 1 }}
                      />
                      {tipoIndexacion === 'Dolar Oficial Venta' && (
                        <Button
                          onClick={() => setTcIndexado(dolarOficialVenta)}
                          variant="outlined"
                          sx={{ ml: 1, minWidth: '48px', color: '#2e7d32', borderColor: '#2e7d32', borderRadius: 8 }}
                        >
                          API
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <ReadOnlyTextField
                      label="Monto Indexado (Calculado)"
                      value={montoIndexado}
                      InputProps={{
                        readOnly: true,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
          <SaveButton
            variant="contained"
            color="primary"
            onClick={handleAgregarSalida}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Guardar Salida'
            )}
          </SaveButton>
        </DialogActions>
      </AddSalidaDialog>

      {/* Diálogo para editar salida */}
      <AddSalidaDialog open={openEditSalida} onClose={() => setOpenEditSalida(false)}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2
        }}>
          <Typography variant="h5" fontWeight="bold" color="#2e7d32">
            Editar Salida
          </Typography>
          <IconButton onClick={() => setOpenEditSalida(false)}>
            <CloseIcon />
          </IconButton>
        </Box>

        <DialogContent sx={{ p: 0 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DatePicker
                  label="Fecha"
                  value={filaEditando?.fecha_salida ? new Date(filaEditando.fecha_salida) : null}
                  onChange={(newValue) =>
                    setFilaEditando({ ...filaEditando, fecha_salida: newValue?.toISOString() })
                  }
                  renderInput={(params) => <StyledTextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledFormControl fullWidth>
                  <InputLabel>Caja Origen</InputLabel>
                  <Select
                    value={filaEditando?.caja_origen_salida || ''}
                    onChange={(e) => {
                      setFilaEditando({ ...filaEditando, caja_origen_salida: e.target.value });
                      // Buscar proyecto por caja origen al editar
                      buscarProyectoPorCajaOrigen(e.target.value);
                    }}
                    label="Caja Origen"
                  >
                    {cajasOrigen.map((caja) => (
                      <MenuItem key={caja} value={caja}>
                        {caja}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={filaEditando?.proveedor_salida || ''}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFilaEditando({ ...filaEditando, proveedor_salida: newValue });
                      autocompletarCamposPorProveedor(newValue); // Add this line to auto-fill fields
                    }
                  }}
                  inputValue={nuevoProveedor}
                  onInputChange={(event, newInputValue) => {
                    setNuevoProveedor(newInputValue);
                  }}
                  options={proveedores}
                  freeSolo
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      label="Proveedor"
                      fullWidth
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {nuevoProveedor && !proveedores.includes(nuevoProveedor) && (
                              <Tooltip title="Agregar nuevo proveedor">
                                <IconButton
                                  size="small"
                                  onClick={() => handleAgregarProveedor(nuevoProveedor)}
                                  sx={{ mr: 1 }}
                                >
                                  <AddIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <StyledFormControl fullWidth>
                  <InputLabel>Proyecto</InputLabel>
                  <Select
                    value={filaEditando?.codigo_de_proyecto_salida || ''}
                    onChange={(e) =>
                      setFilaEditando({ ...filaEditando, codigo_de_proyecto_salida: e.target.value })
                    }
                    label="Proyecto"
                  >
                    {proyectos.map((proy) => (
                      <MenuItem key={proy} value={proy}>
                        {proy}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <StyledFormControl fullWidth>
                  <InputLabel>Concepto</InputLabel>
                  <Select
                    value={filaEditando?.concepto_salidas || ''}
                    onChange={(e) =>
                      setFilaEditando({ ...filaEditando, concepto_salidas: e.target.value })
                    }
                    label="Concepto"
                  >
                    {conceptos.map((conc) => (
                      <MenuItem key={conc} value={conc}>
                        {conc}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <StyledFormControl fullWidth>
                  <InputLabel>Etapa</InputLabel>
                  <Select
                    value={filaEditando?.etapa_de_obra_salidas || ''}
                    onChange={(e) => {
                      const newEtapa = e.target.value;
                      setFilaEditando({ ...filaEditando, etapa_de_obra_salidas: newEtapa });
                      fetchSubetapas(newEtapa);
                    }}
                    label="Etapa"
                  >
                    {etapas.map((etapa) => (
                      <MenuItem key={etapa} value={etapa}>
                        {etapa}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  value={filaEditando?.subetapa_de_obra_salidas || ''}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setFilaEditando({ ...filaEditando, subetapa_de_obra_salidas: newValue });
                    }
                  }}
                  inputValue={nuevaSubetapa}
                  onInputChange={(event, newInputValue) => {
                    setNuevaSubetapa(newInputValue);
                  }}
                  options={subetapas}
                  freeSolo
                  disabled={!filaEditando?.etapa_de_obra_salidas}
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      label="Subetapa"
                      fullWidth
                      disabled={!filaEditando?.etapa_de_obra_salidas}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {nuevaSubetapa && filaEditando?.etapa_de_obra_salidas && !subetapas.includes(nuevaSubetapa) && (
                              <Tooltip title="Agregar nueva subetapa">
                                <IconButton
                                  size="small"
                                  onClick={() => handleAgregarSubetapa(nuevaSubetapa)}
                                  sx={{ mr: 1 }}
                                >
                                  <AddIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <StyledTextField
                    label="Tipo de Cambio"
                    value={filaEditando?.tipo_de_cambio_salida || ''}
                    onChange={(e) =>
                      setFilaEditando({ ...filaEditando, tipo_de_cambio_salida: e.target.value })
                    }
                    fullWidth
                    type="number"
                    inputProps={{ step: 5 }}
                  />
                  <Button
                    onClick={() => {
                      const currentValue = parseFloat(filaEditando?.tipo_de_cambio_salida) || 0;
                      setFilaEditando({
                        ...filaEditando,
                        tipo_de_cambio_salida: (currentValue + 5).toString()
                      });
                    }}
                    variant="outlined"
                    sx={{ ml: 1, minWidth: '48px', color: '#2e7d32', borderColor: '#2e7d32', borderRadius: 8 }}
                  >
                    +$5
                  </Button>
                  <Button
                    onClick={() => {
                      const currentValue = parseFloat(filaEditando?.tipo_de_cambio_salida) || 0;
                      setFilaEditando({
                        ...filaEditando,
                        tipo_de_cambio_salida: Math.max(0, currentValue - 5).toString()
                      });
                    }}
                    variant="outlined"
                    sx={{ ml: 1, minWidth: '48px', color: '#d32f2f', borderColor: '#d32f2f', borderRadius: 8 }}
                  >
                    -$5
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <StyledFormControl fullWidth>
                  <InputLabel>Moneda</InputLabel>
                  <Select
                    value={filaEditando?.moneda_salida || 'ARS'}
                    onChange={(e) =>
                      setFilaEditando({ ...filaEditando, moneda_salida: e.target.value })
                    }
                    label="Moneda"
                  >
                    <MenuItem value="ARS">ARS</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  label="Monto"
                  value={filaEditando?.monto_salida || ''}
                  onChange={(e) =>
                    setFilaEditando({ ...filaEditando, monto_salida: e.target.value })
                  }
                  fullWidth
                  type="number"
                />
              </Grid>

              <Grid item xs={12}>
                <StyledTextField
                  label="Descripción (opcional)"
                  value={descripcion}
                  onChange={(e) => setDescripcion(e.target.value)}
                  fullWidth
                  multiline
                  rows={3}
                  placeholder="Ingrese una descripción detallada de la salida..."
                />
              </Grid>

              {/* Sección de Comprobante en modo edición */}
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }}>
                  <Chip label="Comprobante" color="primary" />
                </Divider>
                <FormControl component="fieldset">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="subtitle2">¿Tiene comprobante?</Typography>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={tieneComprobante}
                            onChange={(e) => setTieneComprobante(e.target.checked)}
                            color="primary"
                          />
                        }
                        label={tieneComprobante ? "Sí" : "No"}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>

              {tieneComprobante && (
                <>
                  <Grid item xs={12}>
                    <input
                      ref={comprobanteInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleComprobanteChange}
                      style={{ display: 'none' }}
                    />

                    {!comprobantePreview ? (
                      <FileUploadBox onClick={handleComprobanteUpload}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <UploadFileIcon sx={{ fontSize: 40, color: '#2e7d32', mb: 1 }} />
                          <Typography variant="body1" gutterBottom>
                            Haz clic para subir una imagen de comprobante
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            JPG, PNG o PDF (máx. 5MB)
                          </Typography>
                        </Box>
                      </FileUploadBox>
                    ) : (
                      <Box sx={{ position: 'relative' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            width: '100%',
                            height: 200,
                            backgroundColor: '#f8f9fa',
                            borderRadius: 12,
                            overflow: 'hidden',
                            border: '1px solid #e0e0e0',
                          }}
                        >
                          <Box
                            component="img"
                            src={comprobantePreview}
                            alt="Vista previa del comprobante"
                            sx={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain',
                            }}
                          />
                          <IconButton
                            size="small"
                            sx={{
                              position: 'absolute',
                              top: 8,
                              right: 8,
                              backgroundColor: 'rgba(255, 255, 255, 0.8)',
                              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                            }}
                            onClick={removeComprobante}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Box>

                        {codigoComprobante && (
                          <Box sx={{
                            mt: 1,
                            p: 1,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#f1f8e9',
                            borderRadius: 8
                          }}>
                            <Typography variant="caption" sx={{ fontFamily: 'monospace', mr: 1 }}>
                              {codigoComprobante}
                            </Typography>
                            <Tooltip title="Copiar código">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigator.clipboard.writeText(codigoComprobante);
                                  setSnackbar({
                                    open: true,
                                    message: 'Código copiado al portapapeles',
                                    severity: 'success'
                                  });
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Grid>
                </>
              )}

              {/* Sección de Factura en modo edición */}
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }}>
                  <Chip label="Factura" color="primary" />
                </Divider>
                <FormControl component="fieldset">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="subtitle2">¿Tiene factura?</Typography>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={tieneFactura}
                            onChange={(e) => setTieneFactura(e.target.checked)}
                            color="primary"
                          />
                        }
                        label={tieneFactura ? "Sí" : "No"}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>

              {tieneFactura && (
                <>
                  <Grid item xs={12}>
                    <input
                      ref={facturaInputRef}
                      type="file"
                      accept="image/*"
                      onChange={handleFacturaChange}
                      style={{ display: 'none' }}
                    />

                    {!facturaPreview ? (
                      <FileUploadBox onClick={handleFacturaUpload}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <UploadFileIcon sx={{ fontSize: 40, color: '#2e7d32', mb: 1 }} />
                          <Typography variant="body1" gutterBottom>
                            Haz clic para subir una imagen de factura
                          </Typography>
                          <Typography variant="body2" color="text.secondary">
                            JPG, PNG o PDF (máx. 5MB)
                          </Typography>
                        </Box>
                      </FileUploadBox>
                    ) : (
                      <Box sx={{ position: 'relative' }}>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            width: '100%',
                            height: 200,
                            backgroundColor: '#f8f9fa',
                            borderRadius: 12,
                            overflow: 'hidden',
                            border: '1px solid #e0e0e0',
                          }}
                        >
                          <Box
                            component="img"
                            src={facturaPreview}
                            alt="Vista previa de la factura"
                            sx={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain',
                            }}
                          />
                          <IconButton
                            size="small"
                            sx={{
                              position: 'absolute',
                              top: 8,
                              right: 8,
                              backgroundColor: 'rgba(255, 255, 255, 0.8)',
                              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                            }}
                            onClick={removeFactura}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Box>

                        {codigoFactura && (
                          <Box sx={{
                            mt: 1,
                            p: 1,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: '#f1f8e9',
                            borderRadius: 8
                          }}>
                            <Typography variant="caption" sx={{ fontFamily: 'monospace', mr: 1 }}>
                              {codigoFactura}
                            </Typography>
                            <Tooltip title="Copiar código">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigator.clipboard.writeText(codigoFactura);
                                  setSnackbar({
                                    open: true,
                                    message: 'Código copiado al portapapeles',
                                    severity: 'success'
                                  });
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        )}
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <StyledFormControl fullWidth>
                      <InputLabel>A nombre de</InputLabel>
                      {addingNewNombre ? (
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                          <StyledTextField
                            value={nuevoNombreFactura}
                            onChange={(e) => setNuevoNombreFactura(e.target.value)}
                            label="Nuevo nombre"
                            fullWidth
                            size="small"
                          />
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAgregarNuevoNombre}
                            sx={{ ml: 1, minWidth: 'auto', borderRadius: 8 }}
                          >
                            <CheckIcon />
                          </Button>
                          <Button
                            variant="outlined"
                            onClick={() => setAddingNewNombre(false)}
                            sx={{ ml: 1, minWidth: 'auto', borderRadius: 8 }}
                          >
                            <CloseIcon />
                          </Button>
                        </Box>
                      ) : (
                        <>
                          <Select
                            value={facturaANombreDe}
                            onChange={(e) => setFacturaANombreDe(e.target.value)}
                            disabled={addingNewNombre}
                            label="A nombre de"
                          >
                            <MenuItem value="">
                              <em>Seleccione un nombre</em>
                            </MenuItem>
                            {nombresFacturas.map((nombre) => (
                              <MenuItem key={nombre} value={nombre}>
                                {nombre}
                              </MenuItem>
                            ))}
                          </Select>
                          <Box sx={{ mt: 1, textAlign: 'right' }}>
                            <Button
                              size="small"
                              startIcon={<AddIcon />}
                              onClick={() => setAddingNewNombre(true)}
                              sx={{ borderRadius: 8 }}
                            >
                              Agregar nuevo nombre
                            </Button>
                          </Box>
                        </>
                      )}
                    </StyledFormControl>
                  </Grid>
                </>
              )}

              {/* Sección de Indexación en modo edición - Movida al final */}
              <Grid item xs={12}>
                <Divider sx={{ my: 2 }}>
                  <Chip label="Indexación" color="primary" />
                </Divider>
                <FormControl component="fieldset">
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <Typography variant="subtitle2">¿Tiene indexación?</Typography>
                    </Grid>
                    <Grid item>
                      <FormControlLabel
                        control={
                          <Switch
                            checked={tieneIndexacion}
                            onChange={(e) => setTieneIndexacion(e.target.checked)}
                            color="primary"
                          />
                        }
                        label={tieneIndexacion ? "Sí" : "No"}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </Grid>

              {tieneIndexacion && (
                <>
                  <Grid item xs={6}>
                    <Autocomplete
                      value={tipoIndexacion}
                      onChange={(event, newValue) => {
                        if (newValue) {
                          setTipoIndexacion(newValue);
                          if (newValue === 'Dolar Oficial Venta') {
                            setTcIndexado(dolarOficialVenta);
                          }
                        }
                      }}
                      inputValue={nuevoTipoIndexacion}
                      onInputChange={(event, newInputValue) => {
                        setNuevoTipoIndexacion(newInputValue);
                      }}
                      options={tiposIndexacion}
                      freeSolo
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          label="Tipo de Indexación"
                          fullWidth
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {nuevoTipoIndexacion && !tiposIndexacion.includes(nuevoTipoIndexacion) && (
                                  <Tooltip title="Agregar nuevo tipo">
                                    <IconButton
                                      size="small"
                                      onClick={handleAddTipoIndexacion}
                                      sx={{ mr: 1 }}
                                    >
                                      <AddIcon fontSize="small" />
                                    </IconButton>
                                  </Tooltip>
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <StyledTextField
                        label="TC Indexado"
                        value={tcIndexado}
                        onChange={(e) => setTcIndexado(e.target.value)}
                        fullWidth
                        type="number"
                        inputProps={{ step: 1 }}
                      />
                      {tipoIndexacion === 'Dolar Oficial Venta' && (
                        <Button
                          onClick={() => setTcIndexado(dolarOficialVenta)}
                          variant="outlined"
                          sx={{ ml: 1, minWidth: '48px', color: '#2e7d32', borderColor: '#2e7d32', borderRadius: 8 }}
                        >
                          API
                        </Button>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <ReadOnlyTextField
                      label="Monto Indexado (Calculado)"
                      value={montoIndexado}
                      InputProps={{
                        readOnly: true,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
          <SaveButton
            variant="contained"
            color="primary"
            onClick={handleActualizarSalida}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              'Guardar Cambios'
            )}
          </SaveButton>
        </DialogActions>
      </AddSalidaDialog>

      {/* Diálogo para vista previa de imagen */}
      <ImagePreviewDialog
        open={openImagePreview}
        onClose={() => setOpenImagePreview(false)}
        maxWidth="md"
        onClick={() => setOpenImagePreview(false)}
      >
        <DialogContent sx={{ p: 0, overflow: 'hidden', position: 'relative' }}>
          {currentImage && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                background: 'transparent',
              }}
            >
              <Box
                component="img"
                src={currentImage.url}
                alt={currentImage.tipo || "Imagen"}
                sx={{
                  maxWidth: '100%',
                  maxHeight: '75vh',
                  objectFit: 'contain',
                  borderRadius: 1,
                  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
                }}
                onError={(e) => {
                  console.error('Error cargando imagen en diálogo:', e);
                  e.target.src = 'https://via.placeholder.com/400x300?text=Imagen+no+disponible';
                }}
              />

              {currentImage.codigo && (
                <Box sx={{
                  mt: 2,
                  p: 1,
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                  <Typography variant="body2" fontWeight="bold" sx={{ mb: 0.5 }}>
                    Código de {currentImage.tipo === 'factura' ? 'factura' : 'comprobante'}
                  </Typography>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#e8f5e9',
                    p: 1,
                    borderRadius: 1,
                    fontFamily: 'monospace'
                  }}>
                    <Typography variant="body2">
                      {currentImage.codigo}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(currentImage.codigo);
                        setSnackbar({
                          open: true,
                          message: 'Código copiado al portapapeles',
                          severity: 'success'
                        });
                      }}
                      sx={{ ml: 1 }}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              )}

              <IconButton
                size="small"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                }}
                onClick={() => setOpenImagePreview(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </DialogContent>
      </ImagePreviewDialog>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            borderRadius: 2,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Salidas;