import React, { useState, useEffect, useMemo } from 'react';
import { supabase } from '../supabaseClient';
import { format, parseISO, subMonths, addMonths, startOfMonth, isSameMonth, getYear } from 'date-fns';
import { es } from 'date-fns/locale';
import {
  Box,
  Container,
  Grid,
  Typography,
  Card,
  CardContent,
  Paper,
  Chip,
  Divider,
  Button,
  IconButton,
  MenuItem,
  FormControl,
  Select,
  InputLabel,
  Tab,
  Tabs,
  Stack,
  useTheme,
  useMediaQuery,
  Tooltip,
  Skeleton,
  Avatar,
  Switch,
  FormControlLabel,
  CircularProgress,
  Alert
} from '@mui/material';
import {
  AttachMoney,
  DateRange,
  Equalizer,
  Star,
  Refresh,
  CalendarToday,
  TrendingUp,
  PersonOutline,
  ShowChart,
  BarChart,
  PieChart as PieChartIcon,
  LocalAtm,
  CurrencyExchange,
  Balance,
  CompareArrows,
  TrendingDown,
  Timeline,
  SpeedOutlined,
  ArrowUpward,
  ArrowDownward,
  Celebration,
  QueryStats,
  MonetizationOn,
  AccountBalance,
  Speed,
  TrendingFlat
} from '@mui/icons-material';
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  BarChart as RechartsBarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip as RechartsTooltip,
  Legend,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  RadialBarChart,
  RadialBar,
  ComposedChart
} from 'recharts';
import { motion, AnimatePresence } from 'framer-motion';
import { styled } from '@mui/material/styles';

// Styled components con diseño mejorado
const StyledCard = styled(Card)(({ theme, gradient, accentcolor }) => ({
  borderRadius: 16,
  boxShadow: '0 8px 25px rgba(0, 0, 0, 0.08)',
  height: '100%',
  position: 'relative',
  overflow: 'hidden',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  background: gradient ?
    `linear-gradient(135deg, ${theme.palette[accentcolor || 'primary'].light} 0%, ${theme.palette[accentcolor || 'primary'].main} 100%)` :
    theme.palette.background.paper,
  color: gradient ? theme.palette.getContrastText(theme.palette[accentcolor || 'primary'].main) : 'inherit',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 30px rgba(0, 0, 0, 0.12)',
  },
}));

const KPIValue = styled(Typography)(({ theme, trending }) => ({
  color: trending === 'up' ? theme.palette.success.main :
    trending === 'down' ? theme.palette.error.main :
      trending === 'neutral' ? theme.palette.info.main : 'inherit',
  display: 'inline-flex',
  alignItems: 'center',
  fontWeight: 700,
}));

const TrendingIcon = styled(({ trending, ...props }) =>
  trending === 'up' ? <ArrowUpward {...props} /> :
    trending === 'down' ? <ArrowDownward {...props} /> :
      <TrendingFlat {...props} />
)(({ theme, trending }) => ({
  fontSize: '1rem',
  marginLeft: theme.spacing(0.5),
  color: trending === 'up' ? theme.palette.success.main :
    trending === 'down' ? theme.palette.error.main :
      theme.palette.info.main,
}));

const GlowingBackground = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 0,
  overflow: 'hidden',
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: '200%',
    height: '200%',
    transform: 'translate(-50%, -50%)',
    background: 'radial-gradient(circle, rgba(255,255,255,0.1) 0%, rgba(255,255,255,0) 70%)',
  },
}));

const StyledTabs = styled(Tabs)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.primary.main,
    height: 3,
    borderRadius: '3px 3px 0 0',
  },
  '& .MuiTab-root': {
    textTransform: 'none',
    minWidth: 0,
    fontWeight: 600,
    padding: '12px 20px',
    transition: 'all 0.2s',
    '&.Mui-selected': {
      color: theme.palette.primary.main,
    },
  },
}));

const CardHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const ErrorAlert = styled(Alert)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: 8,
  boxShadow: theme.shadows[2],
}));

const StyledWorkersContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(1),
}));

const WorkerChip = styled(Chip)(({ theme, selected }) => ({
  borderRadius: 16,
  fontWeight: 600,
  transition: 'all 0.2s ease',
  backgroundColor: selected ? theme.palette.primary.main : theme.palette.background.default,
  color: selected ? theme.palette.primary.contrastText : theme.palette.text.primary,
  border: `1px solid ${selected ? theme.palette.primary.main : theme.palette.divider}`,
  '&:hover': {
    backgroundColor: selected ? theme.palette.primary.dark : theme.palette.action.hover,
  },
}));

// Colores vibrantes pero armoniosos para gráficos
const COLORS = ['#4361ee', '#3a0ca3', '#7209b7', '#f72585', '#4cc9f0', '#4895ef', '#560bad', '#480ca8', '#b5179e'];

// Colores alternativos para otro tipo de gráficos
const ALT_COLORS = ['#4cc9f0', '#3a0ca3', '#f72585', '#7209b7', '#4361ee', '#560bad', '#480ca8', '#b5179e'];

// Colores para los meses del año
const MONTH_COLORS = [
  '#4361ee', '#4895ef', '#4cc9f0', '#90e0ef',
  '#06d6a0', '#22577a', '#38a3a5', '#57cc99',
  '#f72585', '#b5179e', '#7209b7', '#560bad'
];

// Formatear moneda
const formatCurrency = (value, currency = 'USD', maximumFractionDigits = 2) => {
  if (typeof value !== 'number' || isNaN(value)) return '0';
  return new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency,
    maximumFractionDigits,
  }).format(value);
};

// Componente principal
const DashboardTrabajadores = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cobros, setCobros] = useState([]);
  const [trabajadores, setTrabajadores] = useState([]);
  const [monthlyData, setMonthlyData] = useState([]);
  const [yearlyData, setYearlyData] = useState([]);
  const [rollingAverageData, setRollingAverageData] = useState([]);
  const [availableYears, setAvailableYears] = useState([]);
  const [kpis, setKpis] = useState({
    totalEarningsARS: 0,
    totalEarningsUSD: 0,
    ytdEarningsARS: 0,
    ytdEarningsUSD: 0,
    prevYtdEarningsARS: 0,
    prevYtdEarningsUSD: 0,
    ytdGrowthPercentage: 0,
    ytdAvgMonthlyARS: 0,
    ytdAvgMonthlyUSD: 0,
    prevYtdAvgMonthlyARS: 0,
    prevYtdAvgMonthlyUSD: 0,
    ytdAvgGrowthPercentage: 0,
    averageMonthlyARS: 0,
    averageMonthlyUSD: 0,
    prevYearAvgMonthlyARS: 0,
    prevYearAvgMonthlyUSD: 0,
    avgMonthlyGrowthPercentage: 0,
    bestMonthARS: { month: '', value: 0 },
    bestMonthUSD: { month: '', value: 0 },
    monthlyGrowth: 0,
    yearlyGrowth: 0,
    monthlyCount: 0,
  });
  const [workersEarnings, setWorkersEarnings] = useState([]);
  const [sociosBalance, setSociosBalance] = useState({
    disparity: 0,
    percentage: 0,
    status: 'balanced' // 'balanced', 'moderate', 'unbalanced'
  });
  const [currency, setCurrency] = useState('USD');
  const [selectedWorker, setSelectedWorker] = useState('all');
  const [tabValue, setTabValue] = useState(0);
  const [showAverage, setShowAverage] = useState(true);
  const [timeframeTab, setTimeframeTab] = useState(0); // Default año móvil
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear().toString());

  // Fetch data from Supabase
  const fetchData = async () => {
    setLoading(true);
    setError(null);
    try {
      // Get cobros data
      const { data: cobrosData, error: cobrosError } = await supabase
        .from('cobro_trabajadores')
        .select('*')
        .order('fecha', { ascending: true });

      if (cobrosError) throw new Error(`Error fetching cobros: ${cobrosError.message}`);

      // Get trabajadores data
      const { data: trabajadoresData, error: trabajadoresError } = await supabase
        .from('trabajadores_empresa')
        .select('*');

      if (trabajadoresError) throw new Error(`Error fetching trabajadores: ${trabajadoresError.message}`);

      // Process data
      const processedCobros = cobrosData.map(cobro => ({
        ...cobro,
        fecha: cobro.fecha ? parseISO(cobro.fecha) : null,
        month: cobro.fecha ? format(parseISO(cobro.fecha), 'MM') : '',
        year: cobro.fecha ? format(parseISO(cobro.fecha), 'yyyy') : '',
        monthName: cobro.fecha ? format(parseISO(cobro.fecha), 'MMM', { locale: es }) : '',
        monthYear: cobro.fecha ? format(parseISO(cobro.fecha), 'MM/yyyy') : '',
        formattedDate: cobro.fecha ? format(parseISO(cobro.fecha), 'MMM yyyy', { locale: es }) : '',
      })).filter(cobro => cobro.fecha);

      setCobros(processedCobros);
      setTrabajadores(trabajadoresData);
      calculateStats(processedCobros, trabajadoresData);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Calculate statistics
  const calculateStats = (cobrosData, trabajadoresData) => {
    if (!cobrosData || cobrosData.length === 0) return;

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    // Group data by month and year
    const monthlyData = {};
    const yearlyData = {};
    const workerMonthlyData = {};
    const years = new Set();

    // Process each cobro
    cobrosData.forEach(cobro => {
      const { monthYear, year, formattedDate } = cobro;

      years.add(year);

      // Initialize monthly data if not exists
      if (!monthlyData[monthYear]) {
        monthlyData[monthYear] = {
          ars: 0,
          usd: 0,
          count: 0,
          byWorker: {},
          formattedDate,
          month: parseInt(monthYear.split('/')[0], 10),
          year: parseInt(monthYear.split('/')[1], 10)
        };
      }

      // Initialize yearly data if not exists
      if (!yearlyData[year]) {
        yearlyData[year] = {
          ars: 0,
          usd: 0,
          count: 0,
          byWorker: {},
          byMonth: {}
        };
      }

      const worker = cobro.trabajador_cobro;

      // Initialize worker data structures
      if (!monthlyData[monthYear].byWorker[worker]) {
        monthlyData[monthYear].byWorker[worker] = { ars: 0, usd: 0 };
      }

      if (!yearlyData[year].byWorker[worker]) {
        yearlyData[year].byWorker[worker] = { ars: 0, usd: 0 };
      }

      if (!workerMonthlyData[worker]) {
        workerMonthlyData[worker] = {};
      }

      if (!workerMonthlyData[worker][monthYear]) {
        workerMonthlyData[worker][monthYear] = {
          ars: 0,
          usd: 0,
          formattedDate
        };
      }

      const monthNum = parseInt(monthYear.split('/')[0]);
      if (!yearlyData[year].byMonth[monthNum]) {
        yearlyData[year].byMonth[monthNum] = { ars: 0, usd: 0 };
      }

      // Add amounts based on currency
      if (cobro.moneda_cobro === 'ARS') {
        monthlyData[monthYear].ars += cobro.monto_cobro || 0;
        monthlyData[monthYear].byWorker[worker].ars += cobro.monto_cobro || 0;
        monthlyData[monthYear].usd += cobro.monto_en_usd_cobro || 0;
        monthlyData[monthYear].byWorker[worker].usd += cobro.monto_en_usd_cobro || 0;

        yearlyData[year].ars += cobro.monto_cobro || 0;
        yearlyData[year].byWorker[worker].ars += cobro.monto_cobro || 0;
        yearlyData[year].usd += cobro.monto_en_usd_cobro || 0;
        yearlyData[year].byWorker[worker].usd += cobro.monto_en_usd_cobro || 0;

        yearlyData[year].byMonth[monthNum].ars += cobro.monto_cobro || 0;
        yearlyData[year].byMonth[monthNum].usd += cobro.monto_en_usd_cobro || 0;

        workerMonthlyData[worker][monthYear].ars += cobro.monto_cobro || 0;
        workerMonthlyData[worker][monthYear].usd += cobro.monto_en_usd_cobro || 0;
      } else if (cobro.moneda_cobro === 'USD') {
        monthlyData[monthYear].usd += cobro.monto_cobro || 0;
        monthlyData[monthYear].byWorker[worker].usd += cobro.monto_cobro || 0;
        monthlyData[monthYear].ars += cobro.monto_en_ars_cobro || 0;
        monthlyData[monthYear].byWorker[worker].ars += cobro.monto_en_ars_cobro || 0;

        yearlyData[year].usd += cobro.monto_cobro || 0;
        yearlyData[year].byWorker[worker].usd += cobro.monto_cobro || 0;
        yearlyData[year].ars += cobro.monto_en_ars_cobro || 0;
        yearlyData[year].byWorker[worker].ars += cobro.monto_en_ars_cobro || 0;

        yearlyData[year].byMonth[monthNum].usd += cobro.monto_cobro || 0;
        yearlyData[year].byMonth[monthNum].ars += cobro.monto_en_ars_cobro || 0;

        workerMonthlyData[worker][monthYear].ars += cobro.monto_en_ars_cobro || 0;
        workerMonthlyData[worker][monthYear].usd += cobro.monto_cobro || 0;
      }

      monthlyData[monthYear].count += 1;
      yearlyData[year].count += 1;
    });

    // Format monthly data for charts
    const formattedMonthlyData = Object.keys(monthlyData)
      .sort((a, b) => {
        const [aMonth, aYear] = a.split('/').map(Number);
        const [bMonth, bYear] = b.split('/').map(Number);
        return aYear !== bYear ? aYear - bYear : aMonth - bMonth;
      })
      .map(monthYear => {
        const [month, year] = monthYear.split('/');
        const dataPoint = {
          name: `${month}/${year}`,
          displayName: monthlyData[monthYear].formattedDate,
          ars: monthlyData[monthYear].ars,
          usd: monthlyData[monthYear].usd,
          count: monthlyData[monthYear].count,
          month: parseInt(month, 10),
          year: parseInt(year, 10)
        };

        // Add worker data
        trabajadoresData.forEach(worker => {
          const workerName = worker.nombre_del_trabajador;
          if (monthlyData[monthYear].byWorker[workerName]) {
            dataPoint[`${workerName}_ars`] = monthlyData[monthYear].byWorker[workerName].ars;
            dataPoint[`${workerName}_usd`] = monthlyData[monthYear].byWorker[workerName].usd;
          } else {
            dataPoint[`${workerName}_ars`] = 0;
            dataPoint[`${workerName}_usd`] = 0;
          }
        });

        return dataPoint;
      });

    // Format yearly data for charts
    const formattedYearlyData = Object.keys(yearlyData)
      .sort((a, b) => Number(a) - Number(b))
      .map(year => {
        const dataPoint = {
          name: year,
          ars: yearlyData[year].ars,
          usd: yearlyData[year].usd,
          count: yearlyData[year].count
        };

        // Add worker data
        trabajadoresData.forEach(worker => {
          const workerName = worker.nombre_del_trabajador;
          if (yearlyData[year].byWorker[workerName]) {
            dataPoint[`${workerName}_ars`] = yearlyData[year].byWorker[workerName].ars;
            dataPoint[`${workerName}_usd`] = yearlyData[year].byWorker[workerName].usd;
          } else {
            dataPoint[`${workerName}_ars`] = 0;
            dataPoint[`${workerName}_usd`] = 0;
          }
        });

        return dataPoint;
      });

    // Calculate rolling 12-month averages
    const rollingAverages = [];
    formattedMonthlyData.forEach((monthData, index) => {
      if (index >= 11) { // Need at least 12 months of data
        const last12Months = formattedMonthlyData.slice(index - 11, index + 1);
        const totalARS = last12Months.reduce((sum, m) => sum + m.ars, 0);
        const totalUSD = last12Months.reduce((sum, m) => sum + m.usd, 0);

        const avgData = {
          name: monthData.name,
          displayName: monthData.displayName,
          avgARS: totalARS / 12,
          avgUSD: totalUSD / 12,
          ars: monthData.ars,
          usd: monthData.usd,
          month: monthData.month,
          year: monthData.year
        };

        // Add worker averages
        trabajadoresData.forEach(worker => {
          const workerName = worker.nombre_del_trabajador;
          const workerTotalARS = last12Months.reduce((sum, m) => sum + (m[`${workerName}_ars`] || 0), 0);
          const workerTotalUSD = last12Months.reduce((sum, m) => sum + (m[`${workerName}_usd`] || 0), 0);

          avgData[`${workerName}_avgARS`] = workerTotalARS / 12;
          avgData[`${workerName}_avgUSD`] = workerTotalUSD / 12;
          avgData[`${workerName}_ars`] = monthData[`${workerName}_ars`] || 0;
          avgData[`${workerName}_usd`] = monthData[`${workerName}_usd`] || 0;
        });

        rollingAverages.push(avgData);
      }
    });

    // Create complete monthly datasets for each year (for year view)
    const yearDatasets = {};

    // For each available year
    Array.from(years).forEach(year => {
      const yearInt = parseInt(year, 10);
      yearDatasets[year] = [];

      // Create data points for all 12 months
      for (let month = 1; month <= 12; month++) {
        const monthKey = `${month.toString().padStart(2, '0')}/${year}`;
        const monthData = monthlyData[monthKey];

        // If we have data for this month, use it, otherwise create empty data
        if (monthData) {
          yearDatasets[year].push({
            name: monthKey,
            displayName: monthData.formattedDate,
            ars: monthData.ars,
            usd: monthData.usd,
            count: monthData.count,
            month,
            year: yearInt
          });
        } else {
          // Create empty data for this month
          const date = new Date(yearInt, month - 1, 1);
          yearDatasets[year].push({
            name: monthKey,
            displayName: format(date, 'MMM yyyy', { locale: es }),
            ars: 0,
            usd: 0,
            count: 0,
            month,
            year: yearInt
          });
        }
      }

      // Sort by month
      yearDatasets[year].sort((a, b) => a.month - b.month);
    });

    // Calculate worker earnings data
    const workersData = trabajadoresData.map(worker => {
      const name = worker.nombre_del_trabajador;
      let totalARS = 0, totalUSD = 0, ytdARS = 0, ytdUSD = 0, prevYtdARS = 0, prevYtdUSD = 0;

      const monthlyEarnings = [];

      Object.keys(monthlyData).forEach(monthYear => {
        const [month, year] = monthYear.split('/').map(Number);

        if (monthlyData[monthYear].byWorker[name]) {
          const ars = monthlyData[monthYear].byWorker[name].ars || 0;
          const usd = monthlyData[monthYear].byWorker[name].usd || 0;

          totalARS += ars;
          totalUSD += usd;

          if (year === currentYear && month <= currentMonth) {
            ytdARS += ars;
            ytdUSD += usd;
          }

          if (year === currentYear - 1 && month <= currentMonth) {
            prevYtdARS += ars;
            prevYtdUSD += usd;
          }

          monthlyEarnings.push({
            monthYear,
            formattedDate: monthlyData[monthYear].formattedDate,
            ars,
            usd,
            month,
            year
          });
        }
      });

      // Calculate YTD growth percentage
      const ytdGrowthPercentage = prevYtdUSD > 0 ? ((ytdUSD - prevYtdUSD) / prevYtdUSD) * 100 : 0;

      // Calculate YTD average monthly
      const ytdMonthCount = Object.keys(monthlyData)
        .filter(monthYear => {
          const [month, year] = monthYear.split('/').map(Number);
          return year === currentYear && month <= currentMonth &&
            monthlyData[monthYear].byWorker[name] &&
            (monthlyData[monthYear].byWorker[name].ars > 0 || monthlyData[monthYear].byWorker[name].usd > 0);
        }).length;

      const ytdAvgMonthlyARS = ytdMonthCount > 0 ? ytdARS / ytdMonthCount : 0;
      const ytdAvgMonthlyUSD = ytdMonthCount > 0 ? ytdUSD / ytdMonthCount : 0;

      // Calculate previous YTD average monthly
      const prevYtdMonthCount = Object.keys(monthlyData)
        .filter(monthYear => {
          const [month, year] = monthYear.split('/').map(Number);
          return year === currentYear - 1 && month <= currentMonth &&
            monthlyData[monthYear].byWorker[name] &&
            (monthlyData[monthYear].byWorker[name].ars > 0 || monthlyData[monthYear].byWorker[name].usd > 0);
        }).length;

      const prevYtdAvgMonthlyARS = prevYtdMonthCount > 0 ? prevYtdARS / prevYtdMonthCount : 0;
      const prevYtdAvgMonthlyUSD = prevYtdMonthCount > 0 ? prevYtdUSD / prevYtdMonthCount : 0;

      // Calculate YTD average monthly growth percentage
      const ytdAvgGrowthPercentage = prevYtdAvgMonthlyUSD > 0
        ? ((ytdAvgMonthlyUSD - prevYtdAvgMonthlyUSD) / prevYtdAvgMonthlyUSD) * 100
        : 0;

      // Calculate total lifetime monthly average
      const totalMonthCount = monthlyEarnings.length;
      const avgMonthlyARS = totalMonthCount > 0 ? totalARS / totalMonthCount : 0;
      const avgMonthlyUSD = totalMonthCount > 0 ? totalUSD / totalMonthCount : 0;

      return {
        id: worker.id,
        name,
        puesto: worker.puesto_del_trabajador,
        permisos: worker.permisos_del_trabajador,
        totalARS,
        totalUSD,
        ytdARS,
        ytdUSD,
        prevYtdARS,
        prevYtdUSD,
        ytdGrowthPercentage,
        ytdAvgMonthlyARS,
        ytdAvgMonthlyUSD,
        prevYtdAvgMonthlyARS,
        prevYtdAvgMonthlyUSD,
        ytdAvgGrowthPercentage,
        avgMonthlyARS,
        avgMonthlyUSD,
        totalMonthCount,
        monthlyEarnings: monthlyEarnings.sort((a, b) => {
          const [aMonth, aYear] = a.monthYear.split('/').map(Number);
          const [bMonth, bYear] = b.monthYear.split('/').map(Number);
          return aYear !== bYear ? aYear - bYear : aMonth - bMonth;
        })
      };
    });

    // Calculate KPIs
    const totalEarningsARS = Object.values(monthlyData).reduce((sum, data) => sum + data.ars, 0);
    const totalEarningsUSD = Object.values(monthlyData).reduce((sum, data) => sum + data.usd, 0);

    // Year-to-date earnings
    const ytdMonths = Object.keys(monthlyData)
      .filter(monthYear => {
        const [month, year] = monthYear.split('/').map(Number);
        return year === currentYear && month <= currentMonth;
      });

    const ytdEarningsARS = ytdMonths.reduce((sum, monthYear) => sum + monthlyData[monthYear].ars, 0);
    const ytdEarningsUSD = ytdMonths.reduce((sum, monthYear) => sum + monthlyData[monthYear].usd, 0);
    const ytdMonthCount = ytdMonths.length;

    // Previous year YTD earnings
    const prevYtdMonths = Object.keys(monthlyData)
      .filter(monthYear => {
        const [month, year] = monthYear.split('/').map(Number);
        return year === currentYear - 1 && month <= currentMonth;
      });

    const prevYtdEarningsARS = prevYtdMonths.reduce((sum, monthYear) => sum + monthlyData[monthYear].ars, 0);
    const prevYtdEarningsUSD = prevYtdMonths.reduce((sum, monthYear) => sum + monthlyData[monthYear].usd, 0);
    const prevYtdMonthCount = prevYtdMonths.length;

    // Calculate YTD growth percentage
    const ytdGrowthPercentage = prevYtdEarningsUSD > 0
      ? ((ytdEarningsUSD - prevYtdEarningsUSD) / prevYtdEarningsUSD) * 100
      : 0;

    // Calculate YTD average monthly
    const ytdAvgMonthlyARS = ytdMonthCount > 0 ? ytdEarningsARS / ytdMonthCount : 0;
    const ytdAvgMonthlyUSD = ytdMonthCount > 0 ? ytdEarningsUSD / ytdMonthCount : 0;

    // Calculate previous YTD average monthly
    const prevYtdAvgMonthlyARS = prevYtdMonthCount > 0 ? prevYtdEarningsARS / prevYtdMonthCount : 0;
    const prevYtdAvgMonthlyUSD = prevYtdMonthCount > 0 ? prevYtdEarningsUSD / prevYtdMonthCount : 0;

    // Calculate YTD average monthly growth percentage
    const ytdAvgGrowthPercentage = prevYtdAvgMonthlyUSD > 0
      ? ((ytdAvgMonthlyUSD - prevYtdAvgMonthlyUSD) / prevYtdAvgMonthlyUSD) * 100
      : 0;

    // Find best month
    let bestMonthARS = { month: '', value: 0 };
    let bestMonthUSD = { month: '', value: 0 };

    Object.keys(monthlyData).forEach(monthYear => {
      if (monthlyData[monthYear].ars > bestMonthARS.value) {
        bestMonthARS = {
          month: monthYear,
          value: monthlyData[monthYear].ars,
          formattedDate: monthlyData[monthYear].formattedDate
        };
      }

      if (monthlyData[monthYear].usd > bestMonthUSD.value) {
        bestMonthUSD = {
          month: monthYear,
          value: monthlyData[monthYear].usd,
          formattedDate: monthlyData[monthYear].formattedDate
        };
      }
    });

    // Calculate average monthly earnings
    const monthCount = Object.keys(monthlyData).length;
    const averageMonthlyARS = monthCount > 0 ? totalEarningsARS / monthCount : 0;
    const averageMonthlyUSD = monthCount > 0 ? totalEarningsUSD / monthCount : 0;

    // Previous year average monthly earnings
    const prevYearMonths = Object.keys(monthlyData).filter(monthYear => {
      const [_, year] = monthYear.split('/').map(Number);
      return year === currentYear - 1;
    });

    const prevYearMonthCount = prevYearMonths.length;
    const prevYearTotalARS = prevYearMonths.reduce((sum, monthYear) => sum + monthlyData[monthYear].ars, 0);
    const prevYearTotalUSD = prevYearMonths.reduce((sum, monthYear) => sum + monthlyData[monthYear].usd, 0);

    const prevYearAvgMonthlyARS = prevYearMonthCount > 0 ? prevYearTotalARS / prevYearMonthCount : 0;
    const prevYearAvgMonthlyUSD = prevYearMonthCount > 0 ? prevYearTotalUSD / prevYearMonthCount : 0;

    // Calculate average monthly growth percentage compared to previous year
    const avgMonthlyGrowthPercentage = prevYearAvgMonthlyUSD > 0
      ? ((averageMonthlyUSD - prevYearAvgMonthlyUSD) / prevYearAvgMonthlyUSD) * 100
      : 0;

    // Calculate growth rates
    const sortedMonths = Object.keys(monthlyData).sort((a, b) => {
      const [aMonth, aYear] = a.split('/').map(Number);
      const [bMonth, bYear] = b.split('/').map(Number);
      return aYear !== bYear ? aYear - bYear : aMonth - bMonth;
    });

    let monthlyGrowth = 0;
    if (sortedMonths.length >= 2) {
      const lastMonth = sortedMonths[sortedMonths.length - 1];
      const previousMonth = sortedMonths[sortedMonths.length - 2];
      const lastMonthUSD = monthlyData[lastMonth].usd;
      const previousMonthUSD = monthlyData[previousMonth].usd;

      if (previousMonthUSD > 0) {
        monthlyGrowth = ((lastMonthUSD - previousMonthUSD) / previousMonthUSD) * 100;
      }
    }

    let yearlyGrowth = 0;
    if (yearlyData[currentYear] && yearlyData[currentYear - 1]) {
      const currentYearUSD = yearlyData[currentYear].usd;
      const previousYearUSD = yearlyData[currentYear - 1].usd;

      if (previousYearUSD > 0) {
        yearlyGrowth = ((currentYearUSD - previousYearUSD) / previousYearUSD) * 100;
      }
    }

    // Calculate balance between socios (admin workers)
    const adminWorkers = workersData.filter(worker =>
      worker.permisos && worker.permisos.toLowerCase().includes('admin')
    );

    let sociosBalanceData = { disparity: 0, percentage: 0, status: 'balanced' };

    if (adminWorkers.length >= 2) {
      let maxEarnings = 0, minEarnings = Infinity;

      adminWorkers.forEach(worker => {
        if (worker.totalUSD > maxEarnings) maxEarnings = worker.totalUSD;
        if (worker.totalUSD < minEarnings && worker.totalUSD > 0) minEarnings = worker.totalUSD;
      });

      const disparity = maxEarnings - minEarnings;
      const percentage = maxEarnings > 0 ? (disparity / maxEarnings) * 100 : 0;

      let status = 'balanced';
      if (percentage > 15) {
        status = 'unbalanced';
      } else if (percentage > 5) {
        status = 'moderate';
      }

      sociosBalanceData = { disparity, percentage, status };
    }

    // Sort available years in descending order
    const sortedYears = Array.from(years).sort((a, b) => parseInt(b) - parseInt(a));

    // Update state with calculated data
    setMonthlyData(formattedMonthlyData);
    setYearlyData(formattedYearlyData);
    setRollingAverageData(rollingAverages);
    setWorkersEarnings(workersData);
    setSociosBalance(sociosBalanceData);
    setAvailableYears(sortedYears);

    // Set selected year to current year if available, otherwise to latest year
    if (sortedYears.includes(currentYear.toString())) {
      setSelectedYear(currentYear.toString());
    } else if (sortedYears.length > 0) {
      setSelectedYear(sortedYears[0]);
    }

    setKpis({
      totalEarningsARS,
      totalEarningsUSD,
      ytdEarningsARS,
      ytdEarningsUSD,
      prevYtdEarningsARS,
      prevYtdEarningsUSD,
      ytdGrowthPercentage,
      ytdAvgMonthlyARS,
      ytdAvgMonthlyUSD,
      prevYtdAvgMonthlyARS,
      prevYtdAvgMonthlyUSD,
      ytdAvgGrowthPercentage,
      averageMonthlyARS,
      averageMonthlyUSD,
      prevYearAvgMonthlyARS,
      prevYearAvgMonthlyUSD,
      avgMonthlyGrowthPercentage,
      bestMonthARS,
      bestMonthUSD,
      monthlyGrowth,
      yearlyGrowth,
      monthlyCount: monthCount,
    });
  };

  // Filter data based on selected worker
  const filteredData = useMemo(() => {
    if (selectedWorker === 'all') {
      return {
        monthly: monthlyData,
        yearly: yearlyData,
        rollingAverage: rollingAverageData,
        years: availableYears.reduce((acc, year) => {
          // Create monthly data for each year with all 12 months
          const yearData = [];
          for (let month = 1; month <= 12; month++) {
            const monthKey = `${month.toString().padStart(2, '0')}/${year}`;
            const monthData = monthlyData.find(m => m.name === monthKey);

            if (monthData) {
              yearData.push(monthData);
            } else {
              // Create empty data for this month
              const date = new Date(parseInt(year), month - 1, 1);
              yearData.push({
                name: monthKey,
                displayName: format(date, 'MMM yyyy', { locale: es }),
                ars: 0,
                usd: 0,
                count: 0,
                month,
                year: parseInt(year)
              });
            }
          }

          // Sort by month
          yearData.sort((a, b) => a.month - b.month);
          acc[year] = yearData;
          return acc;
        }, {}),
      };
    }

    // Get selected worker data
    const workerData = workersEarnings.find(w => w.name === selectedWorker);

    if (!workerData) {
      return {
        monthly: [],
        yearly: [],
        rollingAverage: [],
        years: {},
      };
    }

    // Filter monthly data for selected worker
    const filteredMonthly = monthlyData.map(month => ({
      name: month.name,
      displayName: month.displayName,
      ars: month[`${selectedWorker}_ars`] || 0,
      usd: month[`${selectedWorker}_usd`] || 0,
      month: month.month,
      year: month.year
    }));

    // Filter yearly data for selected worker
    const filteredYearly = yearlyData.map(year => ({
      name: year.name,
      ars: year[`${selectedWorker}_ars`] || 0,
      usd: year[`${selectedWorker}_usd`] || 0
    }));

    // Filter rolling average data for selected worker
    const filteredRollingAverage = rollingAverageData.map(month => ({
      name: month.name,
      displayName: month.displayName,
      ars: month[`${selectedWorker}_ars`] || 0,
      usd: month[`${selectedWorker}_usd`] || 0,
      avgARS: month[`${selectedWorker}_avgARS`] || 0,
      avgUSD: month[`${selectedWorker}_avgUSD`] || 0,
      month: month.month,
      year: month.year
    }));

    // Create yearly data with all 12 months for each year
    const yearlyMonthlyData = availableYears.reduce((acc, year) => {
      // Create monthly data for each year with all 12 months
      const yearData = [];
      for (let month = 1; month <= 12; month++) {
        const monthKey = `${month.toString().padStart(2, '0')}/${year}`;
        const monthData = filteredMonthly.find(m => m.name === monthKey);

        if (monthData) {
          yearData.push(monthData);
        } else {
          // Create empty data for this month
          const date = new Date(parseInt(year), month - 1, 1);
          yearData.push({
            name: monthKey,
            displayName: format(date, 'MMM yyyy', { locale: es }),
            ars: 0,
            usd: 0,
            month,
            year: parseInt(year)
          });
        }
      }

      // Sort by month
      yearData.sort((a, b) => a.month - b.month);
      acc[year] = yearData;
      return acc;
    }, {});

    return {
      monthly: filteredMonthly,
      yearly: filteredYearly,
      rollingAverage: filteredRollingAverage,
      years: yearlyMonthlyData
    };
  }, [monthlyData, yearlyData, rollingAverageData, selectedWorker, availableYears, workersEarnings]);

  // Load data on component mount
  useEffect(() => {
    fetchData();
  }, []);

  const handleTabChange = (event, newValue) => setTabValue(newValue);
  const handleTimeframeChange = (event, newValue) => setTimeframeTab(newValue);
  const handleWorkerChange = (worker) => setSelectedWorker(worker);
  const handleCurrencyChange = (event) => setCurrency(event.target.value);
  const toggleAverageView = () => setShowAverage(!showAverage);
  const handleYearChange = (event) => setSelectedYear(event.target.value);

  // Get data for current timeframe
  const getTimeframeData = () => {
    let data = filteredData.monthly;

    // For rolling average, include that data if available
    if (filteredData.rollingAverage.length > 0) {
      // Map rolling average data to normal data structure
      data = data.map(monthData => {
        const rollingDataPoint = filteredData.rollingAverage.find(
          r => r.month === monthData.month && r.year === monthData.year
        );

        if (rollingDataPoint) {
          return {
            ...monthData,
            avgARS: rollingDataPoint.avgARS,
            avgUSD: rollingDataPoint.avgUSD
          };
        }
        return monthData;
      });
    }

    switch (timeframeTab) {
      case 0: { // Año móvil (últimos 12 meses)
        const currentDate = new Date();
        const oneYearAgo = new Date(currentDate);
        oneYearAgo.setFullYear(currentDate.getFullYear() - 1);

        return data.filter(item => {
          const itemDate = new Date(item.year, item.month - 1, 1);
          return itemDate >= oneYearAgo;
        });
      }
      case 1: { // Año específico
        // Return data for all months in the selected year
        const yearInt = parseInt(selectedYear);
        return filteredData.years[selectedYear] || [];
      }
      case 2: // Todo
        return data;
      default:
        return data;
    }
  };

  // Custom tooltip for charts
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      // Find the original data point to get the formatted date
      const dataPoint = getTimeframeData().find(d => d.name === label);
      const displayName = dataPoint?.displayName || label;

      return (
        <Paper sx={{ p: 2, boxShadow: theme.shadows[3], borderRadius: 2 }}>
          <Typography variant="subtitle2" gutterBottom>{displayName}</Typography>
          {payload.map((entry, index) => (
            <Box key={`tooltip-item-${index}`} sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
              <Box component="span" sx={{ width: 12, height: 12, backgroundColor: entry.color, mr: 1, borderRadius: '50%' }} />
              <Typography variant="body2" color="text.secondary">
                {entry.name.includes('avg') ? 'Promedio Anual Móvil: ' : ''}
                {formatCurrency(entry.value, entry.name.includes('ars') ? 'ARS' : 'USD')}
              </Typography>
            </Box>
          ))}
        </Paper>
      );
    }
    return null;
  };

  // Get selected worker data
  const selectedWorkerData = useMemo(() => {
    if (selectedWorker === 'all') return null;
    return workersEarnings.find(worker => worker.name === selectedWorker);
  }, [workersEarnings, selectedWorker]);

  // Get socios (admin workers) data
  const sociosData = useMemo(() => {
    return workersEarnings.filter(worker => worker.permisos && worker.permisos.toLowerCase().includes('admin'));
  }, [workersEarnings]);

  const hasData = cobros.length > 0;

  return (
    <Container maxWidth="xl" sx={{ py: { xs: 2, md: 4 } }}>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {/* Header Section */}
          <Box sx={{ mb: 4 }}>
            <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: { xs: 'flex-start', md: 'center' }, mb: 2 }}>
              <Box>
                <Typography variant="h4" component="h1" fontWeight="800" gutterBottom>
                  Dashboard de Trabajadores
                </Typography>
                <Typography variant="subtitle1" color="text.secondary">
                  Análisis de cobros y ganancias detallado
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', gap: 2, mt: { xs: 2, md: 0 }, flexWrap: 'wrap' }}>
                <Button
                  variant="outlined"
                  startIcon={<Refresh />}
                  onClick={fetchData}
                  disabled={loading}
                >
                  Actualizar
                </Button>
                <FormControl size="small" sx={{ minWidth: 120 }}>
                  <InputLabel id="currency-select-label">Moneda</InputLabel>
                  <Select
                    labelId="currency-select-label"
                    value={currency}
                    label="Moneda"
                    onChange={handleCurrencyChange}
                  >
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="ARS">ARS</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
            <Divider />
          </Box>

          {error && <ErrorAlert severity="error">Error al cargar datos: {error}</ErrorAlert>}

          <Box sx={{ mb: 4 }}>
            <Typography variant="subtitle1" fontWeight="600" gutterBottom>Filtrar por trabajador:</Typography>
            <StyledWorkersContainer>
              <WorkerChip
                label="Todos"
                avatar={<Avatar sx={{ bgcolor: theme.palette.primary.main }}>A</Avatar>}
                onClick={() => handleWorkerChange('all')}
                selected={selectedWorker === 'all'}
              />
              {trabajadores.map(worker => (
                <WorkerChip
                  key={worker.id}
                  label={worker.nombre_del_trabajador}
                  avatar={
                    <Avatar sx={{
                      bgcolor: COLORS[worker.id % COLORS.length]
                    }}>
                      {worker.nombre_del_trabajador.charAt(0)}
                    </Avatar>
                  }
                  onClick={() => handleWorkerChange(worker.nombre_del_trabajador)}
                  selected={selectedWorker === worker.nombre_del_trabajador}
                />
              ))}
            </StyledWorkersContainer>
          </Box>

          {loading ? (
            <Grid container spacing={3}>
              {[...Array(9)].map((_, i) => (
                <Grid item xs={12} sm={6} md={4} key={i}>
                  <Skeleton variant="rounded" height={160} animation="wave" sx={{ borderRadius: 3 }} />
                </Grid>
              ))}
              <Grid item xs={12}>
                <Skeleton variant="rounded" height={400} animation="wave" sx={{ borderRadius: 3 }} />
              </Grid>
            </Grid>
          ) : (
            <>
              {/* KPIs Section */}
              <Grid container spacing={3} sx={{ mb: 4 }}>
                {/* Total Ganado */}
                <Grid item xs={12} sm={6} md={4}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.1 }}
                  >
                    <StyledCard gradient accentcolor="primary">
                      <GlowingBackground />
                      <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="subtitle2" fontWeight="600">Total Ganado</Typography>
                          <AttachMoney fontSize="small" />
                        </Box>
                        <Typography variant="h4" fontWeight="800">
                          {formatCurrency(
                            selectedWorkerData
                              ? (currency === 'USD' ? selectedWorkerData.totalUSD : selectedWorkerData.totalARS)
                              : (currency === 'USD' ? kpis.totalEarningsUSD : kpis.totalEarningsARS),
                            currency
                          )}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          {selectedWorker === 'all'
                            ? `Total histórico en ${selectedWorkerData ? selectedWorkerData.totalMonthCount : kpis.monthlyCount} meses`
                            : `Total para ${selectedWorker} en ${selectedWorkerData ? selectedWorkerData.totalMonthCount : 0} meses`}
                        </Typography>
                      </CardContent>
                    </StyledCard>
                  </motion.div>
                </Grid>

                {/* Promedio Mensual */}
                <Grid item xs={12} sm={6} md={4}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.2 }}
                  >
                    <StyledCard gradient accentcolor="info">
                      <GlowingBackground />
                      <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="subtitle2" fontWeight="600">Promedio Mensual</Typography>
                          <Equalizer fontSize="small" />
                        </Box>
                        <Typography variant="h4" fontWeight="800">
                          {formatCurrency(
                            selectedWorkerData
                              ? (currency === 'USD' ? selectedWorkerData.avgMonthlyUSD : selectedWorkerData.avgMonthlyARS)
                              : (currency === 'USD' ? kpis.averageMonthlyUSD : kpis.averageMonthlyARS),
                            currency
                          )}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                          <KPIValue
                            variant="body2"
                            trending={
                              selectedWorkerData
                                ? (selectedWorkerData.ytdAvgGrowthPercentage > 0 ? 'up' :
                                  selectedWorkerData.ytdAvgGrowthPercentage < 0 ? 'down' : 'neutral')
                                : (kpis.avgMonthlyGrowthPercentage > 0 ? 'up' :
                                  kpis.avgMonthlyGrowthPercentage < 0 ? 'down' : 'neutral')
                            }
                          >
                            {(selectedWorkerData
                              ? selectedWorkerData.ytdAvgGrowthPercentage
                              : kpis.avgMonthlyGrowthPercentage).toFixed(1)}%
                            <TrendingIcon
                              trending={
                                selectedWorkerData
                                  ? (selectedWorkerData.ytdAvgGrowthPercentage > 0 ? 'up' :
                                    selectedWorkerData.ytdAvgGrowthPercentage < 0 ? 'down' : 'neutral')
                                  : (kpis.avgMonthlyGrowthPercentage > 0 ? 'up' :
                                    kpis.avgMonthlyGrowthPercentage < 0 ? 'down' : 'neutral')
                              }
                            />
                          </KPIValue>
                          <Typography variant="body2" color="text.secondary" sx={{ ml: 1 }}>
                            vs. {new Date().getFullYear() - 1}
                          </Typography>
                        </Box>
                        <Typography variant="caption" color="text.secondary">
                          Total histórico / Meses con actividad
                        </Typography>
                      </CardContent>
                    </StyledCard>
                  </motion.div>
                </Grid>

                {/* YTD */}
                <Grid item xs={12} sm={6} md={4}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.3 }}
                  >
                    <StyledCard gradient accentcolor="success">
                      <GlowingBackground />
                      <CardContent sx={{ position: 'relative', zIndex: 1 }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="subtitle2" fontWeight="600">
                            YTD {new Date().getFullYear()}
                          </Typography>
                          <DateRange fontSize="small" />
                        </Box>
                        <Typography variant="h4" fontWeight="800">
                          {formatCurrency(
                            selectedWorkerData
                              ? (currency === 'USD' ? selectedWorkerData.ytdUSD : selectedWorkerData.ytdARS)
                              : (currency === 'USD' ? kpis.ytdEarningsUSD : kpis.ytdEarningsARS),
                            currency
                          )}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                          <KPIValue
                            variant="body2"
                            trending={
                              selectedWorkerData
                                ? (selectedWorkerData.ytdGrowthPercentage > 0 ? 'up' :
                                  selectedWorkerData.ytdGrowthPercentage < 0 ? 'down' : 'neutral')
                                : (kpis.ytdGrowthPercentage > 0 ? 'up' :
                                  kpis.ytdGrowthPercentage < 0 ? 'down' : 'neutral')
                            }
                          >
                            {(selectedWorkerData
                              ? selectedWorkerData.ytdGrowthPercentage
                              : kpis.ytdGrowthPercentage).toFixed(1)}%
                            <TrendingIcon
                              trending={
                                selectedWorkerData
                                  ? (selectedWorkerData.ytdGrowthPercentage > 0 ? 'up' :
                                    selectedWorkerData.ytdGrowthPercentage < 0 ? 'down' : 'neutral')
                                  : (kpis.ytdGrowthPercentage > 0 ? 'up' :
                                    kpis.ytdGrowthPercentage < 0 ? 'down' : 'neutral')
                              }
                            />
                          </KPIValue>
                        </Box>
                        <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
                          YTD {new Date().getFullYear() - 1}: {formatCurrency(
                            selectedWorkerData
                              ? (currency === 'USD' ? selectedWorkerData.prevYtdUSD : selectedWorkerData.prevYtdARS)
                              : (currency === 'USD' ? kpis.prevYtdEarningsUSD : kpis.prevYtdEarningsARS),
                            currency
                          )}
                        </Typography>
                      </CardContent>
                    </StyledCard>
                  </motion.div>
                </Grid>

                {/* YTD Promedio Mensual */}
                <Grid item xs={12} sm={6} md={4}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.4 }}
                  >
                    <StyledCard>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="subtitle2" fontWeight="600" color="text.secondary">
                            YTD Promedio Mensual
                          </Typography>
                          <QueryStats sx={{ color: theme.palette.primary.main }} fontSize="small" />
                        </Box>
                        <Typography variant="h4" fontWeight="800" color="text.primary">
                          {formatCurrency(
                            selectedWorkerData
                              ? (currency === 'USD' ? selectedWorkerData.ytdAvgMonthlyUSD : selectedWorkerData.ytdAvgMonthlyARS)
                              : (currency === 'USD' ? kpis.ytdAvgMonthlyUSD : kpis.ytdAvgMonthlyARS),
                            currency
                          )}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                          <KPIValue
                            variant="body2"
                            trending={
                              selectedWorkerData
                                ? (selectedWorkerData.ytdAvgGrowthPercentage > 0 ? 'up' :
                                  selectedWorkerData.ytdAvgGrowthPercentage < 0 ? 'down' : 'neutral')
                                : (kpis.ytdAvgGrowthPercentage > 0 ? 'up' :
                                  kpis.ytdAvgGrowthPercentage < 0 ? 'down' : 'neutral')
                            }
                          >
                            {(selectedWorkerData
                              ? selectedWorkerData.ytdAvgGrowthPercentage
                              : kpis.ytdAvgGrowthPercentage).toFixed(1)}%
                            <TrendingIcon
                              trending={
                                selectedWorkerData
                                  ? (selectedWorkerData.ytdAvgGrowthPercentage > 0 ? 'up' :
                                    selectedWorkerData.ytdAvgGrowthPercentage < 0 ? 'down' : 'neutral')
                                  : (kpis.ytdAvgGrowthPercentage > 0 ? 'up' :
                                    kpis.ytdAvgGrowthPercentage < 0 ? 'down' : 'neutral')
                              }
                            />
                          </KPIValue>
                        </Box>
                        <Typography variant="caption" sx={{ display: 'block', mt: 1 }}>
                          YTD {new Date().getFullYear() - 1} Prom.: {formatCurrency(
                            selectedWorkerData
                              ? (currency === 'USD' ? selectedWorkerData.prevYtdAvgMonthlyUSD : selectedWorkerData.prevYtdAvgMonthlyARS)
                              : (currency === 'USD' ? kpis.prevYtdAvgMonthlyUSD : kpis.prevYtdAvgMonthlyARS),
                            currency
                          )}
                        </Typography>
                      </CardContent>
                    </StyledCard>
                  </motion.div>
                </Grid>

                {/* Mejor Mes */}
                <Grid item xs={12} sm={6} md={4}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.5 }}
                  >
                    <StyledCard>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="subtitle2" fontWeight="600" color="text.secondary">Mejor Mes</Typography>
                          <Star sx={{ color: theme.palette.warning.main }} fontSize="small" />
                        </Box>
                        <Typography variant="h4" fontWeight="800" color="text.primary">
                          {selectedWorkerData
                            ? formatCurrency(
                              Math.max(...selectedWorkerData.monthlyEarnings.map(e =>
                                currency === 'USD' ? e.usd : e.ars)) || 0,
                              currency
                            )
                            : formatCurrency(
                              currency === 'USD'
                                ? kpis.bestMonthUSD.value
                                : kpis.bestMonthARS.value,
                              currency
                            )}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          {selectedWorkerData
                            ? (selectedWorkerData.monthlyEarnings.length > 0
                              ? selectedWorkerData.monthlyEarnings.reduce((best, curr) =>
                                (currency === 'USD' ? curr.usd > best.usd : curr.ars > best.ars) ? curr : best,
                                { usd: 0, ars: 0 }).formattedDate
                              : '-')
                            : (currency === 'USD'
                              ? kpis.bestMonthUSD.formattedDate
                              : kpis.bestMonthARS.formattedDate)}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Mes con mayor facturación histórico
                        </Typography>
                      </CardContent>
                    </StyledCard>
                  </motion.div>
                </Grid>

                {/* Promedio vs Año Anterior */}
                <Grid item xs={12} sm={6} md={4}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.6 }}
                  >
                    <StyledCard>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="subtitle2" fontWeight="600" color="text.secondary">
                            Promedio Año Anterior
                          </Typography>
                          <CompareArrows sx={{ color: theme.palette.info.main }} fontSize="small" />
                        </Box>
                        <Typography variant="h4" fontWeight="800" color="text.primary">
                          {formatCurrency(
                            selectedWorkerData
                              ? (currency === 'USD' ?
                                (selectedWorkerData.monthlyEarnings
                                  .filter(m => m.year === new Date().getFullYear() - 1)
                                  .reduce((sum, m) => sum + m.usd, 0) /
                                  selectedWorkerData.monthlyEarnings
                                    .filter(m => m.year === new Date().getFullYear() - 1).length || 0) :
                                (selectedWorkerData.monthlyEarnings
                                  .filter(m => m.year === new Date().getFullYear() - 1)
                                  .reduce((sum, m) => sum + m.ars, 0) /
                                  selectedWorkerData.monthlyEarnings
                                    .filter(m => m.year === new Date().getFullYear() - 1).length || 0)
                              )
                              : (currency === 'USD'
                                ? kpis.prevYearAvgMonthlyUSD
                                : kpis.prevYearAvgMonthlyARS),
                            currency
                          )}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          {`Promedio mensual de ${new Date().getFullYear() - 1}`}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Total del año / Meses con actividad
                        </Typography>
                      </CardContent>
                    </StyledCard>
                  </motion.div>
                </Grid>

                {/* Acumulado este año */}
                <Grid item xs={12} sm={6} md={4}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.7 }}
                  >
                    <StyledCard>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="subtitle2" fontWeight="600" color="text.secondary">
                            Acumulado {new Date().getFullYear()}
                          </Typography>
                          <MonetizationOn sx={{ color: theme.palette.success.main }} fontSize="small" />
                        </Box>
                        <Typography variant="h4" fontWeight="800" color="text.primary">
                          {formatCurrency(
                            selectedWorkerData
                              ? (currency === 'USD' ?
                                selectedWorkerData.monthlyEarnings
                                  .filter(m => m.year === new Date().getFullYear())
                                  .reduce((sum, m) => sum + m.usd, 0) :
                                selectedWorkerData.monthlyEarnings
                                  .filter(m => m.year === new Date().getFullYear())
                                  .reduce((sum, m) => sum + m.ars, 0)
                              )
                              : (currency === 'USD'
                                ? yearlyData.find(y => y.name === new Date().getFullYear().toString())?.usd || 0
                                : yearlyData.find(y => y.name === new Date().getFullYear().toString())?.ars || 0),
                            currency
                          )}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          Total facturado en {new Date().getFullYear()}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Expectativa anual: {formatCurrency(
                            selectedWorkerData
                              ? (currency === 'USD' ?
                                (selectedWorkerData.monthlyEarnings
                                  .filter(m => m.year === new Date().getFullYear())
                                  .reduce((sum, m) => sum + m.usd, 0) /
                                  (new Date().getMonth() + 1)) * 12 :
                                (selectedWorkerData.monthlyEarnings
                                  .filter(m => m.year === new Date().getFullYear())
                                  .reduce((sum, m) => sum + m.ars, 0) /
                                  (new Date().getMonth() + 1)) * 12
                              )
                              : (currency === 'USD'
                                ? (yearlyData.find(y => y.name === new Date().getFullYear().toString())?.usd || 0) /
                                (new Date().getMonth() + 1) * 12
                                : (yearlyData.find(y => y.name === new Date().getFullYear().toString())?.ars || 0) /
                                (new Date().getMonth() + 1) * 12),
                            currency
                          )}
                        </Typography>
                      </CardContent>
                    </StyledCard>
                  </motion.div>
                </Grid>

                {/* Tendencia */}
                <Grid item xs={12} sm={6} md={4}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.8 }}
                  >
                    <StyledCard>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="subtitle2" fontWeight="600" color="text.secondary">
                            Tendencia
                          </Typography>
                          <TrendingUp sx={{ color: theme.palette.warning.main }} fontSize="small" />
                        </Box>
                        <Typography variant="h4" fontWeight="800" color="text.primary">
                          {kpis.monthlyGrowth > 0 ? "↗ Creciendo" : kpis.monthlyGrowth < 0 ? "↘ Decreciendo" : "→ Estable"}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                          Último mes: {Math.abs(kpis.monthlyGrowth).toFixed(1)}%
                          {kpis.monthlyGrowth > 0 ? " de crecimiento" : kpis.monthlyGrowth < 0 ? " de caída" : " sin cambios"}
                        </Typography>
                        <Typography variant="caption" color="text.secondary">
                          Basado en los últimos 2 meses
                        </Typography>
                      </CardContent>
                    </StyledCard>
                  </motion.div>
                </Grid>

                {/* Balance entre Socios */}
                <Grid item xs={12} sm={6} md={4}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.9 }}
                  >
                    <StyledCard>
                      <CardContent>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="subtitle2" fontWeight="600" color="text.secondary">
                            Balance entre Socios
                          </Typography>
                          <Balance sx={{
                            color:
                              sociosBalance.status === 'balanced'
                                ? theme.palette.success.main
                                : sociosBalance.status === 'moderate'
                                  ? theme.palette.warning.main
                                  : theme.palette.error.main
                          }} fontSize="small" />
                        </Box>
                        <Typography variant="h4" fontWeight="800" color="text.primary">
                          {(100 - sociosBalance.percentage).toFixed(1)}%
                        </Typography>
                        <Typography
                          variant="body2"
                          sx={{
                            mt: 1,
                            color:
                              sociosBalance.status === 'balanced'
                                ? theme.palette.success.main
                                : sociosBalance.status === 'moderate'
                                  ? theme.palette.warning.main
                                  : theme.palette.error.main
                          }}
                        >
                          {sociosBalance.status === 'balanced'
                            ? 'Excelente balance'
                            : sociosBalance.status === 'moderate'
                              ? 'Balance aceptable'
                              : 'Requiere ajuste'}
                          {' '}(Diferencia: {formatCurrency(sociosBalance.disparity, 'USD')})
                        </Typography>
                      </CardContent>
                    </StyledCard>
                  </motion.div>
                </Grid>
              </Grid>

              {/* Distribution Card (Balance entre Socios) */}
              <Grid container spacing={3} sx={{ mb: 4 }}>
                <Grid item xs={12}>
                  <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.3, delay: 0.7 }}
                  >
                    <StyledCard>
                      <CardContent>
                        <CardHeader>
                          <Typography variant="h6" fontWeight="700">Distribución entre Socios ({currency})</Typography>
                          {sociosBalance.status === 'balanced' && (
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <Celebration sx={{ color: theme.palette.success.main, mr: 1 }} />
                              <Typography variant="subtitle2" color="success.main">
                                Excelente balance entre socios
                              </Typography>
                            </Box>
                          )}
                        </CardHeader>

                        <Grid container spacing={3}>
                          <Grid item xs={12} md={5}>
                            <Box sx={{ height: 250 }}>
                              <ResponsiveContainer width="100%" height="100%">
                                <PieChart>
                                  <Pie
                                    data={sociosData.map(worker => ({
                                      name: worker.name,
                                      value: currency === 'USD' ? worker.totalUSD : worker.totalARS
                                    }))}
                                    cx="50%"
                                    cy="50%"
                                    labelLine={false}
                                    outerRadius={isMobile ? 90 : 100}
                                    fill="#8884d8"
                                    dataKey="value"
                                    nameKey="name"
                                    label={({ name, percent }) =>
                                      `${name}: ${(percent * 100).toFixed(1)}%`
                                    }
                                    animationDuration={1500}
                                  >
                                    {sociosData.map((entry, index) => (
                                      <Cell
                                        key={`cell-${index}`}
                                        fill={COLORS[index % COLORS.length]}
                                      />
                                    ))}
                                  </Pie>
                                  <RechartsTooltip
                                    formatter={(value) => [formatCurrency(value, currency), "Total"]}
                                    content={<CustomTooltip />}
                                  />
                                </PieChart>
                              </ResponsiveContainer>
                            </Box>
                          </Grid>

                          <Grid item xs={12} md={7}>
                            <Box sx={{ height: 250, pl: { xs: 0, md: 4 } }}>
                              <Typography variant="subtitle1" gutterBottom>
                                Balance de ingresos entre socios
                              </Typography>

                              {sociosData.length >= 2 ? (
                                <>
                                  <Typography variant="body1" paragraph>
                                    Los socios deben tener una distribución equitativa de ingresos para mantener
                                    un balance justo en la empresa.
                                  </Typography>

                                  <Box sx={{ mt: 3 }}>
                                    <Grid container spacing={2}>
                                      {sociosData.map(socio => (
                                        <Grid item xs={12} key={socio.id}>
                                          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                            <Typography variant="body2" fontWeight="600">
                                              {socio.name}
                                            </Typography>
                                            <Typography variant="body2">
                                              {formatCurrency(
                                                currency === 'USD' ? socio.totalUSD : socio.totalARS,
                                                currency
                                              )}
                                            </Typography>
                                          </Box>
                                          <Box
                                            sx={{
                                              width: '100%',
                                              height: 8,
                                              borderRadius: 4,
                                              bgcolor: 'background.default',
                                              overflow: 'hidden'
                                            }}
                                          >
                                            <Box
                                              sx={{
                                                width: `${(
                                                  (currency === 'USD' ? socio.totalUSD : socio.totalARS) /
                                                  (currency === 'USD'
                                                    ? Math.max(...sociosData.map(s => s.totalUSD))
                                                    : Math.max(...sociosData.map(s => s.totalARS))
                                                  ) * 100
                                                )}%`,
                                                height: '100%',
                                                borderRadius: 4,
                                                bgcolor: COLORS[socio.id % COLORS.length]
                                              }}
                                            />
                                          </Box>
                                        </Grid>
                                      ))}
                                    </Grid>
                                  </Box>

                                  <Typography
                                    variant="body2"
                                    sx={{
                                      mt: 2,
                                      color:
                                        sociosBalance.status === 'balanced'
                                          ? theme.palette.success.main
                                          : sociosBalance.status === 'moderate'
                                            ? theme.palette.warning.main
                                            : theme.palette.error.main
                                    }}
                                  >
                                    {sociosBalance.status === 'balanced'
                                      ? 'La diferencia actual entre socios es ideal, por debajo del 5%.'
                                      : sociosBalance.status === 'moderate'
                                        ? 'La diferencia actual entre socios es aceptable, pero podría mejorar.'
                                        : 'La diferencia actual entre socios es significativa y requiere atención.'}
                                  </Typography>
                                </>
                              ) : (
                                <Typography variant="body1">
                                  No hay suficientes socios para comparar el balance.
                                </Typography>
                              )}
                            </Box>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </StyledCard>
                  </motion.div>
                </Grid>
              </Grid>

              {/* Main chart section */}
              <Box sx={{ mb: 4 }}>
                {/* Tabs for timeframe selection */}
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                  <StyledTabs value={timeframeTab} onChange={handleTimeframeChange} variant="scrollable" scrollButtons="auto">
                    <Tab label="Año Móvil" />
                    <Tab label="Año Específico" />
                    <Tab label="Todo" />
                  </StyledTabs>

                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {timeframeTab === 1 && (
                      <FormControl size="small" sx={{ minWidth: 100 }}>
                        <InputLabel id="year-select-label">Año</InputLabel>
                        <Select
                          labelId="year-select-label"
                          value={selectedYear}
                          label="Año"
                          onChange={handleYearChange}
                        >
                          {availableYears.map(year => (
                            <MenuItem key={year} value={year}>{year}</MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}

                    <FormControlLabel
                      control={<Switch checked={showAverage} onChange={toggleAverageView} />}
                      label="Mostrar Promedio Anual Móvil"
                    />
                  </Box>
                </Box>

                {/* Monthly evolution chart */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.4 }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      p: 3,
                      borderRadius: 4,
                      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.08)',
                      height: 500,
                      mb: 4
                    }}
                  >
                    <Typography variant="h6" gutterBottom fontWeight="700">
                      Evolución Mensual de Cobros ({currency})
                      {selectedWorker !== 'all' && ` - ${selectedWorker}`}
                    </Typography>

                    <ResponsiveContainer width="100%" height="90%">
                      <ComposedChart data={getTimeframeData()} margin={{ top: 20, right: 20, left: 20, bottom: 20 }}>
                        <CartesianGrid strokeDasharray="3 3" opacity={0.1} vertical={false} />
                        <XAxis
                          dataKey="displayName"
                          tick={{ fill: theme.palette.text.secondary }}
                          axisLine={{ stroke: theme.palette.divider }}
                          tickLine={false}
                        />
                        <YAxis
                          tickFormatter={(value) => formatCurrency(value, currency, 0)}
                          tick={{ fill: theme.palette.text.secondary }}
                          axisLine={{ stroke: theme.palette.divider }}
                          tickLine={false}
                        />
                        <RechartsTooltip content={<CustomTooltip />} />
                        <Legend />

                        {/* Main bar chart */}
                        <Bar
                          dataKey={currency.toLowerCase()}
                          name={currency}
                          radius={[8, 8, 0, 0]}
                          barSize={30}
                          animationDuration={1500}
                        >
                          {timeframeTab === 1 && getTimeframeData().map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={MONTH_COLORS[entry.month - 1]} />
                          ))}
                          {timeframeTab !== 1 && (
                            <Cell fill={theme.palette.primary.main} />
                          )}
                        </Bar>

                        {/* Rolling average line (if enabled) */}
                        {showAverage && (
                          <Line
                            type="monotone"
                            dataKey={`avg${currency}`}
                            name={`Promedio Anual Móvil (${currency})`}
                            stroke={theme.palette.secondary.main}
                            strokeWidth={3}
                            dot={{ r: 4, strokeWidth: 2 }}
                            activeDot={{ r: 6 }}
                          />
                        )}
                      </ComposedChart>
                    </ResponsiveContainer>
                  </Paper>
                </motion.div>

                {/* Year over Year comparison chart */}
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      p: 3,
                      borderRadius: 4,
                      boxShadow: '0 10px 30px rgba(0, 0, 0, 0.08)',
                      mb: 4
                    }}
                  >
                    <CardHeader>
                      <Typography variant="h6" fontWeight="700">
                        Comparación Anual ({currency})
                        {selectedWorker !== 'all' && ` - ${selectedWorker}`}
                      </Typography>
                    </CardHeader>

                    <Box sx={{ height: 400 }}>
                      <ResponsiveContainer width="100%" height="100%">
                        <RechartsBarChart
                          data={filteredData.yearly}
                          margin={{ top: 20, right: 30, left: 20, bottom: 20 }}
                        >
                          <CartesianGrid strokeDasharray="3 3" opacity={0.1} vertical={false} />
                          <XAxis
                            dataKey="name"
                            tick={{ fill: theme.palette.text.secondary }}
                            axisLine={{ stroke: theme.palette.divider }}
                            tickLine={false}
                          />
                          <YAxis
                            tickFormatter={(value) => formatCurrency(value, currency, 0)}
                            tick={{ fill: theme.palette.text.secondary }}
                            axisLine={{ stroke: theme.palette.divider }}
                            tickLine={false}
                          />
                          <RechartsTooltip content={<CustomTooltip />} />
                          <Legend />
                          <Bar
                            dataKey={currency.toLowerCase()}
                            fill={theme.palette.success.main}
                            name={currency}
                            radius={[8, 8, 0, 0]}
                            barSize={40}
                            animationDuration={1500}
                          />
                        </RechartsBarChart>
                      </ResponsiveContainer>
                    </Box>
                  </Paper>
                </motion.div>
              </Box>

              {/* No data message */}
              {!hasData && !loading && (
                <Box sx={{ textAlign: 'center', py: 8 }}>
                  <Typography variant="h6" gutterBottom>
                    No hay datos disponibles
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Aún no se han registrado cobros para visualizar.
                  </Typography>
                  <Button
                    variant="contained"
                    sx={{ mt: 2 }}
                    onClick={() => window.location.href = '/cobros/nuevo'}
                  >
                    Registrar Cobro
                  </Button>
                </Box>
              )}
            </>
          )}
        </motion.div>
      </AnimatePresence>
    </Container>
  );
};

export default DashboardTrabajadores;