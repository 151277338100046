// src/utils/imageUtils.js
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import { supabase } from '../supabaseClient';

/**
 * Formatea una URL de imagen agregando un parámetro para evitar caché
 * @param {string} url - La URL original de la imagen
 * @returns {string} - URL con parámetro anti-caché
 */
export const formatImageUrl = (url) => {
  if (!url) return null;

  // Agregar timestamp para evitar problemas de caché
  const cacheBuster = `t=${Date.now()}`;

  // Ensure URL is clean before adding parameters
  let cleanUrl = url;
  if (cleanUrl.includes('?')) {
    cleanUrl = cleanUrl.split('?')[0];
  }

  return `${cleanUrl}?${cacheBuster}`;
};

/**
 * Sube una imagen al bucket "Comprobantes" de Supabase
 * @param {File} file - El archivo a subir
 * @param {Object} metadata - Metadatos adicionales
 * @returns {Promise<Object>} - Información de la imagen subida
 */
export const uploadImage = async (file, metadata = {}) => {
  if (!file) return null;

  try {
    // Extraer extensión del archivo
    const fileExt = file.name.split('.').pop();

    // Generar un ID único para el archivo
    const uniqueId = uuidv4();

    // Use custom filename if provided or generate one
    let fileName;
    if (metadata.customFilename) {
      fileName = metadata.customFilename;
    } else {
      // Formato: YYYYMMDD_TIPO_REFERENCIA_ID.ext
      const date = format(new Date(), 'yyyyMMdd');
      const tipo = metadata.tipo || 'entrada';
      const referencia = metadata.referencia || 'general';
      fileName = `${date}_${tipo}_${referencia}_${uniqueId}.${fileExt}`;
    }

    // Create a more reliable code for the document
    const date = format(new Date(), 'yyyyMMdd');
    const tipo = metadata.tipo ? metadata.tipo.substring(0, 3).toUpperCase() : 'GEN';
    const shortId = uniqueId.substring(0, 6).toUpperCase();

    // Use existing code if provided
    const codigoComprobante = metadata.codigo || `${tipo}-${date}-${shortId}`;

    // Subir archivo a Supabase Storage (bucket "Comprobantes")
    const { data, error } = await supabase.storage
      .from('Comprobantes')
      .upload(fileName, file, {
        cacheControl: '3600',
        upsert: true // Changed to true to overwrite if exists
      });

    if (error) {
      console.error('Error uploading to Supabase:', error);
      throw error;
    }

    // Obtener URL pública del archivo
    const { data: urlData } = supabase.storage
      .from('Comprobantes')
      .getPublicUrl(fileName);

    if (!urlData || !urlData.publicUrl) {
      throw new Error('No se pudo obtener la URL pública del archivo');
    }

    // Devolver información completa
    return {
      url: urlData.publicUrl,
      codigo: codigoComprobante,
      ruta: fileName
    };
  } catch (error) {
    console.error('Error al subir imagen:', error);
    throw error;
  }
};

/**
 * Verifica si una URL de imagen es válida
 * @param {string} url - La URL a verificar
 * @returns {Promise<boolean>} - true si la imagen es accesible
 */
export const isImageUrlValid = async (url) => {
  if (!url) return false;

  try {
    // Try with a HEAD request first (lighter)
    const response = await fetch(url, {
      method: 'HEAD',
      cache: 'no-cache', // Prevent caching
      headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache'
      }
    });

    return response.ok;
  } catch (error) {
    console.error('Error verificando URL de imagen:', error);

    // Fallback - try removing cache busters
    if (url.includes('?')) {
      try {
        const cleanUrl = url.split('?')[0];
        const fallbackResponse = await fetch(cleanUrl, { method: 'HEAD' });
        return fallbackResponse.ok;
      } catch {
        return false;
      }
    }

    return false;
  }
};