// src/components/AdminProjects.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
  TextField,
  FormControlLabel,
  Switch,
  MenuItem,
  Select,
  styled,
  CircularProgress,
  Alert,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CloseIcon from '@mui/icons-material/Close';
import UploadIcon from '@mui/icons-material/Upload';
import ImageIcon from '@mui/icons-material/Image';
import { motion } from 'framer-motion';

const ProjectCard = styled(Card)(({ theme }) => ({
  borderRadius: 4,
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  marginBottom: theme.spacing(2),
  transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
  },
}));

const AddProjectDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiPaper-root': {
    borderRadius: 4,
    padding: theme.spacing(2),
    width: '600px',
    maxHeight: '80vh',
    overflowY: 'auto',
    backgroundColor: '#ffffff',
  },
}));

const FancyToggle = styled(Switch)(({ theme }) => ({
  '& .MuiSwitch-track': {
    backgroundColor: '#4caf50',
    opacity: 0.7,
    borderRadius: 12,
  },
  '& .MuiSwitch-thumb': {
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
  },
  '& .Mui-checked': {
    '& .MuiSwitch-track': {
      backgroundColor: '#2e7d32',
    },
    '& .MuiSwitch-thumb': {
      backgroundColor: '#fff',
    },
  },
}));

const UploadBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  border: '2px dashed #ccc',
  borderRadius: 8,
  padding: theme.spacing(3),
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  cursor: 'pointer',
  backgroundColor: '#f5f5f5',
  transition: 'all 0.3s ease',
  '&:hover': {
    backgroundColor: '#e0e0e0',
    borderColor: '#4caf50',
  },
}));

const PreviewImage = styled(Box)(({ theme }) => ({
  width: '100%',
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  borderRadius: 8,
  overflow: 'hidden',
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  '& img': {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
}));

const AdminProjects = () => {
  const [projects, setProjects] = useState([]);
  const [openAddProject, setOpenAddProject] = useState(false);
  const [openEditProject, setOpenEditProject] = useState(false);
  const [projectData, setProjectData] = useState({
    codigo_de_proyecto: '',
    apellido_del_cliente: '',
    email_del_cliente: '',
    ubicacion_del_proyecto: '',
    uf: '',
    metros_cubiertos: '',
    metros_semi_cubiertos: '',
    metros_solados: '',
    monto_honorarios: '',
    monto_inicial_mo: '',
    caja_proyecto_mo: '',
    caja_proyecto: '',
    es_terminada: false,
    mostrar_cajas: true,
    fecha_de_inicio_obra: null,
    fecha_de_finalizacion_obra: null,
    moneda_honorarios: 'USD',
    moneda_mo: 'ARS',
    metodo_de_actualizacion: 'Sin actualización',
    render_photo_path: null,
  });
  const [rowEditing, setRowEditing] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [uploadLoading, setUploadLoading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const fileInputRef = React.useRef(null);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      // Obtener el usuario autenticado y su rol
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) {
        console.log('No hay usuario autenticado');
        return;
      }

      // Obtener el rol usando la función get_user_role
      const { data: userRole, error: roleError } = await supabase.rpc('get_user_role');
      if (roleError) {
        console.log('Error obteniendo el rol:', roleError);
        return;
      }

      // Insertar el rol en la tabla temp_user_roles
      const { error: insertError } = await supabase
        .from('temp_user_roles')
        .upsert({ user_id: user.id, role: userRole }, { onConflict: 'user_id' });
      if (insertError) {
        console.log('Error insertando el rol en temp_user_roles:', insertError);
        return;
      }

      // Hacer la consulta a proyectos
      const { data, error } = await supabase.from('proyectos').select('*');
      if (error) {
        console.log('Error fetching proyectos:', error);
      } else {
        setProjects(data || []);
      }
    } catch (error) {
      console.log('Error en fetchProjects:', error);
    }
  };

  const calculateMetrosDeComputo = (data) => {
    const cubiertos = parseFloat(data.metros_cubiertos) || 0;
    const semiCubiertos = parseFloat(data.metros_semi_cubiertos) || 0;
    const solados = parseFloat(data.metros_solados) || 0;
    return (cubiertos + semiCubiertos * 0.5 + solados * 0.25).toFixed(2);
  };

  const handleInputChange = (field, value) => {
    console.log(`Cambiando ${field} a:`, value); // Depuración
    setProjectData((prev) => ({ ...prev, [field]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Validar tipo de archivo (solo imágenes)
      if (!file.type.match('image.*')) {
        setUploadError('Por favor, selecciona un archivo de imagen válido');
        return;
      }

      // Validar tamaño (máximo 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setUploadError('El archivo es demasiado grande. El tamaño máximo es 5MB');
        return;
      }

      setUploadedFile(file);
      setUploadError('');

      // Crear URL para previsualización
      const reader = new FileReader();
      reader.onload = (event) => {
        setPreviewUrl(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const uploadRenderImage = async (codigoProyecto) => {
    if (!uploadedFile) return null;

    setUploadLoading(true);
    setUploadError('');

    try {
      // Generar nombre de archivo único
      const fileExt = uploadedFile.name.split('.').pop();
      const fileName = `render_${codigoProyecto}_${Date.now()}.${fileExt}`;
      const filePath = `renders/${fileName}`;

      // Subir archivo a Supabase Storage - CORREGIDO EL NOMBRE DEL BUCKET
      const { error: uploadError } = await supabase.storage
        .from('project-renders') // Cambiado de 'proyecto_renders' a 'project-renders'
        .upload(filePath, uploadedFile);

      if (uploadError) throw uploadError;

      // Obtener URL pública
      const { data: publicUrlData } = supabase.storage
        .from('project-renders') // Cambiado también aquí
        .getPublicUrl(filePath);

      if (!publicUrlData || !publicUrlData.publicUrl) {
        throw new Error('No se pudo obtener la URL pública de la imagen');
      }

      console.log("URL de imagen subida:", publicUrlData.publicUrl); // Añadir log para depurar

      setUploadSuccess(true);
      return publicUrlData.publicUrl;
    } catch (error) {
      console.error('Error al subir imagen:', error);
      setUploadError(`Error al subir imagen: ${error.message}`);
      return null;
    } finally {
      setUploadLoading(false);
    }
  };

  const handleAddProject = async () => {
    if (!projectData.codigo_de_proyecto || !projectData.apellido_del_cliente || !projectData.fecha_de_inicio_obra) {
      alert('Por favor, completa los campos obligatorios (Código de Proyecto, Apellido del Cliente y Fecha de Inicio).');
      return;
    }

    // Si hay una imagen para subir, procesarla primero
    let renderPhotoPath = null;
    if (uploadedFile) {
      renderPhotoPath = await uploadRenderImage(projectData.codigo_de_proyecto);
      if (!renderPhotoPath && uploadError) {
        // Si hay error en la subida, mostrar mensaje pero continuar con la creación del proyecto
        console.error('No se pudo subir la imagen del render, continuando sin ella');
      }
    }

    const projectToInsert = {
      codigo_de_proyecto: projectData.codigo_de_proyecto,
      apellido_del_cliente: projectData.apellido_del_cliente,
      email_del_cliente: projectData.email_del_cliente || null,
      ubicacion_del_proyecto: projectData.ubicacion_del_proyecto || null,
      uf: projectData.uf || null,
      metros_cubiertos: projectData.metros_cubiertos ? parseFloat(projectData.metros_cubiertos) : null,
      metros_semi_cubiertos: projectData.metros_semi_cubiertos ? parseFloat(projectData.metros_semi_cubiertos) : null,
      metros_solados: projectData.metros_solados ? parseFloat(projectData.metros_solados) : null,
      monto_honorarios: projectData.monto_honorarios ? parseFloat(projectData.monto_honorarios) : null,
      monto_inicial_mo: projectData.monto_inicial_mo ? parseFloat(projectData.monto_inicial_mo) : null,
      caja_proyecto_mo: projectData.caja_proyecto_mo || null,
      caja_proyecto: projectData.caja_proyecto || null,
      es_terminada: projectData.es_terminada,
      mostrar_cajas: projectData.mostrar_cajas ? 'true' : 'false',
      fecha_de_inicio_obra: projectData.fecha_de_inicio_obra?.toISOString().split('T')[0],
      fecha_de_finalizacion_obra: projectData.es_terminada && projectData.fecha_de_finalizacion_obra
        ? projectData.fecha_de_finalizacion_obra.toISOString().split('T')[0]
        : null,
      moneda_honorarios: projectData.moneda_honorarios,
      moneda_mo: projectData.moneda_mo,
      metodo_de_actualizacion: projectData.metodo_de_actualizacion,
      render_photo_path: renderPhotoPath, // Agregar la URL de la imagen
    };

    console.log('Insertando proyecto:', projectToInsert);

    const { error } = await supabase.from('proyectos').insert([projectToInsert]);
    if (error) {
      console.log('Error adding project:', error);
      alert('Error al agregar el proyecto: ' + error.message);
    } else {
      fetchProjects();
      setOpenAddProject(false);
      resetProjectData();
    }
  };

  const handleEditProject = (project) => {
    setRowEditing(project);
    const mostrarCajas = typeof project.mostrar_cajas === 'string'
      ? project.mostrar_cajas === 'true'
      : project.mostrar_cajas;
    setProjectData({
      codigo_de_proyecto: project.codigo_de_proyecto,
      apellido_del_cliente: project.apellido_del_cliente,
      email_del_cliente: project.email_del_cliente || '',
      ubicacion_del_proyecto: project.ubicacion_del_proyecto || '',
      uf: project.uf || '',
      metros_cubiertos: project.metros_cubiertos?.toString() || '',
      metros_semi_cubiertos: project.metros_semi_cubiertos?.toString() || '',
      metros_solados: project.metros_solados?.toString() || '',
      monto_honorarios: project.monto_honorarios?.toString() || '',
      monto_inicial_mo: project.monto_inicial_mo?.toString() || '',
      caja_proyecto_mo: project.caja_proyecto_mo || '',
      caja_proyecto: project.caja_proyecto || '',
      es_terminada: project.es_terminada,
      mostrar_cajas: mostrarCajas,
      fecha_de_inicio_obra: project.fecha_de_inicio_obra ? new Date(project.fecha_de_inicio_obra) : null,
      fecha_de_finalizacion_obra: project.fecha_de_finalizacion_obra ? new Date(project.fecha_de_finalizacion_obra) : null,
      moneda_honorarios: project.moneda_honorarios || 'USD',
      moneda_mo: project.moneda_mo || 'ARS',
      metodo_de_actualizacion: project.metodo_de_actualizacion || 'Sin actualización',
      render_photo_path: project.render_photo_path || null,
    });

    // Si ya existe una imagen, mostrarla en la previsualización
    if (project.render_photo_path) {
      setPreviewUrl(project.render_photo_path);
    } else {
      setPreviewUrl('');
    }

    setUploadedFile(null);
    setUploadError('');
    setUploadSuccess(false);

    console.log('Editando proyecto con mostrar_cajas:', mostrarCajas);
    setOpenEditProject(true);
  };

  const handleUpdateProject = async () => {
    if (!projectData.codigo_de_proyecto || !projectData.apellido_del_cliente || !projectData.fecha_de_inicio_obra) {
      alert('Por favor, completa los campos obligatorios (Código de Proyecto, Apellido del Cliente y Fecha de Inicio).');
      return;
    }

    // Si hay una imagen nueva para subir, procesarla primero
    let renderPhotoPath = projectData.render_photo_path;
    if (uploadedFile) {
      const newPath = await uploadRenderImage(projectData.codigo_de_proyecto);
      if (newPath) {
        renderPhotoPath = newPath;
      }
    }

    const projectToUpdate = {
      codigo_de_proyecto: projectData.codigo_de_proyecto,
      apellido_del_cliente: projectData.apellido_del_cliente,
      email_del_cliente: projectData.email_del_cliente || null,
      ubicacion_del_proyecto: projectData.ubicacion_del_proyecto || null,
      uf: projectData.uf || null,
      metros_cubiertos: projectData.metros_cubiertos ? parseFloat(projectData.metros_cubiertos) : null,
      metros_semi_cubiertos: projectData.metros_semi_cubiertos ? parseFloat(projectData.metros_semi_cubiertos) : null,
      metros_solados: projectData.metros_solados ? parseFloat(projectData.metros_solados) : null,
      monto_honorarios: projectData.monto_honorarios ? parseFloat(projectData.monto_honorarios) : null,
      monto_inicial_mo: projectData.monto_inicial_mo ? parseFloat(projectData.monto_inicial_mo) : null,
      caja_proyecto_mo: projectData.caja_proyecto_mo || null,
      caja_proyecto: projectData.caja_proyecto || null,
      es_terminada: projectData.es_terminada,
      mostrar_cajas: projectData.mostrar_cajas ? 'true' : 'false',
      fecha_de_inicio_obra: projectData.fecha_de_inicio_obra?.toISOString().split('T')[0],
      fecha_de_finalizacion_obra: projectData.es_terminada && projectData.fecha_de_finalizacion_obra
        ? projectData.fecha_de_finalizacion_obra.toISOString().split('T')[0]
        : null,
      moneda_honorarios: projectData.moneda_honorarios,
      moneda_mo: projectData.moneda_mo,
      metodo_de_actualizacion: projectData.metodo_de_actualizacion,
      render_photo_path: renderPhotoPath,
    };

    console.log('Actualizando proyecto con mostrar_cajas:', projectToUpdate.mostrar_cajas);

    const { error } = await supabase
      .from('proyectos')
      .update(projectToUpdate)
      .eq('id', rowEditing.id);
    if (error) {
      console.log('Error updating project:', error);
      alert('Error al actualizar el proyecto: ' + error.message);
    } else {
      await fetchProjects(); // Esperamos que los datos se actualicen
      setOpenEditProject(false);
      setRowEditing(null);
    }
  };

  const handleDeleteProject = async (codigo_de_proyecto) => {
    const { error } = await supabase.from('proyectos').delete().eq('codigo_de_proyecto', codigo_de_proyecto);
    if (error) console.log('Error deleting project:', error);
    else fetchProjects();
  };

  const calculateDuration = (startDate, endDate) => {
    if (!startDate) return 'N/A';
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : new Date();
    const diffMs = end - start;
    const diffDays = Math.floor(diffMs / (1000 * 60 * 60 * 24));
    const months = Math.floor(diffDays / 30);
    const days = diffDays % 30;
    return `${months} meses, ${days} días`;
  };

  const resetProjectData = () => {
    setProjectData({
      codigo_de_proyecto: '',
      apellido_del_cliente: '',
      email_del_cliente: '',
      ubicacion_del_proyecto: '',
      uf: '',
      metros_cubiertos: '',
      metros_semi_cubiertos: '',
      metros_solados: '',
      monto_honorarios: '',
      monto_inicial_mo: '',
      caja_proyecto_mo: '',
      caja_proyecto: '',
      es_terminada: false,
      mostrar_cajas: true,
      fecha_de_inicio_obra: null,
      fecha_de_finalizacion_obra: null,
      moneda_honorarios: 'USD',
      moneda_mo: 'ARS',
      metodo_de_actualizacion: 'Sin actualización',
      render_photo_path: null,
    });
    setUploadedFile(null);
    setPreviewUrl('');
    setUploadError('');
    setUploadSuccess(false);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 3, px: 0, backgroundColor: '#ffffff' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, ml: 0 }}>
        <Typography variant="h4" fontWeight="bold" color="text.primary">
          Proyectos
        </Typography>
        <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              resetProjectData();
              setOpenAddProject(true);
            }}
            sx={{
              borderRadius: 4,
              backgroundColor: '#4caf50',
              '&:hover': { backgroundColor: '#2e7d32' },
            }}
          >
            Agregar Proyecto
          </Button>
        </motion.div>
      </Box>
      <Grid container spacing={2} sx={{ ml: 0 }}>
        {projects.map((project) => {
          const mostrarCajasTexto = typeof project.mostrar_cajas === 'string'
            ? project.mostrar_cajas === 'true' ? 'Sí' : 'No'
            : project.mostrar_cajas ? 'Sí' : 'No';
          return (
            <Grid item xs={12} sm={6} md={4} key={project.codigo_de_proyecto}>
              <ProjectCard>
                <CardContent sx={{ position: 'relative' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <Typography variant="h6" fontWeight="bold" color="text.primary">
                      {project.codigo_de_proyecto}
                    </Typography>
                    {project.es_terminada ? (
                      <CheckCircleIcon sx={{ ml: 1, color: '#4caf50' }} />
                    ) : (
                      <ScheduleIcon sx={{ ml: 1, color: '#ff9800' }} />
                    )}
                  </Box>
                  <Typography variant="body2" color="primary.main">
                    Duración: {calculateDuration(project.fecha_de_inicio_obra, project.fecha_de_finalizacion_obra)}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    Cliente: {project.apellido_del_cliente}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Email: {project.email_del_cliente || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Ubicación: {project.ubicacion_del_proyecto || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    UF: {project.uf || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Metros Cubiertos: {project.metros_cubiertos || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Metros Semi Cubiertos: {project.metros_semi_cubiertos || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Metros Solados: {project.metros_solados || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Metros de Cómputo: {project.metros_de_computo || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Honorarios: ${project.monto_honorarios || '0'} {project.moneda_honorarios || 'USD'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Mano de Obra Inicial: ${project.monto_inicial_mo || '0'} {project.moneda_mo || 'ARS'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Caja Proyecto: {project.caja_proyecto || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Caja Proyecto MO: {project.caja_proyecto_mo || 'N/A'}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Mostrar Cajas: {mostrarCajasTexto}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Método de Actualización: {project.metodo_de_actualizacion || 'Sin actualización'}
                  </Typography>

                  {/* Mostrar imagen del render si existe */}
                  {project.render_photo_path && (
                    <Box
                      mt={2}
                      sx={{
                        borderRadius: 2,
                        overflow: 'hidden',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      }}
                    >
                      <Box
                        component="img"
                        src={project.render_photo_path}
                        alt="Render del proyecto"
                        sx={{
                          width: '100%',
                          height: 'auto',
                          display: 'block',
                        }}
                      />
                    </Box>
                  )}

                  <Box sx={{ position: 'absolute', top: 8, right: 8 }}>
                    <IconButton color="primary" onClick={() => handleEditProject(project)} sx={{ mr: 1 }}>
                      <motion.div whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
                        <EditIcon />
                      </motion.div>
                    </IconButton>
                    <IconButton color="error" onClick={() => handleDeleteProject(project.codigo_de_proyecto)}>
                      <motion.div whileHover={{ scale: 1.1 }} transition={{ duration: 0.3 }}>
                        <DeleteIcon />
                      </motion.div>
                    </IconButton>
                  </Box>
                </CardContent>
              </ProjectCard>
            </Grid>
          );
        })}
      </Grid>

      <AddProjectDialog open={openAddProject} onClose={() => setOpenAddProject(false)}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="h5" fontWeight="bold" color="text.primary" textAlign="center">
            Agregar Proyecto
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenAddProject(false)}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Código de Proyecto"
                  value={projectData.codigo_de_proyecto}
                  onChange={(e) => handleInputChange('codigo_de_proyecto', e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Apellido del Cliente"
                  value={projectData.apellido_del_cliente}
                  onChange={(e) => handleInputChange('apellido_del_cliente', e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email del Cliente"
                  value={projectData.email_del_cliente}
                  onChange={(e) => handleInputChange('email_del_cliente', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Ubicación del Proyecto"
                  value={projectData.ubicacion_del_proyecto}
                  onChange={(e) => handleInputChange('ubicacion_del_proyecto', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="UF"
                  value={projectData.uf}
                  onChange={(e) => handleInputChange('uf', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Metros Cubiertos"
                  type="number"
                  value={projectData.metros_cubiertos}
                  onChange={(e) => handleInputChange('metros_cubiertos', e.target.value)}
                  fullWidth
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Metros Semi Cubiertos"
                  type="number"
                  value={projectData.metros_semi_cubiertos}
                  onChange={(e) => handleInputChange('metros_semi_cubiertos', e.target.value)}
                  fullWidth
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Metros de Cómputo"
                  value={calculateMetrosDeComputo(projectData)}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Honorarios"
                  type="number"
                  value={projectData.monto_honorarios}
                  onChange={(e) => handleInputChange('monto_honorarios', e.target.value)}
                  fullWidth
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  label="Moneda Honorarios"
                  value={projectData.moneda_honorarios}
                  onChange={(e) => handleInputChange('moneda_honorarios', e.target.value)}
                  fullWidth
                  sx={{ borderRadius: 4 }}
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="ARS">ARS</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Mano de Obra Inicial"
                  type="number"
                  value={projectData.monto_inicial_mo}
                  onChange={(e) => handleInputChange('monto_inicial_mo', e.target.value)}
                  fullWidth
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  label="Moneda Mano de Obra"
                  value={projectData.moneda_mo}
                  onChange={(e) => handleInputChange('moneda_mo', e.target.value)}
                  fullWidth
                  sx={{ borderRadius: 4 }}
                >
                  <MenuItem value="ARS">ARS</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Caja Proyecto"
                  value={projectData.caja_proyecto}
                  onChange={(e) => handleInputChange('caja_proyecto', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Caja Proyecto MO"
                  value={projectData.caja_proyecto_mo}
                  onChange={(e) => handleInputChange('caja_proyecto_mo', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" fontWeight="bold" color="text.primary" gutterBottom>
                  ¿La obra fue terminada?
                </Typography>
                <FormControlLabel
                  control={
                    <FancyToggle
                      checked={projectData.es_terminada}
                      onChange={(e) => handleInputChange('es_terminada', e.target.checked)}
                      color="primary"
                    />
                  }
                  label={projectData.es_terminada ? 'Sí' : 'No'}
                  labelPlacement="end"
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" fontWeight="bold" color="text.primary" gutterBottom>
                  ¿Mostrar cajas en listas y dashboard?
                </Typography>
                <FormControlLabel
                  control={
                    <FancyToggle
                      checked={projectData.mostrar_cajas}
                      onChange={(e) => handleInputChange('mostrar_cajas', e.target.checked)}
                      color="primary"
                    />
                  }
                  label={projectData.mostrar_cajas ? 'Sí' : 'No'}
                  labelPlacement="end"
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="Método de Actualización"
                  value={projectData.metodo_de_actualizacion}
                  onChange={(e) => handleInputChange('metodo_de_actualizacion', e.target.value)}
                  fullWidth
                  sx={{ borderRadius: 4, mt: 2, mb: 2 }}
                >
                  <MenuItem value="CAC Mano de Obra">CAC Mano de Obra</MenuItem>
                  <MenuItem value="CAC General">CAC General</MenuItem>
                  <MenuItem value="CAC Materiales">CAC Materiales</MenuItem>
                  <MenuItem value="Sin actualización">Sin actualización</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="Fecha de Inicio de Obra"
                  value={projectData.fecha_de_inicio_obra}
                  onChange={(newValue) => handleInputChange('fecha_de_inicio_obra', newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth required />}
                />
              </Grid>
              {projectData.es_terminada && (
                <Grid item xs={12}>
                  <DatePicker
                    label="Fecha de Finalización de Obra"
                    value={projectData.fecha_de_finalizacion_obra}
                    onChange={(newValue) => handleInputChange('fecha_de_finalizacion_obra', newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
              )}

              {/* Sección para subir imagen de render */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" fontWeight="bold" color="text.primary" gutterBottom>
                  Imagen de Render del Proyecto
                </Typography>

                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={handleFileChange}
                />

                {!previewUrl ? (
                  <UploadBox onClick={handleUploadClick}>
                    <UploadIcon sx={{ fontSize: 40, color: '#78909c', mb: 1 }} />
                    <Typography variant="body1" color="textSecondary" align="center">
                      Haz clic para seleccionar una imagen del render
                    </Typography>
                    <Typography variant="caption" color="textSecondary" align="center" sx={{ mt: 1 }}>
                      Formatos: JPG, PNG, GIF (Máx: 5MB)
                    </Typography>
                  </UploadBox>
                ) : (
                  <PreviewImage>
                    <Box sx={{ position: 'relative' }}>
                      <img src={previewUrl} alt="Previsualización" />
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                        }}
                        onClick={() => {
                          setPreviewUrl('');
                          setUploadedFile(null);
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </PreviewImage>
                )}

                {uploadError && (
                  <Alert severity="error" sx={{ mt: 1 }}>
                    {uploadError}
                  </Alert>
                )}

                {uploadSuccess && (
                  <Alert severity="success" sx={{ mt: 1 }}>
                    Imagen subida correctamente
                  </Alert>
                )}
              </Grid>
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddProject}
              disabled={uploadLoading}
              sx={{
                borderRadius: 4,
                backgroundColor: '#4caf50',
                '&:hover': { backgroundColor: '#2e7d32' },
              }}
            >
              {uploadLoading ? (
                <>
                  <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                  Subiendo...
                </>
              ) : (
                'Guardar Proyecto'
              )}
            </Button>
          </motion.div>
        </DialogActions>
      </AddProjectDialog>

      <AddProjectDialog open={openEditProject} onClose={() => setOpenEditProject(false)}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="h5" fontWeight="bold" color="text.primary" textAlign="center">
            Editar Proyecto
          </Typography>
          <IconButton
            aria-label="close"
            onClick={() => setOpenEditProject(false)}
            sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Código de Proyecto"
                  value={projectData.codigo_de_proyecto}
                  onChange={(e) => handleInputChange('codigo_de_proyecto', e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Apellido del Cliente"
                  value={projectData.apellido_del_cliente}
                  onChange={(e) => handleInputChange('apellido_del_cliente', e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Email del Cliente"
                  value={projectData.email_del_cliente}
                  onChange={(e) => handleInputChange('email_del_cliente', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Ubicación del Proyecto"
                  value={projectData.ubicacion_del_proyecto}
                  onChange={(e) => handleInputChange('ubicacion_del_proyecto', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="UF"
                  value={projectData.uf}
                  onChange={(e) => handleInputChange('uf', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Metros Cubiertos"
                  type="number"
                  value={projectData.metros_cubiertos}
                  onChange={(e) => handleInputChange('metros_cubiertos', e.target.value)}
                  fullWidth
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Metros Semi Cubiertos"
                  type="number"
                  value={projectData.metros_semi_cubiertos}
                  onChange={(e) => handleInputChange('metros_semi_cubiertos', e.target.value)}
                  fullWidth
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Metros Solados"
                  type="number"
                  value={projectData.metros_solados}
                  onChange={(e) => handleInputChange('metros_solados', e.target.value)}
                  fullWidth
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Metros de Cómputo"
                  value={calculateMetrosDeComputo(projectData)}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Honorarios"
                  type="number"
                  value={projectData.monto_honorarios}
                  onChange={(e) => handleInputChange('monto_honorarios', e.target.value)}
                  fullWidth
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  label="Moneda Honorarios"
                  value={projectData.moneda_honorarios}
                  onChange={(e) => handleInputChange('moneda_honorarios', e.target.value)}
                  fullWidth
                  sx={{ borderRadius: 4 }}
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="ARS">ARS</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Mano de Obra Inicial"
                  type="number"
                  value={projectData.monto_inicial_mo}
                  onChange={(e) => handleInputChange('monto_inicial_mo', e.target.value)}
                  fullWidth
                  inputProps={{ min: 0 }}
                />
              </Grid>
              <Grid item xs={6}>
                <Select
                  label="Moneda Mano de Obra"
                  value={projectData.moneda_mo}
                  onChange={(e) => handleInputChange('moneda_mo', e.target.value)}
                  fullWidth
                  sx={{ borderRadius: 4 }}
                >
                  <MenuItem value="ARS">ARS</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Caja Proyecto"
                  value={projectData.caja_proyecto}
                  onChange={(e) => handleInputChange('caja_proyecto', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Caja Proyecto MO"
                  value={projectData.caja_proyecto_mo}
                  onChange={(e) => handleInputChange('caja_proyecto_mo', e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" fontWeight="bold" color="text.primary" gutterBottom>
                  ¿La obra fue terminada?
                </Typography>
                <FormControlLabel
                  control={
                    <FancyToggle
                      checked={projectData.es_terminada}
                      onChange={(e) => handleInputChange('es_terminada', e.target.checked)}
                      color="primary"
                    />
                  }
                  label={projectData.es_terminada ? 'Sí' : 'No'}
                  labelPlacement="end"
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1" fontWeight="bold" color="text.primary" gutterBottom>
                  ¿Mostrar cajas en listas y dashboard?
                </Typography>
                <FormControlLabel
                  control={
                    <FancyToggle
                      checked={projectData.mostrar_cajas}
                      onChange={(e) => handleInputChange('mostrar_cajas', e.target.checked)}
                      color="primary"
                    />
                  }
                  label={projectData.mostrar_cajas ? 'Sí' : 'No'}
                  labelPlacement="end"
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Select
                  label="Método de Actualización"
                  value={projectData.metodo_de_actualizacion}
                  onChange={(e) => handleInputChange('metodo_de_actualizacion', e.target.value)}
                  fullWidth
                  sx={{ borderRadius: 4, mt: 2, mb: 2 }}
                >
                  <MenuItem value="CAC Mano de Obra">CAC Mano de Obra</MenuItem>
                  <MenuItem value="CAC General">CAC General</MenuItem>
                  <MenuItem value="CAC Materiales">CAC Materiales</MenuItem>
                  <MenuItem value="Sin actualización">Sin actualización</MenuItem>
                </Select>
              </Grid>
              <Grid item xs={12}>
                <DatePicker
                  label="Fecha de Inicio de Obra"
                  value={projectData.fecha_de_inicio_obra}
                  onChange={(newValue) => handleInputChange('fecha_de_inicio_obra', newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth required />}
                />
              </Grid>
              {projectData.es_terminada && (
                <Grid item xs={12}>
                  <DatePicker
                    label="Fecha de Finalización de Obra"
                    value={projectData.fecha_de_finalizacion_obra}
                    onChange={(newValue) => handleInputChange('fecha_de_finalizacion_obra', newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
              )}

              {/* Sección para subir imagen de render */}
              <Grid item xs={12}>
                <Typography variant="subtitle1" fontWeight="bold" color="text.primary" gutterBottom>
                  Imagen de Render del Proyecto
                </Typography>

                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept="image/*"
                  onChange={handleFileChange}
                />

                {!previewUrl ? (
                  <UploadBox onClick={handleUploadClick}>
                    <UploadIcon sx={{ fontSize: 40, color: '#78909c', mb: 1 }} />
                    <Typography variant="body1" color="textSecondary" align="center">
                      Haz clic para seleccionar una imagen del render
                    </Typography>
                    <Typography variant="caption" color="textSecondary" align="center" sx={{ mt: 1 }}>
                      Formatos: JPG, PNG, GIF (Máx: 5MB)
                    </Typography>
                  </UploadBox>
                ) : (
                  <PreviewImage>
                    <Box sx={{ position: 'relative' }}>
                      <img src={previewUrl} alt="Previsualización" />
                      <IconButton
                        sx={{
                          position: 'absolute',
                          top: 8,
                          right: 8,
                          backgroundColor: 'rgba(255, 255, 255, 0.8)',
                          '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                        }}
                        onClick={() => {
                          // Si es una URL existente y no un archivo nuevo, no la borrar del estado
                          if (projectData.render_photo_path === previewUrl) {
                            setUploadedFile(null);
                          } else {
                            setPreviewUrl('');
                            setUploadedFile(null);
                            if (projectData.render_photo_path) {
                              setPreviewUrl(projectData.render_photo_path);
                            }
                          }
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </PreviewImage>
                )}

                {uploadError && (
                  <Alert severity="error" sx={{ mt: 1 }}>
                    {uploadError}
                  </Alert>
                )}

                {uploadSuccess && (
                  <Alert severity="success" sx={{ mt: 1 }}>
                    Imagen subida correctamente
                  </Alert>
                )}
              </Grid>
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleUpdateProject}
              disabled={uploadLoading}
              sx={{
                borderRadius: 4,
                backgroundColor: '#4caf50',
                '&:hover': { backgroundColor: '#2e7d32' },
              }}
            >
              {uploadLoading ? (
                <>
                  <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
                  Actualizando...
                </>
              ) : (
                'Guardar Cambios'
              )}
            </Button>
          </motion.div>
        </DialogActions>
      </AddProjectDialog>
    </Container>
  );
};

export default AdminProjects;