import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Paper,
  Card,
  CardContent,
  IconButton,
  Divider,
  Chip,
  Container,
  Dialog,
  DialogContent,
  Tooltip,
  InputAdornment,
  Tabs,
  Tab,
  Menu,
  ListItemText,
  Fade,
  AppBar,
  Toolbar,
  Snackbar,
  Alert
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import FolderIcon from '@mui/icons-material/Folder';
import DownloadIcon from '@mui/icons-material/Download';
import SortIcon from '@mui/icons-material/Sort';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import RefreshIcon from '@mui/icons-material/Refresh';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MoneyIcon from '@mui/icons-material/Money';
import PaymentsIcon from '@mui/icons-material/Payments';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import { formatImageUrl } from '../utils/imageUtils';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

// Componentes con estilos
const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 12,
  overflow: 'hidden',
  transition: 'transform 0.3s, box-shadow 0.3s',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  '&:hover': {
    transform: 'translateY(-8px)',
    boxShadow: '0 16px 30px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledCardMedia = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingTop: '75%', // Aspect ratio 4:3
  overflow: 'hidden',
  cursor: 'pointer',
  backgroundColor: '#f5f5f5',
}));

const CodeChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  bottom: 8,
  right: 8,
  backgroundColor: 'rgba(46, 125, 50, 0.9)',
  color: 'white',
  fontFamily: 'monospace',
  fontWeight: 'bold',
  fontSize: '0.75rem',
  boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
}));

const OriginChip = styled(Chip)(({ theme, origin }) => {
  const colors = {
    entrada: { bg: 'rgba(25, 118, 210, 0.7)', color: 'white' },
    cambio: { bg: 'rgba(46, 125, 50, 0.7)', color: 'white' },
    salida: { bg: 'rgba(211, 47, 47, 0.7)', color: 'white' },
    cobro: { bg: 'rgba(245, 124, 0, 0.7)', color: 'white' },
    default: { bg: 'rgba(97, 97, 97, 0.7)', color: 'white' }
  };

  const style = colors[origin] || colors.default;

  return {
    position: 'absolute',
    top: 8,
    left: 8,
    backgroundColor: style.bg,
    color: style.color,
    fontSize: '0.7rem',
    fontWeight: 'bold',
    boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
  };
});

const ImagePreviewDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  },
  '& .MuiPaper-root': {
    borderRadius: 12,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
}));

const TitleBar = styled(AppBar)(({ theme }) => ({
  position: 'relative',
  backgroundColor: 'white',
  color: theme.palette.text.primary,
  boxShadow: 'none',
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
}));

const DownloadButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: 10,             // Ahora arriba a la derecha en vez de abajo
  right: 10,
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  color: '#2e7d32',
  '&:hover': {
    backgroundColor: 'white',
    transform: 'scale(1.1)',
  },
  boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
  zIndex: 5,           // Asegura que está por encima de otros elementos
  padding: 8,          // Tamaño consistente
}));

// Define los tipos de origen de comprobantes
const COMPROBANTE_ORIGINS = {
  ENTRADA: 'entrada',
  CAMBIO: 'cambio',
  SALIDA: 'salida',
  COBRO: 'cobro'
};

// Mapeo de iconos para orígenes
const originIcons = {
  [COMPROBANTE_ORIGINS.ENTRADA]: <AttachMoneyIcon />,
  [COMPROBANTE_ORIGINS.CAMBIO]: <MoneyIcon />,
  [COMPROBANTE_ORIGINS.SALIDA]: <PaymentsIcon />,
  [COMPROBANTE_ORIGINS.COBRO]: <AccountBalanceIcon />,
};

const BuscadorComprobantes = () => {
  const [cajasOrigen, setCajasOrigen] = useState([]);
  const [conceptos, setConceptos] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [comprobantes, setComprobantes] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' o 'list'
  const [tabValue, setTabValue] = useState(0); // 0: Todos, 1: Recientes, 2: Por obra, 3: Por caja
  const [selectedFolder, setSelectedFolder] = useState(null);

  // Estados para menús y sort
  const [sortAnchorEl, setSortAnchorEl] = useState(null);
  const [sortBy, setSortBy] = useState('date_desc');

  const [filters, setFilters] = useState({
    codigo: '',
    proyecto: '',
    caja_origen: '',
    concepto: '',
    fecha_desde: null,
    fecha_hasta: null,
    origen: '', // Nuevo filtro para el origen
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Referencia para la animación del scroll
  const listRef = useRef(null);

  useEffect(() => {
    fetchCajasOrigen();
    fetchConceptos();
    fetchProyectos();
    // Ejecutar búsqueda inicial para mostrar los últimos comprobantes
    handleSearchComprobantes();
  }, []);

  // Efecto para cambiar búsqueda según tab seleccionado
  useEffect(() => {
    if (tabValue === 0) {
      // Todos los comprobantes
      handleSearchComprobantes();
    } else if (tabValue === 1) {
      // Recientes (últimos 30 días)
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      setFilters({
        ...filters,
        fecha_desde: thirtyDaysAgo,
        fecha_hasta: new Date(),
      });

      handleSearchComprobantes();
    }
  }, [tabValue]);

  const fetchCajasOrigen = async () => {
    const { data, error } = await supabase.from('cajas_origen').select('caja_origen');
    if (error) console.error('Error:', error);
    else setCajasOrigen(data.map((caja) => caja.caja_origen) || []);
  };

  const fetchConceptos = async () => {
    const { data, error } = await supabase.from('conceptos_de_entradas').select('concepto_de_entrada');
    if (error) console.error('Error:', error);
    else setConceptos(data.map((item) => item.concepto_de_entrada) || []);
  };

  const fetchProyectos = async () => {
    // Asume que tienes una tabla de proyectos o puedes obtenerlos desde algún lugar
    const { data, error } = await supabase.from('proyectos').select('codigo_de_proyecto');
    if (error) {
      console.error('Error fetching proyectos:', error);
      // Usar los valores disponibles en entradas si no hay tabla de proyectos
      const { data: entradaData } = await supabase
        .from('entradas')
        .select('codigo_de_proyecto_entrada')
        .not('codigo_de_proyecto_entrada', 'is', null);

      if (entradaData) {
        // Extraer valores únicos
        const uniqueProyectos = [...new Set(entradaData.map(item => item.codigo_de_proyecto_entrada))];
        setProyectos(uniqueProyectos.filter(p => p)); // Filtrar valores nulos o undefined
      }
    } else {
      setProyectos(data.map((item) => item.codigo_de_proyecto) || []);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSortClick = (event) => {
    setSortAnchorEl(event.currentTarget);
  };

  const handleSortClose = () => {
    setSortAnchorEl(null);
  };

  const handleSortSelect = (sortOption) => {
    setSortBy(sortOption);
    setSortAnchorEl(null);

    // Reordenar los resultados existentes
    let sortedComprobantes = [...comprobantes];

    switch (sortOption) {
      case 'date_desc':
        sortedComprobantes.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
        break;
      case 'date_asc':
        sortedComprobantes.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
        break;
      case 'amount_desc':
        sortedComprobantes.sort((a, b) => parseFloat(b.monto) - parseFloat(a.monto));
        break;
      case 'amount_asc':
        sortedComprobantes.sort((a, b) => parseFloat(a.monto) - parseFloat(b.monto));
        break;
      default:
        break;
    }

    setComprobantes(sortedComprobantes);
  };

  // Función para normalizar datos de diferentes orígenes
  const normalizeComprobanteData = (data, origen) => {
    let normalizedData = [];

    switch (origen) {
      case COMPROBANTE_ORIGINS.ENTRADA:
        normalizedData = data.map(item => ({
          id: item.id,
          origen: COMPROBANTE_ORIGINS.ENTRADA,
          codigo: item.codigo_de_comprobante_entrada,
          fecha: item.fecha_entrada,
          fecha_formateada: item.fecha_entrada ? format(new Date(item.fecha_entrada), 'dd/MM/yyyy') : '',
          monto: item.monto_entrada,
          moneda: item.moneda_entrada,
          monto_formateado: formatCurrency(item.monto_entrada, item.moneda_entrada),
          imagen: item.imagen_comprobante_entrada ? formatImageUrl(item.imagen_comprobante_entrada) : null,
          caja: item.caja_origen_entrada,
          concepto: item.concepto_entrada,
          proyecto: item.codigo_de_proyecto_entrada,
          ruta_archivo: item.ruta_archivo_comprobante,
          // Campos originales para mantener compatibilidad
          ...item
        }));
        break;

      case COMPROBANTE_ORIGINS.CAMBIO:
        normalizedData = data.map(item => ({
          id: item.id,
          origen: COMPROBANTE_ORIGINS.CAMBIO,
          codigo: item.codigo_de_comprobante_cambio,
          fecha: item.fecha_cambio,
          fecha_formateada: item.fecha_cambio ? format(new Date(item.fecha_cambio), 'dd/MM/yyyy') : '',
          monto: item.monto_inicial_cambio,
          moneda: item.moneda_inicial_cambio,
          monto_formateado: formatCurrency(item.monto_inicial_cambio, item.moneda_inicial_cambio),
          imagen: item.imagen_comprobante_cambio ? formatImageUrl(item.imagen_comprobante_cambio) : null,
          caja: item.caja_cambio,
          concepto: `Cambio ${item.moneda_inicial_cambio} → ${item.moneda_final_cambio}`,
          proyecto: null, // Los cambios de moneda pueden no tener proyecto
          ruta_archivo: item.ruta_archivo_comprobante_cambio,
          // Campos originales para mantener compatibilidad
          ...item
        }));
        break;

      case COMPROBANTE_ORIGINS.SALIDA:
        normalizedData = data.map(item => ({
          id: item.id,
          origen: COMPROBANTE_ORIGINS.SALIDA,
          codigo: item.codigo_de_comprobante_salida,
          fecha: item.fecha_salida,
          fecha_formateada: item.fecha_salida ? format(new Date(item.fecha_salida), 'dd/MM/yyyy') : '',
          monto: item.monto_salida,
          moneda: item.moneda_salida,
          monto_formateado: formatCurrency(item.monto_salida, item.moneda_salida),
          imagen: item.imagen_comprobante_salida ? formatImageUrl(item.imagen_comprobante_salida) : null,
          caja: item.caja_origen_salida,
          concepto: item.concepto_salidas,
          proyecto: item.codigo_de_proyecto_salida,
          ruta_archivo: item.ruta_archivo_comprobante_salida,
          // Campos originales para mantener compatibilidad
          ...item
        }));
        break;

      // Puedes añadir más casos según necesites
      case COMPROBANTE_ORIGINS.COBRO:
        // Implementar cuando esté disponible
        break;

      default:
        break;
    }

    return normalizedData;
  };

  const handleSearchComprobantes = async () => {
    setLoading(true);
    try {
      let allComprobantes = [];

      // Filtro de origen
      const origensABuscar = filters.origen
        ? [filters.origen]
        : [COMPROBANTE_ORIGINS.ENTRADA, COMPROBANTE_ORIGINS.CAMBIO, COMPROBANTE_ORIGINS.SALIDA];

      for (const origen of origensABuscar) {
        let data = [];

        if (origen === COMPROBANTE_ORIGINS.ENTRADA) {
          // Buscar comprobantes en entradas
          let query = supabase
            .from('entradas')
            .select('*')
            .eq('tiene_comprobante_entrada', true);

          // Aplicar filtros específicos para entradas
          if (filters.codigo) {
            query = query.ilike('codigo_de_comprobante_entrada', `%${filters.codigo}%`);
          }
          if (filters.proyecto) {
            query = query.eq('codigo_de_proyecto_entrada', filters.proyecto);
          }
          if (filters.caja_origen) {
            query = query.eq('caja_origen_entrada', filters.caja_origen);
          }
          if (filters.concepto) {
            query = query.eq('concepto_entrada', filters.concepto);
          }
          if (filters.fecha_desde) {
            const fechaDesdeFmt = format(new Date(filters.fecha_desde), 'yyyy-MM-dd');
            query = query.gte('fecha_entrada', fechaDesdeFmt);
          }
          if (filters.fecha_hasta) {
            const fechaHasta = new Date(filters.fecha_hasta);
            fechaHasta.setDate(fechaHasta.getDate() + 1);
            const fechaHastaFmt = format(fechaHasta, 'yyyy-MM-dd');
            query = query.lt('fecha_entrada', fechaHastaFmt);
          }

          // Ordenar según la selección actual
          switch (sortBy) {
            case 'date_desc':
              query = query.order('fecha_entrada', { ascending: false });
              break;
            case 'date_asc':
              query = query.order('fecha_entrada', { ascending: true });
              break;
            case 'amount_desc':
              query = query.order('monto_entrada', { ascending: false });
              break;
            case 'amount_asc':
              query = query.order('monto_entrada', { ascending: true });
              break;
            default:
              query = query.order('fecha_entrada', { ascending: false });
          }

          const { data: entradasData, error } = await query.limit(100);
          if (error) {
            console.error('Error fetching comprobantes from entradas:', error.message);
          } else {
            data = entradasData;
          }

          // Normalizar y agregar a la lista total
          const normalizedData = normalizeComprobanteData(data, COMPROBANTE_ORIGINS.ENTRADA);
          allComprobantes = [...allComprobantes, ...normalizedData];
        }

        if (origen === COMPROBANTE_ORIGINS.CAMBIO) {
          // Buscar comprobantes en cambios de moneda
          let query = supabase
            .from('cambios_de_moneda')
            .select('*')
            .eq('tiene_comprobante_cambio', true);

          // Aplicar filtros específicos para cambios
          if (filters.codigo) {
            query = query.ilike('codigo_de_comprobante_cambio', `%${filters.codigo}%`);
          }
          if (filters.caja_origen) {
            query = query.eq('caja_cambio', filters.caja_origen);
          }
          // No filtramos por concepto en cambios ya que no tiene ese campo
          if (filters.fecha_desde) {
            const fechaDesdeFmt = format(new Date(filters.fecha_desde), 'yyyy-MM-dd');
            query = query.gte('fecha_cambio', fechaDesdeFmt);
          }
          if (filters.fecha_hasta) {
            const fechaHasta = new Date(filters.fecha_hasta);
            fechaHasta.setDate(fechaHasta.getDate() + 1);
            const fechaHastaFmt = format(fechaHasta, 'yyyy-MM-dd');
            query = query.lt('fecha_cambio', fechaHastaFmt);
          }

          // Ordenar según la selección actual
          switch (sortBy) {
            case 'date_desc':
              query = query.order('fecha_cambio', { ascending: false });
              break;
            case 'date_asc':
              query = query.order('fecha_cambio', { ascending: true });
              break;
            case 'amount_desc':
              query = query.order('monto_inicial_cambio', { ascending: false });
              break;
            case 'amount_asc':
              query = query.order('monto_inicial_cambio', { ascending: true });
              break;
            default:
              query = query.order('fecha_cambio', { ascending: false });
          }

          const { data: cambiosData, error } = await query.limit(100);
          if (error) {
            console.error('Error fetching comprobantes from cambios:', error.message);
          } else {
            data = cambiosData;
          }

          // Normalizar y agregar a la lista total
          const normalizedData = normalizeComprobanteData(data, COMPROBANTE_ORIGINS.CAMBIO);
          allComprobantes = [...allComprobantes, ...normalizedData];
        }

        if (origen === COMPROBANTE_ORIGINS.SALIDA) {
          // Buscar comprobantes en salidas
          let query = supabase
            .from('salidas')
            .select('*')
            .eq('tiene_comprobante_salida', true);

          // Aplicar filtros específicos para salidas
          if (filters.codigo) {
            query = query.ilike('codigo_de_comprobante_salida', `%${filters.codigo}%`);
          }
          if (filters.proyecto) {
            query = query.eq('codigo_de_proyecto_salida', filters.proyecto);
          }
          if (filters.caja_origen) {
            query = query.eq('caja_origen_salida', filters.caja_origen);
          }
          if (filters.concepto) {
            query = query.eq('concepto_salidas', filters.concepto);
          }
          if (filters.fecha_desde) {
            const fechaDesdeFmt = format(new Date(filters.fecha_desde), 'yyyy-MM-dd');
            query = query.gte('fecha_salida', fechaDesdeFmt);
          }
          if (filters.fecha_hasta) {
            const fechaHasta = new Date(filters.fecha_hasta);
            fechaHasta.setDate(fechaHasta.getDate() + 1);
            const fechaHastaFmt = format(fechaHasta, 'yyyy-MM-dd');
            query = query.lt('fecha_salida', fechaHastaFmt);
          }

          // Ordenar según la selección actual
          switch (sortBy) {
            case 'date_desc':
              query = query.order('fecha_salida', { ascending: false });
              break;
            case 'date_asc':
              query = query.order('fecha_salida', { ascending: true });
              break;
            case 'amount_desc':
              query = query.order('monto_salida', { ascending: false });
              break;
            case 'amount_asc':
              query = query.order('monto_salida', { ascending: true });
              break;
            default:
              query = query.order('fecha_salida', { ascending: false });
          }

          const { data: salidasData, error } = await query.limit(100);
          if (error) {
            console.error('Error fetching comprobantes from salidas:', error.message);
          } else {
            data = salidasData;
          }

          // Normalizar y agregar a la lista total
          const normalizedData = normalizeComprobanteData(data, COMPROBANTE_ORIGINS.SALIDA);
          allComprobantes = [...allComprobantes, ...normalizedData];
        }
      }

      // Ordenar todos los comprobantes juntos según el criterio seleccionado
      switch (sortBy) {
        case 'date_desc':
          allComprobantes.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
          break;
        case 'date_asc':
          allComprobantes.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
          break;
        case 'amount_desc':
          allComprobantes.sort((a, b) => parseFloat(b.monto) - parseFloat(a.monto));
          break;
        case 'amount_asc':
          allComprobantes.sort((a, b) => parseFloat(a.monto) - parseFloat(b.monto));
          break;
        default:
          allComprobantes.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
      }

      setComprobantes(allComprobantes);

    } catch (error) {
      console.error('Error searching comprobantes:', error);
      setSnackbar({
        open: true,
        message: 'Error al procesar la búsqueda',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const formatCurrency = (amount, currency) => {
    if (!amount) return '';
    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currency === 'USD' ? 'USD' : 'ARS',
      minimumFractionDigits: 2
    });
    return formatter.format(amount);
  };

  const handleDownload = async (e, comprobante) => {
    // Detener evento completamente
    e.preventDefault();
    e.stopPropagation();

    try {
      // URL de la imagen
      const imageUrl = comprobante.imagen.split('?')[0];

      // Nombre del archivo - evitamos la duplicación de prefijos
      let fileName;

      // Si el código ya contiene el prefijo del proyecto, lo usamos directamente
      if (comprobante.codigo &&
        comprobante.proyecto &&
        comprobante.codigo.startsWith(comprobante.proyecto)) {
        fileName = `${comprobante.codigo}.jpg`;
      } else {
        // Si no, lo añadimos
        const projectCode = comprobante.proyecto || "GEN";
        fileName = `${projectCode}_${comprobante.codigo || format(new Date(), 'yyyyMMdd')}.jpg`;
      }

      // Usar fetch para obtener los datos como blob
      const response = await fetch(imageUrl);
      const blob = await response.blob();

      // Usar saveAs de file-saver
      saveAs(blob, fileName);

      // Notificar éxito
      setSnackbar({
        open: true,
        message: 'Imagen descargada exitosamente',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error descargando imagen:', error);
      setSnackbar({
        open: true,
        message: 'Error al descargar la imagen',
        severity: 'error'
      });
    }

    return false;
  };

  const handleShowImage = (comprobante) => {
    // Asegurarnos de que el código tenga el prefijo del proyecto correcto
    const projectCode = comprobante.proyecto || "GEN";
    let codigoMostrar = comprobante.codigo;

    if (!codigoMostrar) {
      codigoMostrar = `${projectCode}-${format(new Date(), 'yyyyMMdd')}-${Math.floor(Math.random() * 100000)}`;
    }

    const origenLabels = {
      [COMPROBANTE_ORIGINS.ENTRADA]: 'Entrada',
      [COMPROBANTE_ORIGINS.CAMBIO]: 'Cambio de Moneda',
      [COMPROBANTE_ORIGINS.SALIDA]: 'Salida',
      [COMPROBANTE_ORIGINS.COBRO]: 'Cobro Socios',
    };

    setCurrentImage({
      url: comprobante.imagen,
      codigo: codigoMostrar,
      fecha: comprobante.fecha_formateada,
      concepto: comprobante.concepto,
      monto: comprobante.monto_formateado,
      caja: comprobante.caja,
      proyecto: comprobante.proyecto,
      origen: origenLabels[comprobante.origen] || 'Desconocido'
    });
    setOpenPreview(true);
  };

  const resetFilters = () => {
    setFilters({
      codigo: '',
      proyecto: '',
      caja_origen: '',
      concepto: '',
      fecha_desde: null,
      fecha_hasta: null,
      origen: '',
    });
  };

  const getFoldersByType = (type) => {
    // Obtiene carpetas únicas basadas en el tipo (proyecto o caja)
    const uniqueFolders = [];

    if (type === 'proyecto') {
      comprobantes.forEach(comp => {
        if (comp.proyecto && !uniqueFolders.includes(comp.proyecto)) {
          uniqueFolders.push(comp.proyecto);
        }
      });
    } else if (type === 'caja') {
      comprobantes.forEach(comp => {
        if (comp.caja && !uniqueFolders.includes(comp.caja)) {
          uniqueFolders.push(comp.caja);
        }
      });
    }

    return uniqueFolders.sort();
  };

  const handleFolderSelect = (folder, type) => {
    setSelectedFolder(folder);

    if (type === 'proyecto') {
      setFilters({
        ...filters,
        proyecto: folder,
        caja_origen: ''
      });
    } else if (type === 'caja') {
      setFilters({
        ...filters,
        caja_origen: folder,
        proyecto: ''
      });
    }

    handleSearchComprobantes();
  };
  // Función para descargar todas las imágenes filtradas
  const downloadAllImages = async () => {
    const filteredComprobantes = [...comprobantes];

    // Aplicar filtros si hay una carpeta seleccionada
    let comprobantesToDownload = filteredComprobantes;
    if (selectedFolder && tabValue === 2) {
      comprobantesToDownload = filteredComprobantes.filter(
        comp => comp.proyecto === selectedFolder
      );
    } else if (selectedFolder && tabValue === 3) {
      comprobantesToDownload = filteredComprobantes.filter(
        comp => comp.caja === selectedFolder
      );
    }

    if (comprobantesToDownload.length === 0) {
      setSnackbar({
        open: true,
        message: 'No hay comprobantes para descargar',
        severity: 'info'
      });
      return;
    }

    try {
      setLoading(true);

      // Crear un nuevo ZIP
      const zip = new JSZip();
      const imagesFolder = zip.folder("comprobantes");

      // Contador para tracking de progreso
      let completedCount = 0;

      // Función para actualizar la UI cuando se completa todo
      const checkCompletion = () => {
        completedCount++;
        if (completedCount === comprobantesToDownload.length) {
          zip.generateAsync({ type: "blob" })
            .then(content => {
              saveAs(content, `comprobantes_${format(new Date(), 'yyyyMMdd')}.zip`);
              setLoading(false);
              setSnackbar({
                open: true,
                message: `${completedCount} comprobantes descargados exitosamente`,
                severity: 'success'
              });
            });
        }
      };

      // Procesar cada comprobante
      for (const comprobante of comprobantesToDownload) {
        if (!comprobante.imagen) {
          completedCount++;
          continue;
        }

        try {
          // Limpiar la URL (quitar parámetros de cache si existen)
          const cleanUrl = comprobante.imagen.split('?')[0];

          // Obtener la imagen
          const response = await fetch(cleanUrl);
          if (!response.ok) {
            console.error(`Error al descargar imagen: ${cleanUrl}`);
            checkCompletion();
            continue;
          }

          const blob = await response.blob();

          // Generar un nombre de archivo descriptivo
          let fileName = `${comprobante.codigo || 'comprobante'}.jpg`;

          // Si tiene proyecto, añadirlo al nombre
          if (comprobante.proyecto) {
            fileName = `${comprobante.proyecto}_${fileName}`;
          }

          // Añadir origen al nombre para claridad
          fileName = `${comprobante.origen}_${fileName}`;

          // Añadir al ZIP
          imagesFolder.file(fileName, blob);
          checkCompletion();
        } catch (error) {
          console.error(`Error procesando comprobante ${comprobante.id}:`, error);
          checkCompletion();
        }
      }
    } catch (error) {
      console.error('Error al crear el archivo ZIP:', error);
      setSnackbar({
        open: true,
        message: 'Error al descargar los comprobantes',
        severity: 'error'
      });
      setLoading(false);
    }
  };

  // Renderiza la vista de carpetas
  const renderFolders = () => {
    let folders = [];
    let folderType = '';

    if (tabValue === 2) { // Por obra
      folders = getFoldersByType('proyecto');
      folderType = 'proyecto';
    } else if (tabValue === 3) { // Por caja
      folders = getFoldersByType('caja');
      folderType = 'caja';
    } else {
      return null;
    }

    return (
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {folders.map((folder) => (
          <Grid item xs={6} sm={4} md={3} lg={2} key={folder}>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Card
                sx={{
                  cursor: 'pointer',
                  backgroundColor: folder === selectedFolder ? '#e8f5e9' : 'white',
                  transition: 'all 0.3s ease',
                  boxShadow: folder === selectedFolder ? '0 4px 20px rgba(46, 125, 50, 0.2)' : '0 2px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
                  }
                }}
                onClick={() => handleFolderSelect(folder, folderType)}
              >
                <CardContent sx={{ textAlign: 'center', p: 2 }}>
                  <FolderIcon sx={{ fontSize: 60, color: '#2e7d32', mb: 1 }} />
                  <Typography variant="subtitle1" noWrap fontWeight={folder === selectedFolder ? 'bold' : 'regular'}>
                    {folder}
                  </Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    );
  };

  // Renderiza la vista de grid o lista según la selección
  const renderComprobantes = () => {
    // Si está en vista de carpetas y hay una carpeta seleccionada, mostrar solo comprobantes de esa carpeta
    let filteredComprobantes = [...comprobantes];

    if (selectedFolder && tabValue === 2) {
      filteredComprobantes = filteredComprobantes.filter(
        comp => comp.proyecto === selectedFolder
      );
    } else if (selectedFolder && tabValue === 3) {
      filteredComprobantes = filteredComprobantes.filter(
        comp => comp.caja === selectedFolder
      );
    }

    if (viewMode === 'grid') {
      return (
        <Grid container spacing={3} ref={listRef}>
          {filteredComprobantes.map((comprobante) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={`${comprobante.origen}-${comprobante.id}`}>
              <Fade in={true} timeout={500}>
                <StyledCard>
                  <StyledCardMedia>
                    {comprobante.imagen ? (
                      <>
                        <Box
                          component="img"
                          src={comprobante.imagen}
                          alt={`Comprobante ${comprobante.codigo || ""}`}
                          sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                          onClick={() => handleShowImage(comprobante)}
                        />
                        {/* Etiqueta de origen */}
                        <OriginChip
                          label={comprobante.origen}
                          size="small"
                          icon={originIcons[comprobante.origen]}
                          origin={comprobante.origen}
                        />
                        {/* Botón de descarga */}
                        <DownloadButton
                          onClick={(e) => {
                            handleDownload(e, comprobante);
                            // Estas líneas adicionales garantizan que no haya propagación
                            if (e && e.stopPropagation) e.stopPropagation();
                            if (e && e.preventDefault) e.preventDefault();
                            return false;
                          }}
                          size="medium"
                        >
                          <DownloadIcon fontSize="medium" />
                        </DownloadButton>
                      </>
                    ) : (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#f5f5f5'
                        }}
                        onClick={() => handleShowImage(comprobante)}
                      >
                        <ReceiptIcon sx={{ fontSize: 60, color: '#9e9e9e' }} />
                        {/* Etiqueta de origen incluso sin imagen */}
                        <OriginChip
                          label={comprobante.origen}
                          size="small"
                          icon={originIcons[comprobante.origen]}
                          origin={comprobante.origen}
                        />
                      </Box>
                    )}
                    {comprobante.codigo && (
                      <CodeChip
                        label={comprobante.codigo}
                        size="small"
                      />
                    )}
                  </StyledCardMedia>
                  <CardContent>
                    <Typography variant="subtitle1" fontWeight="bold" gutterBottom noWrap>
                      {comprobante.concepto || 'Sin concepto'}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                      <Typography variant="body2" color="text.secondary">
                        {comprobante.fecha_formateada}
                      </Typography>
                      <Typography variant="body2" fontWeight="bold" color="primary">
                        {comprobante.monto_formateado}
                      </Typography>
                    </Box>
                    <Divider sx={{ my: 1 }} />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Tooltip title={`Caja: ${comprobante.caja || "No especificada"}`}>
                        <Typography variant="caption" color="text.secondary" noWrap sx={{ maxWidth: '45%' }}>
                          {comprobante.caja || "No especificada"}
                        </Typography>
                      </Tooltip>

                      <Tooltip title={`Proyecto: ${comprobante.proyecto || "No especificado"}`}>
                        <Chip
                          label={comprobante.proyecto || "Sin proyecto"}
                          size="small"
                          sx={{ fontSize: '0.6rem', height: 20 }}
                        />
                      </Tooltip>
                    </Box>
                  </CardContent>
                </StyledCard>
              </Fade>
            </Grid>
          ))}
        </Grid>
      );
    } else {
      // Vista de lista
      return (
        <Box ref={listRef}>
          {filteredComprobantes.map((comprobante) => (
            <Fade in={true} key={`${comprobante.origen}-${comprobante.id}`} timeout={500}>
              <Paper
                elevation={0}
                sx={{
                  mb: 2,
                  border: '1px solid rgba(0,0,0,0.08)',
                  borderRadius: 2,
                  overflow: 'hidden',
                  transition: 'all 0.2s ease',
                  '&:hover': {
                    boxShadow: '0 4px 12px rgba(0,0,0,0.08)',
                    borderColor: 'rgba(0,0,0,0.12)',
                  }
                }}
              >
                <Grid container>
                  <Grid item xs={12} sm={3} md={2} sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        height: '100%',
                        position: 'relative',
                        cursor: 'pointer',
                        minHeight: { xs: 140, sm: 'auto' }
                      }}
                    >
                      {comprobante.imagen ? (
                        <Box sx={{ position: 'relative', width: '100%', height: '100%' }}>
                          <Box
                            component="img"
                            src={comprobante.imagen}
                            alt={`Comprobante ${comprobante.codigo || ""}`}
                            sx={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                              minHeight: { xs: 140, sm: '100%' },
                            }}
                            onClick={() => handleShowImage(comprobante)}
                          />
                          {/* Etiqueta de origen */}
                          <OriginChip
                            label={comprobante.origen}
                            size="small"
                            icon={originIcons[comprobante.origen]}
                            origin={comprobante.origen}
                          />
                          {/* Botón de descarga para lista */}
                          <DownloadButton
                            onClick={(e) => {
                              handleDownload(e, comprobante);
                              // Estas líneas adicionales garantizan que no haya propagación
                              if (e && e.stopPropagation) e.stopPropagation();
                              if (e && e.preventDefault) e.preventDefault();
                              return false;
                            }} size="medium"
                          >
                            <DownloadIcon fontSize="medium" />
                          </DownloadButton>
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            width: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            minHeight: { xs: 140, sm: '100%' },
                            backgroundColor: '#f5f5f5',
                          }}
                          onClick={() => handleShowImage(comprobante)}
                        >
                          <ReceiptIcon sx={{ fontSize: 60, color: '#9e9e9e' }} />
                          {/* Etiqueta de origen incluso sin imagen */}
                          <OriginChip
                            label={comprobante.origen}
                            size="small"
                            icon={originIcons[comprobante.origen]}
                            origin={comprobante.origen}
                          />
                        </Box>
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={9} md={10}>
                    <Box sx={{ p: 2 }}>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                        <Typography variant="h6" fontWeight="medium">
                          {comprobante.concepto || 'Sin concepto'}
                        </Typography>
                        <Typography variant="h6" fontWeight="bold" color="primary">
                          {comprobante.monto_formateado}
                        </Typography>
                      </Box>

                      <Box sx={{ mt: 1, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                        <Chip
                          icon={<FolderIcon />}
                          label={comprobante.proyecto || "Sin proyecto"}
                          size="small"
                          color={comprobante.proyecto ? "primary" : "default"}
                          variant={comprobante.proyecto ? "filled" : "outlined"}
                        />

                        <Chip
                          label={comprobante.caja}
                          size="small"
                          variant="outlined"
                        />

                        <Chip
                          label={comprobante.fecha_formateada}
                          size="small"
                          variant="outlined"
                        />

                        <Chip
                          label={comprobante.codigo}
                          size="small"
                          color="success"
                          onClick={(e) => {
                            e.stopPropagation();
                            navigator.clipboard.writeText(comprobante.codigo);
                            setSnackbar({
                              open: true,
                              message: 'Código copiado al portapapeles',
                              severity: 'success'
                            });
                          }}
                          sx={{
                            fontFamily: 'monospace',
                            cursor: 'pointer',
                          }}
                        />
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Fade>
          ))}
        </Box>
      );
    }
  };

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <TitleBar position="static">
          <Toolbar>
            <Typography variant="h4" fontWeight="bold" color="#2e7d32" sx={{ flexGrow: 1 }}>
              Buscador de Comprobantes
            </Typography>

            <Box sx={{ display: 'flex', gap: 1 }}>
              <Tooltip title="Cambiar vista">
                <IconButton
                  color="primary"
                  onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}
                >
                  {viewMode === 'grid' ?
                    <ViewListIcon /> :
                    <ViewModuleIcon />
                  }
                </IconButton>
              </Tooltip>

              <Tooltip title="Ordenar">
                <IconButton
                  color="primary"
                  onClick={handleSortClick}
                >
                  <SortIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title="Descargar todos los comprobantes">
                <IconButton
                  color="primary"
                  onClick={downloadAllImages}
                  disabled={loading || comprobantes.length === 0}
                >
                  <FileDownloadIcon />
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={sortAnchorEl}
                open={Boolean(sortAnchorEl)}
                onClose={handleSortClose}
                TransitionComponent={Fade}
              >
                <MenuItem
                  onClick={() => handleSortSelect('date_desc')}
                  selected={sortBy === 'date_desc'}
                >
                  <ListItemText>Fecha (más reciente)</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => handleSortSelect('date_asc')}
                  selected={sortBy === 'date_asc'}
                >
                  <ListItemText>Fecha (más antigua)</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => handleSortSelect('amount_desc')}
                  selected={sortBy === 'amount_desc'}
                >
                  <ListItemText>Monto (mayor)</ListItemText>
                </MenuItem>
                <MenuItem
                  onClick={() => handleSortSelect('amount_asc')}
                  selected={sortBy === 'amount_asc'}
                >
                  <ListItemText>Monto (menor)</ListItemText>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            variant="scrollable"
            scrollButtons="auto"
            sx={{
              mb: 2,
              px: 2,
              '& .MuiTab-root': {
                minWidth: 'auto',
                fontWeight: 'medium',
                px: 3,
              },
              '& .Mui-selected': {
                color: '#2e7d32',
                fontWeight: 'bold',
              },
              '& .MuiTabs-indicator': {
                backgroundColor: '#2e7d32',
                height: 3,
                borderRadius: '3px 3px 0 0',
              },
            }}
          >
            <Tab label="Todos" />
            <Tab label="Recientes" />
            <Tab label="Por Obra" />
            <Tab label="Por Caja" />
          </Tabs>
        </TitleBar>

        <Paper elevation={0} sx={{ p: 3, borderRadius: 2, mb: 3, mt: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
            <TextField
              placeholder="Buscar por código..."
              value={filters.codigo}
              onChange={(e) => setFilters({ ...filters, codigo: e.target.value })}
              sx={{
                flex: 1,
                minWidth: 250,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8,
                  '&:hover fieldset': {
                    borderColor: '#2e7d32',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#2e7d32' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<FilterListIcon />}
              onClick={() => setShowFilters(!showFilters)}
              sx={{
                borderRadius: 8,
                borderColor: '#2e7d32',
                color: '#2e7d32',
                '&:hover': {
                  borderColor: '#1b5e20',
                  backgroundColor: 'rgba(46, 125, 50, 0.04)',
                },
              }}
            >
              Filtros
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSearchComprobantes}
              disabled={loading}
              sx={{
                borderRadius: 8,
                backgroundColor: '#2e7d32',
                '&:hover': { backgroundColor: '#1b5e20' },
                px: 3,
              }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Buscar'}
            </Button>
          </Box>

          {showFilters && (
            <Box sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Origen</InputLabel>
                    <Select
                      value={filters.origen}
                      onChange={(e) => setFilters({ ...filters, origen: e.target.value })}
                      label="Origen"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      <MenuItem value={COMPROBANTE_ORIGINS.ENTRADA}>Entradas</MenuItem>
                      <MenuItem value={COMPROBANTE_ORIGINS.CAMBIO}>Cambios de Moneda</MenuItem>
                      <MenuItem value={COMPROBANTE_ORIGINS.SALIDA}>Salidas</MenuItem>
                      <MenuItem value={COMPROBANTE_ORIGINS.COBRO}>Cobros Socios</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Proyecto</InputLabel>
                    <Select
                      value={filters.proyecto}
                      onChange={(e) => setFilters({ ...filters, proyecto: e.target.value })}
                      label="Proyecto"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {proyectos.map((proyecto) => (
                        <MenuItem key={proyecto} value={proyecto}>{proyecto}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Caja Origen</InputLabel>
                    <Select
                      value={filters.caja_origen}
                      onChange={(e) => setFilters({ ...filters, caja_origen: e.target.value })}
                      label="Caja Origen"
                    >
                      <MenuItem value="">Todas</MenuItem>
                      {cajasOrigen.map((caja) => (
                        <MenuItem key={caja} value={caja}>{caja}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Concepto</InputLabel>
                    <Select
                      value={filters.concepto}
                      onChange={(e) => setFilters({ ...filters, concepto: e.target.value })}
                      label="Concepto"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {conceptos.map((conc) => (
                        <MenuItem key={conc} value={conc}>{conc}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Desde"
                      value={filters.fecha_desde}
                      onChange={(newValue) => setFilters({ ...filters, fecha_desde: newValue })}
                      renderInput={(params) => <TextField size="small" {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="Hasta"
                      value={filters.fecha_hasta}
                      onChange={(newValue) => setFilters({ ...filters, fecha_hasta: newValue })}
                      renderInput={(params) => <TextField size="small" {...params} fullWidth />}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>

              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
                <Button
                  onClick={resetFilters}
                  sx={{ mr: 2, color: '#757575' }}
                >
                  Limpiar filtros
                </Button>
              </Box>
            </Box>
          )}
        </Paper>

        {tabValue === 2 || tabValue === 3 ? renderFolders() : null}

        {comprobantes.length > 0 ? (
          <Box sx={{ mt: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'medium', color: '#666' }}>
              {
                selectedFolder
                  ? `Comprobantes de ${tabValue === 2 ? 'proyecto' : 'caja'}: ${selectedFolder}`
                  : `${comprobantes.length} comprobante${comprobantes.length !== 1 ? 's' : ''} encontrado${comprobantes.length !== 1 ? 's' : ''}`
              }
            </Typography>

            {renderComprobantes()}
          </Box>
        ) : loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 200 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Box sx={{ textAlign: 'center', py: 6 }}>
            <ReceiptIcon sx={{ fontSize: 80, color: '#e0e0e0', mb: 2 }} />
            <Typography variant="h6" color="text.secondary">
              No se encontraron comprobantes
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Intenta con otros filtros o realiza una nueva búsqueda
            </Typography>
          </Box>
        )}
      </motion.div>

      {/* Diálogo para vista previa de imagen */}
      <ImagePreviewDialog
        open={openPreview}
        onClose={() => setOpenPreview(false)}
        maxWidth="lg"
        onClick={() => setOpenPreview(false)}
      >
        <DialogContent sx={{ p: 0, overflow: 'hidden', position: 'relative' }}>
          {currentImage && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0,0,0,0.02)',
                pb: 2,
              }}
            >
              <Box
                component="img"
                src={currentImage.url}
                alt="Comprobante"
                sx={{
                  maxWidth: '100%',
                  maxHeight: '70vh',
                  objectFit: 'contain',
                  borderRadius: 1,
                  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
                  mb: 2
                }}
                onError={(e) => {
                  console.error('Error cargando imagen en diálogo:', e);
                  e.target.src = 'https://via.placeholder.com/400x300?text=Imagen+no+disponible';
                }}
              />

              <Box sx={{
                p: 3,
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                borderRadius: 2,
                width: '100%',
                maxWidth: 500,
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.08)',
              }}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary" fontWeight="bold">
                      Código
                    </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography variant="body2" sx={{ fontFamily: 'monospace', mr: 1 }}>
                        {currentImage.codigo}
                      </Typography>
                      <IconButton
                        size="small"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigator.clipboard.writeText(currentImage.codigo);
                          setSnackbar({
                            open: true,
                            message: 'Código copiado al portapapeles',
                            severity: 'success'
                          });
                        }}
                      >
                        <ContentCopyIcon fontSize="small" />
                      </IconButton>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary" fontWeight="bold">
                      Fecha
                    </Typography>
                    <Typography variant="body2">
                      {currentImage.fecha}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary" fontWeight="bold">
                      Proyecto
                    </Typography>
                    <Typography variant="body2">
                      {currentImage.proyecto || "Sin proyecto"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary" fontWeight="bold">
                      Caja
                    </Typography>
                    <Typography variant="body2">
                      {currentImage.caja}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary" fontWeight="bold">
                      Origen
                    </Typography>
                    <Typography variant="body2">
                      {currentImage.origen}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="caption" color="text.secondary" fontWeight="bold">
                      Monto
                    </Typography>
                    <Typography variant="body1" fontWeight="bold" color="primary">
                      {currentImage.monto}
                    </Typography>
                  </Grid>
                </Grid>

                <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    startIcon={<DownloadIcon />}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      const link = document.createElement('a');
                      link.href = currentImage.url.split('?')[0]; // Quitar parámetros de caché
                      link.download = `${currentImage.proyecto || 'comprobante'}_${currentImage.codigo}.jpg`;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);

                      setSnackbar({
                        open: true,
                        message: 'Imagen descargada exitosamente',
                        severity: 'success'
                      });
                      return false;
                    }}
                    sx={{
                      backgroundColor: '#2e7d32',
                      '&:hover': { backgroundColor: '#1b5e20' }
                    }}
                  >
                    Descargar imagen
                  </Button>

                  <Button
                    variant="outlined"
                    onClick={() => setOpenPreview(false)}
                    sx={{ borderColor: '#2e7d32', color: '#2e7d32' }}
                  >
                    Cerrar
                  </Button>
                </Box>
              </Box>

              <IconButton
                size="small"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                }}
                onClick={() => setOpenPreview(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </DialogContent>
      </ImagePreviewDialog>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default BuscadorComprobantes;