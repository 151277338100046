import { useEffect } from 'react';
import { supabase } from './supabaseClient';

const TestAuth = () => {
  useEffect(() => {
    const checkAuth = async () => {
      try {
        const { data: { user } } = await supabase.auth.getUser();
        if (user) {
          console.log('Usuario autenticado:', user.email);
          console.log('user.user_metadata:', user.user_metadata);

          // Llamar a la función get_user_role
          const { data, error } = await supabase.rpc('get_user_role');
          if (error) {
            console.error('Error al llamar a get_user_role:', error);
          } else {
            console.log('Rol desde get_user_role:', data);
          }
        } else {
          console.log('No hay usuario autenticado');
        }
      } catch (error) {
        console.error('Error al verificar autenticación:', error);
      }
    };
    checkAuth();
  }, []);

  return null;
};

export default TestAuth;