import React, { useEffect, useState, useRef } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Typography,
  IconButton,
  Container,
  Paper,
  Tooltip,
  Chip,
  InputBase,
  InputAdornment,
  Alert,
  Snackbar,
  Divider,
  CircularProgress,
  FormControlLabel,
  Switch,
  Autocomplete,
  Card,
  CardContent,
  Fab,
  Badge,
  Drawer,
  SwipeableDrawer,
  AppBar,
  Toolbar,
  Slide,
  LinearProgress,
  Pagination,
  useTheme,
  Avatar,
  Stack,
  Stepper,
  Step,
  StepLabel,
  MobileStepper,
  useScrollTrigger,
  Zoom
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CalculateIcon from '@mui/icons-material/Calculate';
import SummarizeIcon from '@mui/icons-material/Summarize';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import { uploadImage, formatImageUrl } from '../utils/imageUtils';
import { useAuth } from '../contexts/AuthContext'; // Importar useAuth

// Estilos personalizados con enfoque en mobile
const MobileContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1, 1.5, 10, 1.5), // Padding extra abajo para el botón flotante
  maxWidth: '100%',
  backgroundColor: '#f8f9fa'
}));

const EntradaCard = styled(Card)(({ theme }) => ({
  borderRadius: 20,
  marginBottom: theme.spacing(2),
  overflow: 'hidden',
  boxShadow: '0 3px 10px rgba(0, 0, 0, 0.08)',
  transition: 'all 0.3s ease',
  background: 'linear-gradient(to bottom, #ffffff, #fafafa)',
  '&:hover': {
    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
    transform: 'translateY(-2px)'
  },
  '&:active': {
    transform: 'scale(0.98)',
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)',
  },
}));

const CardRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0.8, 0),
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const SearchBarContainer = styled(Box)(({ theme, disappear }) => ({
  display: 'flex',
  alignItems: 'stretch',
  marginBottom: theme.spacing(2),
  gap: theme.spacing(1),
  transition: 'opacity 0.3s ease, transform 0.3s ease',
  opacity: disappear ? 0 : 1,
  transform: disappear ? 'scale(0.95)' : 'scale(1)',
  pointerEvents: disappear ? 'none' : 'auto'
}));

const SearchBar = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: 24,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  flexGrow: 1
}));

const FilterButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  borderRadius: 20,
  padding: theme.spacing(1, 2),
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  color: theme.palette.primary.main,
  border: '1px solid rgba(0, 0, 0, 0.12)'
}));

const FixedSearchContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 16,
  right: 16,
  display: 'flex',
  gap: theme.spacing(1),
  zIndex: 1201,
  transition: 'opacity 0.3s ease, transform 0.3s ease',
}));

const FloatingSearchButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 20,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
}));

const FloatingFilterButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 20,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
}));

const AddFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: 110, // Aumentado para mejor visibilidad por encima de la barra de navegación
  right: 16,
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  boxShadow: '0 6px 15px rgba(46, 125, 50, 0.4)',
  zIndex: 1000,
}));

// Campos de formulario mejorados
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(1.5), // Reducido de 2 a 1.5
  '& .MuiOutlinedInput-root': {
    borderRadius: 20,
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
    },
  },
  '& .MuiInputLabel-outlined': {
    backgroundColor: 'transparent',
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(1.5), // Reducido de 2 a 1.5
  '& .MuiOutlinedInput-root': {
    borderRadius: 20,
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
    },
  },
  '& .MuiInputLabel-outlined': {
    backgroundColor: 'transparent',
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

// Nuevo diálogo rediseñado para formularios
const FullWidthDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0)', // Fondo transparente inicial
    backdropFilter: 'blur(0px) brightness(100%)', // Sin blur ni cambio de brillo al inicio
    transition: 'backdrop-filter 0.6s cubic-bezier(0.4, 0, 0.2, 1)', // Curva suave y dinámica
    '&.MuiBackdrop-root.MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)', // Muy leve gris para dar profundidad
      backdropFilter: 'blur(12px) brightness(95%)', // Blur más pronunciado y leve oscurecimiento
    },
  },
  '& .MuiDialog-paper': {
    borderRadius: '30px 30px 0 0',
    margin: 0,
    width: '100%',
    maxWidth: '100%',
    maxHeight: '85vh',
    position: 'fixed',
    bottom: 0,
    overflowY: 'hidden',
    boxShadow: '0 0 0 rgba(0, 0, 0, 0)', // Sombra inicial nula
    backgroundImage: 'linear-gradient(to bottom, rgba(250, 250, 250, 0.95), rgba(255, 255, 255, 0.95))',
    transition: 'box-shadow 0.6s cubic-bezier(0.4, 0, 0.2, 1), transform 0.4s ease-out', // Sombra y leve desplazamiento
    '&:hover': {
      boxShadow: '0 -10px 30px rgba(0, 0, 0, 0.15)', // Sombra dinámica al abrir
    },
  }
}));

// Contenedor principal del formulario
const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '81vh',
  width: '100%',
  position: 'relative',
}));

// Header mejorado del formulario con indicador de arrastre
const FormHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 3, 1, 3),
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
  backgroundColor: 'transparent',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '50px',
    height: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    top: '8px',
    left: '50%',
    transform: 'translateX(-50%)',
  }
}));

// Icono de cierre con animación
const CloseButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  color: theme.palette.text.secondary,
  borderRadius: 16,
  padding: 8,
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    transform: 'scale(1.05)',
  },
}));

// Pasos con animación
const StepButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 3, 2, 3),
  marginTop: theme.spacing(2.5), // Más espacio después del título
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
}));

// Contenido del formulario
const FormContent = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  flex: 1,
  padding: theme.spacing(3, 3, 5, 3),
  backgroundColor: '#f8f9fa', // Fondo igual al contenedor principal
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
  },
}));

// Footer del formulario
const FormFooter = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderTop: '1px solid rgba(0, 0, 0, 0.06)',
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.05)',
}));

// Sección del formulario
const FormSection = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  borderRadius: 24,
  marginBottom: theme.spacing(3),
}));

const FormSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
  fontSize: '1.1rem',
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    fontSize: '1.4rem',
  },
}));

// Área de carga de archivos
const FileUploadBox = styled(Box)(({ theme }) => ({
  border: '2px dashed rgba(46, 125, 50, 0.3)',
  borderRadius: 24,
  padding: theme.spacing(4),
  textAlign: 'center',
  backgroundColor: 'rgba(46, 125, 50, 0.03)',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  marginBottom: theme.spacing(3),
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: 'rgba(46, 125, 50, 0.05)',
    transform: 'translateY(-2px)',
  },
}));

// Botón de acción
const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 28,
  textTransform: 'none',
  boxShadow: '0 3px 8px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(1.2, 3),
  fontWeight: 'bold',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 5px 12px rgba(0, 0, 0, 0.15)',
  }
}));

// Botones de navegación para los pasos
const StepButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: 20,
  textTransform: 'none',
  padding: theme.spacing(1, 2),
  minWidth: variant === 'next' ? 120 : 100,
  boxShadow: variant === 'next' ? '0 3px 8px rgba(46, 125, 50, 0.2)' : 'none',
  backgroundColor: variant === 'next' ? theme.palette.primary.main : 'transparent',
  color: variant === 'next' ? '#fff' : theme.palette.text.secondary,
  border: variant === 'next' ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
  fontWeight: variant === 'next' ? 'bold' : 'normal',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: variant === 'next' ? theme.palette.primary.dark : 'rgba(0, 0, 0, 0.05)',
    transform: 'translateY(-2px)',
    boxShadow: variant === 'next' ? '0 4px 10px rgba(46, 125, 50, 0.3)' : '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
}));

// Indicador de paso actual
const StepIndicator = styled(Box)(({ theme, active }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: active ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.12)',
  margin: '0 4px',
  transition: 'all 0.3s ease',
  transform: active ? 'scale(1.2)' : 'scale(1)',
}));

// Box para filtros
const SwipableFilterBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '28px 28px 0 0',
  padding: theme.spacing(2.5), // Reducido de 3 a 2.5
  paddingBottom: theme.spacing(4), // Reducido de 5 a 4
  marginTop: 'auto',
  maxHeight: '75vh', // Aumentado un poco para dar más espacio
  overflow: 'auto',
}));

// Toggle switch para opciones
const SwitchFormControl = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  marginRight: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1.5), // Reducido de 2 a 1.5
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.primary.main,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main,
  },
}));

// Botón de guardar
const StyledSaveButton = styled(Button)(({ theme }) => ({
  borderRadius: 28,
  boxShadow: '0 4px 12px rgba(46, 125, 50, 0.3)',
  padding: theme.spacing(1.2, 4),
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '1rem',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: '0 6px 16px rgba(46, 125, 50, 0.4)',
    transform: 'translateY(-2px)',
  }
}));

// Para la vista de detalle mejorada
const MobileDetailView = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  backgroundColor: '#f8f9fa',
  height: '85vh',
  width: '100%',
  overflow: 'auto',
  borderRadius: '28px 28px 28px 28px',
  margin: '3vh 2vw',
  boxShadow: '0 5px 20px rgba(0, 0, 0, 0.15)',
}));

const DetailSection = styled(Paper)(({ theme }) => ({
  borderRadius: 24,
  marginBottom: theme.spacing(3),
  overflow: 'hidden',
  padding: theme.spacing(3),
  backgroundColor: '#fff',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
}));

const DetailSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.1rem',
  color: theme.palette.primary.main,
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    fontSize: '1.3rem',
  },
}));

const DetailRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1.2, 0),
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const DetailLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.9rem',
}));

const DetailValue = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '0.95rem',
  textAlign: 'right',
}));

const ImagePreviewBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 200,
  borderRadius: 20,
  overflow: 'hidden',
  marginBottom: theme.spacing(3),
  backgroundColor: '#f5f5f5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid rgba(0, 0, 0, 0.1)',
}));

// Para los chips de documento
const DocChip = styled(Chip)(({ theme }) => ({
  borderRadius: 16,
  height: 36,
  padding: theme.spacing(0.5, 0.5),
  '& .MuiChip-label': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }
}));

// Panel de resumen financiero
const SummaryPanel = styled(Paper)(({ theme }) => ({
  borderRadius: 24,
  padding: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgba(46, 125, 50, 0.04)',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.08)',
}));

const SummaryRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1.2),
  '&:last-child': {
    marginBottom: 0,
  }
}));

// Controladores de cambio para tipo de cambio
const TipoCambioControl = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1),
  backgroundColor: 'transparent',
  borderRadius: 16,
}));

const TcStepButton = styled(IconButton)(({ theme, color = 'primary' }) => ({
  backgroundColor: color === 'primary' ? 'rgba(46, 125, 50, 0.1)' : 'rgba(211, 47, 47, 0.1)',
  color: color === 'primary' ? theme.palette.primary.main : theme.palette.error.main,
  borderRadius: 12,
  padding: theme.spacing(0.6),
  '&:hover': {
    backgroundColor: color === 'primary' ? 'rgba(46, 125, 50, 0.2)' : 'rgba(211, 47, 47, 0.2)',
    transform: 'scale(1.05)',
  }
}));

// Animaciones para las transiciones
const pageVariants = {
  initial: {
    opacity: 0,
    y: 20
  },
  in: {
    opacity: 1,
    y: 0
  },
  out: {
    opacity: 0,
    y: -20
  }
};

const pageTransition = {
  type: 'spring',
  stiffness: 300,
  damping: 30
};

// Componente principal
const EntradasMobile = () => {
  // Obtener el contexto de autenticación
  const { user, userRole, isClient } = useAuth();

  // Verificar si el usuario es cliente - CORREGIDO PARA USAR EL NOMBRE DE ROL CORRECTO
  const isCliente = isClient || userRole === 'client' || localStorage.getItem('userRole') === 'client';

  const theme = useTheme();
  const [entradas, setEntradas] = useState([]);
  const [filteredEntradas, setFilteredEntradas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [userProyecto, setUserProyecto] = useState('');
  const [userCajasPermitidas, setUserCajasPermitidas] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDetailView, setOpenDetailView] = useState(false);
  const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);
  const [selectedEntrada, setSelectedEntrada] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const itemsPerPage = 25;

  // Estados para la animación de eliminación y diálogo de confirmación
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteAnimation, setDeleteAnimation] = useState(false);

  // Estado para gestionar cuándo mostrar los botones flotantes
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  // Estados para los diálogos flotantes
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);

  // MODIFICADO: Estado para manejar si el resumen está expandido o colapsado
  const [summaryExpanded, setSummaryExpanded] = useState(false);

  const [summaryData, setSummaryData] = useState({
    totalARS: 0,
    totalUSD: 0,
    totalARSEquivalente: 0,
    totalUSDEquivalente: 0
  });

  // FORM STATES
  const [cajasOrigen, setCajasOrigen] = useState([]);
  const [cajasFin, setCajasFin] = useState([]);
  const [conceptos, setConceptos] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [nombreFacturas, setNombresFacturas] = useState([]);

  // ENTRADA FORM DATA
  const [fechaEntrada, setFechaEntrada] = useState(new Date());
  const [cajaOrigen, setCajaOrigen] = useState('');
  const [cajaFin, setCajaFin] = useState('');
  const [concepto, setConcepto] = useState('');
  const [nuevoConcepto, setNuevoConcepto] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [tipoCambio, setTipoCambio] = useState('');
  const [moneda, setMoneda] = useState('ARS');
  const [monto, setMonto] = useState('');
  const [proyecto, setProyecto] = useState('');

  // COMPROBANTE STATES
  const [tieneComprobante, setTieneComprobante] = useState(false);
  const [comprobanteImagen, setComprobanteImagen] = useState(null);
  const [comprobantePreview, setComprobantePreview] = useState('');
  const comprobanteInputRef = useRef(null);

  // FACTURA STATES
  const [tieneFactura, setTieneFactura] = useState(false);
  const [facturaImagen, setFacturaImagen] = useState(null);
  const [facturaPreview, setFacturaPreview] = useState('');
  const [facturaANombreDe, setFacturaANombreDe] = useState('');
  const [nuevoNombreFactura, setNuevoNombreFactura] = useState('');
  const [addingNewNombre, setAddingNewNombre] = useState(false);
  const facturaInputRef = useRef(null);

  // FILTERS STATES
  const [filterCajaOrigen, setFilterCajaOrigen] = useState('');
  const [filterCajaFin, setFilterCajaFin] = useState('');
  const [filterConcepto, setFilterConcepto] = useState('');
  const [filterProyecto, setFilterProyecto] = useState('');
  const [filterMoneda, setFilterMoneda] = useState('');
  const [filterFechaDesde, setFilterFechaDesde] = useState(null);
  const [filterFechaHasta, setFilterFechaHasta] = useState(null);
  const [filterTieneComprobante, setFilterTieneComprobante] = useState(null);
  const [filterTieneFactura, setFilterTieneFactura] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);

  // NOTIFICATION STATES
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // FORM SECTIONS
  const [currentFormSection, setCurrentFormSection] = useState(0);
  const formSections = [
    { title: 'Información Básica', icon: <InfoIcon /> },
    { title: 'Detalles Adicionales', icon: <AssignmentIcon /> },
    { title: 'Información Financiera', icon: <AttachMoneyIcon /> },
    { title: 'Documentación', icon: <DescriptionIcon /> }];

  // Referencia para el swipe en el diálogo de form
  const formDialogRef = useRef(null);

  // EFFECTS
  // Efecto para configurar el proyecto y cajas permitidas para clientes
  useEffect(() => {
    const fetchUserProyectoYCajas = async () => {
      if (isCliente && user && user.email) {
        try {
          setInitialLoading(true);
          // Obtener el proyecto del cliente
          const { data, error } = await supabase
            .from('proyectos')
            .select('codigo_de_proyecto, apellido_del_cliente, caja_proyecto')
            .eq('email_del_cliente', user.email)
            .single();

          if (error) {
            console.error('Error al obtener proyecto del cliente:', error);
            setSnackbar({
              open: true,
              message: 'Error al obtener información de tu proyecto',
              severity: 'error'
            });
            return;
          }

          if (data) {
            console.log('Datos del proyecto del cliente:', data);
            setUserProyecto(data.codigo_de_proyecto);

            // Guardar las cajas permitidas para este cliente
            const cajasPermitidas = [];
            if (data.apellido_del_cliente) cajasPermitidas.push(data.apellido_del_cliente);
            if (data.caja_proyecto) cajasPermitidas.push(data.caja_proyecto);

            setUserCajasPermitidas(cajasPermitidas);
            console.log('Cajas permitidas para este cliente:', cajasPermitidas);

            // Ahora que tenemos esta información, cargar las entradas filtradas para el cliente
            await fetchEntradasForCliente(data.codigo_de_proyecto, cajasPermitidas);
          } else {
            setSnackbar({
              open: true,
              message: 'No se encontró un proyecto asociado a tu cuenta',
              severity: 'warning'
            });
          }
        } catch (error) {
          console.error('Error en fetchUserProyectoYCajas:', error);
        } finally {
          setInitialLoading(false);
        }
      } else {
        setInitialLoading(false);
      }
    };

    fetchUserProyectoYCajas();
  }, [isCliente, user]);

  // Efecto principal para cargar datos
  useEffect(() => {
    if (isCliente) {
      // Para clientes, los datos se cargan en el useEffect anterior
      // Las entradas ya se filtraron por proyecto y cajas permitidas
    } else {
      // Para administradores, cargar todo normalmente
      fetchDolarBlue();
      fetchEntradas();
      fetchCajasOrigen();
      fetchCajasFin();
      fetchConceptos();
      fetchProyectos();
      fetchNombresFacturas();
    }
  }, [isCliente]);

  useEffect(() => {
    applyFilters();
    calculateSummary();
  }, [entradas, searchTerm, activeFilters, filterCajaOrigen, filterCajaFin, filterConcepto, filterProyecto, filterMoneda, filterFechaDesde, filterFechaHasta, filterTieneComprobante, filterTieneFactura]);

  // FETCH FUNCTIONS
  // Función para obtener entradas filtradas para un cliente
  const fetchEntradasForCliente = async (proyectoCode, cajasPermitidas) => {
    if (!proyectoCode || !cajasPermitidas.length) {
      setEntradas([]);
      setFilteredEntradas([]);
      return;
    }

    try {
      setLoading(true);

      // Construir la consulta - entradas que tengan el código de proyecto del cliente
      // Y además cuya caja de origen esté en la lista de cajas permitidas
      let query = supabase
        .from('entradas')
        .select('*')
        .eq('codigo_de_proyecto_entrada', proyectoCode)
        .in('caja_origen_entrada', cajasPermitidas)
        .order('fecha_entrada', { ascending: false });

      const { data, error } = await query;

      if (error) {
        console.error('Error al obtener entradas para el cliente:', error);
        setSnackbar({
          open: true,
          message: 'Error al cargar entradas',
          severity: 'error'
        });
        return;
      }

      // Formatear los datos
      const formattedData = data.map(item => ({
        ...item,
        fecha_formateada: item.fecha_entrada ? format(new Date(item.fecha_entrada), 'dd/MM/yyyy') : '',
        monto_formateado: formatCurrency(item.monto_entrada, item.moneda_entrada),
        imagen_comprobante_entrada: item.imagen_comprobante_entrada ? formatImageUrl(item.imagen_comprobante_entrada) : null,
        imagen_factura_entrada: item.imagen_factura_entrada ? formatImageUrl(item.imagen_factura_entrada) : null
      }));

      console.log(`Se encontraron ${formattedData.length} entradas para el cliente`);

      setEntradas(formattedData);
      setFilteredEntradas(formattedData);

      // Cargar las opciones de filtros disponibles para el cliente
      await fetchFilterOptionsForCliente(cajasPermitidas, formattedData);
    } catch (error) {
      console.error('Error en fetchEntradasForCliente:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFilterOptionsForCliente = async (cajasPermitidas, entriesData) => {
    // Cajas origen (limitadas a las permitidas para el cliente)
    setCajasOrigen(cajasPermitidas || []);

    // Extraer valores únicos para cajas fin y conceptos
    if (entriesData && entriesData.length) {
      const cajasFinSet = new Set(entriesData.map(item => item.caja_fin_entrada).filter(Boolean));
      setCajasFin(Array.from(cajasFinSet));

      const conceptosSet = new Set(entriesData.map(item => item.concepto_entrada).filter(Boolean));
      setConceptos(Array.from(conceptosSet));
    }
  };

  const fetchDolarBlue = async () => {
    try {
      const response = await fetch('https://dolarapi.com/v1/dolares/blue');
      if (!response.ok) throw new Error('Error al obtener el dólar blue');
      const data = await response.json();
      setTipoCambio(data.compra.toString());
    } catch (error) {
      console.error('Error fetching dólar blue:', error.message);
      // Valor predeterminado en caso de error
      setTipoCambio('1050');
    }
  };

  const fetchEntradas = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('entradas')
        .select('*')
        .order('fecha_entrada', { ascending: false });

      if (error) throw error;

      // Formatear datos
      const formattedData = data.map(item => ({
        ...item,
        fecha_formateada: item.fecha_entrada ? format(new Date(item.fecha_entrada), 'dd/MM/yyyy') : '',
        monto_formateado: formatCurrency(item.monto_entrada, item.moneda_entrada),
        imagen_comprobante_entrada: item.imagen_comprobante_entrada ? formatImageUrl(item.imagen_comprobante_entrada) : null,
        imagen_factura_entrada: item.imagen_factura_entrada ? formatImageUrl(item.imagen_factura_entrada) : null
      }));

      setEntradas(formattedData || []);
      setFilteredEntradas(formattedData || []);
    } catch (error) {
      console.error('Error en fetchEntradas:', error);
      setSnackbar({
        open: true,
        message: 'Error al cargar las entradas',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchCajasOrigen = async () => {
    try {
      const { data, error } = await supabase.from('cajas_origen_activas').select('caja_origen');
      if (error) throw error;
      setCajasOrigen(data.map((caja) => caja.caja_origen) || []);
    } catch (error) {
      console.error('Error fetching cajas_origen_activas:', error);
    }
  };

  const fetchCajasFin = async () => {
    try {
      const { data, error } = await supabase.from('cajas_cambios').select('caja');
      if (error) throw error;
      setCajasFin(data.map((item) => item.caja) || []);
    } catch (error) {
      console.error('Error fetchCajasFin:', error);
    }
  };

  const fetchConceptos = async () => {
    try {
      const { data, error } = await supabase.from('conceptos_de_entradas').select('concepto_de_entrada');
      if (error) throw error;
      setConceptos(data.map((item) => item.concepto_de_entrada) || []);
    } catch (error) {
      console.error('Error fetchConceptos:', error);
    }
  };

  const fetchProyectos = async () => {
    try {
      const { data, error } = await supabase.from('proyectos').select('codigo_de_proyecto');
      if (error) {
        console.error('Error fetching proyectos:', error);
        // Si no tienes una tabla de proyectos, intenta obtenerlos de entradas
        const { data: entradaData } = await supabase
          .from('entradas')
          .select('codigo_de_proyecto_entrada')
          .not('codigo_de_proyecto_entrada', 'is', null);

        if (entradaData) {
          // Extraer valores únicos
          const uniqueProyectos = [...new Set(entradaData.map(item => item.codigo_de_proyecto_entrada))];
          setProyectos(uniqueProyectos.filter(p => p)); // Filtrar valores nulos o undefined
        }
      } else {
        setProyectos(data.map((item) => item.codigo_de_proyecto) || []);
      }
    } catch (error) {
      console.error('Error en fetchProyectos:', error);
    }
  };

  const fetchNombresFacturas = async () => {
    try {
      const { data, error } = await supabase
        .from('facturas_a_nombre_de_salidas')
        .select('nombre_factura');

      if (error) console.error('Error fetching nombres factura:', error);
      else setNombresFacturas(data.map((item) => item.nombre_factura) || []);
    } catch (error) {
      console.error('Error en fetchNombresFactura:', error);
    }
  };

  // UTILITY FUNCTIONS
  const formatCurrency = (amount, currency) => {
    if (!amount) return '';
    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currency === 'USD' ? 'USD' : 'ARS',
      minimumFractionDigits: 2
    });
    return formatter.format(amount);
  };

  // MODIFICADO: Calcular el sumario de montos sin condición para mostrar/ocultar
  const calculateSummary = () => {
    let totalARS = 0;
    let totalUSD = 0;
    let totalARSEquivalente = 0;
    let totalUSDEquivalente = 0;

    filteredEntradas.forEach(entrada => {
      if (entrada.moneda_entrada === 'ARS') {
        totalARS += parseFloat(entrada.monto_entrada || 0);
        // También sumamos al equivalente en USD
        const tc = parseFloat(entrada.tipo_de_cambio_entrada || 0);
        if (tc > 0) {
          totalUSDEquivalente += parseFloat(entrada.monto_entrada || 0) / tc;
        }
        // Sumamos al equivalente en ARS (que es el mismo valor)
        totalARSEquivalente += parseFloat(entrada.monto_entrada || 0);
      } else if (entrada.moneda_entrada === 'USD') {
        totalUSD += parseFloat(entrada.monto_entrada || 0);
        // También sumamos al ARS el equivalente
        totalARSEquivalente += parseFloat(entrada.monto_entrada || 0) * parseFloat(entrada.tipo_de_cambio_entrada || 0);
        // Sumamos al equivalente en USD (que es el mismo valor)
        totalUSDEquivalente += parseFloat(entrada.monto_entrada || 0);
      }
    });

    setSummaryData({
      totalARS: totalARS.toFixed(2),
      totalUSD: totalUSD.toFixed(2),
      totalARSEquivalente: totalARSEquivalente.toFixed(2),
      totalUSDEquivalente: totalUSDEquivalente.toFixed(2)
    });

    // Ya no controlamos la visibilidad basada en filtros
    // El sumario siempre está visible (colapsado o expandido)
  };

  const buscarProyectoPorCajaOrigen = async (caja) => {
    try {
      // Primero buscar en caja_proyecto_mo
      let { data, error } = await supabase
        .from('proyectos')
        .select('codigo_de_proyecto')
        .eq('caja_proyecto_mo', caja)
        .limit(1);

      if (error) throw error;

      // Si no se encuentra, buscar en caja_proyecto
      if (!data || data.length === 0) {
        const response = await supabase
          .from('proyectos')
          .select('codigo_de_proyecto')
          .eq('caja_proyecto', caja)
          .limit(1);

        if (response.error) throw response.error;
        data = response.data;
      }

      // Si aún no se encuentra, buscar en apellido_del_cliente
      if (!data || data.length === 0) {
        const response = await supabase
          .from('proyectos')
          .select('codigo_de_proyecto')
          .eq('apellido_del_cliente', caja)
          .limit(1);

        if (response.error) throw response.error;
        data = response.data;
      }

      // Si se encontró un proyecto, establecerlo como predeterminado
      if (data && data.length > 0) {
        setProyecto(data[0].codigo_de_proyecto);
      }
    } catch (error) {
      console.error('Error al buscar proyecto por caja origen:', error);
    }
  };

  // Función para agregar nuevo concepto
  const handleAgregarConcepto = async (nuevoConceptoText) => {
    if (!nuevoConceptoText || nuevoConceptoText.trim() === '') {
      setSnackbar({
        open: true,
        message: 'Por favor, ingrese un concepto válido',
        severity: 'error'
      });
      return;
    }

    try {
      // Verificar si ya existe
      const { data, error } = await supabase
        .from('conceptos_de_entradas')
        .select('concepto_de_entrada')
        .eq('concepto_de_entrada', nuevoConceptoText.trim());

      if (error) throw error;

      // Si no existe, agregarlo
      if (!data || data.length === 0) {
        const { error: insertError } = await supabase
          .from('conceptos_de_entradas')
          .insert([{ concepto_de_entrada: nuevoConceptoText.trim() }]);

        if (insertError) throw insertError;

        // Actualizar la lista local
        setConceptos([...conceptos, nuevoConceptoText.trim()]);
        // Establecer el concepto seleccionado
        setConcepto(nuevoConceptoText.trim());

        setSnackbar({
          open: true,
          message: 'Concepto agregado con éxito',
          severity: 'success'
        });
      } else {
        // Si ya existe, solo seleccionarlo
        setConcepto(nuevoConceptoText.trim());
      }

      setNuevoConcepto('');
    } catch (error) {
      console.error('Error al agregar concepto:', error);
      setSnackbar({
        open: true,
        message: 'Error al agregar concepto: ' + error.message,
        severity: 'error'
      });
    }
  };

  // Función para ajustar el tipo de cambio
  const adjustTipoCambio = (amount) => {
    const currentValue = parseFloat(tipoCambio) || 0;
    const newValue = Math.max(0, currentValue + amount);
    setTipoCambio(newValue.toString());
  };

  const handleAgregarNuevoNombre = async () => {
    if (!nuevoNombreFactura.trim()) {
      setSnackbar({
        open: true,
        message: 'Por favor, ingrese un nombre válido',
        severity: 'error'
      });
      return;
    }

    try {
      const { error } = await supabase.from('facturas_a_nombre_de_salidas').insert([
        { nombre_factura: nuevoNombreFactura.trim() }
      ]);

      if (error) {
        throw error;
      }

      fetchNombresFacturas();
      setFacturaANombreDe(nuevoNombreFactura.trim());
      setNuevoNombreFactura('');
      setAddingNewNombre(false);

      setSnackbar({
        open: true,
        message: 'Nombre agregado con éxito',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error al agregar nuevo nombre:', error.message);
      setSnackbar({
        open: true,
        message: 'Error al agregar nuevo nombre: ' + error.message,
        severity: 'error'
      });
    }
  };

  const applyFilters = () => {
    let filtered = [...entradas];

    // Búsqueda global
    if (searchTerm) {
      filtered = filtered.filter(entrada =>
        Object.values(entrada).some(value =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Filtros específicos
    if (filterCajaOrigen) {
      filtered = filtered.filter(entrada => entrada.caja_origen_entrada === filterCajaOrigen);
    }

    if (filterCajaFin) {
      filtered = filtered.filter(entrada => entrada.caja_fin_entrada === filterCajaFin);
    }

    if (filterConcepto) {
      filtered = filtered.filter(entrada => entrada.concepto_entrada === filterConcepto);
    }

    if (filterProyecto) {
      filtered = filtered.filter(entrada => entrada.codigo_de_proyecto_entrada === filterProyecto);
    }

    if (filterMoneda) {
      filtered = filtered.filter(entrada => entrada.moneda_entrada === filterMoneda);
    }

    if (filterFechaDesde) {
      filtered = filtered.filter(entrada =>
        new Date(entrada.fecha_entrada) >= new Date(filterFechaDesde)
      );
    }

    if (filterFechaHasta) {
      filtered = filtered.filter(entrada =>
        new Date(entrada.fecha_entrada) <= new Date(filterFechaHasta)
      );
    }

    if (filterTieneComprobante !== null) {
      filtered = filtered.filter(entrada => entrada.tiene_comprobante_entrada === filterTieneComprobante);
    }

    if (filterTieneFactura !== null) {
      filtered = filtered.filter(entrada => entrada.tiene_factura_entrada === filterTieneFactura);
    }

    setFilteredEntradas(filtered);
  };

  // MODIFICADO: Ya no controlamos la visibilidad del sumario en resetFilters
  const resetFilters = () => {
    setFilterCajaOrigen('');
    setFilterCajaFin('');
    setFilterConcepto('');
    setFilterProyecto('');
    setFilterMoneda('');
    setFilterFechaDesde(null);
    setFilterFechaHasta(null);
    setFilterTieneComprobante(null);
    setFilterTieneFactura(null);
    setActiveFilters([]);
    setSearchTerm('');
    // Eliminamos esta línea: setSummaryVisible(false);
  };

  const applyFilterSelection = () => {
    // Crear array de filtros activos para mostrar badges
    const newFilters = [];

    if (filterCajaOrigen) {
      newFilters.push({ type: 'cajaOrigen', value: filterCajaOrigen });
    }

    if (filterCajaFin) {
      newFilters.push({ type: 'cajaFin', value: filterCajaFin });
    }

    if (filterConcepto) {
      newFilters.push({ type: 'concepto', value: filterConcepto });
    }

    if (filterProyecto) {
      newFilters.push({ type: 'proyecto', value: filterProyecto });
    }

    if (filterMoneda) {
      newFilters.push({ type: 'moneda', value: filterMoneda });
    }

    if (filterFechaDesde) {
      newFilters.push({ type: 'fechaDesde', value: format(new Date(filterFechaDesde), 'dd/MM/yy') });
    }

    if (filterFechaHasta) {
      newFilters.push({ type: 'fechaHasta', value: format(new Date(filterFechaHasta), 'dd/MM/yy') });
    }

    if (filterTieneComprobante !== null) {
      newFilters.push({
        type: 'tieneComprobante',
        value: filterTieneComprobante ? 'Con comprobante' : 'Sin comprobante'
      });
    }

    if (filterTieneFactura !== null) {
      newFilters.push({
        type: 'tieneFactura',
        value: filterTieneFactura ? 'Con factura' : 'Sin factura'
      });
    }

    setActiveFilters(newFilters);
    setOpenFiltersDrawer(false);
    applyFilters();
    calculateSummary();
  };

  // Función para resetear formulario
  const resetForm = () => {
    setFechaEntrada(new Date());
    setCajaOrigen('');
    setCajaFin('');
    setConcepto('');
    setDescripcion('');
    setTipoCambio('');
    setMoneda('ARS');
    setMonto('');
    setProyecto('');

    // Resetear estados de comprobante
    setTieneComprobante(false);
    setComprobanteImagen(null);
    setComprobantePreview('');
    if (comprobanteInputRef.current) {
      comprobanteInputRef.current.value = '';
    }

    // Resetear estados de factura
    setTieneFactura(false);
    setFacturaImagen(null);
    setFacturaPreview('');
    setFacturaANombreDe('');
    setNuevoNombreFactura('');
    setAddingNewNombre(false);
    if (facturaInputRef.current) {
      facturaInputRef.current.value = '';
    }
  };

  // FORM HANDLERS
  const handleOpenAddDialog = () => {
    // No permitir agregar entradas para clientes
    if (isCliente) {
      setSnackbar({
        open: true,
        message: 'No tienes permisos para agregar entradas',
        severity: 'warning'
      });
      return;
    }

    setEditMode(false);
    resetForm();
    setCurrentFormSection(0);
    setOpenAddDialog(true);
  };

  const handleOpenEditDialog = (entrada) => {
    // No permitir editar entradas para clientes
    if (isCliente) {
      setSnackbar({
        open: true,
        message: 'No tienes permisos para editar entradas',
        severity: 'warning'
      });
      return;
    }

    setEditMode(true);
    setSelectedEntrada(entrada);
    populateFormWithEntrada(entrada);
    setCurrentFormSection(0);
    setOpenAddDialog(true);
  };

  const populateFormWithEntrada = (entrada) => {
    // Datos básicos
    setFechaEntrada(new Date(entrada.fecha_entrada));
    setCajaOrigen(entrada.caja_origen_entrada || '');
    setCajaFin(entrada.caja_fin_entrada || '');
    setConcepto(entrada.concepto_entrada || '');
    setDescripcion(entrada.descripción_entrada || '');
    setTipoCambio(entrada.tipo_de_cambio_entrada || '');
    setMoneda(entrada.moneda_entrada || 'ARS');
    setMonto(entrada.monto_entrada || '');
    setProyecto(entrada.codigo_de_proyecto_entrada || '');

    // Comprobante
    setTieneComprobante(entrada.tiene_comprobante_entrada || false);
    setComprobantePreview(entrada.imagen_comprobante_entrada || '');

    // Factura
    setTieneFactura(entrada.tiene_factura_entrada || false);
    setFacturaPreview(entrada.imagen_factura_entrada || '');
    setFacturaANombreDe(entrada.factura_a_nombre_de_entrada || '');
  };

  const handleComprobanteChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificar tipo de archivo
      if (!file.type.match('image.*')) {
        setSnackbar({
          open: true,
          message: 'Por favor, selecciona un archivo de imagen válido',
          severity: 'error'
        });
        return;
      }

      // Verificar tamaño (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setSnackbar({
          open: true,
          message: 'La imagen debe ser menor a 5MB',
          severity: 'error'
        });
        return;
      }

      setComprobanteImagen(file);
      setTieneComprobante(true);

      // Crear URL temporal para vista previa
      const previewURL = URL.createObjectURL(file);
      setComprobantePreview(previewURL);
    }
  };

  const handleFacturaChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificar tipo de archivo
      if (!file.type.match('image.*')) {
        setSnackbar({
          open: true,
          message: 'Por favor, selecciona un archivo de imagen válido',
          severity: 'error'
        });
        return;
      }

      // Verificar tamaño (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setSnackbar({
          open: true,
          message: 'La imagen debe ser menor a 5MB',
          severity: 'error'
        });
        return;
      }

      setFacturaImagen(file);
      setTieneFactura(true);

      // Crear URL temporal para vista previa
      const previewURL = URL.createObjectURL(file);
      setFacturaPreview(previewURL);
    }
  };

  const handleComprobanteUpload = () => {
    comprobanteInputRef.current.click();
  };

  const handleFacturaUpload = () => {
    facturaInputRef.current.click();
  };

  const removeComprobante = () => {
    setComprobanteImagen(null);
    setComprobantePreview('');

    // Limpiar el input de archivo
    if (comprobanteInputRef.current) {
      comprobanteInputRef.current.value = '';
    }
  };

  const removeFactura = () => {
    setFacturaImagen(null);
    setFacturaPreview('');
    setFacturaANombreDe('');

    // Limpiar el input de archivo
    if (facturaInputRef.current) {
      facturaInputRef.current.value = '';
    }
  };

  const handleSubmitForm = async () => {
    // No permitir guardar entradas para clientes
    if (isCliente) {
      setSnackbar({
        open: true,
        message: 'No tienes permisos para agregar o editar entradas',
        severity: 'warning'
      });
      return;
    }

    // Validar campos obligatorios
    if (!fechaEntrada || !cajaOrigen || !cajaFin || !tipoCambio || !moneda || !monto) {
      setSnackbar({
        open: true,
        message: 'Por favor, completa todos los campos obligatorios',
        severity: 'error'
      });
      return;
    }

    // Validaciones adicionales para documentos
    if (tieneFactura && !facturaImagen && !facturaPreview) {
      setSnackbar({
        open: true,
        message: 'Por favor, sube una imagen de la factura',
        severity: 'error'
      });
      return;
    }

    if (tieneComprobante && !comprobanteImagen && !comprobantePreview) {
      setSnackbar({
        open: true,
        message: 'Por favor, sube una imagen del comprobante',
        severity: 'error'
      });
      return;
    }

    try {
      setLoading(true);

      let imageDataComprobante = null;
      let imageDataFactura = null;

      // Si estamos en edición y ya hay imágenes previas, mantenerlas
      const entradaId = editMode && selectedEntrada ? selectedEntrada.id : null;
      let imagenComprobanteExistente = editMode && selectedEntrada ? selectedEntrada.imagen_comprobante_entrada : null;
      let rutaComprobanteExistente = editMode && selectedEntrada ? selectedEntrada.ruta_archivo_comprobante : null;

      let imagenFacturaExistente = editMode && selectedEntrada ? selectedEntrada.imagen_factura_entrada : null;
      let rutaFacturaExistente = editMode && selectedEntrada ? selectedEntrada.ruta_archivo_factura : null;

      // Subir comprobante si existe una nueva imagen
      if (tieneComprobante && comprobanteImagen) {
        // Si hay edición y hay imagen previa, eliminarla
        if (editMode && rutaComprobanteExistente) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([rutaComprobanteExistente]);
          } catch (err) {
            console.error("Error al eliminar comprobante existente:", err);
            // Continuar aunque falle la eliminación
          }
        }

        try {
          imageDataComprobante = await uploadImage(comprobanteImagen, {
            tipo: 'entrada',
            referencia: cajaOrigen,
            concepto: concepto,
            proyecto: proyecto
          });
        } catch (uploadError) {
          throw new Error('Error al subir el comprobante: ' + uploadError.message);
        }
      }

      // Subir factura si existe una nueva imagen
      if (tieneFactura && facturaImagen) {
        // Si hay edición y hay imagen previa, eliminarla
        if (editMode && rutaFacturaExistente) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([rutaFacturaExistente]);
          } catch (err) {
            console.error("Error al eliminar factura existente:", err);
            // Continuar aunque falle la eliminación
          }
        }

        try {
          imageDataFactura = await uploadImage(facturaImagen, {
            tipo: 'entrada_factura',
            referencia: cajaOrigen,
            concepto: concepto,
            proyecto: proyecto
          });
        } catch (uploadError) {
          throw new Error('Error al subir la factura: ' + uploadError.message);
        }
      }

      // Asegurarse que todos los datos numéricos sean números válidos
      const validMonto = parseFloat(monto);
      const validTipoCambio = parseFloat(tipoCambio);

      // Preparar datos para guardar
      const entradaData = {
        fecha_entrada: fechaEntrada.toISOString(),
        caja_origen_entrada: cajaOrigen,
        caja_fin_entrada: cajaFin,
        concepto_entrada: concepto,
        descripción_entrada: descripcion,
        tipo_de_cambio_entrada: validTipoCambio,
        moneda_entrada: moneda,
        monto_entrada: validMonto,
        codigo_de_proyecto_entrada: proyecto,

        // Datos de comprobante
        tiene_comprobante_entrada: tieneComprobante,
        imagen_comprobante_entrada: tieneComprobante
          ? (imageDataComprobante ? imageDataComprobante.url : imagenComprobanteExistente)
          : null,
        codigo_de_comprobante_entrada: tieneComprobante && imageDataComprobante
          ? imageDataComprobante.codigo
          : selectedEntrada?.codigo_de_comprobante_entrada,
        ruta_archivo_comprobante: tieneComprobante
          ? (imageDataComprobante ? imageDataComprobante.ruta : rutaComprobanteExistente)
          : null,

        // Datos de factura
        tiene_factura_entrada: tieneFactura,
        imagen_factura_entrada: tieneFactura
          ? (imageDataFactura ? imageDataFactura.url : imagenFacturaExistente)
          : null,
        codigo_de_factura_entrada: tieneFactura && imageDataFactura
          ? imageDataFactura.codigo
          : selectedEntrada?.codigo_de_factura_entrada,
        ruta_archivo_factura: tieneFactura
          ? (imageDataFactura ? imageDataFactura.ruta : rutaFacturaExistente)
          : null,
        factura_a_nombre_de_entrada: tieneFactura ? facturaANombreDe : null,
      };

      // Registro detallado para depuración
      console.log("Enviando datos a Supabase:", entradaData);

      let response;
      if (editMode && entradaId) {
        // Actualizar entrada existente
        response = await supabase
          .from('entradas')
          .update(entradaData)
          .eq('id', entradaId);
      } else {
        // Crear nueva entrada
        response = await supabase
          .from('entradas')
          .insert([entradaData]);
      }

      if (response.error) {
        console.error("Error de Supabase:", response.error);
        throw new Error(
          (editMode ? 'Error al actualizar entrada: ' : 'Error al agregar entrada: ') +
          (response.error.message || 'Por favor, verifica los datos e intenta nuevamente')
        );
      }

      // Recargar datos
      if (isCliente) {
        fetchEntradasForCliente(userProyecto, userCajasPermitidas);
      } else {
        fetchEntradas();
      }

      setSnackbar({
        open: true,
        message: editMode ? 'Entrada actualizada con éxito' : 'Entrada agregada con éxito',
        severity: 'success'
      });

      setOpenAddDialog(false);
      resetForm();

    } catch (error) {
      console.error('Error al guardar entrada:', error);
      setSnackbar({
        open: true,
        message: error.message || "Error al procesar la solicitud. Intenta nuevamente.",
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Función para animar la eliminación
  const animateItemDelete = () => {
    setDeleteAnimation(true);

    // Establecer referencia al elemento de la vista detallada si existe
    if (openDetailView && selectedEntrada) {
      const detailElement = document.querySelector('.mobile-detail-view');
      if (detailElement) {
        detailElement.style.transition = 'all 0.5s ease';
        detailElement.style.transform = 'scale(0.8)';
        detailElement.style.opacity = '0';
      }
    }

    // Esperar a que termine la animación antes de ejecutar la eliminación
    setTimeout(() => {
      if (selectedEntrada) {
        handleDeleteEntrada(selectedEntrada);
      }
      setDeleteAnimation(false);
    }, 300);
  };

  const handleDeleteEntrada = async (entrada, event) => {
    // No permitir eliminar entradas para clientes
    if (isCliente) {
      setSnackbar({
        open: true,
        message: 'No tienes permisos para eliminar entradas',
        severity: 'warning'
      });
      return;
    }

    if (event) {
      event.stopPropagation();
    }

    try {
      setLoading(true);

      // Eliminar archivos si existen
      if (entrada.tiene_comprobante_entrada && entrada.ruta_archivo_comprobante) {
        try {
          await supabase.storage
            .from('Comprobantes')
            .remove([entrada.ruta_archivo_comprobante]);
        } catch (error) {
          console.error('Error al eliminar el archivo de comprobante:', error);
          // Continuar aunque falle la eliminación
        }
      }

      if (entrada.tiene_factura_entrada && entrada.ruta_archivo_factura) {
        try {
          await supabase.storage
            .from('Comprobantes')
            .remove([entrada.ruta_archivo_factura]);
        } catch (error) {
          console.error('Error al eliminar el archivo de factura:', error);
          // Continuar aunque falle la eliminación
        }
      }

      // Eliminar el registro
      const { error } = await supabase
        .from('entradas')
        .delete()
        .eq('id', entrada.id);

      if (error) {
        throw error;
      }

      setSnackbar({
        open: true,
        message: 'Entrada eliminada con éxito',
        severity: 'success'
      });

      // Recargar entradas dependiendo del tipo de usuario
      if (isCliente) {
        fetchEntradasForCliente(userProyecto, userCajasPermitidas);
      } else {
        fetchEntradas();
      }

      // Cerrar vista de detalle si está abierta
      if (openDetailView && selectedEntrada && selectedEntrada.id === entrada.id) {
        setOpenDetailView(false);
      }

      // Cerrar diálogo de confirmación
      setDeleteConfirmOpen(false);

    } catch (error) {
      console.error('Error al eliminar entrada:', error);
      setSnackbar({
        open: true,
        message: 'Error al eliminar entrada: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // VIEW HANDLERS
  const handleOpenDetailView = (entrada) => {
    setSelectedEntrada(entrada);
    setOpenDetailView(true);
  };

  const handleOpenImagePreview = (imageUrl, event) => {
    if (event) {
      event.stopPropagation();
    }
    setCurrentImage(imageUrl);
    setImagePreviewOpen(true);
  };

  const handleNextFormSection = () => {
    if (currentFormSection < formSections.length - 1) {
      setCurrentFormSection(prevSection => prevSection + 1);
    }
  };

  const handlePrevFormSection = () => {
    if (currentFormSection > 0) {
      setCurrentFormSection(prevSection => prevSection - 1);
    }
  };

  // Handle swipe down to close dialog
  const handleFormDialogSwipe = (e) => {
    if (e.target.scrollTop === 0 && e.deltaY < -50) {
      setOpenAddDialog(false);
    }
  };

  // Función para manejar la apertura del diálogo de búsqueda flotante
  const handleSearchDialogOpen = () => {
    setSearchDialogOpen(true);
  };

  // Contenido del formulario según la sección actual - REDISEÑADO
  const renderFormContent = () => {
    switch (currentFormSection) {
      case 0: // Información Básica
        return (
          <Box>
            <FormSectionTitle>
              <InfoIcon /> Información Básica
            </FormSectionTitle>

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <DatePicker
                label="Fecha"
                value={fechaEntrada}
                onChange={(newValue) => setFechaEntrada(newValue)}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>

            <StyledFormControl fullWidth>
              <InputLabel>Caja Origen</InputLabel>
              <Select
                value={cajaOrigen}
                onChange={(e) => {
                  setCajaOrigen(e.target.value);
                  buscarProyectoPorCajaOrigen(e.target.value);
                }}
                label="Caja Origen"
              >
                {cajasOrigen.map((caja) => (
                  <MenuItem key={caja} value={caja}>
                    {caja}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>

            <StyledFormControl fullWidth>
              <InputLabel>Caja Fin</InputLabel>
              <Select
                value={cajaFin}
                onChange={(e) => setCajaFin(e.target.value)}
                label="Caja Fin"
              >
                {cajasFin.map((caja) => (
                  <MenuItem key={caja} value={caja}>
                    {caja}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Box>
        );

      case 1: // Detalles Adicionales
        return (
          <Box>
            <FormSectionTitle>
              <AssignmentIcon /> Detalles Adicionales
            </FormSectionTitle>

            <StyledFormControl fullWidth>
              <InputLabel>Proyecto</InputLabel>
              <Select
                value={proyecto}
                onChange={(e) => setProyecto(e.target.value)}
                label="Proyecto"
              >
                <MenuItem value="">Sin proyecto</MenuItem>
                {proyectos.map((proy) => (
                  <MenuItem key={proy} value={proy}>
                    {proy}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>

            <Autocomplete
              value={concepto}
              onChange={(event, newValue) => {
                if (newValue) {
                  setConcepto(newValue);
                  // Ocultar teclado después de seleccionar
                  document.activeElement.blur();
                }
              }}
              inputValue={nuevoConcepto}
              onInputChange={(event, newInputValue) => {
                setNuevoConcepto(newInputValue);
              }}
              options={conceptos}
              freeSolo
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label="Concepto"
                  margin="normal"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {nuevoConcepto && !conceptos.includes(nuevoConcepto) && (
                          <Tooltip title="Agregar nuevo concepto">
                            <IconButton
                              size="small"
                              onClick={() => handleAgregarConcepto(nuevoConcepto)}
                              sx={{ mr: 1 }}
                            >
                              <AddIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />

            <StyledTextField
              label="Descripción (opcional)"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              multiline
              rows={3}
              fullWidth
              placeholder="Ingrese una descripción detallada..."
            />
          </Box>
        );

      case 2: // Información Financiera
        return (
          <Box>
            <FormSectionTitle>
              <AttachMoneyIcon /> Información Financiera
            </FormSectionTitle>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <StyledFormControl fullWidth>
                  <InputLabel>Moneda</InputLabel>
                  <Select
                    value={moneda}
                    onChange={(e) => setMoneda(e.target.value)}
                    label="Moneda"
                  >
                    <MenuItem value="ARS">ARS</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  label="Tipo de Cambio"
                  value={tipoCambio}
                  onChange={(e) => setTipoCambio(e.target.value)}
                  fullWidth
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>

            {/* Controles para ajustar el tipo de cambio - Solo botones ±5 */}
            <TipoCambioControl>
              <Typography variant="body2" sx={{ flex: 1 }}>Ajustar tipo de cambio:</Typography>
              <TcStepButton color="error" onClick={() => adjustTipoCambio(-5)}>
                -5
              </TcStepButton>
              <TcStepButton onClick={() => adjustTipoCambio(5)}>
                +5
              </TcStepButton>
              <Tooltip title="Actualizar al valor actual">
                <IconButton
                  size="small"
                  onClick={fetchDolarBlue}
                  sx={{ ml: 1, bgcolor: 'rgba(46, 125, 50, 0.1)', color: 'primary.main' }}
                >
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TipoCambioControl>

            <StyledTextField
              label="Monto"
              value={monto}
              onChange={(e) => setMonto(e.target.value)}
              fullWidth
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">{moneda === 'USD' ? '$' : '$'}</InputAdornment>,
              }}
            />
          </Box>
        );

      case 3: // Documentación
        return (
          <Box>
            <FormSectionTitle>
              <DescriptionIcon /> Documentación
            </FormSectionTitle>

            <SwitchFormControl
              control={
                <Switch
                  checked={tieneComprobante}
                  onChange={(e) => setTieneComprobante(e.target.checked)}
                  color="primary"
                />
              }
              label="¿Tiene comprobante?"
              labelPlacement="start"
            />

            {tieneComprobante && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <input
                  ref={comprobanteInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleComprobanteChange}
                  style={{ display: 'none' }}
                />

                {!comprobantePreview ? (
                  <FileUploadBox onClick={handleComprobanteUpload}>
                    <CameraAltIcon sx={{ fontSize: 40, color: theme.palette.primary.main, mb: 1 }} />
                    <Typography variant="body1" gutterBottom>
                      Toca para subir una imagen de comprobante
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      JPG, PNG (máx. 5MB)
                    </Typography>
                  </FileUploadBox>
                ) : (
                  <Box sx={{ position: 'relative', mb: 2 }}>
                    <ImagePreviewBox>
                      <Box
                        component="img"
                        src={comprobantePreview}
                        alt="Vista previa del comprobante"
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </ImagePreviewBox>

                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
                      }}
                      onClick={removeComprobante}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </motion.div>
            )}

            <SwitchFormControl
              control={
                <Switch
                  checked={tieneFactura}
                  onChange={(e) => setTieneFactura(e.target.checked)}
                  color="primary"
                />
              }
              label="¿Tiene factura?"
              labelPlacement="start"
            />

            {tieneFactura && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <input
                  ref={facturaInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleFacturaChange}
                  style={{ display: 'none' }}
                />

                {!facturaPreview ? (
                  <FileUploadBox onClick={handleFacturaUpload}>
                    <CameraAltIcon sx={{ fontSize: 40, color: theme.palette.primary.main, mb: 1 }} />
                    <Typography variant="body1" gutterBottom>
                      Toca para subir una imagen de factura
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      JPG, PNG (máx. 5MB)
                    </Typography>
                  </FileUploadBox>
                ) : (
                  <Box sx={{ position: 'relative', mb: 2 }}>
                    <ImagePreviewBox>
                      <Box
                        component="img"
                        src={facturaPreview}
                        alt="Vista previa de la factura"
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </ImagePreviewBox>

                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
                      }}
                      onClick={removeFactura}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}

                <StyledFormControl fullWidth>
                  <InputLabel>A nombre de</InputLabel>
                  <Select
                    value={facturaANombreDe}
                    onChange={(e) => setFacturaANombreDe(e.target.value)}
                    label="A nombre de"
                  >
                    <MenuItem value="">
                      <em>Seleccione un nombre</em>
                    </MenuItem>
                    {nombreFacturas.map((nombre) => (
                      <MenuItem key={nombre} value={nombre}>
                        {nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>

                <Box mt={1} mb={2} display="flex" justifyContent="center">
                  <Button
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => setAddingNewNombre(true)}
                    sx={{ borderRadius: 16, py: 1, px: 2 }}
                  >
                    Agregar nuevo nombre
                  </Button>
                </Box>

                {addingNewNombre && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <StyledTextField
                        value={nuevoNombreFactura}
                        onChange={(e) => setNuevoNombreFactura(e.target.value)}
                        label="Nuevo nombre"
                        fullWidth
                        size="small"
                      />
                      <IconButton
                        color="primary"
                        onClick={handleAgregarNuevoNombre}
                        sx={{ ml: 1 }}
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => setAddingNewNombre(false)}
                        sx={{ ml: 0.5 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </motion.div>
                )}
              </motion.div>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  // Renderización de las tarjetas de entrada para la vista principal
  const renderEntradaCard = (entrada) => {
    // Función para animar la eliminación desde la tarjeta
    const animateCardDelete = (e) => {
      e.stopPropagation();

      // No permitir eliminar para clientes
      if (isCliente) {
        setSnackbar({
          open: true,
          message: 'No tienes permisos para eliminar entradas',
          severity: 'warning'
        });
        return;
      }

      setDeleteConfirmOpen(true);
      setSelectedEntrada(entrada);
    };

    return (
      <EntradaCard
        key={entrada.id}
        onClick={() => handleOpenDetailView(entrada)}
        component={motion.div}
        whileHover={{ y: -4 }}
        whileTap={{ scale: 0.98 }}
      >
        <CardContent sx={{ p: 2, pb: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                sx={{
                  bgcolor: entrada.moneda_entrada === 'USD' ? 'rgba(255, 193, 7, 0.1)' : 'rgba(46, 125, 50, 0.1)',
                  color: entrada.moneda_entrada === 'USD' ? '#f57c00' : '#2e7d32',
                  mr: 1.5,
                  width: 40,
                  height: 40,
                  fontSize: '0.9rem',
                  fontWeight: 'bold'
                }}
              >
                {entrada.moneda_entrada}
              </Avatar>
              <Box>
                <Typography variant="body1" fontWeight="bold">
                  {entrada.monto_formateado}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  TC: {entrada.tipo_de_cambio_entrada}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2" fontWeight="medium" color="primary">
                {entrada.fecha_formateada}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {entrada.caja_origen_entrada}
              </Typography>
            </Box>
          </Box>

          <CardRow>
            <Typography variant="body2" color="text.secondary">Caja Fin:</Typography>
            <Typography variant="body2" fontWeight="medium" noWrap sx={{ maxWidth: '60%', textAlign: 'right' }}>
              {entrada.caja_fin_entrada}
            </Typography>
          </CardRow>

          <CardRow>
            <Typography variant="body2" color="text.secondary">Concepto:</Typography>
            <Typography variant="body2" fontWeight="medium" noWrap sx={{ maxWidth: '60%', textAlign: 'right' }}>
              {entrada.concepto_entrada}
            </Typography>
          </CardRow>

          {entrada.codigo_de_proyecto_entrada && (
            <CardRow>
              <Typography variant="body2" color="text.secondary">Proyecto:</Typography>
              <Typography variant="body2" fontWeight="medium" noWrap sx={{ maxWidth: '60%', textAlign: 'right' }}>
                {entrada.codigo_de_proyecto_entrada}
              </Typography>
            </CardRow>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            {entrada.tiene_comprobante_entrada && (
              <DocChip
                icon={<ReceiptIcon />}
                label="Comprobante"
                size="small"
                color="success"
                variant="outlined"
                sx={{ mr: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenImagePreview(entrada.imagen_comprobante_entrada, e);
                }}
              />
            )}

            {entrada.tiene_factura_entrada && (
              <DocChip
                icon={<DescriptionIcon />}
                label="Factura"
                size="small"
                color="primary"
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenImagePreview(entrada.imagen_factura_entrada, e);
                }}
              />
            )}

            {/* Solo mostrar botones de edición/eliminación para administradores */}
            {!isCliente && (
              <Box sx={{ ml: 'auto', display: 'flex' }}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenEditDialog(entrada);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>

                <IconButton
                  size="small"
                  color="error"
                  onClick={animateCardDelete}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
        </CardContent>
      </EntradaCard>
    );
  };

  // RENDER - REDISEÑADO COMPLETAMENTE
  return (
    <>
      <MobileContainer sx={{ backgroundColor: '#f8f9fa' }}>
        {/* Barra de búsqueda con botón de filtro separado */}
        <SearchBarContainer disappear={trigger}>
          <SearchBar elevation={1}>
            <InputBase
              placeholder="Buscar entradas..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              sx={{ ml: 1 }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </SearchBar>
          <FilterButton
            startIcon={<FilterAltIcon />}
            onClick={() => setOpenFiltersDrawer(true)}
            variant="outlined"
          >
            Filtros
          </FilterButton>
        </SearchBarContainer>

        {/* Botones flotantes que aparecen al hacer scroll */}
        <Zoom in={trigger && !openDetailView}>
          <FixedSearchContainer>
            <FloatingSearchButton
              color="primary"
              onClick={() => {
                setSearchTerm('');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              <SearchIcon />
            </FloatingSearchButton>
            <FloatingFilterButton
              color="primary"
              onClick={() => setOpenFiltersDrawer(true)}
            >
              <FilterAltIcon />
            </FloatingFilterButton>
          </FixedSearchContainer>
        </Zoom>

        {/* MODIFICADO: Sumario financiero siempre visible pero colapsable */}
        <SummaryPanel>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: summaryExpanded ? 1 : 0 }}>
            <SummarizeIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="subtitle1" fontWeight="bold">
              Resumen Financiero
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              size="small"
              onClick={() => setSummaryExpanded(!summaryExpanded)}
              sx={{ transform: summaryExpanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}
            >
              <KeyboardArrowUpIcon fontSize="small" />
            </IconButton>
          </Box>

          {summaryExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Divider sx={{ mb: 2 }} />
              <SummaryRow>
                <Typography variant="body2" color="text.secondary">Total ARS:</Typography>
                <Typography variant="body1" fontWeight="bold" color="primary.main">
                  {formatCurrency(summaryData.totalARS, 'ARS')}
                </Typography>
              </SummaryRow>
              <SummaryRow>
                <Typography variant="body2" color="text.secondary">Total USD:</Typography>
                <Typography variant="body1" fontWeight="bold" color="#f57c00">
                  {formatCurrency(summaryData.totalUSD, 'USD')}
                </Typography>
              </SummaryRow>
              <Divider sx={{ my: 1 }} />
              <SummaryRow>
                <Typography variant="body2" color="text.secondary">Equivalente en ARS:</Typography>
                <Typography variant="body1" fontWeight="bold" color="primary.main">
                  {formatCurrency(summaryData.totalARSEquivalente, 'ARS')}
                </Typography>
              </SummaryRow>
              <SummaryRow>
                <Typography variant="body2" color="text.secondary">Equivalente en USD:</Typography>
                <Typography variant="body1" fontWeight="bold" color="#f57c00">
                  {formatCurrency(summaryData.totalUSDEquivalente, 'USD')}
                </Typography>
              </SummaryRow>
            </motion.div>
          )}
        </SummaryPanel>

        {/* Filtros activos */}
        {activeFilters.length > 0 && (
          <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap' }}>
            {activeFilters.map((filter, index) => (
              <Chip
                key={index}
                label={`${filter.type === 'cajaOrigen' ? 'Origen: ' :
                  filter.type === 'cajaFin' ? 'Fin: ' :
                    filter.type === 'concepto' ? 'Conc: ' :
                      filter.type === 'proyecto' ? 'Proy: ' :
                        filter.type === 'moneda' ? 'Mon: ' :
                          filter.type === 'fechaDesde' ? 'Desde: ' :
                            filter.type === 'fechaHasta' ? 'Hasta: ' :
                              filter.type === 'tieneComprobante' ? '' :
                                filter.type === 'tieneFactura' ? '' : ''}${filter.value}`}
                onDelete={() => {
                  const newFilters = [...activeFilters];
                  newFilters.splice(index, 1);
                  setActiveFilters(newFilters);

                  // También resetear el estado correspondiente
                  if (filter.type === 'cajaOrigen') setFilterCajaOrigen('');
                  if (filter.type === 'cajaFin') setFilterCajaFin('');
                  if (filter.type === 'concepto') setFilterConcepto('');
                  if (filter.type === 'proyecto') setFilterProyecto('');
                  if (filter.type === 'moneda') setFilterMoneda('');
                  if (filter.type === 'fechaDesde') setFilterFechaDesde(null);
                  if (filter.type === 'fechaHasta') setFilterFechaHasta(null);
                  if (filter.type === 'tieneComprobante') setFilterTieneComprobante(null);
                  if (filter.type === 'tieneFactura') setFilterTieneFactura(null);
                }}
                size="small"
                sx={{ m: 0.5, borderRadius: 16, py: 1 }}
              />
            ))}
            <Chip
              label="Limpiar"
              onClick={resetFilters}
              size="small"
              color="primary"
              sx={{ m: 0.5, borderRadius: 16, py: 1 }}
            />
          </Box>
        )}

        {/* Lista de entradas */}
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          {loading && filteredEntradas.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', flexDirection: 'column' }}>
              <CircularProgress color="primary" />
              <Typography variant="body1" sx={{ mt: 2 }}>Cargando entradas...</Typography>
            </Box>
          ) : filteredEntradas.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', flexDirection: 'column' }}>
              <Typography variant="body1">No se encontraron entradas</Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Intenta con otros criterios de búsqueda{!isCliente ? ' o crea una nueva entrada' : ''}
              </Typography>
              {!isCliente && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleOpenAddDialog}
                  sx={{ mt: 2, borderRadius: 20, px: 3, py: 1 }}
                >
                  Nueva Entrada
                </Button>
              )}
            </Box>
          ) : (
            <>
              {filteredEntradas
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map(renderEntradaCard)}

              {/* Paginación */}
              {filteredEntradas.length > itemsPerPage && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 4 }}>
                  <Pagination
                    count={Math.ceil(filteredEntradas.length / itemsPerPage)}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    color="primary"
                    showFirstButton
                    showLastButton
                    size="small"
                  />
                </Box>
              )}
            </>
          )}
        </motion.div>

        {/* Botón flotante para agregar - Solo para administradores */}
        {!isCliente && (
          <AddFab
            color="primary"
            aria-label="add"
            onClick={handleOpenAddDialog}
          >
            <AddIcon />
          </AddFab>
        )}

        {/* Dialog para agregar/editar entrada */}
        <FullWidthDialog
          open={openAddDialog}
          onClose={() => setOpenAddDialog(false)}
          TransitionComponent={Slide}
          TransitionProps={{ direction: 'up' }}
          fullWidth
          maxWidth="sm"
          keepMounted={false}
        >
          <FormContainer>
            {/* Header - Con capacidad de deslizar hacia abajo para cerrar */}
            <FormHeader
              ref={formDialogRef}
              onWheel={handleFormDialogSwipe}
              component={motion.div}
              whileTap={{ cursor: 'grabbing' }}
              sx={{ cursor: 'grab' }}
            >
              <CloseButton onClick={() => setOpenAddDialog(false)}>
                <CloseIcon />
              </CloseButton>
              <Typography variant="h6" sx={{ ml: 2, fontWeight: 600 }}>
                {editMode ? 'Editar Entrada' : 'Nueva Entrada'}
              </Typography>
            </FormHeader>

            {/* Selector de pasos */}
            <StepButtonContainer>
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                {formSections.map((_, index) => (
                  <StepIndicator
                    key={index}
                    active={currentFormSection === index}
                    onClick={() => setCurrentFormSection(index)}
                    sx={{ cursor: 'pointer' }}
                  />
                ))}
              </Box>
            </StepButtonContainer>

            {/* Contenido del formulario con animación */}
            <FormContent>
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentFormSection}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  {renderFormContent()}
                </motion.div>
              </AnimatePresence>
            </FormContent>

            {/* Footer con botones de navegación */}
            <FormFooter>
              {currentFormSection > 0 && (
                <StepButton
                  variant="prev"
                  onClick={handlePrevFormSection}
                  startIcon={<KeyboardArrowLeftIcon />}
                >
                  Anterior
                </StepButton>
              )}
              {currentFormSection < formSections.length - 1 ? (
                <StepButton
                  variant="next"
                  onClick={handleNextFormSection}
                  endIcon={<KeyboardArrowRightIcon />}
                  sx={{ ml: 'auto' }}
                >
                  Siguiente
                </StepButton>
              ) : (
                <StyledSaveButton
                  onClick={handleSubmitForm}
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                  sx={{ ml: 'auto' }}
                >
                  {editMode ? 'Actualizar' : 'Guardar'}
                </StyledSaveButton>
              )}
            </FormFooter>
          </FormContainer>
        </FullWidthDialog>

        {/* Vista detallada de entrada */}
        <Drawer
          anchor="right"
          open={openDetailView}
          onClose={() => setOpenDetailView(false)}
          PaperProps={{
            sx: {
              width: '100%',
              backgroundColor: 'transparent',
              boxShadow: '0 0 0 rgba(0, 0, 0, 0)', // Sombra inicial nula
              zIndex: 1300,
              transition: 'box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1)', // Curva elástica para sombra
              '& ~ .MuiBackdrop-root': {
                backgroundColor: 'rgba(0, 0, 0, 0)', // Sin color inicial
                backdropFilter: 'blur(0px) brightness(100%)', // Sin blur ni cambio de brillo
                transition: 'backdrop-filter 0.7s cubic-bezier(0.25, 0.8, 0.25, 1)', // Transición elástica
                '&.MuiBackdrop-root.MuiModal-backdrop': {
                  backgroundColor: 'rgba(0, 0, 0, 0.05)', // Gris ultraligero
                  backdropFilter: 'blur(4px) brightness(97%)', // Blur sutil y leve ajuste de brillo
                },
              },
              '& .mobile-detail-view': {
                boxShadow: '0 0 0 rgba(0, 0, 0, 0)', // Sombra inicial del detalle
                transition: 'box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1)', // Sincronizada con el backdrop
                '&:hover': {
                  boxShadow: '0 5px 25px rgba(0, 0, 0, 0.12)', // Sombra al abrir
                },
              },
            }
          }}
          SlideProps={{
            timeout: 400
          }}
          transitionDuration={400}
        >
          {selectedEntrada && (
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              background: 'rgba(0, 0, 0, 0.4)', // Fondo aplicado aquí en vez de en el Paper
              backdropFilter: 'blur(2px)', // Efecto blur aquí
              transition: 'background 0.4s ease-in-out, backdrop-filter 0.4s ease-in-out', // Transición suave para el fondo
            }}>
              <MobileDetailView
                className="mobile-detail-view"
                component={motion.div}
                initial={{ opacity: 0, y: 20, scale: 0.95 }} // Cambio en los valores iniciales
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: 20, scale: 0.95 }}
                transition={{
                  duration: 0.35, // Ligeramente más largo
                  type: 'spring',
                  stiffness: 300, // Reducido para más suavidad
                  damping: 25, // Ajustado
                  bounce: 0.1 // Muy ligero bounce
                }}
              >
                <Box sx={{ position: 'relative' }}>
                  <AppBar
                    position="sticky"
                    color="transparent"
                    elevation={0}
                    sx={{
                      bgcolor: 'white',
                      borderBottom: '1px solid rgba(0,0,0,0.05)',
                      borderTopLeftRadius: 28,
                      borderTopRightRadius: 28,
                    }}
                  >
                    <Toolbar>
                      <IconButton edge="start" onClick={() => setOpenDetailView(false)}>
                        <ArrowBackIcon />
                      </IconButton>
                      <Typography variant="h6" fontWeight="medium" sx={{ ml: 1 }}>
                        Detalles de Entrada
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      {/* Botones de edición y eliminación solo para administradores */}
                      {!isCliente && (
                        <>
                          <IconButton
                            color="primary"
                            onClick={() => handleOpenEditDialog(selectedEntrada)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => setDeleteConfirmOpen(true)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </Toolbar>
                  </AppBar>

                  <Box sx={{ p: 2, overflowY: 'auto', height: 'calc(85vh - 64px)', backgroundColor: '#f8f9fa' }}>
                    {/* Cabecera con monto y fecha */}
                    <DetailSection>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Box>
                          <Typography variant="body2" color="text.secondary">Fecha</Typography>
                          <Typography variant="h6" fontWeight="bold">{selectedEntrada.fecha_formateada}</Typography>
                        </Box>
                        <Box sx={{ textAlign: 'right' }}>
                          <Typography variant="body2" color="text.secondary">Monto</Typography>
                          <Typography variant="h6" fontWeight="bold" color={selectedEntrada.moneda_entrada === 'USD' ? '#f57c00' : 'primary'}>
                            {selectedEntrada.monto_formateado}
                          </Typography>
                        </Box>
                      </Box>

                      <Divider sx={{ my: 1 }} />

                      <DetailRow>
                        <DetailLabel>Caja Origen</DetailLabel>
                        <DetailValue>{selectedEntrada.caja_origen_entrada}</DetailValue>
                      </DetailRow>

                      <DetailRow>
                        <DetailLabel>Caja Fin</DetailLabel>
                        <DetailValue>{selectedEntrada.caja_fin_entrada}</DetailValue>
                      </DetailRow>

                      <DetailRow>
                        <DetailLabel>Tipo de Cambio</DetailLabel>
                        <DetailValue>${selectedEntrada.tipo_de_cambio_entrada}</DetailValue>
                      </DetailRow>

                      {/* Montos equivalentes - VERSIÓN MÁS MINIMALISTA */}
                      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                        {/* Equivalente en ARS */}
                        <Box
                          sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255, 153, 0, 0.08)',
                            p: 1.5,
                            borderRadius: 3,
                          }}
                        >
                          <Typography variant="caption" color="text.secondary" sx={{ mr: 1 }}>
                            Equiv. ARS
                          </Typography>
                          <Typography variant="body2" fontWeight="medium" color="#f57c00" sx={{ ml: 'auto' }}>
                            {selectedEntrada.moneda_entrada === 'USD'
                              ? new Intl.NumberFormat('es-AR', {
                                style: 'currency',
                                currency: 'ARS',
                                minimumFractionDigits: 2
                              }).format(selectedEntrada.monto_entrada * selectedEntrada.tipo_de_cambio_entrada)
                              : selectedEntrada.monto_formateado
                            }
                          </Typography>
                        </Box>

                        {/* Equivalente en USD */}
                        <Box
                          sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'rgba(25, 118, 210, 0.08)',
                            p: 1.5,
                            borderRadius: 3,
                          }}
                        >
                          <Typography variant="caption" color="text.secondary" sx={{ mr: 1 }}>
                            Equiv. USD
                          </Typography>
                          <Typography variant="body2" fontWeight="medium" color="#1976d2" sx={{ ml: 'auto' }}>
                            {selectedEntrada.moneda_entrada === 'ARS'
                              ? new Intl.NumberFormat('es-AR', {
                                style: 'currency',
                                currency: 'USD',
                                minimumFractionDigits: 2
                              }).format(selectedEntrada.monto_entrada / selectedEntrada.tipo_de_cambio_entrada)
                              : selectedEntrada.monto_formateado
                            }
                          </Typography>
                        </Box>
                      </Box>
                    </DetailSection>

                    {/* Información adicional */}
                    <DetailSection>
                      <DetailSectionTitle>
                        <AssignmentIcon /> Información adicional
                      </DetailSectionTitle>

                      {selectedEntrada.codigo_de_proyecto_entrada && (
                        <DetailRow>
                          <DetailLabel>Proyecto</DetailLabel>
                          <DetailValue>{selectedEntrada.codigo_de_proyecto_entrada}</DetailValue>
                        </DetailRow>
                      )}

                      <DetailRow>
                        <DetailLabel>Concepto</DetailLabel>
                        <DetailValue>{selectedEntrada.concepto_entrada}</DetailValue>
                      </DetailRow>

                      {selectedEntrada.descripción_entrada && (
                        <Box sx={{ mt: 2 }}>
                          <DetailLabel>Descripción</DetailLabel>
                          <Typography variant="body2" sx={{ mt: 0.5, whiteSpace: 'pre-wrap', p: 1.5, bgcolor: 'rgba(0,0,0,0.02)', borderRadius: 2 }}>
                            {selectedEntrada.descripción_entrada}
                          </Typography>
                        </Box>
                      )}
                    </DetailSection>

                    {/* Documentos */}
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
                      {/* Comprobante */}
                      {selectedEntrada.tiene_comprobante_entrada && (
                        <DetailSection sx={{ flex: 1, minWidth: '45%' }}>
                          <DetailSectionTitle>
                            <ReceiptIcon /> Comprobante
                          </DetailSectionTitle>

                          <Box
                            sx={{
                              width: '100%',
                              borderRadius: 3,
                              overflow: 'hidden',
                              border: '1px solid rgba(0,0,0,0.1)',
                              mb: 1
                            }}
                            onClick={() => handleOpenImagePreview(selectedEntrada.imagen_comprobante_entrada)}
                          >
                            <img
                              src={selectedEntrada.imagen_comprobante_entrada}
                              alt="Comprobante"
                              style={{
                                width: '100%',
                                maxHeight: 200,
                                objectFit: 'contain',
                                backgroundColor: '#f9f9f9',
                                padding: 4
                              }}
                            />
                          </Box>

                          {selectedEntrada.codigo_de_comprobante_entrada && (
                            <Box sx={{
                              p: 1.5,
                              backgroundColor: 'rgba(46, 125, 50, 0.05)',
                              borderRadius: 2,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}>
                              <Typography variant="caption" fontFamily="monospace" noWrap sx={{ maxWidth: '80%' }}>
                                {selectedEntrada.codigo_de_comprobante_entrada}
                              </Typography>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigator.clipboard.writeText(selectedEntrada.codigo_de_comprobante_entrada);
                                  setSnackbar({
                                    open: true,
                                    message: 'Código copiado al portapapeles',
                                    severity: 'success'
                                  });
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          )}
                        </DetailSection>
                      )}

                      {/* Factura */}
                      {selectedEntrada.tiene_factura_entrada && (
                        <DetailSection sx={{ flex: 1, minWidth: '45%' }}>
                          <DetailSectionTitle>
                            <DescriptionIcon /> Factura
                          </DetailSectionTitle>

                          <Box
                            sx={{
                              width: '100%',
                              borderRadius: 3,
                              overflow: 'hidden',
                              border: '1px solid rgba(0,0,0,0.1)',
                              mb: 1
                            }}
                            onClick={() => handleOpenImagePreview(selectedEntrada.imagen_factura_entrada)}
                          >
                            <img
                              src={selectedEntrada.imagen_factura_entrada}
                              alt="Factura"
                              style={{
                                width: '100%',
                                maxHeight: 200,
                                objectFit: 'contain',
                                backgroundColor: '#f9f9f9',
                                padding: 4
                              }}
                            />
                          </Box>

                          {selectedEntrada.codigo_de_factura_entrada && (
                            <Box sx={{
                              p: 1.5,
                              backgroundColor: 'rgba(46, 125, 50, 0.05)',
                              borderRadius: 2,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              mb: 1
                            }}>
                              <Typography variant="caption" fontFamily="monospace" noWrap sx={{ maxWidth: '80%' }}>
                                {selectedEntrada.codigo_de_factura_entrada}
                              </Typography>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigator.clipboard.writeText(selectedEntrada.codigo_de_factura_entrada);
                                  setSnackbar({
                                    open: true,
                                    message: 'Código copiado al portapapeles',
                                    severity: 'success'
                                  });
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          )}

                          {selectedEntrada.factura_a_nombre_de_entrada && (
                            <Typography variant="body2" color="text.secondary">
                              A nombre de: <b>{selectedEntrada.factura_a_nombre_de_entrada}</b>
                            </Typography>
                          )}
                        </DetailSection>
                      )}
                    </Box>
                  </Box>
                </Box>
              </MobileDetailView>
            </Box>
          )}
        </Drawer>

        {/* Diálogo de confirmación de eliminación */}
        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
          PaperProps={{
            sx: {
              borderRadius: '24px',
              padding: 2,
              maxWidth: '85%'
            }
          }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
          >
            <Box sx={{ p: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box
                  sx={{
                    width: 48,
                    height: 48,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'rgba(211, 47, 47, 0.1)',
                    color: 'error.main',
                    mr: 2
                  }}
                >
                  <DeleteIcon fontSize="medium" />
                </Box>
                <Typography variant="h6">¿Eliminar entrada?</Typography>
              </Box>

              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Esta acción eliminará permanentemente la entrada de {selectedEntrada?.monto_formateado} del {selectedEntrada?.fecha_formateada}.
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button
                  variant="outlined"
                  onClick={() => setDeleteConfirmOpen(false)}
                  sx={{ borderRadius: 8, textTransform: 'none' }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setDeleteConfirmOpen(false);
                    // Animación de salida antes de eliminar
                    animateItemDelete();
                  }}
                  sx={{ borderRadius: 8, textTransform: 'none' }}
                >
                  Eliminar
                </Button>
              </Box>
            </Box>
          </motion.div>
        </Dialog>

        {/* Dialog para vista previa de imágenes */}
        <Dialog
          open={imagePreviewOpen}
          onClose={() => setImagePreviewOpen(false)}
          maxWidth="lg"
          PaperProps={{
            sx: {
              borderRadius: 3,
              bgcolor: 'rgba(0,0,0,0.9)',
              p: 0
            }
          }}
        >
          <IconButton
            onClick={() => setImagePreviewOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              bgcolor: 'rgba(0,0,0,0.3)',
              zIndex: 1
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
              width: '100vw',
              maxWidth: '100%'
            }}
          >
            {currentImage && (
              <motion.img
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
                src={currentImage}
                alt="Vista ampliada"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}
              />
            )}
          </Box>
        </Dialog>

        {/* Drawer para filtros */}
        <SwipeableDrawer
          anchor="bottom"
          open={openFiltersDrawer}
          onClose={() => setOpenFiltersDrawer(false)}
          onOpen={() => setOpenFiltersDrawer(true)}
          disableSwipeToOpen={false}
          swipeAreaWidth={30}
          ModalProps={{
            keepMounted: true,
            sx: {
              '& .MuiBackdrop-root': {
                backgroundColor: 'rgba(0, 0, 0, 0)', // Transparente al inicio
                backdropFilter: 'blur(0px) brightness(100%)', // Sin blur ni cambio de brillo
                transition: 'backdrop-filter 0.6s cubic-bezier(0.4, 0, 0.2, 1)', // Curva suave
                '&.MuiBackdrop-root.MuiModal-backdrop': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)', // Gris muy sutil
                  backdropFilter: 'blur(6px) brightness(96%)', // Blur intermedio y leve ajuste
                },
              },
            },
          }}
          PaperProps={{
            sx: {
              borderRadius: '28px 28px 0 0',
              maxHeight: '75vh',
              boxShadow: '0 0 0 rgba(0, 0, 0, 0)', // Sombra inicial nula
              transition: 'box-shadow 0.6s cubic-bezier(0.4, 0, 0.2, 1), transform 0.4s ease-out', // Sombra y desplazamiento
              '&:hover': {
                boxShadow: '0 -8px 20px rgba(0, 0, 0, 0.1)', // Sombra dinámica al abrir
              },
            }
          }}
        >
          <SwipableFilterBox>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1.5 }}>
              <Typography variant="h6" fontWeight="medium">Filtrar Entradas</Typography>
              <IconButton onClick={() => setOpenFiltersDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Box>

            <FormSection>
              <StyledFormControl fullWidth>
                <InputLabel>Caja Origen</InputLabel>
                <Select
                  value={filterCajaOrigen}
                  onChange={(e) => setFilterCajaOrigen(e.target.value)}
                  label="Caja Origen"
                >
                  <MenuItem value="">Todas</MenuItem>
                  {cajasOrigen.map((caja) => (
                    <MenuItem key={caja} value={caja}>{caja}</MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Caja Fin</InputLabel>
                <Select
                  value={filterCajaFin}
                  onChange={(e) => setFilterCajaFin(e.target.value)}
                  label="Caja Fin"
                >
                  <MenuItem value="">Todas</MenuItem>
                  {cajasFin.map((caja) => (
                    <MenuItem key={caja} value={caja}>{caja}</MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Concepto</InputLabel>
                <Select
                  value={filterConcepto}
                  onChange={(e) => setFilterConcepto(e.target.value)}
                  label="Concepto"
                >
                  <MenuItem value="">Todos</MenuItem>
                  {conceptos.map((concepto) => (
                    <MenuItem key={concepto} value={concepto}>{concepto}</MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Proyecto</InputLabel>
                <Select
                  value={filterProyecto}
                  onChange={(e) => setFilterProyecto(e.target.value)}
                  label="Proyecto"
                >
                  <MenuItem value="">Todos</MenuItem>
                  {proyectos.map((proyecto) => (
                    <MenuItem key={proyecto} value={proyecto}>{proyecto}</MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Moneda</InputLabel>
                <Select
                  value={filterMoneda}
                  onChange={(e) => setFilterMoneda(e.target.value)}
                  label="Moneda"
                >
                  <MenuItem value="">Todas</MenuItem>
                  <MenuItem value="ARS">ARS</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Comprobante</InputLabel>
                <Select
                  value={filterTieneComprobante === null ? '' : filterTieneComprobante.toString()}
                  onChange={(e) => {
                    const value = e.target.value === '' ? null : e.target.value === 'true';
                    setFilterTieneComprobante(value);
                  }}
                  label="Comprobante"
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="true">Con comprobante</MenuItem>
                  <MenuItem value="false">Sin comprobante</MenuItem>
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Factura</InputLabel>
                <Select
                  value={filterTieneFactura === null ? '' : filterTieneFactura.toString()}
                  onChange={(e) => {
                    const value = e.target.value === '' ? null : e.target.value === 'true';
                    setFilterTieneFactura(value);
                  }}
                  label="Factura"
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="true">Con factura</MenuItem>
                  <MenuItem value="false">Sin factura</MenuItem>
                </Select>
              </StyledFormControl>

              <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                <Grid container spacing={1.5} sx={{ mt: 0.5 }}>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Desde"
                      value={filterFechaDesde}
                      onChange={(newValue) => setFilterFechaDesde(newValue)}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          fullWidth
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <CalendarTodayIcon color="primary" fontSize="small" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Hasta"
                      value={filterFechaHasta}
                      onChange={(newValue) => setFilterFechaHasta(newValue)}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          fullWidth
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <CalendarTodayIcon color="primary" fontSize="small" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </FormSection>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button
                variant="outlined"
                color="inherit"
                onClick={resetFilters}
                sx={{
                  width: '48%',
                  borderRadius: 20,
                  borderColor: 'rgba(0,0,0,0.2)',
                  color: 'text.secondary'
                }}
              >
                Limpiar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={applyFilterSelection}
                sx={{ width: '48%', borderRadius: 20 }}
              >
                Aplicar Filtros
              </Button>
            </Box>
          </SwipableFilterBox>
        </SwipeableDrawer>

        {/* Diálogo para búsqueda flotante */}
        <Dialog
          open={searchDialogOpen}
          onClose={() => setSearchDialogOpen(false)}
          PaperProps={{
            sx: {
              borderRadius: 3,
              width: '90%',
              maxWidth: '90%',
              p: 2
            }
          }}
        >
          <Box sx={{ p: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              Buscar entrada
            </Typography>
            <TextField
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              variant="outlined"
              placeholder="Buscar..."
              sx={{ mb: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={() => {
                  setSearchDialogOpen(false);
                }}
                color="primary"
                sx={{ borderRadius: 20 }}
              >
                Buscar
              </Button>
            </Box>
          </Box>
        </Dialog>

        {/* Snackbar para notificaciones */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={{ mb: 7 }} // Para que aparezca por encima de la navegación
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{
              width: '100%',
              borderRadius: 20,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </MobileContainer>
    </>
  );
};

export default EntradasMobile;