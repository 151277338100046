import React from 'react';
import {
  Box,
  IconButton,
  Typography,
  AppBar,
  Toolbar
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

// Header flotante con fondo claro y siempre redondeado
const FloatingHeader = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  backdropFilter: 'blur(10px)',
  boxShadow: '0 4px 16px rgba(0, 0, 0, 0.08)',
  borderRadius: '24px', // Siempre redondeado
  transition: 'all 0.3s ease',
  top: 8, // Siempre con un pequeño margen superior
  margin: '0px 16px 0 16px', // Márgenes laterales consistentes
  width: 'calc(100% - 32px)', // Ancho constante
  zIndex: 1200,
  [theme.breakpoints.up('sm')]: {
    display: 'none', // Ocultar en escritorio
  },
}));

// Mapeo de rutas a títulos
const routeTitles = {
  '/dashboard': 'Cajas',
  '/dashboard-obras': 'Dashboard',
  '/dashboard-trabajadores': 'Trabajadores',
  '/presupuestos': 'Presupuestos',
  '/entradas': 'Entradas',
  '/salidas': 'Salidas',
  '/admin/cobro-trabajadores': 'Cobro socios',
  '/cambios-de-moneda': 'Cambios',
  '/admin/arqueos': 'Arqueos',
  '/buscador-unificado': 'Buscador Unificado',
  '/admin/proyectos': 'Proyectos',
  '/admin/conceptos': 'Conceptos',
  '/admin/etapas': 'Etapas',
  '/admin/proveedores': 'Proveedores',
  '/admin/trabajadores': 'Trabajadores',
  '/admin/caja-empresa': 'Caja Empresa',
  '/admin/indices-cac': 'Índices CAC',
  '/client/dashboard': 'Dashboard Cliente'
};

const MobileHeader = ({ toggleSidebar, empresaNombre, isClient }) => {
  const location = useLocation();

  // Obtener el título basado en la ruta actual y el rol del usuario
  const getPageTitle = () => {
    // Si es cliente y está en la ruta de dashboard
    if (isClient && location.pathname === '/dashboard') {
      return 'Dashboard';
    }
    return routeTitles[location.pathname] || 'Nokk';
  };

  return (
    <FloatingHeader elevation={0}>
      <Toolbar sx={{ minHeight: 56 }}>
        <IconButton
          edge="start"
          color="primary"
          aria-label="menu"
          onClick={toggleSidebar}
          sx={{
            mr: 1.5,
            color: '#2e7d32',
            padding: '8px',
          }}
          component={motion.button}
          whileTap={{ scale: 0.95 }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{
                fontWeight: 600,
                color: '#2e7d32',
                display: 'flex',
                alignItems: 'center',
                fontSize: '1.1rem'
              }}
            >
              {getPageTitle()}
            </Typography>
          </motion.div>
        </Box>
      </Toolbar>
    </FloatingHeader>
  );
};

export default MobileHeader;