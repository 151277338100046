import React, { useEffect, useState, useRef } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Typography,
  IconButton,
  Container,
  Paper,
  Tooltip,
  Chip,
  InputBase,
  InputAdornment,
  Alert,
  Snackbar,
  Divider,
  CircularProgress,
  FormControlLabel,
  Switch,
  Autocomplete,
  Card,
  CardContent,
  Fab,
  Badge,
  Drawer,
  SwipeableDrawer,
  AppBar,
  Toolbar,
  Slide,
  LinearProgress,
  Pagination,
  useTheme,
  Avatar,
  Stack,
  Stepper,
  Step,
  StepLabel,
  MobileStepper,
  useScrollTrigger,
  Zoom
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CalculateIcon from '@mui/icons-material/Calculate';
import SummarizeIcon from '@mui/icons-material/Summarize';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import { uploadImage, formatImageUrl } from '../utils/imageUtils';
import { useAuth } from '../contexts/AuthContext'; // Importar useAuth para la autenticación

// Estilos personalizados con enfoque en mobile - MEJORADOS
const MobileContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1, 1.5, 10, 1.5), // Padding extra abajo para el botón flotante
  maxWidth: '100%',
  backgroundColor: '#f8f9fa'
}));

const SalidaCard = styled(Card)(({ theme }) => ({
  borderRadius: 20,
  marginBottom: theme.spacing(2),
  overflow: 'hidden',
  boxShadow: '0 3px 10px rgba(0, 0, 0, 0.08)',
  transition: 'all 0.3s ease',
  background: 'linear-gradient(to bottom, #ffffff, #fafafa)',
  '&:hover': {
    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
    transform: 'translateY(-2px)'
  },
  '&:active': {
    transform: 'scale(0.98)',
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)',
  },
}));

const CardRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0.8, 0),
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const SearchBarContainer = styled(Box)(({ theme, disappear }) => ({
  display: 'flex',
  alignItems: 'stretch',
  marginBottom: theme.spacing(2),
  gap: theme.spacing(1),
  transition: 'opacity 0.3s ease, transform 0.3s ease',
  opacity: disappear ? 0 : 1,
  transform: disappear ? 'scale(0.95)' : 'scale(1)',
  pointerEvents: disappear ? 'none' : 'auto',
  backgroundColor: '#f8f9fa', // Añadido para asegurar que el fondo sea uniforme
}));

const SearchBar = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: 24,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  flexGrow: 1
}));

const FilterButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  borderRadius: 20,
  padding: theme.spacing(1, 2),
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  color: theme.palette.primary.main,
  border: '1px solid rgba(0, 0, 0, 0.12)'
}));

const FixedSearchContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 16,
  right: 16,
  display: 'flex',
  gap: theme.spacing(1),
  zIndex: 1201,
  transition: 'opacity 0.3s ease, transform 0.3s ease',
}));

const FloatingSearchButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 20,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
}));

const FloatingFilterButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 20,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
}));

const AddFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: 110, // Aumentado para mejor visibilidad por encima de la barra de navegación
  right: 16,
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  boxShadow: '0 6px 15px rgba(46, 125, 50, 0.4)',
  zIndex: 1000,
}));

// Campos de formulario mejorados - Sin fondo y con mejor espaciado
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(2), // Reducido de 4 a 2 según la solicitud
  '& .MuiOutlinedInput-root': {
    borderRadius: 20,
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
    },
  },
  '& .MuiInputLabel-outlined': {
    backgroundColor: 'transparent', // Removido el fondo sutil para la etiqueta
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(2), // Reducido de 4 a 2 según la solicitud
  '& .MuiOutlinedInput-root': {
    borderRadius: 20,
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
    },
  },
  '& .MuiInputLabel-outlined': {
    backgroundColor: 'transparent', // Removido el fondo sutil para la etiqueta
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

// Nuevo diálogo rediseñado para formularios
const FullWidthDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  '& .MuiDialog-paper': {
    borderRadius: '30px 30px 0 0',
    margin: 0,
    width: '100%',
    maxWidth: '100%',
    maxHeight: '85vh',
    position: 'fixed',
    bottom: 0,
    overflowY: 'hidden',
    boxShadow: '0 -8px 20px rgba(0, 0, 0, 0.15)',
    backgroundImage: 'linear-gradient(to bottom, rgba(250, 250, 250, 0.95), rgba(255, 255, 255, 0.95))',
    backdropFilter: 'blur(10px)',
  }
}));

// Contenedor principal del formulario
const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '81vh',
  width: '100%',
  position: 'relative',
}));

// Header mejorado del formulario con indicador de arrastre
const FormHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 3, 1, 3),
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
  backgroundColor: 'transparent',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '50px',
    height: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    top: '8px',
    left: '50%',
    transform: 'translateX(-50%)',
  }
}));

// Icono de cierre con animación
const CloseButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  color: theme.palette.text.secondary,
  borderRadius: 16,
  padding: 8,
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    transform: 'scale(1.05)',
  },
}));

// Pasos con animación - Aumentado espaciado
const StepButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 3, 2, 3),
  marginTop: theme.spacing(2.5), // Más espacio después del título
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
}));

// Contenido del formulario
const FormContent = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  flex: 1,
  padding: theme.spacing(3, 3, 5, 3),
  backgroundColor: '#f8f9fa', // Fondo igual al contenedor principal
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
  },
}));

// Footer del formulario
const FormFooter = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderTop: '1px solid rgba(0, 0, 0, 0.06)',
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.05)',
}));

// Sección del formulario
const FormSection = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  borderRadius: 24,
  marginBottom: theme.spacing(3),
}));

const FormSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
  fontSize: '1.1rem',
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    fontSize: '1.4rem',
  },
}));

// Área de carga de archivos
const FileUploadBox = styled(Box)(({ theme }) => ({
  border: '2px dashed rgba(46, 125, 50, 0.3)',
  borderRadius: 24,
  padding: theme.spacing(4),
  textAlign: 'center',
  backgroundColor: 'rgba(46, 125, 50, 0.03)',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  marginBottom: theme.spacing(3),
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: 'rgba(46, 125, 50, 0.05)',
    transform: 'translateY(-2px)',
  },
}));

// Botón de acción
const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 28,
  textTransform: 'none',
  boxShadow: '0 3px 8px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(1.2, 3),
  fontWeight: 'bold',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 5px 12px rgba(0, 0, 0, 0.15)',
  }
}));

// Botones de navegación para los pasos
const StepButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: 20,
  textTransform: 'none',
  padding: theme.spacing(1, 2),
  minWidth: variant === 'next' ? 120 : 100,
  boxShadow: variant === 'next' ? '0 3px 8px rgba(46, 125, 50, 0.2)' : 'none',
  backgroundColor: variant === 'next' ? theme.palette.primary.main : 'transparent',
  color: variant === 'next' ? '#fff' : theme.palette.text.secondary,
  border: variant === 'next' ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
  fontWeight: variant === 'next' ? 'bold' : 'normal',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: variant === 'next' ? theme.palette.primary.dark : 'rgba(0, 0, 0, 0.05)',
    transform: 'translateY(-2px)',
    boxShadow: variant === 'next' ? '0 4px 10px rgba(46, 125, 50, 0.3)' : '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
}));

// Indicador de paso actual
const StepIndicator = styled(Box)(({ theme, active }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: active ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.12)',
  margin: '0 4px',
  transition: 'all 0.3s ease',
  transform: active ? 'scale(1.2)' : 'scale(1)',
}));

// Box para filtros - modificado para que no sea necesario hacer scroll
const SwipableFilterBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '28px 28px 0 0',
  padding: theme.spacing(3),
  paddingBottom: theme.spacing(5),
  marginTop: 'auto',
  maxHeight: '70vh', // Reducido de 80vh a 70vh para evitar el scroll
  overflow: 'auto',
}));

// Toggle switch para opciones
const SwitchFormControl = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  marginRight: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(2),
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.primary.main,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main,
  },
}));

// Botón de guardar
const StyledSaveButton = styled(Button)(({ theme }) => ({
  borderRadius: 28,
  boxShadow: '0 4px 12px rgba(46, 125, 50, 0.3)',
  padding: theme.spacing(1.2, 4),
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '1rem',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: '0 6px 16px rgba(46, 125, 50, 0.4)',
    transform: 'translateY(-2px)',
  }
}));

// Para la vista de detalle mejorada
const MobileDetailView = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  backgroundColor: '#f8f9fa',
  height: '85vh',
  width: '100%',
  overflow: 'auto',
  borderRadius: '28px 28px 28px 28px',
  margin: '3vh 2vw',
  boxShadow: '0 5px 20px rgba(0, 0, 0, 0.15)',
}));

const DetailSection = styled(Paper)(({ theme }) => ({
  borderRadius: 24,
  marginBottom: theme.spacing(3),
  overflow: 'hidden',
  padding: theme.spacing(3),
  backgroundColor: '#fff',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
}));

const DetailSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.1rem',
  color: theme.palette.primary.main,
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    fontSize: '1.3rem',
  },
}));

const DetailRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1.2, 0),
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const DetailLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.9rem',
}));

const DetailValue = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '0.95rem',
  textAlign: 'right',
}));

const ImagePreviewBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 200,
  borderRadius: 20,
  overflow: 'hidden',
  marginBottom: theme.spacing(3),
  backgroundColor: '#f5f5f5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid rgba(0, 0, 0, 0.1)',
}));

// Para los chips de documento
const DocChip = styled(Chip)(({ theme }) => ({
  borderRadius: 16,
  height: 36,
  padding: theme.spacing(0.5, 0.5),
  '& .MuiChip-label': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }
}));

// Panel de resumen financiero
const SummaryPanel = styled(Paper)(({ theme }) => ({
  borderRadius: 24,
  padding: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgba(46, 125, 50, 0.04)',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.08)',
}));

const SummaryRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1.2),
  '&:last-child': {
    marginBottom: 0,
  }
}));

// Controladores de cambio para tipo de cambio
const TipoCambioControl = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1),
  backgroundColor: 'transparent',
  borderRadius: 16,
}));

const TcStepButton = styled(IconButton)(({ theme, color = 'primary' }) => ({
  backgroundColor: color === 'primary' ? 'rgba(46, 125, 50, 0.1)' : 'rgba(211, 47, 47, 0.1)',
  color: color === 'primary' ? theme.palette.primary.main : theme.palette.error.main,
  borderRadius: 12,
  padding: theme.spacing(0.6),
  '&:hover': {
    backgroundColor: color === 'primary' ? 'rgba(46, 125, 50, 0.2)' : 'rgba(211, 47, 47, 0.2)',
    transform: 'scale(1.05)',
  }
}));

// Animaciones para las transiciones
const pageVariants = {
  initial: {
    opacity: 0,
    y: 20
  },
  in: {
    opacity: 1,
    y: 0
  },
  out: {
    opacity: 0,
    y: -20
  }
};

const pageTransition = {
  type: 'spring',
  stiffness: 300,
  damping: 30
};

// Componente principal
const SalidasMobile = () => {
  // Obtener el contexto de autenticación
  const { user, userRole, isClient } = useAuth();

  // Verificar si el usuario es cliente
  const isCliente = isClient || userRole === 'client' || localStorage.getItem('userRole') === 'client';

  const theme = useTheme();
  const [salidas, setSalidas] = useState([]);
  const [filteredSalidas, setFilteredSalidas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [initialLoading, setInitialLoading] = useState(true);
  const [userProyecto, setUserProyecto] = useState('');
  const [userCajasPermitidas, setUserCajasPermitidas] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDetailView, setOpenDetailView] = useState(false);
  const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);
  const [selectedSalida, setSelectedSalida] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const itemsPerPage = 25;

  // Estados para la animación de eliminación y diálogo de confirmación
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteAnimation, setDeleteAnimation] = useState(false);

  // Estado para gestionar cuándo mostrar los botones flotantes
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  // Estados para los diálogos flotantes
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);

  // MODIFICADO: Estado para manejar si el resumen está expandido o colapsado
  const [summaryExpanded, setSummaryExpanded] = useState(false);

  const [summaryData, setSummaryData] = useState({
    totalARS: 0,
    totalUSD: 0,
    totalARSEquivalente: 0,
    totalUSDEquivalente: 0
  });

  // FORM STATES
  const [cajasOrigen, setCajasOrigen] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [proveedoresInfo, setProveedoresInfo] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [conceptos, setConceptos] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [subetapas, setSubetapas] = useState([]);
  const [tiposIndexacion, setTiposIndexacion] = useState(['Dolar Oficial Venta', 'Custom']);
  const [nombresFacturas, setNombresFacturas] = useState([]);

  // SALIDA FORM DATA
  const [fechaSalida, setFechaSalida] = useState(new Date());
  const [cajaOrigen, setCajaOrigen] = useState('');
  const [proveedor, setProveedor] = useState('');
  const [nuevoProveedor, setNuevoProveedor] = useState('');
  const [codigoProyecto, setCodigoProyecto] = useState('');
  const [concepto, setConcepto] = useState('');
  const [etapa, setEtapa] = useState('');
  const [subetapa, setSubetapa] = useState('');
  const [nuevaSubetapa, setNuevaSubetapa] = useState('');
  const [tipoCambio, setTipoCambio] = useState('');
  const [moneda, setMoneda] = useState('ARS');
  const [monto, setMonto] = useState('');
  const [descripcion, setDescripcion] = useState('');

  // COMPROBANTE STATES
  const [tieneComprobante, setTieneComprobante] = useState(false);
  const [comprobanteImagen, setComprobanteImagen] = useState(null);
  const [comprobantePreview, setComprobantePreview] = useState('');
  const [codigoComprobante, setCodigoComprobante] = useState('');
  const comprobanteInputRef = useRef(null);

  // FACTURA STATES
  const [tieneFactura, setTieneFactura] = useState(false);
  const [facturaImagen, setFacturaImagen] = useState(null);
  const [facturaPreview, setFacturaPreview] = useState('');
  const [codigoFactura, setCodigoFactura] = useState('');
  const [facturaANombreDe, setFacturaANombreDe] = useState('');
  const [nuevoNombreFactura, setNuevoNombreFactura] = useState('');
  const [addingNewNombre, setAddingNewNombre] = useState(false);
  const facturaInputRef = useRef(null);

  // INDEXACIÓN STATES
  const [tieneIndexacion, setTieneIndexacion] = useState(false);
  const [tipoIndexacion, setTipoIndexacion] = useState('');
  const [nuevoTipoIndexacion, setNuevoTipoIndexacion] = useState('');
  const [tcIndexado, setTcIndexado] = useState('');
  const [montoIndexado, setMontoIndexado] = useState('');
  const [dolarOficialVenta, setDolarOficialVenta] = useState('');
  const [dolarBlueCompra, setDolarBlueCompra] = useState('');

  // FILTERS STATES
  const [filterCajaOrigen, setFilterCajaOrigen] = useState('');
  const [filterProveedor, setFilterProveedor] = useState('');
  const [filterProyecto, setFilterProyecto] = useState('');
  const [filterConcepto, setFilterConcepto] = useState(''); // Nuevo filtro para concepto
  const [filterFechaDesde, setFilterFechaDesde] = useState(null);
  const [filterFechaHasta, setFilterFechaHasta] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);

  // NOTIFICATION STATES
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // FORM SECTIONS
  const [currentFormSection, setCurrentFormSection] = useState(0);
  const formSections = [
    { title: 'Información Básica', icon: <InfoIcon /> },
    { title: 'Detalles del Proyecto', icon: <AssignmentIcon /> },
    { title: 'Información Financiera', icon: <AttachMoneyIcon /> },
    { title: 'Documentación', icon: <DescriptionIcon /> }];

  // Referencia para el swipe en el diálogo de form
  const formDialogRef = useRef(null);

  // EFFECTS
  // Efecto para configurar el proyecto y cajas permitidas para clientes
  useEffect(() => {
    const fetchUserProyectoYCajas = async () => {
      if (isCliente && user && user.email) {
        try {
          setInitialLoading(true);
          // Obtener el proyecto del cliente
          const { data, error } = await supabase
            .from('proyectos')
            .select('codigo_de_proyecto, apellido_del_cliente, caja_proyecto')
            .eq('email_del_cliente', user.email)
            .single();

          if (error) {
            console.error('Error al obtener proyecto del cliente:', error);
            setSnackbar({
              open: true,
              message: 'Error al obtener información de tu proyecto',
              severity: 'error'
            });
            return;
          }

          if (data) {
            console.log('Datos del proyecto del cliente:', data);
            setUserProyecto(data.codigo_de_proyecto);

            // Guardar las cajas permitidas para este cliente
            const cajasPermitidas = [];
            if (data.apellido_del_cliente) cajasPermitidas.push(data.apellido_del_cliente);
            if (data.caja_proyecto) cajasPermitidas.push(data.caja_proyecto);

            setUserCajasPermitidas(cajasPermitidas);
            console.log('Cajas permitidas para este cliente:', cajasPermitidas);

            // Ahora que tenemos esta información, cargar las salidas filtradas para el cliente
            await fetchSalidasForCliente(data.codigo_de_proyecto, cajasPermitidas);
          } else {
            setSnackbar({
              open: true,
              message: 'No se encontró un proyecto asociado a tu cuenta',
              severity: 'warning'
            });
          }
        } catch (error) {
          console.error('Error en fetchUserProyectoYCajas:', error);
        } finally {
          setInitialLoading(false);
        }
      } else {
        setInitialLoading(false);
      }
    };

    fetchUserProyectoYCajas();
  }, [isCliente, user]);

  // Efecto principal para cargar datos
  useEffect(() => {
    if (isCliente) {
      // Para clientes, los datos se cargan en el useEffect anterior
      // Las salidas ya se filtraron por proyecto y cajas permitidas
    } else {
      // Para administradores, cargar todo normalmente
      fetchDolarBlue();
      fetchDolarOficial();
      fetchSalidas();
      fetchCajasOrigen();
      fetchProveedores();
      fetchProyectos();
      fetchConceptos();
      fetchEtapas();
      fetchNombresFacturas();
      fetchTiposIndexacion();
      fetchProveedoresInfo();
    }
  }, [isCliente]);

  useEffect(() => {
    applyFilters();
    calculateSummary();
  }, [salidas, searchTerm, activeFilters, filterCajaOrigen, filterProveedor, filterProyecto, filterConcepto, filterFechaDesde, filterFechaHasta]);

  useEffect(() => {
    if (cajaOrigen) {
      buscarProyectoPorCajaOrigen(cajaOrigen);
    }
  }, [cajaOrigen]);

  useEffect(() => {
    calcularMontoIndexado();
  }, [tieneIndexacion, monto, moneda, tipoCambio, tcIndexado]);

  // FETCH FUNCTIONS
  // Función para obtener salidas filtradas para un cliente
  const fetchSalidasForCliente = async (proyectoCode, cajasPermitidas) => {
    if (!proyectoCode || !cajasPermitidas.length) {
      setSalidas([]);
      setFilteredSalidas([]);
      return;
    }

    try {
      setLoading(true);

      // Construir la consulta - salidas que tengan el código de proyecto del cliente
      // Y además cuya caja de origen esté en la lista de cajas permitidas
      let query = supabase
        .from('salidas')
        .select('*')
        .eq('codigo_de_proyecto_salida', proyectoCode)
        .in('caja_origen_salida', cajasPermitidas)
        .order('fecha_salida', { ascending: false });

      const { data, error } = await query;

      if (error) {
        console.error('Error al obtener salidas para el cliente:', error);
        setSnackbar({
          open: true,
          message: 'Error al cargar salidas',
          severity: 'error'
        });
        return;
      }

      // Formatear los datos
      const formattedData = data.map(item => ({
        ...item,
        fecha_formateada: item.fecha_salida ? format(new Date(item.fecha_salida), 'dd/MM/yyyy') : '',
        monto_formateado: formatCurrency(item.monto_salida, item.moneda_salida),
        imagen_comprobante_salida: item.imagen_comprobante_salida ? formatImageUrl(item.imagen_comprobante_salida) : null,
        imagen_factura_salida: item.imagen_factura_salida ? formatImageUrl(item.imagen_factura_salida) : null
      }));

      console.log(`Se encontraron ${formattedData.length} salidas para el cliente`);

      setSalidas(formattedData);
      setFilteredSalidas(formattedData);

      // Cargar las opciones de filtros disponibles para el cliente
      await fetchFilterOptionsForCliente(cajasPermitidas, formattedData);
    } catch (error) {
      console.error('Error en fetchSalidasForCliente:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFilterOptionsForCliente = async (cajasPermitidas, entriesData) => {
    // Cajas origen (limitadas a las permitidas para el cliente)
    setCajasOrigen(cajasPermitidas || []);

    // Extraer valores únicos para proveedores, conceptos, etc.
    if (entriesData && entriesData.length) {
      const proveedoresSet = new Set(entriesData.map(item => item.proveedor_salida).filter(Boolean));
      setProveedores(Array.from(proveedoresSet));

      const conceptosSet = new Set(entriesData.map(item => item.concepto_salidas).filter(Boolean));
      setConceptos(Array.from(conceptosSet));
    }
  };

  const fetchDolarBlue = async () => {
    try {
      const response = await fetch('https://dolarapi.com/v1/dolares/blue');
      if (!response.ok) throw new Error('Error al obtener el dólar blue');
      const data = await response.json();
      setTipoCambio(data.compra.toString());
      setDolarBlueCompra(data.compra.toString());
    } catch (error) {
      console.error('Error fetching dólar blue:', error.message);
      // Valor predeterminado en caso de error
      setTipoCambio('1050');
      setDolarBlueCompra('1050');
    }
  };

  const fetchDolarOficial = async () => {
    try {
      const response = await fetch('https://dolarapi.com/v1/dolares/oficial');
      if (!response.ok) throw new Error('Error al obtener el dólar oficial');
      const data = await response.json();
      setDolarOficialVenta(data.venta.toString());
      setTcIndexado(data.venta.toString());
    } catch (error) {
      console.error('Error fetching dólar oficial:', error.message);
      // Valor predeterminado en caso de error
      setDolarOficialVenta('950');
      setTcIndexado('950');
    }
  };

  const fetchSalidas = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('salidas')
        .select('*')
        .order('fecha_salida', { ascending: false });

      if (error) throw error;

      // Formatear datos
      const formattedData = data.map(item => ({
        ...item,
        fecha_formateada: item.fecha_salida ? format(new Date(item.fecha_salida), 'dd/MM/yyyy') : '',
        monto_formateado: formatCurrency(item.monto_salida, item.moneda_salida),
        imagen_comprobante_salida: item.imagen_comprobante_salida ? formatImageUrl(item.imagen_comprobante_salida) : null,
        imagen_factura_salida: item.imagen_factura_salida ? formatImageUrl(item.imagen_factura_salida) : null
      }));

      setSalidas(formattedData || []);
      setFilteredSalidas(formattedData || []);
    } catch (error) {
      console.error('Error en fetchSalidas:', error);
      setSnackbar({
        open: true,
        message: 'Error al cargar las salidas',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchCajasOrigen = async () => {
    try {
      const { data, error } = await supabase.from('cajas_origen_activas').select('caja_origen');
      if (error) throw error;
      setCajasOrigen(data.map((caja) => caja.caja_origen) || []);
    } catch (error) {
      console.error('Error fetchCajasOrigen:', error);
    }
  };

  const fetchProveedores = async () => {
    try {
      const { data, error } = await supabase.from('proveedores_de_servicios').select('proveedor_de_servicios');
      if (error) throw error;
      setProveedores(data.map((prov) => prov.proveedor_de_servicios) || []);
    } catch (error) {
      console.error('Error fetchProveedores:', error);
    }
  };

  const fetchProveedoresInfo = async () => {
    try {
      const { data, error } = await supabase.from('proveedor_frecuencia').select('*');
      if (error) throw error;
      setProveedoresInfo(data || []);
    } catch (error) {
      console.error('Error fetchProveedoresInfo:', error);
    }
  };

  const fetchProyectos = async () => {
    try {
      const { data, error } = await supabase.from('proyectos').select('codigo_de_proyecto');
      if (error) throw error;
      setProyectos(data.map((proy) => proy.codigo_de_proyecto) || []);
    } catch (error) {
      console.error('Error fetchProyectos:', error);
    }
  };

  const fetchConceptos = async () => {
    try {
      const { data, error } = await supabase.from('conceptos_de_salidas').select('concepto_de_salida');
      if (error) throw error;
      setConceptos(data.map((conc) => conc.concepto_de_salida) || []);
    } catch (error) {
      console.error('Error fetchConceptos:', error);
    }
  };

  const fetchEtapas = async () => {
    try {
      const { data, error } = await supabase.from('etapas_de_obra').select('etapa_de_obra');
      if (error) throw error;
      setEtapas([...new Set(data.map((etapa) => etapa.etapa_de_obra))] || []);
    } catch (error) {
      console.error('Error fetchEtapas:', error);
    }
  };

  const fetchSubetapas = async (etapaSeleccionada) => {
    try {
      const { data, error } = await supabase
        .from('etapas_de_obra')
        .select('subetapa_de_obra')
        .eq('etapa_de_obra', etapaSeleccionada);
      if (error) throw error;
      setSubetapas(data.map((sub) => sub.subetapa_de_obra) || []);
    } catch (error) {
      console.error('Error fetchSubetapas:', error);
    }
  };

  const fetchNombresFacturas = async () => {
    try {
      const { data, error } = await supabase.from('facturas_a_nombre_de_salidas').select('nombre_factura');
      if (error) throw error;
      setNombresFacturas(data.map((item) => item.nombre_factura) || []);
    } catch (error) {
      console.error('Error fetchNombresFacturas:', error);
    }
  };

  const fetchTiposIndexacion = async () => {
    try {
      const { data, error } = await supabase
        .from('tipos_indexacion_salidas')
        .select('tipo_indexacion');

      if (error) throw error;

      if (data && data.length > 0) {
        const tipos = data.map(item => item.tipo_indexacion);
        if (!tipos.includes('Dolar Oficial Venta')) {
          tipos.unshift('Dolar Oficial Venta');
        }
        setTiposIndexacion(tipos);
      }
    } catch (error) {
      console.error('Error fetchTiposIndexacion:', error);
    }
  };

  // UTILITY FUNCTIONS
  const formatCurrency = (amount, currency) => {
    if (!amount) return '';
    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currency === 'USD' ? 'USD' : 'ARS',
      minimumFractionDigits: 2
    });
    return formatter.format(amount);
  };

  // MODIFICADO: Calcular el sumario de montos sin condición para mostrar/ocultar
  const calculateSummary = () => {
    let totalARS = 0;
    let totalUSD = 0;
    let totalARSEquivalente = 0;
    let totalUSDEquivalente = 0;

    filteredSalidas.forEach(salida => {
      if (salida.moneda_salida === 'ARS') {
        totalARS += parseFloat(salida.monto_salida || 0);
        // También sumamos al equivalente en USD
        const tc = parseFloat(salida.tipo_de_cambio_salida || 0);
        if (tc > 0) {
          totalUSDEquivalente += parseFloat(salida.monto_salida || 0) / tc;
        }
        // Sumamos al equivalente en ARS (que es el mismo valor)
        totalARSEquivalente += parseFloat(salida.monto_salida || 0);
      } else if (salida.moneda_salida === 'USD') {
        totalUSD += parseFloat(salida.monto_salida || 0);
        // También sumamos al ARS el equivalente
        totalARSEquivalente += parseFloat(salida.monto_salida || 0) * parseFloat(salida.tipo_de_cambio_salida || 0);
        // Sumamos al equivalente en USD (que es el mismo valor)
        totalUSDEquivalente += parseFloat(salida.monto_salida || 0);
      }
    });

    setSummaryData({
      totalARS: totalARS.toFixed(2),
      totalUSD: totalUSD.toFixed(2),
      totalARSEquivalente: totalARSEquivalente.toFixed(2),
      totalUSDEquivalente: totalUSDEquivalente.toFixed(2)
    });

    // Ya no controlamos la visibilidad basada en filtros
    // El sumario siempre está visible (colapsado o expandido)
  };

  const buscarProyectoPorCajaOrigen = async (caja) => {
    try {
      // Primero buscar en caja_proyecto_mo
      let { data, error } = await supabase
        .from('proyectos')
        .select('codigo_de_proyecto')
        .eq('caja_proyecto_mo', caja)
        .limit(1);

      if (error) throw error;

      // Si no se encuentra, buscar en caja_proyecto
      if (!data || data.length === 0) {
        const response = await supabase
          .from('proyectos')
          .select('codigo_de_proyecto')
          .eq('caja_proyecto', caja)
          .limit(1);

        if (response.error) throw response.error;
        data = response.data;
      }

      // Si aún no se encuentra, buscar en apellido_del_cliente
      if (!data || data.length === 0) {
        const response = await supabase
          .from('proyectos')
          .select('codigo_de_proyecto')
          .eq('apellido_del_cliente', caja)
          .limit(1);

        if (response.error) throw response.error;
        data = response.data;
      }

      // Si se encontró un proyecto, establecerlo como predeterminado
      if (data && data.length > 0) {
        setCodigoProyecto(data[0].codigo_de_proyecto);

        // Regenerar códigos si hay imágenes
        if (comprobanteImagen) {
          generateCodigoComprobante(data[0].codigo_de_proyecto);
        }
        if (facturaImagen) {
          generateCodigoFactura(data[0].codigo_de_proyecto);
        }
      }
    } catch (error) {
      console.error('Error al buscar proyecto por caja origen:', error);
    }
  };

  const getProveedorInfo = (proveedorName) => {
    return proveedoresInfo.find(p => p.proveedor_salida === proveedorName) || null;
  };

  const calcularMontoIndexado = () => {
    if (tieneIndexacion && monto && tcIndexado && !isNaN(tcIndexado) && parseFloat(tcIndexado) > 0) {
      const montoARS = moneda === 'USD' ? parseFloat(monto) * parseFloat(tipoCambio) : parseFloat(monto);
      const indexado = montoARS / parseFloat(tcIndexado);
      setMontoIndexado(indexado.toFixed(2));
    } else {
      setMontoIndexado('');
    }
  };

  const generateCodigoComprobante = (proyectoSeleccionado = null) => {
    const proyecto = proyectoSeleccionado || codigoProyecto;
    if (!proyecto) return;

    const dateStr = format(new Date(), 'yyyyMMdd');
    const randomId = Math.floor(Math.random() * 100000).toString().padStart(5, '0');
    setCodigoComprobante(`${proyecto}-COM-${dateStr}-${randomId}`);
  };

  const generateCodigoFactura = (proyectoSeleccionado = null) => {
    const proyecto = proyectoSeleccionado || codigoProyecto;
    if (!proyecto) return;

    const dateStr = format(new Date(), 'yyyyMMdd');
    const randomId = Math.floor(Math.random() * 100000).toString().padStart(5, '0');
    setCodigoFactura(`${proyecto}-FAC-${dateStr}-${randomId}`);
  };

  const applyFilters = () => {
    let filtered = [...salidas];

    // Búsqueda global
    if (searchTerm) {
      filtered = filtered.filter(salida =>
        Object.values(salida).some(value =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Filtros activos
    if (filterCajaOrigen) {
      filtered = filtered.filter(salida => salida.caja_origen_salida === filterCajaOrigen);
    }

    if (filterProveedor) {
      filtered = filtered.filter(salida => salida.proveedor_salida === filterProveedor);
    }

    if (filterProyecto) {
      filtered = filtered.filter(salida => salida.codigo_de_proyecto_salida === filterProyecto);
    }

    // Nuevo filtro para concepto
    if (filterConcepto) {
      filtered = filtered.filter(salida => salida.concepto_salidas === filterConcepto);
    }

    if (filterFechaDesde) {
      filtered = filtered.filter(salida =>
        new Date(salida.fecha_salida) >= new Date(filterFechaDesde)
      );
    }

    if (filterFechaHasta) {
      filtered = filtered.filter(salida =>
        new Date(salida.fecha_salida) <= new Date(filterFechaHasta)
      );
    }

    setFilteredSalidas(filtered);
  };

  // MODIFICADO: Ya no controlamos la visibilidad del sumario en resetFilters
  const resetFilters = () => {
    setFilterCajaOrigen('');
    setFilterProveedor('');
    setFilterProyecto('');
    setFilterConcepto(''); // Resetear también el nuevo filtro de concepto
    setFilterFechaDesde(null);
    setFilterFechaHasta(null);
    setActiveFilters([]);
    setSearchTerm('');
    // Eliminamos esta línea: setSummaryVisible(false);
  };

  const applyFilterSelection = () => {
    // Crear array de filtros activos para mostrar badges
    const newFilters = [];

    if (filterCajaOrigen) {
      newFilters.push({ type: 'caja', value: filterCajaOrigen });
    }

    if (filterProveedor) {
      newFilters.push({ type: 'proveedor', value: filterProveedor });
    }

    if (filterProyecto) {
      newFilters.push({ type: 'proyecto', value: filterProyecto });
    }

    // Añadir el nuevo filtro de concepto
    if (filterConcepto) {
      newFilters.push({ type: 'concepto', value: filterConcepto });
    }

    if (filterFechaDesde) {
      newFilters.push({ type: 'fechaDesde', value: format(new Date(filterFechaDesde), 'dd/MM/yy') });
    }

    if (filterFechaHasta) {
      newFilters.push({ type: 'fechaHasta', value: format(new Date(filterFechaHasta), 'dd/MM/yy') });
    }

    setActiveFilters(newFilters);
    setOpenFiltersDrawer(false);
    applyFilters();
    calculateSummary();
  };

  // Función para ajustar el tipo de cambio (solo ±5)
  const adjustTipoCambio = (amount) => {
    const currentValue = parseFloat(tipoCambio) || 0;
    const newValue = Math.max(0, currentValue + amount);
    setTipoCambio(newValue.toString());
  };

  // Función para resetear formulario
  const resetForm = () => {
    setFechaSalida(new Date());
    setCajaOrigen('');
    setProveedor('');
    setCodigoProyecto('');
    setConcepto('');
    setEtapa('');
    setSubetapa('');
    setTipoCambio(dolarBlueCompra || '1050');
    setMoneda('ARS');
    setMonto('');
    setDescripcion('');

    // Resetear estados de comprobante
    setTieneComprobante(false);
    setComprobanteImagen(null);
    setComprobantePreview('');
    setCodigoComprobante('');
    if (comprobanteInputRef.current) {
      comprobanteInputRef.current.value = '';
    }

    // Resetear estados de factura
    setTieneFactura(false);
    setFacturaImagen(null);
    setFacturaPreview('');
    setCodigoFactura('');
    setFacturaANombreDe('');
    setNuevoNombreFactura('');
    setAddingNewNombre(false);
    if (facturaInputRef.current) {
      facturaInputRef.current.value = '';
    }

    // Resetear estados de indexación
    setTieneIndexacion(false);
    setTipoIndexacion('');
    setNuevoTipoIndexacion('');
    setTcIndexado(dolarOficialVenta || '950');
    setMontoIndexado('');

    // Resetear nuevos estados
    setNuevoProveedor('');
    setNuevaSubetapa('');
  };
  // FORM HANDLERS
  const handleOpenAddDialog = () => {
    // MODIFICADO: No permitir agregar salidas para clientes
    if (isCliente) {
      setSnackbar({
        open: true,
        message: 'No tienes permisos para agregar salidas',
        severity: 'warning'
      });
      return;
    }

    setEditMode(false);
    resetForm();
    setCurrentFormSection(0);
    setOpenAddDialog(true);
  };

  const handleOpenEditDialog = (salida) => {
    // MODIFICADO: No permitir editar salidas para clientes
    if (isCliente) {
      setSnackbar({
        open: true,
        message: 'No tienes permisos para editar salidas',
        severity: 'warning'
      });
      return;
    }

    setEditMode(true);
    setSelectedSalida(salida);
    populateFormWithSalida(salida);
    setCurrentFormSection(0);
    setOpenAddDialog(true);
  };

  const populateFormWithSalida = (salida) => {
    // Datos básicos
    setFechaSalida(new Date(salida.fecha_salida));
    setCajaOrigen(salida.caja_origen_salida || '');
    setProveedor(salida.proveedor_salida || '');
    setCodigoProyecto(salida.codigo_de_proyecto_salida || '');
    setConcepto(salida.concepto_salidas || '');
    setEtapa(salida.etapa_de_obra_salidas || '');
    fetchSubetapas(salida.etapa_de_obra_salidas);
    setSubetapa(salida.subetapa_de_obra_salidas || '');
    setTipoCambio(salida.tipo_de_cambio_salida || '');
    setMoneda(salida.moneda_salida || 'ARS');
    setMonto(salida.monto_salida || '');
    setDescripcion(salida.descripcion_salida || '');

    // Comprobante
    setTieneComprobante(salida.tiene_comprobante_salida || false);
    setCodigoComprobante(salida.codigo_de_comprobante_salida || '');
    setComprobantePreview(salida.imagen_comprobante_salida || '');

    // Factura
    setTieneFactura(salida.tiene_factura_salida || false);
    setCodigoFactura(salida.codigo_de_factura_salida || '');
    setFacturaPreview(salida.imagen_factura_salida || '');
    setFacturaANombreDe(salida.factura_a_nombre_de_salida || '');

    // Indexación
    setTieneIndexacion(salida.tiene_indexacion || false);
    setTipoIndexacion(salida.tipo_indexacion || '');
    setTcIndexado(salida.tc_indexado || '');
    setMontoIndexado(salida.monto_indexado || '');
  };

  const handleComprobanteChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificar tipo de archivo
      if (!file.type.match('image.*')) {
        setSnackbar({
          open: true,
          message: 'Por favor, selecciona un archivo de imagen válido',
          severity: 'error'
        });
        return;
      }

      // Verificar tamaño (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setSnackbar({
          open: true,
          message: 'La imagen debe ser menor a 5MB',
          severity: 'error'
        });
        return;
      }

      setComprobanteImagen(file);
      setTieneComprobante(true);

      // Crear URL temporal para vista previa
      const previewURL = URL.createObjectURL(file);
      setComprobantePreview(previewURL);

      // Generar código automático si hay un proyecto seleccionado
      generateCodigoComprobante();
    }
  };

  const handleFacturaChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificar tipo de archivo
      if (!file.type.match('image.*')) {
        setSnackbar({
          open: true,
          message: 'Por favor, selecciona un archivo de imagen válido',
          severity: 'error'
        });
        return;
      }

      // Verificar tamaño (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setSnackbar({
          open: true,
          message: 'La imagen debe ser menor a 5MB',
          severity: 'error'
        });
        return;
      }

      setFacturaImagen(file);
      setTieneFactura(true);

      // Crear URL temporal para vista previa
      const previewURL = URL.createObjectURL(file);
      setFacturaPreview(previewURL);

      // Generar código automático si hay un proyecto seleccionado
      generateCodigoFactura();
    }
  };

  const handleComprobanteUpload = () => {
    comprobanteInputRef.current.click();
  };

  const handleFacturaUpload = () => {
    facturaInputRef.current.click();
  };

  const removeComprobante = () => {
    setComprobanteImagen(null);
    setComprobantePreview('');
    setCodigoComprobante('');

    // Limpiar el input de archivo
    if (comprobanteInputRef.current) {
      comprobanteInputRef.current.value = '';
    }
  };

  const removeFactura = () => {
    setFacturaImagen(null);
    setFacturaPreview('');
    setCodigoFactura('');
    setFacturaANombreDe('');

    // Limpiar el input de archivo
    if (facturaInputRef.current) {
      facturaInputRef.current.value = '';
    }
  };

  const handleAgregarProveedor = async (nuevoProveedorNombre) => {
    if (!nuevoProveedorNombre || nuevoProveedorNombre.trim() === '') {
      setSnackbar({
        open: true,
        message: 'Por favor, ingrese un nombre de proveedor válido',
        severity: 'error'
      });
      return;
    }

    try {
      // Verificar si ya existe
      const { data, error } = await supabase
        .from('proveedores_de_servicios')
        .select('proveedor_de_servicios')
        .eq('proveedor_de_servicios', nuevoProveedorNombre.trim());

      if (error) throw error;

      // Si no existe, agregarlo
      if (!data || data.length === 0) {
        const { error: insertError } = await supabase
          .from('proveedores_de_servicios')
          .insert([{ proveedor_de_servicios: nuevoProveedorNombre.trim() }]);

        if (insertError) throw insertError;

        // Actualizar la lista local
        setProveedores([...proveedores, nuevoProveedorNombre.trim()]);
        // Establecer el proveedor seleccionado
        setProveedor(nuevoProveedorNombre.trim());

        setSnackbar({
          open: true,
          message: 'Proveedor agregado con éxito',
          severity: 'success'
        });
      } else {
        // Si ya existe, solo seleccionarlo
        setProveedor(nuevoProveedorNombre.trim());
      }

      setNuevoProveedor('');
    } catch (error) {
      console.error('Error al agregar proveedor:', error);
      setSnackbar({
        open: true,
        message: 'Error al agregar proveedor: ' + error.message,
        severity: 'error'
      });
    }
  };

  const handleAgregarSubetapa = async (nuevaSubetapaNombre) => {
    if (!etapa) {
      setSnackbar({
        open: true,
        message: 'Por favor, seleccione primero una etapa',
        severity: 'error'
      });
      return;
    }

    if (!nuevaSubetapaNombre || nuevaSubetapaNombre.trim() === '') {
      setSnackbar({
        open: true,
        message: 'Por favor, ingrese un nombre de subetapa válido',
        severity: 'error'
      });
      return;
    }

    try {
      // Verificar si ya existe
      const { data, error } = await supabase
        .from('etapas_de_obra')
        .select('*')
        .eq('etapa_de_obra', etapa)
        .eq('subetapa_de_obra', nuevaSubetapaNombre.trim());

      if (error) throw error;

      // Si no existe, agregarlo
      if (!data || data.length === 0) {
        const { error: insertError } = await supabase
          .from('etapas_de_obra')
          .insert([{
            etapa_de_obra: etapa,
            subetapa_de_obra: nuevaSubetapaNombre.trim()
          }]);

        if (insertError) throw insertError;

        // Actualizar la lista local
        setSubetapas([...subetapas, nuevaSubetapaNombre.trim()]);
        // Establecer la subetapa seleccionada
        setSubetapa(nuevaSubetapaNombre.trim());

        setSnackbar({
          open: true,
          message: 'Subetapa agregada con éxito',
          severity: 'success'
        });
      } else {
        // Si ya existe, solo seleccionarla
        setSubetapa(nuevaSubetapaNombre.trim());
      }

      setNuevaSubetapa('');
    } catch (error) {
      console.error('Error al agregar subetapa:', error);
      setSnackbar({
        open: true,
        message: 'Error al agregar subetapa: ' + error.message,
        severity: 'error'
      });
    }
  };

  const handleAddTipoIndexacion = async () => {
    if (!nuevoTipoIndexacion.trim()) return;

    try {
      // Primero verificar si ya existe
      const { data, error } = await supabase
        .from('tipos_indexacion_salidas')
        .select('tipo_indexacion')
        .eq('tipo_indexacion', nuevoTipoIndexacion.trim());

      if (error) throw error;

      // Si no existe, agregar
      if (!data || data.length === 0) {
        const { error: insertError } = await supabase
          .from('tipos_indexacion_salidas')
          .insert([{ tipo_indexacion: nuevoTipoIndexacion.trim() }]);

        if (insertError) throw insertError;
      }

      // Actualizar lista local
      if (!tiposIndexacion.includes(nuevoTipoIndexacion.trim())) {
        setTiposIndexacion([...tiposIndexacion, nuevoTipoIndexacion.trim()]);
      }

      // Establecer como tipo seleccionado
      setTipoIndexacion(nuevoTipoIndexacion.trim());
      setNuevoTipoIndexacion('');

    } catch (error) {
      console.error('Error al agregar tipo de indexación:', error);
      setSnackbar({
        open: true,
        message: 'Error al agregar tipo de indexación: ' + error.message,
        severity: 'error'
      });
    }
  };

  const handleAgregarNuevoNombre = async () => {
    if (!nuevoNombreFactura.trim()) {
      setSnackbar({
        open: true,
        message: 'Por favor, ingrese un nombre válido',
        severity: 'error'
      });
      return;
    }

    try {
      const { error } = await supabase.from('facturas_a_nombre_de_salidas').insert([
        { nombre_factura: nuevoNombreFactura.trim() }
      ]);

      if (error) {
        throw error;
      }

      fetchNombresFacturas();
      setFacturaANombreDe(nuevoNombreFactura.trim());
      setNuevoNombreFactura('');
      setAddingNewNombre(false);

      setSnackbar({
        open: true,
        message: 'Nombre agregado con éxito',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error al agregar nuevo nombre:', error.message);
      setSnackbar({
        open: true,
        message: 'Error al agregar nuevo nombre: ' + error.message,
        severity: 'error'
      });
    }
  };

  const handleSubmitForm = async () => {
    // MODIFICADO: No permitir guardar salidas para clientes
    if (isCliente) {
      setSnackbar({
        open: true,
        message: 'No tienes permisos para agregar o editar salidas',
        severity: 'warning'
      });
      return;
    }

    // Validar campos obligatorios
    if (!fechaSalida || !cajaOrigen || !proveedor || !codigoProyecto || !concepto || !etapa || !subetapa || !tipoCambio || !moneda || !monto) {
      setSnackbar({
        open: true,
        message: 'Por favor, completa todos los campos obligatorios',
        severity: 'error'
      });
      return;
    }

    // Validaciones adicionales para documentos
    if (tieneFactura && !facturaImagen && !facturaPreview) {
      setSnackbar({
        open: true,
        message: 'Por favor, sube una imagen de la factura',
        severity: 'error'
      });
      return;
    }

    if (tieneFactura && !facturaANombreDe) {
      setSnackbar({
        open: true,
        message: 'Por favor, selecciona a nombre de quién está la factura',
        severity: 'error'
      });
      return;
    }

    if (tieneComprobante && !comprobanteImagen && !comprobantePreview) {
      setSnackbar({
        open: true,
        message: 'Por favor, sube una imagen del comprobante',
        severity: 'error'
      });
      return;
    }

    if (tieneIndexacion && (!tipoIndexacion || tipoIndexacion === '')) {
      setSnackbar({
        open: true,
        message: 'Por favor, selecciona o ingresa un tipo de indexación',
        severity: 'error'
      });
      return;
    }

    if (tieneIndexacion && (!tcIndexado || parseFloat(tcIndexado) <= 0)) {
      setSnackbar({
        open: true,
        message: 'Por favor, ingresa un tipo de cambio válido para la indexación',
        severity: 'error'
      });
      return;
    }

    try {
      setLoading(true);

      let imageDataComprobante = null;
      let imageDataFactura = null;

      // Si estamos en edición y ya hay imágenes previas, mantenerlas
      const salidaId = editMode && selectedSalida ? selectedSalida.id : null;
      let imagenComprobanteExistente = editMode && selectedSalida ? selectedSalida.imagen_comprobante_salida : null;
      let rutaComprobanteExistente = editMode && selectedSalida ? selectedSalida.ruta_archivo_comprobante_salida : null;
      let codigoComprobanteExistente = editMode && selectedSalida ? selectedSalida.codigo_de_comprobante_salida : null;

      let imagenFacturaExistente = editMode && selectedSalida ? selectedSalida.imagen_factura_salida : null;
      let rutaFacturaExistente = editMode && selectedSalida ? selectedSalida.ruta_archivo_factura_salida : null;
      let codigoFacturaExistente = editMode && selectedSalida ? selectedSalida.codigo_de_factura_salida : null;

      // Subir comprobante si existe una nueva imagen
      if (tieneComprobante && comprobanteImagen) {
        // Si hay edición y hay imagen previa, eliminarla
        if (editMode && rutaComprobanteExistente) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([rutaComprobanteExistente]);
          } catch (err) {
            console.error("Error al eliminar comprobante existente:", err);
            // Continuar aunque falle la eliminación
          }
        }

        try {
          imageDataComprobante = await uploadImage(comprobanteImagen, {
            tipo: 'comprobante',
            referencia: codigoProyecto,
            concepto: concepto
          });
        } catch (uploadError) {
          throw new Error('Error al subir el comprobante: ' + uploadError.message);
        }
      }

      // Subir factura si existe una nueva imagen
      if (tieneFactura && facturaImagen) {
        // Si hay edición y hay imagen previa, eliminarla
        if (editMode && rutaFacturaExistente) {
          try {
            await supabase.storage
              .from('Facturas')
              .remove([rutaFacturaExistente]);
          } catch (err) {
            console.error("Error al eliminar factura existente:", err);
            // Continuar aunque falle la eliminación
          }
        }

        try {
          imageDataFactura = await uploadImage(facturaImagen, {
            tipo: 'factura',
            referencia: codigoProyecto,
            concepto: concepto
          });
        } catch (uploadError) {
          throw new Error('Error al subir la factura: ' + uploadError.message);
        }
      }

      // Asegurarse que todos los datos numéricos sean números válidos
      const validMonto = parseFloat(monto);
      const validTipoCambio = parseFloat(tipoCambio);
      const validMontoIndexado = tieneIndexacion ? parseFloat(montoIndexado) : null;
      const validTcIndexado = tieneIndexacion ? parseFloat(tcIndexado) : null;

      // Preparar datos para guardar - asegurarse que los valores son del tipo correcto y excluir columnas generadas
      const salidaData = {
        fecha_salida: fechaSalida.toISOString(),
        caja_origen_salida: cajaOrigen,
        proveedor_salida: proveedor,
        codigo_de_proyecto_salida: codigoProyecto,
        concepto_salidas: concepto,
        etapa_de_obra_salidas: etapa,
        subetapa_de_obra_salidas: subetapa,
        tipo_de_cambio_salida: validTipoCambio,
        moneda_salida: moneda,
        monto_salida: validMonto,
        descripcion_salida: descripcion,
        // Omitimos monto_en_ars_salida y monto_en_usd_salida que son columnas generadas

        // Datos de comprobante
        tiene_comprobante_salida: tieneComprobante,
        imagen_comprobante_salida: tieneComprobante
          ? (imageDataComprobante ? imageDataComprobante.url : imagenComprobanteExistente)
          : null,
        codigo_de_comprobante_salida: tieneComprobante
          ? (imageDataComprobante ? imageDataComprobante.codigo || codigoComprobante : codigoComprobanteExistente || codigoComprobante)
          : null,
        ruta_archivo_comprobante_salida: tieneComprobante
          ? (imageDataComprobante ? imageDataComprobante.ruta : rutaComprobanteExistente)
          : null,

        // Datos de factura
        tiene_factura_salida: tieneFactura,
        imagen_factura_salida: tieneFactura
          ? (imageDataFactura ? imageDataFactura.url : imagenFacturaExistente)
          : null,
        codigo_de_factura_salida: tieneFactura
          ? (imageDataFactura ? imageDataFactura.codigo || codigoFactura : codigoFacturaExistente || codigoFactura)
          : null,
        ruta_archivo_factura_salida: tieneFactura
          ? (imageDataFactura ? imageDataFactura.ruta : rutaFacturaExistente)
          : null,
        factura_a_nombre_de_salida: tieneFactura ? facturaANombreDe : null,

        // Datos de indexación
        tiene_indexacion: tieneIndexacion,
        tipo_indexacion: tieneIndexacion ? tipoIndexacion : null,
        tc_indexado: validTcIndexado,
        monto_indexado: validMontoIndexado
      };

      // Registro detallado para depuración
      console.log("Enviando datos a Supabase:", salidaData);

      let response;
      if (editMode && salidaId) {
        // Actualizar salida existente
        response = await supabase
          .from('salidas')
          .update(salidaData)
          .eq('id', salidaId);
      } else {
        // Crear nueva salida
        response = await supabase
          .from('salidas')
          .insert([salidaData]);
      }

      if (response.error) {
        console.error("Error de Supabase:", response.error);
        throw new Error(
          (editMode ? 'Error al actualizar salida: ' : 'Error al agregar salida: ') +
          (response.error.message || 'Por favor, verifica los datos e intenta nuevamente')
        );
      }

      // Recargar datos
      // MODIFICADO: Usar la función correspondiente según si es cliente o no
      if (isCliente) {
        fetchSalidasForCliente(userProyecto, userCajasPermitidas);
      } else {
        fetchSalidas();
      }

      setSnackbar({
        open: true,
        message: editMode ? 'Salida actualizada con éxito' : 'Salida agregada con éxito',
        severity: 'success'
      });

      setOpenAddDialog(false);
      resetForm();

    } catch (error) {
      console.error('Error al guardar salida:', error);
      setSnackbar({
        open: true,
        message: error.message || "Error al procesar la solicitud. Intenta nuevamente.",
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Función para animar la eliminación
  const animateItemDelete = () => {
    setDeleteAnimation(true);

    // Establecer referencia al elemento de la vista detallada si existe
    if (openDetailView && selectedSalida) {
      const detailElement = document.querySelector('.mobile-detail-view');
      if (detailElement) {
        detailElement.style.transition = 'all 0.5s ease';
        detailElement.style.transform = 'scale(0.8)';
        detailElement.style.opacity = '0';
      }
    }

    // Esperar a que termine la animación antes de ejecutar la eliminación
    setTimeout(() => {
      if (selectedSalida) {
        handleDeleteSalida(selectedSalida);
      }
      setDeleteAnimation(false);
    }, 300);
  };

  const handleDeleteSalida = async (salida, event) => {
    // MODIFICADO: No permitir eliminar salidas para clientes
    if (isCliente) {
      setSnackbar({
        open: true,
        message: 'No tienes permisos para eliminar salidas',
        severity: 'warning'
      });
      return;
    }

    if (event) {
      event.stopPropagation();
    }

    try {
      setLoading(true);

      // Eliminar archivos si existen
      if (salida.tiene_comprobante_salida && salida.ruta_archivo_comprobante_salida) {
        try {
          await supabase.storage
            .from('Comprobantes')
            .remove([salida.ruta_archivo_comprobante_salida]);
        } catch (error) {
          console.error('Error al eliminar el archivo de comprobante:', error);
          // Continuar aunque falle la eliminación
        }
      }

      if (salida.tiene_factura_salida && salida.ruta_archivo_factura_salida) {
        try {
          await supabase.storage
            .from('Facturas')
            .remove([salida.ruta_archivo_factura_salida]);
        } catch (error) {
          console.error('Error al eliminar el archivo de factura:', error);
          // Continuar aunque falle la eliminación
        }
      }

      // Eliminar el registro
      const { error } = await supabase
        .from('salidas')
        .delete()
        .eq('id', salida.id);

      if (error) {
        throw error;
      }

      setSnackbar({
        open: true,
        message: 'Salida eliminada con éxito',
        severity: 'success'
      });

      // MODIFICADO: Usar la función correspondiente según si es cliente o no
      if (isCliente) {
        fetchSalidasForCliente(userProyecto, userCajasPermitidas);
      } else {
        fetchSalidas();
      }

      // Cerrar vista de detalle si está abierta
      if (openDetailView && selectedSalida && selectedSalida.id === salida.id) {
        setOpenDetailView(false);
      }

      // Cerrar diálogo de confirmación
      setDeleteConfirmOpen(false);

    } catch (error) {
      console.error('Error al eliminar salida:', error);
      setSnackbar({
        open: true,
        message: 'Error al eliminar salida: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // VIEW HANDLERS
  const handleOpenDetailView = (salida) => {
    setSelectedSalida(salida);
    setOpenDetailView(true);
  };

  const handleOpenImagePreview = (imageUrl, event) => {
    if (event) {
      event.stopPropagation();
    }
    setCurrentImage(imageUrl);
    setImagePreviewOpen(true);
  };

  const handleNextFormSection = () => {
    if (currentFormSection < formSections.length - 1) {
      setCurrentFormSection(prevSection => prevSection + 1);
    }
  };

  const handlePrevFormSection = () => {
    if (currentFormSection > 0) {
      setCurrentFormSection(prevSection => prevSection - 1);
    }
  };

  // Handle swipe down to close dialog
  const handleFormDialogSwipe = (e) => {
    if (e.target.scrollTop === 0 && e.deltaY < -50) {
      setOpenAddDialog(false);
    }
  };

  // Función para manejar la apertura del diálogo de búsqueda flotante
  const handleSearchDialogOpen = () => {
    setSearchDialogOpen(true);
  };

  // Función para autocompletar campos basados en proveedor
  const autocompletarCamposPorProveedor = (proveedorSeleccionado) => {
    const info = getProveedorInfo(proveedorSeleccionado);
    if (info) {
      if (info.concepto_mas_usado && !concepto) {
        setConcepto(info.concepto_mas_usado);
      }
      if (info.etapa_mas_usada && !etapa) {
        setEtapa(info.etapa_mas_usada);
        fetchSubetapas(info.etapa_mas_usada);

        // Si hay una subetapa más usada para este proveedor, seleccionarla
        if (info.subetapa_mas_usada) {
          setTimeout(() => {
            setSubetapa(info.subetapa_mas_usada);
          }, 300); // Pequeño retraso para asegurar que las subetapas se carguen
        }
      }
    }
  };

  // Contenido del formulario según la sección actual - REDISEÑADO
  const renderFormContent = () => {
    switch (currentFormSection) {
      case 0: // Información Básica
        return (
          <Box>
            <FormSectionTitle>
              <InfoIcon /> Información Básica
            </FormSectionTitle>

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <DatePicker
                label="Fecha"
                value={fechaSalida}
                onChange={(newValue) => setFechaSalida(newValue)}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>

            <StyledFormControl fullWidth>
              <InputLabel>Caja Origen</InputLabel>
              <Select
                value={cajaOrigen}
                onChange={(e) => setCajaOrigen(e.target.value)}
                label="Caja Origen"
              >
                {cajasOrigen.map((caja) => (
                  <MenuItem key={caja} value={caja}>
                    {caja}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>

            <Autocomplete
              value={proveedor}
              onChange={(event, newValue) => {
                if (newValue) {
                  setProveedor(newValue);
                  autocompletarCamposPorProveedor(newValue);
                  // Ocultar teclado después de seleccionar
                  document.activeElement.blur();
                }
              }}
              inputValue={nuevoProveedor}
              onInputChange={(event, newInputValue) => {
                setNuevoProveedor(newInputValue);
              }}
              options={proveedores}
              freeSolo
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label="Proveedor"
                  margin="normal"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {nuevoProveedor && !proveedores.includes(nuevoProveedor) && (
                          <Tooltip title="Agregar nuevo proveedor">
                            <IconButton
                              size="small"
                              onClick={() => handleAgregarProveedor(nuevoProveedor)}
                              sx={{ mr: 1 }}
                            >
                              <AddIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          </Box>
        ); case 1: // Detalles del Proyecto
        return (
          <Box>
            <FormSectionTitle>
              <AssignmentIcon /> Detalles del Proyecto
            </FormSectionTitle>

            <StyledFormControl fullWidth>
              <InputLabel>Proyecto</InputLabel>
              <Select
                value={codigoProyecto}
                onChange={(e) => {
                  setCodigoProyecto(e.target.value);
                  // Regenerar códigos si hay imágenes
                  if (comprobanteImagen) {
                    generateCodigoComprobante(e.target.value);
                  }
                  if (facturaImagen) {
                    generateCodigoFactura(e.target.value);
                  }
                }}
                label="Proyecto"
              >
                {proyectos.map((proy) => (
                  <MenuItem key={proy} value={proy}>
                    {proy}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>

            <StyledFormControl fullWidth>
              <InputLabel>Concepto</InputLabel>
              <Select
                value={concepto}
                onChange={(e) => setConcepto(e.target.value)}
                label="Concepto"
              >
                {conceptos.map((conc) => (
                  <MenuItem key={conc} value={conc}>
                    {conc}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>

            <StyledFormControl fullWidth>
              <InputLabel>Etapa</InputLabel>
              <Select
                value={etapa}
                onChange={(e) => {
                  setEtapa(e.target.value);
                  fetchSubetapas(e.target.value);
                  setSubetapa(''); // Reset subetapa when etapa changes
                }}
                label="Etapa"
              >
                {etapas.map((etapa) => (
                  <MenuItem key={etapa} value={etapa}>
                    {etapa}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>

            <Autocomplete
              value={subetapa}
              onChange={(event, newValue) => {
                if (newValue) {
                  setSubetapa(newValue);
                  // Ocultar teclado después de seleccionar
                  document.activeElement.blur();
                }
              }}
              inputValue={nuevaSubetapa}
              onInputChange={(event, newInputValue) => {
                setNuevaSubetapa(newInputValue);
              }}
              options={subetapas}
              freeSolo
              disabled={!etapa}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  label="Subetapa"
                  margin="normal"
                  disabled={!etapa}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {nuevaSubetapa && etapa && !subetapas.includes(nuevaSubetapa) && (
                          <Tooltip title="Agregar nueva subetapa">
                            <IconButton
                              size="small"
                              onClick={() => handleAgregarSubetapa(nuevaSubetapa)}
                              sx={{ mr: 1 }}
                            >
                              <AddIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />

            <StyledTextField
              label="Descripción (opcional)"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              multiline
              rows={3}
              fullWidth
              placeholder="Ingrese una descripción detallada..."
            />
          </Box>
        );

      case 2: // Información Financiera
        return (
          <Box>
            <FormSectionTitle>
              <AttachMoneyIcon /> Información Financiera
            </FormSectionTitle>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <StyledFormControl fullWidth>
                  <InputLabel>Moneda</InputLabel>
                  <Select
                    value={moneda}
                    onChange={(e) => setMoneda(e.target.value)}
                    label="Moneda"
                  >
                    <MenuItem value="ARS">ARS</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  label="Tipo de Cambio"
                  value={tipoCambio}
                  onChange={(e) => setTipoCambio(e.target.value)}
                  fullWidth
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>

            {/* Controles para ajustar el tipo de cambio - Solo botones ±5 */}
            <TipoCambioControl>
              <Typography variant="body2" sx={{ flex: 1 }}>Ajustar tipo de cambio:</Typography>
              <TcStepButton color="error" onClick={() => adjustTipoCambio(-5)}>
                -5
              </TcStepButton>
              <TcStepButton onClick={() => adjustTipoCambio(5)}>
                +5
              </TcStepButton>
              <Tooltip title="Restaurar valor del dólar blue">
                <IconButton
                  size="small"
                  onClick={() => setTipoCambio(dolarBlueCompra)}
                  sx={{ ml: 1, bgcolor: 'rgba(46, 125, 50, 0.1)', color: 'primary.main' }}
                >
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TipoCambioControl>

            <StyledTextField
              label="Monto"
              value={monto}
              onChange={(e) => setMonto(e.target.value)}
              fullWidth
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">{moneda === 'USD' ? '$' : '$'}</InputAdornment>,
              }}
            />

            <SwitchFormControl
              control={
                <Switch
                  checked={tieneIndexacion}
                  onChange={(e) => setTieneIndexacion(e.target.checked)}
                  color="primary"
                />
              }
              label="¿Tiene indexación?"
              labelPlacement="start"
            />

            {tieneIndexacion && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <Autocomplete
                  value={tipoIndexacion}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      setTipoIndexacion(newValue);
                      if (newValue === 'Dolar Oficial Venta') {
                        setTcIndexado(dolarOficialVenta);
                      }
                    }
                  }}
                  inputValue={nuevoTipoIndexacion}
                  onInputChange={(event, newInputValue) => {
                    setNuevoTipoIndexacion(newInputValue);
                  }}
                  options={tiposIndexacion}
                  freeSolo
                  renderInput={(params) => (
                    <StyledTextField
                      {...params}
                      label="Tipo de Indexación"
                      margin="normal"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {nuevoTipoIndexacion && !tiposIndexacion.includes(nuevoTipoIndexacion) && (
                              <Tooltip title="Agregar nuevo tipo">
                                <IconButton
                                  size="small"
                                  onClick={handleAddTipoIndexacion}
                                  sx={{ mr: 1 }}
                                >
                                  <AddIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            )}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />

                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <StyledTextField
                      label="TC Indexado"
                      value={tcIndexado}
                      onChange={(e) => setTcIndexado(e.target.value)}
                      fullWidth
                      type="number"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        endAdornment: tipoIndexacion === 'Dolar Oficial Venta' && (
                          <IconButton
                            edge="end"
                            onClick={() => setTcIndexado(dolarOficialVenta)}
                            size="small"
                          >
                            <RefreshIcon fontSize="small" />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <StyledTextField
                      label="Monto Indexado"
                      value={montoIndexado}
                      InputProps={{
                        readOnly: true,
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                      fullWidth
                      disabled
                      sx={{
                        '& .MuiInputBase-input.Mui-disabled': {
                          WebkitTextFillColor: 'rgba(0, 0, 0, 0.8)',
                          fontWeight: 'bold',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              </motion.div>
            )}
          </Box>
        );

      case 3: // Documentación
        return (
          <Box>
            <FormSectionTitle>
              <DescriptionIcon /> Documentación
            </FormSectionTitle>

            <SwitchFormControl
              control={
                <Switch
                  checked={tieneComprobante}
                  onChange={(e) => setTieneComprobante(e.target.checked)}
                  color="primary"
                />
              }
              label="¿Tiene comprobante?"
              labelPlacement="start"
            />

            {tieneComprobante && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <input
                  ref={comprobanteInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleComprobanteChange}
                  style={{ display: 'none' }}
                />

                {!comprobantePreview ? (
                  <FileUploadBox onClick={handleComprobanteUpload}>
                    <CameraAltIcon sx={{ fontSize: 40, color: theme.palette.primary.main, mb: 1 }} />
                    <Typography variant="body1" gutterBottom>
                      Toca para subir una imagen de comprobante
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      JPG, PNG (máx. 5MB)
                    </Typography>
                  </FileUploadBox>
                ) : (
                  <Box sx={{ position: 'relative', mb: 2 }}>
                    <ImagePreviewBox>
                      <Box
                        component="img"
                        src={comprobantePreview}
                        alt="Vista previa del comprobante"
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </ImagePreviewBox>

                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
                      }}
                      onClick={removeComprobante}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>

                    {codigoComprobante && (
                      <Typography
                        variant="caption"
                        sx={{
                          display: 'block',
                          textAlign: 'center',
                          mt: 1,
                          fontFamily: 'monospace',
                          backgroundColor: 'rgba(46, 125, 50, 0.1)',
                          p: 1.5,
                          borderRadius: 2,
                        }}
                      >
                        {codigoComprobante}
                      </Typography>
                    )}
                  </Box>
                )}
              </motion.div>
            )}

            <SwitchFormControl
              control={
                <Switch
                  checked={tieneFactura}
                  onChange={(e) => setTieneFactura(e.target.checked)}
                  color="primary"
                />
              }
              label="¿Tiene factura?"
              labelPlacement="start"
            />

            {tieneFactura && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <input
                  ref={facturaInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleFacturaChange}
                  style={{ display: 'none' }}
                />

                {!facturaPreview ? (
                  <FileUploadBox onClick={handleFacturaUpload}>
                    <CameraAltIcon sx={{ fontSize: 40, color: theme.palette.primary.main, mb: 1 }} />
                    <Typography variant="body1" gutterBottom>
                      Toca para subir una imagen de factura
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      JPG, PNG (máx. 5MB)
                    </Typography>
                  </FileUploadBox>
                ) : (
                  <Box sx={{ position: 'relative', mb: 2 }}>
                    <ImagePreviewBox>
                      <Box
                        component="img"
                        src={facturaPreview}
                        alt="Vista previa de la factura"
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </ImagePreviewBox>

                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
                      }}
                      onClick={removeFactura}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>

                    {codigoFactura && (
                      <Typography
                        variant="caption"
                        sx={{
                          display: 'block',
                          textAlign: 'center',
                          mt: 1,
                          fontFamily: 'monospace',
                          backgroundColor: 'rgba(46, 125, 50, 0.1)',
                          p: 1.5,
                          borderRadius: 2,
                        }}
                      >
                        {codigoFactura}
                      </Typography>
                    )}
                  </Box>
                )}

                <StyledFormControl fullWidth>
                  <InputLabel>A nombre de</InputLabel>
                  <Select
                    value={facturaANombreDe}
                    onChange={(e) => setFacturaANombreDe(e.target.value)}
                    label="A nombre de"
                  >
                    <MenuItem value="">
                      <em>Seleccione un nombre</em>
                    </MenuItem>
                    {nombresFacturas.map((nombre) => (
                      <MenuItem key={nombre} value={nombre}>
                        {nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>

                <Box mt={1} mb={2} display="flex" justifyContent="center">
                  <Button
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => setAddingNewNombre(true)}
                    sx={{ borderRadius: 16, py: 1, px: 2 }}
                  >
                    Agregar nuevo nombre
                  </Button>
                </Box>

                {addingNewNombre && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <StyledTextField
                        value={nuevoNombreFactura}
                        onChange={(e) => setNuevoNombreFactura(e.target.value)}
                        label="Nuevo nombre"
                        fullWidth
                        size="small"
                      />
                      <IconButton
                        color="primary"
                        onClick={handleAgregarNuevoNombre}
                        sx={{ ml: 1 }}
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => setAddingNewNombre(false)}
                        sx={{ ml: 0.5 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </motion.div>
                )}
              </motion.div>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  // Renderización de las tarjetas de salida para la vista principal
  const renderSalidaCard = (salida) => {
    // Función para animar la eliminación desde la tarjeta
    const animateCardDelete = (e) => {
      e.stopPropagation();

      // MODIFICADO: No permitir eliminar para clientes
      if (isCliente) {
        setSnackbar({
          open: true,
          message: 'No tienes permisos para eliminar salidas',
          severity: 'warning'
        });
        return;
      }

      setDeleteConfirmOpen(true);
      setSelectedSalida(salida);
    };

    return (
      <SalidaCard
        key={salida.id}
        onClick={() => handleOpenDetailView(salida)}
        component={motion.div}
        whileHover={{ y: -4 }}
        whileTap={{ scale: 0.98 }}
      >
        <CardContent sx={{ p: 2, pb: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                sx={{
                  bgcolor: salida.moneda_salida === 'USD' ? 'rgba(255, 193, 7, 0.1)' : 'rgba(46, 125, 50, 0.1)',
                  color: salida.moneda_salida === 'USD' ? '#f57c00' : '#2e7d32',
                  mr: 1.5,
                  width: 40,
                  height: 40,
                  fontSize: '0.9rem',
                  fontWeight: 'bold'
                }}
              >
                {salida.moneda_salida}
              </Avatar>
              <Box>
                <Typography variant="body1" fontWeight="bold">
                  {salida.monto_formateado}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  TC: {salida.tipo_de_cambio_salida}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2" fontWeight="medium" color="primary">
                {salida.fecha_formateada}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {salida.caja_origen_salida}
              </Typography>
            </Box>
          </Box>

          <CardRow>
            <Typography variant="body2" color="text.secondary">Proveedor:</Typography>
            <Typography variant="body2" fontWeight="medium" noWrap sx={{ maxWidth: '60%', textAlign: 'right' }}>
              {salida.proveedor_salida}
            </Typography>
          </CardRow>

          <CardRow>
            <Typography variant="body2" color="text.secondary">Proyecto:</Typography>
            <Typography variant="body2" fontWeight="medium" noWrap sx={{ maxWidth: '60%', textAlign: 'right' }}>
              {salida.codigo_de_proyecto_salida}
            </Typography>
          </CardRow>

          <CardRow>
            <Typography variant="body2" color="text.secondary">Subetapa:</Typography>
            <Typography variant="body2" fontWeight="medium" noWrap sx={{ maxWidth: '60%', textAlign: 'right' }}>
              {salida.subetapa_de_obra_salidas}
            </Typography>
          </CardRow>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            {salida.tiene_comprobante_salida && (
              <DocChip
                icon={<ReceiptIcon />}
                label="Comprobante"
                size="small"
                color="success"
                variant="outlined"
                sx={{ mr: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenImagePreview(salida.imagen_comprobante_salida, e);
                }}
              />
            )}

            {salida.tiene_factura_salida && (
              <DocChip
                icon={<DescriptionIcon />}
                label="Factura"
                size="small"
                color="primary"
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenImagePreview(salida.imagen_factura_salida, e);
                }}
              />
            )}

            {/* MODIFICADO: Solo mostrar botones de edición/eliminación para no clientes */}
            {!isCliente && (
              <Box sx={{ ml: 'auto', display: 'flex' }}>
                <IconButton
                  size="small"
                  color="primary"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOpenEditDialog(salida);
                  }}
                >
                  <EditIcon fontSize="small" />
                </IconButton>

                <IconButton
                  size="small"
                  color="error"
                  onClick={animateCardDelete}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          </Box>
        </CardContent>
      </SalidaCard>
    );
  };

  // RENDER - REDISEÑADO COMPLETAMENTE
  return (
    <>
      <MobileContainer sx={{ backgroundColor: '#f8f9fa' }}>
        {/* Barra de búsqueda con botón de filtro separado */}
        <SearchBarContainer disappear={trigger}>
          <SearchBar elevation={1}>
            <InputBase
              placeholder="Buscar salidas..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              sx={{ ml: 1 }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </SearchBar>
          <FilterButton
            startIcon={<FilterAltIcon />}
            onClick={() => setOpenFiltersDrawer(true)}
            variant="outlined"
          >
            Filtros
          </FilterButton>
        </SearchBarContainer>

        {/* Botones flotantes que aparecen al hacer scroll */}
        <Zoom in={trigger}>
          <FixedSearchContainer>
            <FloatingSearchButton
              color="primary"
              onClick={() => {
                setSearchTerm('');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              <SearchIcon />
            </FloatingSearchButton>
            <FloatingFilterButton
              color="primary"
              onClick={() => setOpenFiltersDrawer(true)}
            >
              <FilterAltIcon />
            </FloatingFilterButton>
          </FixedSearchContainer>
        </Zoom>

        {/* MODIFICADO: Sumario financiero siempre visible pero colapsable */}
        <SummaryPanel>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: summaryExpanded ? 1 : 0 }}>
            <SummarizeIcon sx={{ mr: 1, color: 'primary.main' }} />
            <Typography variant="subtitle1" fontWeight="bold">
              Resumen Financiero
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <IconButton
              size="small"
              onClick={() => setSummaryExpanded(!summaryExpanded)}
              sx={{ transform: summaryExpanded ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'transform 0.3s ease' }}
            >
              <KeyboardArrowUpIcon fontSize="small" />
            </IconButton>
          </Box>

          {summaryExpanded && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: 'auto' }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Divider sx={{ mb: 2 }} />
              <SummaryRow>
                <Typography variant="body2" color="text.secondary">Total ARS:</Typography>
                <Typography variant="body1" fontWeight="bold" color="primary.main">
                  {formatCurrency(summaryData.totalARS, 'ARS')}
                </Typography>
              </SummaryRow>
              <SummaryRow>
                <Typography variant="body2" color="text.secondary">Total USD:</Typography>
                <Typography variant="body1" fontWeight="bold" color="#f57c00">
                  {formatCurrency(summaryData.totalUSD, 'USD')}
                </Typography>
              </SummaryRow>
              <Divider sx={{ my: 1 }} />
              <SummaryRow>
                <Typography variant="body2" color="text.secondary">Equivalente en ARS:</Typography>
                <Typography variant="body1" fontWeight="bold" color="primary.main">
                  {formatCurrency(summaryData.totalARSEquivalente, 'ARS')}
                </Typography>
              </SummaryRow>
              <SummaryRow>
                <Typography variant="body2" color="text.secondary">Equivalente en USD:</Typography>
                <Typography variant="body1" fontWeight="bold" color="#f57c00">
                  {formatCurrency(summaryData.totalUSDEquivalente, 'USD')}
                </Typography>
              </SummaryRow>
            </motion.div>
          )}
        </SummaryPanel>

        {/* Filtros activos */}
        {activeFilters.length > 0 && (
          <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap' }}>
            {activeFilters.map((filter, index) => (
              <Chip
                key={index}
                label={`${filter.type === 'caja' ? 'Caja: ' :
                  filter.type === 'proveedor' ? 'Prov: ' :
                    filter.type === 'proyecto' ? 'Proy: ' :
                      filter.type === 'concepto' ? 'Conc: ' : // Añadido para el nuevo filtro concepto
                        filter.type === 'fechaDesde' ? 'Desde: ' :
                          filter.type === 'fechaHasta' ? 'Hasta: ' : ''}${filter.value}`}
                onDelete={() => {
                  const newFilters = [...activeFilters];
                  newFilters.splice(index, 1);
                  setActiveFilters(newFilters);

                  // También resetear el estado correspondiente
                  if (filter.type === 'caja') setFilterCajaOrigen('');
                  if (filter.type === 'proveedor') setFilterProveedor('');
                  if (filter.type === 'proyecto') setFilterProyecto('');
                  if (filter.type === 'concepto') setFilterConcepto(''); // Reseteo del filtro concepto
                  if (filter.type === 'fechaDesde') setFilterFechaDesde(null);
                  if (filter.type === 'fechaHasta') setFilterFechaHasta(null);
                }}
                size="small"
                sx={{ m: 0.5, borderRadius: 16, py: 1 }}
              />
            ))}
            <Chip
              label="Limpiar"
              onClick={resetFilters}
              size="small"
              color="primary"
              sx={{ m: 0.5, borderRadius: 16, py: 1 }}
            />
          </Box>
        )}

        {/* Lista de salidas */}
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
          sx={{ backgroundColor: '#f8f9fa' }} // Añadido para asegurar que el fondo sea uniforme
        >
          {initialLoading || (loading && filteredSalidas.length === 0) ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', flexDirection: 'column' }}>
              <CircularProgress color="primary" />
              <Typography variant="body1" sx={{ mt: 2 }}>Cargando salidas...</Typography>
            </Box>
          ) : filteredSalidas.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', flexDirection: 'column' }}>
              <Typography variant="body1">No se encontraron salidas</Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Intenta con otros criterios de búsqueda{!isCliente ? ' o crea una nueva salida' : ''}
              </Typography>
              {!isCliente && (
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<AddIcon />}
                  onClick={handleOpenAddDialog}
                  sx={{ mt: 2, borderRadius: 20, px: 3, py: 1 }}
                >
                  Nueva Salida
                </Button>
              )}
            </Box>
          ) : (
            <>
              {filteredSalidas
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map(renderSalidaCard)}

              {/* Paginación */}
              {filteredSalidas.length > itemsPerPage && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 4 }}>
                  <Pagination
                    count={Math.ceil(filteredSalidas.length / itemsPerPage)}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    color="primary"
                    showFirstButton
                    showLastButton
                    size="small"
                  />
                </Box>
              )}
            </>
          )}
        </motion.div>

        {/* MODIFICADO: Botón flotante para agregar solo visible para no clientes */}
        {!isCliente && (
          <AddFab
            color="primary"
            aria-label="add"
            onClick={handleOpenAddDialog}
          >
            <AddIcon />
          </AddFab>
        )}

        {/* Dialog COMPLETAMENTE REDISEÑADO para agregar/editar salida */}
        <FullWidthDialog
          open={openAddDialog}
          onClose={() => setOpenAddDialog(false)}
          TransitionComponent={Slide}
          TransitionProps={{ direction: 'up' }}
          fullWidth
          maxWidth="sm"
          // Solución para el error aria-hidden
          keepMounted={false}
        >
          <FormContainer>
            {/* Header - Con capacidad de deslizar hacia abajo para cerrar */}
            <FormHeader
              ref={formDialogRef}
              onWheel={handleFormDialogSwipe}
              component={motion.div}
              whileTap={{ cursor: 'grabbing' }}
              sx={{ cursor: 'grab' }}
            >
              <CloseButton onClick={() => setOpenAddDialog(false)}>
                <CloseIcon />
              </CloseButton>
              <Typography variant="h6" sx={{ ml: 2, fontWeight: 600 }}>
                {editMode ? 'Editar Salida' : 'Nueva Salida'}
              </Typography>
            </FormHeader>

            {/* Selector de pasos */}
            <StepButtonContainer>
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                {formSections.map((_, index) => (
                  <StepIndicator
                    key={index}
                    active={currentFormSection === index}
                    onClick={() => setCurrentFormSection(index)}
                    sx={{ cursor: 'pointer' }}
                  />
                ))}
              </Box>
            </StepButtonContainer>

            {/* Contenido del formulario con animación */}
            <FormContent>
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentFormSection}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  {renderFormContent()}
                </motion.div>
              </AnimatePresence>
            </FormContent>

            {/* Footer con botones de navegación */}
            <FormFooter>
              {currentFormSection > 0 && (
                <StepButton
                  variant="prev"
                  onClick={handlePrevFormSection}
                  startIcon={<KeyboardArrowLeftIcon />}
                >
                  Anterior
                </StepButton>
              )}
              {currentFormSection < formSections.length - 1 ? (
                <StepButton
                  variant="next"
                  onClick={handleNextFormSection}
                  endIcon={<KeyboardArrowRightIcon />}
                  sx={{ ml: 'auto' }}
                >
                  Siguiente
                </StepButton>
              ) : (
                <StyledSaveButton
                  onClick={handleSubmitForm}
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                  sx={{ ml: 'auto' }}
                >
                  {editMode ? 'Actualizar' : 'Guardar'}
                </StyledSaveButton>
              )}
            </FormFooter>
          </FormContainer>
        </FullWidthDialog>

        {/* Vista detallada de salida - REDISEÑADA CON TAMAÑO FIJO */}
        <Drawer
          anchor="right"
          open={openDetailView}
          onClose={() => setOpenDetailView(false)}
          PaperProps={{
            sx: {
              width: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(2px)',
              boxShadow: 'none'
            }
          }}
        >
          {selectedSalida && (
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
            }}>
              <MobileDetailView
                className="mobile-detail-view"
                component={motion.div}
                initial={{ opacity: 0, scale: 0.98 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.98 }}
                transition={{
                  duration: 0.3,
                  type: 'spring',
                  stiffness: 400,
                  damping: 30,
                  bounce: 0
                }}
              >
                <Box sx={{ position: 'relative' }}>
                  <AppBar
                    position="sticky"
                    color="transparent"
                    elevation={0}
                    sx={{
                      bgcolor: 'white',
                      borderBottom: '1px solid rgba(0,0,0,0.05)',
                      borderTopLeftRadius: 28,
                      borderTopRightRadius: 28,
                    }}
                  >
                    <Toolbar>
                      <IconButton edge="start" onClick={() => setOpenDetailView(false)}>
                        <ArrowBackIcon />
                      </IconButton>
                      <Typography variant="h6" fontWeight="medium" sx={{ ml: 1 }}>
                        Detalles de Salida
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      {/* MODIFICADO: Botones de edición y eliminación solo para no clientes */}
                      {!isCliente && (
                        <>
                          <IconButton
                            color="primary"
                            onClick={() => handleOpenEditDialog(selectedSalida)}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            color="error"
                            onClick={() => setDeleteConfirmOpen(true)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </Toolbar>
                  </AppBar>

                  <Box sx={{ p: 2, overflowY: 'auto', height: 'calc(85vh - 64px)', backgroundColor: '#f8f9fa' }}>
                    {/* Cabecera con monto y fecha */}
                    <DetailSection>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Box>
                          <Typography variant="body2" color="text.secondary">Fecha</Typography>
                          <Typography variant="h6" fontWeight="bold">{selectedSalida.fecha_formateada}</Typography>
                        </Box>
                        <Box sx={{ textAlign: 'right' }}>
                          <Typography variant="body2" color="text.secondary">Monto</Typography>
                          <Typography variant="h6" fontWeight="bold" color={selectedSalida.moneda_salida === 'USD' ? '#f57c00' : 'primary'}>
                            {selectedSalida.monto_formateado}
                          </Typography>
                        </Box>
                      </Box>

                      <Divider sx={{ my: 1 }} />

                      <DetailRow>
                        <DetailLabel>Caja Origen</DetailLabel>
                        <DetailValue>{selectedSalida.caja_origen_salida}</DetailValue>
                      </DetailRow>

                      <DetailRow>
                        <DetailLabel>Proveedor</DetailLabel>
                        <DetailValue>{selectedSalida.proveedor_salida}</DetailValue>
                      </DetailRow>

                      <DetailRow>
                        <DetailLabel>Tipo de Cambio</DetailLabel>
                        <DetailValue>${selectedSalida.tipo_de_cambio_salida}</DetailValue>
                      </DetailRow>

                      {/* Montos equivalentes - VERSIÓN MÁS MINIMALISTA */}
                      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                        {/* Equivalente en ARS */}
                        <Box
                          sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255, 153, 0, 0.08)',
                            p: 1.5,
                            borderRadius: 3,
                          }}
                        >
                          <Typography variant="caption" color="text.secondary" sx={{ mr: 1 }}>
                            Equiv. ARS
                          </Typography>
                          <Typography variant="body2" fontWeight="medium" color="#f57c00" sx={{ ml: 'auto' }}>
                            {new Intl.NumberFormat('es-AR', {
                              style: 'currency',
                              currency: 'ARS',
                              minimumFractionDigits: 2
                            }).format(selectedSalida.monto_en_ars_salida)}
                          </Typography>
                        </Box>

                        {/* Equivalente en USD */}
                        <Box
                          sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'rgba(25, 118, 210, 0.08)',
                            p: 1.5,
                            borderRadius: 3,
                          }}
                        >
                          <Typography variant="caption" color="text.secondary" sx={{ mr: 1 }}>
                            Equiv. USD
                          </Typography>
                          <Typography variant="body2" fontWeight="medium" color="#1976d2" sx={{ ml: 'auto' }}>
                            {new Intl.NumberFormat('es-AR', {
                              style: 'currency',
                              currency: 'USD',
                              minimumFractionDigits: 2
                            }).format(selectedSalida.monto_en_usd_salida)}
                          </Typography>
                        </Box>
                      </Box>
                    </DetailSection>

                    {/* Información del proyecto */}
                    <DetailSection>
                      <DetailSectionTitle>
                        <AssignmentIcon /> Información del Proyecto
                      </DetailSectionTitle>

                      <DetailRow>
                        <DetailLabel>Proyecto</DetailLabel>
                        <DetailValue>{selectedSalida.codigo_de_proyecto_salida}</DetailValue>
                      </DetailRow>

                      <DetailRow>
                        <DetailLabel>Concepto</DetailLabel>
                        <DetailValue>{selectedSalida.concepto_salidas}</DetailValue>
                      </DetailRow>

                      <DetailRow>
                        <DetailLabel>Etapa</DetailLabel>
                        <DetailValue>{selectedSalida.etapa_de_obra_salidas}</DetailValue>
                      </DetailRow>

                      <DetailRow>
                        <DetailLabel>Subetapa</DetailLabel>
                        <DetailValue>{selectedSalida.subetapa_de_obra_salidas}</DetailValue>
                      </DetailRow>{selectedSalida.descripcion_salida && (
                        <Box sx={{ mt: 2 }}>
                          <DetailLabel>Descripción</DetailLabel>
                          <Typography variant="body2" sx={{ mt: 0.5, whiteSpace: 'pre-wrap', p: 1.5, bgcolor: 'rgba(0,0,0,0.02)', borderRadius: 2 }}>
                            {selectedSalida.descripcion_salida}
                          </Typography>
                        </Box>
                      )}
                    </DetailSection>

                    {/* Información de Indexación */}
                    {selectedSalida.tiene_indexacion && (
                      <DetailSection>
                        <DetailSectionTitle>
                          <CurrencyExchangeIcon /> Información de Indexación
                        </DetailSectionTitle>

                        <DetailRow>
                          <DetailLabel>Tipo de Indexación</DetailLabel>
                          <DetailValue>{selectedSalida.tipo_indexacion}</DetailValue>
                        </DetailRow>

                        <DetailRow>
                          <DetailLabel>TC Indexado</DetailLabel>
                          <DetailValue>${selectedSalida.tc_indexado}</DetailValue>
                        </DetailRow>

                        <DetailRow>
                          <DetailLabel>Monto Indexado</DetailLabel>
                          <DetailValue color="secondary">${selectedSalida.monto_indexado}</DetailValue>
                        </DetailRow>
                      </DetailSection>
                    )}

                    {/* Documentos */}
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
                      {/* Comprobante */}
                      {selectedSalida.tiene_comprobante_salida && (
                        <DetailSection sx={{ flex: 1, minWidth: '45%' }}>
                          <DetailSectionTitle>
                            <ReceiptIcon /> Comprobante
                          </DetailSectionTitle>

                          <Box
                            sx={{
                              width: '100%',
                              borderRadius: 3,
                              overflow: 'hidden',
                              border: '1px solid rgba(0,0,0,0.1)',
                              mb: 1
                            }}
                            onClick={() => handleOpenImagePreview(selectedSalida.imagen_comprobante_salida)}
                          >
                            <img
                              src={selectedSalida.imagen_comprobante_salida}
                              alt="Comprobante"
                              style={{
                                width: '100%',
                                maxHeight: 200,
                                objectFit: 'contain',
                                backgroundColor: '#f9f9f9',
                                padding: 4
                              }}
                            />
                          </Box>

                          {selectedSalida.codigo_de_comprobante_salida && (
                            <Box sx={{
                              p: 1.5,
                              backgroundColor: 'rgba(46, 125, 50, 0.05)',
                              borderRadius: 2,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}>
                              <Typography variant="caption" fontFamily="monospace" noWrap sx={{ maxWidth: '80%' }}>
                                {selectedSalida.codigo_de_comprobante_salida}
                              </Typography>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigator.clipboard.writeText(selectedSalida.codigo_de_comprobante_salida);
                                  setSnackbar({
                                    open: true,
                                    message: 'Código copiado al portapapeles',
                                    severity: 'success'
                                  });
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          )}
                        </DetailSection>
                      )}

                      {/* Factura */}
                      {selectedSalida.tiene_factura_salida && (
                        <DetailSection sx={{ flex: 1, minWidth: '45%' }}>
                          <DetailSectionTitle>
                            <DescriptionIcon /> Factura
                          </DetailSectionTitle>

                          <Box
                            sx={{
                              width: '100%',
                              borderRadius: 3,
                              overflow: 'hidden',
                              border: '1px solid rgba(0,0,0,0.1)',
                              mb: 1
                            }}
                            onClick={() => handleOpenImagePreview(selectedSalida.imagen_factura_salida)}
                          >
                            <img
                              src={selectedSalida.imagen_factura_salida}
                              alt="Factura"
                              style={{
                                width: '100%',
                                maxHeight: 200,
                                objectFit: 'contain',
                                backgroundColor: '#f9f9f9',
                                padding: 4
                              }}
                            />
                          </Box>

                          {selectedSalida.codigo_de_factura_salida && (
                            <Box sx={{
                              p: 1.5,
                              backgroundColor: 'rgba(46, 125, 50, 0.05)',
                              borderRadius: 2,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              mb: 1
                            }}>
                              <Typography variant="caption" fontFamily="monospace" noWrap sx={{ maxWidth: '80%' }}>
                                {selectedSalida.codigo_de_factura_salida}
                              </Typography>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigator.clipboard.writeText(selectedSalida.codigo_de_factura_salida);
                                  setSnackbar({
                                    open: true,
                                    message: 'Código copiado al portapapeles',
                                    severity: 'success'
                                  });
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          )}

                          {selectedSalida.factura_a_nombre_de_salida && (
                            <Typography variant="body2" color="text.secondary">
                              A nombre de: <b>{selectedSalida.factura_a_nombre_de_salida}</b>
                            </Typography>
                          )}
                        </DetailSection>
                      )}
                    </Box>
                  </Box>
                </Box>
              </MobileDetailView>
            </Box>
          )}
        </Drawer>

        {/* Diálogo de confirmación de eliminación */}
        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
          PaperProps={{
            sx: {
              borderRadius: '24px',
              padding: 2,
              maxWidth: '85%'
            }
          }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
          >
            <Box sx={{ p: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box
                  sx={{
                    width: 48,
                    height: 48,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'rgba(211, 47, 47, 0.1)',
                    color: 'error.main',
                    mr: 2
                  }}
                >
                  <DeleteIcon fontSize="medium" />
                </Box>
                <Typography variant="h6">¿Eliminar salida?</Typography>
              </Box>

              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Esta acción eliminará permanentemente la salida de {selectedSalida?.monto_formateado} del {selectedSalida?.fecha_formateada}.
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button
                  variant="outlined"
                  onClick={() => setDeleteConfirmOpen(false)}
                  sx={{ borderRadius: 8, textTransform: 'none' }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setDeleteConfirmOpen(false);
                    // Animación de salida antes de eliminar
                    animateItemDelete();
                  }}
                  sx={{ borderRadius: 8, textTransform: 'none' }}
                >
                  Eliminar
                </Button>
              </Box>
            </Box>
          </motion.div>
        </Dialog>

        {/* Dialog para vista previa de imágenes */}
        <Dialog
          open={imagePreviewOpen}
          onClose={() => setImagePreviewOpen(false)}
          maxWidth="lg"
          PaperProps={{
            sx: {
              borderRadius: 3,
              bgcolor: 'rgba(0,0,0,0.9)',
              p: 0
            }
          }}
        >
          <IconButton
            onClick={() => setImagePreviewOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              bgcolor: 'rgba(0,0,0,0.3)',
              zIndex: 1
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
              width: '100vw',
              maxWidth: '100%'
            }}
          >
            {currentImage && (
              <motion.img
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
                src={currentImage}
                alt="Vista ampliada"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}
              />
            )}
          </Box>
        </Dialog>

        {/* Drawer para filtros */}
        <SwipeableDrawer
          anchor="bottom"
          open={openFiltersDrawer}
          onClose={() => setOpenFiltersDrawer(false)}
          onOpen={() => setOpenFiltersDrawer(true)}
          disableSwipeToOpen={false}
          swipeAreaWidth={30}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: {
              borderRadius: '28px 28px 0 0',
              maxHeight: '70vh'
            }
          }}
        >
          <SwipableFilterBox>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h6" fontWeight="medium">Filtrar Salidas</Typography>
              <IconButton onClick={() => setOpenFiltersDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Box>

            <FormSection>
              <StyledFormControl fullWidth>
                <InputLabel>Caja Origen</InputLabel>
                <Select
                  value={filterCajaOrigen}
                  onChange={(e) => setFilterCajaOrigen(e.target.value)}
                  label="Caja Origen"
                >
                  <MenuItem value="">Todas</MenuItem>
                  {cajasOrigen.map((caja) => (
                    <MenuItem key={caja} value={caja}>{caja}</MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Proveedor</InputLabel>
                <Select
                  value={filterProveedor}
                  onChange={(e) => setFilterProveedor(e.target.value)}
                  label="Proveedor"
                >
                  <MenuItem value="">Todos</MenuItem>
                  {proveedores.map((prov) => (
                    <MenuItem key={prov} value={prov}>{prov}</MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Proyecto</InputLabel>
                <Select
                  value={filterProyecto}
                  onChange={(e) => setFilterProyecto(e.target.value)}
                  label="Proyecto"
                >
                  <MenuItem value="">Todos</MenuItem>
                  {proyectos.map((proy) => (
                    <MenuItem key={proy} value={proy}>{proy}</MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              {/* Nuevo filtro de concepto */}
              <StyledFormControl fullWidth>
                <InputLabel>Concepto</InputLabel>
                <Select
                  value={filterConcepto}
                  onChange={(e) => setFilterConcepto(e.target.value)}
                  label="Concepto"
                >
                  <MenuItem value="">Todos</MenuItem>
                  {conceptos.map((conc) => (
                    <MenuItem key={conc} value={conc}>{conc}</MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Desde"
                      value={filterFechaDesde}
                      onChange={(newValue) => setFilterFechaDesde(newValue)}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          fullWidth
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <CalendarTodayIcon color="primary" fontSize="small" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Hasta"
                      value={filterFechaHasta}
                      onChange={(newValue) => setFilterFechaHasta(newValue)}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          fullWidth
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <CalendarTodayIcon color="primary" fontSize="small" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </FormSection>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Button
                variant="outlined"
                color="inherit"
                onClick={resetFilters}
                sx={{
                  width: '48%',
                  borderRadius: 20,
                  borderColor: 'rgba(0,0,0,0.2)',
                  color: 'text.secondary'
                }}
              >
                Limpiar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={applyFilterSelection}
                sx={{ width: '48%', borderRadius: 20 }}
              >
                Aplicar Filtros
              </Button>
            </Box>
          </SwipableFilterBox>
        </SwipeableDrawer>

        {/* Diálogo para búsqueda flotante */}
        <Dialog
          open={searchDialogOpen}
          onClose={() => setSearchDialogOpen(false)}
          PaperProps={{
            sx: {
              borderRadius: 3,
              width: '90%',
              maxWidth: '90%',
              p: 2
            }
          }}
        >
          <Box sx={{ p: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              Buscar salida
            </Typography>
            <TextField
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              variant="outlined"
              placeholder="Buscar..."
              sx={{ mb: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={() => {
                  setSearchDialogOpen(false);
                }}
                color="primary"
                sx={{ borderRadius: 20 }}
              >
                Buscar
              </Button>
            </Box>
          </Box>
        </Dialog>

        {/* Snackbar para notificaciones */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={{ mb: 7 }} // Para que aparezca por encima de la navegación
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{
              width: '100%',
              borderRadius: 20,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </MobileContainer>
    </>
  );
};

export default SalidasMobile;