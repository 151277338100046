// src/components/Proveedores.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
  Container,
  InputAdornment,
  IconButton,
  Menu,
  MenuItem as MenuItemMUI,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

const ProveedorDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  '& .MuiPaper-root': {
    borderRadius: 4,
    padding: theme.spacing(2),
    width: '400px',
    maxHeight: '80vh',
    overflowY: 'auto',
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
}));

const ProveedorCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  borderRadius: 4,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Proveedores = () => {
  const [proveedoresServicios, setProveedoresServicios] = useState([]);
  const [proveedoresCambio, setProveedoresCambio] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [newProveedor, setNewProveedor] = useState('');
  const [editValue, setEditValue] = useState('');
  const [editId, setEditId] = useState(null);
  const [tableType, setTableType] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [pageServicios, setPageServicios] = useState(0);
  const [pageCambio, setPageCambio] = useState(0);
  const itemsPerPage = 10;

  useEffect(() => {
    fetchProveedores();
  }, []);

  const fetchProveedores = async () => {
    const [cambioData, servicioData] = await Promise.all([
      supabase.from('proveedores_de_cambio_de_moneda').select('*'),
      supabase.from('proveedores_de_servicios').select('*'),
    ]);
    if (cambioData.error) console.log('Error fetching proveedores_de_cambio_de_moneda:', cambioData.error);
    else setProveedoresCambio(cambioData.data || []);
    if (servicioData.error) console.log('Error fetching proveedores_de_servicios:', servicioData.error);
    else setProveedoresServicios(servicioData.data || []);
  };

  const handleAddProveedor = async () => {
    if (!newProveedor.trim()) {
      alert('Por favor, ingresa un nombre válido para el proveedor.');
      return;
    }
    const table = tableType === 'servicio' ? 'proveedores_de_servicios' : 'proveedores_de_cambio_de_moneda';
    const column = tableType === 'servicio' ? 'proveedor_de_servicios' : 'proveedor_cambio_de_moneda';
    const { error } = await supabase
      .from(table)
      .insert([{ [column]: newProveedor.trim() }]);
    if (error) {
      console.log('Error adding proveedor:', error);
      alert('Error al agregar el proveedor: ' + error.message);
    } else {
      fetchProveedores();
      setOpenAddDialog(false);
      setNewProveedor('');
    }
  };

  const handleEdit = async () => {
    if (!editValue.trim()) {
      alert('Por favor, ingresa un nombre válido.');
      return;
    }
    const table = tableType === 'servicio' ? 'proveedores_de_servicios' : 'proveedores_de_cambio_de_moneda';
    const column = tableType === 'servicio' ? 'proveedor_de_servicios' : 'proveedor_cambio_de_moneda';
    const { error } = await supabase
      .from(table)
      .update({ [column]: editValue.trim() })
      .eq('id', editId);
    if (error) {
      console.log('Error updating proveedor:', error);
      alert('Error al actualizar: ' + error.message);
    } else {
      fetchProveedores();
      setOpenEditDialog(false);
      setEditValue('');
      setEditId(null);
      setTableType('');
    }
  };

  const handleDelete = async (id, type) => {
    const table = type === 'servicio' ? 'proveedores_de_servicios' : 'proveedores_de_cambio_de_moneda';
    const { error } = await supabase.from(table).delete().eq('id', id);
    if (error) {
      console.log('Error deleting proveedor:', error);
      alert('Error al eliminar: ' + error.message);
    } else {
      fetchProveedores();
      setAnchorEl(null);
    }
  };

  const handleMenuOpen = (event, id, type) => {
    setAnchorEl(event.currentTarget);
    setSelectedItemId(id);
    setTableType(type);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItemId(null);
  };

  const handleEditClick = (item, type) => {
    setEditId(item.id);
    setEditValue(type === 'servicio' ? item.proveedor_de_servicios : item.proveedor_cambio_de_moneda);
    setTableType(type);
    setOpenEditDialog(true);
    handleMenuClose();
  };

  const filteredServicios = proveedoresServicios.filter((proveedor) =>
    proveedor.proveedor_de_servicios.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const filteredCambio = proveedoresCambio.filter((proveedor) =>
    proveedor.proveedor_cambio_de_moneda.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedServicios = filteredServicios.slice(pageServicios * itemsPerPage, (pageServicios + 1) * itemsPerPage);
  const paginatedCambio = filteredCambio.slice(pageCambio * itemsPerPage, (pageCambio + 1) * itemsPerPage);

  const handleNextPageServicios = () => setPageServicios((prev) => prev + 1);
  const handlePrevPageServicios = () => setPageServicios((prev) => Math.max(prev - 1, 0));
  const handleNextPageCambio = () => setPageCambio((prev) => prev + 1);
  const handlePrevPageCambio = () => setPageCambio((prev) => Math.max(prev - 1, 0));

  return (
    <Container maxWidth="lg" sx={{ py: 3, px: 0, backgroundColor: '#ffffff' }}>
      <Typography variant="h4" fontWeight="bold" color="text.primary" gutterBottom>
        Proveedores
      </Typography>
      <Box sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
        <TextField
          label="Buscar Proveedor"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          variant="outlined"
          sx={{ mr: 2, flexGrow: 1 }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={() => { setOpenAddDialog(true); setTableType('servicio'); }}
          sx={{ mr: 1, borderRadius: 4, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#2e7d32' }, fontSize: '0.9rem', padding: '4px 12px' }}
        >
          Nuevo Proveedor
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => { setOpenAddDialog(true); setTableType('cambio'); }}
          sx={{ borderRadius: 4, borderColor: '#4caf50', color: '#4caf50', '&:hover': { borderColor: '#2e7d32', color: '#2e7d32' }, fontSize: '0.9rem', padding: '4px 12px' }}
        >
          Nuevo Proveedor Cambio
        </Button>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="h6" fontWeight="medium" color="text.primary" gutterBottom>
          Proveedores de Servicios
        </Typography>
        {paginatedServicios.map((proveedor) => (
          <ProveedorCard key={proveedor.id}>
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
              <Typography variant="body2" color="text.primary" sx={{ fontSize: '0.95rem' }}>
                {proveedor.proveedor_de_servicios}
              </Typography>
              <IconButton size="small" onClick={(e) => handleMenuOpen(e, proveedor.id, 'servicio')}>
                <MoreVertIcon fontSize="small" />
              </IconButton>
            </CardContent>
          </ProveedorCard>
        ))}
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            variant="outlined"
            onClick={handlePrevPageServicios}
            disabled={pageServicios === 0}
            sx={{ borderRadius: 4, fontSize: '0.9rem', padding: '4px 8px' }}
          >
            Anterior
          </Button>
          <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
            Página {pageServicios + 1} de {Math.ceil(filteredServicios.length / itemsPerPage)}
          </Typography>
          <Button
            variant="outlined"
            onClick={handleNextPageServicios}
            disabled={(pageServicios + 1) * itemsPerPage >= filteredServicios.length}
            sx={{ borderRadius: 4, fontSize: '0.9rem', padding: '4px 8px' }}
          >
            Siguiente
          </Button>
        </Box>
      </Box>

      <Box>
        <Typography variant="h6" fontWeight="medium" color="text.primary" gutterBottom>
          Proveedores de Cambio de Moneda
        </Typography>
        {paginatedCambio.map((proveedor) => (
          <ProveedorCard key={proveedor.id}>
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1 }}>
              <Typography variant="body2" color="text.primary" sx={{ fontSize: '0.95rem' }}>
                {proveedor.proveedor_cambio_de_moneda}
              </Typography>
              <IconButton size="small" onClick={(e) => handleMenuOpen(e, proveedor.id, 'cambio')}>
                <MoreVertIcon fontSize="small" />
              </IconButton>
            </CardContent>
          </ProveedorCard>
        ))}
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            variant="outlined"
            onClick={handlePrevPageCambio}
            disabled={pageCambio === 0}
            sx={{ borderRadius: 4, fontSize: '0.9rem', padding: '4px 8px' }}
          >
            Anterior
          </Button>
          <Typography variant="body2" sx={{ fontSize: '0.9rem' }}>
            Página {pageCambio + 1} de {Math.ceil(filteredCambio.length / itemsPerPage)}
          </Typography>
          <Button
            variant="outlined"
            onClick={handleNextPageCambio}
            disabled={(pageCambio + 1) * itemsPerPage >= filteredCambio.length}
            sx={{ borderRadius: 4, fontSize: '0.9rem', padding: '4px 8px' }}
          >
            Siguiente
          </Button>
        </Box>
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: { borderRadius: 4, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' },
        }}
      >
        <MenuItemMUI onClick={() => handleEditClick(proveedoresServicios.find(e => e.id === selectedItemId) || proveedoresCambio.find(e => e.id === selectedItemId), tableType)} sx={{ fontSize: '0.9rem' }}>
          Editar
        </MenuItemMUI>
        <MenuItemMUI onClick={() => { handleDelete(selectedItemId, tableType); handleMenuClose(); }} sx={{ fontSize: '0.9rem', color: 'error.main' }}>
          Eliminar
        </MenuItemMUI>
      </Menu>

      {/* Diálogo Agregar Proveedor */}
      <ProveedorDialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <DialogTitle>
          <Typography variant="h5" fontWeight="bold" color="text.primary" textAlign="center">
            {tableType === 'servicio' ? 'Nuevo Proveedor de Servicio' : 'Nuevo Proveedor de Cambio'}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            label={tableType === 'servicio' ? 'Nombre del Proveedor de Servicio' : 'Nombre del Proveedor de Cambio'}
            value={newProveedor}
            onChange={(e) => setNewProveedor(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button onClick={() => setOpenAddDialog(false)} color="inherit" sx={{ fontSize: '0.9rem', borderRadius: 4 }}>
              Cancelar
            </Button>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddProveedor}
              sx={{ borderRadius: 4, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#2e7d32' }, fontSize: '0.9rem', padding: '4px 12px' }}
            >
              Guardar
            </Button>
          </motion.div>
        </DialogActions>
      </ProveedorDialog>

      {/* Diálogo Editar Proveedor */}
      <ProveedorDialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>
          <Typography variant="h5" fontWeight="bold" color="text.primary" textAlign="center">
            Editar Proveedor
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            label={tableType === 'servicio' ? 'Nombre del Proveedor de Servicio' : 'Nombre del Proveedor de Cambio'}
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button onClick={() => setOpenEditDialog(false)} color="inherit" sx={{ fontSize: '0.9rem', borderRadius: 4 }}>
              Cancelar
            </Button>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEdit}
              sx={{ borderRadius: 4, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#2e7d32' }, fontSize: '0.9rem', padding: '4px 12px' }}
            >
              Guardar
            </Button>
          </motion.div>
        </DialogActions>
      </ProveedorDialog>
    </Container>
  );
};

export default Proveedores;