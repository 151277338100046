// src/pages/auth/AccessDenied.js
import React from 'react';
import { Container, Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const AccessDenied = () => {
  const navigate = useNavigate();
  const { isClient } = useAuth();

  const handleRedirect = () => {
    // Redireccionar según el rol
    if (isClient) {
      navigate('/client/dashboard');
    } else {
      navigate('/dashboard');
    }
  };

  return (
    <Container maxWidth="md">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '80vh',
          textAlign: 'center'
        }}
      >
        <Typography variant="h3" component="h1" gutterBottom color="error">
          Acceso Denegado
        </Typography>

        <Typography variant="h6" color="text.secondary" sx={{ mb: 4 }}>
          No tienes permisos para acceder a esta sección.
        </Typography>

        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleRedirect}
        >
          Ir al Dashboard
        </Button>
      </Box>
    </Container>
  );
};

export default AccessDenied;