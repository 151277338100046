import React, { useEffect, useState, useRef } from 'react';
import { supabase } from '../supabaseClient';
import { useAuth } from '../contexts/AuthContext';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Typography,
  IconButton,
  Container,
  Paper,
  Tooltip,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  InputAdornment,
  Collapse,
  Alert,
  Snackbar,
  CircularProgress,
  FormControlLabel,
  Switch,
  Tab,
  Tabs,
  Divider,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import DownloadIcon from '@mui/icons-material/Download';
import MoneyIcon from '@mui/icons-material/Money';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import SummarizeIcon from '@mui/icons-material/Summarize';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';

// Nuevas importaciones
import { uploadImage, formatImageUrl } from '../utils/imageUtils';

// Estilos 
const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 16,
  overflow: 'hidden',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.12)',
    transition: 'box-shadow 0.3s ease-in-out',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
  },
  '&:hover': {
    backgroundColor: 'rgba(46, 125, 50, 0.04)',
    transition: 'background-color 0.2s ease',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '12px 16px',
  fontSize: '0.875rem',
  textAlign: 'center',
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#f8f9fa',
  padding: '14px 16px',
  fontSize: '0.875rem',
  fontWeight: 'bold',
  color: '#2e7d32',
  borderBottom: '2px solid #e8f5e9',
  textAlign: 'center',
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: 'rgba(46, 125, 50, 0.1)',
  },
}));

const AddEntryDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiPaper-root': {
    borderRadius: 16,
    padding: theme.spacing(2),
    width: '600px',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
}));

const ImagePreviewDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
  '& .MuiPaper-root': {
    borderRadius: 8,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
}));

const FilterChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: '#e8f5e9',
  color: '#2e7d32',
  '&:hover': {
    backgroundColor: '#c8e6c9',
  },
  '& .MuiChip-deleteIcon': {
    color: '#2e7d32',
    '&:hover': {
      color: '#1b5e20',
    },
  },
}));

const ExportButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#2e7d32',
  color: 'white',
  padding: '8px 16px',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: '#1b5e20',
  },
  transition: 'background-color 0.3s ease',
}));

const AddButton = styled(IconButton)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#2e7d32',
  width: 40,
  height: 40,
  boxShadow: '0 4px 10px rgba(46, 125, 50, 0.3)',
  '&:hover': {
    backgroundColor: '#1b5e20',
    boxShadow: '0 6px 15px rgba(46, 125, 50, 0.4)',
  },
  transition: 'all 0.3s ease',
}));

const FileUploadBox = styled(Box)(({ theme }) => ({
  border: '2px dashed #c8e6c9',
  borderRadius: 8,
  padding: theme.spacing(3),
  textAlign: 'center',
  backgroundColor: '#f1f8e9',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    borderColor: '#2e7d32',
    backgroundColor: '#e8f5e9',
  },
}));

// Tabs estilizados
const StyledTabs = styled(Tabs)(({ theme }) => ({
  borderBottom: '1px solid #e0e0e0',
  '& .MuiTabs-indicator': {
    backgroundColor: '#2e7d32',
    height: 3,
  },
  '& .MuiTab-root': {
    textTransform: 'none',
    fontWeight: 'medium',
    fontSize: '0.9rem',
    '&.Mui-selected': {
      color: '#2e7d32',
      fontWeight: 'bold',
    },
  },
}));

// Panel de resumen financiero
const SummaryPanel = styled(Paper)(({ theme }) => ({
  borderRadius: 16,
  padding: theme.spacing(2),
  marginBottom: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgba(46, 125, 50, 0.04)',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.08)',
}));

const SummaryRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
  '&:last-child': {
    marginBottom: 0,
  }
}));

// ExpandMore icon estilizado
const ExpandMore = styled(IconButton)(({ theme, expanded }) => ({
  transform: expanded ? 'rotate(180deg)' : 'rotate(0deg)',
  transition: 'transform 0.3s ease',
  marginLeft: 'auto',
}));

// Componente para imagen
const ImagePreview = ({
  src,
  alt = 'Imagen',
  onClick,
  width = '100%',
  height = '100%',
  showZoom = true,
  thumbSize = 'medium' // 'small', 'medium', 'large'
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  // Mapeo de tamaños
  const sizes = {
    small: { width: 40, height: 40, iconSize: 20 },
    medium: { width: 80, height: 80, iconSize: 36 },
    large: { width: 120, height: 120, iconSize: 48 }
  };

  const size = sizes[thumbSize] || sizes.medium;

  useEffect(() => {
    if (!src) {
      setError(true);
      return;
    }

    console.log("Intentando cargar imagen desde:", src);
    setLoaded(false);
    setError(false);

    const img = new Image();

    img.onload = () => {
      console.log("Imagen cargada correctamente");
      setLoaded(true);
      setError(false);
    };

    img.onerror = () => {
      console.error('Error cargando imagen:', src);
      // Intentar URL directa sin parámetros de caché
      if (src.includes('?')) {
        const directUrl = src.split('?')[0];
        console.log("Intentando URL directa:", directUrl);

        const directImg = new Image();
        directImg.onload = () => {
          console.log("Imagen cargada con URL directa");
          setLoaded(true);
          setError(false);
        };
        directImg.onerror = () => {
          console.error("Error también con URL directa");
          setError(true);
          setLoaded(false);
        };
        directImg.src = directUrl;
      } else {
        setError(true);
        setLoaded(false);
      }
    };

    img.src = src;

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src, retryCount]);

  const handleRetry = (e) => {
    e.stopPropagation();
    setRetryCount(prev => prev + 1);
  };

  if (error) {
    return (
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      >
        <Box
          sx={{
            width: width !== '100%' ? width : size.width,
            height: height !== '100%' ? height : size.height,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f5f5f5',
            borderRadius: 1,
            border: '1px solid #e0e0e0',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <ReceiptIcon sx={{ color: '#9e9e9e', fontSize: size.iconSize }} />
          <Typography variant="caption" sx={{ fontSize: '10px', textAlign: 'center', mt: 0.5 }}>
            Error
          </Typography>

          <Tooltip title="Reintentar cargar imagen">
            <IconButton
              size="small"
              onClick={handleRetry}
              sx={{
                position: 'absolute',
                top: 2,
                right: 2,
                background: 'rgba(255,255,255,0.8)',
                width: 16,
                height: 16
              }}
            >
              <RefreshIcon sx={{ fontSize: 12 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </motion.div>
    );
  }

  if (!loaded) {
    return (
      <Box
        sx={{
          width: width !== '100%' ? width : size.width,
          height: height !== '100%' ? height : size.height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f5f5f5',
          borderRadius: 1,
          border: '1px solid #e0e0e0'
        }}
      >
        <CircularProgress size={size.iconSize / 2} sx={{ color: '#2e7d32' }} />
      </Box>
    );
  }

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      style={{ cursor: 'pointer', position: 'relative' }}
    >
      <Box
        sx={{
          width: width !== '100%' ? width : size.width,
          height: height !== '100%' ? height : size.height,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 1,
          border: '1px solid #e0e0e0'
        }}
        onClick={onClick}
      >
        <Box
          component="img"
          src={src}
          alt={alt}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 0.3s ease',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        />

        {showZoom && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 4,
              right: 4,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 24,
              height: 24,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              }
            }}
          >
            <ZoomInIcon sx={{ fontSize: 16, color: '#2e7d32' }} />
          </Box>
        )}
      </Box>
    </motion.div>
  );
};

// Componente principal
const Entradas = () => {
  // Obtener el contexto de autenticación
  const { user, userRole } = useAuth();

  const [entradas, setEntradas] = useState([]);
  const [filteredEntradas, setFilteredEntradas] = useState([]);
  const [cajasOrigen, setCajasOrigen] = useState([]);
  const [cajasFin, setCajasFin] = useState([]);
  const [conceptos, setConceptos] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [openAddEntry, setOpenAddEntry] = useState(false);
  const [openEditEntry, setOpenEditEntry] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [tabValue, setTabValue] = useState(0); // 0: Comprobante, 1: Factura
  const [nombreFacturas, setNombreFacturas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [userProyecto, setUserProyecto] = useState('');
  const [userCajasPermitidas, setUserCajasPermitidas] = useState([]);

  // Paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100); // Mostrar 100 filas por página

  // Filtros
  const [filters, setFilters] = useState({
    caja_origen: '',
    caja_fin: '',
    concepto: '',
    moneda: '',
    fecha_desde: null,
    fecha_hasta: null,
    tiene_comprobante: null,
    tiene_factura: null,
    codigo_comprobante: '',
    codigo_factura: '',
    proyecto: '',
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilters, setActiveFilters] = useState([]);

  // Estados para nuevas entradas
  const [fechaEntrada, setFechaEntrada] = useState(new Date());
  const [cajaOrigen, setCajaOrigen] = useState('');
  const [cajaFin, setCajaFin] = useState('');
  const [concepto, setConcepto] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [tipoCambio, setTipoCambio] = useState('');
  const [moneda, setMoneda] = useState('ARS');
  const [monto, setMonto] = useState('');
  const [proyecto, setProyecto] = useState('');
  const [filaEditando, setFilaEditando] = useState(null);
  const [nombreFactura, setNombreFactura] = useState('');

  // Estados para comprobante
  const [tieneComprobante, setTieneComprobante] = useState(false);
  const [imagenComprobante, setImagenComprobante] = useState(null);
  const [imagenComprobanteURL, setImagenComprobanteURL] = useState('');

  // Estados para factura
  const [tieneFactura, setTieneFactura] = useState(false);
  const [imagenFactura, setImagenFactura] = useState(null);
  const [imagenFacturaURL, setImagenFacturaURL] = useState('');

  // Estado para carga
  const [uploading, setUploading] = useState(false);

  // Estado para el resumen financiero
  const [summaryVisible, setSummaryVisible] = useState(false); // Inicialmente colapsado
  const [summaryData, setSummaryData] = useState({
    totalARS: 0,
    totalUSD: 0,
    totalARSEquivalente: 0,
    totalUSDEquivalente: 0
  });

  // Referencias a los inputs de archivos
  const comprobanteFileInputRef = useRef(null);
  const facturaFileInputRef = useRef(null);

  // Notificación
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Verificar si el usuario es cliente
  const isCliente = userRole === 'client' || localStorage.getItem('userRole') === 'client';

  // Función para obtener el tipo de cambio
  const fetchDolarBlue = async () => {
    try {
      const response = await fetch('https://dolarapi.com/v1/dolares/blue');
      if (!response.ok) throw new Error('Error al obtener el dólar blue');
      const data = await response.json();
      setTipoCambio(data.compra.toString());
    } catch (error) {
      console.error('Error fetching dólar blue:', error.message);
      setTipoCambio('1050'); // Valor por defecto en caso de error
    }
  };

  // Función para obtener el proyecto y las cajas permitidas para un cliente
  const fetchUserProyectoYCajas = async (email) => {
    if (!email) return;

    try {
      setLoading(true);
      // Obtener el proyecto del cliente
      const { data, error } = await supabase
        .from('proyectos')
        .select('codigo_de_proyecto, apellido_del_cliente, caja_proyecto')
        .eq('email_del_cliente', email)
        .single();

      if (error) {
        console.error('Error al obtener proyecto del cliente:', error);
        setSnackbar({
          open: true,
          message: 'Error al obtener información de tu proyecto',
          severity: 'error'
        });
        return;
      }

      if (data) {
        console.log('Datos del proyecto del cliente:', data);
        setUserProyecto(data.codigo_de_proyecto);

        // Guardar las cajas permitidas para este cliente
        const cajasPermitidas = [];
        if (data.apellido_del_cliente) cajasPermitidas.push(data.apellido_del_cliente);
        if (data.caja_proyecto) cajasPermitidas.push(data.caja_proyecto);

        setUserCajasPermitidas(cajasPermitidas);
        console.log('Cajas permitidas para este cliente:', cajasPermitidas);

        // Ahora que tenemos esta información, cargar las entradas filtradas
        await fetchEntradasForCliente(data.codigo_de_proyecto, cajasPermitidas);
      } else {
        setSnackbar({
          open: true,
          message: 'No se encontró un proyecto asociado a tu cuenta',
          severity: 'warning'
        });
      }
    } catch (error) {
      console.error('Error en fetchUserProyectoYCajas:', error);
    } finally {
      setLoading(false);
    }
  };

  // Función para obtener entradas filtradas para un cliente
  const fetchEntradasForCliente = async (proyectoCode, cajasPermitidas) => {
    if (!proyectoCode || !cajasPermitidas.length) {
      setEntradas([]);
      setFilteredEntradas([]);
      return;
    }

    try {
      setLoading(true);

      // Construir la consulta - entradas que tengan el código de proyecto del cliente
      // Y además cuya caja de origen esté en la lista de cajas permitidas
      let query = supabase
        .from('entradas')
        .select('*')
        .eq('codigo_de_proyecto_entrada', proyectoCode)
        .in('caja_origen_entrada', cajasPermitidas)
        .order('fecha_entrada', { ascending: false });

      const { data, error } = await query;

      if (error) {
        console.error('Error al obtener entradas para el cliente:', error);
        setSnackbar({
          open: true,
          message: 'Error al cargar entradas',
          severity: 'error'
        });
        return;
      }

      // Formatear los datos
      const formattedData = data.map(item => ({
        ...item,
        fecha_formateada: item.fecha_entrada ? format(new Date(item.fecha_entrada), 'dd/MM/yyyy') : '',
        monto_formateado: formatCurrency(item.monto_entrada, item.moneda_entrada),
        imagen_comprobante_entrada: item.imagen_comprobante_entrada ?
          formatImageUrl(item.imagen_comprobante_entrada) : null,
        imagen_factura_entrada: item.imagen_factura_entrada ?
          formatImageUrl(item.imagen_factura_entrada) : null
      }));

      console.log(`Se encontraron ${formattedData.length} entradas para el cliente`);

      setEntradas(formattedData);
      setFilteredEntradas(formattedData);

      // Cargar las opciones de filtros disponibles para este cliente
      await fetchCajasOrigenForCliente(cajasPermitidas);
      await fetchCajasFinFromEntradas(formattedData);
      await fetchConceptosFromEntradas(formattedData);

      // Calcular resumen financiero
      calculateSummary(formattedData);
    } catch (error) {
      console.error('Error en fetchEntradasForCliente:', error);
    } finally {
      setLoading(false);
    }
  };

  // Carga opciones de filtros para clientes desde las entradas disponibles
  const fetchCajasOrigenForCliente = async (cajasPermitidas) => {
    if (!cajasPermitidas.length) return;
    setCajasOrigen(cajasPermitidas);
  };

  const fetchCajasFinFromEntradas = async (entradas) => {
    if (!entradas || !entradas.length) return;

    // Extraer valores únicos de caja_fin_entrada
    const cajasSet = new Set(entradas.map(item => item.caja_fin_entrada).filter(Boolean));
    const cajasFinArray = Array.from(cajasSet);

    setCajasFin(cajasFinArray);
  };

  const fetchConceptosFromEntradas = async (entradas) => {
    if (!entradas || !entradas.length) return;

    // Extraer valores únicos de concepto_entrada
    const conceptosSet = new Set(entradas.map(item => item.concepto_entrada).filter(Boolean));
    const conceptosArray = Array.from(conceptosSet);

    setConceptos(conceptosArray);
  };

  useEffect(() => {
    if (isCliente && user && user.email) {
      // Si es cliente, obtener su proyecto y cajas permitidas
      fetchUserProyectoYCajas(user.email);
    } else {
      // Si es administrador, cargar todo normalmente
      fetchDolarBlue();
      fetchEntradas();
      fetchCajasOrigen();
      fetchCajasFin();
      fetchConceptos();
      fetchProyectos();
      fetchNombresFactura();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCliente, user]);

  useEffect(() => {
    if (!isCliente) {
      // Solo para administradores
      applyFilters();
      calculateSummary();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entradas, searchTerm, activeFilters, isCliente]);

  const fetchNombresFactura = async () => {
    try {
      const { data, error } = await supabase
        .from('facturas_a_nombre_de_salidas')
        .select('nombre_factura');

      if (error) console.error('Error fetching nombres factura:', error);
      else setNombreFacturas(data.map((item) => item.nombre_factura) || []);
    } catch (error) {
      console.error('Error en fetchNombresFactura:', error);
    }
  };

  const fetchProyectos = async () => {
    try {
      const { data, error } = await supabase.from('proyectos').select('codigo_de_proyecto');
      if (error) {
        console.error('Error fetching proyectos:', error);
        // Si no tienes una tabla de proyectos, intenta obtenerlos de entradas
        const { data: entradaData } = await supabase
          .from('entradas')
          .select('codigo_de_proyecto_entrada')
          .not('codigo_de_proyecto_entrada', 'is', null);

        if (entradaData) {
          // Extraer valores únicos
          const uniqueProyectos = [...new Set(entradaData.map(item => item.codigo_de_proyecto_entrada))];
          setProyectos(uniqueProyectos.filter(p => p)); // Filtrar valores nulos o undefined
        }
      } else {
        setProyectos(data.map((item) => item.codigo_de_proyecto) || []);
      }
    } catch (error) {
      console.error('Error en fetchProyectos:', error);
    }
  };

  const fetchEntradas = async () => {
    try {
      setLoading(true);
      // Ordenar por fecha descendente (más reciente primero)
      const { data, error } = await supabase
        .from('entradas')
        .select('*')
        .order('fecha_entrada', { ascending: false });

      if (error) {
        console.error('Error fetching entradas:', error.message);
      } else {
        // Formatear fechas y aplicar formatImageUrl a las URLs de imágenes
        const formattedData = data.map(item => ({
          ...item,
          fecha_formateada: item.fecha_entrada ? format(new Date(item.fecha_entrada), 'dd/MM/yyyy') : '',
          monto_formateado: formatCurrency(item.monto_entrada, item.moneda_entrada),
          imagen_comprobante_entrada: item.imagen_comprobante_entrada ?
            formatImageUrl(item.imagen_comprobante_entrada) : null,
          imagen_factura_entrada: item.imagen_factura_entrada ?
            formatImageUrl(item.imagen_factura_entrada) : null
        }));

        setEntradas(formattedData || []);
        setFilteredEntradas(formattedData || []);
      }
    } catch (error) {
      console.error('Error en fetchEntradas:', error);
    } finally {
      setLoading(false);
    }
  };

  const formatCurrency = (amount, currency) => {
    if (!amount) return '';
    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currency === 'USD' ? 'USD' : 'ARS',
      minimumFractionDigits: 2
    });
    return formatter.format(amount);
  };

  const fetchCajasOrigen = async () => {
    try {
      const { data, error } = await supabase.from('cajas_origen_activas').select('caja_origen');
      if (error) throw error;
      setCajasOrigen(data.map(caja => caja.caja_origen) || []);
    } catch (error) {
      console.error('Error fetching cajas_origen_activas:', error);
    }
  };

  const fetchCajasFin = async () => {
    try {
      const { data, error } = await supabase.from('cajas_cambios').select('caja');
      if (error) throw error;
      setCajasFin(data.map(item => item.caja) || []);
    } catch (error) {
      console.error('Error en fetchCajasFin:', error);
    }
  };

  const fetchConceptos = async () => {
    try {
      const { data, error } = await supabase.from('conceptos_de_entradas').select('concepto_de_entrada');
      if (error) throw error;
      setConceptos(data.map(item => item.concepto_de_entrada) || []);
    } catch (error) {
      console.error('Error en fetchConceptos:', error);
    }
  };

  // Calcular el resumen financiero
  const calculateSummary = (dataToCalculate = filteredEntradas) => {
    let totalARS = 0;
    let totalUSD = 0;
    let totalARSEquivalente = 0;
    let totalUSDEquivalente = 0;

    dataToCalculate.forEach(entrada => {
      const monto = parseFloat(entrada.monto_entrada || 0);
      const tipoCambio = parseFloat(entrada.tipo_de_cambio_entrada || 0);

      if (entrada.moneda_entrada === 'ARS') {
        totalARS += monto;
        // Calcular equivalente en USD (solo si hay tipo de cambio válido)
        if (tipoCambio > 0) {
          totalUSDEquivalente += monto / tipoCambio;
        }
        // El monto en ARS es el mismo
        totalARSEquivalente += monto;
      } else if (entrada.moneda_entrada === 'USD') {
        totalUSD += monto;
        // Calcular equivalente en ARS (solo si hay tipo de cambio válido)
        if (tipoCambio > 0) {
          totalARSEquivalente += monto * tipoCambio;
        }
        // El monto en USD es el mismo
        totalUSDEquivalente += monto;
      }
    });

    setSummaryData({
      totalARS: totalARS.toFixed(2),
      totalUSD: totalUSD.toFixed(2),
      totalARSEquivalente: totalARSEquivalente.toFixed(2),
      totalUSDEquivalente: totalUSDEquivalente.toFixed(2)
    });
  };

  const handleComprobanteFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Verificar tipo de archivo
      if (!file.type.match('image.*')) {
        setSnackbar({
          open: true,
          message: 'Por favor, selecciona un archivo de imagen válido',
          severity: 'error'
        });
        return;
      }

      // Verificar tamaño (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setSnackbar({
          open: true,
          message: 'La imagen debe ser menor a 5MB',
          severity: 'error'
        });
        return;
      }

      setImagenComprobante(file);
      setTieneComprobante(true);

      // Crear URL temporal para vista previa
      const previewURL = URL.createObjectURL(file);
      setImagenComprobanteURL(previewURL);
    }
  };

  const handleFacturaFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Verificar tipo de archivo
      if (!file.type.match('image.*')) {
        setSnackbar({
          open: true,
          message: 'Por favor, selecciona un archivo de imagen válido',
          severity: 'error'
        });
        return;
      }

      // Verificar tamaño (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setSnackbar({
          open: true,
          message: 'La imagen debe ser menor a 5MB',
          severity: 'error'
        });
        return;
      }

      setImagenFactura(file);
      setTieneFactura(true);

      // Crear URL temporal para vista previa
      const previewURL = URL.createObjectURL(file);
      setImagenFacturaURL(previewURL);
    }
  };

  const handleComprobanteUpload = () => {
    comprobanteFileInputRef.current.click();
  };

  const handleFacturaUpload = () => {
    facturaFileInputRef.current.click();
  };

  const removeComprobanteImage = () => {
    setImagenComprobante(null);
    setImagenComprobanteURL('');
    setTieneComprobante(false);

    // Limpiar el input de archivo
    if (comprobanteFileInputRef.current) {
      comprobanteFileInputRef.current.value = '';
    }
  };

  const removeFacturaImage = () => {
    setImagenFactura(null);
    setImagenFacturaURL('');
    setTieneFactura(false);

    // Limpiar el input de archivo
    if (facturaFileInputRef.current) {
      facturaFileInputRef.current.value = '';
    }
  };

  const handleAgregarEntrada = async () => {
    if (!fechaEntrada || !cajaOrigen || !cajaFin || !tipoCambio || !moneda || !monto) {
      setSnackbar({
        open: true,
        message: 'Por favor, completa todos los campos obligatorios',
        severity: 'error'
      });
      return;
    }

    try {
      setUploading(true);

      // Subir comprobante si existe
      let comprobanteData = null;

      if (tieneComprobante && imagenComprobante) {
        try {
          // Pasar el código de proyecto
          comprobanteData = await uploadImage(imagenComprobante, {
            tipo: 'entrada',
            referencia: cajaOrigen,
            concepto: concepto,
            proyecto: proyecto
          });
        } catch (uploadError) {
          console.error('Error al subir el comprobante:', uploadError);
          setSnackbar({
            open: true,
            message: 'Error al subir el comprobante: ' + uploadError.message,
            severity: 'error'
          });
          setUploading(false);
          return;
        }
      }

      // Subir factura si existe
      let facturaData = null;

      if (tieneFactura && imagenFactura) {
        try {
          // Pasar el código de proyecto
          facturaData = await uploadImage(imagenFactura, {
            tipo: 'entrada_factura',
            referencia: cajaOrigen,
            concepto: concepto,
            proyecto: proyecto
          });
        } catch (uploadError) {
          console.error('Error al subir la factura:', uploadError);
          setSnackbar({
            open: true,
            message: 'Error al subir la factura: ' + uploadError.message,
            severity: 'error'
          });
          setUploading(false);
          return;
        }
      }

      // Insertar entrada en la base de datos
      const { error } = await supabase.from('entradas').insert([
        {
          fecha_entrada: fechaEntrada.toISOString(),
          caja_origen_entrada: cajaOrigen,
          caja_fin_entrada: cajaFin,
          concepto_entrada: concepto,
          descripción_entrada: descripcion,
          tipo_de_cambio_entrada: tipoCambio,
          moneda_entrada: moneda,
          monto_entrada: monto,
          codigo_de_proyecto_entrada: proyecto,
          // Comprobante
          tiene_comprobante_entrada: tieneComprobante,
          imagen_comprobante_entrada: comprobanteData ? comprobanteData.url : null,
          codigo_de_comprobante_entrada: comprobanteData ? comprobanteData.codigo : null,
          ruta_archivo_comprobante: comprobanteData ? comprobanteData.ruta : null,
          // Factura
          tiene_factura_entrada: tieneFactura,
          imagen_factura_entrada: facturaData ? facturaData.url : null,
          codigo_de_factura_entrada: facturaData ? facturaData.codigo : null,
          ruta_archivo_factura: facturaData ? facturaData.ruta : null,
          factura_a_nombre_de_entrada: nombreFactura
        },
      ]);

      if (error) {
        console.error('Error adding entrada:', error.message);
        setSnackbar({
          open: true,
          message: 'Error al agregar entrada: ' + error.message,
          severity: 'error'
        });
      } else {
        if (isCliente) {
          fetchEntradasForCliente(userProyecto, userCajasPermitidas);
        } else {
          fetchEntradas();
        }
        setOpenAddEntry(false);
        resetForm();
        setSnackbar({
          open: true,
          message: 'Entrada agregada con éxito',
          severity: 'success'
        });
      }
    } catch (error) {
      console.error('Error en el proceso de agregar entrada:', error);
      setSnackbar({
        open: true,
        message: 'Error al procesar la solicitud',
        severity: 'error'
      });
    } finally {
      setUploading(false);
    }
  };

  const handleEditarEntrada = (fila) => {
    setFilaEditando({ ...fila });
    setTieneComprobante(fila.tiene_comprobante_entrada || false);
    setImagenComprobanteURL(fila.imagen_comprobante_entrada || '');
    setTieneFactura(fila.tiene_factura_entrada || false);
    setImagenFacturaURL(fila.imagen_factura_entrada || '');
    setTabValue(0); // Resetear a la pestaña de comprobante por defecto
    setOpenEditEntry(true);
  };

  const handleEliminarEntrada = async (id, rutaArchivoComprobante, rutaArchivoFactura) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar esta entrada?')) {
      try {
        // Si tiene comprobante, eliminarlo del storage
        if (rutaArchivoComprobante) {
          try {
            await supabase.storage
              .from('Comprobantes').remove([rutaArchivoComprobante]);
          } catch (deleteError) {
            console.error('Error eliminando comprobante del storage:', deleteError);
            // Continuamos aunque falle la eliminación del archivo
          }
        }

        // Si tiene factura, eliminarla del storage
        if (rutaArchivoFactura) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([rutaArchivoFactura]);
          } catch (deleteError) {
            console.error('Error eliminando factura del storage:', deleteError);
            // Continuamos aunque falle la eliminación del archivo
          }
        }

        // Eliminar el registro de la base de datos
        const { error } = await supabase.from('entradas').delete().eq('id', id);

        if (error) {
          console.error('Error deleting entrada:', error.message);
          setSnackbar({
            open: true,
            message: 'Error al eliminar entrada: ' + error.message,
            severity: 'error'
          });
        } else {
          if (isCliente) {
            fetchEntradasForCliente(userProyecto, userCajasPermitidas);
          } else {
            fetchEntradas();
          }
          setSnackbar({
            open: true,
            message: 'Entrada eliminada con éxito',
            severity: 'success'
          });
        }
      } catch (error) {
        console.error('Error en el proceso de eliminación:', error);
        setSnackbar({
          open: true,
          message: 'Error al procesar la solicitud',
          severity: 'error'
        });
      }
    }
  };

  const handleUpdateEntrada = async () => {
    try {
      setUploading(true);

      // Gestionar comprobante
      let comprobante = {
        url: filaEditando.imagen_comprobante_entrada,
        codigo: filaEditando.codigo_de_comprobante_entrada,
        ruta: filaEditando.ruta_archivo_comprobante
      };

      if (tieneComprobante && imagenComprobante) {
        // Eliminar la imagen anterior si existe
        if (comprobante.url && comprobante.ruta) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([comprobante.ruta]);
          } catch (deleteError) {
            console.error('Error eliminando comprobante anterior:', deleteError);
          }
        }

        // Subir el nuevo comprobante
        try {
          const comprobanteData = await uploadImage(imagenComprobante, {
            tipo: 'entrada',
            referencia: filaEditando.caja_origen_entrada,
            concepto: filaEditando.concepto_entrada,
            proyecto: filaEditando.codigo_de_proyecto_entrada
          });

          comprobante = {
            url: comprobanteData.url,
            codigo: comprobanteData.codigo,
            ruta: comprobanteData.ruta
          };
        } catch (uploadError) {
          console.error('Error al subir el nuevo comprobante:', uploadError);
          setSnackbar({
            open: true,
            message: 'Error al subir el nuevo comprobante',
            severity: 'error'
          });
          setUploading(false);
          return;
        }
      } else if (!tieneComprobante) {
        // Si se quitó el comprobante, eliminar la imagen
        if (comprobante.url && comprobante.ruta) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([comprobante.ruta]);
          } catch (deleteError) {
            console.error('Error eliminando comprobante:', deleteError);
          }
        }

        comprobante = { url: null, codigo: null, ruta: null };
      }

      // Gestionar factura
      let factura = {
        url: filaEditando.imagen_factura_entrada,
        codigo: filaEditando.codigo_de_factura_entrada,
        ruta: filaEditando.ruta_archivo_factura
      };

      if (tieneFactura && imagenFactura) {
        // Eliminar la factura anterior si existe
        if (factura.url && factura.ruta) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([factura.ruta]);
          } catch (deleteError) {
            console.error('Error eliminando factura anterior:', deleteError);
          }
        }

        // Subir la nueva factura
        try {
          const facturaData = await uploadImage(imagenFactura, {
            tipo: 'entrada_factura',
            referencia: filaEditando.caja_origen_entrada,
            concepto: filaEditando.concepto_entrada,
            proyecto: filaEditando.codigo_de_proyecto_entrada
          });

          factura = {
            url: facturaData.url,
            codigo: facturaData.codigo,
            ruta: facturaData.ruta
          };
        } catch (uploadError) {
          console.error('Error al subir la nueva factura:', uploadError);
          setSnackbar({
            open: true,
            message: 'Error al subir la nueva factura',
            severity: 'error'
          });
          setUploading(false);
          return;
        }
      } else if (!tieneFactura) {
        // Si se quitó la factura, eliminar la imagen
        if (factura.url && factura.ruta) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([factura.ruta]);
          } catch (deleteError) {
            console.error('Error eliminando factura:', deleteError);
          }
        }

        factura = { url: null, codigo: null, ruta: null };
      }

      // Actualizar la entrada en la base de datos
      const { error } = await supabase
        .from('entradas')
        .update({
          fecha_entrada: filaEditando.fecha_entrada,
          caja_origen_entrada: filaEditando.caja_origen_entrada,
          caja_fin_entrada: filaEditando.caja_fin_entrada,
          concepto_entrada: filaEditando.concepto_entrada,
          descripción_entrada: filaEditando.descripción_entrada,
          tipo_de_cambio_entrada: filaEditando.tipo_de_cambio_entrada,
          moneda_entrada: filaEditando.moneda_entrada,
          monto_entrada: filaEditando.monto_entrada,
          codigo_de_proyecto_entrada: filaEditando.codigo_de_proyecto_entrada,
          // Comprobante
          tiene_comprobante_entrada: tieneComprobante,
          imagen_comprobante_entrada: comprobante.url,
          codigo_de_comprobante_entrada: comprobante.codigo,
          ruta_archivo_comprobante: comprobante.ruta,
          // Factura
          tiene_factura_entrada: tieneFactura,
          imagen_factura_entrada: factura.url,
          codigo_de_factura_entrada: factura.codigo,
          ruta_archivo_factura: factura.ruta,
          factura_a_nombre_de_entrada: filaEditando.factura_a_nombre_de_entrada
        })
        .eq('id', filaEditando.id);

      if (error) {
        console.error('Error updating entrada:', error.message);
        setSnackbar({
          open: true,
          message: 'Error al actualizar entrada: ' + error.message,
          severity: 'error'
        });
      } else {
        if (isCliente) {
          fetchEntradasForCliente(userProyecto, userCajasPermitidas);
        } else {
          fetchEntradas();
        }
        setOpenEditEntry(false);
        setSnackbar({
          open: true,
          message: 'Entrada actualizada con éxito',
          severity: 'success'
        });
      }
    } catch (error) {
      console.error('Error en el proceso de actualización:', error);
      setSnackbar({
        open: true,
        message: 'Error al procesar la solicitud',
        severity: 'error'
      });
    } finally {
      setUploading(false);
    }
  };

  const adjustTipoCambio = (amount) => {
    const currentValue = parseFloat(tipoCambio) || 0;
    const newValue = Math.max(0, currentValue + amount);
    setTipoCambio(newValue.toString());
  };

  const resetForm = () => {
    setFechaEntrada(new Date());
    setCajaOrigen('');
    setCajaFin('');
    setConcepto('');
    setDescripcion('');
    setTipoCambio('');
    setMoneda('ARS');
    setMonto('');
    setProyecto('');
    setNombreFactura('');
    // Resetear comprobante
    setTieneComprobante(false);
    setImagenComprobante(null);
    setImagenComprobanteURL('');
    if (comprobanteFileInputRef.current) {
      comprobanteFileInputRef.current.value = '';
    }
    // Resetear factura
    setTieneFactura(false);
    setImagenFactura(null);
    setImagenFacturaURL('');
    if (facturaFileInputRef.current) {
      facturaFileInputRef.current.value = '';
    }
    // Resetear pestaña
    setTabValue(0);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const applyFilters = () => {
    let filtered = [...entradas];

    // Búsqueda global
    if (searchTerm) {
      filtered = filtered.filter(item =>
        Object.values(item).some(value =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Aplicar filtros activos
    filtered = filtered.filter(item => {
      return activeFilters.every(filter => {
        if (filter.type === 'fecha_desde' && filter.value) {
          return new Date(item.fecha_entrada) >= new Date(filter.value);
        } else if (filter.type === 'fecha_hasta' && filter.value) {
          return new Date(item.fecha_entrada) <= new Date(filter.value);
        } else if (filter.type === 'tiene_comprobante' && filter.value !== null) {
          return item.tiene_comprobante_entrada === filter.value;
        } else if (filter.type === 'tiene_factura' && filter.value !== null) {
          return item.tiene_factura_entrada === filter.value;
        } else if (filter.type === 'codigo_comprobante' && filter.value) {
          return (
            item.codigo_de_comprobante_entrada &&
            item.codigo_de_comprobante_entrada.toLowerCase().includes(filter.value.toLowerCase())
          );
        } else if (filter.type === 'codigo_factura' && filter.value) {
          return (
            item.codigo_de_factura_entrada &&
            item.codigo_de_factura_entrada.toLowerCase().includes(filter.value.toLowerCase())
          );
        } else if (filter.type === 'proyecto' && filter.value) {
          return item.codigo_de_proyecto_entrada === filter.value;
        } else if (filter.value) {
          const fieldMap = {
            caja_origen: 'caja_origen_entrada',
            caja_fin: 'caja_fin_entrada',
            concepto: 'concepto_entrada',
            moneda: 'moneda_entrada',
          };
          const field = fieldMap[filter.type];
          if (field) {
            return item[field] === filter.value;
          }
        }
        return true; // Si no se aplica ningún filtro específico, incluir el elemento
      });
    });

    setFilteredEntradas(filtered);
    calculateSummary(filtered);
  };

  const addFilter = () => {
    const newFilters = [];

    if (filters.caja_origen) {
      newFilters.push({ type: 'caja_origen', value: filters.caja_origen, label: `Caja Origen: ${filters.caja_origen}` });
    }

    if (filters.caja_fin) {
      newFilters.push({ type: 'caja_fin', value: filters.caja_fin, label: `Caja Fin: ${filters.caja_fin}` });
    }

    if (filters.concepto) {
      newFilters.push({ type: 'concepto', value: filters.concepto, label: `Concepto: ${filters.concepto}` });
    }

    if (filters.moneda) {
      newFilters.push({ type: 'moneda', value: filters.moneda, label: `Moneda: ${filters.moneda}` });
    }

    if (filters.codigo_comprobante) {
      newFilters.push({
        type: 'codigo_comprobante',
        value: filters.codigo_comprobante,
        label: `Código Comprobante: ${filters.codigo_comprobante}`
      });
    }

    if (filters.codigo_factura) {
      newFilters.push({
        type: 'codigo_factura',
        value: filters.codigo_factura,
        label: `Código Factura: ${filters.codigo_factura}`
      });
    }

    if (filters.proyecto) {
      newFilters.push({
        type: 'proyecto',
        value: filters.proyecto,
        label: `Proyecto: ${filters.proyecto}`
      });
    }

    if (filters.fecha_desde) {
      newFilters.push({ type: 'fecha_desde', value: filters.fecha_desde, label: `Desde: ${format(new Date(filters.fecha_desde), 'dd/MM/yyyy')}` });
    }

    if (filters.fecha_hasta) {
      newFilters.push({ type: 'fecha_hasta', value: filters.fecha_hasta, label: `Hasta: ${format(new Date(filters.fecha_hasta), 'dd/MM/yyyy')}` });
    }

    if (filters.tiene_comprobante !== null) {
      newFilters.push({
        type: 'tiene_comprobante',
        value: filters.tiene_comprobante,
        label: `Comprobante: ${filters.tiene_comprobante ? 'Con comprobante' : 'Sin comprobante'}`
      });
    }

    if (filters.tiene_factura !== null) {
      newFilters.push({
        type: 'tiene_factura',
        value: filters.tiene_factura,
        label: `Factura: ${filters.tiene_factura ? 'Con factura' : 'Sin factura'}`
      });
    }

    setActiveFilters([...activeFilters, ...newFilters]);

    // Resetear los filtros
    setFilters({
      caja_origen: '',
      caja_fin: '',
      concepto: '',
      moneda: '',
      fecha_desde: null,
      fecha_hasta: null,
      tiene_comprobante: null,
      tiene_factura: null,
      codigo_comprobante: '',
      codigo_factura: '',
      proyecto: '',
    });

    setOpenFilters(false);
  };

  const removeFilter = (index) => {
    const updatedFilters = [...activeFilters];
    updatedFilters.splice(index, 1);
    setActiveFilters(updatedFilters);
  };

  const removeAllFilters = () => {
    setActiveFilters([]);
    setSearchTerm('');
  };

  const exportToCSV = () => {
    // Preparar los datos para exportación
    const csvContent = [
      // Encabezados
      ['ID', 'Fecha', 'Caja Origen', 'Caja Fin', 'Concepto', 'Descripción', 'Tipo de Cambio', 'Moneda', 'Monto',
        'Monto ARS', 'Monto USD', 'Proyecto', 'Tiene Comprobante', 'Código Comprobante',
        'Tiene Factura', 'Código Factura', 'A Nombre De'],
      // Datos
      ...filteredEntradas.map(entrada => [
        entrada.id,
        entrada.fecha_formateada,
        entrada.caja_origen_entrada,
        entrada.caja_fin_entrada,
        entrada.concepto_entrada,
        entrada.descripción_entrada,
        entrada.tipo_de_cambio_entrada,
        entrada.moneda_entrada,
        entrada.monto_entrada,
        entrada.monto_en_ars_entrada,
        entrada.monto_en_usd_entrada,
        entrada.codigo_de_proyecto_entrada || '',
        entrada.tiene_comprobante_entrada ? 'Sí' : 'No',
        entrada.codigo_de_comprobante_entrada || '',
        entrada.tiene_factura_entrada ? 'Sí' : 'No',
        entrada.codigo_de_factura_entrada || '',
        entrada.factura_a_nombre_de_entrada || ''
      ])
    ].map(row => row.join(',')).join('\n');

    // Crear un blob y descargar
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `entradas_${format(new Date(), 'yyyyMMdd')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShowImage = (entrada, tipo = 'comprobante') => {
    // Si es un objeto completo de entrada, extraer la información necesaria
    if (typeof entrada === 'object' && entrada !== null && !Array.isArray(entrada)) {
      if (tipo === 'comprobante') {
        setCurrentImage({
          url: entrada.imagen_comprobante_entrada,
          codigo: entrada.codigo_de_comprobante_entrada,
          fecha: entrada.fecha_formateada,
          concepto: entrada.concepto_entrada,
          tipo: 'Comprobante'
        });
      } else {
        setCurrentImage({
          url: entrada.imagen_factura_entrada,
          codigo: entrada.codigo_de_factura_entrada,
          fecha: entrada.fecha_formateada,
          concepto: entrada.concepto_entrada,
          nombreFactura: entrada.factura_a_nombre_de_entrada,
          tipo: 'Factura'
        });
      }
    } else {
      // Si es solo la URL, usarla directamente
      setCurrentImage({ url: entrada, tipo: tipo === 'comprobante' ? 'Comprobante' : 'Factura' });
    }
    setOpenImagePreview(true);
  };

  // Manejar el cambio de pestañas
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Toggle para el panel de resumen
  const handleToggleSummary = () => {
    setSummaryVisible(!summaryVisible);
  };

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" fontWeight="bold" color="#2e7d32">
              Entradas
            </Typography>

            {/* Solo mostrar botón de agregar para administradores */}
            {!isCliente && (
              <Tooltip title="Agregar nueva entrada">
                <AddButton
                  onClick={() => setOpenAddEntry(true)}
                  sx={{ ml: 2 }}
                >
                  <AddIcon />
                </AddButton>
              </Tooltip>
            )}
          </Box>

          {/* Mostrar botón de exportar para todos */}
          <Tooltip title="Exportar a CSV">
            <ExportButton
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={exportToCSV}
            >
              Exportar
            </ExportButton>
          </Tooltip>
        </Box>

        {/* Resumen financiero - Con botón de expansión/colapso */}
        <AnimatePresence>
          <Box sx={{ mb: 3 }}>
            <Paper
              sx={{ p: 2, borderRadius: 2, cursor: 'pointer' }}
              onClick={handleToggleSummary}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <SummarizeIcon sx={{ mr: 1, color: 'primary.main' }} />
                  <Typography variant="subtitle1" fontWeight="bold">
                    Resumen Financiero
                  </Typography>
                </Box>
                <ExpandMore
                  expanded={summaryVisible}
                  aria-expanded={summaryVisible}
                  aria-label="mostrar detalle"
                >
                  <ExpandMoreIcon />
                </ExpandMore>
              </Box>
            </Paper>

            <Collapse in={summaryVisible} timeout="auto">
              <SummaryPanel>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      <SummaryRow>
                        <Typography variant="body2" color="text.secondary">Total ARS:</Typography>
                        <Typography variant="body1" fontWeight="bold" color="primary.main">
                          {formatCurrency(summaryData.totalARS, 'ARS')}
                        </Typography>
                      </SummaryRow>
                      <SummaryRow>
                        <Typography variant="body2" color="text.secondary">Total USD:</Typography>
                        <Typography variant="body1" fontWeight="bold" color="#f57c00">
                          {formatCurrency(summaryData.totalUSD, 'USD')}
                        </Typography>
                      </SummaryRow>
                    </Box>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box>
                      <SummaryRow>
                        <Typography variant="body2" color="text.secondary">Equivalente en ARS:</Typography>
                        <Typography variant="body1" fontWeight="bold" color="primary.main">
                          {formatCurrency(summaryData.totalARSEquivalente, 'ARS')}
                        </Typography>
                      </SummaryRow>
                      <SummaryRow>
                        <Typography variant="body2" color="text.secondary">Equivalente en USD:</Typography>
                        <Typography variant="body1" fontWeight="bold" color="#f57c00">
                          {formatCurrency(summaryData.totalUSDEquivalente, 'USD')}
                        </Typography>
                      </SummaryRow>
                    </Box>
                  </Grid>
                </Grid>
              </SummaryPanel>
            </Collapse>
          </Box>
        </AnimatePresence>

        {/* Barra de búsqueda y filtros */}
        <StyledPaper elevation={0} sx={{ mb: 3, p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
            <TextField
              placeholder="Buscar entradas..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                flex: 1,
                minWidth: 250,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8,
                  '&:hover fieldset': {
                    borderColor: '#2e7d32',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#2e7d32' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<FilterListIcon />}
              onClick={() => setOpenFilters(!openFilters)}
              sx={{
                borderRadius: 8,
                borderColor: '#2e7d32',
                color: '#2e7d32',
                '&:hover': {
                  borderColor: '#1b5e20',
                  backgroundColor: 'rgba(46, 125, 50, 0.04)',
                },
              }}
            >
              Filtros
            </Button>

            {activeFilters.length > 0 && (
              <Button
                variant="text"
                size="small"
                onClick={removeAllFilters}
                sx={{
                  color: '#2e7d32',
                  '&:hover': { backgroundColor: 'rgba(46, 125, 50, 0.04)' },
                }}
              >
                Limpiar filtros
              </Button>
            )}
          </Box>

          <Collapse in={openFilters}>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Caja Origen</InputLabel>
                    <Select
                      value={filters.caja_origen}
                      onChange={(e) => setFilters({ ...filters, caja_origen: e.target.value })}
                      label="Caja Origen"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300
                          }
                        }
                      }}
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {cajasOrigen.map((caja) => (
                        <MenuItem key={caja} value={caja}>{caja}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Caja Fin</InputLabel>
                    <Select
                      value={filters.caja_fin}
                      onChange={(e) => setFilters({ ...filters, caja_fin: e.target.value })}
                      label="Caja Fin"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300
                          }
                        }
                      }}
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {cajasFin.map((caja) => (
                        <MenuItem key={caja} value={caja}>{caja}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Concepto</InputLabel>
                    <Select
                      value={filters.concepto}
                      onChange={(e) => setFilters({ ...filters, concepto: e.target.value })}
                      label="Concepto"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            maxHeight: 300
                          }
                        }
                      }}
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {conceptos.map((conc) => (
                        <MenuItem key={conc} value={conc}>{conc}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Moneda</InputLabel>
                    <Select
                      value={filters.moneda}
                      onChange={(e) => setFilters({ ...filters, moneda: e.target.value })}
                      label="Moneda"
                    >
                      <MenuItem value="">Todas</MenuItem>
                      <MenuItem value="ARS">ARS</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Documentos</InputLabel>
                    <Select
                      value={filters.tiene_comprobante === null ? '' : filters.tiene_comprobante}
                      onChange={(e) => {
                        const value = e.target.value === '' ? null : e.target.value === 'true';
                        setFilters({ ...filters, tiene_comprobante: value });
                      }}
                      label="Documentos"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      <MenuItem value="true">Con comprobante</MenuItem>
                      <MenuItem value="false">Sin comprobante</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Facturas</InputLabel>
                    <Select
                      value={filters.tiene_factura === null ? '' : filters.tiene_factura}
                      onChange={(e) => {
                        const value = e.target.value === '' ? null : e.target.value === 'true';
                        setFilters({ ...filters, tiene_factura: value });
                      }}
                      label="Facturas"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      <MenuItem value="true">Con factura</MenuItem>
                      <MenuItem value="false">Sin factura</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Código de comprobante"
                    value={filters.codigo_comprobante || ''}
                    onChange={(e) => setFilters({ ...filters, codigo_comprobante: e.target.value })}
                    placeholder="Buscar por código"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Código de factura"
                    value={filters.codigo_factura || ''}
                    onChange={(e) => setFilters({ ...filters, codigo_factura: e.target.value })}
                    placeholder="Buscar por código"
                  />
                </Grid>

                {/* Filtro de proyecto - Solo visible para administradores */}
                {!isCliente && (
                  <Grid item xs={12} sm={6} md={4} lg={2}>
                    <FormControl fullWidth size="small">
                      <InputLabel>Proyecto</InputLabel>
                      <Select
                        value={filters.proyecto}
                        onChange={(e) => setFilters({ ...filters, proyecto: e.target.value })}
                        label="Proyecto"
                        MenuProps={{
                          PaperProps: {
                            style: {
                              maxHeight: 300
                            }
                          }
                        }}
                      >
                        <MenuItem value="">Todos</MenuItem>
                        {proyectos.map((proy) => (
                          <MenuItem key={proy} value={proy}>{proy}</MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}

                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={addFilter}
                    sx={{
                      height: '40px',
                      borderRadius: 8,
                      backgroundColor: '#2e7d32',
                      '&:hover': { backgroundColor: '#1b5e20' },
                    }}
                  >
                    Aplicar Filtros
                  </Button>
                </Grid>
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Desde"
                      value={filters.fecha_desde}
                      onChange={(newValue) => setFilters({ ...filters, fecha_desde: newValue })}
                      renderInput={(params) => <TextField size="small" {...params} fullWidth />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Hasta"
                      value={filters.fecha_hasta}
                      onChange={(newValue) => setFilters({ ...filters, fecha_hasta: newValue })}
                      renderInput={(params) => <TextField size="small" {...params} fullWidth />}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </Box>
          </Collapse>

          {/* Chips de filtros activos */}
          {activeFilters.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', pt: 2 }}>
              {activeFilters.map((filter, index) => (
                <FilterChip
                  key={index}
                  label={filter.label}
                  onDelete={() => removeFilter(index)}
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
            </Box>
          )}
        </StyledPaper>

        {/* Tabla de entradas */}
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <StyledPaper elevation={0}>
              {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', p: 5 }}>
                  <CircularProgress color="primary" />
                </Box>
              ) : filteredEntradas.length === 0 ? (
                <Box sx={{ p: 5, textAlign: 'center' }}>
                  <Typography variant="h6" color="textSecondary">
                    No se encontraron entradas
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                    Intenta ajustar los filtros o realizar una nueva búsqueda
                  </Typography>
                </Box>
              ) : (
                <TableContainer sx={{ maxHeight: 'calc(100vh - 350px)' }}>
                  <Table stickyHeader aria-label="tabla de entradas">
                    <TableHead>
                      <TableRow>
                        <StyledTableHeaderCell>Fecha</StyledTableHeaderCell>
                        <StyledTableHeaderCell>Caja Origen</StyledTableHeaderCell>
                        <StyledTableHeaderCell>Caja Fin</StyledTableHeaderCell>
                        <StyledTableHeaderCell>Concepto</StyledTableHeaderCell>
                        <StyledTableHeaderCell>Descripción</StyledTableHeaderCell>
                        <StyledTableHeaderCell>Tipo Cambio</StyledTableHeaderCell>
                        <StyledTableHeaderCell>Monto</StyledTableHeaderCell>
                        <StyledTableHeaderCell>Comprobante</StyledTableHeaderCell>
                        <StyledTableHeaderCell>Factura</StyledTableHeaderCell>

                        {/* Solo mostrar columna de acciones para administradores */}
                        {!isCliente && (
                          <StyledTableHeaderCell align="center">Acciones</StyledTableHeaderCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredEntradas
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((entrada) => (
                          <StyledTableRow key={entrada.id}>
                            <StyledTableCell>
                              {entrada.fecha_formateada}
                            </StyledTableCell>
                            <StyledTableCell>{entrada.caja_origen_entrada}</StyledTableCell>
                            <StyledTableCell>{entrada.caja_fin_entrada}</StyledTableCell>
                            <StyledTableCell>{entrada.concepto_entrada}</StyledTableCell>
                            <StyledTableCell>{entrada.descripción_entrada}</StyledTableCell>
                            <StyledTableCell>{entrada.tipo_de_cambio_entrada}</StyledTableCell>
                            <StyledTableCell>
                              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <MoneyIcon sx={{ color: entrada.moneda_entrada === 'USD' ? '#2e7d32' : '#1976d2', mr: 1, fontSize: '1rem' }} />
                                <Typography sx={{ fontWeight: 'medium' }}>
                                  {entrada.monto_formateado}
                                </Typography>
                              </Box>
                            </StyledTableCell>
                            <StyledTableCell>
                              {entrada.tiene_comprobante_entrada ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <ImagePreview
                                    src={entrada.imagen_comprobante_entrada}
                                    onClick={() => handleShowImage(entrada, 'comprobante')}
                                    thumbSize="medium"
                                    alt={`Comprobante ${entrada.codigo_de_comprobante_entrada || ""}`}
                                    sx={{ mb: 1 }}
                                  />
                                  {entrada.codigo_de_comprobante_entrada && (
                                    <Tooltip title="Código del comprobante (click para copiar)">
                                      <Typography variant="caption" sx={{
                                        fontSize: '0.7rem',
                                        fontFamily: 'monospace',
                                        backgroundColor: '#e8f5e9',
                                        padding: '2px 4px',
                                        borderRadius: '4px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '80px',
                                        cursor: 'pointer',
                                      }} onClick={(e) => {
                                        e.stopPropagation();
                                        // Copiar código al portapapeles
                                        navigator.clipboard.writeText(entrada.codigo_de_comprobante_entrada);
                                        setSnackbar({
                                          open: true,
                                          message: 'Código copiado al portapapeles',
                                          severity: 'success'
                                        });
                                      }}>
                                        {entrada.codigo_de_comprobante_entrada}
                                      </Typography>
                                    </Tooltip>
                                  )}
                                </Box>
                              ) : (
                                <Typography variant="body2" color="text.secondary">
                                  No
                                </Typography>
                              )}
                            </StyledTableCell>
                            <StyledTableCell>
                              {entrada.tiene_factura_entrada ? (
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                  <ImagePreview
                                    src={entrada.imagen_factura_entrada}
                                    onClick={() => handleShowImage(entrada, 'factura')}
                                    thumbSize="medium"
                                    alt={`Factura ${entrada.codigo_de_factura_entrada || ""}`}
                                    sx={{ mb: 1 }}
                                  />
                                  {entrada.codigo_de_factura_entrada && (
                                    <Tooltip title="Código de la factura (click para copiar)">
                                      <Typography variant="caption" sx={{
                                        fontSize: '0.7rem',
                                        fontFamily: 'monospace',
                                        backgroundColor: '#e3f2fd',
                                        padding: '2px 4px',
                                        borderRadius: '4px',
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '80px',
                                        cursor: 'pointer',
                                      }} onClick={(e) => {
                                        e.stopPropagation();
                                        // Copiar código al portapapeles
                                        navigator.clipboard.writeText(entrada.codigo_de_factura_entrada);
                                        setSnackbar({
                                          open: true,
                                          message: 'Código copiado al portapapeles',
                                          severity: 'success'
                                        });
                                      }}>
                                        {entrada.codigo_de_factura_entrada}
                                      </Typography>
                                    </Tooltip>
                                  )}
                                </Box>
                              ) : (
                                <Typography variant="body2" color="text.secondary">
                                  No
                                </Typography>
                              )}
                            </StyledTableCell>

                            {/* Solo mostrar acciones para administradores */}
                            {!isCliente && (
                              <StyledTableCell align="center">
                                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                                  <Tooltip title="Editar">
                                    <ActionButton
                                      size="small"
                                      onClick={() => handleEditarEntrada(entrada)}
                                    >
                                      <EditIcon fontSize="small" />
                                    </ActionButton>
                                  </Tooltip>
                                  <Tooltip title="Eliminar">
                                    <ActionButton
                                      size="small"
                                      onClick={() => handleEliminarEntrada(entrada.id, entrada.ruta_archivo_comprobante, entrada.ruta_archivo_factura)}
                                      sx={{ color: '#d32f2f' }}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </ActionButton>
                                  </Tooltip>
                                </Box>
                              </StyledTableCell>
                            )}
                          </StyledTableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={filteredEntradas.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página:"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                sx={{
                  borderTop: '1px solid rgba(224, 224, 224, 1)',
                  '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                    color: '#424242'
                  },
                  '.MuiTablePagination-select': {
                    color: '#2e7d32'
                  }
                }}
              />
            </StyledPaper>
          </motion.div>
        </AnimatePresence>
      </motion.div>

      {/* Diálogo para agregar nueva entrada - Solo para administradores */}
      {!isCliente && (
        <AddEntryDialog open={openAddEntry} onClose={() => setOpenAddEntry(false)}>
          <DialogContent>
            <Typography variant="h5" fontWeight="bold" color="#2e7d32" gutterBottom textAlign="center">
              Agregar Entrada
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DatePicker
                    label="Fecha"
                    value={fechaEntrada}
                    onChange={(newValue) => setFechaEntrada(newValue)}
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Caja Origen</InputLabel>
                    <Select value={cajaOrigen} onChange={(e) => setCajaOrigen(e.target.value)}>
                      {cajasOrigen.map((caja) => (
                        <MenuItem key={caja} value={caja}>
                          {caja}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Caja Fin</InputLabel>
                    <Select value={cajaFin} onChange={(e) => setCajaFin(e.target.value)}>
                      {cajasFin.map((caja) => (
                        <MenuItem key={caja} value={caja}>
                          {caja}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Concepto</InputLabel>
                    <Select value={concepto} onChange={(e) => setConcepto(e.target.value)}>
                      {conceptos.map((conceptoItem) => (
                        <MenuItem key={conceptoItem} value={conceptoItem}>
                          {conceptoItem}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Proyecto</InputLabel>
                    <Select value={proyecto} onChange={(e) => setProyecto(e.target.value)}>
                      <MenuItem value="">Sin proyecto</MenuItem>
                      {proyectos.map((proyectoItem) => (
                        <MenuItem key={proyectoItem} value={proyectoItem}>
                          {proyectoItem}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Descripción"
                    value={descripcion}
                    onChange={(e) => setDescripcion(e.target.value)}
                    fullWidth
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Tipo de Cambio"
                      value={tipoCambio || ''}
                      onChange={(e) => setTipoCambio(e.target.value)}
                      fullWidth
                      type="number"
                      inputProps={{ step: 5 }}
                      onKeyDown={(e) => {
                        if (e.key === 'ArrowUp') {
                          e.preventDefault();
                          adjustTipoCambio(5);
                        } else if (e.key === 'ArrowDown') {
                          e.preventDefault();
                          adjustTipoCambio(-5);
                        }
                      }}
                    />
                    <Button
                      onClick={() => adjustTipoCambio(5)}
                      variant="outlined"
                      sx={{ ml: 1, minWidth: '48px', color: '#2e7d32', borderColor: '#2e7d32' }}
                    >
                      +$5
                    </Button>
                    <Button
                      onClick={() => adjustTipoCambio(-5)}
                      variant="outlined"
                      sx={{ ml: 1, minWidth: '48px', color: '#d32f2f', borderColor: '#d32f2f' }}
                    >
                      -$5
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Moneda</InputLabel>
                    <Select value={moneda} onChange={(e) => setMoneda(e.target.value)}>
                      <MenuItem value="ARS">ARS</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Monto"
                    value={monto}
                    onChange={(e) => setMonto(e.target.value)}
                    fullWidth
                    type="number"
                  />
                </Grid>

                {/* Tabs para alternar entre comprobante y factura */}
                <Grid item xs={12}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
                    <StyledTabs value={tabValue} onChange={handleTabChange}>
                      <Tab label="Comprobante" icon={<ReceiptIcon />} iconPosition="start" />
                      <Tab label="Factura" icon={<DescriptionIcon />} iconPosition="start" />
                    </StyledTabs>
                  </Box>
                </Grid>

                {/* Contenido de la pestaña de comprobante */}
                {tabValue === 0 && (
                  <>
                    <Grid item xs={12}>
                      <Box sx={{ mt: 2, mb: 1 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={tieneComprobante}
                              onChange={(e) => setTieneComprobante(e.target.checked)}
                              color="primary"
                            />
                          }
                          label="Tiene comprobante"
                        />
                      </Box>
                    </Grid>

                    {tieneComprobante && (
                      <Grid item xs={12}>
                        <input
                          ref={comprobanteFileInputRef}
                          type="file"
                          accept="image/*"
                          onChange={handleComprobanteFileChange}
                          style={{ display: 'none' }}
                        />

                        {!imagenComprobanteURL ? (
                          <FileUploadBox onClick={handleComprobanteUpload}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <AttachFileIcon sx={{ fontSize: 40, color: '#2e7d32', mb: 1 }} />
                              <Typography variant="body1" gutterBottom>
                                Haz clic para subir una imagen de comprobante
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                JPG, PNG o PDF (máx. 5MB)
                              </Typography>
                            </Box>
                          </FileUploadBox>
                        ) : (
                          <Box sx={{ mt: 2, position: 'relative' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'relative',
                                width: '100%',
                                height: 200,
                                backgroundColor: '#f8f9fa',
                                borderRadius: 2,
                                overflow: 'hidden',
                                border: '1px solid #e0e0e0',
                              }}
                            >
                              <Box
                                component="img"
                                src={imagenComprobanteURL}
                                alt="Vista previa de comprobante"
                                sx={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  objectFit: 'contain',
                                }}
                              />
                              <IconButton
                                size="small"
                                sx={{
                                  position: 'absolute',
                                  top: 8,
                                  right: 8,
                                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                                }}
                                onClick={removeComprobanteImage}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          </Box>
                        )}
                      </Grid>
                    )}
                  </>
                )}

                {/* Contenido de la pestaña de factura */}
                {tabValue === 1 && (
                  <>
                    <Grid item xs={12}>
                      <Box sx={{ mt: 2, mb: 1 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={tieneFactura}
                              onChange={(e) => setTieneFactura(e.target.checked)}
                              color="primary"
                            />
                          }
                          label="Tiene factura"
                        />
                      </Box>
                    </Grid>

                    {tieneFactura && (
                      <>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel>A Nombre De</InputLabel>
                            <Select
                              value={nombreFactura}
                              onChange={(e) => setNombreFactura(e.target.value)}
                              label="A Nombre De"
                            >
                              <MenuItem value="">Seleccionar nombre</MenuItem>
                              {nombreFacturas.map((nombre) => (
                                <MenuItem key={nombre} value={nombre}>{nombre}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <input
                            ref={facturaFileInputRef}
                            type="file"
                            accept="image/*"
                            onChange={handleFacturaFileChange}
                            style={{ display: 'none' }}
                          />

                          {!imagenFacturaURL ? (
                            <FileUploadBox onClick={handleFacturaUpload}>
                              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <AttachFileIcon sx={{ fontSize: 40, color: '#1976d2', mb: 1 }} />
                                <Typography variant="body1" gutterBottom>
                                  Haz clic para subir una imagen de factura
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  JPG, PNG o PDF (máx. 5MB)
                                </Typography>
                              </Box>
                            </FileUploadBox>
                          ) : (
                            <Box sx={{ mt: 2, position: 'relative' }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  position: 'relative',
                                  width: '100%',
                                  height: 200,
                                  backgroundColor: '#f8f9fa',
                                  borderRadius: 2,
                                  overflow: 'hidden',
                                  border: '1px solid #e0e0e0',
                                }}
                              >
                                <Box
                                  component="img"
                                  src={imagenFacturaURL}
                                  alt="Vista previa de factura"
                                  sx={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                  }}
                                />
                                <IconButton
                                  size="small"
                                  sx={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                                  }}
                                  onClick={removeFacturaImage}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </LocalizationProvider>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
            <Button
              onClick={() => {
                setOpenAddEntry(false);
                resetForm();
              }}
              sx={{
                borderRadius: 8,
                color: '#757575',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.04)'
                }
              }}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAgregarEntrada}
              disabled={uploading}
              sx={{
                borderRadius: 8,
                backgroundColor: '#2e7d32',
                '&:hover': { backgroundColor: '#1b5e20' },
                px: 3
              }}
            >
              {uploading ? <CircularProgress size={24} color="inherit" /> : 'Guardar Entrada'}
            </Button>
          </DialogActions>
        </AddEntryDialog>
      )}

      {/* Diálogo para editar entrada - Solo para administradores */}
      {!isCliente && (
        <AddEntryDialog open={openEditEntry} onClose={() => setOpenEditEntry(false)}>
          <DialogContent>
            <Typography variant="h5" fontWeight="bold" color="#2e7d32" gutterBottom textAlign="center">
              Editar Entrada
            </Typography>

            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <DatePicker
                    label="Fecha"
                    value={filaEditando?.fecha_entrada ? new Date(filaEditando.fecha_entrada) : null}
                    onChange={(newValue) =>
                      setFilaEditando({ ...filaEditando, fecha_entrada: newValue?.toISOString() })
                    }
                    renderInput={(params) => <TextField {...params} fullWidth />}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Caja Origen</InputLabel>
                    <Select
                      value={filaEditando?.caja_origen_entrada || ''}
                      onChange={(e) =>
                        setFilaEditando({ ...filaEditando, caja_origen_entrada: e.target.value })
                      }
                    >
                      {cajasOrigen.map((caja) => (
                        <MenuItem key={caja} value={caja}>
                          {caja}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Caja Fin</InputLabel>
                    <Select
                      value={filaEditando?.caja_fin_entrada || ''}
                      onChange={(e) =>
                        setFilaEditando({ ...filaEditando, caja_fin_entrada: e.target.value })
                      }
                    >
                      {cajasFin.map((caja) => (
                        <MenuItem key={caja} value={caja}>
                          {caja}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Concepto</InputLabel>
                    <Select
                      value={filaEditando?.concepto_entrada || ''}
                      onChange={(e) =>
                        setFilaEditando({ ...filaEditando, concepto_entrada: e.target.value })
                      }
                    >
                      {conceptos.map((concepto) => (
                        <MenuItem key={concepto} value={concepto}>
                          {concepto}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Proyecto</InputLabel>
                    <Select
                      value={filaEditando?.codigo_de_proyecto_entrada || ''}
                      onChange={(e) =>
                        setFilaEditando({ ...filaEditando, codigo_de_proyecto_entrada: e.target.value })
                      }
                    >
                      <MenuItem value="">Sin proyecto</MenuItem>
                      {proyectos.map((proyecto) => (
                        <MenuItem key={proyecto} value={proyecto}>
                          {proyecto}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Descripción"
                    value={filaEditando?.descripción_entrada || ''}
                    onChange={(e) =>
                      setFilaEditando({ ...filaEditando, descripción_entrada: e.target.value })
                    }
                    fullWidth
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      label="Tipo de Cambio"
                      value={filaEditando?.tipo_de_cambio_entrada || ''}
                      onChange={(e) =>
                        setFilaEditando({ ...filaEditando, tipo_de_cambio_entrada: e.target.value })
                      }
                      fullWidth
                      type="number"
                      inputProps={{ step: 5 }}
                      onKeyDown={(e) => {
                        if (e.key === 'ArrowUp') {
                          e.preventDefault();
                          const currentValue = parseFloat(filaEditando?.tipo_de_cambio_entrada) || 0;
                          setFilaEditando({
                            ...filaEditando,
                            tipo_de_cambio_entrada: (currentValue + 5).toString()
                          });
                        } else if (e.key === 'ArrowDown') {
                          e.preventDefault();
                          const currentValue = parseFloat(filaEditando?.tipo_de_cambio_entrada) || 0;
                          setFilaEditando({
                            ...filaEditando,
                            tipo_de_cambio_entrada: Math.max(0, currentValue - 5).toString()
                          });
                        }
                      }}
                    />
                    <Button
                      onClick={() => {
                        const currentValue = parseFloat(filaEditando?.tipo_de_cambio_entrada) || 0;
                        setFilaEditando({
                          ...filaEditando,
                          tipo_de_cambio_entrada: (currentValue + 5).toString()
                        });
                      }}
                      variant="outlined"
                      sx={{ ml: 1, minWidth: '48px', color: '#2e7d32', borderColor: '#2e7d32' }}
                    >
                      +$5
                    </Button>
                    <Button
                      onClick={() => {
                        const currentValue = parseFloat(filaEditando?.tipo_de_cambio_entrada) || 0;
                        setFilaEditando({
                          ...filaEditando,
                          tipo_de_cambio_entrada: Math.max(0, currentValue - 5).toString()
                        });
                      }}
                      variant="outlined"
                      sx={{ ml: 1, minWidth: '48px', color: '#d32f2f', borderColor: '#d32f2f' }}
                    >
                      -$5
                    </Button>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel>Moneda</InputLabel>
                    <Select
                      value={filaEditando?.moneda_entrada || 'ARS'}
                      onChange={(e) =>
                        setFilaEditando({ ...filaEditando, moneda_entrada: e.target.value })
                      }
                    >
                      <MenuItem value="ARS">ARS</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Monto"
                    value={filaEditando?.monto_entrada || ''}
                    onChange={(e) =>
                      setFilaEditando({ ...filaEditando, monto_entrada: e.target.value })
                    }
                    fullWidth
                    type="number"
                  />
                </Grid>

                {/* Tabs para alternar entre comprobante y factura */}
                <Grid item xs={12}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', mt: 2 }}>
                    <StyledTabs value={tabValue} onChange={handleTabChange}>
                      <Tab label="Comprobante" icon={<ReceiptIcon />} iconPosition="start" />
                      <Tab label="Factura" icon={<DescriptionIcon />} iconPosition="start" />
                    </StyledTabs>
                  </Box>
                </Grid>

                {/* Contenido de la pestaña de comprobante */}
                {tabValue === 0 && (
                  <>
                    <Grid item xs={12}>
                      <Box sx={{ mt: 2, mb: 1 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={tieneComprobante}
                              onChange={(e) => setTieneComprobante(e.target.checked)}
                              color="primary"
                            />
                          }
                          label="Tiene comprobante"
                        />
                      </Box>
                    </Grid>

                    {tieneComprobante && (
                      <Grid item xs={12}>
                        <input
                          ref={comprobanteFileInputRef}
                          type="file"
                          accept="image/*"
                          onChange={handleComprobanteFileChange}
                          style={{ display: 'none' }}
                        />

                        {!imagenComprobanteURL ? (
                          <FileUploadBox onClick={handleComprobanteUpload}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                              <AttachFileIcon sx={{ fontSize: 40, color: '#2e7d32', mb: 1 }} />
                              <Typography variant="body1" gutterBottom>
                                Haz clic para subir una imagen de comprobante
                              </Typography>
                              <Typography variant="body2" color="text.secondary">
                                JPG, PNG o PDF (máx. 5MB)
                              </Typography>
                            </Box>
                          </FileUploadBox>
                        ) : (
                          <Box sx={{ mt: 2, position: 'relative' }}>
                            <Box
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                position: 'relative',
                                width: '100%',
                                height: 200,
                                backgroundColor: '#f8f9fa',
                                borderRadius: 2,
                                overflow: 'hidden',
                                border: '1px solid #e0e0e0',
                              }}
                            >
                              <Box
                                component="img"
                                src={imagenComprobanteURL}
                                alt="Vista previa de comprobante"
                                sx={{
                                  maxWidth: '100%',
                                  maxHeight: '100%',
                                  objectFit: 'contain',
                                }}
                              />
                              <IconButton
                                size="small"
                                sx={{
                                  position: 'absolute',
                                  top: 8,
                                  right: 8,
                                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                                }}
                                onClick={removeComprobanteImage}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          </Box>
                        )}
                      </Grid>
                    )}
                  </>
                )}

                {/* Contenido de la pestaña de factura */}
                {tabValue === 1 && (
                  <>
                    <Grid item xs={12}>
                      <Box sx={{ mt: 2, mb: 1 }}>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={tieneFactura}
                              onChange={(e) => setTieneFactura(e.target.checked)}
                              color="primary"
                            />
                          }
                          label="Tiene factura"
                        />
                      </Box>
                    </Grid>

                    {tieneFactura && (
                      <>
                        <Grid item xs={12}>
                          <FormControl fullWidth>
                            <InputLabel>A Nombre De</InputLabel>
                            <Select
                              value={filaEditando?.factura_a_nombre_de_entrada || ''}
                              onChange={(e) =>
                                setFilaEditando({ ...filaEditando, factura_a_nombre_de_entrada: e.target.value })
                              }
                              label="A Nombre De"
                            >
                              <MenuItem value="">Seleccionar nombre</MenuItem>
                              {nombreFacturas.map((nombre) => (
                                <MenuItem key={nombre} value={nombre}>{nombre}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                          <input
                            ref={facturaFileInputRef}
                            type="file"
                            accept="image/*"
                            onChange={handleFacturaFileChange}
                            style={{ display: 'none' }}
                          />

                          {!imagenFacturaURL ? (
                            <FileUploadBox onClick={handleFacturaUpload}>
                              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <AttachFileIcon sx={{ fontSize: 40, color: '#1976d2', mb: 1 }} />
                                <Typography variant="body1" gutterBottom>
                                  Haz clic para subir una imagen de factura
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                  JPG, PNG o PDF (máx. 5MB)
                                </Typography>
                              </Box>
                            </FileUploadBox>
                          ) : (
                            <Box sx={{ mt: 2, position: 'relative' }}>
                              <Box
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  position: 'relative',
                                  width: '100%',
                                  height: 200,
                                  backgroundColor: '#f8f9fa',
                                  borderRadius: 2,
                                  overflow: 'hidden',
                                  border: '1px solid #e0e0e0',
                                }}
                              >
                                <Box
                                  component="img"
                                  src={imagenFacturaURL}
                                  alt="Vista previa de factura"
                                  sx={{
                                    maxWidth: '100%',
                                    maxHeight: '100%',
                                    objectFit: 'contain',
                                  }}
                                />
                                <IconButton
                                  size="small"
                                  sx={{
                                    position: 'absolute',
                                    top: 8,
                                    right: 8,
                                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                    '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                                  }}
                                  onClick={removeFacturaImage}
                                >
                                  <CloseIcon fontSize="small" />
                                </IconButton>
                              </Box>
                            </Box>
                          )}
                        </Grid>
                      </>
                    )}
                  </>
                )}
              </Grid>
            </LocalizationProvider>
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
            <Button
              onClick={() => setOpenEditEntry(false)}
              sx={{
                borderRadius: 8,
                color: '#757575',
                '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleUpdateEntrada}
              variant="contained"
              color="primary"
              disabled={uploading}
              sx={{
                borderRadius: 8,
                backgroundColor: '#2e7d32',
                '&:hover': { backgroundColor: '#1b5e20' },
                px: 3
              }}
            >
              {uploading ? <CircularProgress size={24} color="inherit" /> : 'Guardar Cambios'}
            </Button>
          </DialogActions>
        </AddEntryDialog>
      )}

      {/* Diálogo para vista previa de imagen */}
      <ImagePreviewDialog
        open={openImagePreview}
        onClose={() => setOpenImagePreview(false)}
        maxWidth="md"
        onClick={() => setOpenImagePreview(false)}
      >
        <DialogContent sx={{ p: 0, overflow: 'hidden', position: 'relative' }}>
          {currentImage && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                background: 'transparent',
              }}
            >
              <Box
                component="img"
                src={currentImage.url || currentImage}
                alt={currentImage.tipo || "Documento"}
                sx={{
                  maxWidth: '100%',
                  maxHeight: '75vh',
                  objectFit: 'contain',
                  borderRadius: 1,
                  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
                }}
                onError={(e) => {
                  console.error('Error cargando imagen en diálogo:', e);
                  e.target.src = 'https://via.placeholder.com/400x300?text=Imagen+no+disponible';
                }}
              />

              {currentImage.codigo && (
                <Box sx={{
                  mt: 2,
                  p: 1,
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                  <Typography variant="body2" fontWeight="bold" sx={{ mb: 0.5 }}>
                    Código de {currentImage.tipo === 'Factura' ? 'factura' : 'comprobante'}
                  </Typography>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: currentImage.tipo === 'Factura' ? '#e3f2fd' : '#e8f5e9',
                    p: 1,
                    borderRadius: 1,
                    fontFamily: 'monospace'
                  }}>
                    <Typography variant="body2">
                      {currentImage.codigo}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(currentImage.codigo);
                        setSnackbar({
                          open: true,
                          message: 'Código copiado al portapapeles',
                          severity: 'success'
                        });
                      }}
                      sx={{ ml: 1 }}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              )}

              <IconButton
                size="small"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                }}
                onClick={() => setOpenImagePreview(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </DialogContent>
      </ImagePreviewDialog>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            borderRadius: 2,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Entradas;