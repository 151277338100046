import React, { useEffect, useState, useRef } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Typography,
  IconButton,
  Container,
  Paper,
  Tooltip,
  Chip,
  InputBase,
  InputAdornment,
  Alert,
  Snackbar,
  Divider,
  CircularProgress,
  FormControlLabel,
  Switch,
  Avatar,
  Card,
  CardContent,
  Fab,
  Drawer,
  SwipeableDrawer,
  AppBar,
  Toolbar,
  Slide,
  useScrollTrigger,
  Zoom
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import SummarizeIcon from '@mui/icons-material/Summarize';
import MoneyIcon from '@mui/icons-material/Money';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';

// Estilos personalizados con enfoque en mobile
const MobileContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1, 1.5, 10, 1.5), // Padding extra abajo para el botón flotante
  maxWidth: '100%',
  backgroundColor: '#f8f9fa'
}));

const CurrencyCard = styled(Card)(({ theme }) => ({
  borderRadius: 20,
  marginBottom: theme.spacing(2),
  overflow: 'hidden',
  boxShadow: '0 3px 10px rgba(0, 0, 0, 0.08)',
  transition: 'all 0.3s ease',
  background: 'linear-gradient(to bottom, #ffffff, #fafafa)',
  '&:hover': {
    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
    transform: 'translateY(-2px)'
  },
  '&:active': {
    transform: 'scale(0.98)',
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)',
  },
}));

const CardRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0.8, 0),
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const SearchBarContainer = styled(Box)(({ theme, disappear }) => ({
  display: 'flex',
  alignItems: 'stretch',
  marginBottom: theme.spacing(2),
  gap: theme.spacing(1),
  transition: 'opacity 0.3s ease, transform 0.3s ease',
  opacity: disappear ? 0 : 1,
  transform: disappear ? 'scale(0.95)' : 'scale(1)',
  pointerEvents: disappear ? 'none' : 'auto'
}));

const SearchBar = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: 24,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  flexGrow: 1
}));

const FilterButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  borderRadius: 20,
  padding: theme.spacing(1, 2),
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  color: theme.palette.primary.main,
  border: '1px solid rgba(0, 0, 0, 0.12)'
}));

const FixedSearchContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 16,
  right: 16,
  display: 'flex',
  gap: theme.spacing(1),
  zIndex: 1201,
  transition: 'opacity 0.3s ease, transform 0.3s ease',
}));

const FloatingSearchButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 20,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
}));

const FloatingFilterButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 20,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
}));

const AddFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: 110, // Aumentado para mejor visibilidad por encima de la barra de navegación
  right: 16,
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  boxShadow: '0 6px 15px rgba(46, 125, 50, 0.4)',
  zIndex: 1000,
}));

// Campos de formulario mejorados
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  '& .MuiOutlinedInput-root': {
    borderRadius: 20,
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
    },
  },
  '& .MuiInputLabel-outlined': {
    backgroundColor: 'transparent',
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(1.5),
  '& .MuiOutlinedInput-root': {
    borderRadius: 20,
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
    },
  },
  '& .MuiInputLabel-outlined': {
    backgroundColor: 'transparent',
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

// Nuevo diálogo rediseñado para formularios
const FullWidthDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    backdropFilter: 'blur(0px) brightness(100%)',
    transition: 'backdrop-filter 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
    '&.MuiBackdrop-root.MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      backdropFilter: 'blur(12px) brightness(95%)',
    },
  },
  '& .MuiDialog-paper': {
    borderRadius: '30px 30px 0 0',
    margin: 0,
    width: '100%',
    maxWidth: '100%',
    maxHeight: '85vh',
    position: 'fixed',
    bottom: 0,
    overflowY: 'hidden',
    boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
    backgroundImage: 'linear-gradient(to bottom, rgba(250, 250, 250, 0.95), rgba(255, 255, 255, 0.95))',
    transition: 'box-shadow 0.6s cubic-bezier(0.4, 0, 0.2, 1), transform 0.4s ease-out',
    '&:hover': {
      boxShadow: '0 -10px 30px rgba(0, 0, 0, 0.15)',
    },
  }
}));

// Contenedor principal del formulario
const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '81vh',
  width: '100%',
  position: 'relative',
}));

// Header mejorado del formulario con indicador de arrastre
const FormHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 3, 1, 3),
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
  backgroundColor: 'transparent',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '50px',
    height: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    top: '8px',
    left: '50%',
    transform: 'translateX(-50%)',
  }
}));

// Icono de cierre con animación
const CloseButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  color: theme.palette.text.secondary,
  borderRadius: 16,
  padding: 8,
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    transform: 'scale(1.05)',
  },
}));

// Pasos con animación
const StepButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 3, 2, 3),
  marginTop: theme.spacing(2.5),
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
}));

// Contenido del formulario
const FormContent = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  flex: 1,
  padding: theme.spacing(3, 3, 5, 3),
  backgroundColor: '#f8f9fa',
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
  },
}));

// Footer del formulario
const FormFooter = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderTop: '1px solid rgba(0, 0, 0, 0.06)',
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.05)',
}));

// Sección del formulario
const FormSection = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  borderRadius: 24,
  marginBottom: theme.spacing(3),
}));

const FormSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
  fontSize: '1.1rem',
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    fontSize: '1.4rem',
  },
}));

// Área de carga de archivos
const FileUploadBox = styled(Box)(({ theme }) => ({
  border: '2px dashed rgba(46, 125, 50, 0.3)',
  borderRadius: 24,
  padding: theme.spacing(4),
  textAlign: 'center',
  backgroundColor: 'rgba(46, 125, 50, 0.03)',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  marginBottom: theme.spacing(3),
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: 'rgba(46, 125, 50, 0.05)',
    transform: 'translateY(-2px)',
  },
}));

// Botones de navegación para los pasos
const StepButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: 20,
  textTransform: 'none',
  padding: theme.spacing(1, 2),
  minWidth: variant === 'next' ? 120 : 100,
  boxShadow: variant === 'next' ? '0 3px 8px rgba(46, 125, 50, 0.2)' : 'none',
  backgroundColor: variant === 'next' ? theme.palette.primary.main : 'transparent',
  color: variant === 'next' ? '#fff' : theme.palette.text.secondary,
  border: variant === 'next' ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
  fontWeight: variant === 'next' ? 'bold' : 'normal',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: variant === 'next' ? theme.palette.primary.dark : 'rgba(0, 0, 0, 0.05)',
    transform: 'translateY(-2px)',
    boxShadow: variant === 'next' ? '0 4px 10px rgba(46, 125, 50, 0.3)' : '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
}));

// Indicador de paso actual
const StepIndicator = styled(Box)(({ theme, active }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: active ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.12)',
  margin: '0 4px',
  transition: 'all 0.3s ease',
  transform: active ? 'scale(1.2)' : 'scale(1)',
}));

// Box para filtros
const SwipeableFilterBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '28px 28px 0 0',
  padding: theme.spacing(2.5),
  paddingBottom: theme.spacing(4),
  marginTop: 'auto',
  maxHeight: '75vh',
  overflow: 'auto',
}));

// Toggle switch para opciones
const SwitchFormControl = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  marginRight: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1.5),
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.primary.main,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main,
  },
}));

// Botón de guardar
const StyledSaveButton = styled(Button)(({ theme }) => ({
  borderRadius: 28,
  boxShadow: '0 4px 12px rgba(46, 125, 50, 0.3)',
  padding: theme.spacing(1.2, 4),
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '1rem',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: '0 6px 16px rgba(46, 125, 50, 0.4)',
    transform: 'translateY(-2px)',
  }
}));

// Para la vista de detalle mejorada
const MobileDetailView = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  backgroundColor: '#f8f9fa',
  height: '85vh',
  width: '100%',
  overflow: 'auto',
  borderRadius: '28px 28px 28px 28px',
  margin: '3vh 2vw',
  boxShadow: '0 5px 20px rgba(0, 0, 0, 0.15)',
}));

const DetailSection = styled(Paper)(({ theme }) => ({
  borderRadius: 24,
  marginBottom: theme.spacing(3),
  overflow: 'hidden',
  padding: theme.spacing(3),
  backgroundColor: '#fff',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
}));

const DetailSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.1rem',
  color: theme.palette.primary.main,
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    fontSize: '1.3rem',
  },
}));

const DetailRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1.2, 0),
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const DetailLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.9rem',
}));

const DetailValue = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '0.95rem',
  textAlign: 'right',
}));

const ImagePreviewBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 200,
  borderRadius: 20,
  overflow: 'hidden',
  marginBottom: theme.spacing(3),
  backgroundColor: '#f5f5f5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid rgba(0, 0, 0, 0.1)',
}));

// Para los chips de documento
const DocChip = styled(Chip)(({ theme }) => ({
  borderRadius: 16,
  height: 36,
  padding: theme.spacing(0.5, 0.5),
  '& .MuiChip-label': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }
}));

// Panel de resumen financiero
const SummaryPanel = styled(Paper)(({ theme }) => ({
  borderRadius: 24,
  padding: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgba(46, 125, 50, 0.04)',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.08)',
}));

const SummaryRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1.2),
  '&:last-child': {
    marginBottom: 0,
  }
}));

// Controladores de cambio para tipo de cambio
const TipoCambioControl = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1),
  backgroundColor: 'transparent',
  borderRadius: 16,
}));

const TcStepButton = styled(IconButton)(({ theme, color = 'primary' }) => ({
  backgroundColor: color === 'primary' ? 'rgba(46, 125, 50, 0.1)' : 'rgba(211, 47, 47, 0.1)',
  color: color === 'primary' ? theme.palette.primary.main : theme.palette.error.main,
  borderRadius: 12,
  padding: theme.spacing(0.6),
  '&:hover': {
    backgroundColor: color === 'primary' ? 'rgba(46, 125, 50, 0.2)' : 'rgba(211, 47, 47, 0.2)',
    transform: 'scale(1.05)',
  }
}));

const CurrencyChip = styled(Chip)(({ theme, currency }) => ({
  backgroundColor: currency === 'USD' ? '#e3f2fd' : '#fff3e0',
  color: currency === 'USD' ? '#1976d2' : '#ed6c02',
  fontWeight: 'bold',
  '& .MuiChip-icon': {
    color: currency === 'USD' ? '#1976d2' : '#ed6c02',
  },
}));

const ExchangeIcon = styled(CompareArrowsIcon)(({ theme }) => ({
  color: '#2e7d32',
  fontSize: '1.25rem',
  animation: '$pulse 1.5s infinite ease-in-out',
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
}));

// Animaciones para las transiciones
const pageVariants = {
  initial: {
    opacity: 0,
    y: 20
  },
  in: {
    opacity: 1,
    y: 0
  },
  out: {
    opacity: 0,
    y: -20
  }
};

const pageTransition = {
  type: 'spring',
  stiffness: 300,
  damping: 30
};

// Función para formatear URLs de imagen
const formatImageUrl = (url) => {
  if (!url) return null;
  // Si ya incluye parámetros de caché, retornar la URL tal como está
  if (url.includes('?')) return url;
  // Agregar un parámetro de caché para evitar problemas de caché
  const cacheParam = `t=${new Date().getTime()}`;
  return `${url}?${cacheParam}`;
};

// Función para subir imágenes
const uploadImage = async (file, metadata = {}) => {
  if (!file) return null;

  try {
    // Generar un código único para el comprobante
    const codigo = `CM-${metadata.referencia || 'GEN'}-${format(new Date(), 'yyyyMMdd')}-${uuidv4().substring(0, 6)}`;

    // Crear la ruta del archivo
    const fileExt = file.name.split('.').pop();
    const fileName = `cambio_moneda/${codigo}.${fileExt}`;

    // Subir archivo al bucket de Comprobantes
    const { data, error } = await supabase.storage
      .from('Comprobantes')
      .upload(fileName, file, {
        cacheControl: '3600',
        upsert: false
      });

    if (error) throw error;

    // Obtener URL pública del archivo
    const { data: urlData } = await supabase.storage
      .from('Comprobantes')
      .getPublicUrl(fileName);

    return {
      url: urlData.publicUrl,
      codigo: codigo,
      ruta: fileName
    };
  } catch (error) {
    console.error('Error al subir imagen:', error);
    throw error;
  }
};

const CurrencyChangesMobile = () => {
  const [changes, setChanges] = useState([]);
  const [filteredChanges, setFilteredChanges] = useState([]);
  const [cajas, setCajas] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [loading, setLoading] = useState(true);

  // Paginación
  const [page, setPage] = useState(1);
  const itemsPerPage = 10;

  // Estados de UI
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDetailView, setOpenDetailView] = useState(false);
  const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [selectedChange, setSelectedChange] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);

  // Estado para gestionar cuándo mostrar los botones flotantes
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  // Sumario de montos filtrados
  const [summaryVisible, setSummaryVisible] = useState(false);
  const [summaryData, setSummaryData] = useState({
    totalARS: 0,
    totalUSD: 0,
    totalOperaciones: 0
  });

  // FORM STATES
  const [fechaCambio, setFechaCambio] = useState(new Date());
  const [cajaCambio, setCajaCambio] = useState('');
  const [proveedorCambio, setProveedorCambio] = useState('');
  const [tipoCambioCambio, setTipoCambioCambio] = useState('');
  const [monedaInicialCambio, setMonedaInicialCambio] = useState('ARS');
  const [montoInicialCambio, setMontoInicialCambio] = useState('');

  // COMPROBANTE STATES
  const [tieneComprobante, setTieneComprobante] = useState(false);
  const [imagenComprobante, setImagenComprobante] = useState(null);
  const [imagenComprobanteURL, setImagenComprobanteURL] = useState('');
  const comprobanteInputRef = useRef(null);

  // FILTROS STATES
  const [filterCaja, setFilterCaja] = useState('');
  const [filterProveedor, setFilterProveedor] = useState('');
  const [filterMonedaInicial, setFilterMonedaInicial] = useState('');
  const [filterFechaDesde, setFilterFechaDesde] = useState(null);
  const [filterFechaHasta, setFilterFechaHasta] = useState(null);
  const [filterTieneComprobante, setFilterTieneComprobante] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);

  // NOTIFICATION STATES
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // FORM SECTIONS
  const [currentFormSection, setCurrentFormSection] = useState(0);
  const formSections = [
    { title: 'Información Básica', icon: <InfoIcon /> },
    { title: 'Información Financiera', icon: <AttachMoneyIcon /> },
    { title: 'Documentación', icon: <DescriptionIcon /> }
  ];

  // Referencia para el swipe en el diálogo de form
  const formDialogRef = useRef(null);

  // EFFECTS
  useEffect(() => {
    fetchDolarBlue();
    fetchChanges();
    fetchCajas();
    fetchProveedores();
  }, []);

  useEffect(() => {
    applyFilters();
    calculateSummary();
  }, [changes, searchTerm, activeFilters, filterCaja, filterProveedor, filterMonedaInicial, filterFechaDesde, filterFechaHasta, filterTieneComprobante]);

  // FETCH FUNCTIONS
  const fetchDolarBlue = async () => {
    try {
      const response = await fetch('https://dolarapi.com/v1/dolares/blue');
      if (!response.ok) throw new Error('Error al obtener el dólar blue');
      const data = await response.json();
      setTipoCambioCambio(data.compra.toString());
    } catch (error) {
      console.error('Error fetching dólar blue:', error.message);
      // Valor predeterminado en caso de error
      setTipoCambioCambio('1050');
    }
  };

  const fetchChanges = async () => {
    setLoading(true);
    try {
      // Ordenar por fecha descendente (más reciente primero)
      const { data, error } = await supabase
        .from('cambios_de_moneda')
        .select('*')
        .order('fecha_cambio', { ascending: false });

      if (error) throw error;

      // Formatear fechas para mejor visualización
      const formattedData = data.map(item => ({
        ...item,
        fecha_formateada: item.fecha_cambio ? format(new Date(item.fecha_cambio), 'dd/MM/yyyy') : '',
        monto_inicial_formateado: formatCurrency(item.monto_inicial_cambio, item.moneda_inicial_cambio),
        monto_final_formateado: formatCurrency(item.monto_final_cambio, item.moneda_final_cambio),
        imagen_comprobante_cambio: item.imagen_comprobante_cambio ? formatImageUrl(item.imagen_comprobante_cambio) : null
      }));

      setChanges(formattedData || []);
      setFilteredChanges(formattedData || []);
    } catch (error) {
      console.error('Error en fetchChanges:', error);
      setSnackbar({
        open: true,
        message: 'Error al cargar los cambios',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchCajas = async () => {
    try {
      const { data, error } = await supabase.from('cajas_cambios').select('caja');
      if (error) throw error;
      setCajas(data.map((item) => item.caja) || []);
    } catch (error) {
      console.error('Error fetchCajas:', error);
    }
  };

  const fetchProveedores = async () => {
    try {
      const { data, error } = await supabase.from('proveedores_de_cambio_de_moneda').select('proveedor_cambio_de_moneda');
      if (error) throw error;
      setProveedores(data.map((item) => item.proveedor_cambio_de_moneda) || []);
    } catch (error) {
      console.error('Error fetchProveedores:', error);
    }
  };

  // UTILITY FUNCTIONS
  const formatCurrency = (amount, currency) => {
    if (!amount) return '';
    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currency === 'USD' ? 'USD' : 'ARS',
      minimumFractionDigits: 2
    });
    return formatter.format(amount);
  };

  const getMonedaFinal = (monedaInicial) => {
    return monedaInicial === 'ARS' ? 'USD' : 'ARS';
  };

  const getMontoFinal = (monedaInicial, montoInicial, tipoCambio) => {
    const monto = parseFloat(montoInicial) || 0;
    const tipo = parseFloat(tipoCambio) || 0;
    if (monedaInicial === 'ARS' && tipo > 0) return (monto / tipo).toFixed(2);
    if (monedaInicial === 'USD' && tipo > 0) return (monto * tipo).toFixed(2);
    return '';
  };

  // Calcular el sumario de montos
  const calculateSummary = () => {
    let totalARS = 0;
    let totalUSD = 0;
    let totalOperaciones = filteredChanges.length;

    filteredChanges.forEach(cambio => {
      if (cambio.moneda_inicial_cambio === 'ARS') {
        totalARS += parseFloat(cambio.monto_inicial_cambio || 0);
      } else if (cambio.moneda_inicial_cambio === 'USD') {
        totalUSD += parseFloat(cambio.monto_inicial_cambio || 0);
      }
    });

    setSummaryData({
      totalARS: totalARS.toFixed(2),
      totalUSD: totalUSD.toFixed(2),
      totalOperaciones
    });

    // Mostrar el sumario solo si hay filtros activos o búsqueda
    setSummaryVisible(activeFilters.length > 0 || searchTerm !== '');
  };

  // Función para ajustar el tipo de cambio
  const adjustTipoCambio = (amount) => {
    const currentValue = parseFloat(tipoCambioCambio) || 0;
    const newValue = Math.max(0, currentValue + amount);
    setTipoCambioCambio(newValue.toString());
  };

  const applyFilters = () => {
    let filtered = [...changes];

    // Búsqueda global
    if (searchTerm) {
      filtered = filtered.filter(cambio =>
        Object.values(cambio).some(value =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Filtros específicos
    if (filterCaja) {
      filtered = filtered.filter(cambio => cambio.caja_cambio === filterCaja);
    }

    if (filterProveedor) {
      filtered = filtered.filter(cambio => cambio.proveedor_de_cambio === filterProveedor);
    }

    if (filterMonedaInicial) {
      filtered = filtered.filter(cambio => cambio.moneda_inicial_cambio === filterMonedaInicial);
    }

    if (filterFechaDesde) {
      filtered = filtered.filter(cambio =>
        new Date(cambio.fecha_cambio) >= new Date(filterFechaDesde)
      );
    }

    if (filterFechaHasta) {
      filtered = filtered.filter(cambio =>
        new Date(cambio.fecha_cambio) <= new Date(filterFechaHasta)
      );
    }

    if (filterTieneComprobante !== null) {
      filtered = filtered.filter(cambio => cambio.tiene_comprobante_cambio === filterTieneComprobante);
    }

    setFilteredChanges(filtered);
  };

  const resetFilters = () => {
    setFilterCaja('');
    setFilterProveedor('');
    setFilterMonedaInicial('');
    setFilterFechaDesde(null);
    setFilterFechaHasta(null);
    setFilterTieneComprobante(null);
    setActiveFilters([]);
    setSearchTerm('');
    setSummaryVisible(false);
  };

  const applyFilterSelection = () => {
    // Crear array de filtros activos para mostrar badges
    const newFilters = [];

    if (filterCaja) {
      newFilters.push({ type: 'caja', value: filterCaja });
    }

    if (filterProveedor) {
      newFilters.push({ type: 'proveedor', value: filterProveedor });
    }

    if (filterMonedaInicial) {
      newFilters.push({ type: 'monedaInicial', value: filterMonedaInicial });
    }

    if (filterFechaDesde) {
      newFilters.push({ type: 'fechaDesde', value: format(new Date(filterFechaDesde), 'dd/MM/yy') });
    }

    if (filterFechaHasta) {
      newFilters.push({ type: 'fechaHasta', value: format(new Date(filterFechaHasta), 'dd/MM/yy') });
    }

    if (filterTieneComprobante !== null) {
      newFilters.push({
        type: 'tieneComprobante',
        value: filterTieneComprobante ? 'Con comprobante' : 'Sin comprobante'
      });
    }

    setActiveFilters(newFilters);
    setOpenFiltersDrawer(false);
    applyFilters();
    calculateSummary();
  };

  // Función para resetear formulario
  const resetForm = () => {
    setFechaCambio(new Date());
    setCajaCambio('');
    setProveedorCambio('');
    setTipoCambioCambio('');
    setMonedaInicialCambio('ARS');
    setMontoInicialCambio('');

    // Resetear estados de comprobante
    setTieneComprobante(false);
    setImagenComprobante(null);
    setImagenComprobanteURL('');
    if (comprobanteInputRef.current) {
      comprobanteInputRef.current.value = '';
    }
  };

  // FORM HANDLERS
  const handleOpenAddDialog = () => {
    setEditMode(false);
    resetForm();
    fetchDolarBlue();
    setCurrentFormSection(0);
    setOpenAddDialog(true);
  };

  const handleOpenEditDialog = (cambio) => {
    setEditMode(true);
    setSelectedChange(cambio);
    populateFormWithChange(cambio);
    setCurrentFormSection(0);
    setOpenAddDialog(true);
  };

  const populateFormWithChange = (cambio) => {
    // Datos básicos
    setFechaCambio(new Date(cambio.fecha_cambio));
    setCajaCambio(cambio.caja_cambio || '');
    setProveedorCambio(cambio.proveedor_de_cambio || '');
    setTipoCambioCambio(cambio.tipo_de_cambio_cambio || '');
    setMonedaInicialCambio(cambio.moneda_inicial_cambio || 'ARS');
    setMontoInicialCambio(cambio.monto_inicial_cambio || '');

    // Comprobante
    setTieneComprobante(cambio.tiene_comprobante_cambio || false);
    setImagenComprobanteURL(cambio.imagen_comprobante_cambio || '');
  };

  const handleComprobanteChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificar tipo de archivo
      if (!file.type.match('image.*')) {
        setSnackbar({
          open: true,
          message: 'Por favor, selecciona un archivo de imagen válido',
          severity: 'error'
        });
        return;
      }

      // Verificar tamaño (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setSnackbar({
          open: true,
          message: 'La imagen debe ser menor a 5MB',
          severity: 'error'
        });
        return;
      }

      setImagenComprobante(file);
      setTieneComprobante(true);

      // Crear URL temporal para vista previa
      const previewURL = URL.createObjectURL(file);
      setImagenComprobanteURL(previewURL);
    }
  };

  const handleComprobanteUpload = () => {
    comprobanteInputRef.current.click();
  };

  const removeComprobante = () => {
    setImagenComprobante(null);
    setImagenComprobanteURL('');

    // Limpiar el input de archivo
    if (comprobanteInputRef.current) {
      comprobanteInputRef.current.value = '';
    }
  };

  const handleSubmitForm = async () => {
    // Validar campos obligatorios
    if (!fechaCambio || !cajaCambio || !proveedorCambio || !tipoCambioCambio || !monedaInicialCambio || !montoInicialCambio) {
      setSnackbar({
        open: true,
        message: 'Por favor, completa todos los campos obligatorios',
        severity: 'error'
      });
      return;
    }

    // Validaciones adicionales para documentos
    if (tieneComprobante && !imagenComprobante && !imagenComprobanteURL) {
      setSnackbar({
        open: true,
        message: 'Por favor, sube una imagen del comprobante',
        severity: 'error'
      });
      return;
    }

    try {
      setLoading(true);

      let imageData = null;

      // Si estamos en edición y ya hay imágenes previas, mantenerlas
      const cambioId = editMode && selectedChange ? selectedChange.id : null;
      let imagenComprobanteExistente = editMode && selectedChange ? selectedChange.imagen_comprobante_cambio : null;
      let rutaComprobanteExistente = editMode && selectedChange ? selectedChange.ruta_archivo_comprobante_cambio : null;

      // Subir comprobante si existe una nueva imagen
      if (tieneComprobante && imagenComprobante) {
        // Si hay edición y hay imagen previa, eliminarla
        if (editMode && rutaComprobanteExistente) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([rutaComprobanteExistente]);
          } catch (err) {
            console.error("Error al eliminar comprobante existente:", err);
            // Continuar aunque falle la eliminación
          }
        }

        try {
          imageData = await uploadImage(imagenComprobante, {
            tipo: 'cambio_moneda',
            referencia: cajaCambio,
            proveedor: proveedorCambio
          });
        } catch (uploadError) {
          throw new Error('Error al subir el comprobante: ' + uploadError.message);
        }
      }

      // Asegurarse que todos los datos numéricos sean números válidos
      const validMontoInicial = parseFloat(montoInicialCambio);
      const validTipoCambio = parseFloat(tipoCambioCambio);
      const montoFinalCambio = getMontoFinal(monedaInicialCambio, montoInicialCambio, tipoCambioCambio);
      const monedaFinalCambio = getMonedaFinal(monedaInicialCambio);

      // Preparar datos para guardar
      const cambioData = {
        fecha_cambio: fechaCambio.toISOString(),
        caja_cambio: cajaCambio,
        proveedor_de_cambio: proveedorCambio,
        tipo_de_cambio_cambio: validTipoCambio,
        moneda_inicial_cambio: monedaInicialCambio,
        monto_inicial_cambio: validMontoInicial,
        moneda_final_cambio: monedaFinalCambio,
        monto_final_cambio: montoFinalCambio,

        // Datos de comprobante
        tiene_comprobante_cambio: tieneComprobante,
        imagen_comprobante_cambio: tieneComprobante
          ? (imageData ? imageData.url : imagenComprobanteExistente)
          : null,
        codigo_de_comprobante_cambio: tieneComprobante && imageData
          ? imageData.codigo
          : selectedChange?.codigo_de_comprobante_cambio,
        ruta_archivo_comprobante_cambio: tieneComprobante
          ? (imageData ? imageData.ruta : rutaComprobanteExistente)
          : null,
      };

      let response;
      if (editMode && cambioId) {
        // Actualizar cambio existente
        response = await supabase
          .from('cambios_de_moneda')
          .update(cambioData)
          .eq('id', cambioId);
      } else {
        // Crear nuevo cambio
        response = await supabase
          .from('cambios_de_moneda')
          .insert([cambioData]);
      }

      if (response.error) {
        throw new Error(
          (editMode ? 'Error al actualizar cambio: ' : 'Error al agregar cambio: ') +
          (response.error.message || 'Por favor, verifica los datos e intenta nuevamente')
        );
      }

      // Recargar datos
      fetchChanges();

      setSnackbar({
        open: true,
        message: editMode ? 'Cambio actualizado con éxito' : 'Cambio agregado con éxito',
        severity: 'success'
      });

      setOpenAddDialog(false);
      resetForm();

    } catch (error) {
      console.error('Error al guardar cambio:', error);
      setSnackbar({
        open: true,
        message: error.message || "Error al procesar la solicitud. Intenta nuevamente.",
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Función para animar la eliminación
  const animateItemDelete = () => {
    // Establecer referencia al elemento de la vista detallada si existe
    if (openDetailView && selectedChange) {
      const detailElement = document.querySelector('.mobile-detail-view');
      if (detailElement) {
        detailElement.style.transition = 'all 0.5s ease';
        detailElement.style.transform = 'scale(0.8)';
        detailElement.style.opacity = '0';
      }
    }

    // Esperar a que termine la animación antes de ejecutar la eliminación
    setTimeout(() => {
      if (selectedChange) {
        handleDeleteChange(selectedChange);
      }
    }, 300);
  };

  const handleDeleteChange = async (cambio, event) => {
    if (event) {
      event.stopPropagation();
    }

    try {
      setLoading(true);

      // Eliminar archivos si existen
      if (cambio.tiene_comprobante_cambio && cambio.ruta_archivo_comprobante_cambio) {
        try {
          await supabase.storage
            .from('Comprobantes')
            .remove([cambio.ruta_archivo_comprobante_cambio]);
        } catch (error) {
          console.error('Error al eliminar el archivo de comprobante:', error);
          // Continuar aunque falle la eliminación
        }
      }

      // Eliminar el registro
      const { error } = await supabase
        .from('cambios_de_moneda')
        .delete()
        .eq('id', cambio.id);

      if (error) {
        throw error;
      }

      setSnackbar({
        open: true,
        message: 'Cambio eliminado con éxito',
        severity: 'success'
      });

      fetchChanges();

      // Cerrar vista de detalle si está abierta
      if (openDetailView && selectedChange && selectedChange.id === cambio.id) {
        setOpenDetailView(false);
      }

      // Cerrar diálogo de confirmación
      setDeleteConfirmOpen(false);

    } catch (error) {
      console.error('Error al eliminar cambio:', error);
      setSnackbar({
        open: true,
        message: 'Error al eliminar cambio: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // VIEW HANDLERS
  const handleOpenDetailView = (cambio) => {
    setSelectedChange(cambio);
    setOpenDetailView(true);
  };

  const handleOpenImagePreview = (imageUrl, event) => {
    if (event) {
      event.stopPropagation();
    }
    setCurrentImage(imageUrl);
    setOpenImagePreview(true);
  };

  const handleNextFormSection = () => {
    if (currentFormSection < formSections.length - 1) {
      setCurrentFormSection(prevSection => prevSection + 1);
    }
  };

  const handlePrevFormSection = () => {
    if (currentFormSection > 0) {
      setCurrentFormSection(prevSection => prevSection - 1);
    }
  };

  // Handle swipe down to close dialog
  const handleFormDialogSwipe = (e) => {
    if (e.target.scrollTop === 0 && e.deltaY < -50) {
      setOpenAddDialog(false);
    }
  };

  // Contenido del formulario según la sección actual
  const renderFormContent = () => {
    switch (currentFormSection) {
      case 0: // Información Básica
        return (
          <Box>
            <FormSectionTitle>
              <InfoIcon /> Información Básica
            </FormSectionTitle>

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <DatePicker
                label="Fecha"
                value={fechaCambio}
                onChange={(newValue) => setFechaCambio(newValue)}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>

            <StyledFormControl fullWidth>
              <InputLabel>Caja</InputLabel>
              <Select
                value={cajaCambio}
                onChange={(e) => setCajaCambio(e.target.value)}
                label="Caja"
              >
                {cajas.map((caja) => (
                  <MenuItem key={caja} value={caja}>
                    {caja}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>

            <StyledFormControl fullWidth>
              <InputLabel>Proveedor</InputLabel>
              <Select
                value={proveedorCambio}
                onChange={(e) => setProveedorCambio(e.target.value)}
                label="Proveedor"
              >
                {proveedores.map((prov) => (
                  <MenuItem key={prov} value={prov}>
                    {prov}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>
          </Box>
        );

      case 1: // Información Financiera
        return (
          <Box>
            <FormSectionTitle>
              <AttachMoneyIcon /> Información Financiera
            </FormSectionTitle>

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <StyledFormControl fullWidth>
                  <InputLabel>Moneda Inicial</InputLabel>
                  <Select
                    value={monedaInicialCambio}
                    onChange={(e) => setMonedaInicialCambio(e.target.value)}
                    label="Moneda Inicial"
                  >
                    <MenuItem value="ARS">ARS</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                  </Select>
                </StyledFormControl>
              </Grid>
              <Grid item xs={6}>
                <StyledTextField
                  label="Tipo de Cambio"
                  value={tipoCambioCambio}
                  onChange={(e) => setTipoCambioCambio(e.target.value)}
                  fullWidth
                  type="number"
                  InputProps={{
                    startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  }}
                />
              </Grid>
            </Grid>

            {/* Controles para ajustar el tipo de cambio */}
            <TipoCambioControl>
              <Typography variant="body2" sx={{ flex: 1 }}>Ajustar tipo de cambio:</Typography>
              <TcStepButton color="error" onClick={() => adjustTipoCambio(-5)}>
                -5
              </TcStepButton>
              <TcStepButton onClick={() => adjustTipoCambio(5)}>
                +5
              </TcStepButton>
              <Tooltip title="Actualizar al valor actual">
                <IconButton
                  size="small"
                  onClick={fetchDolarBlue}
                  sx={{ ml: 1, bgcolor: 'rgba(46, 125, 50, 0.1)', color: 'primary.main' }}
                >
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TipoCambioControl>

            <StyledTextField
              label="Monto Inicial"
              value={montoInicialCambio}
              onChange={(e) => setMontoInicialCambio(e.target.value)}
              fullWidth
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">{monedaInicialCambio === 'USD' ? '$' : '$'}</InputAdornment>,
              }}
            />

            <Box sx={{ mt: 3, p: 2, bgcolor: 'rgba(46, 125, 50, 0.04)', borderRadius: 3 }}>
              <Typography variant="subtitle2" sx={{ color: 'primary.main', mb: 1 }}>
                Resumen de la operación
              </Typography>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography variant="body2">Moneda Final:</Typography>
                <Chip
                  label={getMonedaFinal(monedaInicialCambio)}
                  color={monedaInicialCambio === 'ARS' ? 'primary' : 'warning'}
                  size="small"
                  variant="outlined"
                />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body2">Monto Final:</Typography>
                <Typography variant="body1" fontWeight="medium">
                  {formatCurrency(
                    getMontoFinal(monedaInicialCambio, montoInicialCambio, tipoCambioCambio),
                    getMonedaFinal(monedaInicialCambio)
                  )}
                </Typography>
              </Box>
            </Box>
          </Box>
        );

      case 2: // Documentación
        return (
          <Box>
            <FormSectionTitle>
              <DescriptionIcon /> Documentación
            </FormSectionTitle>

            <SwitchFormControl
              control={
                <Switch
                  checked={tieneComprobante}
                  onChange={(e) => setTieneComprobante(e.target.checked)}
                  color="primary"
                />
              }
              label="¿Tiene comprobante?"
              labelPlacement="start"
            />

            {tieneComprobante && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <input
                  ref={comprobanteInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleComprobanteChange}
                  style={{ display: 'none' }}
                />

                {!imagenComprobanteURL ? (
                  <FileUploadBox onClick={handleComprobanteUpload}>
                    <CameraAltIcon sx={{ fontSize: 40, color: 'primary.main', mb: 1 }} />
                    <Typography variant="body1" gutterBottom>
                      Toca para subir una imagen de comprobante
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      JPG, PNG (máx. 5MB)
                    </Typography>
                  </FileUploadBox>
                ) : (
                  <Box sx={{ position: 'relative', mb: 2 }}>
                    <ImagePreviewBox>
                      <Box
                        component="img"
                        src={imagenComprobanteURL}
                        alt="Vista previa del comprobante"
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </ImagePreviewBox>

                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
                      }}
                      onClick={removeComprobante}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </motion.div>
            )}
          </Box>
        );

      default:
        return null;
    }
  };

  // Renderización de las tarjetas de cambio para la vista principal
  const renderCurrencyCard = (cambio) => {
    // Función para animar la eliminación desde la tarjeta
    const animateCardDelete = (e) => {
      e.stopPropagation();
      setDeleteConfirmOpen(true);
      setSelectedChange(cambio);
    };

    const monedaInicial = cambio.moneda_inicial_cambio;
    const monedaFinal = cambio.moneda_final_cambio;

    return (
      <CurrencyCard
        key={cambio.id}
        onClick={() => handleOpenDetailView(cambio)}
        component={motion.div}
        whileHover={{ y: -4 }}
        whileTap={{ scale: 0.98 }}
      >
        <CardContent sx={{ p: 2, pb: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                sx={{
                  bgcolor: monedaInicial === 'USD' ? 'rgba(255, 193, 7, 0.1)' : 'rgba(46, 125, 50, 0.1)',
                  color: monedaInicial === 'USD' ? '#f57c00' : '#2e7d32',
                  mr: 1.5,
                  width: 40,
                  height: 40,
                  fontSize: '0.9rem',
                  fontWeight: 'bold'
                }}
              >
                {monedaInicial}
              </Avatar>
              <Box>
                <Typography variant="body1" fontWeight="bold">
                  {cambio.monto_inicial_formateado}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  TC: {cambio.tipo_de_cambio_cambio}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2" fontWeight="medium" color="primary">
                {cambio.fecha_formateada}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {cambio.caja_cambio}
              </Typography>
            </Box>
          </Box>

          <CardRow>
            <Typography variant="body2" color="text.secondary">Moneda Final:</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <ExchangeIcon sx={{ mr: 1, fontSize: '1rem' }} />
              <CurrencyChip
                label={cambio.monto_final_formateado}
                currency={monedaFinal}
                size="small"
              />
            </Box>
          </CardRow>

          <CardRow>
            <Typography variant="body2" color="text.secondary">Proveedor:</Typography>
            <Typography variant="body2" fontWeight="medium" noWrap sx={{ maxWidth: '60%', textAlign: 'right' }}>
              {cambio.proveedor_de_cambio}
            </Typography>
          </CardRow>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            {cambio.tiene_comprobante_cambio && (
              <DocChip
                icon={<ReceiptIcon />}
                label="Comprobante"
                size="small"
                color="success"
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenImagePreview(cambio.imagen_comprobante_cambio, e);
                }}
              />
            )}

            <Box sx={{ ml: 'auto', display: 'flex' }}>
              <IconButton
                size="small"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenEditDialog(cambio);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>

              <IconButton
                size="small"
                color="error"
                onClick={animateCardDelete}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </CurrencyCard>
    );
  };

  // RENDER
  return (
    <>
      <MobileContainer sx={{ backgroundColor: '#f8f9fa' }}>
        {/* Barra de búsqueda con botón de filtro separado */}
        <SearchBarContainer disappear={trigger}>
          <SearchBar elevation={1}>
            <InputBase
              placeholder="Buscar cambios..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              sx={{ ml: 1 }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </SearchBar>
          <FilterButton
            startIcon={<FilterAltIcon />}
            onClick={() => setOpenFiltersDrawer(true)}
            variant="outlined"
          >
            Filtros
          </FilterButton>
        </SearchBarContainer>

        {/* Botones flotantes que aparecen al hacer scroll */}
        <Zoom in={trigger && !openDetailView}>
          <FixedSearchContainer>
            <FloatingSearchButton
              color="primary"
              onClick={() => {
                setSearchTerm('');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              <SearchIcon />
            </FloatingSearchButton>
            <FloatingFilterButton
              color="primary"
              onClick={() => setOpenFiltersDrawer(true)}
            >
              <FilterAltIcon />
            </FloatingFilterButton>
          </FixedSearchContainer>
        </Zoom>

        {/* Sumario financiero */}
        <AnimatePresence>
          {summaryVisible && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <SummaryPanel>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <SummarizeIcon sx={{ mr: 1, color: 'primary.main' }} />
                  <Typography variant="subtitle1" fontWeight="bold">
                    Resumen Financiero
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton size="small" onClick={() => setSummaryVisible(false)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <SummaryRow>
                  <Typography variant="body2" color="text.secondary">Total ARS:</Typography>
                  <Typography variant="body1" fontWeight="bold" color="primary.main">
                    {formatCurrency(summaryData.totalARS, 'ARS')}
                  </Typography>
                </SummaryRow>
                <SummaryRow>
                  <Typography variant="body2" color="text.secondary">Total USD:</Typography>
                  <Typography variant="body1" fontWeight="bold" color="#f57c00">
                    {formatCurrency(summaryData.totalUSD, 'USD')}
                  </Typography>
                </SummaryRow>
                <Divider sx={{ my: 1 }} />
                <SummaryRow>
                  <Typography variant="body2" color="text.secondary">Operaciones:</Typography>
                  <Typography variant="body1" fontWeight="bold">
                    {summaryData.totalOperaciones}
                  </Typography>
                </SummaryRow>
              </SummaryPanel>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Filtros activos */}
        {activeFilters.length > 0 && (
          <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap' }}>
            {activeFilters.map((filter, index) => (
              <Chip
                key={index}
                label={`${filter.type === 'caja' ? 'Caja: ' :
                  filter.type === 'proveedor' ? 'Prov: ' :
                    filter.type === 'monedaInicial' ? 'Moneda: ' :
                      filter.type === 'fechaDesde' ? 'Desde: ' :
                        filter.type === 'fechaHasta' ? 'Hasta: ' :
                          filter.type === 'tieneComprobante' ? '' : ''}${filter.value}`}
                onDelete={() => {
                  const newFilters = [...activeFilters];
                  newFilters.splice(index, 1);
                  setActiveFilters(newFilters);

                  // También resetear el estado correspondiente
                  if (filter.type === 'caja') setFilterCaja('');
                  if (filter.type === 'proveedor') setFilterProveedor('');
                  if (filter.type === 'monedaInicial') setFilterMonedaInicial('');
                  if (filter.type === 'fechaDesde') setFilterFechaDesde(null);
                  if (filter.type === 'fechaHasta') setFilterFechaHasta(null);
                  if (filter.type === 'tieneComprobante') setFilterTieneComprobante(null);

                  // Recalcular sumario si aún hay filtros activos
                  if (newFilters.length === 0 && !searchTerm) {
                    setSummaryVisible(false);
                  }
                }}
                size="small"
                sx={{ m: 0.5, borderRadius: 16, py: 1 }}
              />
            ))}
            <Chip
              label="Limpiar"
              onClick={resetFilters}
              size="small"
              color="primary"
              sx={{ m: 0.5, borderRadius: 16, py: 1 }}
            />
          </Box>
        )}

        {/* Lista de cambios */}
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          {loading && filteredChanges.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', flexDirection: 'column' }}>
              <CircularProgress color="primary" />
              <Typography variant="body1" sx={{ mt: 2 }}>Cargando cambios...</Typography>
            </Box>
          ) : filteredChanges.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', flexDirection: 'column' }}>
              <Typography variant="body1">No se encontraron cambios de moneda</Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Intenta con otros criterios de búsqueda o crea un nuevo cambio
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleOpenAddDialog}
                sx={{ mt: 2, borderRadius: 20, px: 3, py: 1 }}
              >
                Nuevo Cambio
              </Button>
            </Box>
          ) : (
            <>
              {filteredChanges
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map(renderCurrencyCard)}
            </>
          )}
        </motion.div>

        {/* Botón flotante para agregar */}
        <AddFab
          color="primary"
          aria-label="add"
          onClick={handleOpenAddDialog}
        >
          <AddIcon />
        </AddFab>

        {/* Dialog para agregar/editar cambio */}
        <FullWidthDialog
          open={openAddDialog}
          onClose={() => setOpenAddDialog(false)}
          TransitionComponent={Slide}
          TransitionProps={{ direction: 'up' }}
          fullWidth
          maxWidth="sm"
          keepMounted={false}
        >
          <FormContainer>
            {/* Header - Con capacidad de deslizar hacia abajo para cerrar */}
            <FormHeader
              ref={formDialogRef}
              onWheel={handleFormDialogSwipe}
              component={motion.div}
              whileTap={{ cursor: 'grabbing' }}
              sx={{ cursor: 'grab' }}
            >
              <CloseButton onClick={() => setOpenAddDialog(false)}>
                <CloseIcon />
              </CloseButton>
              <Typography variant="h6" sx={{ ml: 2, fontWeight: 600 }}>
                {editMode ? 'Editar Cambio' : 'Nuevo Cambio'}
              </Typography>
            </FormHeader>

            {/* Selector de pasos */}
            <StepButtonContainer>
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                {formSections.map((_, index) => (
                  <StepIndicator
                    key={index}
                    active={currentFormSection === index}
                    onClick={() => setCurrentFormSection(index)}
                    sx={{ cursor: 'pointer' }}
                  />
                ))}
              </Box>
            </StepButtonContainer>

            {/* Contenido del formulario con animación */}
            <FormContent>
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentFormSection}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  {renderFormContent()}
                </motion.div>
              </AnimatePresence>
            </FormContent>

            {/* Footer con botones de navegación */}
            <FormFooter>
              {currentFormSection > 0 && (
                <StepButton
                  variant="prev"
                  onClick={handlePrevFormSection}
                  startIcon={<KeyboardArrowLeftIcon />}
                >
                  Anterior
                </StepButton>
              )}
              {currentFormSection < formSections.length - 1 ? (
                <StepButton
                  variant="next"
                  onClick={handleNextFormSection}
                  endIcon={<KeyboardArrowRightIcon />}
                  sx={{ ml: 'auto' }}
                >
                  Siguiente
                </StepButton>
              ) : (
                <StyledSaveButton
                  onClick={handleSubmitForm}
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                  sx={{ ml: 'auto' }}
                >
                  {editMode ? 'Actualizar' : 'Guardar'}
                </StyledSaveButton>
              )}
            </FormFooter>
          </FormContainer>
        </FullWidthDialog>

        {/* Vista detallada de cambio */}
        <Drawer
          anchor="right"
          open={openDetailView}
          onClose={() => setOpenDetailView(false)}
          PaperProps={{
            sx: {
              width: '100%',
              backgroundColor: 'transparent',
              boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
              zIndex: 1300,
              transition: 'box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1)',
              '& ~ .MuiBackdrop-root': {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                backdropFilter: 'blur(0px) brightness(100%)',
                transition: 'backdrop-filter 0.7s cubic-bezier(0.25, 0.8, 0.25, 1)',
                '&.MuiBackdrop-root.MuiModal-backdrop': {
                  backgroundColor: 'rgba(0, 0, 0, 0.05)',
                  backdropFilter: 'blur(4px) brightness(97%)',
                },
              },
              '& .mobile-detail-view': {
                boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
                transition: 'box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1)',
                '&:hover': {
                  boxShadow: '0 5px 25px rgba(0, 0, 0, 0.12)',
                },
              },
            }
          }}
          SlideProps={{
            timeout: 400
          }}
          transitionDuration={400}
        >
          {selectedChange && (
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              background: 'rgba(0, 0, 0, 0.4)',
              backdropFilter: 'blur(2px)',
              transition: 'background 0.4s ease-in-out, backdrop-filter 0.4s ease-in-out',
            }}>
              <MobileDetailView
                className="mobile-detail-view"
                component={motion.div}
                initial={{ opacity: 0, y: 20, scale: 0.95 }}
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: 20, scale: 0.95 }}
                transition={{
                  duration: 0.35,
                  type: 'spring',
                  stiffness: 300,
                  damping: 25,
                  bounce: 0.1
                }}
              >
                <Box sx={{ position: 'relative' }}>
                  <AppBar
                    position="sticky"
                    color="transparent"
                    elevation={0}
                    sx={{
                      bgcolor: 'white',
                      borderBottom: '1px solid rgba(0,0,0,0.05)',
                      borderTopLeftRadius: 28,
                      borderTopRightRadius: 28,
                    }}
                  >
                    <Toolbar>
                      <IconButton edge="start" onClick={() => setOpenDetailView(false)}>
                        <ArrowBackIcon />
                      </IconButton>
                      <Typography variant="h6" fontWeight="medium" sx={{ ml: 1 }}>
                        Detalles del Cambio
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenEditDialog(selectedChange)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => setDeleteConfirmOpen(true)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Toolbar>
                  </AppBar>

                  <Box sx={{ p: 2, overflowY: 'auto', height: 'calc(85vh - 64px)', backgroundColor: '#f8f9fa' }}>
                    {/* Cabecera con montos y tipo de cambio */}
                    <DetailSection>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Box>
                          <Typography variant="body2" color="text.secondary">Fecha</Typography>
                          <Typography variant="h6" fontWeight="bold">{selectedChange.fecha_formateada}</Typography>
                        </Box>
                        <Box sx={{ textAlign: 'right' }}>
                          <Typography variant="body2" color="text.secondary">Tipo de Cambio</Typography>
                          <Typography variant="h6" fontWeight="bold" color="primary">
                            $ {selectedChange.tipo_de_cambio_cambio}
                          </Typography>
                        </Box>
                      </Box>

                      <Divider sx={{ my: 1 }} />

                      {/* Montos de cambio */}
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          p: 2,
                          borderRadius: 3,
                          backgroundColor: selectedChange.moneda_inicial_cambio === 'USD' ? 'rgba(25, 118, 210, 0.05)' : 'rgba(46, 125, 50, 0.05)'
                        }}>
                          <Box>
                            <Typography variant="body2" color="text.secondary">Moneda Inicial</Typography>
                            <Typography variant="h6" fontWeight="bold" color={selectedChange.moneda_inicial_cambio === 'USD' ? '#1976d2' : 'primary'}>
                              {selectedChange.moneda_inicial_cambio}
                            </Typography>
                          </Box>
                          <Typography variant="h6" fontWeight="bold">
                            {selectedChange.monto_inicial_formateado}
                          </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 1 }}>
                          <ExchangeIcon sx={{ fontSize: '2rem' }} />
                        </Box>

                        <Box sx={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          p: 2,
                          borderRadius: 3,
                          backgroundColor: selectedChange.moneda_final_cambio === 'USD' ? 'rgba(25, 118, 210, 0.05)' : 'rgba(46, 125, 50, 0.05)'
                        }}>
                          <Box>
                            <Typography variant="body2" color="text.secondary">Moneda Final</Typography>
                            <Typography variant="h6" fontWeight="bold" color={selectedChange.moneda_final_cambio === 'USD' ? '#1976d2' : 'primary'}>
                              {selectedChange.moneda_final_cambio}
                            </Typography>
                          </Box>
                          <Typography variant="h6" fontWeight="bold">
                            {selectedChange.monto_final_formateado}
                          </Typography>
                        </Box>
                      </Box>
                    </DetailSection>

                    {/* Información adicional */}
                    <DetailSection>
                      <DetailSectionTitle>
                        <AssignmentIcon /> Información adicional
                      </DetailSectionTitle>

                      <DetailRow>
                        <DetailLabel>Caja</DetailLabel>
                        <DetailValue>{selectedChange.caja_cambio}</DetailValue>
                      </DetailRow>

                      <DetailRow>
                        <DetailLabel>Proveedor</DetailLabel>
                        <DetailValue>{selectedChange.proveedor_de_cambio}</DetailValue>
                      </DetailRow>
                    </DetailSection>

                    {/* Comprobante */}
                    {selectedChange.tiene_comprobante_cambio && (
                      <DetailSection>
                        <DetailSectionTitle>
                          <ReceiptIcon /> Comprobante
                        </DetailSectionTitle>

                        <Box
                          sx={{
                            width: '100%',
                            borderRadius: 3,
                            overflow: 'hidden',
                            border: '1px solid rgba(0,0,0,0.1)',
                            mb: 1
                          }}
                          onClick={() => handleOpenImagePreview(selectedChange.imagen_comprobante_cambio)}
                        >
                          <img
                            src={selectedChange.imagen_comprobante_cambio}
                            alt="Comprobante"
                            style={{
                              width: '100%',
                              maxHeight: 200,
                              objectFit: 'contain',
                              backgroundColor: '#f9f9f9',
                              padding: 4
                            }}
                          />
                        </Box>

                        {selectedChange.codigo_de_comprobante_cambio && (
                          <Box sx={{
                            p: 1.5,
                            backgroundColor: 'rgba(46, 125, 50, 0.05)',
                            borderRadius: 2,
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                          }}>
                            <Typography variant="caption" fontFamily="monospace" noWrap sx={{ maxWidth: '80%' }}>
                              {selectedChange.codigo_de_comprobante_cambio}
                            </Typography>
                            <IconButton
                              size="small"
                              onClick={() => {
                                navigator.clipboard.writeText(selectedChange.codigo_de_comprobante_cambio);
                                setSnackbar({
                                  open: true,
                                  message: 'Código copiado al portapapeles',
                                  severity: 'success'
                                });
                              }}
                            >
                              <ContentCopyIcon fontSize="small" />
                            </IconButton>
                          </Box>
                        )}
                      </DetailSection>
                    )}
                  </Box>
                </Box>
              </MobileDetailView>
            </Box>
          )}
        </Drawer>

        {/* Diálogo de confirmación de eliminación */}
        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
          PaperProps={{
            sx: {
              borderRadius: '24px',
              padding: 2,
              maxWidth: '85%'
            }
          }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
          >
            <Box sx={{ p: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box
                  sx={{
                    width: 48,
                    height: 48,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'rgba(211, 47, 47, 0.1)',
                    color: 'error.main',
                    mr: 2
                  }}
                >
                  <DeleteIcon fontSize="medium" />
                </Box>
                <Typography variant="h6">¿Eliminar cambio?</Typography>
              </Box>

              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Esta acción eliminará permanentemente el cambio de {selectedChange?.moneda_inicial_cambio} {selectedChange?.monto_inicial_formateado} del {selectedChange?.fecha_formateada}.
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button
                  variant="outlined"
                  onClick={() => setDeleteConfirmOpen(false)}
                  sx={{ borderRadius: 8, textTransform: 'none' }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setDeleteConfirmOpen(false);
                    // Animación de salida antes de eliminar
                    animateItemDelete();
                  }}
                  sx={{ borderRadius: 8, textTransform: 'none' }}
                >
                  Eliminar
                </Button>
              </Box>
            </Box>
          </motion.div>
        </Dialog>

        {/* Dialog para vista previa de imágenes */}
        <Dialog
          open={openImagePreview}
          onClose={() => setOpenImagePreview(false)}
          maxWidth="lg"
          PaperProps={{
            sx: {
              borderRadius: 3,
              bgcolor: 'rgba(0,0,0,0.9)',
              p: 0
            }
          }}
        >
          <IconButton
            onClick={() => setOpenImagePreview(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              bgcolor: 'rgba(0,0,0,0.3)',
              zIndex: 1
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
              width: '100vw',
              maxWidth: '100%'
            }}
          >
            {currentImage && (
              <motion.img
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
                src={currentImage}
                alt="Vista ampliada"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}
              />
            )}
          </Box>
        </Dialog>

        {/* Drawer para filtros */}
        <SwipeableDrawer
          anchor="bottom"
          open={openFiltersDrawer}
          onClose={() => setOpenFiltersDrawer(false)}
          onOpen={() => setOpenFiltersDrawer(true)}
          disableSwipeToOpen={false}
          swipeAreaWidth={30}
          ModalProps={{
            keepMounted: true,
            sx: {
              '& .MuiBackdrop-root': {
                backgroundColor: 'rgba(0, 0, 0, 0)',
                backdropFilter: 'blur(0px) brightness(100%)',
                transition: 'backdrop-filter 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
                '&.MuiBackdrop-root.MuiModal-backdrop': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)',
                  backdropFilter: 'blur(6px) brightness(96%)',
                },
              },
            },
          }}
          PaperProps={{
            sx: {
              borderRadius: '28px 28px 0 0',
              maxHeight: '75vh',
              boxShadow: '0 0 0 rgba(0, 0, 0, 0)',
              transition: 'box-shadow 0.6s cubic-bezier(0.4, 0, 0.2, 1), transform 0.4s ease-out',
              '&:hover': {
                boxShadow: '0 -8px 20px rgba(0, 0, 0, 0.1)',
              },
            }
          }}
        >
          <SwipeableFilterBox>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1.5 }}>
              <Typography variant="h6" fontWeight="medium">Filtrar Cambios</Typography>
              <IconButton onClick={() => setOpenFiltersDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Box>

            <FormSection>
              <StyledFormControl fullWidth>
                <InputLabel>Caja</InputLabel>
                <Select
                  value={filterCaja}
                  onChange={(e) => setFilterCaja(e.target.value)}
                  label="Caja"
                >
                  <MenuItem value="">Todas</MenuItem>
                  {cajas.map((caja) => (
                    <MenuItem key={caja} value={caja}>{caja}</MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Proveedor</InputLabel>
                <Select
                  value={filterProveedor}
                  onChange={(e) => setFilterProveedor(e.target.value)}
                  label="Proveedor"
                >
                  <MenuItem value="">Todos</MenuItem>
                  {proveedores.map((prov) => (
                    <MenuItem key={prov} value={prov}>{prov}</MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Moneda Inicial</InputLabel>
                <Select
                  value={filterMonedaInicial}
                  onChange={(e) => setFilterMonedaInicial(e.target.value)}
                  label="Moneda Inicial"
                >
                  <MenuItem value="">Todas</MenuItem>
                  <MenuItem value="ARS">ARS</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Comprobante</InputLabel>
                <Select
                  value={filterTieneComprobante === null ? '' : filterTieneComprobante.toString()}
                  onChange={(e) => {
                    const value = e.target.value === '' ? null : e.target.value === 'true';
                    setFilterTieneComprobante(value);
                  }}
                  label="Comprobante"
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="true">Con comprobante</MenuItem>
                  <MenuItem value="false">Sin comprobante</MenuItem>
                </Select>
              </StyledFormControl>

              <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                <Grid container spacing={1.5} sx={{ mt: 0.5 }}>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Desde"
                      value={filterFechaDesde}
                      onChange={(newValue) => setFilterFechaDesde(newValue)}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          fullWidth
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <CalendarTodayIcon color="primary" fontSize="small" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Hasta"
                      value={filterFechaHasta}
                      onChange={(newValue) => setFilterFechaHasta(newValue)}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          fullWidth
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <CalendarTodayIcon color="primary" fontSize="small" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </FormSection>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button
                variant="outlined"
                color="inherit"
                onClick={resetFilters}
                sx={{
                  width: '48%',
                  borderRadius: 20,
                  borderColor: 'rgba(0,0,0,0.2)',
                  color: 'text.secondary'
                }}
              >
                Limpiar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={applyFilterSelection}
                sx={{ width: '48%', borderRadius: 20 }}
              >
                Aplicar Filtros
              </Button>
            </Box>
          </SwipeableFilterBox>
        </SwipeableDrawer>

        {/* Snackbar para notificaciones */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={{ mb: 7 }} // Para que aparezca por encima de la navegación
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{
              width: '100%',
              borderRadius: 20,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </MobileContainer>
    </>
  );
};

export default CurrencyChangesMobile;