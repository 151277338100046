// src/components/CACManagement.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
  Container,
  IconButton,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: '#f1f8f1',
    transition: 'background-color 0.2s ease',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const CACManagement = () => {
  const [cacRecords, setCacRecords] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);

  // Get current date and calculate default month (2 months ago)
  const currentDate = new Date();
  const defaultMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2, 1);

  const [formData, setFormData] = useState({
    mes: defaultMonth.getMonth() + 1, // Default to 2 months ago
    año: defaultMonth.getFullYear(),
    cac_general: '',
    cac_materiales: '',
    cac_mo: '',
  });
  const [editId, setEditId] = useState(null);

  // Define months for the dropdown
  const months = [
    { value: 1, label: 'Enero' },
    { value: 2, label: 'Febrero' },
    { value: 3, label: 'Marzo' },
    { value: 4, label: 'Abril' },
    { value: 5, label: 'Mayo' },
    { value: 6, label: 'Junio' },
    { value: 7, label: 'Julio' },
    { value: 8, label: 'Agosto' },
    { value: 9, label: 'Septiembre' },
    { value: 10, label: 'Octubre' },
    { value: 11, label: 'Noviembre' },
    { value: 12, label: 'Diciembre' },
  ];

  useEffect(() => {
    fetchCACRecords();
  }, []);

  const fetchCACRecords = async () => {
    const { data, error } = await supabase
      .from('cac_cargar')
      .select('*')
      .order('año', { ascending: false })
      .order('mes', { ascending: false });

    if (error) console.error('Error fetching CAC records:', error);
    else setCacRecords(data || []);
  };

  const handleAddCACRecord = async () => {
    const cacGeneralValue = parseFloat(formData.cac_general) || 0;
    const cacMaterialesValue = parseFloat(formData.cac_materiales) || 0;
    const cacMoValue = parseFloat(formData.cac_mo) || 0;

    const { error } = await supabase.from('cac_cargar').insert({
      mes: parseInt(formData.mes),
      año: parseInt(formData.año),
      cac_general: cacGeneralValue,
      cac_materiales: cacMaterialesValue,
      cac_mo: cacMoValue,
    });

    if (error) alert('Error al agregar registro CAC: ' + error.message);
    else {
      fetchCACRecords();
      setOpenAddDialog(false);
      resetForm();
    }
  };

  const handleEditCACRecord = async () => {
    const cacGeneralValue = parseFloat(formData.cac_general) || 0;
    const cacMaterialesValue = parseFloat(formData.cac_materiales) || 0;
    const cacMoValue = parseFloat(formData.cac_mo) || 0;

    const { error } = await supabase
      .from('cac_cargar')
      .update({
        mes: parseInt(formData.mes),
        año: parseInt(formData.año),
        cac_general: cacGeneralValue,
        cac_materiales: cacMaterialesValue,
        cac_mo: cacMoValue,
      })
      .eq('id', editId);

    if (error) alert('Error al editar registro CAC: ' + error.message);
    else {
      fetchCACRecords();
      setOpenEditDialog(false);
      setEditId(null);
      resetForm();
    }
  };

  const handleDeleteCACRecord = async (id) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este registro?')) {
      const { error } = await supabase.from('cac_cargar').delete().eq('id', id);
      if (error) alert('Error al eliminar registro CAC: ' + error.message);
      else fetchCACRecords();
    }
  };

  const resetForm = () => {
    setFormData({
      mes: defaultMonth.getMonth() + 1,
      año: defaultMonth.getFullYear(),
      cac_general: '',
      cac_materiales: '',
      cac_mo: '',
    });
  };

  const getMonthName = (monthNumber) => {
    // Convert to a number if it's a string
    const monthNum = Number(monthNumber);
    if (isNaN(monthNum) || monthNum < 1 || monthNum > 12) {
      console.log("Invalid month number:", monthNumber);
      return "Mes desconocido";
    }

    // Find the month name
    const month = months.find(m => m.value === monthNum);
    return month ? month.label : "Mes desconocido";
  };

  return (
    <Container maxWidth="md" sx={{ py: 3, px: 0, minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 3, gap: 1 }}>
        <Typography variant="h4" fontWeight="bold" color="#2e7d32">
          Índices CAC
        </Typography>
        <Tooltip title="Agregar nuevo registro CAC">
          <IconButton
            onClick={() => setOpenAddDialog(true)}
            sx={{
              color: '#2e7d32',
              backgroundColor: '#e8f5e9',
              borderRadius: '50%',
              width: 40,
              height: 40,
              '&:hover': { backgroundColor: '#c8e6c9' },
              ml: 1,
            }}
          >
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Box>

      <Box sx={{ mb: 3 }}>
        <Typography variant="body1" color="text.secondary">
          Gestiona los valores del Índice del Costo de la Construcción (CAC) mensualmente para cálculos de ajustes por inflación.
        </Typography>
      </Box>

      {/* Lista de Registros CAC - Versión Moderna con Tabla */}
      {cacRecords.length === 0 ? (
        <Box sx={{ textAlign: 'center', py: 4 }}>
          <Typography variant="body1" color="text.secondary">
            No hay registros CAC. Agrega uno nuevo para comenzar.
          </Typography>
        </Box>
      ) : (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <Paper elevation={2} sx={{
            borderRadius: 2,
            overflow: 'hidden',
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)'
          }}>
            <TableContainer sx={{ maxHeight: 'calc(100vh - 250px)' }}>
              <Table stickyHeader aria-label="CAC records table">
                <TableHead>
                  <TableRow sx={{
                    backgroundColor: '#f5f5f5',
                    '& th': {
                      fontWeight: 'bold',
                      color: '#424242',
                      backgroundColor: '#f5f5f5'
                    }
                  }}>
                    <TableCell sx={{ width: '20%' }}>Período</TableCell>
                    <TableCell align="center">CAC General</TableCell>
                    <TableCell align="center">CAC Materiales</TableCell>
                    <TableCell align="center">CAC M. Obra</TableCell>
                    <TableCell align="center" sx={{ width: '15%' }}>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {cacRecords.map((record) => {
                    console.log("Record data:", record);
                    return (
                      <StyledTableRow key={record.id}>
                        <TableCell>
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 'medium',
                              fontSize: '0.9rem'
                            }}
                          >
                            <strong>Mes:</strong> {getMonthName(record.mes)}
                            <br />
                            <strong>Año:</strong> {record.año}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              color: '#2e7d32'
                            }}
                          >
                            {record.cac_general}%
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              color: '#1976d2'
                            }}
                          >
                            {record.cac_materiales}%
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Typography
                            sx={{
                              fontWeight: 'bold',
                              color: '#ed6c02'
                            }}
                          >
                            {record.cac_mo}%
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                            <Tooltip title="Editar">
                              <IconButton
                                size="small"
                                onClick={() => {
                                  setEditId(record.id);
                                  setFormData({
                                    mes: record.mes,
                                    año: record.año,
                                    cac_general: record.cac_general,
                                    cac_materiales: record.cac_materiales,
                                    cac_mo: record.cac_mo,
                                  });
                                  setOpenEditDialog(true);
                                }}
                                sx={{ color: '#2e7d32', mr: 1 }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Eliminar">
                              <IconButton
                                size="small"
                                onClick={() => handleDeleteCACRecord(record.id)}
                                sx={{ color: '#d32f2f' }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </motion.div>
      )}

      {/* Diálogo para agregar nuevo registro CAC */}
      <Dialog open={openAddDialog} onClose={() => setOpenAddDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" fontWeight="bold" color="#424242">
            Nuevo Registro CAC
          </Typography>
          <IconButton onClick={() => setOpenAddDialog(false)} sx={{ color: '#d32f2f' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 0.5 }}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Mes</InputLabel>
                <Select
                  value={formData.mes}
                  label="Mes"
                  onChange={(e) => setFormData({ ...formData, mes: e.target.value })}
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Año"
                type="number"
                value={formData.año}
                onChange={(e) => setFormData({ ...formData, año: e.target.value })}
                fullWidth
                sx={{ mb: 2 }}
                InputProps={{
                  inputProps: { min: 2000, max: 2100 }
                }}
              />
            </Grid>
          </Grid>

          <TextField
            label="CAC General"
            type="number"
            value={formData.cac_general}
            onChange={(e) => setFormData({ ...formData, cac_general: e.target.value })}
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { step: "0.01" }
            }}
          />

          <TextField
            label="CAC Materiales"
            type="number"
            value={formData.cac_materiales}
            onChange={(e) => setFormData({ ...formData, cac_materiales: e.target.value })}
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { step: "0.01" }
            }}
          />

          <TextField
            label="CAC Mano de Obra"
            type="number"
            value={formData.cac_mo}
            onChange={(e) => setFormData({ ...formData, cac_mo: e.target.value })}
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { step: "0.01" }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
          <Button
            variant="contained"
            color="success"
            onClick={handleAddCACRecord}
            sx={{ borderRadius: 12, backgroundColor: '#2e7d32', '&:hover': { backgroundColor: '#1b5e20' }, px: 4 }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Diálogo para editar registro CAC */}
      <Dialog open={openEditDialog} onClose={() => setOpenEditDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h6" fontWeight="bold" color="#424242">
            Editar Registro CAC
          </Typography>
          <IconButton onClick={() => setOpenEditDialog(false)} sx={{ color: '#d32f2f' }}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 0.5 }}>
            <Grid item xs={6}>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel>Mes</InputLabel>
                <Select
                  value={formData.mes}
                  label="Mes"
                  onChange={(e) => setFormData({ ...formData, mes: e.target.value })}
                >
                  {months.map((month) => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Año"
                type="number"
                value={formData.año}
                onChange={(e) => setFormData({ ...formData, año: e.target.value })}
                fullWidth
                sx={{ mb: 2 }}
                InputProps={{
                  inputProps: { min: 2000, max: 2100 }
                }}
              />
            </Grid>
          </Grid>

          <TextField
            label="CAC General"
            type="number"
            value={formData.cac_general}
            onChange={(e) => setFormData({ ...formData, cac_general: e.target.value })}
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { step: "0.01" }
            }}
          />

          <TextField
            label="CAC Materiales"
            type="number"
            value={formData.cac_materiales}
            onChange={(e) => setFormData({ ...formData, cac_materiales: e.target.value })}
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { step: "0.01" }
            }}
          />

          <TextField
            label="CAC Mano de Obra"
            type="number"
            value={formData.cac_mo}
            onChange={(e) => setFormData({ ...formData, cac_mo: e.target.value })}
            fullWidth
            sx={{ mb: 2 }}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
              inputProps: { step: "0.01" }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
          <Button
            variant="contained"
            color="success"
            onClick={handleEditCACRecord}
            sx={{ borderRadius: 12, backgroundColor: '#2e7d32', '&:hover': { backgroundColor: '#1b5e20' }, px: 4 }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CACManagement;