import React, { useState, useEffect, useCallback, useRef } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  Container, Box, Typography, Button, Tab, Tabs, Paper, Grid,
  TextField, MenuItem, Dialog, DialogTitle, DialogContent,
  DialogActions, IconButton, Chip, Divider, CircularProgress,
  Card, CardContent, Collapse, Alert, Tooltip,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Modal, Backdrop, Fade, Autocomplete, Drawer, List,
  ListItem, ListItemIcon, ListItemText, Fab, useMediaQuery, useTheme,
  Snackbar, Skeleton
} from '@mui/material';
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Refresh as RefreshIcon,
  ExpandMore as ExpandMoreIcon,
  AttachMoney as AttachMoneyIcon,
  Assignment as AssignmentIcon,
  TrendingUp as TrendingUpIcon,
  Construction as ConstructionIcon,
  FilterList as FilterListIcon,
  AccountBalance as AccountBalanceIcon,
  FileUpload as FileUploadIcon,
  PictureAsPdf as PdfIcon,
  Close as CloseIcon,
  Code as CodeIcon,
  MoreVert as MoreVertIcon,
  Search as SearchIcon,
  ArrowBack as ArrowBackIcon,
  Menu as MenuIcon,
  Payment as PaymentIcon,
  DateRange as DateRangeIcon,
  Receipt as ReceiptIcon
} from '@mui/icons-material';
import { styled, alpha } from '@mui/material/styles';
import { supabase } from '../supabaseClient';
import { format } from 'date-fns';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { useAuth } from '../contexts/AuthContext'; // Importar useAuth

// Styled components
const PresupuestoCard = styled(motion(Card))(({ theme }) => ({
  margin: theme.spacing(1, 0),
  borderRadius: 16,
  overflow: 'hidden',
  boxShadow: '0 4px 15px rgba(0, 0, 0, 0.05)',
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.1)',
    transform: 'translateY(-3px)',
  },
}));

const IndicatorBar = styled('div')(({ theme, percentage, color }) => ({
  height: 8,
  width: '100%',
  backgroundColor: theme.palette.grey[100],
  borderRadius: 4,
  position: 'relative',
  overflow: 'hidden',
  '&::after': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: `${percentage > 100 ? 100 : percentage}%`,
    backgroundColor: color || theme.palette.primary.main,
    borderRadius: 4,
    transition: 'width 1s ease-in-out',
  },
}));

const StatusChip = styled(Chip)(({ theme, statuscolor }) => ({
  borderRadius: 16,
  fontWeight: 'bold',
  backgroundColor: statuscolor,
  color: theme.palette.getContrastText(statuscolor),
}));

const ModalContent = styled(Paper)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  maxHeight: '90vh',
  overflowY: 'auto',
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(3),
  borderRadius: 16,
}));

const DetailItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1.5),
  '&:last-child': {
    marginBottom: 0,
  },
}));

const DetailLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.text.secondary,
}));

const DetailValue = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
}));

const FileUploadButton = styled('label')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(1, 2),
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  borderRadius: 16,
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

const FixedFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: 110, // Cambiado a 110px desde el bottom
  right: theme.spacing(2),
  zIndex: 1000,
}));

const MobileDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-paper': {
    borderRadius: 16,
    width: '100%',
    margin: 8,
    maxHeight: '90vh',
  },
}));

const SearchBar = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: 20,
  backgroundColor: alpha(theme.palette.primary.main, 0.1),
  '&:hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.15),
  },
  marginLeft: 0,
  width: '100%',
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(TextField)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 20,
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
}));

// Componente para mostrar el detalle de un presupuesto en un drawer más pequeño
const DetailDrawer = styled(Drawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    width: '90%', // Reducido al 90% del ancho
    maxWidth: '400px', // Máximo de 400px
    borderRadius: '20px 0 0 20px', // Bordes redondeados en la izquierda
    maxHeight: '85vh', // No ocupa toda la pantalla
    top: '7.5vh', // Centrado verticalmente
    height: '85vh', // Altura fija
  }
}));

// Skeleton loaders para estados de carga
const SkeletonCard = () => (
  <Paper sx={{ p: 2, mb: 2, borderRadius: 4 }}>
    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 2 }}>
      <Skeleton variant="text" width="60%" height={30} />
      <Skeleton variant="rectangular" width={70} height={24} sx={{ borderRadius: 4 }} />
    </Box>
    <Skeleton variant="text" width="40%" height={20} sx={{ mb: 1 }} />

    <Box sx={{ mt: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
        <Skeleton variant="text" width="30%" height={20} />
        <Skeleton variant="text" width="20%" height={20} />
      </Box>
      <Skeleton variant="rectangular" width="100%" height={8} sx={{ borderRadius: 4, mb: 1 }} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Skeleton variant="rectangular" width="25%" height={24} sx={{ borderRadius: 4 }} />
        <Skeleton variant="text" width="35%" height={24} />
      </Box>
    </Box>
  </Paper>
);

// Componente de animación de carga para transiciones suaves
const FadeInContent = ({ loading, children, delay = 0 }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: loading ? 0 : 1 }}
    transition={{ duration: 0.3, delay }}
  >
    {children}
  </motion.div>
);

// Estilo para tarjetas de pagos
const PaymentCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(1.5),
  borderRadius: theme.spacing(2),
  boxShadow: '0 2px 6px rgba(0,0,0,0.08)',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    boxShadow: '0 4px 12px rgba(0,0,0,0.12)',
    transform: 'translateY(-2px)',
  }
}));

// Main component
const PresupuestosMobile = ({ clientFiltered = false }) => {
  const theme = useTheme();
  const { user, isClient, userRole } = useAuth(); // Obtener información de autorización

  // Determinar si el usuario es cliente (ya sea por prop o por contexto)
  const isClientUser = clientFiltered || isClient || userRole === 'client' || localStorage.getItem('userRole') === 'client';

  // States
  const [tab, setTab] = useState(0); // Siempre iniciar en tab 0 (Gastos Generales)
  const [presupuestos, setPresupuestos] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [subetapas, setSubetapas] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [empresaInfo, setEmpresaInfo] = useState({});
  const [currentProject, setCurrentProject] = useState('');
  const [clientProject, setClientProject] = useState(''); // Para almacenar el proyecto del cliente
  const [loading, setLoading] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false); // Estado para controlar cuando los datos están listos
  const [expanded, setExpanded] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAdditionalDialog, setOpenAdditionalDialog] = useState(false);
  const [openEditAdditionalDialog, setOpenEditAdditionalDialog] = useState(false);
  const [openDeleteAdditionalDialog, setOpenDeleteAdditionalDialog] = useState(false);
  const [currentPresupuestoToEdit, setCurrentPresupuestoToEdit] = useState(null);
  const [currentPresupuestoToDelete, setCurrentPresupuestoToDelete] = useState(null);
  const [currentAdditionalToEdit, setCurrentAdditionalToEdit] = useState(null);
  const [currentAdditionalToDelete, setCurrentAdditionalToDelete] = useState(null);
  const [exchangeRates, setExchangeRates] = useState({
    DOLAR_BLUE_VENTA: 0,
    DOLAR_BLUE_COMPRA: 0,
    DOLAR_OFICIAL_VENTA: 0
  });
  const [customCurrencies, setCustomCurrencies] = useState([]);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const [openPaymentDetail, setOpenPaymentDetail] = useState(false);
  const fileInputRef = useRef(null);
  const additionalFileInputRef = useRef(null);

  // States para sugerencias de proveedor y subetapa
  const [proveedorSuggestions, setProveedorSuggestions] = useState([]);
  const [subetapasSuggestions, setSubetapasSuggestions] = useState([]);
  const [proveedorSearchQuery, setProveedorSearchQuery] = useState('');
  const [subetapasFrequencies, setSubetapasFrequencies] = useState({});

  // Mobile-specific states
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedPresupuesto, setSelectedPresupuesto] = useState(null);
  const [detailDrawerOpen, setDetailDrawerOpen] = useState(false);
  const [snackbarInfo, setSnackbarInfo] = useState({ open: false, message: '', severity: 'success' });

  // Estados para pagos relacionados
  const [relatedPayments, setRelatedPayments] = useState([]);
  const [loadingPayments, setLoadingPayments] = useState(false);
  const [paymentDetailOpen, setPaymentDetailOpen] = useState(false);
  const [selectedPaymentDetail, setSelectedPaymentDetail] = useState(null);

  // Estados para saldos
  const [showSaldosSection, setShowSaldosSection] = useState(true);
  const [saldosCobrar, setSaldosCobrar] = useState({});
  const [filtroObraSaldos, setFiltroObraSaldos] = useState('');
  const [loadingSaldos, setLoadingSaldos] = useState(false);
  const [cajasMO, setCajasMO] = useState({
    ars: 0,
    usd: 0,
    loading: false,
    cajas: []
  });

  // Form states
  const [formData, setFormData] = useState({
    codigo_de_proyecto: '',
    proveedor_id: '',
    subetapa_de_obra: '',
    monto: '',
    moneda: 'ARS',
    tipo_presupuesto: 'GASTOS_GENERALES', // Para clientes siempre será GASTOS_GENERALES
    descripcion: '',
    tiene_comprobante: false,
    imagen_comprobante: null,
    codigo_comprobante: generateComprobanteCodigo(),
    ruta_archivo_comprobante: ''
  });

  const [additionalFormData, setAdditionalFormData] = useState({
    presupuesto_id: '',
    monto: '',
    moneda: 'ARS',
    descripcion: '',
    tiene_comprobante: false,
    imagen_comprobante: null,
    codigo_comprobante: generateComprobanteCodigo(),
    ruta_archivo_comprobante: ''
  });

  const [filePreview, setFilePreview] = useState(null);
  const [additionalFilePreview, setAdditionalFilePreview] = useState(null);

  // Forzar tab 0 para clientes
  useEffect(() => {
    if (isClientUser) {
      setTab(0); // Forzar la vista de Gastos Generales para clientes
    }
  }, [isClientUser]);

  // Efecto para buscar y establecer el proyecto del cliente
  useEffect(() => {
    const fetchClientProject = async () => {
      if (isClientUser && user && user.email) {
        try {
          const { data, error } = await supabase
            .from('proyectos')
            .select('codigo_de_proyecto')
            .eq('email_del_cliente', user.email)
            .single();

          if (error) {
            console.error('Error al buscar proyecto del cliente:', error);
            return;
          }

          if (data) {
            // Establecer el proyecto del cliente como filtro
            setClientProject(data.codigo_de_proyecto);
            setCurrentProject(data.codigo_de_proyecto);
            console.log('Proyecto del cliente configurado:', data.codigo_de_proyecto);
          }
        } catch (err) {
          console.error('Error al obtener proyecto de cliente:', err);
        }
      }
    };

    fetchClientProject();
  }, [isClientUser, user]);

  // Generate unique code for comprobantes
  function generateComprobanteCodigo() {
    const timestamp = Date.now().toString();
    const randomPart = Math.floor(Math.random() * 10000).toString().padStart(4, '0');
    return `COMP-${timestamp.slice(-6)}-${randomPart}`;
  }

  // Fetch data from Supabase
  const fetchProyectos = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('proyectos')
        .select('codigo_de_proyecto, caja_proyecto_mo, es_terminada, email_del_cliente');

      if (error) throw error;
      setProyectos(data);
    } catch (error) {
      console.error('Error fetching proyectos:', error);
    }
  }, []);

  const fetchSubetapas = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('etapas_de_obra')
        .select('subetapa_de_obra');

      if (error) throw error;
      setSubetapas(data);
    } catch (error) {
      console.error('Error fetching subetapas:', error);
    }
  }, []);

  const fetchProveedores = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('proveedores_de_servicios')
        .select('id, proveedor_de_servicios');

      if (error) throw error;
      setProveedores(data);
      setProveedorSuggestions(data.map(p => ({ id: p.id, label: p.proveedor_de_servicios })));
    } catch (error) {
      console.error('Error fetching proveedores:', error);
    }
  }, []);

  // Función para obtener subetapas frecuentes por proveedor
  const fetchProveedorFrequency = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('proveedor_frecuencia')
        .select('proveedor_salida, subetapa_mas_usada');

      if (error) throw error;

      // Crear un mapa para búsqueda rápida
      const frequencyMap = {};
      data.forEach(item => {
        frequencyMap[item.proveedor_salida] = item.subetapa_mas_usada;
      });

      setSubetapasFrequencies(frequencyMap);
    } catch (error) {
      console.error('Error fetching proveedor frequencies:', error);
    }
  }, []);

  const fetchEmpresaInfo = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('empresa')
        .select('*')
        .single();

      if (error) throw error;
      setEmpresaInfo(data || {});
    } catch (error) {
      console.error('Error fetching empresa info:', error);
    }
  }, []);

  const fetchCustomCurrencies = useCallback(async () => {
    try {
      const { data, error } = await supabase
        .from('custom_currencies')
        .select('*');

      if (error) throw error;
      setCustomCurrencies(data || []);
    } catch (error) {
      console.error('Error fetching custom currencies:', error);
    }
  }, []);

  // Nueva función para cargar los pagos relacionados a un presupuesto
  const fetchRelatedPayments = useCallback(async (presupuestoId, pagosIds) => {
    try {
      setLoadingPayments(true);
      if (!pagosIds || pagosIds.length === 0) {
        setRelatedPayments([]);
        return;
      }

      // Si pagosIds es una cadena, intentamos parsearlo como JSON
      let idsArray = pagosIds;
      if (typeof pagosIds === 'string') {
        try {
          idsArray = JSON.parse(pagosIds);
        } catch (err) {
          console.error('Error parsing pagos_ids:', err);
          idsArray = [];
        }
      }

      if (idsArray.length === 0) {
        setRelatedPayments([]);
        return;
      }

      // Consultar las salidas relacionadas
      const { data, error } = await supabase
        .from('salidas')
        .select('*')
        .in('id', idsArray);

      if (error) throw error;

      // Ordenar por fecha más reciente
      const sortedData = data.sort((a, b) =>
        new Date(b.fecha_salida) - new Date(a.fecha_salida)
      );

      setRelatedPayments(sortedData);
    } catch (error) {
      console.error('Error fetching related payments:', error);
      setSnackbarInfo({
        open: true,
        message: 'Error al cargar pagos relacionados',
        severity: 'error'
      });
    } finally {
      setLoadingPayments(false);
    }
  }, []);

  // Nueva función para obtener los saldos de cajas MO
  const fetchCajasMO = useCallback(async () => {
    try {
      setCajasMO(prev => ({ ...prev, loading: true }));

      // Obtener datos de la vista de cajas
      const { data, error } = await supabase
        .from('caja_saldos_vista')
        .select('*');

      if (error) throw error;

      // Filtrar las cajas que son de MO (contienen "MO" en el nombre)
      const cajasMOData = data.filter(caja => caja.caja_ars && caja.caja_ars.includes('MO'));

      // Si hay un filtro de proyecto, reducir aún más
      let cajasFiltradas = cajasMOData;
      if (filtroObraSaldos) {
        // Extraer código de proyecto de nombre de caja (por ejemplo, "Caja FER MO" -> "FER")
        cajasFiltradas = cajasMOData.filter(caja => {
          const nombreCaja = caja.caja_ars || '';
          const match = nombreCaja.match(/Caja\s+(\w+)\s+MO/);
          return match && match[1] === filtroObraSaldos;
        });
      }

      // Calcular totales
      let totalARS = 0;
      let totalUSD = 0;

      cajasFiltradas.forEach(caja => {
        // Sumar montos ARS y USD
        totalARS += Number(caja.saldo_ars || 0);
        totalUSD += Number(caja.saldo_usd || 0);
      });

      setCajasMO({
        ars: totalARS,
        usd: totalUSD,
        loading: false,
        cajas: cajasFiltradas // Guardar también las cajas individuales para mostrarlas
      });

    } catch (error) {
      console.error('Error fetching saldos de cajas MO:', error);
      setCajasMO({
        ars: 0,
        usd: 0,
        loading: false,
        cajas: []
      });
    }
  }, [filtroObraSaldos]);

  // Nueva función para obtener los saldos de proyectos
  const fetchSaldosProyectos = useCallback(async () => {
    try {
      setLoadingSaldos(true);

      // Obtener saldos actuales de proyectos desde Supabase
      const { data, error } = await supabase
        .from('saldos_actuales_proyectos')
        .select('codigo_de_proyecto, saldo_actual');

      if (error) throw error;

      // Convertir el array a un objeto para acceso rápido por código de proyecto
      const saldosMap = {};
      data.forEach(item => {
        saldosMap[item.codigo_de_proyecto] = item.saldo_actual;
      });

      setSaldosCobrar(saldosMap);
    } catch (error) {
      console.error('Error fetching saldos de proyectos:', error);
    } finally {
      setLoadingSaldos(false);
    }
  }, []);

  const fetchExchangeRates = useCallback(async () => {
    try {
      setLoading(true);

      // Obtener dólar blue
      let blueResponse;
      try {
        const blueResult = await fetch('https://dolarapi.com/v1/dolares/blue');
        if (blueResult.ok) {
          blueResponse = await blueResult.json();
        } else {
          throw new Error('Error al obtener dólar blue');
        }
      } catch (error) {
        console.error('Error fetching dólar blue:', error);
        // Fallback a valores por defecto
        blueResponse = {
          compra: 1200,
          venta: 1250,
          casa: "blue",
          nombre: "Blue",
          moneda: "USD",
          fechaActualizacion: new Date().toISOString()
        };
      }

      // Obtener dólar oficial
      let oficialResponse;
      try {
        const oficialResult = await fetch('https://dolarapi.com/v1/dolares/oficial');
        if (oficialResult.ok) {
          oficialResponse = await oficialResult.json();
        } else {
          throw new Error('Error al obtener dólar oficial');
        }
      } catch (error) {
        console.error('Error fetching dólar oficial:', error);
        // Fallback a valores por defecto
        oficialResponse = {
          compra: 1050,
          venta: 1086,
          casa: "oficial",
          nombre: "Oficial",
          moneda: "USD",
          fechaActualizacion: new Date().toISOString()
        };
      }

      // Actualizar estados con las cotizaciones obtenidas
      setExchangeRates({
        DOLAR_BLUE_VENTA: blueResponse.venta,
        DOLAR_BLUE_COMPRA: blueResponse.compra,
        DOLAR_OFICIAL_VENTA: oficialResponse.venta
      });

      // Guardar estas cotizaciones en la base de datos para histórico
      await supabase.from('tipos_de_cambio').insert([
        { tipo: 'DOLAR_BLUE_VENTA', valor: blueResponse.venta },
        { tipo: 'DOLAR_BLUE_COMPRA', valor: blueResponse.compra },
        { tipo: 'DOLAR_OFICIAL_VENTA', valor: oficialResponse.venta }
      ]);

      // Mostrar notificación de éxito
      setSnackbarInfo({
        open: true,
        message: `Cotizaciones actualizadas: Dólar Blue ${blueResponse.venta}, Dólar Oficial ${oficialResponse.venta}`,
        severity: 'success'
      });

    } catch (error) {
      console.error('Error fetching exchange rates:', error);
      setSnackbarInfo({
        open: true,
        message: 'Error al actualizar cotizaciones. Se usarán valores anteriores.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchPresupuestos = useCallback(async () => {
    try {
      setLoading(true);
      setDataLoaded(false); // Marcar que los datos no están listos

      // Verificar cliente
      if (isClientUser && !clientProject) {
        // Si es cliente pero no encontramos su proyecto, no mostramos nada
        setPresupuestos([]);
        setDataLoaded(true);
        setLoading(false);
        return;
      }

      // Crear la consulta a la vista presupuestos_completos
      let query = supabase
        .from('presupuestos_completos')
        .select('*');

      // Filtrado según tipo de usuario
      if (isClientUser) {
        // Para clientes, solo mostrar sus proyectos de tipo GASTOS_GENERALES
        query = query.eq('codigo_de_proyecto', clientProject);
        query = query.eq('tipo_presupuesto', 'GASTOS_GENERALES');
      } else {
        // Para administradores
        if (currentProject) {
          query = query.eq('codigo_de_proyecto', currentProject);
        }

        // Filtrar por tipo de presupuesto según la pestaña seleccionada
        if (tab === 0) {
          query = query.eq('tipo_presupuesto', 'GASTOS_GENERALES');
        } else {
          query = query.eq('tipo_presupuesto', 'MANO_DE_OBRA');
        }
      }

      // Aplicar filtro de búsqueda si existe
      if (searchQuery) {
        query = query.or(`proveedor_de_servicios.ilike.%${searchQuery}%,codigo_de_proyecto.ilike.%${searchQuery}%,subetapa_de_obra.ilike.%${searchQuery}%,descripcion.ilike.%${searchQuery}%`);
      }

      const { data, error } = await query.order('fecha_creacion', { ascending: false });

      if (error) throw error;

      // Procesar los datos devueltos para asegurar compatibilidad
      const processedData = data.map(item => {
        // Preparar array de pagos_ids
        let pagosIdsArray = [];
        if (item.pagos_ids) {
          try {
            if (typeof item.pagos_ids === 'string') {
              pagosIdsArray = JSON.parse(item.pagos_ids);
            } else if (Array.isArray(item.pagos_ids)) {
              pagosIdsArray = item.pagos_ids;
            }
          } catch (e) {
            console.error('Error parsing pagos_ids:', e);
          }
        }

        // Preparar objeto pagos vacío para mantener compatibilidad
        const pagos = pagosIdsArray.map(id => ({ id }));

        return {
          ...item,
          // Mantener compatibilidad con el formato anterior
          pagos: pagos,
          totalPaid: Number(item.total_pagado_moneda_original || 0),
          totalPaidUSD: Number(item.total_pagado_usd || 0),
          totalPresupuestadoUSD: Number(item.total_presupuestado_usd || 0),
          // Compatibilidad adicional
          presupuestos_adicionales: [], // Los adicionales están agregados en los campos totales
          presupuestos_pagos: pagos,
          // Guardar los IDs de pagos en un formato accesible
          pagosIdsArray: pagosIdsArray
        };
      });

      setPresupuestos(processedData);
      setDataLoaded(true); // Marcar que los datos están listos
    } catch (error) {
      console.error('Error fetching presupuestos:', error);
      setSnackbarInfo({
        open: true,
        message: 'Error al cargar presupuestos: ' + error.message,
        severity: 'error'
      });
      setDataLoaded(true); // En caso de error, también marcar como cargado
    } finally {
      setLoading(false);
    }
  }, [currentProject, tab, searchQuery, isClientUser, clientProject]);

  // Nuevas funciones para calcular los saldos usando los datos de la vista
  const calcularTotalSaldosPagar = () => {
    let total = 0;

    // Filtrar presupuestos de mano de obra
    const presupuestosManoObra = presupuestos.filter(p => p.tipo_presupuesto === 'MANO_DE_OBRA');

    // Si hay filtro de obra, aplicarlo
    const presupuestosFiltrados = filtroObraSaldos
      ? presupuestosManoObra.filter(p => p.codigo_de_proyecto === filtroObraSaldos)
      : presupuestosManoObra;

    // Sumar saldos restantes directamente de la vista
    presupuestosFiltrados.forEach(presupuesto => {
      if (presupuesto.moneda === 'ARS') {
        total += Number(presupuesto.saldo_restante || 0);
      } else {
        // Convertir a ARS usando las tasas de cambio si es necesario
        const saldoARS = calcularEquivalenteEnARS(presupuesto.saldo_restante, presupuesto.moneda);
        total += saldoARS;
      }
    });

    return total;
  };

  // Función para calcular equivalente en ARS
  const calcularEquivalenteEnARS = (monto, moneda) => {
    if (!monto) return 0;

    monto = Number(monto);

    if (moneda === 'ARS') return monto;
    if (moneda === 'USD') return monto * exchangeRates.DOLAR_BLUE_VENTA;
    if (moneda === 'DOLAR_OFICIAL_VENTA') return monto * exchangeRates.DOLAR_OFICIAL_VENTA;

    // Moneda personalizada
    const customCurrency = customCurrencies.find(c => c.name === moneda);
    if (customCurrency) {
      return monto * Number(customCurrency.value);
    }

    return monto; // Fallback
  };

  const obtenerTotalCobrar = () => {
    if (filtroObraSaldos) {
      return saldosCobrar[filtroObraSaldos] || 0;
    }

    // Si no hay filtro, sumar todos los saldos de proyectos que tienen presupuestos de mano de obra
    let total = 0;
    const proyectosConPresupuestos = new Set(
      presupuestos
        .filter(p => p.tipo_presupuesto === 'MANO_DE_OBRA')
        .map(p => p.codigo_de_proyecto)
    );

    // Sumar saldos de esos proyectos
    for (const codigo of proyectosConPresupuestos) {
      total += saldosCobrar[codigo] || 0;
    }

    return total;
  };

  const calcularBalanceNeto = () => {
    // Saldo base (a cobrar - a pagar)
    const saldoBase = obtenerTotalCobrar() - calcularTotalSaldosPagar();

    // Sumar saldos disponibles de cajas MO
    // ARS directamente, USD convertido a ARS usando dólar blue compra
    const saldoCajasARS = cajasMO.ars + (cajasMO.usd * exchangeRates.DOLAR_BLUE_COMPRA);

    return saldoBase + saldoCajasARS;
  };

  const calcularBalanceNetoUSD = () => {
    // Equivalente en USD del saldo base, usando dólar blue venta
    const saldoBaseUSD = (obtenerTotalCobrar() - calcularTotalSaldosPagar()) / exchangeRates.DOLAR_BLUE_VENTA;

    // Cajas MO en USD directamente, cajas ARS convertidas a USD
    const saldoCajasUSD = cajasMO.usd + (cajasMO.ars / exchangeRates.DOLAR_BLUE_VENTA);

    return saldoBaseUSD + saldoCajasUSD;
  };

  const obtenerProyectosConPresupuestosManoObra = () => {
    return [...new Set(presupuestos
      .filter(p => p.tipo_presupuesto === 'MANO_DE_OBRA')
      .map(p => p.codigo_de_proyecto))
    ];
  };

  // Initial data load
  useEffect(() => {
    fetchProyectos();
    fetchSubetapas();
    fetchProveedores();
    fetchExchangeRates();
    fetchCustomCurrencies();
    fetchEmpresaInfo();
    fetchSaldosProyectos();
    fetchProveedorFrequency(); // Cargar las frecuencias para autocompletar subetapas
  }, [fetchProyectos, fetchSubetapas, fetchProveedores, fetchExchangeRates, fetchCustomCurrencies, fetchEmpresaInfo, fetchSaldosProyectos, fetchProveedorFrequency]);

  // Load presupuestos when tab or project changes
  useEffect(() => {
    if (currentProject || tab === 0 || tab === 1) {
      fetchPresupuestos();
    }

    // Solo para la pestaña de mano de obra (y no para clientes)
    if (tab === 1 && !isClientUser) {
      fetchSaldosProyectos();
      fetchCajasMO();
    }
  }, [currentProject, tab, fetchPresupuestos, fetchSaldosProyectos, fetchCajasMO, isClientUser, clientProject]);

  // Reset search query when tab changes
  useEffect(() => {
    setSearchQuery('');
  }, [tab]);

  // Cargar pagos relacionados cuando se selecciona un presupuesto
  useEffect(() => {
    if (selectedPresupuesto && selectedPresupuesto.pagosIdsArray && selectedPresupuesto.pagosIdsArray.length > 0) {
      fetchRelatedPayments(selectedPresupuesto.id, selectedPresupuesto.pagosIdsArray);
    }
  }, [selectedPresupuesto, fetchRelatedPayments]);

  // Actualizar subetapa sugerida cuando cambia el proveedor
  useEffect(() => {
    if (formData.proveedor_id) {
      const proveedor = proveedores.find(p => p.id === formData.proveedor_id);
      if (proveedor) {
        const proveedorNombre = proveedor.proveedor_de_servicios;
        const subetapaSugerida = subetapasFrequencies[proveedorNombre];

        if (subetapaSugerida && !formData.subetapa_de_obra) {
          setFormData(prev => ({
            ...prev,
            subetapa_de_obra: subetapaSugerida
          }));
        }
      }
    }
  }, [formData.proveedor_id, proveedores, subetapasFrequencies]);

  // Handle tab change
  const handleTabChange = (event, newValue) => {
    // Si es cliente, no permitir cambiar de tab
    if (isClientUser) return;

    setTab(newValue);
    setDetailDrawerOpen(false);
    setSelectedPresupuesto(null);
  };

  // Búsqueda de proveedores
  const handleProveedorSearch = async (value) => {
    setProveedorSearchQuery(value);

    if (!value || value.length < 2) return;

    try {
      const { data, error } = await supabase
        .from('proveedores_de_servicios')
        .select('id, proveedor_de_servicios')
        .ilike('proveedor_de_servicios', `%${value}%`)
        .limit(10);

      if (error) throw error;

      setProveedorSuggestions(data.map(p => ({ id: p.id, label: p.proveedor_de_servicios })));
    } catch (error) {
      console.error('Error searching providers:', error);
    }
  };

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleAdditionalInputChange = (e) => {
    const { name, value } = e.target;
    setAdditionalFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        imagen_comprobante: file,
        tiene_comprobante: true
      }));

      // Crear una vista previa
      const reader = new FileReader();
      reader.onloadend = () => {
        setFilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleAdditionalFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setAdditionalFormData(prev => ({
        ...prev,
        imagen_comprobante: file,
        tiene_comprobante: true
      }));

      // Crear una vista previa
      const reader = new FileReader();
      reader.onloadend = () => {
        setAdditionalFilePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Calculate budget status with current exchange rates - Adaptado para la vista
  const calculateStatus = (presupuesto) => {
    // Usar directamente los valores de la vista
    const totalBudget = Number(presupuesto.total_presupuestado || 0);
    const totalPaid = Number(presupuesto.total_pagado_moneda_original || 0);
    const remaining = Number(presupuesto.saldo_restante || 0);
    const percentagePaid = Number(presupuesto.porcentaje_avance || 0);

    // Determinar estado basado en porcentaje de pago
    let statusText;
    let statusColor;

    if (percentagePaid === 0) {
      statusText = "Pendiente";
      statusColor = "#FF9800"; // Orange
    } else if (percentagePaid < 100) {
      statusText = "En progreso";
      statusColor = "#2196F3"; // Blue
    } else {
      statusText = "Completado";
      statusColor = "#4CAF50"; // Green
    }

    // Calcular valor en ARS del saldo restante (si no es ARS)
    let remainingArsEquivalent = null;
    if (presupuesto.moneda !== 'ARS') {
      remainingArsEquivalent = calcularEquivalenteEnARS(remaining, presupuesto.moneda);
    }

    return {
      percentagePaid,
      statusText,
      statusColor,
      totalBudget,
      totalPaid,
      remaining,
      remainingArsEquivalent
    };
  };

  // Convert amounts based on exchange rates
  const convertAmount = (amount, moneda) => {
    let arsAmount = amount;
    let usdAmount = amount;

    switch (moneda) {
      case 'USD':
        arsAmount = amount * exchangeRates.DOLAR_BLUE_VENTA;
        usdAmount = amount;
        break;
      case 'ARS':
        arsAmount = amount;
        usdAmount = amount / exchangeRates.DOLAR_BLUE_COMPRA;
        break;
      case 'DOLAR_OFICIAL_VENTA':
        arsAmount = amount * exchangeRates.DOLAR_OFICIAL_VENTA;
        usdAmount = amount * (exchangeRates.DOLAR_OFICIAL_VENTA / exchangeRates.DOLAR_BLUE_COMPRA);
        break;
      default:
        // Buscar si es una moneda personalizada
        const customCurrency = customCurrencies.find(c => c.name === moneda);
        if (customCurrency) {
          arsAmount = amount * Number(customCurrency.value);
          usdAmount = amount * (Number(customCurrency.value) / exchangeRates.DOLAR_BLUE_COMPRA);
        } else {
          arsAmount = amount;
          usdAmount = amount / exchangeRates.DOLAR_BLUE_COMPRA;
        }
    }

    return { arsAmount, usdAmount };
  };

  // Upload file to storage
  const uploadFile = async (file, path) => {
    if (!file) return null;

    const fileExt = file.name.split('.').pop();
    const fileName = `${path}_${Date.now()}.${fileExt}`;
    const filePath = `comprobantes/${fileName}`;

    const { error } = await supabase.storage
      .from('files')
      .upload(filePath, file);

    if (error) {
      console.error('Error uploading file:', error);
      return null;
    }

    // Get public URL
    const { data } = supabase.storage
      .from('files')
      .getPublicUrl(filePath);

    return {
      url: data.publicUrl,
      path: filePath
    };
  };

  // Handle submit new budget
  const handleSubmitPresupuesto = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      // Calculate ARS and USD equivalents based on current exchange rates
      const { arsAmount, usdAmount } = convertAmount(
        Number(formData.monto),
        formData.moneda
      );

      // Upload comprobante if exists
      let fileUrl = null;
      let filePath = null;
      if (formData.imagen_comprobante) {
        const uploadResult = await uploadFile(formData.imagen_comprobante, `presupuesto_${formData.codigo_de_proyecto}`);
        if (uploadResult) {
          fileUrl = uploadResult.url;
          filePath = uploadResult.path;
        }
      }

      const newPresupuesto = {
        ...formData,
        monto: Number(formData.monto),
        monto_en_ars_presupuestado: arsAmount,
        monto_en_usd_presupuestado: usdAmount,
        tiene_comprobante: !!formData.imagen_comprobante,
        imagen_comprobante: fileUrl,
        codigo_comprobante: formData.codigo_comprobante || generateComprobanteCodigo(),
        ruta_archivo_comprobante: filePath
      };

      const { error } = await supabase
        .from('presupuestos')
        .insert(newPresupuesto);

      if (error) throw error;

      // Reset form and refresh data
      setFormData({
        codigo_de_proyecto: currentProject || '',
        proveedor_id: '',
        subetapa_de_obra: '',
        monto: '',
        moneda: 'ARS',
        tipo_presupuesto: isClientUser ? 'GASTOS_GENERALES' : (tab === 0 ? 'GASTOS_GENERALES' : 'MANO_DE_OBRA'),
        descripcion: '',
        tiene_comprobante: false,
        imagen_comprobante: null,
        codigo_comprobante: generateComprobanteCodigo(),
        ruta_archivo_comprobante: ''
      });
      setFilePreview(null);

      setOpenDialog(false);
      fetchPresupuestos();

      // Show success alert
      setSnackbarInfo({
        open: true,
        message: 'Presupuesto creado correctamente',
        severity: 'success'
      });

    } catch (error) {
      console.error('Error submitting presupuesto:', error);
      setSnackbarInfo({
        open: true,
        message: 'Error al crear presupuesto: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle edit budget
  const handleEditPresupuesto = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (!currentPresupuestoToEdit) return;

      // Calculate ARS and USD equivalents based on current exchange rates
      const { arsAmount, usdAmount } = convertAmount(
        Number(formData.monto),
        formData.moneda
      );

      // Verificar si hay un archivo nuevo para subir
      let fileUrl = currentPresupuestoToEdit.imagen_comprobante;
      let filePath = currentPresupuestoToEdit.ruta_archivo_comprobante;

      if (formData.imagen_comprobante && formData.imagen_comprobante !== currentPresupuestoToEdit.imagen_comprobante) {
        const uploadResult = await uploadFile(formData.imagen_comprobante, `presupuesto_${formData.codigo_de_proyecto}`);
        if (uploadResult) {
          fileUrl = uploadResult.url;
          filePath = uploadResult.path;
        }
      }

      const updatedPresupuesto = {
        ...formData,
        monto: Number(formData.monto),
        monto_en_ars_presupuestado: arsAmount,
        monto_en_usd_presupuestado: usdAmount,
        tiene_comprobante: fileUrl ? true : !!currentPresupuestoToEdit.tiene_comprobante,
        imagen_comprobante: fileUrl || currentPresupuestoToEdit.imagen_comprobante,
        ruta_archivo_comprobante: filePath || currentPresupuestoToEdit.ruta_archivo_comprobante
      };

      const { error } = await supabase
        .from('presupuestos')
        .update(updatedPresupuesto)
        .eq('id', currentPresupuestoToEdit.id);

      if (error) throw error;

      // Reset form and refresh data
      setFormData({
        codigo_de_proyecto: currentProject || '',
        proveedor_id: '',
        subetapa_de_obra: '',
        monto: '',
        moneda: 'ARS',
        tipo_presupuesto: isClientUser ? 'GASTOS_GENERALES' : (tab === 0 ? 'GASTOS_GENERALES' : 'MANO_DE_OBRA'),
        descripcion: '',
        tiene_comprobante: false,
        imagen_comprobante: null,
        codigo_comprobante: generateComprobanteCodigo(),
        ruta_archivo_comprobante: ''
      });
      setFilePreview(null);

      setOpenEditDialog(false);
      setCurrentPresupuestoToEdit(null);
      fetchPresupuestos();

      // Show success alert
      setSnackbarInfo({
        open: true,
        message: 'Presupuesto actualizado correctamente',
        severity: 'success'
      });

    } catch (error) {
      console.error('Error updating presupuesto:', error);
      setSnackbarInfo({
        open: true,
        message: 'Error al actualizar presupuesto: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle delete budget
  const handleDeletePresupuesto = async () => {
    try {
      setLoading(true);

      if (!currentPresupuestoToDelete) return;

      // Delete the budget
      const { error } = await supabase
        .from('presupuestos')
        .delete()
        .eq('id', currentPresupuestoToDelete.id);

      if (error) throw error;

      setOpenDeleteDialog(false);
      setCurrentPresupuestoToDelete(null);
      fetchPresupuestos();

      // Show success alert
      setSnackbarInfo({
        open: true,
        message: 'Presupuesto eliminado correctamente',
        severity: 'success'
      });

    } catch (error) {
      console.error('Error deleting presupuesto:', error);
      setSnackbarInfo({
        open: true,
        message: 'Error al eliminar presupuesto: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle submit additional budget
  const handleSubmitAdicional = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      // Calculate ARS and USD equivalents based on current exchange rates
      const { arsAmount, usdAmount } = convertAmount(
        Number(additionalFormData.monto),
        additionalFormData.moneda
      );

      // Upload comprobante if exists
      let fileUrl = null;
      let filePath = null;
      if (additionalFormData.imagen_comprobante) {
        const uploadResult = await uploadFile(
          additionalFormData.imagen_comprobante,
          `adicional_${additionalFormData.presupuesto_id}`
        );
        if (uploadResult) {
          fileUrl = uploadResult.url;
          filePath = uploadResult.path;
        }
      }

      const newAdicional = {
        presupuesto_id: additionalFormData.presupuesto_id,
        monto: Number(additionalFormData.monto),
        moneda: additionalFormData.moneda,
        descripcion: additionalFormData.descripcion,
        monto_en_ars_adicional: arsAmount,
        monto_en_usd_adicional: usdAmount,
        tiene_comprobante: !!additionalFormData.imagen_comprobante,
        imagen_comprobante: fileUrl,
        codigo_comprobante: additionalFormData.codigo_comprobante || generateComprobanteCodigo(),
        ruta_archivo_comprobante: filePath
      };

      const { error } = await supabase
        .from('presupuestos_adicionales')
        .insert(newAdicional);

      if (error) throw error;

      // Reset form and refresh data
      setAdditionalFormData({
        presupuesto_id: '',
        monto: '',
        moneda: 'ARS',
        descripcion: '',
        tiene_comprobante: false,
        imagen_comprobante: null,
        codigo_comprobante: generateComprobanteCodigo(),
        ruta_archivo_comprobante: ''
      });
      setAdditionalFilePreview(null);

      setOpenAdditionalDialog(false);
      fetchPresupuestos();

      // Show success alert
      setSnackbarInfo({
        open: true,
        message: 'Adicional agregado correctamente',
        severity: 'success'
      });

    } catch (error) {
      console.error('Error submitting adicional:', error);
      setSnackbarInfo({
        open: true,
        message: 'Error al agregar adicional: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle edit additional
  const handleEditAdicional = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      if (!currentAdditionalToEdit) return;

      // Calculate ARS and USD equivalents based on current exchange rates
      const { arsAmount, usdAmount } = convertAmount(
        Number(additionalFormData.monto),
        additionalFormData.moneda
      );

      // Verificar si hay un archivo nuevo para subir
      let fileUrl = currentAdditionalToEdit.imagen_comprobante;
      let filePath = currentAdditionalToEdit.ruta_archivo_comprobante;

      if (additionalFormData.imagen_comprobante && additionalFormData.imagen_comprobante !== currentAdditionalToEdit.imagen_comprobante) {
        const uploadResult = await uploadFile(
          additionalFormData.imagen_comprobante,
          `adicional_${additionalFormData.presupuesto_id}`
        );
        if (uploadResult) {
          fileUrl = uploadResult.url;
          filePath = uploadResult.path;
        }
      }

      const updatedAdicional = {
        presupuesto_id: additionalFormData.presupuesto_id,
        monto: Number(additionalFormData.monto),
        moneda: additionalFormData.moneda,
        descripcion: additionalFormData.descripcion,
        monto_en_ars_adicional: arsAmount,
        monto_en_usd_adicional: usdAmount,
        tiene_comprobante: fileUrl ? true : !!currentAdditionalToEdit.tiene_comprobante,
        imagen_comprobante: fileUrl || currentAdditionalToEdit.imagen_comprobante,
        ruta_archivo_comprobante: filePath || currentAdditionalToEdit.ruta_archivo_comprobante
      };

      const { error } = await supabase
        .from('presupuestos_adicionales')
        .update(updatedAdicional)
        .eq('id', currentAdditionalToEdit.id);

      if (error) throw error;

      // Reset form and refresh data
      setAdditionalFormData({
        presupuesto_id: '',
        monto: '',
        moneda: 'ARS',
        descripcion: '',
        tiene_comprobante: false,
        imagen_comprobante: null,
        codigo_comprobante: generateComprobanteCodigo(),
        ruta_archivo_comprobante: ''
      });
      setAdditionalFilePreview(null);

      setOpenEditAdditionalDialog(false);
      setCurrentAdditionalToEdit(null);
      fetchPresupuestos();

      // Show success alert
      setSnackbarInfo({
        open: true,
        message: 'Adicional actualizado correctamente',
        severity: 'success'
      });

    } catch (error) {
      console.error('Error updating adicional:', error);
      setSnackbarInfo({
        open: true,
        message: 'Error al actualizar adicional: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Handle delete additional
  const handleDeleteAdicional = async () => {
    try {
      setLoading(true);

      if (!currentAdditionalToDelete) return;

      const { error } = await supabase
        .from('presupuestos_adicionales')
        .delete()
        .eq('id', currentAdditionalToDelete.id);

      if (error) throw error;

      setOpenDeleteAdditionalDialog(false);
      setCurrentAdditionalToDelete(null);
      fetchPresupuestos();

      // Show success alert
      setSnackbarInfo({
        open: true,
        message: 'Adicional eliminado correctamente',
        severity: 'success'
      });

    } catch (error) {
      console.error('Error deleting adicional:', error);
      setSnackbarInfo({
        open: true,
        message: 'Error al eliminar adicional: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Función para abrir el detalle de un pago
  const handleViewPaymentDetail = (payment) => {
    setSelectedPaymentDetail(payment);
    setPaymentDetailOpen(true);
  };


  // Handle expanding card details
  const handleExpandClick = (id) => {
    setExpanded(prev => ({
      ...prev,
      [id]: !prev[id]
    }));
  };

  // Generate PDF with budget details
  const generatePDF = (presupuesto) => {
    try {
      // Crear nueva instancia de jsPDF
      const doc = new jsPDF();
      const providerName = presupuesto.proveedor_de_servicios || 'Desconocido';

      // Añadir logo
      try {
        const logoUrl = '/images/logo.png';
        const logoImg = new Image();
        logoImg.src = logoUrl;

        // Usar logo si está disponible
        doc.addImage('/images/logo.png', 'PNG', 14, 10, 40, 20);
      } catch (logoError) {
        console.error('Error adding logo to PDF:', logoError);
        // Continuar sin logo
      }

      // Título y encabezado
      doc.setFontSize(20);
      doc.setTextColor(46, 125, 50); // Verde
      doc.text('PRESUPUESTO', 105, 20, { align: 'center' });

      // Fecha del documento
      doc.setFontSize(10);
      doc.setTextColor(100, 100, 100);
      doc.text(`Fecha de emisión: ${format(new Date(), 'dd/MM/yyyy')}`, 195, 20, { align: 'right' });

      // Información básica en un cuadro
      doc.setDrawColor(220, 220, 220);
      doc.setFillColor(250, 250, 250);
      doc.roundedRect(14, 35, 182, 50, 3, 3, 'FD');

      doc.setFontSize(11);
      doc.setTextColor(60, 60, 60);
      doc.setFont(undefined, 'bold');
      doc.text('INFORMACIÓN DEL PRESUPUESTO', 105, 45, { align: 'center' });

      doc.setFont(undefined, 'normal');
      doc.setFontSize(10);

      // Columna izquierda
      doc.text(`Proyecto: `, 20, 55);
      doc.text(`Proveedor: `, 20, 65);
      doc.text(`Fecha: `, 20, 75);

      // Columna derecha
      doc.text(`Tipo: `, 120, 55);
      doc.text(`Moneda: `, 120, 65);
      doc.text(`Estado: `, 120, 75);

      // Valores en negrita
      doc.setFont(undefined, 'bold');
      doc.text(presupuesto.codigo_de_proyecto, 45, 55);
      doc.text(providerName, 45, 65);
      doc.text(format(new Date(presupuesto.fecha_creacion), 'dd/MM/yyyy'), 45, 75);

      doc.text(presupuesto.tipo_presupuesto.replace('_', ' '), 140, 55);
      doc.text(getCurrencySymbol(presupuesto.moneda), 140, 65);

      // Status calculado
      const status = calculateStatus(presupuesto);
      doc.setTextColor(
        status.statusText === "Completado" ? 46 : (status.statusText === "En progreso" ? 33 : 255),
        status.statusText === "Completado" ? 125 : (status.statusText === "En progreso" ? 150 : 153),
        status.statusText === "Completado" ? 50 : (status.statusText === "En progreso" ? 243 : 0)
      );
      doc.text(status.statusText, 140, 75);

      // Resetear color
      doc.setTextColor(60, 60, 60);

      // Detalles de montos
      doc.setDrawColor(220, 220, 220);
      doc.setFillColor(250, 250, 250);
      doc.roundedRect(14, 95, 182, 62, 3, 3, 'FD');

      doc.setFont(undefined, 'bold');
      doc.text('MONTOS', 105, 105, { align: 'center' });
      doc.setFont(undefined, 'normal');

      // Primera columna
      doc.text('Monto Original:', 20, 115);
      doc.text('Adicionales:', 20, 125);
      doc.text('Total Presupuestado:', 20, 135);
      doc.text('Total Pagado:', 20, 145);
      doc.text('Saldo Restante:', 20, 155);

      // Valores
      doc.setFont(undefined, 'bold');
      doc.text(`${presupuesto.monto.toLocaleString('es-AR')} ${getCurrencySymbol(presupuesto.moneda)}`, 70, 115, { align: 'right' });

      // Calcular total de adicionales (ahora usando la columna adicionales_monto)
      const totalAdicionales = Number(presupuesto.adicionales_monto || 0);

      doc.text(`${totalAdicionales.toLocaleString('es-AR')} ${getCurrencySymbol(presupuesto.moneda)}`, 70, 125, { align: 'right' });
      doc.text(`${status.totalBudget.toLocaleString('es-AR')} ${getCurrencySymbol(presupuesto.moneda)}`, 70, 135, { align: 'right' });
      doc.text(`${status.totalPaid.toLocaleString('es-AR')} ${getCurrencySymbol(presupuesto.moneda)}`, 70, 145, { align: 'right' });
      doc.text(`${status.remaining.toLocaleString('es-AR')} ${getCurrencySymbol(presupuesto.moneda)}`, 70, 155, { align: 'right' });

      // Segunda columna - Equivalentes en USD
      doc.setFont(undefined, 'normal');
      doc.text('Valor en USD:', 120, 115);
      doc.text('Adicionales en USD:', 120, 125);
      doc.text('Total en USD:', 120, 135);
      doc.text('Pagado en USD:', 120, 145);
      doc.text('Avance:', 120, 155);

      // Calcular valores en USD usando la vista
      const montoUSD = presupuesto.monto_en_usd_presupuestado || 0;
      const totalAdicionalesUSD = presupuesto.adicionales_usd || 0;
      const totalUSD = presupuesto.total_presupuestado_usd || 0;
      const totalPagadoUSD = presupuesto.total_pagado_usd || 0;

      // Valores en USD
      doc.setFont(undefined, 'bold');
      doc.text(`USD ${montoUSD.toLocaleString('es-AR')}`, 170, 115, { align: 'right' });
      doc.text(`USD ${totalAdicionalesUSD.toLocaleString('es-AR')}`, 170, 125, { align: 'right' });
      doc.text(`USD ${totalUSD.toLocaleString('es-AR')}`, 170, 135, { align: 'right' });
      doc.text(`USD ${totalPagadoUSD.toLocaleString('es-AR')}`, 170, 145, { align: 'right' });
      doc.text(`${status.percentagePaid.toFixed(1)}%`, 170, 155, { align: 'right' });

      // Barra de progreso
      const barY = 164;
      doc.setDrawColor(220, 220, 220);
      doc.setFillColor(240, 240, 240);
      doc.roundedRect(20, barY, 175, 5, 2, 2, 'FD');

      // Parte completada de la barra
      const barWidth = Math.min(175 * (status.percentagePaid / 100), 175);
      doc.setFillColor(
        status.statusText === "Completado" ? 46 : (status.statusText === "En progreso" ? 33 : 255),
        status.statusText === "Completado" ? 125 : (status.statusText === "En progreso" ? 150 : 153),
        status.statusText === "Completado" ? 50 : (status.statusText === "En progreso" ? 243 : 0)
      );

      if (barWidth > 0) {
        doc.roundedRect(20, barY, barWidth, 5, 2, 2, 'F');
      }

      // Resetear color
      doc.setTextColor(60, 60, 60);

      // Descripción
      if (presupuesto.descripcion) {
        doc.setDrawColor(220, 220, 220);
        doc.setFillColor(250, 250, 250);
        doc.roundedRect(14, 167, 182, 30, 3, 3, 'FD');

        doc.setFont(undefined, 'bold');
        doc.text('DESCRIPCIÓN', 105, 177, { align: 'center' });
        doc.setFont(undefined, 'normal');

        // Si la descripción es muy larga, limitar y mostrar parte
        const descripcion = presupuesto.descripcion.toString();
        if (descripcion.length > 200) {
          doc.text(descripcion.substring(0, 200) + '...', 20, 187, { maxWidth: 170 });
        } else {
          doc.text(descripcion, 20, 187, { maxWidth: 170 });
        }
      }

      // Agregar sección de pagos relacionados si hay
      let yPos = presupuesto.descripcion ? 207 : 177;

      // Si hay pagos relacionados, agregarlos
      if (presupuesto.pagosIdsArray && presupuesto.pagosIdsArray.length > 0) {
        // Si queda poco espacio, nueva página
        if (yPos > 230) {
          doc.addPage();
          yPos = 20;
        }

        doc.setFontSize(12);
        doc.setFont(undefined, 'bold');
        doc.text('RESUMEN DE PAGOS', 105, yPos, { align: 'center' });
        yPos += 10;

        // Crear un resumen de los pagos
        doc.setFontSize(10);
        doc.setFont(undefined, 'normal');
        doc.text(`Cantidad de pagos: ${presupuesto.cantidad_pagos || 0}`, 20, yPos);
        yPos += 10;

        // Agregar información sobre pagos totales
        if (presupuesto.total_pagado_ars) {
          doc.text(`Total pagado en ARS: ${presupuesto.total_pagado_ars.toLocaleString('es-AR')} ARS`, 20, yPos);
          yPos += 8;
        }

        if (presupuesto.total_pagado_usd) {
          doc.text(`Total pagado en USD: ${presupuesto.total_pagado_usd.toLocaleString('es-AR')} USD`, 20, yPos);
          yPos += 8;
        }
      }

      // Agregar sección de firmas
      if (yPos > 240) {
        doc.addPage();
        yPos = 20;
      }

      yPos += 20;

      doc.setDrawColor(80, 80, 80);
      // Firma proveedor
      doc.line(30, yPos + 20, 90, yPos + 20);
      doc.setFontSize(10);
      doc.setFont(undefined, 'normal');
      doc.text('Firma del Proveedor', 60, yPos + 30, { align: 'center' });
      doc.text(providerName, 60, yPos + 40, { align: 'center' });

      // Firma empresa
      doc.line(110, yPos + 20, 170, yPos + 20);
      doc.text('Firma Autorizada', 140, yPos + 30, { align: 'center' });
      doc.text(empresaInfo.nombre_de_empresa || '', 140, yPos + 40, { align: 'center' });

      // Pie de página
      const pageCount = doc.internal.getNumberOfPages();
      for (let i = 1; i <= pageCount; i++) {
        doc.setPage(i);
        doc.setFontSize(8);
        doc.setTextColor(150, 150, 150);
        doc.text(`Página ${i} de ${pageCount}`, 105, 287, { align: 'center' });
        doc.text(`Presupuesto generado el ${format(new Date(), 'dd/MM/yyyy HH:mm')}`, 105, 292, { align: 'center' });
      }

      // Guardar el PDF
      doc.save(`Presupuesto_${presupuesto.codigo_de_proyecto}_${providerName}.pdf`);

      // Mostrar alerta de éxito
      setSnackbarInfo({
        open: true,
        message: 'PDF generado correctamente',
        severity: 'success'
      });

    } catch (error) {
      console.error('Error generating PDF:', error);
      setSnackbarInfo({
        open: true,
        message: 'Error al generar PDF: ' + error.message,
        severity: 'error'
      });
    }
  };

  // Format currency based on type
  const formatCurrency = (amount, currency) => {
    return `${amount.toLocaleString('es-AR')} ${getCurrencySymbol(currency)}`;
  };

  // Get currency symbol based on currency type
  const getCurrencySymbol = (currency) => {
    switch (currency) {
      case 'USD':
        return 'USD';
      case 'ARS':
        return 'ARS';
      case 'DOLAR_OFICIAL_VENTA':
        return 'USD Oficial Venta';
      default:
        // Podría ser una moneda personalizada
        return currency;
    }
  };

  // Handle opening edit dialog
  const handleOpenEditDialog = (presupuesto) => {
    setCurrentPresupuestoToEdit(presupuesto);
    setFormData({
      codigo_de_proyecto: presupuesto.codigo_de_proyecto,
      proveedor_id: presupuesto.proveedor_id,
      subetapa_de_obra: presupuesto.subetapa_de_obra,
      monto: presupuesto.monto,
      moneda: presupuesto.moneda,
      tipo_presupuesto: presupuesto.tipo_presupuesto,
      descripcion: presupuesto.descripcion || '',
      tiene_comprobante: presupuesto.tiene_comprobante || false,
      imagen_comprobante: presupuesto.imagen_comprobante || null,
      codigo_comprobante: presupuesto.codigo_comprobante || '',
      ruta_archivo_comprobante: presupuesto.ruta_archivo_comprobante || ''
    });
    setFilePreview(presupuesto.imagen_comprobante || null);
    setOpenEditDialog(true);
  };

  // Handle opening edit additional dialog
  const handleOpenEditAdditionalDialog = (adicional) => {
    setCurrentAdditionalToEdit(adicional);
    setAdditionalFormData({
      presupuesto_id: adicional.presupuesto_id,
      monto: adicional.monto,
      moneda: adicional.moneda,
      descripcion: adicional.descripcion || '',
      tiene_comprobante: adicional.tiene_comprobante || false,
      imagen_comprobante: adicional.imagen_comprobante || null,
      codigo_comprobante: adicional.codigo_comprobante || '',
      ruta_archivo_comprobante: adicional.ruta_archivo_comprobante || ''
    });
    setAdditionalFilePreview(adicional.imagen_comprobante || null);
    setOpenEditAdditionalDialog(true);
  };

  // UI Elements
  const renderStatusIndicator = (presupuesto) => {
    const status = calculateStatus(presupuesto);

    return (
      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
          <Typography variant="body2">Progreso del pago</Typography>
          <Typography variant="body2" fontWeight="bold">
            {status.percentagePaid.toFixed(1)}%
          </Typography>
        </Box>
        <IndicatorBar
          percentage={status.percentagePaid}
          color={status.statusColor}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
          <StatusChip
            label={status.statusText}
            size="small"
            statuscolor={status.statusColor}
          />
          <Typography variant="body2">
            {formatCurrency(status.totalPaid, presupuesto.moneda)} / {formatCurrency(status.totalBudget, presupuesto.moneda)}
          </Typography>
        </Box>
      </Box>
    );
  };

  // Renderizar tabla de adicionales - Ahora es un resumen
  const renderAdditionalsTable = (presupuesto) => {
    // Como no tenemos los adicionales individuales en la vista, mostramos un resumen
    if (!presupuesto.adicionales_monto || Number(presupuesto.adicionales_monto) <= 0) return null;

    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>Adicionales:</Typography>
        <Paper sx={{ p: 2, borderRadius: 2, bgcolor: 'rgba(0, 0, 0, 0.02)' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
            <Typography variant="body2" color="text.secondary">Monto total adicionales:</Typography>
            <Typography variant="body1" fontWeight="bold">
              {formatCurrency(Number(presupuesto.adicionales_monto), presupuesto.moneda)}
            </Typography>
          </Box>
          {presupuesto.adicionales_usd > 0 && (
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="body2" color="text.secondary">Equivalente en USD:</Typography>
              <Typography variant="body1">
                USD {Number(presupuesto.adicionales_usd).toLocaleString('es-AR')}
              </Typography>
            </Box>
          )}
        </Paper>
      </Box>
    );
  };

  // Renderizar la tabla de pagos
  const renderPaymentsTable = () => {
    if (loadingPayments) {
      return (
        <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={30} />
        </Box>
      );
    }

    if (!relatedPayments || relatedPayments.length === 0) {
      return (
        <Box sx={{ p: 2, textAlign: 'center' }}>
          <Typography color="text.secondary">No hay pagos registrados para este presupuesto</Typography>
        </Box>
      );
    }

    return (
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle2" sx={{ mb: 1 }}>Pagos realizados:</Typography>

        {relatedPayments.map((payment) => (
          <PaymentCard
            key={payment.id}
            onClick={() => handleViewPaymentDetail(payment)}
            elevation={1}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <DateRangeIcon sx={{ mr: 1, color: 'primary.main', fontSize: 20 }} />
                <Typography variant="body2">
                  {format(new Date(payment.fecha_salida || '1970-01-01'), 'dd/MM/yyyy')}
                </Typography>
              </Box>
              <Chip
                label={formatCurrency(Number(payment.monto_salida || 0), payment.moneda_salida || 'ARS')}
                size="small"
                color="primary"
                variant="outlined"
              />
            </Box>

            <Typography
              variant="body2"
              sx={{ mt: 1, fontWeight: 'medium', display: 'flex', alignItems: 'center' }}
            >
              <PaymentIcon sx={{ mr: 1, fontSize: 16, color: 'text.secondary' }} />
              {payment.concepto_salidas || 'Sin concepto'}
            </Typography>

            {payment.proveedor_salida && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 0.5 }}>
                Proveedor: {payment.proveedor_salida}
              </Typography>
            )}

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
              <Typography variant="caption" color="text.secondary">
                ARS: {Number(payment.monto_en_ars_salida || 0).toLocaleString('es-AR')}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                USD: {Number(payment.monto_en_usd_salida || 0).toLocaleString('es-AR')}
              </Typography>
            </Box>

            {payment.tiene_comprobante_salida && (
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 1 }}>
                <Chip
                  icon={<ReceiptIcon fontSize="small" />}
                  label="Comprobante"
                  size="small"
                  variant="outlined"
                  color="success"
                />
              </Box>
            )}
          </PaymentCard>
        ))}
      </Box>
    );
  };

  const renderMobileCard = (presupuesto) => {
    // Get provider name from the new view structure
    const providerName = presupuesto.proveedor_de_servicios || 'Desconocido';

    // Calculate current status
    const status = calculateStatus(presupuesto);

    return (
      <motion.div
        key={presupuesto.id}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
        layout
      >
        <PresupuestoCard onClick={() => {
          setSelectedPresupuesto(presupuesto);
          setDetailDrawerOpen(true);
        }}>
          <CardContent>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>
                {providerName}
              </Typography>
              <Chip
                size="small"
                label={presupuesto.codigo_de_proyecto}
                color="secondary"
                variant="outlined"
                sx={{ fontSize: '0.75rem' }}
              />
            </Box>

            <Typography variant="body2" color="text.secondary" sx={{ mt: 1, fontSize: '0.8rem' }}>
              {format(new Date(presupuesto.fecha_creacion), 'dd/MM/yyyy')} - {presupuesto.subetapa_de_obra}
            </Typography>

            {renderStatusIndicator(presupuesto)}

            <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box>
                <Typography variant="body2" color="text.secondary" fontSize="0.75rem">
                  Total
                </Typography>
                <Typography variant="body1" fontWeight="bold">
                  {formatCurrency(status.totalBudget, presupuesto.moneda)}
                </Typography>
              </Box>
              <Box sx={{ textAlign: 'right' }}>
                <Typography variant="body2" color="text.secondary" fontSize="0.75rem">
                  Saldo
                </Typography>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  color={status.remaining > 0 ? 'text.primary' : 'success.main'}
                >
                  {formatCurrency(status.remaining, presupuesto.moneda)}
                </Typography>
              </Box>
            </Box>
          </CardContent>
        </PresupuestoCard>
      </motion.div>
    );
  };
  const renderSaldosSection = () => {
    if (tab !== 1 || isClientUser) return null;

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <Paper
          elevation={0}
          sx={{
            p: 2,
            mb: 3,
            borderRadius: 2,
            backgroundColor: 'rgba(236, 246, 253, 0.6)',
            borderLeft: '3px solid #2196F3'
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
            <Typography
              variant="subtitle1"
              onClick={() => setShowSaldosSection(!showSaldosSection)}
              sx={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'primary.main',
                fontWeight: 500,
                '&:hover': { color: 'primary.dark' }
              }}
            >
              <AccountBalanceIcon sx={{ mr: 1, fontSize: 20 }} />
              Resumen de Saldos
              <ExpandMoreIcon
                sx={{
                  ml: 1,
                  fontSize: 20,
                  transform: showSaldosSection ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              />
            </Typography>
          </Box>

          <Collapse in={showSaldosSection}>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                {/* A cobrar */}
                <Grid item xs={6}>
                  <Paper
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      backgroundColor: '#fff',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                      border: '1px solid rgba(0,0,0,0.03)'
                    }}
                  >
                    <Typography variant="subtitle2" color="text.secondary" fontSize="0.8rem">
                      Total a cobrar
                    </Typography>
                    {loadingSaldos ? (
                      <Skeleton variant="text" width="80%" height={30} />
                    ) : (
                      <Typography variant="h6" color="success.main" sx={{ mt: 1, fontWeight: 'bold' }}>
                        ARS {obtenerTotalCobrar().toLocaleString('es-AR')}
                      </Typography>
                    )}
                  </Paper>
                </Grid>

                {/* A pagar */}
                <Grid item xs={6}>
                  <Paper
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      backgroundColor: '#fff',
                      boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
                      border: '1px solid rgba(0,0,0,0.03)'
                    }}
                  >
                    <Typography variant="subtitle2" color="text.secondary" fontSize="0.8rem">
                      Total a pagar
                    </Typography>
                    {loading ? (
                      <Skeleton variant="text" width="80%" height={30} />
                    ) : (
                      <Typography variant="h6" color="error.main" sx={{ mt: 1, fontWeight: 'bold' }}>
                        ARS {calcularTotalSaldosPagar().toLocaleString('es-AR')}
                      </Typography>
                    )}
                  </Paper>
                </Grid>

                {/* Disponible en cajas */}
                <Grid item xs={12}>
                  <Paper
                    sx={{
                      p: 2,
                      borderRadius: 2,
                      backgroundColor: '#f9fafe',
                      boxShadow: '0 2px 4px rgba(0,0,0,0.06)'
                    }}
                  >
                    <Typography variant="subtitle2" color="text.secondary" fontSize="0.8rem">
                      Disponible en cajas MO
                    </Typography>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                      {cajasMO.loading ? (
                        <>
                          <Skeleton variant="text" width="40%" height={24} />
                          <Skeleton variant="text" width="40%" height={24} />
                        </>
                      ) : (
                        <>
                          <Typography variant="body1" fontWeight="medium" color="primary">
                            ARS: {cajasMO.ars.toLocaleString('es-AR')}
                          </Typography>
                          <Typography variant="body1" fontWeight="medium" color="primary">
                            USD: {cajasMO.usd.toLocaleString('es-AR')}
                          </Typography>
                        </>
                      )}
                    </Box>
                  </Paper>
                </Grid>
              </Grid>

              <Box sx={{ mt: 2, backgroundColor: 'rgba(255,255,255,0.7)', p: 2, borderRadius: 2, boxShadow: '0 1px 4px rgba(0,0,0,0.03)' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="subtitle2" fontSize="0.8rem">
                    Balance neto:
                  </Typography>
                  {loading || loadingSaldos || cajasMO.loading ? (
                    <Skeleton variant="text" width="40%" height={30} />
                  ) : (
                    <Typography
                      variant="h6"
                      color={calcularBalanceNeto() >= 0 ? 'success.main' : 'error.main'}
                      sx={{ fontWeight: 'bold' }}
                    >
                      ARS {calcularBalanceNeto().toLocaleString('es-AR')}
                    </Typography>
                  )}
                </Box>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                  <Typography variant="body2" color="text.secondary" fontSize="0.75rem">
                    Equivalente USD:
                  </Typography>
                  {loading || loadingSaldos || cajasMO.loading ? (
                    <Skeleton variant="text" width="30%" height={24} />
                  ) : (
                    <Typography
                      variant="body1"
                      color={calcularBalanceNetoUSD() >= 0 ? 'success.main' : 'error.main'}
                    >
                      USD {calcularBalanceNetoUSD().toLocaleString('es-AR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                    </Typography>
                  )}
                </Box>
              </Box>
            </Box>
          </Collapse>
        </Paper>
      </motion.div>
    );
  };

  const renderMobilePresupuestos = () => {
    if (loading && !dataLoaded) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mb: 7 }}>
          <SkeletonCard />
          <SkeletonCard />
          <SkeletonCard />
        </Box>
      );
    }

    if (presupuestos.length === 0 && dataLoaded) {
      return (
        <Paper sx={{ p: 3, borderRadius: 2, textAlign: 'center', mt: 2 }}>
          <Typography variant="h6">No hay presupuestos</Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
            No hay presupuestos disponibles para este proyecto o tipo.
          </Typography>
          {!isClientUser && (
            <Button
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => {
                setFormData(prev => ({
                  ...prev,
                  codigo_de_proyecto: currentProject,
                  tipo_presupuesto: isClientUser ? 'GASTOS_GENERALES' : (tab === 0 ? 'GASTOS_GENERALES' : 'MANO_DE_OBRA'),
                  codigo_comprobante: generateComprobanteCodigo()
                }));
                setOpenDialog(true);
              }}
              sx={{ borderRadius: 8 }}
            >
              Agregar Presupuesto
            </Button>
          )}
        </Paper>
      );
    }

    return (
      <AnimatePresence>
        <Box sx={{ mb: 7 }}>
          {presupuestos.map(presupuesto => (
            <FadeInContent key={presupuesto.id} loading={loading}>
              {renderMobileCard(presupuesto)}
            </FadeInContent>
          ))}
        </Box>
      </AnimatePresence>
    );
  };
  return (
    <Box sx={{ pb: 4 }}>
      {/* Tabs navigation - Solo mostrar para no clientes */}
      {!isClientUser && (
        <Paper
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 10,
            borderRadius: '24px',
            boxShadow: '0 4px 15px rgba(0, 0, 0, 0.07)',
            mb: 2,
            overflow: 'hidden'
          }}
        >
          <Box sx={{ p: 1.5, bgcolor: 'white' }}>
            <Box
              sx={{
                display: 'flex',
                gap: 1,
                p: 0.5,
                bgcolor: 'rgba(0, 0, 0, 0.04)',
                borderRadius: 4
              }}
            >
              <Button
                fullWidth
                variant={tab === 0 ? "contained" : "text"}
                onClick={() => handleTabChange(null, 0)}
                startIcon={<AttachMoneyIcon />}
                sx={{
                  borderRadius: 3,
                  py: 1.2,
                  color: tab === 0 ? 'white' : 'text.primary',
                  boxShadow: tab === 0 ? 2 : 'none',
                  '&:hover': {
                    bgcolor: tab === 0 ? 'primary.main' : 'rgba(0, 0, 0, 0.08)'
                  }
                }}
              >
                Gastos
              </Button>
              <Button
                fullWidth
                variant={tab === 1 ? "contained" : "text"}
                onClick={() => handleTabChange(null, 1)}
                startIcon={<ConstructionIcon />}
                sx={{
                  borderRadius: 3,
                  py: 1.2,
                  color: tab === 1 ? 'white' : 'text.primary',
                  boxShadow: tab === 1 ? 2 : 'none',
                  '&:hover': {
                    bgcolor: tab === 1 ? 'primary.main' : 'rgba(0, 0, 0, 0.08)'
                  }
                }}
              >
                Mano de Obra
              </Button>
            </Box>
          </Box>
        </Paper>
      )}

      <Container maxWidth="sm" sx={{ mt: 2, pb: 2 }}>
        {/* Exchange rates info */}
        <Paper
          elevation={0}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            p: 2,
            mb: 2,
            borderRadius: 4,
            background: 'linear-gradient(145deg, rgba(33, 150, 243, 0.05) 0%, rgba(33, 150, 243, 0.1) 100%)',
            border: '1px solid rgba(33, 150, 243, 0.12)',
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.04)'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AttachMoneyIcon color="primary" sx={{ mr: 1, fontSize: 20 }} />
            <Box>
              <Typography variant="body2" sx={{ fontSize: '0.75rem', color: 'text.secondary', fontWeight: 500 }}>
                USD Blue Venta
              </Typography>
              {loading && !dataLoaded ? (
                <Skeleton variant="text" width={60} height={24} />
              ) : (
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                  {exchangeRates.DOLAR_BLUE_VENTA}
                </Typography>
              )}
            </Box>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <AccountBalanceIcon color="primary" sx={{ mr: 1, fontSize: 20 }} />
            <Box>
              <Typography variant="body2" sx={{ fontSize: '0.75rem', color: 'text.secondary', fontWeight: 500 }}>
                USD Oficial
              </Typography>
              {loading && !dataLoaded ? (
                <Skeleton variant="text" width={60} height={24} />
              ) : (
                <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'primary.main' }}>
                  {exchangeRates.DOLAR_OFICIAL_VENTA}
                </Typography>
              )}
            </Box>
          </Box>

          <IconButton
            size="small"
            onClick={fetchExchangeRates}
            color="primary"
            sx={{
              bgcolor: 'white',
              boxShadow: '0 2px 5px rgba(0, 0, 0, 0.08)',
              '&:hover': {
                bgcolor: 'primary.light',
                color: 'white'
              }
            }}
          >
            <RefreshIcon fontSize="small" />
          </IconButton>
        </Paper>

        {/* Search and Filters */}
        {/* Para clientes, no mostramos filtros de proyectos */}
        {!isClientUser && (
          <>
            <SearchBar sx={{ mb: 2 }}>
              <SearchIconWrapper>
                <SearchIcon />
              </SearchIconWrapper>
              <StyledInputBase
                placeholder="Buscar presupuestos..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                fullWidth
                size="small"
                variant="outlined"
              />
            </SearchBar>

            <Box sx={{ mb: 2 }}>
              <TextField
                select
                fullWidth
                label="Filtrar por Proyecto"
                value={currentProject}
                onChange={(e) => setCurrentProject(e.target.value)}
                variant="outlined"
                size="small"
                sx={{ borderRadius: 2 }}
              >
                <MenuItem value="">Todos los proyectos</MenuItem>
                {proyectos.map((proyecto) => (
                  <MenuItem key={proyecto.codigo_de_proyecto} value={proyecto.codigo_de_proyecto}>
                    {proyecto.codigo_de_proyecto}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </>
        )}

        {/* Para clientes, mostrar el proyecto filtrado automáticamente */}
        {isClientUser && clientProject && (
          <Paper
            sx={{
              p: 2,
              mb: 2,
              borderRadius: 4,
              bgcolor: 'rgba(33, 150, 243, 0.05)',
              border: '1px solid rgba(33, 150, 243, 0.12)'
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: 'medium', display: 'flex', alignItems: 'center' }}>
              <CodeIcon sx={{ mr: 1, fontSize: 20, color: 'primary.main' }} />
              Proyecto: <Typography component="span" sx={{ ml: 1, fontWeight: 'bold' }}>{clientProject}</Typography>
            </Typography>
          </Paper>
        )}

        {/* Saldos section - Solo visible para administradores en tab "Mano de Obra" */}
        {renderSaldosSection()}

        {/* Presupuestos list */}
        {renderMobilePresupuestos()}
      </Container>

      {/* FAB - Solo mostrar para no clientes */}
      {!isClientUser && (
        <FixedFab
          color="primary"
          aria-label="add"
          onClick={() => {
            setFormData(prev => ({
              ...prev,
              codigo_de_proyecto: currentProject,
              tipo_presupuesto: isClientUser ? 'GASTOS_GENERALES' : (tab === 0 ? 'GASTOS_GENERALES' : 'MANO_DE_OBRA'),
              codigo_comprobante: generateComprobanteCodigo()
            }));
            setOpenDialog(true);
          }}
        >
          <AddIcon />
        </FixedFab>
      )}
      {/* Detail Drawer */}
      <DetailDrawer
        anchor="right"
        open={detailDrawerOpen}
        onClose={() => setDetailDrawerOpen(false)}
      >
        {selectedPresupuesto && (
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, position: 'sticky', top: 0, zIndex: 10, backgroundColor: 'white', py: 1 }}>
              <IconButton onClick={() => setDetailDrawerOpen(false)}>
                <ArrowBackIcon />
              </IconButton>
              <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                Detalles
              </Typography>
              <Button
                variant="contained"
                startIcon={<PdfIcon />}
                onClick={() => generatePDF(selectedPresupuesto)}
                size="small"
                color="secondary"
              >
                PDF
              </Button>
            </Box>

            <Box sx={{ mb: 2 }}>
              {/* Provider Name and Project */}
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 1 }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {selectedPresupuesto.proveedor_de_servicios || 'Desconocido'}
                </Typography>
                <Chip
                  label={selectedPresupuesto.codigo_de_proyecto}
                  color="secondary"
                  size="small"
                />
              </Box>

              <Typography variant="body2" color="text.secondary">
                {selectedPresupuesto.subetapa_de_obra}
              </Typography>

              <Typography variant="body2" color="text.secondary">
                Creado: {format(new Date(selectedPresupuesto.fecha_creacion), 'dd/MM/yyyy')}
              </Typography>

              {selectedPresupuesto.descripcion && (
                <Box sx={{ mt: 2, mb: 2, p: 1.5, backgroundColor: 'rgba(0, 0, 0, 0.03)', borderRadius: 2 }}>
                  <Typography variant="body2">
                    {selectedPresupuesto.descripcion}
                  </Typography>
                </Box>
              )}

              {/* Progress and Status */}
              {renderStatusIndicator(selectedPresupuesto)}

              {/* Amount Details */}
              <Grid container spacing={2} sx={{ mt: 2 }}>
                <Grid item xs={6}>
                  <Paper sx={{ p: 2, borderRadius: 2, bgcolor: 'rgba(0, 0, 0, 0.02)' }}>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Monto Original
                    </Typography>
                    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                      {formatCurrency(selectedPresupuesto.monto, selectedPresupuesto.moneda)}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid item xs={6}>
                  <Paper sx={{ p: 2, borderRadius: 2, bgcolor: 'rgba(0, 0, 0, 0.02)' }}>
                    <Typography variant="body2" color="text.secondary" gutterBottom>
                      Saldo Restante
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: 'bold' }}
                      color={calculateStatus(selectedPresupuesto).remaining > 0 ? 'text.primary' : 'success.main'}
                    >
                      {formatCurrency(calculateStatus(selectedPresupuesto).remaining, selectedPresupuesto.moneda)}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>

              <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between', backgroundColor: 'rgba(33, 150, 243, 0.08)', p: 2, borderRadius: 2 }}>
                <Typography variant="body2" color="primary">
                  USD Presup: <b>${selectedPresupuesto.total_presupuestado_usd.toLocaleString('es-AR')}</b>
                </Typography>
                <Typography variant="body2" color="primary">
                  USD Pagado: <b>${selectedPresupuesto.total_pagado_usd.toLocaleString('es-AR')}</b>
                </Typography>
              </Box>
            </Box>

            {/* Tabs for additionals and payments */}
            <Box sx={{ width: '100%', mb: 2 }}>
              <Tabs
                value={expanded[selectedPresupuesto.id] ? 1 : 0}
                onChange={() => handleExpandClick(selectedPresupuesto.id)}
                variant="fullWidth"
                sx={{ mb: 1 }}
              >
                <Tab label="Adicionales" />
                <Tab label="Pagos" />
              </Tabs>

              <Collapse in={expanded[selectedPresupuesto.id] === undefined || expanded[selectedPresupuesto.id] === false}>
                {renderAdditionalsTable(selectedPresupuesto)}
              </Collapse>

              <Collapse in={expanded[selectedPresupuesto.id] === true}>
                {renderPaymentsTable()}
              </Collapse>
            </Box>

            {/* Actions - Solo mostrar para no clientes */}
            {!isClientUser && (
              <Box sx={{ display: 'flex', gap: 2, mt: 3 }}>
                <Button
                  variant="contained"
                  startIcon={<EditIcon />}
                  onClick={() => {
                    handleOpenEditDialog(selectedPresupuesto);
                    setDetailDrawerOpen(false);
                  }}
                  fullWidth
                >
                  Editar
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<TrendingUpIcon />}
                  onClick={() => {
                    setAdditionalFormData(prev => ({
                      ...prev,
                      presupuesto_id: selectedPresupuesto.id,
                      moneda: selectedPresupuesto.moneda,
                      codigo_comprobante: generateComprobanteCodigo()
                    }));
                    setOpenAdditionalDialog(true);
                    setDetailDrawerOpen(false);
                  }}
                  fullWidth
                >
                  Adicional
                </Button>
                <Button
                  variant="outlined"
                  startIcon={<DeleteIcon />}
                  onClick={() => {
                    setCurrentPresupuestoToDelete(selectedPresupuesto);
                    setOpenDeleteDialog(true);
                    setDetailDrawerOpen(false);
                  }}
                  fullWidth
                  color="error"
                >
                  Eliminar
                </Button>
              </Box>
            )}
          </Box>
        )}
      </DetailDrawer>

      {/* Detailed Payment Modal */}
      <Modal
        open={paymentDetailOpen}
        onClose={() => setPaymentDetailOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={paymentDetailOpen}>
          <ModalContent>
            {selectedPaymentDetail && (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
                  <Typography variant="h6" component="h2" sx={{ fontWeight: 'bold' }}>
                    Detalle del Pago
                  </Typography>
                  <IconButton onClick={() => setPaymentDetailOpen(false)}>
                    <CloseIcon />
                  </IconButton>
                </Box>

                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3, borderRadius: 2, height: '100%', boxShadow: '0 4px 12px rgba(0,0,0,0.05)' }}>
                      <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold', color: 'primary.main', borderBottom: '2px solid', borderColor: 'primary.light', pb: 1 }}>
                        Información General
                      </Typography>

                      <DetailItem>
                        <DetailLabel variant="body2">Fecha:</DetailLabel>
                        <DetailValue variant="body1">
                          {format(new Date(selectedPaymentDetail.fecha_salida), 'dd/MM/yyyy')}
                        </DetailValue>
                      </DetailItem>

                      <DetailItem>
                        <DetailLabel variant="body2">Proveedor:</DetailLabel>
                        <DetailValue variant="body1">{selectedPaymentDetail.proveedor_salida || '-'}</DetailValue>
                      </DetailItem>

                      <DetailItem>
                        <DetailLabel variant="body2">Caja Origen:</DetailLabel>
                        <DetailValue variant="body1">{selectedPaymentDetail.caja_origen_salida || '-'}</DetailValue>
                      </DetailItem>

                      <DetailItem>
                        <DetailLabel variant="body2">Concepto:</DetailLabel>
                        <DetailValue variant="body1">{selectedPaymentDetail.concepto_salidas || '-'}</DetailValue>
                      </DetailItem>

                      <DetailItem>
                        <DetailLabel variant="body2">Proyecto:</DetailLabel>
                        <DetailValue variant="body1">{selectedPaymentDetail.codigo_de_proyecto_salida || '-'}</DetailValue>
                      </DetailItem>

                      <DetailItem>
                        <DetailLabel variant="body2">Etapa/Subetapa:</DetailLabel>
                        <DetailValue variant="body1">
                          {selectedPaymentDetail.etapa_de_obra_salidas || '-'} / {selectedPaymentDetail.subetapa_de_obra_salidas || '-'}
                        </DetailValue>
                      </DetailItem>
                    </Paper>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Paper sx={{ p: 3, borderRadius: 2, height: '100%', boxShadow: '0 4px 12px rgba(0,0,0,0.05)' }}>
                      <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold', color: 'primary.main', borderBottom: '2px solid', borderColor: 'primary.light', pb: 1 }}>
                        Montos y Valores
                      </Typography>

                      <DetailItem>
                        <DetailLabel variant="body2">Moneda:</DetailLabel>
                        <DetailValue variant="body1" sx={{ fontWeight: 'bold' }}>
                          {selectedPaymentDetail.moneda_salida || '-'}
                        </DetailValue>
                      </DetailItem>

                      <DetailItem>
                        <DetailLabel variant="body2">Monto:</DetailLabel>
                        <DetailValue variant="body1" sx={{ fontWeight: 'bold' }}>
                          {selectedPaymentDetail.monto_salida
                            ? selectedPaymentDetail.monto_salida.toLocaleString('es-AR')
                            : '-'}
                        </DetailValue>
                      </DetailItem>

                      <DetailItem>
                        <DetailLabel variant="body2">Monto ARS:</DetailLabel>
                        <DetailValue variant="body1">
                          {selectedPaymentDetail.monto_en_ars_salida
                            ? selectedPaymentDetail.monto_en_ars_salida.toLocaleString('es-AR') + ' ARS'
                            : '-'}
                        </DetailValue>
                      </DetailItem>

                      <DetailItem>
                        <DetailLabel variant="body2">Monto USD:</DetailLabel>
                        <DetailValue variant="body1">
                          {selectedPaymentDetail.monto_en_usd_salida
                            ? selectedPaymentDetail.monto_en_usd_salida.toLocaleString('es-AR') + ' USD'
                            : '-'}
                        </DetailValue>
                      </DetailItem>

                      <DetailItem>
                        <DetailLabel variant="body2">Tipo de Cambio:</DetailLabel>
                        <DetailValue variant="body1">
                          {selectedPaymentDetail.tipo_de_cambio_salida
                            ? selectedPaymentDetail.tipo_de_cambio_salida.toLocaleString('es-AR')
                            : '-'}
                        </DetailValue>
                      </DetailItem>

                      {selectedPaymentDetail.tiene_indexacion && (
                        <>
                          <DetailItem>
                            <DetailLabel variant="body2">Tipo de Indexación:</DetailLabel>
                            <DetailValue variant="body1">
                              {selectedPaymentDetail.tipo_indexacion || '-'}
                            </DetailValue>
                          </DetailItem>
                          <DetailItem>
                            <DetailLabel variant="body2">TC Indexado:</DetailLabel>
                            <DetailValue variant="body1">
                              {selectedPaymentDetail.tc_indexado
                                ? selectedPaymentDetail.tc_indexado.toLocaleString('es-AR')
                                : '-'}
                            </DetailValue>
                          </DetailItem>
                          <DetailItem>
                            <DetailLabel variant="body2">Monto Indexado:</DetailLabel>
                            <DetailValue variant="body1">
                              {selectedPaymentDetail.monto_indexado
                                ? selectedPaymentDetail.monto_indexado.toLocaleString('es-AR')
                                : '-'}
                            </DetailValue>
                          </DetailItem>
                        </>
                      )}
                    </Paper>
                  </Grid>

                  {/* Información adicional */}
                  <Grid item xs={12}>
                    <Paper sx={{ p: 3, borderRadius: 2, boxShadow: '0 4px 12px rgba(0,0,0,0.05)' }}>
                      <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold', color: 'primary.main', borderBottom: '2px solid', borderColor: 'primary.light', pb: 1 }}>
                        Comprobantes
                      </Typography>

                      <Grid container spacing={2}>
                        {/* Comprobante */}
                        <Grid item xs={12} sm={6}>
                          <Box sx={{ p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 2 }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Comprobante de pago
                            </Typography>
                            {selectedPaymentDetail.tiene_comprobante_salida ? (
                              <Button
                                variant="outlined"
                                startIcon={<AssignmentIcon />}
                                onClick={() => window.open(selectedPaymentDetail.imagen_comprobante_salida, '_blank')}
                                size="small"
                                sx={{ mt: 1 }}
                              >
                                Ver comprobante
                              </Button>
                            ) : (
                              <Typography variant="body2" color="text.secondary">
                                No hay comprobante disponible
                              </Typography>
                            )}
                            {selectedPaymentDetail.codigo_de_comprobante_salida && (
                              <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                                Código: {selectedPaymentDetail.codigo_de_comprobante_salida}
                              </Typography>
                            )}
                          </Box>
                        </Grid>

                        {/* Factura */}
                        <Grid item xs={12} sm={6}>
                          <Box sx={{ p: 2, border: '1px solid', borderColor: 'divider', borderRadius: 2 }}>
                            <Typography variant="subtitle2" gutterBottom>
                              Factura
                            </Typography>
                            {selectedPaymentDetail.tiene_factura_salida ? (
                              <>
                                <Button
                                  variant="outlined"
                                  startIcon={<AssignmentIcon />}
                                  onClick={() => window.open(selectedPaymentDetail.imagen_factura_salida, '_blank')}
                                  size="small"
                                  sx={{ mt: 1 }}
                                >
                                  Ver factura
                                </Button>
                                <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                                  A nombre de: {selectedPaymentDetail.factura_a_nombre_de_salida || '-'}
                                </Typography>
                              </>
                            ) : (
                              <Typography variant="body2" color="text.secondary">
                                No hay factura disponible
                              </Typography>
                            )}
                            {selectedPaymentDetail.codigo_de_factura_salida && (
                              <Typography variant="caption" display="block" sx={{ mt: 1 }}>
                                Código: {selectedPaymentDetail.codigo_de_factura_salida}
                              </Typography>
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    </Paper>
                  </Grid>

                  {/* Descripción si existe */}
                  {selectedPaymentDetail.descripcion_salida && (
                    <Grid item xs={12}>
                      <Paper sx={{ p: 3, borderRadius: 2, boxShadow: '0 4px 12px rgba(0,0,0,0.05)' }}>
                        <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: 'bold', color: 'primary.main', borderBottom: '2px solid', borderColor: 'primary.light', pb: 1 }}>
                          Descripción
                        </Typography>
                        <Typography variant="body2">
                          {selectedPaymentDetail.descripcion_salida}
                        </Typography>
                      </Paper>
                    </Grid>
                  )}
                </Grid>

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                  <Button
                    variant="contained"
                    onClick={() => setPaymentDetailOpen(false)}
                    startIcon={<CloseIcon />}
                  >
                    Cerrar
                  </Button>
                </Box>
              </>
            )}
          </ModalContent>
        </Fade>
      </Modal>

      {/* Snackbar for alerts */}
      <Snackbar
        open={snackbarInfo.open}
        autoHideDuration={4000}
        onClose={() => setSnackbarInfo(prev => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbarInfo(prev => ({ ...prev, open: false }))}
          severity={snackbarInfo.severity}
          sx={{ width: '100%', borderRadius: 2 }}
        >
          {snackbarInfo.message}
        </Alert>
      </Snackbar>

      {/* Add Budget Dialog */}
      <MobileDialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
              Nuevo Presupuesto
            </Typography>
            <IconButton onClick={() => setOpenDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                required
                fullWidth
                label="Código de Proyecto"
                name="codigo_de_proyecto"
                value={formData.codigo_de_proyecto}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ mb: 2 }}
              >
                {proyectos.map((proyecto) => (
                  <MenuItem key={proyecto.codigo_de_proyecto} value={proyecto.codigo_de_proyecto}>
                    {proyecto.codigo_de_proyecto}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              {/* Autocomplete para proveedor con búsqueda */}
              <Autocomplete
                options={proveedorSuggestions}
                getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
                value={proveedorSuggestions.find(p => p.id === formData.proveedor_id) || null}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setFormData(prev => ({
                      ...prev,
                      proveedor_id: newValue.id
                    }));
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  handleProveedorSearch(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Proveedor"
                    required
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                )}
                filterOptions={(options, state) => options}
                freeSolo
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                required
                fullWidth
                label="Subetapa de Obra"
                name="subetapa_de_obra"
                value={formData.subetapa_de_obra}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ mb: 2 }}
              >
                {subetapas.map((subetapa) => (
                  <MenuItem key={subetapa.subetapa_de_obra} value={subetapa.subetapa_de_obra}>
                    {subetapa.subetapa_de_obra}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Monto"
                name="monto"
                type="number"
                value={formData.monto}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                options={['ARS', 'USD', 'DOLAR_OFICIAL_VENTA', ...customCurrencies.map(c => c.name)]}
                value={formData.moneda}
                onChange={(event, newValue) => {
                  setFormData({ ...formData, moneda: newValue || 'ARS' });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Moneda"
                    name="moneda"
                    required
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción (Opcional)"
                name="descripcion"
                multiline
                rows={3}
                value={formData.descripcion}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Comprobante del presupuesto (Opcional)
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept="image/*,.pdf"
                  onChange={handleFileChange}
                />
                <FileUploadButton htmlFor="file-upload" onClick={() => fileInputRef.current.click()}>
                  <FileUploadIcon sx={{ mr: 1 }} />
                  Subir Archivo
                </FileUploadButton>
                {filePreview && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2">
                      Archivo seleccionado
                    </Typography>
                    {filePreview.startsWith('data:image') ? (
                      <Box
                        component="img"
                        src={filePreview}
                        alt="Vista previa"
                        sx={{ height: 80, mt: 1, borderRadius: 1 }}
                      />
                    ) : (
                      <AssignmentIcon color="primary" sx={{ fontSize: 40, mt: 1 }} />
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={handleSubmitPresupuesto}
            variant="contained"
            disabled={loading}
            fullWidth
            sx={{ borderRadius: 8, py: 1.5 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Guardar Presupuesto'}
          </Button>
        </DialogActions>
      </MobileDialog>

      {/* Edit Budget Dialog (similar structure) */}
      <MobileDialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
              Editar Presupuesto
            </Typography>
            <IconButton onClick={() => setOpenEditDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          {/* Same form fields as Add Budget Dialog */}
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                select
                required
                fullWidth
                label="Código de Proyecto"
                name="codigo_de_proyecto"
                value={formData.codigo_de_proyecto}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ mb: 2 }}
              >
                {proyectos.map((proyecto) => (
                  <MenuItem key={proyecto.codigo_de_proyecto} value={proyecto.codigo_de_proyecto}>
                    {proyecto.codigo_de_proyecto}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              {/* Autocomplete para proveedor con búsqueda */}
              <Autocomplete
                options={proveedorSuggestions}
                getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
                value={proveedorSuggestions.find(p => p.id === formData.proveedor_id) || null}
                onChange={(event, newValue) => {
                  if (newValue) {
                    setFormData(prev => ({
                      ...prev,
                      proveedor_id: newValue.id
                    }));
                  }
                }}
                onInputChange={(event, newInputValue) => {
                  handleProveedorSearch(newInputValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Proveedor"
                    required
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                )}
                filterOptions={(options, state) => options}
                freeSolo
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                required
                fullWidth
                label="Subetapa de Obra"
                name="subetapa_de_obra"
                value={formData.subetapa_de_obra}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ mb: 2 }}
              >
                {subetapas.map((subetapa) => (
                  <MenuItem key={subetapa.subetapa_de_obra} value={subetapa.subetapa_de_obra}>
                    {subetapa.subetapa_de_obra}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Monto"
                name="monto"
                type="number"
                value={formData.monto}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                options={['ARS', 'USD', 'DOLAR_OFICIAL_VENTA', ...customCurrencies.map(c => c.name)]}
                value={formData.moneda}
                onChange={(event, newValue) => {
                  setFormData({ ...formData, moneda: newValue || 'ARS' });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Moneda"
                    name="moneda"
                    required
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Descripción (Opcional)"
                name="descripcion"
                multiline
                rows={3}
                value={formData.descripcion}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Comprobante del presupuesto
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  accept="image/*,.pdf"
                  onChange={handleFileChange}
                />
                <FileUploadButton htmlFor="file-upload" onClick={() => fileInputRef.current.click()}>
                  <FileUploadIcon sx={{ mr: 1 }} />
                  {formData.tiene_comprobante ? 'Cambiar Archivo' : 'Subir Archivo'}
                </FileUploadButton>
                {filePreview && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2">
                      {formData.tiene_comprobante ? 'Comprobante actual' : 'Archivo seleccionado'}
                    </Typography>
                    {filePreview.startsWith('data:image') || filePreview.startsWith('http') ? (
                      <Box
                        component="img"
                        src={filePreview}
                        alt="Vista previa"
                        sx={{ height: 80, mt: 1, borderRadius: 1 }}
                      />
                    ) : (
                      <AssignmentIcon color="primary" sx={{ fontSize: 40, mt: 1 }} />
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={handleEditPresupuesto}
            variant="contained"
            disabled={loading}
            fullWidth
            sx={{ borderRadius: 8, py: 1.5 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Actualizar Presupuesto'}
          </Button>
        </DialogActions>
      </MobileDialog>

      {/* Delete Budget Confirmation Dialog */}
      <MobileDialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
              Eliminar Presupuesto
            </Typography>
            <IconButton onClick={() => setOpenDeleteDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ pt: 2 }}>
          <Typography variant="body1">
            ¿Estás seguro de que deseas eliminar este presupuesto? Esta acción no se puede deshacer y eliminará todos los adicionales asociados.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setOpenDeleteDialog(false)}
            variant="outlined"
            sx={{ borderRadius: 8 }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeletePresupuesto}
            variant="contained"
            color="error"
            disabled={loading}
            sx={{ borderRadius: 8 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Eliminar Presupuesto'}
          </Button>
        </DialogActions>
      </MobileDialog>

      {/* Add Additional Dialog */}
      <MobileDialog
        open={openAdditionalDialog}
        onClose={() => setOpenAdditionalDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
              Agregar Adicional o Aumento
            </Typography>
            <IconButton onClick={() => setOpenAdditionalDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Monto"
                name="monto"
                type="number"
                value={additionalFormData.monto}
                onChange={handleAdditionalInputChange}
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                options={['ARS', 'USD', 'DOLAR_OFICIAL_VENTA', ...customCurrencies.map(c => c.name)]}
                value={additionalFormData.moneda}
                onChange={(event, newValue) => {
                  setAdditionalFormData({ ...additionalFormData, moneda: newValue || 'ARS' });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Moneda"
                    name="moneda"
                    required
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Descripción del Adicional"
                name="descripcion"
                multiline
                rows={3}
                value={additionalFormData.descripcion}
                onChange={handleAdditionalInputChange}
                placeholder="Explica a qué corresponde este adicional o aumento"
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Comprobante del adicional (Opcional)
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
                <input
                  type="file"
                  ref={additionalFileInputRef}
                  style={{ display: 'none' }}
                  accept="image/*,.pdf"
                  onChange={handleAdditionalFileChange}
                />
                <FileUploadButton htmlFor="file-upload" onClick={() => additionalFileInputRef.current.click()}>
                  <FileUploadIcon sx={{ mr: 1 }} />
                  Subir Archivo
                </FileUploadButton>
                {additionalFilePreview && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2">
                      Archivo seleccionado
                    </Typography>
                    {additionalFilePreview.startsWith('data:image') ? (
                      <Box
                        component="img"
                        src={additionalFilePreview}
                        alt="Vista previa"
                        sx={{ height: 80, mt: 1, borderRadius: 1 }}
                      />
                    ) : (
                      <AssignmentIcon color="primary" sx={{ fontSize: 40, mt: 1 }} />
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={handleSubmitAdicional}
            variant="contained"
            disabled={loading}
            fullWidth
            sx={{ borderRadius: 8, py: 1.5 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Guardar Adicional'}
          </Button>
        </DialogActions>
      </MobileDialog>

      {/* Edit Additional Dialog */}
      <MobileDialog
        open={openEditAdditionalDialog}
        onClose={() => setOpenEditAdditionalDialog(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
              Editar Adicional
            </Typography>
            <IconButton onClick={() => setOpenEditAdditionalDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                required
                fullWidth
                label="Monto"
                name="monto"
                type="number"
                value={additionalFormData.monto}
                onChange={handleAdditionalInputChange}
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                freeSolo
                options={['ARS', 'USD', 'DOLAR_OFICIAL_VENTA', ...customCurrencies.map(c => c.name)]}
                value={additionalFormData.moneda}
                onChange={(event, newValue) => {
                  setAdditionalFormData({ ...additionalFormData, moneda: newValue || 'ARS' });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Moneda"
                    name="moneda"
                    required
                    fullWidth
                    variant="outlined"
                    sx={{ mb: 2 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                fullWidth
                label="Descripción del Adicional"
                name="descripcion"
                multiline
                rows={3}
                value={additionalFormData.descripcion}
                onChange={handleAdditionalInputChange}
                variant="outlined"
                sx={{ mb: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2" gutterBottom>
                Comprobante del adicional
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, flexWrap: 'wrap' }}>
                <input
                  type="file"
                  ref={additionalFileInputRef}
                  style={{ display: 'none' }}
                  accept="image/*,.pdf"
                  onChange={handleAdditionalFileChange}
                />
                <FileUploadButton htmlFor="file-upload" onClick={() => additionalFileInputRef.current.click()}>
                  <FileUploadIcon sx={{ mr: 1 }} />
                  {additionalFormData.tiene_comprobante ? 'Cambiar Archivo' : 'Subir Archivo'}
                </FileUploadButton>
                {additionalFilePreview && (
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body2">
                      {additionalFormData.tiene_comprobante ? 'Comprobante actual' : 'Archivo seleccionado'}
                    </Typography>
                    {additionalFilePreview.startsWith('data:image') || additionalFilePreview.startsWith('http') ? (
                      <Box
                        component="img"
                        src={additionalFilePreview}
                        alt="Vista previa"
                        sx={{ height: 80, mt: 1, borderRadius: 1 }}
                      />
                    ) : (
                      <AssignmentIcon color="primary" sx={{ fontSize: 40, mt: 1 }} />
                    )}
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={handleEditAdicional}
            variant="contained"
            disabled={loading}
            fullWidth
            sx={{ borderRadius: 8, py: 1.5 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Actualizar Adicional'}
          </Button>
        </DialogActions>
      </MobileDialog>

      {/* Delete Additional Confirmation Dialog */}
      <MobileDialog
        open={openDeleteAdditionalDialog}
        onClose={() => setOpenDeleteAdditionalDialog(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
              Eliminar Adicional
            </Typography>
            <IconButton onClick={() => setOpenDeleteAdditionalDialog(false)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ pt: 2 }}>
          <Typography variant="body1">
            ¿Estás seguro de que deseas eliminar este adicional? Esta acción no se puede deshacer.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            onClick={() => setOpenDeleteAdditionalDialog(false)}
            variant="outlined"
            sx={{ borderRadius: 8 }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleDeleteAdicional}
            variant="contained"
            color="error"
            disabled={loading}
            sx={{ borderRadius: 8 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Eliminar Adicional'}
          </Button>
        </DialogActions>
      </MobileDialog>
    </Box>
  );
};

export default PresupuestosMobile;