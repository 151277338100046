import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Box, CircularProgress, Typography, Button, Alert, useMediaQuery } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';

// Autenticación
import { AuthProvider, useAuth } from './contexts/AuthContext';
import ProtectedRoute from './components/auth/ProtectedRoute';
import Login from './pages/auth/Login';
import LoginMobile from './pages/auth/LoginMobile';
import AccessDenied from './pages/auth/AccessDenied';
import ClientDashboard from './pages/client/ClientDashboard';
import TestAuth from './TestAuth';
import InviteUsers from './pages/admin/InviteUsers';
import TestClientSignup from './pages/admin/TestClientSignup';

// Componentes existentes
import Sidebar from './components/Sidebar';
import Dashboard from './components/Dashboard';
import Entradas from './components/Entradas';
import EntradasMobile from './components/EntradasMobile';
import Salidas from './components/Salidas';
import SalidasMobile from './components/SalidasMobile';
import CurrencyChanges from './components/CurrencyChanges';
import CurrencyChangesMobile from './components/CurrencyChangesMobile';
import AdminProjects from './components/AdminProjects';
import Conceptos from './components/Conceptos';
import Etapas from './components/Etapas';
import Proveedores from './components/Proveedores';
import Arqueos from './components/Arqueos';
import CobroTrabajadores from './components/CobroTrabajadores';
import MobileCobroTrabajadores from './components/MobileCobroTrabajadores';
import Trabajadores from './components/Trabajadores';
import CajaEmpresa from './components/CajaEmpresa';
import CACManagement from './components/CACManagement';
import NewDashboard from './components/NewDashboard';
import DashboardTrabajadores from './components/DashboardTrabajadores';
import { supabase } from './supabaseClient';

// Componentes de buscadores
import BuscadorComprobantes from './components/BuscadorComprobantes';
import BuscadorFacturas from './components/BuscadorFacturas';
import BuscadorUnificado from './components/BuscadorUnificado';
import BuscadorUnificadoMobile from './components/BuscadorUnificadoMobile';

// Navegación móvil
import MobileNavigation from './components/MobileNavigation';

// Nuevos componentes mobile mejorados
import MobileHeader from './components/MobileHeader';
import MobileSidebar from './components/MobileSidebar';
import DashboardMobile from './components/DashboardMobile';

// Presupuestos
import Presupuestos from './components/Presupuestos';
import PresupuestosMobile from './components/PresupuestosMobile';

// Tema personalizado con soporte para responsive
const theme = createTheme({
  palette: {
    primary: {
      main: '#2e7d32',
      light: '#4caf50',
      dark: '#1b5e20',
    },
    background: {
      default: '#F5F5F5',
      paper: '#ffffff',
    },
    text: {
      primary: '#424242',
      secondary: '#757575',
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    button: { textTransform: 'none' },
    h6: { fontWeight: 500, fontSize: '1.15rem', lineHeight: 1.3 },
    body1: { fontSize: '1rem' },
    body2: { fontSize: '0.875rem' },
  },
  shape: { borderRadius: 12 },
  transitions: {
    easing: { sharp: 'cubic-bezier(0.4, 0, 0.6, 1)', easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)', easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)' },
    duration: { shortest: 150, shorter: 200, short: 250, standard: 300, complex: 375, enteringScreen: 225, leavingScreen: 195 },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&:hover': { boxShadow: '0 2px 10px rgba(0,0,0,0.1)' },
          '@media (max-width:600px)': { padding: '8px 16px', minHeight: '42px' },
        },
        containedPrimary: { background: 'linear-gradient(145deg, #2e7d32, #388e3c)' },
      },
    },
    MuiCard: { styleOverrides: { root: { borderRadius: 16, boxShadow: '0 4px 20px rgba(0,0,0,0.05)' } } },
    MuiPaper: { styleOverrides: { rounded: { borderRadius: 16 } } },
    MuiBottomNavigation: { styleOverrides: { root: { height: 56 } } },
    MuiTextField: {
      styleOverrides: {
        root: {
          '@media (max-width:600px)': {
            '& .MuiInputBase-root': { fontSize: '1rem', minHeight: '48px' },
            '& .MuiInputLabel-root': { fontSize: '1rem' },
          },
        },
      },
    },
  },
});

// Componente de pantalla de carga genérica para reutilizar
const LoadingScreen = ({ message = "Cargando...", subtitle = "Verificando sesión", onRetry = null }) => (
  <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: 'calc(var(--vh) * 100)', backgroundColor: '#f5f5f5' }}>
    <motion.div initial={{ scale: 0.8, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ duration: 0.5 }}>
      <CircularProgress sx={{ mb: 3, color: '#2e7d32' }} />
    </motion.div>
    <motion.div initial={{ y: 20, opacity: 0 }} animate={{ y: 0, opacity: 1 }} transition={{ delay: 0.3, duration: 0.5 }}>
      <Typography variant="h6" sx={{ mb: 1 }}>{message}</Typography>
      <Typography variant="body2" color="text.secondary">{subtitle}</Typography>

      {onRetry && (
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ delay: 5, duration: 0.3 }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={onRetry}
            sx={{
              mt: 3,
              borderRadius: 28,
              minWidth: 120
            }}
          >
            Reintentar
          </Button>
        </motion.div>
      )}
    </motion.div>
  </Box>
);

const AppContent = () => {
  const { loading, user, isClient, userRole, userDetails, authError, verifyingPermissions } = useAuth();
  const [empresaNombre, setEmpresaNombre] = useState('Nokk');
  const [loadingTimeout, setLoadingTimeout] = useState(false);
  const [isPWA, setIsPWA] = useState(false);
  const timeoutRef = useRef(null);
  const [installPrompt, setInstallPrompt] = useState(null);
  const [showInstallBanner, setShowInstallBanner] = useState(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [userTypeVerified, setUserTypeVerified] = useState(false);

  const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
  const [mobileNavVisible, setMobileNavVisible] = useState(true);

  // Detect user type from localStorage as early as possible to avoid flashing
  const savedRole = localStorage.getItem('userRole');
  const isSavedClient = savedRole === 'client';
  const isSavedAdmin = savedRole === 'admin' || savedRole === 'worker';

  const sidebarWidth = 260;

  const toggleMobileSidebar = () => {
    setMobileSidebarOpen(!mobileSidebarOpen);
    setMobileNavVisible(!mobileSidebarOpen ? false : true);
  };

  // Función para reintentar la carga cuando hay problemas
  const handleRetry = () => {
    // Forzar recarga de la página para reiniciar el proceso de auth
    window.location.reload();
  };

  useEffect(() => {
    let metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (!metaThemeColor) {
      metaThemeColor = document.createElement('meta');
      metaThemeColor.setAttribute('name', 'theme-color');
      document.head.appendChild(metaThemeColor);
    }
    metaThemeColor.setAttribute('content', '#f5f5f5');
  }, []);

  useEffect(() => {
    const isRunningAsPWA = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
    setIsPWA(isRunningAsPWA);

    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setInstallPrompt(e);
      if (!isRunningAsPWA && !localStorage.getItem('installBannerDismissed')) {
        setShowInstallBanner(true);
      }
    };

    const handleAppInstalled = () => {
      setIsPWA(true);
      setShowInstallBanner(false);
      console.log('Aplicación instalada como PWA');
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    window.addEventListener('appinstalled', handleAppInstalled);

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  // Verificar cuando el usuario está completamente cargado
  useEffect(() => {
    if (!loading && user) {
      const effectiveIsClient = isClient || isSavedClient;
      const effectiveIsAdmin = userRole === 'admin' || userRole === 'worker' || isSavedAdmin;

      // Solo marcar como verificado si tenemos confirmación de algún tipo
      if (effectiveIsClient || effectiveIsAdmin) {
        setUserTypeVerified(true);
      }
    }
  }, [loading, user, isClient, userRole, isSavedClient, isSavedAdmin]);

  const handleInstallClick = () => {
    if (!installPrompt) return;
    installPrompt.prompt();
    installPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') console.log('Usuario aceptó instalar la PWA');
      else localStorage.setItem('installBannerDismissed', 'true');
      setInstallPrompt(null);
      setShowInstallBanner(false);
    });
  };

  const dismissInstallBanner = () => {
    setShowInstallBanner(false);
    localStorage.setItem('installBannerDismissed', 'true');
  };

  useEffect(() => {
    if (loading || verifyingPermissions) {
      // Reducir el tiempo de espera a 3 segundos para mejor experiencia de usuario
      timeoutRef.current = setTimeout(() => {
        console.log('Tiempo de carga excedido');
        setLoadingTimeout(true);
      }, 3000); // Reducido de 5000ms a 3000ms
    } else clearTimeout(timeoutRef.current);
    return () => clearTimeout(timeoutRef.current);
  }, [loading, verifyingPermissions]);

  useEffect(() => {
    const fetchEmpresaNombre = async () => {
      const { data, error } = await supabase.from('empresa').select('nombre_de_empresa').limit(1);
      if (error) console.error('Error fetching empresa nombre:', error);
      else if (data && data.length > 0 && data[0].nombre_de_empresa) setEmpresaNombre(data[0].nombre_de_empresa);
    };
    fetchEmpresaNombre();
  }, []);

  const updateEmpresaNombre = (newName) => setEmpresaNombre(newName);

  // Si estamos cargando pero ya sabemos que es cliente, mostrar pantalla de carga específica
  if (loading && isSavedClient) {
    return <LoadingScreen message="Cargando..." subtitle="Preparando vista de cliente" />;
  }

  // Si estamos verificando permisos, mostrar pantalla específica con botón de reintentar
  if (verifyingPermissions) {
    return <LoadingScreen
      message="Verificando permisos..."
      subtitle={`Usuario: ${user?.email || 'Cargando...'}`}
      onRetry={handleRetry}
    />;
  }

  // Si estamos cargando normalmente, mostrar pantalla de carga genérica
  if (loading && !loadingTimeout) {
    return <LoadingScreen subtitle={user ? `Usuario: ${user.email}` : 'Verificando sesión'} />;
  }

  if (!user) {
    return (
      <Routes>
        <Route path="/login" element={isMobile ? <LoginMobile /> : <Login />} />
        <Route path="/admin/test-client" element={<TestClientSignup />} />
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    );
  }

  // Determinar si es cliente de la forma más segura posible
  const isClientUser = isClient || isSavedClient;

  // Mostrar pantalla de error por timeout
  if ((loadingTimeout && !userRole && !savedRole) && user) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 'calc(var(--vh) * 100)', flexDirection: 'column', gap: 2, padding: 3 }}>
        <Typography variant="h5" gutterBottom>Estamos teniendo problemas para cargar tu perfil</Typography>
        {authError && <Alert severity="error" sx={{ mb: 2, width: '100%', maxWidth: 500 }}>{authError}</Alert>}
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>Usuario: {user.email}</Typography>
        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', justifyContent: 'center' }}>
          <Button variant="contained" color="primary" onClick={() => window.location.reload()} sx={{ borderRadius: 28 }}>Intentar nuevamente</Button>
          <Button variant="outlined" color="primary" onClick={() => {
            if (user.email.includes('@gmail.com') || user.email.includes('@hotmail.com') || user.email.includes('@yahoo.com')) {
              localStorage.setItem('userRole', 'client');
              window.location.reload();
            } else alert('No se pudo recuperar automáticamente. Contacte al soporte técnico.');
          }} sx={{ borderRadius: 28 }}>Recuperar como cliente</Button>
          <Button variant="text" color="error" onClick={() => {
            localStorage.removeItem('userRole');
            supabase.auth.signOut().then(() => window.location.href = '/login');
          }} sx={{ borderRadius: 28 }}>Cerrar sesión</Button>
        </Box>
      </Box>
    );
  }

  // Si aún no estamos seguros del tipo de usuario, mostrar una pantalla de carga
  if (!userTypeVerified && !isSavedClient && !isSavedAdmin) {
    return <LoadingScreen
      message="Verificando permisos..."
      subtitle={`Usuario: ${user.email}`}
      onRetry={handleRetry}
    />;
  }

  let mainContent;
  if (isClientUser) {
    console.log("Usuario detectado como cliente, redirigiendo a vista de cliente");
    mainContent = (
      <>
        <MobileHeader
          toggleSidebar={toggleMobileSidebar}
          empresaNombre={empresaNombre}
          isClient={true}
        />
        <MobileSidebar
          open={mobileSidebarOpen}
          onClose={() => setMobileSidebarOpen(false)}
          empresaNombre={empresaNombre}
          setMobileNavVisible={setMobileNavVisible}
        />
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          {/* Usar Sidebar normal para clientes también */}
          <Sidebar empresaNombre={empresaNombre} />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: { xs: 2, sm: 3 },
            mt: { xs: '64px', sm: 0 },
            ml: { xs: 0, sm: `${sidebarWidth}px` },
            height: 'calc(var(--vh) * 100)',
            overflowY: 'auto',
            pb: { xs: '72px', sm: 0 },
            boxSizing: 'border-box',
            backgroundColor: '#f8f9fa',
          }}
        >
          <Routes>
            <Route path="/dashboard" element={
              <NewDashboard
                isClientView={true}
                forceClientView={true}
                selectedProject={userDetails?.codigo_de_proyecto || ""}
              />
            } />
            <Route path="/presupuestos" element={isMobile || isPWA ? <PresupuestosMobile clientFiltered={true} /> : <Presupuestos clientFiltered={true} />} />
            <Route path="/entradas" element={isMobile || isPWA ? <EntradasMobile clientFiltered={true} /> : <Entradas clientFiltered={true} />} />
            <Route path="/salidas" element={isMobile || isPWA ? <SalidasMobile clientFiltered={true} /> : <Salidas clientFiltered={true} />} />
            <Route path="/cambios-de-moneda" element={isMobile || isPWA ? <CurrencyChangesMobile clientFiltered={true} /> : <CurrencyChanges clientFiltered={true} />} />
            <Route path="/buscador-unificado" element={isMobile || isPWA ? <BuscadorUnificadoMobile clientFiltered={true} /> : <BuscadorUnificado clientFiltered={true} />} />
            <Route path="/access-denied" element={<AccessDenied />} />
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </Box>
      </>
    );
  } else if (userRole === 'admin' || userRole === 'worker' || isSavedAdmin) {
    console.log("Mostrando interfaz de administrador/trabajador");
    mainContent = (
      <>
        <MobileHeader
          toggleSidebar={toggleMobileSidebar}
          empresaNombre={empresaNombre}
          isClient={false}
        />
        <MobileSidebar
          open={mobileSidebarOpen}
          onClose={() => setMobileSidebarOpen(false)}
          empresaNombre={empresaNombre}
          setMobileNavVisible={setMobileNavVisible}
        />
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Sidebar empresaNombre={empresaNombre} />
        </Box>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: { xs: 2, sm: 3 },
            mt: { xs: '64px', sm: 0 },
            ml: { xs: 0, sm: `${sidebarWidth}px` },
            height: 'calc(var(--vh) * 100)',
            overflowY: 'auto',
            pb: { xs: '72px', sm: 3 },
            boxSizing: 'border-box',
            backgroundColor: '#f5f5f5',
          }}
        >
          <TestAuth />
          <Routes>
            <Route path="/dashboard" element={isMobile || isPWA ? <DashboardMobile /> : <Dashboard />} />
            <Route path="/dashboard-obras" element={<NewDashboard />} />
            <Route path="/entradas" element={isMobile || isPWA ? <EntradasMobile /> : <Entradas />} />
            <Route path="/salidas" element={isMobile || isPWA ? <SalidasMobile /> : <Salidas />} />
            <Route path="/cambios-de-moneda" element={isMobile || isPWA ? <CurrencyChangesMobile /> : <CurrencyChanges />} />
            <Route path="/buscador-comprobantes" element={<BuscadorComprobantes />} />
            <Route path="/buscador-facturas" element={<BuscadorFacturas />} />
            <Route path="/buscador-unificado" element={isMobile || isPWA ? <BuscadorUnificadoMobile /> : <BuscadorUnificado />} />
            <Route path="/presupuestos" element={isMobile || isPWA ? <PresupuestosMobile /> : <Presupuestos />} />
            <Route path="/admin/invite-users" element={<InviteUsers />} />
            <Route path="/admin/cobro-trabajadores" element={isMobile || isPWA ? <MobileCobroTrabajadores /> : <CobroTrabajadores />} />
            <Route path="/admin/arqueos" element={<Arqueos />} />
            <Route path="/admin/proyectos" element={<AdminProjects />} />
            <Route path="/admin/conceptos" element={<Conceptos />} />
            <Route path="/admin/etapas" element={<Etapas />} />
            <Route path="/admin/proveedores" element={<Proveedores />} />
            <Route path="/admin/trabajadores" element={<Trabajadores />} />
            <Route path="/admin/caja-empresa" element={<CajaEmpresa updateEmpresaNombre={updateEmpresaNombre} />} />
            <Route path="/admin/indices-cac" element={<CACManagement />} />
            <Route path="/dashboard-trabajadores" element={<DashboardTrabajadores />} />
            <Route path="/access-denied" element={<AccessDenied />} />
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          </Routes>
        </Box>
      </>
    );
  } else {
    // Si llegamos aquí es porque no podemos determinar el tipo de usuario
    // Mostrar una pantalla de carga neutral en vez de arriesgarnos a mostrar contenido incorrecto
    return <LoadingScreen
      message="Verificando permisos..."
      subtitle="Por favor espere..."
      onRetry={handleRetry}
    />;
  }

  return (
    <>
      {showInstallBanner && !isPWA && (
        <Box
          component={motion.div}
          initial={{ y: -100, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -100, opacity: 0 }}
          sx={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2000,
            backgroundColor: 'white',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            padding: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body1" sx={{ fontWeight: 500 }}>Instala Nokk para una mejor experiencia</Typography>
          </Box>
          <Box>
            <Button variant="contained" color="primary" size="small" onClick={handleInstallClick} sx={{ mr: 1, borderRadius: 20 }}>Instalar</Button>
            <Button variant="text" color="inherit" size="small" onClick={dismissInstallBanner} sx={{ borderRadius: 20 }}>Ahora no</Button>
          </Box>
        </Box>
      )}
      {mainContent}
      <MobileNavigation isVisible={mobileNavVisible} />
    </>
  );
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AuthProvider>
        <Router>
          <AnimatePresence>
            <AppContent />
          </AnimatePresence>
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;