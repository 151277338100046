import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Drawer,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Avatar,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Menu,
  MenuItem,
  Fab,
  Slider
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence, useScroll, useSpring } from 'framer-motion';
import { useAuth } from '../contexts/AuthContext';
import { supabase } from '../supabaseClient';
import Cropper from 'react-easy-crop';

// Iconos
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import InboxIcon from '@mui/icons-material/MoveToInboxOutlined';
import SendIcon from '@mui/icons-material/SendOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import ListAltIcon from '@mui/icons-material/ListAltOutlined';
import TimelineIcon from '@mui/icons-material/TimelineOutlined';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined';
import WorkIcon from '@mui/icons-material/WorkOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import BusinessIcon from '@mui/icons-material/BusinessOutlined';
import ConstructionIcon from '@mui/icons-material/ConstructionOutlined';
import BarChartIcon from '@mui/icons-material/BarChartOutlined';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import LockIcon from '@mui/icons-material/LockOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SavingsIcon from '@mui/icons-material/Savings';
import CopyrightIcon from '@mui/icons-material/Copyright';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonIcon from '@mui/icons-material/Person';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

// Drawer contenedor estilizado
const SidebarDrawer = styled(Drawer)(({ theme }) => ({
  zIndex: 1300,
  '& .MuiDrawer-paper': {
    width: '80%',
    maxWidth: 280,
    borderRadius: '20px',
    margin: '8px 0 45px 16px',
    height: 'calc(100% - 53px)',
    boxShadow: '0 8px 40px rgba(0, 0, 0, 0.15)',
    backgroundColor: 'rgba(255, 255, 255, 0.95)',
    backdropFilter: 'blur(10px)',
    border: 'none',
    padding: 0,
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    touchAction: 'pan-y',
    WebkitOverflowScrolling: 'touch',
  },
  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));

// Close Button
const CloseButton = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  top: 10,
  left: 10,
  zIndex: 1400,
  width: 36,
  height: 36,
  minHeight: 'unset',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)',
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  '&:hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
}));

// UserProfileCard
const UserProfileCard = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(1, 2, 1, 2),
  padding: theme.spacing(1.8, 2),
  borderRadius: 16,
  background: 'rgba(255, 255, 255, 0.9)',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
  border: '1px solid rgba(0, 0, 0, 0.04)',
  display: 'flex',
  alignItems: 'center',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
  },
}));

// StyledAvatar
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  width: 42,
  height: 42,
  background: 'linear-gradient(135deg, #ff4b4b 0%, #ff758e 100%)',
  boxShadow: '0 4px 8px rgba(255, 75, 75, 0.2)',
  margin: theme.spacing(0, 2, 0, 0),
  transition: 'transform 0.3s ease-out',
  '&:hover': {
    transform: 'scale(1.1)',
  },
}));

// CategoryCard
const CategoryCard = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(1, 2, 1, 2),
  padding: theme.spacing(0.5, 0),
  borderRadius: 16,
  background: 'rgba(255, 255, 255, 0.9)',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
  border: '1px solid rgba(0, 0, 0, 0.04)',
  overflow: 'hidden',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
  },
}));

// SearchCard
const SearchCard = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(1, 2, 1, 2),
  padding: theme.spacing(0),
  borderRadius: 16,
  background: 'rgba(255, 255, 255, 0.9)',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
  border: '1px solid rgba(0, 0, 0, 0.04)',
  overflow: 'hidden',
  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
  },
}));

// CategoryTitle
const CategoryTitle = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0.5, 2, 0.5, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

// FooterContainer
const FooterContainer = styled(Box)(({ theme }) => ({
  marginTop: 'auto',
  width: '100%',
  padding: theme.spacing(1, 0),
}));

// FooterText
const FooterText = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.7rem',
  textAlign: 'center',
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  opacity: 0.6,
}));

// ScrollIndicator
const ScrollIndicator = styled(motion.div)(({ theme }) => ({
  position: 'absolute',
  bottom: 0, // Cambiado de top a bottom
  left: 0,
  right: 0,
  height: 4,
  backgroundColor: 'rgba(46, 125, 50, 0.3)',
  borderRadius: '0 0 16px 16px',
  zIndex: 10,
}));

// ScrollContainer
const ScrollContainer = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: 'auto',
  overflowX: 'hidden',
  paddingTop: theme.spacing(0.5),
  paddingRight: 4,
  paddingBottom: theme.spacing(2),
  position: 'relative',
  '&::-webkit-scrollbar': {
    display: 'none',
  },
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  WebkitOverflowScrolling: 'touch',
  touchAction: 'pan-y',
}));

// MainContainer
const MainContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  paddingTop: 0,
}));

// ImageCropper Component
const ImageCropper = ({ image, onCropComplete }) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);

  const onCropChange = (newCrop) => {
    setCrop(newCrop);
  };

  const onZoomChange = (newZoom) => {
    setZoom(newZoom);
  };

  const onRotationChange = (event, newRotation) => {
    setRotation(newRotation);
  };

  const onCropCompleteCallback = useCallback((croppedArea, croppedAreaPixels) => {
    onCropComplete(croppedAreaPixels);
  }, [onCropComplete]);

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        borderRadius: 2,
        background: 'rgba(248, 249, 250, 0.8)',
        mb: 2
      }}
    >
      <Typography variant="subtitle2" sx={{ mb: 1, color: '#455a64' }}>
        Ajusta tu imagen
      </Typography>
      <Box sx={{ position: 'relative', height: 250, mb: 2 }}>
        <Cropper
          image={image}
          crop={crop}
          zoom={zoom}
          rotation={rotation}
          aspect={1}
          cropShape="round"
          showGrid={false}
          onCropChange={onCropChange}
          onCropComplete={onCropCompleteCallback}
          onZoomChange={onZoomChange}
          style={{
            containerStyle: { borderRadius: '8px', backgroundColor: 'rgba(0, 0, 0, 0.05)' },
            cropAreaStyle: { border: '2px solid #2e7d32', color: 'rgba(255, 255, 255, 0.5)' },
            mediaStyle: { backgroundColor: '#000' },
          }}
        />
      </Box>
      <Box sx={{ mb: 2 }}>
        <Typography variant="caption" color="textSecondary" gutterBottom>
          Zoom
        </Typography>
        <Slider
          value={zoom}
          min={1}
          max={3}
          step={0.1}
          aria-labelledby="zoom-slider"
          onChange={(e, zoom) => setZoom(zoom)}
          sx={{ color: '#2e7d32', '& .MuiSlider-thumb': { width: 14, height: 14 } }}
        />
      </Box>
      <Box>
        <Typography variant="caption" color="textSecondary" gutterBottom>
          Rotación
        </Typography>
        <Slider
          value={rotation}
          min={0}
          max={360}
          step={1}
          aria-labelledby="rotation-slider"
          onChange={onRotationChange}
          sx={{ color: '#2e7d32', '& .MuiSlider-thumb': { width: 14, height: 14 } }}
        />
      </Box>
    </Paper>
  );
};

// Función para recortar la imagen
const getCroppedImg = (imageSrc, crop, fileName) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement('canvas');
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = crop.width;
      canvas.height = crop.height;
      const ctx = canvas.getContext('2d');

      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Error al generar blob'));
          return;
        }
        const file = new File([blob], fileName, { type: 'image/jpeg' });
        resolve(file);
      }, 'image/jpeg', 0.95);
    };
    image.onerror = () => reject(new Error('Error al cargar la imagen'));
  });
};

const MobileSidebar = ({ open, onClose, empresaNombre, setMobileNavVisible }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, isClient } = useAuth();

  // Estados para las categorías expandibles
  const [infoOpen, setInfoOpen] = useState(true);
  const [inputOpen, setInputOpen] = useState(true);
  const [adminOpen, setAdminOpen] = useState(true); // Admin abierto por default

  // Estados para el perfil de usuario
  const [anchorEl, setAnchorEl] = useState(null);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [avatarUploadOpen, setAvatarUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState('');
  const [cropSelection, setCropSelection] = useState(null);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);
  const [userPosition, setUserPosition] = useState('Usuario');
  const [avatarUrl, setAvatarUrl] = useState(null);
  const [isClientUser, setIsClientUser] = useState(false);

  // Referencia para el contenedor de scroll
  const scrollRef = useRef(null);
  const { scrollYProgress } = useScroll({ container: scrollRef });
  const scaleX = useSpring(scrollYProgress, { stiffness: 300, damping: 20 });
  const menuOpen = Boolean(anchorEl);

  // Para poner el render importo esto

  const [renderUrl, setRenderUrl] = useState(null);


  // Reset scroll position al abrir la sidebar
  useEffect(() => {
    if (open && scrollRef.current) {
      scrollRef.current.scrollTo({ top: 0, behavior: 'auto' });
    }
  }, [open]);

  // Ocultar/mostrar la bottom navigation
  useEffect(() => {
    if (setMobileNavVisible) {
      if (open) {
        setMobileNavVisible(false);
      } else {
        setMobileNavVisible(true);
      }
    }
  }, [open, setMobileNavVisible]);

  // Verificar si el usuario es cliente o trabajador
  useEffect(() => {
    const fetchUserInfo = async () => {
      if (user && user.email) {
        try {
          const { data: trabajadorData, error: trabajadorError } = await supabase
            .from('trabajadores_empresa')
            .select('puesto_del_trabajador, avatar_url')
            .eq('email_del_trabajador', user.email)
            .single();

          if (trabajadorData) {
            setIsClientUser(false);
            setUserPosition(trabajadorData.puesto_del_trabajador || 'Usuario');
            setAvatarUrl(trabajadorData.avatar_url);
            return;
          }

          // Si no es trabajador, buscar en proyectos (cliente)
          const { data: clienteData, error: clienteError } = await supabase
            .from('proyectos')
            .select('apellido_del_cliente, ubicacion_del_proyecto, render_photo_path')
            .eq('email_del_cliente', user.email)
            .single();

          if (clienteData) {
            setIsClientUser(true);
            setUserPosition(clienteData.ubicacion_del_proyecto || 'Cliente');
            setAvatarUrl(null);
            // Guardar la URL de la imagen de render si existe
            if (clienteData.render_photo_path) {
              setRenderUrl(clienteData.render_photo_path);
            }
          } else {
            setUserPosition('Usuario');
            setAvatarUrl(null);
          }
        } catch (err) {
          console.error('Error en la consulta de usuario:', err);
          setUserPosition('Usuario');
          setAvatarUrl(null);
        }
      }
    };



    fetchUserInfo();
  }, [user]);

  // Funciones auxiliares
  const getInitials = () => {
    if (!user || !user.email) return '?';
    const emailName = user.email.split('@')[0];
    const parts = emailName.split(/[._-]/);
    if (parts.length >= 2) {
      return (parts[0][0].toUpperCase() + parts[1][0].toUpperCase());
    }
    return emailName[0].toUpperCase();
  };

  const getUserName = () => {
    if (!user || !user.email) return 'Usuario';

    // Intentar obtener el apellido del cliente si es un cliente
    if (isClientUser) {
      // Buscar en proyectos por el email
      const fetchClientName = async () => {
        const { data } = await supabase
          .from('proyectos')
          .select('apellido_del_cliente')
          .eq('email_del_cliente', user.email)
          .single();

        if (data && data.apellido_del_cliente) {
          return data.apellido_del_cliente;
        }

        return user.email.split('@')[0].charAt(0).toUpperCase() + user.email.split('@')[0].slice(1);
      };

      // Si tenemos el apellido en caché, usarlo
      if (localStorage.getItem('clientLastName')) {
        return localStorage.getItem('clientLastName');
      }

      // Si no, intentar obtenerlo
      fetchClientName().then(name => {
        if (name) {
          localStorage.setItem('clientLastName', name);
        }
      });
    }

    const emailName = user.email.split('@')[0];
    return emailName.charAt(0).toUpperCase() + emailName.slice(1);
  };

  // Funciones para el perfil
  const handleProfileMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenChangePassword = () => {
    handleMenuClose();
    setChangePasswordOpen(true);
    setPassword('');
    setConfirmPassword('');
    setError('');
    setSuccess('');
  };

  const handleCloseChangePassword = () => {
    setChangePasswordOpen(false);
  };

  const handleOpenAvatarUpload = () => {
    handleMenuClose();
    setAvatarUploadOpen(true);
    setSelectedFile(null);
    setPreviewUrl('');
    setCropSelection(null);
    setError('');
    setSuccess('');
  };

  const handleCloseAvatarUpload = () => {
    setAvatarUploadOpen(false);
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        setPreviewUrl(fileReader.result);
      };
      fileReader.readAsDataURL(file);
      setSelectedFile(file);
    }
  };

  const handleAvatarUpload = async () => {
    if (!selectedFile) {
      setError('Por favor, selecciona una imagen');
      return;
    }

    setError('');
    setLoading(true);

    try {
      const fileExt = selectedFile.name.split('.').pop();
      const fileName = `${user.id}-avatar-${Date.now()}.${fileExt}`;
      const filePath = fileName;

      let fileToUpload = selectedFile;
      if (cropSelection) {
        fileToUpload = await getCroppedImg(previewUrl, cropSelection, fileName);
      }

      const { data: uploadData, error: uploadError } = await supabase.storage
        .from('avatars')
        .upload(filePath, fileToUpload, { upsert: true, cacheControl: '3600' });

      if (uploadError) throw uploadError;

      const { data: urlData } = supabase.storage
        .from('avatars')
        .getPublicUrl(filePath);

      const publicUrl = urlData?.publicUrl;
      if (!publicUrl) throw new Error('No se pudo obtener la URL pública del avatar');

      const { error: updateError } = await supabase
        .from('trabajadores_empresa')
        .update({ avatar_url: publicUrl })
        .eq('email_del_trabajador', user.email);

      if (updateError) throw updateError;

      setAvatarUrl(publicUrl);
      setSuccess('Foto de perfil actualizada correctamente');

      setTimeout(() => {
        handleCloseAvatarUpload();
      }, 2000);
    } catch (error) {
      console.error('Error al subir avatar:', error);
      setError(error.message || 'Error al subir la imagen');
    } finally {
      setLoading(false);
    }
  };

  const handleChangePassword = async () => {
    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }

    if (password.length < 6) {
      setError('La contraseña debe tener al menos 6 caracteres');
      return;
    }

    setError('');
    setLoading(true);

    try {
      const { error } = await supabase.auth.updateUser({ password });
      if (error) throw error;
      setSuccess('Contraseña actualizada correctamente');
      setTimeout(() => {
        handleCloseChangePassword();
      }, 2000);
    } catch (error) {
      console.error('Error al cambiar contraseña:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async () => {
    try {
      if (menuOpen) handleMenuClose();
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      window.location.replace('/login');
    } catch (err) {
      console.error("Error al cerrar sesión:", err);
      alert("Error al cerrar sesión.");
    }
  };

  const handleClose = () => {
    if (setMobileNavVisible) setMobileNavVisible(true);
    onClose();
  };

  const handleNavigate = (path) => {
    navigate(path);
    if (setMobileNavVisible) setMobileNavVisible(true);
    onClose();
  };

  // Variantes de animación
  const listItemVariants = {
    visible: { opacity: 1, x: 0 },
    hover: { x: 8, transition: { type: 'spring', stiffness: 300, damping: 10 } }
  };

  const iconVariants = {
    hover: { scale: 1.2, color: '#2e7d32', transition: { type: 'spring', stiffness: 300, damping: 10 } }
  };

  const categoryVariants = {
    closed: { height: 0, opacity: 0, transition: { duration: 0.3, ease: [0.4, 0, 0.2, 1] } },
    open: { height: 'auto', opacity: 1, transition: { duration: 0.3, ease: [0.4, 0, 0.2, 1] } }
  };

  // Items de menú para administradores
  const infoItems = [
    { text: 'Cajas', icon: <SavingsIcon />, path: '/dashboard', index: 0 },
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard-obras', index: 1 },
    { text: 'Trabajadores', icon: <BarChartIcon />, path: '/dashboard-trabajadores', index: 2 },
    { text: 'Presupuestos', icon: <AssignmentIcon />, path: '/presupuestos', index: 3 },
  ];

  const inputItems = [
    { text: 'Entradas', icon: <InboxIcon />, path: '/entradas', index: 0 },
    { text: 'Salidas', icon: <SendIcon />, path: '/salidas', index: 1 },
    { text: 'Cobro socios', icon: <WorkIcon />, path: '/admin/cobro-trabajadores', index: 2 },
    { text: 'Cambios', icon: <CurrencyExchangeIcon />, path: '/cambios-de-moneda', index: 3 },
    { text: 'Arqueos', icon: <AccountBalanceIcon />, path: '/admin/arqueos', index: 4 },
  ];

  const adminItems = [
    { text: 'Proyectos', icon: <AssignmentIcon />, path: '/admin/proyectos', index: 0 },
    { text: 'Conceptos', icon: <ListAltIcon />, path: '/admin/conceptos', index: 1 },
    { text: 'Etapas', icon: <TimelineIcon />, path: '/admin/etapas', index: 2 },
    { text: 'Proveedores', icon: <GroupIcon />, path: '/admin/proveedores', index: 3 },
    { text: 'Trabajadores', icon: <PeopleIcon />, path: '/admin/trabajadores', index: 4 },
    { text: 'Caja Empresa', icon: <BusinessIcon />, path: '/admin/caja-empresa', index: 5 },
    { text: 'Índices CAC', icon: <ConstructionIcon />, path: '/admin/indices-cac', index: 6 },
  ];

  // Items de menú para clientes
  const clientMenuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard', index: 0 },
    { text: 'Presupuestos', icon: <AssignmentIcon />, path: '/presupuestos', index: 1 },
    { text: 'Entradas', icon: <InboxIcon />, path: '/entradas', index: 2 },
    { text: 'Salidas', icon: <SendIcon />, path: '/salidas', index: 3 },
    { text: 'Cambios', icon: <CurrencyExchangeIcon />, path: '/cambios-de-moneda', index: 4 },
    { text: 'Buscador', icon: <SearchIcon />, path: '/buscador-unificado', index: 5 },
  ];

  return (
    <>
      {open && (
        <CloseButton
          color="default"
          size="small"
          onClick={handleClose}
          component={motion.button}
          whileTap={{ scale: 0.95 }}
          whileHover={{ scale: 1.05 }}
        >
          <KeyboardArrowLeftIcon fontSize="small" />
        </CloseButton>
      )}

      <SidebarDrawer
        anchor="left"
        open={open}
        onClose={handleClose}
        variant="temporary"
        ModalProps={{ keepMounted: true }}
      >
        <MainContainer>
          <AnimatePresence>
            {user && (
              <motion.div initial={{ opacity: 0, y: -20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.4 }}>
                <UserProfileCard elevation={0}>
                  <motion.div whileHover={{ scale: 1.1 }} transition={{ type: 'spring', stiffness: 300 }}>
                    {avatarUrl && !isClientUser ? (
                      <StyledAvatar src={avatarUrl} alt={getUserName()} />
                    ) : (
                      <StyledAvatar>{getInitials()}</StyledAvatar>
                    )}
                  </motion.div>
                  <Box sx={{ flex: 1 }}>
                    <Typography variant="body1" sx={{ fontWeight: 600, fontSize: '1rem', color: '#303030', lineHeight: 1.2 }}>
                      {getUserName()}
                    </Typography>
                    <Typography variant="caption" sx={{ color: '#909090', fontSize: '0.8rem' }}>
                      {userPosition}
                    </Typography>
                  </Box>
                  <IconButton onClick={handleProfileMenuClick} size="small" sx={{ color: '#606060' }}>
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                </UserProfileCard>
              </motion.div>
            )}
          </AnimatePresence>

          <Menu
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={handleMenuClose}
            PaperProps={{
              elevation: 4,
              sx: {
                minWidth: 180,
                borderRadius: 3,
                overflow: 'visible',
                backgroundColor: 'rgba(255, 255, 255, 0.95)',
                backdropFilter: 'blur(10px)',
                boxShadow: '0 10px 40px rgba(0,0,0,0.1)',
                mt: 1.5,
                '& .MuiMenuItem-root': { px: 2, py: 1.2, borderRadius: 2, mx: 0.5, my: 0.3 },
                ':before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 18,
                  width: 12,
                  height: 12,
                  bgcolor: 'rgba(255, 255, 255, 0.95)',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {!isClientUser && (
              <MenuItem onClick={handleOpenAvatarUpload} sx={{ borderRadius: 2, mx: 0.5, my: 0.3 }}>
                <ListItemIcon sx={{ color: '#455a64' }}><PersonIcon fontSize="small" /></ListItemIcon>
                <Typography variant="body2">Cambiar foto de perfil</Typography>
              </MenuItem>
            )}
            <MenuItem onClick={handleOpenChangePassword} sx={{ borderRadius: 2, mx: 0.5, my: 0.3 }}>
              <ListItemIcon sx={{ color: '#455a64' }}><LockIcon fontSize="small" /></ListItemIcon>
              <Typography variant="body2">Cambiar contraseña</Typography>
            </MenuItem>
            <MenuItem onClick={handleSignOut} sx={{ color: '#f44336', borderRadius: 2, mx: 0.5, my: 0.3 }}>
              <ListItemIcon sx={{ color: '#f44336' }}><LogoutIcon fontSize="small" /></ListItemIcon>
              <Typography variant="body2">Cerrar sesión</Typography>
            </MenuItem>
          </Menu>

          <motion.div style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
            <ScrollContainer ref={scrollRef}>
              <ScrollIndicator style={{ scaleX }} />

              {isClientUser ? (
                // Menú para clientes
                <CategoryCard elevation={0}>
                  <CategoryTitle>
                    <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '0.85rem', color: '#455a64', textTransform: 'uppercase' }}>
                      MI PROYECTO
                    </Typography>
                    <IconButton size="small" onClick={() => setInfoOpen(!infoOpen)} sx={{ padding: 0.5 }}>
                      <motion.div animate={{ rotate: infoOpen ? 180 : 0 }} transition={{ duration: 0.3 }}>
                        {infoOpen ? <ExpandLessIcon sx={{ fontSize: 20, color: '#78909c' }} /> : <ExpandMoreIcon sx={{ fontSize: 20, color: '#78909c' }} />}
                      </motion.div>
                    </IconButton>
                  </CategoryTitle>
                  <AnimatePresence initial={false}>
                    {infoOpen && (
                      <motion.div initial="closed" animate="open" exit="closed" variants={categoryVariants}>
                        <List disablePadding>
                          {clientMenuItems.map((item) => {
                            const isActive = location.pathname === item.path;
                            return (
                              <motion.div key={`client-${item.text}`} initial="visible" animate="visible" variants={listItemVariants} whileHover="hover">
                                <ListItem disablePadding>
                                  <ListItemButton
                                    onClick={() => handleNavigate(item.path)}
                                    selected={isActive}
                                    sx={{
                                      py: 0.8,
                                      px: 2,
                                      backgroundColor: isActive ? 'rgba(46, 125, 50, 0.08)' : 'transparent',
                                      '&:hover': { backgroundColor: isActive ? 'rgba(46, 125, 50, 0.12)' : 'rgba(0, 0, 0, 0.04)' },
                                      borderRadius: 0,
                                      position: 'relative',
                                      '&::after': isActive ? {
                                        content: '""',
                                        position: 'absolute',
                                        left: 0,
                                        top: '50%',
                                        transform: 'translateY(-50%)',
                                        width: 4,
                                        height: '70%',
                                        backgroundColor: '#2e7d32',
                                        borderRadius: '0 4px 4px 0',
                                      } : {},
                                    }}
                                  >
                                    <ListItemIcon sx={{ minWidth: 36, color: isActive ? '#2e7d32' : '#78909c' }}>
                                      <motion.div variants={iconVariants}>{item.icon}</motion.div>
                                    </ListItemIcon>
                                    <ListItemText
                                      primary={item.text}
                                      primaryTypographyProps={{ fontWeight: isActive ? 600 : 500, fontSize: '0.85rem', color: isActive ? '#2e7d32' : '#37474f' }}
                                    />
                                    {isActive && <Box sx={{ width: 6, height: 6, backgroundColor: '#2e7d32', borderRadius: '50%' }} />}
                                  </ListItemButton>
                                </ListItem>
                              </motion.div>
                            );
                          })}
                        </List>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </CategoryCard>
              ) : (
                // Menú para administradores
                <>
                  {/* Sección INFORMACIÓN */}
                  <CategoryCard elevation={0}>
                    <CategoryTitle>
                      <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '0.85rem', color: '#455a64', textTransform: 'uppercase' }}>
                        INFORMACIÓN
                      </Typography>
                      <IconButton size="small" onClick={() => setInfoOpen(!infoOpen)} sx={{ padding: 0.5 }}>
                        <motion.div animate={{ rotate: infoOpen ? 180 : 0 }} transition={{ duration: 0.3 }}>
                          {infoOpen ? <ExpandLessIcon sx={{ fontSize: 20, color: '#78909c' }} /> : <ExpandMoreIcon sx={{ fontSize: 20, color: '#78909c' }} />}
                        </motion.div>
                      </IconButton>
                    </CategoryTitle>
                    <AnimatePresence initial={false}>
                      {infoOpen && (
                        <motion.div initial="closed" animate="open" exit="closed" variants={categoryVariants}>
                          <List disablePadding>
                            {infoItems.map((item) => {
                              const isActive = location.pathname === item.path;
                              return (
                                <motion.div key={`info-${item.text}`} initial="visible" animate="visible" variants={listItemVariants} whileHover="hover">
                                  <ListItem disablePadding>
                                    <ListItemButton
                                      onClick={() => handleNavigate(item.path)}
                                      selected={isActive}
                                      sx={{
                                        py: 0.8,
                                        px: 2,
                                        backgroundColor: isActive ? 'rgba(46, 125, 50, 0.08)' : 'transparent',
                                        '&:hover': { backgroundColor: isActive ? 'rgba(46, 125, 50, 0.12)' : 'rgba(0, 0, 0, 0.04)' },
                                        borderRadius: 0,
                                        position: 'relative',
                                        '&::after': isActive ? {
                                          content: '""',
                                          position: 'absolute',
                                          left: 0,
                                          top: '50%',
                                          transform: 'translateY(-50%)',
                                          width: 4,
                                          height: '70%',
                                          backgroundColor: '#2e7d32',
                                          borderRadius: '0 4px 4px 0',
                                        } : {},
                                      }}
                                    >
                                      <ListItemIcon sx={{ minWidth: 36, color: isActive ? '#2e7d32' : '#78909c' }}>
                                        <motion.div variants={iconVariants}>{item.icon}</motion.div>
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={item.text}
                                        primaryTypographyProps={{ fontWeight: isActive ? 600 : 500, fontSize: '0.85rem', color: isActive ? '#2e7d32' : '#37474f' }}
                                      />
                                      {isActive && <Box sx={{ width: 6, height: 6, backgroundColor: '#2e7d32', borderRadius: '50%' }} />}
                                    </ListItemButton>
                                  </ListItem>
                                </motion.div>
                              );
                            })}
                          </List>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </CategoryCard>

                  {/* Sección INPUT */}
                  <CategoryCard elevation={0}>
                    <CategoryTitle>
                      <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '0.85rem', color: '#455a64', textTransform: 'uppercase' }}>
                        INPUT
                      </Typography>
                      <IconButton size="small" onClick={() => setInputOpen(!inputOpen)} sx={{ padding: 0.5 }}>
                        <motion.div animate={{ rotate: inputOpen ? 180 : 0 }} transition={{ duration: 0.3 }}>
                          {inputOpen ? <ExpandLessIcon sx={{ fontSize: 20, color: '#78909c' }} /> : <ExpandMoreIcon sx={{ fontSize: 20, color: '#78909c' }} />}
                        </motion.div>
                      </IconButton>
                    </CategoryTitle>
                    <AnimatePresence initial={false}>
                      {inputOpen && (
                        <motion.div initial="closed" animate="open" exit="closed" variants={categoryVariants}>
                          <List disablePadding>
                            {inputItems.map((item) => {
                              const isActive = location.pathname === item.path;
                              return (
                                <motion.div key={`input-${item.text}`} initial="visible" animate="visible" variants={listItemVariants} whileHover="hover">
                                  <ListItem disablePadding>
                                    <ListItemButton
                                      onClick={() => handleNavigate(item.path)}
                                      selected={isActive}
                                      sx={{
                                        py: 0.8,
                                        px: 2,
                                        backgroundColor: isActive ? 'rgba(46, 125, 50, 0.08)' : 'transparent',
                                        '&:hover': { backgroundColor: isActive ? 'rgba(46, 125, 50, 0.12)' : 'rgba(0, 0, 0, 0.04)' },
                                        borderRadius: 0,
                                        position: 'relative',
                                        '&::after': isActive ? {
                                          content: '""',
                                          position: 'absolute',
                                          left: 0,
                                          top: '50%',
                                          transform: 'translateY(-50%)',
                                          width: 4,
                                          height: '70%',
                                          backgroundColor: '#2e7d32',
                                          borderRadius: '0 4px 4px 0',
                                        } : {},
                                      }}
                                    >
                                      <ListItemIcon sx={{ minWidth: 36, color: isActive ? '#2e7d32' : '#78909c' }}>
                                        <motion.div variants={iconVariants}>{item.icon}</motion.div>
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={item.text}
                                        primaryTypographyProps={{ fontWeight: isActive ? 600 : 500, fontSize: '0.85rem', color: isActive ? '#2e7d32' : '#37474f' }}
                                      />
                                      {isActive && <Box sx={{ width: 6, height: 6, backgroundColor: '#2e7d32', borderRadius: '50%' }} />}
                                    </ListItemButton>
                                  </ListItem>
                                </motion.div>
                              );
                            })}
                          </List>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </CategoryCard>

                  {/* Sección BÚSQUEDA */}
                  <SearchCard elevation={0}>
                    <ListItem disablePadding>
                      <ListItemButton
                        onClick={() => handleNavigate('/buscador-unificado')}
                        selected={location.pathname === '/buscador-unificado'}
                        sx={{
                          py: 1.2,
                          px: 2,
                          backgroundColor: location.pathname === '/buscador-unificado' ? 'rgba(46, 125, 50, 0.08)' : 'transparent',
                          '&:hover': { backgroundColor: location.pathname === '/buscador-unificado' ? 'rgba(46, 125, 50, 0.12)' : 'rgba(0, 0, 0, 0.04)' },
                          borderRadius: 0,
                          position: 'relative',
                          '&::after': location.pathname === '/buscador-unificado' ? {
                            content: '""',
                            position: 'absolute',
                            left: 0,
                            top: '50%',
                            transform: 'translateY(-50%)',
                            width: 4,
                            height: '70%',
                            backgroundColor: '#2e7d32',
                            borderRadius: '0 4px 4px 0',
                          } : {},
                        }}
                        component={motion.div}
                        whileHover={{ x: 8, transition: { type: 'spring', stiffness: 300, damping: 10 } }}
                      >
                        <ListItemIcon sx={{ minWidth: 36, color: location.pathname === '/buscador-unificado' ? '#2e7d32' : '#78909c' }}>
                          <motion.div whileHover={iconVariants.hover}><SearchIcon /></motion.div>
                        </ListItemIcon>
                        <ListItemText
                          primary="Buscador Unificado"
                          primaryTypographyProps={{ fontWeight: location.pathname === '/buscador-unificado' ? 600 : 500, fontSize: '0.85rem', color: location.pathname === '/buscador-unificado' ? '#2e7d32' : '#37474f' }}
                        />
                      </ListItemButton>
                    </ListItem>
                  </SearchCard>

                  {/* Sección ADMIN */}
                  <CategoryCard elevation={0}>
                    <CategoryTitle>
                      <Typography variant="subtitle2" sx={{ fontWeight: 500, fontSize: '0.85rem', color: '#455a64', textTransform: 'uppercase' }}>
                        ADMIN
                      </Typography>
                      <IconButton size="small" onClick={() => setAdminOpen(!adminOpen)} sx={{ padding: 0.5 }}>
                        <motion.div animate={{ rotate: adminOpen ? 180 : 0 }} transition={{ duration: 0.3 }}>
                          {adminOpen ? <ExpandLessIcon sx={{ fontSize: 20, color: '#78909c' }} /> : <ExpandMoreIcon sx={{ fontSize: 20, color: '#78909c' }} />}
                        </motion.div>
                      </IconButton>
                    </CategoryTitle>
                    <AnimatePresence initial={false}>
                      {adminOpen && (
                        <motion.div initial="closed" animate="open" exit="closed" variants={categoryVariants}>
                          <List disablePadding>
                            {adminItems.map((item) => {
                              const isActive = location.pathname === item.path;
                              return (
                                <motion.div key={`admin-${item.text}`} initial="visible" animate="visible" variants={listItemVariants} whileHover="hover">
                                  <ListItem disablePadding>
                                    <ListItemButton
                                      onClick={() => handleNavigate(item.path)}
                                      selected={isActive}
                                      sx={{
                                        py: 0.8,
                                        px: 2,
                                        backgroundColor: isActive ? 'rgba(46, 125, 50, 0.08)' : 'transparent',
                                        '&:hover': { backgroundColor: isActive ? 'rgba(46, 125, 50, 0.12)' : 'rgba(0, 0, 0, 0.04)' },
                                        borderRadius: 0,
                                        position: 'relative',
                                        '&::after': isActive ? {
                                          content: '""',
                                          position: 'absolute',
                                          left: 0,
                                          top: '50%',
                                          transform: 'translateY(-50%)',
                                          width: 4,
                                          height: '70%',
                                          backgroundColor: '#2e7d32',
                                          borderRadius: '0 4px 4px 0',
                                        } : {},
                                      }}
                                    >
                                      <ListItemIcon sx={{ minWidth: 36, color: isActive ? '#2e7d32' : '#78909c' }}>
                                        <motion.div variants={iconVariants}>{item.icon}</motion.div>
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={item.text}
                                        primaryTypographyProps={{ fontWeight: isActive ? 600 : 500, fontSize: '0.85rem', color: isActive ? '#2e7d32' : '#37474f' }}
                                      />
                                      {isActive && <Box sx={{ width: 6, height: 6, backgroundColor: '#2e7d32', borderRadius: '50%' }} />}
                                    </ListItemButton>
                                  </ListItem>
                                </motion.div>
                              );
                            })}
                          </List>
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </CategoryCard>
                </>
              )}
            </ScrollContainer>

            {isClientUser && renderUrl && (
              <Box sx={{ mt: 2, mb: 3, px: 2 }}>
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.4, duration: 0.5 }}
                >
                  <Paper
                    elevation={0}
                    sx={{
                      overflow: 'hidden',
                      borderRadius: 3,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    }}
                  >
                    <Box
                      component="img"
                      src={renderUrl}
                      alt="Render del proyecto"
                      sx={{
                        width: '100%',
                        height: 'auto',
                        display: 'block',
                        transition: 'transform 0.3s ease',
                        '&:hover': {
                          transform: 'scale(1.05)',
                        }
                      }}
                    />
                  </Paper>
                </motion.div>
              </Box>
            )}


            <FooterContainer>
              <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ delay: 0.6, duration: 0.4 }}>
                <FooterText>
                  <CopyrightIcon sx={{ fontSize: '0.7rem', mr: 0.3 }} /> 2025 Nokk. All rights reserved.
                </FooterText>
              </motion.div>
            </FooterContainer>
          </motion.div>
        </MainContainer>

        {/* Dialog para cambiar contraseña */}
        <Dialog
          open={changePasswordOpen}
          onClose={handleCloseChangePassword}
          fullWidth
          maxWidth="xs"
          PaperProps={{
            elevation: 24,
            sx: {
              borderRadius: 4,
              px: 1,
              background: 'rgba(255, 255, 255, 0.95)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 24px 50px rgba(0, 0, 0, 0.2)',
            }
          }}
        >
          <DialogTitle sx={{ fontSize: '1.3rem', fontWeight: 600, pt: 3 }}>
            Cambiar contraseña
          </DialogTitle>
          <DialogContent>
            {error && (
              <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
                <Alert severity="error" sx={{ mb: 2, borderRadius: 2, boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)' }}>
                  {error}
                </Alert>
              </motion.div>
            )}
            {success && (
              <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
                <Alert severity="success" sx={{ mb: 2, borderRadius: 2, boxShadow: '0 4px 12px rgba(76, 175, 80, 0.1)' }}>
                  {success}
                </Alert>
              </motion.div>
            )}
            <TextField
              label="Nueva contraseña"
              type="password"
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              disabled={loading || success}
              sx={{
                '& .MuiOutlinedInput-root': { borderRadius: 2, '&.Mui-focused fieldset': { borderColor: '#2e7d32', borderWidth: '1px' } },
                '& .MuiInputLabel-root.Mui-focused': { color: '#2e7d32' }
              }}
            />
            <TextField
              label="Confirmar contraseña"
              type="password"
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              disabled={loading || success}
              sx={{
                '& .MuiOutlinedInput-root': { borderRadius: 2, '&.Mui-focused fieldset': { borderColor: '#2e7d32', borderWidth: '1px' } },
                '& .MuiInputLabel-root.Mui-focused': { color: '#2e7d32' }
              }}
            />
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button
              onClick={handleCloseChangePassword}
              disabled={loading}
              variant="outlined"
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                px: 3,
                borderColor: '#ccc',
                color: '#555',
                '&:hover': { borderColor: '#999', backgroundColor: 'rgba(0, 0, 0, 0.03)' }
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleChangePassword}
              variant="contained"
              color="primary"
              disabled={loading || success}
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                backgroundColor: '#2e7d32',
                px: 3,
                boxShadow: '0 4px 12px rgba(46, 125, 50, 0.2)',
                '&:hover': { backgroundColor: '#246428', boxShadow: '0 6px 16px rgba(46, 125, 50, 0.3)' }
              }}
            >
              {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={16} sx={{ mr: 1, color: 'white' }} /> Guardando...
                </Box>
              ) : 'Guardar'}
            </Button>
          </DialogActions>
        </Dialog>

        {/* Dialog para subir avatar */}
        <Dialog
          open={avatarUploadOpen}
          onClose={handleCloseAvatarUpload}
          fullWidth
          maxWidth="sm"
          PaperProps={{
            elevation: 24,
            sx: {
              borderRadius: 4,
              px: 1,
              background: 'rgba(255, 255, 255, 0.95)',
              backdropFilter: 'blur(10px)',
              boxShadow: '0 24px 50px rgba(0, 0, 0, 0.2)',
            }
          }}
        >
          <DialogTitle sx={{ fontSize: '1.3rem', fontWeight: 600, pt: 3 }}>
            Cambiar foto de perfil
          </DialogTitle>
          <DialogContent>
            {error && (
              <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
                <Alert severity="error" sx={{ mb: 2, borderRadius: 2, boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)' }}>
                  {error}
                </Alert>
              </motion.div>
            )}
            {success && (
              <motion.div initial={{ opacity: 0, y: -10 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.3 }}>
                <Alert severity="success" sx={{ mb: 2, borderRadius: 2, boxShadow: '0 4px 12px rgba(76, 175, 80, 0.1)' }}>
                  {success}
                </Alert>
              </motion.div>
            )}
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 2 }}>
              {!previewUrl ? (
                <>
                  <Avatar
                    sx={{
                      width: 100,
                      height: 100,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
                      mb: 2,
                      fontSize: '2rem',
                      background: 'linear-gradient(135deg, #ff4b4b 0%, #ff758e 100%)'
                    }}
                  >
                    {avatarUrl ? (
                      <img src={avatarUrl} alt="Avatar actual" style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
                    ) : (
                      getInitials()
                    )}
                  </Avatar>
                  <Button
                    component="label"
                    variant="outlined"
                    startIcon={<PhotoCameraIcon />}
                    sx={{
                      borderRadius: 2,
                      textTransform: 'none',
                      px: 3,
                      borderColor: '#ccc',
                      color: '#555',
                      '&:hover': { borderColor: '#999', backgroundColor: 'rgba(0, 0, 0, 0.03)' }
                    }}
                  >
                    Seleccionar imagen
                    <input type="file" accept="image/*" hidden onChange={handleFileSelect} disabled={loading} />
                  </Button>
                </>
              ) : (
                <>
                  <ImageCropper image={previewUrl} onCropComplete={(croppedArea) => setCropSelection(croppedArea)} />
                  <Box sx={{ display: 'flex', gap: 2, mt: 1 }}>
                    <Button
                      variant="outlined"
                      onClick={() => { setPreviewUrl(''); setSelectedFile(null); }}
                      sx={{
                        borderRadius: 2,
                        textTransform: 'none',
                        px: 3,
                        borderColor: '#ccc',
                        color: '#555',
                        '&:hover': { borderColor: '#999', backgroundColor: 'rgba(0, 0, 0, 0.03)' }
                      }}
                    >
                      Cambiar imagen
                    </Button>
                  </Box>
                </>
              )}
            </Box>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button
              onClick={handleCloseAvatarUpload}
              disabled={loading}
              variant="outlined"
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                px: 3,
                borderColor: '#ccc',
                color: '#555',
                '&:hover': { borderColor: '#999', backgroundColor: 'rgba(0, 0, 0, 0.03)' }
              }}
            >
              Cancelar
            </Button>
            <Button
              onClick={handleAvatarUpload}
              variant="contained"
              color="primary"
              disabled={loading || success || !selectedFile}
              sx={{
                borderRadius: 2,
                textTransform: 'none',
                backgroundColor: '#2e7d32',
                px: 3,
                boxShadow: '0 4px 12px rgba(46, 125, 50, 0.2)',
                '&:hover': { backgroundColor: '#246428', boxShadow: '0 6px 16px rgba(46, 125, 50, 0.3)' }
              }}
            >
              {loading ? (
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <CircularProgress size={16} sx={{ mr: 1, color: 'white' }} /> Subiendo...
                </Box>
              ) : 'Guardar'}
            </Button>
          </DialogActions>
        </Dialog>
      </SidebarDrawer>
    </>
  );
};

export default MobileSidebar;