import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
  TextField,
  Divider,
  Tabs,
  Tab,
  CircularProgress,
  Collapse,
  Alert,
  Snackbar,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormControl,
  InputAdornment,
  Fab
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import MoneyIcon from '@mui/icons-material/Money';
import PaidIcon from '@mui/icons-material/Paid';
import FolderIcon from '@mui/icons-material/Folder';
import SaveIcon from '@mui/icons-material/Save';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';

// Estilos simples y eficaces
const MobileContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1, 1.5, 10, 1.5),
  maxWidth: '100%',
  minHeight: 'calc(100vh - 64px)'
}));

const TotalCard = styled(Card)(({ theme, color = 'primary' }) => ({
  borderRadius: 20,
  background: '#ffffff',
  padding: theme.spacing(1.5),
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.07)',
  border: color === 'primary' ? '1px solid rgba(46, 125, 50, 0.1)' : '1px solid rgba(237, 108, 2, 0.1)',
}));

const CajaCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.06)',
  marginBottom: theme.spacing(2)
}));

const CardHeader = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
  backgroundColor: '#fafafa',
  borderRadius: '16px 16px 0 0',
}));

const CardBody = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1.5, 2),
}));

const SectionHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  minWidth: 0,
  fontWeight: 'medium',
  fontSize: '0.85rem',
  padding: '10px 16px',
  '&.Mui-selected': {
    fontWeight: 'bold',
  },
}));

// Componente principal para el Dashboard móvil
const DashboardMobile = () => {
  const [saldos, setSaldos] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [openCashCounter, setOpenCashCounter] = useState(false);
  const [currency, setCurrency] = useState('ARS');
  const [arsBills, setArsBills] = useState({ 200: '', 500: '', 1000: '', 2000: '', 10000: '', 20000: '' });
  const [usdBills, setUsdBills] = useState({ 1: '', 5: '', 10: '', 20: '', 50: '', 100: '' });
  const [manualAmount, setManualAmount] = useState('');
  const [inputMethod, setInputMethod] = useState('bills'); // 'bills' o 'manual'
  const [loading, setLoading] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [expandedSections, setExpandedSections] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      await Promise.all([fetchSaldos(), fetchProyectos()]);
    } catch (error) {
      console.error('Error fetching data:', error);
      setSnackbar({
        open: true,
        message: 'Error al cargar los datos. Intenta nuevamente.',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchSaldos = async () => {
    // Usar la vista caja_saldos_vista
    const { data, error } = await supabase.from('caja_saldos_vista').select('*');
    if (error) {
      console.error('Error fetching saldos:', error);
      throw error;
    } else {
      setSaldos(data || []);
    }
  };

  const fetchProyectos = async () => {
    const { data, error } = await supabase
      .from('proyectos')
      .select('apellido_del_cliente, caja_proyecto, caja_proyecto_mo, mostrar_cajas, id')
      .eq('mostrar_cajas', 'true'); // Solo proyectos activos

    if (error) {
      console.error('Error fetching proyectos:', error);
      throw error;
    } else {
      setProyectos(data || []);
    }
  };

  const totalARS = saldos.reduce((sum, saldo) => sum + (parseFloat(saldo.saldo_ars) || 0), 0);
  const totalUSD = saldos.reduce((sum, saldo) => sum + (parseFloat(saldo.saldo_usd) || 0), 0);

  // Agrupar saldos por proyectos
  const groupedSaldos = proyectos.reduce((acc, proyecto) => {
    const apellido = proyecto.apellido_del_cliente;
    if (!acc[apellido]) acc[apellido] = [];
    const cajasRelacionadas = saldos.filter(
      (saldo) => saldo.caja_ars === proyecto.caja_proyecto || saldo.caja_ars === proyecto.caja_proyecto_mo
    );
    if (cajasRelacionadas.length > 0) {
      acc[apellido].push(...cajasRelacionadas);
    }
    return acc;
  }, {});

  // Saldos sin proyecto asignado
  const allProjectCajas = proyectos.flatMap((p) => [p.caja_proyecto, p.caja_proyecto_mo]).filter(Boolean);
  const cajasSinProyecto = saldos.filter((saldo) => !allProjectCajas.includes(saldo.caja_ars));

  // Calcular total de efectivo para el contador
  const calculateCashTotal = () => {
    if (inputMethod === 'manual') {
      return parseFloat(manualAmount) || 0;
    }

    if (currency === 'ARS') {
      return (
        (parseInt(arsBills[200]) || 0) * 200 +
        (parseInt(arsBills[500]) || 0) * 500 +
        (parseInt(arsBills[1000]) || 0) * 1000 +
        (parseInt(arsBills[2000]) || 0) * 2000 +
        (parseInt(arsBills[10000]) || 0) * 10000 +
        (parseInt(arsBills[20000]) || 0) * 20000
      );
    } else {
      return (
        (parseInt(usdBills[1]) || 0) * 1 +
        (parseInt(usdBills[5]) || 0) * 5 +
        (parseInt(usdBills[10]) || 0) * 10 +
        (parseInt(usdBills[20]) || 0) * 20 +
        (parseInt(usdBills[50]) || 0) * 50 +
        (parseInt(usdBills[100]) || 0) * 100
      );
    }
  };

  const cashTotal = calculateCashTotal();
  const difference = currency === 'ARS' ? cashTotal - totalARS : cashTotal - totalUSD;
  const isCashZero = inputMethod === 'manual'
    ? !manualAmount || parseFloat(manualAmount) === 0
    : currency === 'ARS'
      ? Object.values(arsBills).every(val => val === '' || parseInt(val) === 0)
      : Object.values(usdBills).every(val => val === '' || parseInt(val) === 0);

  const handleSaveCount = async () => {
    try {
      const { error } = await supabase.from('conteos_efectivo').insert([
        {
          monto_conteo: cashTotal,
          moneda_conteo: currency,
          fecha_conteo: new Date().toISOString(),
          total_esperado: currency === 'ARS' ? totalARS : totalUSD,
          diferencia: difference,
        },
      ]);

      if (error) throw error;

      // Resetear formulario
      setArsBills({ 200: '', 500: '', 1000: '', 2000: '', 10000: '', 20000: '' });
      setUsdBills({ 1: '', 5: '', 10: '', 20: '', 50: '', 100: '' });
      setManualAmount('');
      setOpenCashCounter(false);

      setSnackbar({
        open: true,
        message: 'Conteo guardado con éxito',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error saving conteo:', error);
      setSnackbar({
        open: true,
        message: 'Error al guardar el conteo',
        severity: 'error'
      });
    }
  };

  const handleCloseCashCounter = () => {
    setOpenCashCounter(false);
    setArsBills({ 200: '', 500: '', 1000: '', 2000: '', 10000: '', 20000: '' });
    setUsdBills({ 1: '', 5: '', 10: '', 20: '', 50: '', 100: '' });
    setManualAmount('');
    setInputMethod('bills');
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // Función para formatear números sin decimales
  const formatNumber = (number, currency = "") => {
    if (number === undefined || number === null) return currency + "0";

    // Convertir a número si es string
    const numberValue = typeof number === 'string' ? parseFloat(number) : number;

    // Formatear sin decimales
    return currency + Math.round(numberValue).toLocaleString('es-AR');
  };

  // Función para expandir/colapsar secciones
  const toggleSection = (sectionKey) => {
    setExpandedSections(prev => ({
      ...prev,
      [sectionKey]: !prev[sectionKey]
    }));
  };

  // Renderizar las cajas agrupadas por proyecto
  const renderGroupedCajas = () => {
    if (tabValue === 0) {
      // Vista general - Mostrar todos los proyectos
      return (
        <>
          {Object.entries(groupedSaldos).map(([apellido, cajas]) => (
            cajas.length > 0 && (
              <Box key={apellido} sx={{ mb: 2.5 }}>
                <SectionHeader>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <FolderIcon sx={{ color: 'primary.main', mr: 1, fontSize: 20 }} />
                    <Typography variant="subtitle1" fontWeight="medium" color="text.primary">
                      {apellido}
                    </Typography>
                  </Box>
                  <IconButton
                    size="small"
                    onClick={() => toggleSection(apellido)}
                    sx={{ bgcolor: 'rgba(0,0,0,0.04)', borderRadius: 2 }}
                  >
                    {expandedSections[apellido] === false ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </IconButton>
                </SectionHeader>

                <Collapse in={expandedSections[apellido] !== false}>
                  <Grid container spacing={2}>
                    {cajas.map((saldo, index) => (
                      <Grid item xs={12} key={index}>
                        <CajaCard>
                          <CardHeader>
                            <Typography variant="subtitle2" fontWeight="medium" color="text.primary">
                              {saldo.caja_ars}
                            </Typography>
                          </CardHeader>
                          <CardBody>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '50%' }}>
                                <Typography variant="body2" color="text.secondary">
                                  Saldo ARS
                                </Typography>
                                <Typography variant="h6" fontWeight="bold" color="primary.main">
                                  ${formatNumber(saldo.saldo_ars)}
                                </Typography>
                              </Box>

                              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '50%' }}>
                                <Typography variant="body2" color="text.secondary">
                                  Saldo USD
                                </Typography>
                                <Typography variant="h6" fontWeight="bold" color="#f57c00">
                                  ${formatNumber(saldo.saldo_usd)}
                                </Typography>
                              </Box>
                            </Box>
                          </CardBody>
                        </CajaCard>
                      </Grid>
                    ))}
                  </Grid>
                </Collapse>
              </Box>
            )
          ))}

          {/* Cajas sin proyecto */}
          {cajasSinProyecto.length > 0 && (
            <Box sx={{ mb: 2.5 }}>
              <SectionHeader>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <AccountBalanceWalletIcon sx={{ color: 'text.secondary', mr: 1, fontSize: 20 }} />
                  <Typography variant="subtitle1" fontWeight="medium" color="text.primary">
                    Cajas sin Proyecto
                  </Typography>
                </Box>
                <IconButton
                  size="small"
                  onClick={() => toggleSection('sinProyecto')}
                  sx={{ bgcolor: 'rgba(0,0,0,0.04)', borderRadius: 2 }}
                >
                  {expandedSections['sinProyecto'] === false ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              </SectionHeader>

              <Collapse in={expandedSections['sinProyecto'] !== false}>
                <Grid container spacing={2}>
                  {cajasSinProyecto.map((saldo, index) => (
                    <Grid item xs={12} key={index}>
                      <CajaCard>
                        <CardHeader>
                          <Typography variant="subtitle2" fontWeight="medium" color="text.primary">
                            {saldo.caja_ars}
                          </Typography>
                        </CardHeader>
                        <CardBody>
                          <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '50%' }}>
                              <Typography variant="body2" color="text.secondary">
                                Saldo ARS
                              </Typography>
                              <Typography variant="h6" fontWeight="bold" color="primary.main">
                                ${formatNumber(saldo.saldo_ars)}
                              </Typography>
                            </Box>

                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '50%' }}>
                              <Typography variant="body2" color="text.secondary">
                                Saldo USD
                              </Typography>
                              <Typography variant="h6" fontWeight="bold" color="#f57c00">
                                ${formatNumber(saldo.saldo_usd)}
                              </Typography>
                            </Box>
                          </Box>
                        </CardBody>
                      </CajaCard>
                    </Grid>
                  ))}
                </Grid>
              </Collapse>
            </Box>
          )}
        </>
      );
    } else if (tabValue === 1) {
      // Vista ARS - Solo mostrar saldos ARS
      const sortedCajas = [...saldos].sort((a, b) => (parseFloat(b.saldo_ars) || 0) - (parseFloat(a.saldo_ars) || 0));

      return (
        <Grid container spacing={2}>
          {sortedCajas.filter(saldo => parseFloat(saldo.saldo_ars) > 0).map((saldo, index) => (
            <Grid item xs={12} key={index}>
              <CajaCard>
                <CardHeader>
                  <Typography variant="subtitle2" fontWeight="medium" color="text.primary">
                    {saldo.caja_ars}
                  </Typography>
                </CardHeader>
                <CardBody>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="body2" color="text.secondary">
                      Saldo ARS
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" color="primary.main">
                      ${formatNumber(saldo.saldo_ars)}
                    </Typography>
                  </Box>
                </CardBody>
              </CajaCard>
            </Grid>
          ))}
        </Grid>
      );
    } else {
      // Vista USD - Solo mostrar saldos USD
      const sortedCajas = [...saldos].sort((a, b) => (parseFloat(b.saldo_usd) || 0) - (parseFloat(a.saldo_usd) || 0));

      return (
        <Grid container spacing={2}>
          {sortedCajas.filter(saldo => parseFloat(saldo.saldo_usd) > 0).map((saldo, index) => (
            <Grid item xs={12} key={index}>
              <CajaCard>
                <CardHeader>
                  <Typography variant="subtitle2" fontWeight="medium" color="text.primary">
                    {saldo.caja_ars}
                  </Typography>
                </CardHeader>
                <CardBody>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="body2" color="text.secondary">
                      Saldo USD
                    </Typography>
                    <Typography variant="h6" fontWeight="bold" color="#f57c00">
                      ${formatNumber(saldo.saldo_usd)}
                    </Typography>
                  </Box>
                </CardBody>
              </CajaCard>
            </Grid>
          ))}
        </Grid>
      );
    }
  };

  // Renderizar información de resumen
  const renderSummary = () => {
    return (
      <Box sx={{ mb: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TotalCard color="primary">
              <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                Total ARS
              </Typography>
              <Typography variant="h5" fontWeight="bold" color="primary.main">
                ${formatNumber(totalARS)}
              </Typography>
            </TotalCard>
          </Grid>

          <Grid item xs={6}>
            <TotalCard color="secondary">
              <Typography variant="body2" color="text.secondary" sx={{ mb: 0.5 }}>
                Total USD
              </Typography>
              <Typography variant="h5" fontWeight="bold" color="#f57c00">
                ${formatNumber(totalUSD)}
              </Typography>
            </TotalCard>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return (
    <MobileContainer>
      {/* Sección de resumen */}
      {renderSummary()}

      {/* Tabs para filtrar vistas */}
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2 }}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          variant="fullWidth"
          textColor="primary"
          indicatorColor="primary"
        >
          <StyledTab icon={<FolderIcon />} iconPosition="start" label="Proyectos" />
          <StyledTab icon={<MoneyIcon />} iconPosition="start" label="ARS" />
          <StyledTab icon={<PaidIcon />} iconPosition="start" label="USD" />
        </Tabs>
      </Box>

      {/* Loader */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', flexDirection: 'column' }}>
          <CircularProgress color="primary" size={40} />
          <Typography variant="body1" sx={{ mt: 2 }}>Actualizando saldos...</Typography>
        </Box>
      ) : (
        <Box>
          {renderGroupedCajas()}
        </Box>
      )}

      {/* Botón de contador de efectivo - Usando Fab para mayor visibilidad */}
      <Fab
        color="primary"
        aria-label="Contador de Efectivo"
        onClick={() => setOpenCashCounter(true)}
        sx={{
          position: 'fixed',
          bottom: 110,
          right: 16,
          zIndex: 1200
        }}
      >
        <PointOfSaleIcon />
      </Fab>

      {/* Diálogo del contador de efectivo */}
      <Dialog
        open={openCashCounter}
        onClose={handleCloseCashCounter}
        fullWidth
        maxWidth="sm"
        PaperProps={{
          sx: {
            borderRadius: 4
          }
        }}
      >
        <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Typography variant="h6" fontWeight="bold">
            Contador de Efectivo
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseCashCounter}
            size="small"
            sx={{ bgcolor: 'rgba(0,0,0,0.04)' }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>

        <DialogContent>
          <Box sx={{ textAlign: 'center', mb: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
              <Typography variant="body1" sx={{ mr: 2, color: currency === 'ARS' ? 'primary.main' : 'text.secondary', fontWeight: currency === 'ARS' ? 'bold' : 'regular' }}>
                ARS
              </Typography>
              <Switch
                checked={currency === 'USD'}
                onChange={(e) => setCurrency(e.target.checked ? 'USD' : 'ARS')}
                color="primary"
              />
              <Typography variant="body1" sx={{ ml: 2, color: currency === 'USD' ? '#f57c00' : 'text.secondary', fontWeight: currency === 'USD' ? 'bold' : 'regular' }}>
                USD
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ mb: 2 }} />

          {/* Selector de método de entrada */}
          <FormControl component="fieldset" sx={{ mb: 2, width: '100%' }}>
            <RadioGroup
              row
              value={inputMethod}
              onChange={(e) => setInputMethod(e.target.value)}
              sx={{ justifyContent: 'center' }}
            >
              <FormControlLabel value="bills" control={<Radio color="primary" />} label="Contar billetes" />
              <FormControlLabel value="manual" control={<Radio color="primary" />} label="Monto total" />
            </RadioGroup>
          </FormControl>

          {/* Mostrar el formulario adecuado según el método seleccionado */}
          {inputMethod === 'manual' ? (
            <Box sx={{ mb: 3 }}>
              <TextField
                fullWidth
                label={`Monto total en ${currency}`}
                value={manualAmount}
                onChange={(e) => setManualAmount(e.target.value)}
                type="number"
                variant="outlined"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                sx={{ mb: 2 }}
              />
            </Box>
          ) : (
            <Grid container spacing={1} sx={{ mb: 3 }}>
              {currency === 'ARS' ? (
                <>
                  {[[200, 500], [1000, 2000], [10000, 20000]].map((pair, index) => (
                    <Grid container item xs={12} key={index} spacing={1}>
                      {pair.map((denomination) => (
                        <Grid item xs={6} key={denomination}>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <TextField
                              label={`${denomination}`}
                              type="number"
                              value={arsBills[denomination]}
                              onChange={(e) =>
                                setArsBills({
                                  ...arsBills,
                                  [denomination]: e.target.value === '' ? '' : Math.max(0, parseInt(e.target.value)),
                                })
                              }
                              inputProps={{ min: 0 }}
                              sx={{ width: '100px' }}
                              size="small"
                            />
                            <Typography
                              variant="body2"
                              color="primary.main"
                              fontWeight="medium"
                              sx={{ ml: 1 }}
                            >
                              ${formatNumber((parseInt(arsBills[denomination]) || 0) * denomination)}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </>
              ) : (
                <>
                  {[[1, 5], [10, 20], [50, 100]].map((pair, index) => (
                    <Grid container item xs={12} key={index} spacing={1}>
                      {pair.map((denomination) => (
                        <Grid item xs={6} key={denomination}>
                          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <TextField
                              label={`${denomination}`}
                              type="number"
                              value={usdBills[denomination]}
                              onChange={(e) =>
                                setUsdBills({
                                  ...usdBills,
                                  [denomination]: e.target.value === '' ? '' : Math.max(0, parseInt(e.target.value)),
                                })
                              }
                              inputProps={{ min: 0 }}
                              sx={{ width: '100px' }}
                              size="small"
                            />
                            <Typography
                              variant="body2"
                              color="#f57c00"
                              fontWeight="medium"
                              sx={{ ml: 1 }}
                            >
                              ${formatNumber((parseInt(usdBills[denomination]) || 0) * denomination)}
                            </Typography>
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  ))}
                </>
              )}
            </Grid>
          )}

          <Divider sx={{ mb: 2 }} />

          {/* Resumen del conteo */}
          <Box sx={{ mb: 3, p: 2, bgcolor: 'rgba(0,0,0,0.02)', borderRadius: 2 }}>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              Resumen del Conteo:
            </Typography>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body1">Esperado:</Typography>
              <Typography variant="body1" fontWeight="medium">
                ${formatNumber(currency === 'ARS' ? totalARS : totalUSD)} {currency}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
              <Typography variant="body1">Contado:</Typography>
              <Typography variant="body1" fontWeight="medium">
                ${formatNumber(cashTotal)} {currency}
              </Typography>
            </Box>

            {!isCashZero && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Typography variant="body1">Diferencia:</Typography>
                <Typography
                  variant="body1"
                  fontWeight="bold"
                  sx={{ color: difference >= 0 ? 'success.main' : 'error.main' }}>
                  {difference >= 0 ? '+' : ''}{formatNumber(difference)} {currency}
                </Typography>
              </Box>
            )}
          </Box>

          {/* Botón para guardar */}
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<SaveIcon />}
              onClick={handleSaveCount}
              disabled={isCashZero}
              sx={{
                borderRadius: 28,
                py: 1,
                px: 3,
                textTransform: 'none',
                fontWeight: 'medium'
              }}
            >
              Guardar Conteo
            </Button>
          </Box>
        </DialogContent>
      </Dialog>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ mb: 7 }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            borderRadius: 20,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </MobileContainer>
  );
};

export default DashboardMobile;