// src/supabaseClient.js
import { createClient } from '@supabase/supabase-js';

const supabaseUrl = 'https://jctjvhnnmfvjuouwqzhx.supabase.co';
const supabaseAnonKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImpjdGp2aG5ubWZ2anVvdXdxemh4Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3Mzk5NzQ2NTYsImV4cCI6MjA1NTU1MDY1Nn0.LwKdbY-LUSHZ0Z2Tg1gl5UfliwwOl5ZMUY4ZCa2IsXY';

// Crear el cliente de Supabase con opciones para la persistencia de sesión
export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: false,
    storage: window.localStorage
  }
});

// Función para hacer una consulta con timeout
const queryWithTimeout = async (queryPromise, timeoutMs = 1500) => {
  // Create a promise that will reject after timeout
  const timeoutPromise = new Promise((_, reject) => {
    setTimeout(() => {
      reject(new Error("Query timed out"));
    }, timeoutMs);
  });

  return Promise.race([queryPromise, timeoutPromise]);
};

// Función para verificar el tipo de usuario basado en su email
export const getUserRole = async (user) => {
  if (!user) {
    console.error("getUserRole: No hay usuario proporcionado");
    return null;
  }

  console.log("Verificando rol para usuario:", user.email);
  console.time("getUserRole"); // Para medir cuánto tarda en ejecutarse

  // Primero intentamos obtener el rol desde localStorage para respuesta inmediata
  const savedRole = localStorage.getItem('userRole');
  const savedDetails = localStorage.getItem('userDetails');

  if (savedRole && savedDetails) {
    console.log("Usando rol guardado de localStorage:", savedRole);
    // Continuamos con la verificación en segundo plano para actualizar si es necesario
    setTimeout(() => verifyRoleInBackground(user), 100);

    try {
      return {
        role: savedRole,
        details: JSON.parse(savedDetails)
      };
    } catch (e) {
      console.error("Error al parsear detalles guardados:", e);
      // Continuamos con la verificación completa
    }
  }

  try {
    // Hacer consultas en paralelo en lugar de secuencial para mayor velocidad
    const [adminQuery, workerQuery, clientQuery] = await Promise.allSettled([
      // Verificar si es admin
      queryWithTimeout(
        supabase
          .from('trabajadores_empresa')
          .select('*')
          .eq('email_del_trabajador', user.email)
          .eq('permisos_del_trabajador', 'admin')
          .maybeSingle()
      ),

      // Verificar si es trabajador
      queryWithTimeout(
        supabase
          .from('trabajadores_empresa')
          .select('*')
          .eq('email_del_trabajador', user.email)
          .neq('permisos_del_trabajador', 'admin')
          .maybeSingle()
      ),

      // Verificar si es cliente
      queryWithTimeout(
        supabase
          .from('proyectos')
          .select('*')
          .eq('email_del_cliente', user.email)
          .maybeSingle()
      )
    ]);

    // Procesar resultados de las consultas
    if (adminQuery.status === 'fulfilled' && adminQuery.value.data) {
      console.log("Usuario encontrado como administrador:", adminQuery.value.data);
      const roleData = {
        role: 'admin',
        details: adminQuery.value.data
      };

      // Guardar para acceso rápido la próxima vez
      localStorage.setItem('userRole', roleData.role);
      localStorage.setItem('userDetails', JSON.stringify(roleData.details || {}));

      return roleData;
    }

    if (workerQuery.status === 'fulfilled' && workerQuery.value.data) {
      console.log("Usuario encontrado como trabajador:", workerQuery.value.data);
      const roleData = {
        role: 'worker',
        details: workerQuery.value.data
      };

      localStorage.setItem('userRole', roleData.role);
      localStorage.setItem('userDetails', JSON.stringify(roleData.details || {}));

      return roleData;
    }

    if (clientQuery.status === 'fulfilled' && clientQuery.value.data) {
      console.log("Usuario encontrado como cliente:", clientQuery.value.data);
      const clientData = clientQuery.value.data;
      const roleData = {
        role: 'client',
        details: {
          ...clientData,
          email: user.email,
          codigo_de_proyecto: clientData.codigo_de_proyecto,
          apellido_del_cliente: clientData.apellido_del_cliente,
          caja_proyecto: clientData.caja_proyecto
        }
      };

      localStorage.setItem('userRole', roleData.role);
      localStorage.setItem('userDetails', JSON.stringify(roleData.details || {}));

      return roleData;
    }

    // Si alguna consulta falló por timeout, hacer nuestra mejor suposición
    const hasTimeout = [adminQuery, workerQuery, clientQuery].some(
      query => query.status === 'rejected' && query.reason.message === "Query timed out"
    );

    if (hasTimeout) {
      console.warn("Una o más consultas excedieron el tiempo límite");
    }

    // Si el correo del usuario parece ser de un cliente, asumir rol de cliente
    if (user.email.includes('@gmail.com') ||
      user.email.includes('@hotmail.com') ||
      user.email.includes('@yahoo.com') ||
      user.email.includes('@outlook.com')) {
      console.log('El correo parece ser de un cliente, asumiendo rol de cliente');

      const roleData = {
        role: 'client',
        details: {
          email: user.email,
          apellido_del_cliente: user.email.split('@')[0],
          timeout: hasTimeout
        }
      };

      localStorage.setItem('userRole', roleData.role);
      localStorage.setItem('userDetails', JSON.stringify(roleData.details || {}));

      return roleData;
    }

    // Si no se encuentra en ninguna tabla, devolver un rol por defecto
    console.log('Usuario no encontrado en tablas de roles, usando guest');
    const guestData = {
      role: 'guest',
      details: {
        email: user.email
      }
    };

    localStorage.setItem('userRole', guestData.role);
    localStorage.setItem('userDetails', JSON.stringify(guestData.details || {}));

    return guestData;

  } catch (error) {
    console.error('Error al determinar el rol del usuario:', error);

    // Hacer una evaluación heurística basada en el email
    if (user.email.includes('@gmail.com') ||
      user.email.includes('@hotmail.com') ||
      user.email.includes('@yahoo.com') ||
      user.email.includes('@outlook.com')) {

      const fallbackData = {
        role: 'client',
        details: {
          email: user.email,
          fallback: true
        }
      };

      localStorage.setItem('userRole', fallbackData.role);
      localStorage.setItem('userDetails', JSON.stringify(fallbackData.details || {}));

      return fallbackData;
    }

    return {
      role: 'guest',
      details: {
        email: user.email,
        error: true
      }
    };
  } finally {
    console.timeEnd("getUserRole"); // Para ver cuánto tardó
  }
};

// Verificación en segundo plano para actualizar datos sin bloquear la UI
const verifyRoleInBackground = async (user) => {
  try {
    console.log("Verificando rol en segundo plano para usuario:", user.email);

    // Hacer consultas en paralelo
    const [adminQuery, workerQuery, clientQuery] = await Promise.allSettled([
      supabase
        .from('trabajadores_empresa')
        .select('*')
        .eq('email_del_trabajador', user.email)
        .eq('permisos_del_trabajador', 'admin')
        .maybeSingle(),

      supabase
        .from('trabajadores_empresa')
        .select('*')
        .eq('email_del_trabajador', user.email)
        .neq('permisos_del_trabajador', 'admin')
        .maybeSingle(),

      supabase
        .from('proyectos')
        .select('*')
        .eq('email_del_cliente', user.email)
        .maybeSingle()
    ]);

    let roleData = null;

    if (adminQuery.status === 'fulfilled' && adminQuery.value.data) {
      roleData = {
        role: 'admin',
        details: adminQuery.value.data
      };
    } else if (workerQuery.status === 'fulfilled' && workerQuery.value.data) {
      roleData = {
        role: 'worker',
        details: workerQuery.value.data
      };
    } else if (clientQuery.status === 'fulfilled' && clientQuery.value.data) {
      const clientData = clientQuery.value.data;
      roleData = {
        role: 'client',
        details: {
          ...clientData,
          email: user.email,
          codigo_de_proyecto: clientData.codigo_de_proyecto,
          apellido_del_cliente: clientData.apellido_del_cliente,
          caja_proyecto: clientData.caja_proyecto
        }
      };
    }

    if (roleData) {
      // Actualizar localStorage con los datos actualizados
      localStorage.setItem('userRole', roleData.role);
      localStorage.setItem('userDetails', JSON.stringify(roleData.details || {}));
      console.log("Rol actualizado en segundo plano:", roleData.role);
    }
  } catch (error) {
    console.error("Error en verificación de rol en segundo plano:", error);
  }
};