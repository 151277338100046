import React, { useEffect, useState, useRef, useCallback } from 'react';
import { supabase } from '../supabaseClient';
import axios from 'axios';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Typography,
  IconButton,
  Container,
  Paper,
  Tooltip,
  Chip,
  InputBase,
  InputAdornment,
  Alert,
  Snackbar,
  Divider,
  CircularProgress,
  FormControlLabel,
  Switch,
  Autocomplete,
  Card,
  CardContent,
  Fab,
  Badge,
  Drawer,
  SwipeableDrawer,
  AppBar,
  Toolbar,
  Slide,
  LinearProgress,
  Pagination,
  useTheme,
  Avatar,
  Stack,
  Stepper,
  Step,
  StepLabel,
  MobileStepper,
  useScrollTrigger,
  Zoom
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { es } from 'date-fns/locale';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import InfoIcon from '@mui/icons-material/Info';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import AssignmentIcon from '@mui/icons-material/Assignment';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import SaveIcon from '@mui/icons-material/Save';
import DescriptionIcon from '@mui/icons-material/Description';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import CalculateIcon from '@mui/icons-material/Calculate';
import SummarizeIcon from '@mui/icons-material/Summarize';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PersonIcon from '@mui/icons-material/Person';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { styled } from '@mui/material/styles';
import { format, parse } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import { uploadImage, formatImageUrl } from '../utils/imageUtils';
import { v4 as uuidv4 } from 'uuid';

// Estilos personalizados con enfoque en mobile
const MobileContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1, 1.5, 10, 1.5), // Padding extra abajo para el botón flotante
  maxWidth: '100%',
  backgroundColor: '#f8f9fa'
}));

const CobroCard = styled(Card)(({ theme }) => ({
  borderRadius: 20,
  marginBottom: theme.spacing(2),
  overflow: 'hidden',
  boxShadow: '0 3px 10px rgba(0, 0, 0, 0.08)',
  transition: 'all 0.3s ease',
  background: 'linear-gradient(to bottom, #ffffff, #fafafa)',
  '&:hover': {
    boxShadow: '0 6px 15px rgba(0, 0, 0, 0.1)',
    transform: 'translateY(-2px)'
  },
  '&:active': {
    transform: 'scale(0.98)',
    boxShadow: '0 1px 5px rgba(0, 0, 0, 0.1)',
  },
}));

const CardRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0.8, 0),
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const SearchBarContainer = styled(Box)(({ theme, disappear }) => ({
  display: 'flex',
  alignItems: 'stretch',
  marginBottom: theme.spacing(2),
  gap: theme.spacing(1),
  transition: 'opacity 0.3s ease, transform 0.3s ease',
  opacity: disappear ? 0 : 1,
  transform: disappear ? 'scale(0.95)' : 'scale(1)',
  pointerEvents: disappear ? 'none' : 'auto'
}));

const SearchBar = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: 24,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  flexGrow: 1
}));

const FilterButton = styled(Button)(({ theme }) => ({
  minWidth: 'auto',
  borderRadius: 20,
  padding: theme.spacing(1, 2),
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
  color: theme.palette.primary.main,
  border: '1px solid rgba(0, 0, 0, 0.12)'
}));

const FixedSearchContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 16,
  right: 16,
  display: 'flex',
  gap: theme.spacing(1),
  zIndex: 1201,
  transition: 'opacity 0.3s ease, transform 0.3s ease',
}));

const FloatingSearchButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 20,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
}));

const FloatingFilterButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'white',
  borderRadius: 20,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
}));

const AddFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: 110, // Aumentado para mejor visibilidad por encima de la barra de navegación
  right: 16,
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
  boxShadow: '0 6px 15px rgba(46, 125, 50, 0.4)',
  zIndex: 1000,
}));

// Campos de formulario mejorados
const StyledFormControl = styled(FormControl)(({ theme }) => ({
  marginBottom: theme.spacing(1.5), // Reducido de 2 a 1.5
  '& .MuiOutlinedInput-root': {
    borderRadius: 20,
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
    },
  },
  '& .MuiInputLabel-outlined': {
    backgroundColor: 'transparent',
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(1.5), // Reducido de 2 a 1.5
  '& .MuiOutlinedInput-root': {
    borderRadius: 20,
    '&:hover fieldset': {
      borderColor: theme.palette.primary.main,
    },
    '&.Mui-focused fieldset': {
      borderColor: theme.palette.primary.main,
      borderWidth: '1px',
    },
  },
  '& .MuiInputLabel-outlined': {
    backgroundColor: 'transparent',
    paddingLeft: 5,
    paddingRight: 5,
  },
}));

// Nuevo diálogo rediseñado para formularios
const FullWidthDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0)', // Fondo transparente inicial
    backdropFilter: 'blur(0px) brightness(100%)', // Sin blur ni cambio de brillo al inicio
    transition: 'backdrop-filter 0.6s cubic-bezier(0.4, 0, 0.2, 1)', // Curva suave y dinámica
    '&.MuiBackdrop-root.MuiModal-backdrop': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)', // Muy leve gris para dar profundidad
      backdropFilter: 'blur(12px) brightness(95%)', // Blur más pronunciado y leve oscurecimiento
    },
  },
  '& .MuiDialog-paper': {
    borderRadius: '30px 30px 0 0',
    margin: 0,
    width: '100%',
    maxWidth: '100%',
    maxHeight: '85vh',
    position: 'fixed',
    bottom: 0,
    overflowY: 'hidden',
    boxShadow: '0 0 0 rgba(0, 0, 0, 0)', // Sombra inicial nula
    backgroundImage: 'linear-gradient(to bottom, rgba(250, 250, 250, 0.95), rgba(255, 255, 255, 0.95))',
    transition: 'box-shadow 0.6s cubic-bezier(0.4, 0, 0.2, 1), transform 0.4s ease-out', // Sombra y leve desplazamiento
    '&:hover': {
      boxShadow: '0 -10px 30px rgba(0, 0, 0, 0.15)', // Sombra dinámica al abrir
    },
  }
}));

// Contenedor principal del formulario
const FormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '81vh',
  width: '100%',
  position: 'relative',
}));

// Header mejorado del formulario con indicador de arrastre
const FormHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(3, 3, 1, 3),
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
  backgroundColor: 'transparent',
  position: 'relative',
  '&::after': {
    content: '""',
    position: 'absolute',
    width: '50px',
    height: '4px',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '4px',
    top: '8px',
    left: '50%',
    transform: 'translateX(-50%)',
  }
}));

// Icono de cierre con animación
const CloseButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, 0.05)',
  color: theme.palette.text.secondary,
  borderRadius: 16,
  padding: 8,
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    transform: 'scale(1.05)',
  },
}));

// Pasos con animación
const StepButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 3, 2, 3),
  marginTop: theme.spacing(2.5), // Más espacio después del título
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
}));

// Contenido del formulario
const FormContent = styled(Box)(({ theme }) => ({
  overflowY: 'auto',
  flex: 1,
  padding: theme.spacing(3, 3, 5, 3),
  backgroundColor: '#f8f9fa', // Fondo igual al contenedor principal
  '&::-webkit-scrollbar': {
    width: '6px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
  },
}));

// Footer del formulario
const FormFooter = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 3),
  borderTop: '1px solid rgba(0, 0, 0, 0.06)',
  backgroundColor: 'rgba(255, 255, 255, 0.95)',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  boxShadow: '0 -2px 10px rgba(0, 0, 0, 0.05)',
}));

// Sección del formulario
const FormSection = styled(Box)(({ theme }) => ({
  backgroundColor: 'transparent',
  borderRadius: 24,
  marginBottom: theme.spacing(3),
}));

const FormSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(3),
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.primary.main,
  fontSize: '1.1rem',
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    fontSize: '1.4rem',
  },
}));

// Área de carga de archivos
const FileUploadBox = styled(Box)(({ theme }) => ({
  border: '2px dashed rgba(46, 125, 50, 0.3)',
  borderRadius: 24,
  padding: theme.spacing(4),
  textAlign: 'center',
  backgroundColor: 'rgba(46, 125, 50, 0.03)',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  marginBottom: theme.spacing(3),
  '&:hover': {
    borderColor: theme.palette.primary.main,
    backgroundColor: 'rgba(46, 125, 50, 0.05)',
    transform: 'translateY(-2px)',
  },
}));

// Botón de acción
const ActionButton = styled(Button)(({ theme }) => ({
  borderRadius: 28,
  textTransform: 'none',
  boxShadow: '0 3px 8px rgba(0, 0, 0, 0.1)',
  padding: theme.spacing(1.2, 3),
  fontWeight: 'bold',
  transition: 'all 0.2s ease',
  '&:hover': {
    transform: 'translateY(-2px)',
    boxShadow: '0 5px 12px rgba(0, 0, 0, 0.15)',
  }
}));

// Botones de navegación para los pasos
const StepButton = styled(Button)(({ theme, variant }) => ({
  borderRadius: 20,
  textTransform: 'none',
  padding: theme.spacing(1, 2),
  minWidth: variant === 'next' ? 120 : 100,
  boxShadow: variant === 'next' ? '0 3px 8px rgba(46, 125, 50, 0.2)' : 'none',
  backgroundColor: variant === 'next' ? theme.palette.primary.main : 'transparent',
  color: variant === 'next' ? '#fff' : theme.palette.text.secondary,
  border: variant === 'next' ? 'none' : '1px solid rgba(0, 0, 0, 0.12)',
  fontWeight: variant === 'next' ? 'bold' : 'normal',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: variant === 'next' ? theme.palette.primary.dark : 'rgba(0, 0, 0, 0.05)',
    transform: 'translateY(-2px)',
    boxShadow: variant === 'next' ? '0 4px 10px rgba(46, 125, 50, 0.3)' : '0 2px 5px rgba(0, 0, 0, 0.1)',
  },
}));

// Indicador de paso actual
const StepIndicator = styled(Box)(({ theme, active }) => ({
  width: 10,
  height: 10,
  borderRadius: '50%',
  backgroundColor: active ? theme.palette.primary.main : 'rgba(0, 0, 0, 0.12)',
  margin: '0 4px',
  transition: 'all 0.3s ease',
  transform: active ? 'scale(1.2)' : 'scale(1)',
}));

// Box para filtros
const SwipableFilterBox = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  borderRadius: '28px 28px 0 0',
  padding: theme.spacing(2.5), // Reducido de 3 a 2.5
  paddingBottom: theme.spacing(4), // Reducido de 5 a 4
  marginTop: 'auto',
  maxHeight: '75vh', // Aumentado un poco para dar más espacio
  overflow: 'auto',
}));

// Toggle switch para opciones
const SwitchFormControl = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: 0,
  marginRight: 0,
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: theme.spacing(1.5), // Reducido de 2 a 1.5
  '& .MuiSwitch-switchBase.Mui-checked': {
    color: theme.palette.primary.main,
  },
  '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
    backgroundColor: theme.palette.primary.main,
  },
}));

// Botón de guardar
const StyledSaveButton = styled(Button)(({ theme }) => ({
  borderRadius: 28,
  boxShadow: '0 4px 12px rgba(46, 125, 50, 0.3)',
  padding: theme.spacing(1.2, 4),
  textTransform: 'none',
  fontWeight: 'bold',
  fontSize: '1rem',
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
    boxShadow: '0 6px 16px rgba(46, 125, 50, 0.4)',
    transform: 'translateY(-2px)',
  }
}));

// Para la vista de detalle mejorada
const MobileDetailView = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0),
  backgroundColor: '#f8f9fa',
  height: '85vh',
  width: '100%',
  overflow: 'auto',
  borderRadius: '28px 28px 28px 28px',
  margin: '3vh 2vw',
  boxShadow: '0 5px 20px rgba(0, 0, 0, 0.15)',
}));

const DetailSection = styled(Paper)(({ theme }) => ({
  borderRadius: 24,
  marginBottom: theme.spacing(3),
  overflow: 'hidden',
  padding: theme.spacing(3),
  backgroundColor: '#fff',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
}));

const DetailSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  marginBottom: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  fontSize: '1.1rem',
  color: theme.palette.primary.main,
  '& .MuiSvgIcon-root': {
    marginRight: theme.spacing(1),
    fontSize: '1.3rem',
  },
}));

const DetailRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(1.2, 0),
  borderBottom: '1px solid rgba(0, 0, 0, 0.06)',
  '&:last-child': {
    borderBottom: 'none',
  },
}));

const DetailLabel = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  fontSize: '0.9rem',
}));

const DetailValue = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: '0.95rem',
  textAlign: 'right',
}));

const ImagePreviewBox = styled(Box)(({ theme }) => ({
  width: '100%',
  height: 200,
  borderRadius: 20,
  overflow: 'hidden',
  marginBottom: theme.spacing(3),
  backgroundColor: '#f5f5f5',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  border: '1px solid rgba(0, 0, 0, 0.1)',
}));

// Para los chips de documento
const DocChip = styled(Chip)(({ theme }) => ({
  borderRadius: 16,
  height: 36,
  padding: theme.spacing(0.5, 0.5),
  '& .MuiChip-label': {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  }
}));

// Panel de resumen financiero
const SummaryPanel = styled(Paper)(({ theme }) => ({
  borderRadius: 24,
  padding: theme.spacing(2.5),
  marginBottom: theme.spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgba(46, 125, 50, 0.04)',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.08)',
}));

const SummaryRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(1.2),
  '&:last-child': {
    marginBottom: 0,
  }
}));

// Selector de moneda mejorado y simplificado
const CurrencySelector = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 25,
  border: '1px solid #e0e0e0',
  overflow: 'hidden',
  width: 'fit-content',
  marginBottom: theme.spacing(2)
}));

const CurrencyButton = styled(Button)(({ theme, isActive }) => ({
  padding: '4px 16px',
  border: 'none',
  backgroundColor: isActive ? '#2e7d32' : '#f5f5f5',
  color: isActive ? 'white' : '#757575',
  fontWeight: isActive ? 'bold' : 'normal',
  '&:hover': {
    backgroundColor: isActive ? '#1b5e20' : '#e0e0e0',
  },
  borderRadius: 0,
  minWidth: 70,
}));

// Controladores de cambio para tipo de cambio
const TipoCambioControl = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(3),
  padding: theme.spacing(1),
  backgroundColor: 'transparent',
  borderRadius: 16,
}));

const TcStepButton = styled(IconButton)(({ theme, color = 'primary' }) => ({
  backgroundColor: color === 'primary' ? 'rgba(46, 125, 50, 0.1)' : 'rgba(211, 47, 47, 0.1)',
  color: color === 'primary' ? theme.palette.primary.main : theme.palette.error.main,
  borderRadius: 12,
  padding: theme.spacing(0.6),
  '&:hover': {
    backgroundColor: color === 'primary' ? 'rgba(46, 125, 50, 0.2)' : 'rgba(211, 47, 47, 0.2)',
    transform: 'scale(1.05)',
  }
}));

// Animaciones para las transiciones
const pageVariants = {
  initial: {
    opacity: 0,
    y: 20
  },
  in: {
    opacity: 1,
    y: 0
  },
  out: {
    opacity: 0,
    y: -20
  }
};

const pageTransition = {
  type: 'spring',
  stiffness: 300,
  damping: 30
};

// Componente de vista previa de imagen
const ImagePreview = ({
  src,
  alt = 'Imagen',
  onClick,
  width = '100%',
  height = '100%',
  showZoom = true,
  thumbSize = 'medium' // 'small', 'medium', 'large'
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  // Size mapping
  const sizes = {
    small: { width: 40, height: 40, iconSize: 20 },
    medium: { width: 80, height: 80, iconSize: 36 },
    large: { width: 120, height: 120, iconSize: 48 }
  };

  const size = sizes[thumbSize] || sizes.medium;

  useEffect(() => {
    if (!src) {
      setError(true);
      return;
    }

    setLoaded(false);
    setError(false);

    const img = new Image();

    img.onload = () => {
      setLoaded(true);
      setError(false);
    };

    img.onerror = () => {
      // Try direct URL without cache params
      if (src.includes('?')) {
        const directUrl = src.split('?')[0];

        const directImg = new Image();
        directImg.onload = () => {
          setLoaded(true);
          setError(false);
        };
        directImg.onerror = () => {
          setError(true);
          setLoaded(false);
        };
        directImg.src = directUrl;
      } else {
        setError(true);
        setLoaded(false);
      }
    };

    img.src = src;

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src, retryCount]);

  const handleRetry = (e) => {
    e.stopPropagation();
    setRetryCount(prev => prev + 1);
  };

  if (error) {
    return (
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      >
        <Box
          sx={{
            width: width !== '100%' ? width : size.width,
            height: height !== '100%' ? height : size.height,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f5f5f5',
            borderRadius: 1,
            border: '1px solid #e0e0e0',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <ReceiptIcon sx={{ color: '#9e9e9e', fontSize: size.iconSize }} />
          <Typography variant="caption" sx={{ fontSize: '10px', textAlign: 'center', mt: 0.5 }}>
            Error
          </Typography>

          <Tooltip title="Reintentar cargar imagen">
            <IconButton
              size="small"
              onClick={handleRetry}
              sx={{
                position: 'absolute',
                top: 2,
                right: 2,
                background: 'rgba(255,255,255,0.8)',
                width: 16,
                height: 16
              }}
            >
              <RefreshIcon sx={{ fontSize: 12 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </motion.div>
    );
  }

  if (!loaded) {
    return (
      <Box
        sx={{
          width: width !== '100%' ? width : size.width,
          height: height !== '100%' ? height : size.height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f5f5f5',
          borderRadius: 1,
          border: '1px solid #e0e0e0'
        }}
      >
        <CircularProgress size={size.iconSize / 2} sx={{ color: '#2e7d32' }} />
      </Box>
    );
  }

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      style={{ cursor: 'pointer', position: 'relative' }}
    >
      <Box
        sx={{
          width: width !== '100%' ? width : size.width,
          height: height !== '100%' ? height : size.height,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 1,
          border: '1px solid #e0e0e0'
        }}
        onClick={onClick}
      >
        <Box
          component="img"
          src={src}
          alt={alt}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 0.3s ease',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        />

        {showZoom && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 4,
              right: 4,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 24,
              height: 24,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              }
            }}
          >
            <ZoomInIcon sx={{ fontSize: 16, color: '#2e7d32' }} />
          </Box>
        )}
      </Box>
    </motion.div>
  );
};

// Componente principal
const MobileCobroTrabajadores = () => {
  const theme = useTheme();
  const [cobros, setCobros] = useState([]);
  const [filteredCobros, setFilteredCobros] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openDetailView, setOpenDetailView] = useState(false);
  const [openFiltersDrawer, setOpenFiltersDrawer] = useState(false);
  const [selectedCobro, setSelectedCobro] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [editMode, setEditMode] = useState(false);
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const itemsPerPage = 25;

  // Estados para la animación de eliminación y diálogo de confirmación
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteAnimation, setDeleteAnimation] = useState(false);

  // Estado para gestionar cuándo mostrar los botones flotantes
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  // Estados para los diálogos flotantes
  const [searchDialogOpen, setSearchDialogOpen] = useState(false);

  // Sumario de montos filtrados
  const [summaryVisible, setSummaryVisible] = useState(false);
  const [summaryData, setSummaryData] = useState({
    totalARS: 0,
    totalUSD: 0,
    totalARSEquivalente: 0,
    totalUSDEquivalente: 0
  });

  // FORM STATES
  const [trabajadores, setTrabajadores] = useState([]);
  const [cajas, setCajas] = useState([]);
  const [nombreFacturas, setNombresFacturas] = useState([]);
  const [empresa, setEmpresa] = useState(null);

  // COBRO FORM DATA
  const [fechaCobro, setFechaCobro] = useState(new Date());
  const [cajaOrigen, setCajaOrigen] = useState('');
  const [trabajadorCobro, setTrabajadorCobro] = useState('');
  const [nombreFacturador, setNombreFacturador] = useState('');
  const [descripcion, setDescripcion] = useState('');
  const [tipoCambio, setTipoCambio] = useState('');
  const [moneda, setMoneda] = useState('ARS');
  const [monto, setMonto] = useState('');
  const [montoARS, setMontoARS] = useState(0);
  const [montoUSD, setMontoUSD] = useState(0);

  // COMPROBANTE STATES
  const [tieneComprobante, setTieneComprobante] = useState(false);
  const [comprobanteImagen, setComprobanteImagen] = useState(null);
  const [comprobantePreview, setComprobantePreview] = useState('');
  const [codigoComprobante, setCodigoComprobante] = useState('');
  const comprobanteInputRef = useRef(null);

  // FACTURA STATES
  const [tieneFactura, setTieneFactura] = useState(false);
  const [facturaImagen, setFacturaImagen] = useState(null);
  const [facturaPreview, setFacturaPreview] = useState('');
  const [codigoFactura, setCodigoFactura] = useState('');
  const [facturaANombreDe, setFacturaANombreDe] = useState('');
  const [nuevoNombreFactura, setNuevoNombreFactura] = useState('');
  const [addingNewNombre, setAddingNewNombre] = useState(false);
  const facturaInputRef = useRef(null);

  // FILTERS STATES
  const [filterTrabajador, setFilterTrabajador] = useState('');
  const [filterCaja, setFilterCaja] = useState('');
  const [filterMoneda, setFilterMoneda] = useState('');
  const [filterFechaDesde, setFilterFechaDesde] = useState(null);
  const [filterFechaHasta, setFilterFechaHasta] = useState(null);
  const [filterTieneComprobante, setFilterTieneComprobante] = useState(null);
  const [filterTieneFactura, setFilterTieneFactura] = useState(null);
  const [activeFilters, setActiveFilters] = useState([]);

  // NOTIFICATION STATES
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // FORM SECTIONS
  const [currentFormSection, setCurrentFormSection] = useState(0);
  const formSections = [
    { title: 'Información Básica', icon: <InfoIcon /> },
    { title: 'Información Financiera', icon: <AttachMoneyIcon /> },
    { title: 'Documentación', icon: <DescriptionIcon /> }];

  // Referencia para el swipe en el diálogo de form
  const formDialogRef = useRef(null);

  // EFFECTS
  useEffect(() => {
    fetchDolarBlue();
    fetchCobros();
    fetchTrabajadores();
    fetchCajas();
    fetchNombresFacturas();
    fetchEmpresa();
  }, []);

  useEffect(() => {
    applyFilters();
    calculateSummary();
  }, [cobros, searchTerm, activeFilters]);

  // FETCH FUNCTIONS
  const fetchDolarBlue = useCallback(async () => {
    try {
      const response = await axios.get('https://dolarapi.com/v1/dolares/blue');
      const tipoDeCambio = response.data.compra;
      setTipoCambio(tipoDeCambio.toString());
    } catch (error) {
      console.error('Error fetching dolar blue:', error);
      setTipoCambio('1050'); // Valor por defecto
    }
  }, []); const fetchCobros = async () => {
    setLoading(true);
    try {
      const { data, error } = await supabase
        .from('cobro_trabajadores')
        .select('*')
        .order('fecha', { ascending: false });

      if (error) throw error;

      // Formatear fechas y ordenar por monto (mayor a menor)
      const formattedData = data.map(item => ({
        ...item,
        fecha_formateada: item.fecha ? format(new Date(item.fecha), 'dd/MM/yyyy') : '',
        monto_formateado: formatCurrency(item.monto_cobro, item.moneda_cobro),
        imagen_comprobante_formateada: item.imagen_comprobante_cobro ? formatImageUrl(item.imagen_comprobante_cobro) : null,
        imagen_factura_formateada: item.imagen_factura_cobro ? formatImageUrl(item.imagen_factura_cobro) : null
      }));

      setCobros(formattedData || []);
      setFilteredCobros(formattedData || []);
    } catch (error) {
      console.error('Error fetchCobros:', error);
      setSnackbar({
        open: true,
        message: 'Error al cargar los cobros',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchTrabajadores = async () => {
    try {
      const { data, error } = await supabase.from('trabajadores_empresa').select('*');
      if (error) throw error;
      setTrabajadores(data || []);
    } catch (error) {
      console.error('Error fetchTrabajadores:', error);
    }
  };

  const fetchCajas = async () => {
    try {
      const { data, error } = await supabase.from('cajas_cambios').select('caja');
      if (error) throw error;
      setCajas(data || []);
    } catch (error) {
      console.error('Error fetchCajas:', error);
    }
  };

  const fetchNombresFacturas = async () => {
    try {
      const { data, error } = await supabase.from('facturas_a_nombre_de_salidas').select('nombre_factura');
      if (error) throw error;
      setNombresFacturas(data?.map(item => item.nombre_factura) || []);
    } catch (error) {
      console.error('Error fetchNombresFacturas:', error);
    }
  };

  const fetchEmpresa = async () => {
    try {
      const { data, error } = await supabase.from('empresa').select('*').limit(1);
      if (error) throw error;
      if (data && data.length > 0) {
        setEmpresa(data[0]);
        // Establecer valor predeterminado para caja origen desde la empresa
        setCajaOrigen(data[0].caja_empresa || '');
        setFacturaANombreDe(data[0].nombre_empresa || '');
      }
    } catch (error) {
      console.error('Error fetchEmpresa:', error);
    }
  };

  // UTILITY FUNCTIONS
  const formatCurrency = (amount, currency) => {
    if (!amount) return '';
    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currency === 'USD' ? 'USD' : 'ARS',
      minimumFractionDigits: 2
    });
    return formatter.format(amount);
  };

  // Generate unique code for comprobante or factura
  const generateUniqueCode = (type, trabajador) => {
    const date = format(fechaCobro || new Date(), 'yyyyMMdd');
    const prefix = type === 'comprobante' ? 'CT-COM-' : 'CT-FAC-';
    const randomId = uuidv4().substring(0, 6); // Get first 6 chars from UUID

    // Create a sanitized filename without special characters or spaces
    const sanitizedTrabajador = trabajador ?
      trabajador.replace(/[^a-zA-Z0-9]/g, '_').substring(0, 20) : 'Unknown';

    return `${prefix}${date}-${sanitizedTrabajador}-${randomId}`;
  };

  // Calcular el sumario de montos
  const calculateSummary = () => {
    let totalARS = 0;
    let totalUSD = 0;
    let totalARSEquivalente = 0;
    let totalUSDEquivalente = 0;

    filteredCobros.forEach(cobro => {
      if (cobro.moneda_cobro === 'ARS') {
        totalARS += parseFloat(cobro.monto_cobro || 0);
        // También sumamos al equivalente en USD
        const tc = parseFloat(cobro.tipo_de_cambio_cobro || 0);
        if (tc > 0) {
          totalUSDEquivalente += parseFloat(cobro.monto_cobro || 0) / tc;
        }
        // Sumamos al equivalente en ARS (que es el mismo valor)
        totalARSEquivalente += parseFloat(cobro.monto_cobro || 0);
      } else if (cobro.moneda_cobro === 'USD') {
        totalUSD += parseFloat(cobro.monto_cobro || 0);
        // También sumamos al ARS el equivalente
        totalARSEquivalente += parseFloat(cobro.monto_cobro || 0) * parseFloat(cobro.tipo_de_cambio_cobro || 0);
        // Sumamos al equivalente en USD (que es el mismo valor)
        totalUSDEquivalente += parseFloat(cobro.monto_cobro || 0);
      }
    });

    setSummaryData({
      totalARS: totalARS.toFixed(2),
      totalUSD: totalUSD.toFixed(2),
      totalARSEquivalente: totalARSEquivalente.toFixed(2),
      totalUSDEquivalente: totalUSDEquivalente.toFixed(2)
    });

    // Mostrar el sumario solo si hay filtros activos o búsqueda
    setSummaryVisible(activeFilters.length > 0 || searchTerm !== '');
  };

  // Función para ajustar el tipo de cambio
  const adjustTipoCambio = (amount) => {
    const currentValue = parseFloat(tipoCambio) || 0;
    const newValue = Math.max(0, currentValue + amount);
    setTipoCambio(newValue.toString());

    // Actualizar montos equivalentes
    updateEquivalentAmounts(monto, moneda, newValue);
  };

  // Actualiza los montos equivalentes en USD y ARS
  const updateEquivalentAmounts = (montoValue, monedaValue, tipoCambioValue) => {
    const montoNum = parseFloat(montoValue) || 0;
    const tipoCambioNum = parseFloat(tipoCambioValue) || 0;

    if (tipoCambioNum === 0) {
      setMontoARS(0);
      setMontoUSD(0);
      return;
    }

    if (monedaValue === 'ARS') {
      setMontoARS(montoNum);
      setMontoUSD(montoNum / tipoCambioNum);
    } else {
      setMontoARS(montoNum * tipoCambioNum);
      setMontoUSD(montoNum);
    }
  };

  const handleAgregarNuevoNombre = async () => {
    if (!nuevoNombreFactura.trim()) {
      setSnackbar({
        open: true,
        message: 'Por favor, ingrese un nombre válido',
        severity: 'error'
      });
      return;
    }

    try {
      const { error } = await supabase.from('facturas_a_nombre_de_salidas').insert([
        { nombre_factura: nuevoNombreFactura.trim() }
      ]);

      if (error) {
        throw error;
      }

      fetchNombresFacturas();
      setFacturaANombreDe(nuevoNombreFactura.trim());
      setNuevoNombreFactura('');
      setAddingNewNombre(false);

      setSnackbar({
        open: true,
        message: 'Nombre agregado con éxito',
        severity: 'success'
      });
    } catch (error) {
      console.error('Error al agregar nuevo nombre:', error.message);
      setSnackbar({
        open: true,
        message: 'Error al agregar nuevo nombre: ' + error.message,
        severity: 'error'
      });
    }
  };

  const applyFilters = () => {
    let filtered = [...cobros];

    // Búsqueda global
    if (searchTerm) {
      filtered = filtered.filter(cobro =>
        Object.values(cobro).some(value =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Filtrar por trabajador
    if (filterTrabajador) {
      filtered = filtered.filter(cobro => cobro.trabajador_cobro === filterTrabajador);
    }

    // Filtrar por caja
    if (filterCaja) {
      filtered = filtered.filter(cobro => cobro.caja_origen_cobro === filterCaja);
    }

    // Filtrar por moneda
    if (filterMoneda) {
      filtered = filtered.filter(cobro => cobro.moneda_cobro === filterMoneda);
    }

    // Filtrar por fecha desde
    if (filterFechaDesde) {
      filtered = filtered.filter(cobro =>
        new Date(cobro.fecha) >= new Date(filterFechaDesde)
      );
    }

    // Filtrar por fecha hasta
    if (filterFechaHasta) {
      filtered = filtered.filter(cobro =>
        new Date(cobro.fecha) <= new Date(filterFechaHasta)
      );
    }

    // Filtrar por tiene comprobante
    if (filterTieneComprobante !== null) {
      filtered = filtered.filter(cobro => cobro.tiene_comprobante_cobro === filterTieneComprobante);
    }

    // Filtrar por tiene factura
    if (filterTieneFactura !== null) {
      filtered = filtered.filter(cobro => cobro.tiene_factura_cobro === filterTieneFactura);
    }

    setFilteredCobros(filtered);
  }; const resetFilters = () => {
    setFilterTrabajador('');
    setFilterCaja('');
    setFilterMoneda('');
    setFilterFechaDesde(null);
    setFilterFechaHasta(null);
    setFilterTieneComprobante(null);
    setFilterTieneFactura(null);
    setActiveFilters([]);
    setSearchTerm('');
    setSummaryVisible(false);
  };

  const applyFilterSelection = () => {
    // Crear array de filtros activos para mostrar badges
    const newFilters = [];

    if (filterTrabajador) {
      newFilters.push({ type: 'trabajador', value: filterTrabajador });
    }

    if (filterCaja) {
      newFilters.push({ type: 'caja', value: filterCaja });
    }

    if (filterMoneda) {
      newFilters.push({ type: 'moneda', value: filterMoneda });
    }

    if (filterFechaDesde) {
      newFilters.push({ type: 'fechaDesde', value: format(new Date(filterFechaDesde), 'dd/MM/yy') });
    }

    if (filterFechaHasta) {
      newFilters.push({ type: 'fechaHasta', value: format(new Date(filterFechaHasta), 'dd/MM/yy') });
    }

    if (filterTieneComprobante !== null) {
      newFilters.push({
        type: 'tieneComprobante',
        value: filterTieneComprobante ? 'Con comprobante' : 'Sin comprobante'
      });
    }

    if (filterTieneFactura !== null) {
      newFilters.push({
        type: 'tieneFactura',
        value: filterTieneFactura ? 'Con factura' : 'Sin factura'
      });
    }

    setActiveFilters(newFilters);
    setOpenFiltersDrawer(false);
    applyFilters();
    calculateSummary();
  };

  // Función para resetear formulario
  const resetForm = () => {
    setFechaCobro(new Date());
    setCajaOrigen(empresa?.caja_empresa || '');
    setTrabajadorCobro('');
    setNombreFacturador('');
    setDescripcion('');
    setTipoCambio(tipoCambio);  // Mantener el tipo de cambio actual
    setMoneda('ARS');
    setMonto('');
    setMontoARS(0);
    setMontoUSD(0);

    // Resetear estados de comprobante
    setTieneComprobante(false);
    setComprobanteImagen(null);
    setComprobantePreview('');
    setCodigoComprobante('');
    if (comprobanteInputRef.current) {
      comprobanteInputRef.current.value = '';
    }

    // Resetear estados de factura
    setTieneFactura(false);
    setFacturaImagen(null);
    setFacturaPreview('');
    setCodigoFactura('');
    setFacturaANombreDe(empresa?.nombre_empresa || '');
    setNuevoNombreFactura('');
    setAddingNewNombre(false);
    if (facturaInputRef.current) {
      facturaInputRef.current.value = '';
    }
  };

  // FORM HANDLERS
  const handleOpenAddDialog = () => {
    setEditMode(false);
    fetchDolarBlue(); // Actualizar tipo de cambio
    resetForm();
    setCurrentFormSection(0);
    setOpenAddDialog(true);
  };

  const handleOpenEditDialog = (cobro) => {
    setEditMode(true);
    setSelectedCobro(cobro);
    populateFormWithCobro(cobro);
    setCurrentFormSection(0);
    setOpenAddDialog(true);
  };

  const populateFormWithCobro = (cobro) => {
    // Datos básicos
    setFechaCobro(new Date(cobro.fecha));
    setCajaOrigen(cobro.caja_origen_cobro || '');
    setTrabajadorCobro(cobro.trabajador_cobro || '');
    setNombreFacturador(cobro.nombre_facturador_cobro || cobro.trabajador_cobro || '');
    setDescripcion(cobro.descripción_cobro || '');
    setTipoCambio(cobro.tipo_de_cambio_cobro?.toString() || '');
    setMoneda(cobro.moneda_cobro || 'ARS');
    setMonto(cobro.monto_cobro?.toString() || '');
    setMontoARS(cobro.monto_en_ars_cobro || 0);
    setMontoUSD(cobro.monto_en_usd_cobro || 0);

    // Comprobante
    setTieneComprobante(cobro.tiene_comprobante_cobro || false);
    setComprobantePreview(cobro.imagen_comprobante_cobro ? formatImageUrl(cobro.imagen_comprobante_cobro) : '');
    setCodigoComprobante(cobro.codigo_de_comprobante_cobro || '');

    // Factura
    setTieneFactura(cobro.tiene_factura_cobro || false);
    setFacturaPreview(cobro.imagen_factura_cobro ? formatImageUrl(cobro.imagen_factura_cobro) : '');
    setCodigoFactura(cobro.codigo_de_factura_cobro || '');
    setFacturaANombreDe(cobro.factura_a_nombre_de_cobro || '');
  };

  const handleComprobanteChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificar tipo de archivo
      if (!file.type.match('image.*')) {
        setSnackbar({
          open: true,
          message: 'Por favor, selecciona un archivo de imagen válido',
          severity: 'error'
        });
        return;
      }

      // Verificar tamaño (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setSnackbar({
          open: true,
          message: 'La imagen debe ser menor a 5MB',
          severity: 'error'
        });
        return;
      }

      setComprobanteImagen(file);
      setTieneComprobante(true);
      setCodigoComprobante(generateUniqueCode('comprobante', trabajadorCobro));

      // Crear URL temporal para vista previa
      const previewURL = URL.createObjectURL(file);
      setComprobantePreview(previewURL);
    }
  };

  const handleFacturaChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Verificar tipo de archivo
      if (!file.type.match('image.*')) {
        setSnackbar({
          open: true,
          message: 'Por favor, selecciona un archivo de imagen válido',
          severity: 'error'
        });
        return;
      }

      // Verificar tamaño (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setSnackbar({
          open: true,
          message: 'La imagen debe ser menor a 5MB',
          severity: 'error'
        });
        return;
      }

      setFacturaImagen(file);
      setTieneFactura(true);
      setCodigoFactura(generateUniqueCode('factura', trabajadorCobro));

      // Crear URL temporal para vista previa
      const previewURL = URL.createObjectURL(file);
      setFacturaPreview(previewURL);
    }
  };

  const handleComprobanteUpload = () => {
    comprobanteInputRef.current.click();
  };

  const handleFacturaUpload = () => {
    facturaInputRef.current.click();
  };

  const removeComprobante = () => {
    setComprobanteImagen(null);
    setComprobantePreview('');
    setCodigoComprobante('');
    setTieneComprobante(false);

    // Limpiar el input de archivo
    if (comprobanteInputRef.current) {
      comprobanteInputRef.current.value = '';
    }
  };

  const removeFactura = () => {
    setFacturaImagen(null);
    setFacturaPreview('');
    setCodigoFactura('');
    setTieneFactura(false);

    // Limpiar el input de archivo
    if (facturaInputRef.current) {
      facturaInputRef.current.value = '';
    }
  };

  const handleMonedaChange = (newMoneda) => {
    setMoneda(newMoneda);
    updateEquivalentAmounts(monto, newMoneda, tipoCambio);
  };

  const handleMontoChange = (e) => {
    const { value } = e.target;
    // Permitir solo dígitos y punto decimal
    const cleanValue = value.replace(/[^\d.]/g, '');

    // Asegurar solo un punto decimal
    const parts = cleanValue.split('.');
    let finalValue = parts[0] || '';

    if (parts.length > 1) {
      finalValue += '.' + parts.slice(1).join('');
    }

    setMonto(finalValue);
    updateEquivalentAmounts(finalValue, moneda, tipoCambio);
  };
  const handleSubmitForm = async () => {
    // Validar campos obligatorios
    if (!fechaCobro || !cajaOrigen || !trabajadorCobro || !tipoCambio || !moneda || !monto) {
      setSnackbar({
        open: true,
        message: 'Por favor, completa todos los campos obligatorios',
        severity: 'error'
      });
      return;
    }

    if (parseFloat(tipoCambio) === 0) {
      setSnackbar({
        open: true,
        message: 'El tipo de cambio no puede ser cero',
        severity: 'error'
      });
      return;
    }

    // Validaciones adicionales para documentos
    if (tieneFactura && !facturaImagen && !facturaPreview) {
      setSnackbar({
        open: true,
        message: 'Por favor, sube una imagen de la factura',
        severity: 'error'
      });
      return;
    }

    if (tieneComprobante && !comprobanteImagen && !comprobantePreview) {
      setSnackbar({
        open: true,
        message: 'Por favor, sube una imagen del comprobante',
        severity: 'error'
      });
      return;
    }

    try {
      setLoading(true);

      let cobroId = editMode ? selectedCobro.id : null;
      let imageDataComprobante = null;
      let imageDataFactura = null;

      // Si estamos en edición y ya hay imágenes previas, mantenerlas
      let imagenComprobanteExistente = editMode ? selectedCobro.imagen_comprobante_cobro : null;
      let imagenFacturaExistente = editMode ? selectedCobro.imagen_factura_cobro : null;
      let codigoComprobanteExistente = editMode ? selectedCobro.codigo_de_comprobante_cobro : null;
      let codigoFacturaExistente = editMode ? selectedCobro.codigo_de_factura_cobro : null;

      // Subir comprobante si existe una nueva imagen
      if (tieneComprobante && comprobanteImagen) {
        try {
          // Generate a clean filename without special characters
          const sanitizedTrabajador = trabajadorCobro
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') // Remove accents
            .replace(/[^\w\s]/gi, '')  // Remove special chars
            .replace(/\s+/g, '_');     // Replace spaces

          const fecha = format(new Date(), 'yyyyMMdd');
          const fileId = uuidv4().substring(0, 8);
          const fileExtension = comprobanteImagen.name.split('.').pop();

          const customFilename = `${fecha}_comp_${sanitizedTrabajador}_${fileId}.${fileExtension}`;

          imageDataComprobante = await uploadImage(comprobanteImagen, {
            tipo: 'cobro_trabajador_comprobante',
            referencia: sanitizedTrabajador,
            codigo: codigoComprobante || generateUniqueCode('comprobante', sanitizedTrabajador),
            customFilename: customFilename
          });
        } catch (uploadError) {
          throw new Error('Error al subir el comprobante: ' + uploadError.message);
        }
      }

      // Subir factura si existe una nueva imagen
      if (tieneFactura && facturaImagen) {
        try {
          // Similar sanitización para la factura
          const sanitizedTrabajador = trabajadorCobro
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[^\w\s]/gi, '')
            .replace(/\s+/g, '_');

          const fecha = format(new Date(), 'yyyyMMdd');
          const fileId = uuidv4().substring(0, 8);
          const fileExtension = facturaImagen.name.split('.').pop();

          const customFilename = `${fecha}_fact_${sanitizedTrabajador}_${fileId}.${fileExtension}`;

          imageDataFactura = await uploadImage(facturaImagen, {
            tipo: 'cobro_trabajador_factura',
            referencia: sanitizedTrabajador,
            codigo: codigoFactura || generateUniqueCode('factura', sanitizedTrabajador),
            customFilename: customFilename
          });
        } catch (uploadError) {
          throw new Error('Error al subir la factura: ' + uploadError.message);
        }
      }

      // Preparar datos para guardar
      const cobroData = {
        fecha: format(fechaCobro, 'yyyy-MM-dd'),
        caja_origen_cobro: cajaOrigen,
        trabajador_cobro: trabajadorCobro,
        nombre_facturador_cobro: nombreFacturador || trabajadorCobro,
        moneda_cobro: moneda,
        tipo_de_cambio_cobro: parseFloat(tipoCambio),
        monto_cobro: parseFloat(monto),
        descripción_cobro: descripcion,
        monto_en_ars_cobro: montoARS,
        monto_en_usd_cobro: montoUSD,

        // Datos de comprobante
        tiene_comprobante_cobro: tieneComprobante,
        imagen_comprobante_cobro: tieneComprobante
          ? (imageDataComprobante ? imageDataComprobante.url : imagenComprobanteExistente)
          : null,
        codigo_de_comprobante_cobro: tieneComprobante
          ? (codigoComprobante || codigoComprobanteExistente)
          : null,

        // Datos de factura
        tiene_factura_cobro: tieneFactura,
        imagen_factura_cobro: tieneFactura
          ? (imageDataFactura ? imageDataFactura.url : imagenFacturaExistente)
          : null,
        codigo_de_factura_cobro: tieneFactura
          ? (codigoFactura || codigoFacturaExistente)
          : null,
        factura_a_nombre_de_cobro: tieneFactura ? facturaANombreDe : null,
      };

      // Insertar o actualizar en la base de datos
      let response;
      if (editMode && cobroId) {
        response = await supabase
          .from('cobro_trabajadores')
          .update(cobroData)
          .eq('id', cobroId);
      } else {
        response = await supabase
          .from('cobro_trabajadores')
          .insert([cobroData]);
      }

      if (response.error) {
        throw new Error(response.error.message);
      }

      // Actualizar o insertar en comprobantes/facturas para referencias cruzadas
      if (tieneComprobante && (imageDataComprobante || imagenComprobanteExistente)) {
        const comprobanteCode = codigoComprobante || codigoComprobanteExistente;
        await supabase.from('comprobantes')
          .upsert([{
            tipo_comprobante: 'cobro_trabajador',
            codigo_comprobante: comprobanteCode,
            fecha_comprobante: format(fechaCobro, 'yyyy-MM-dd'),
            entidad_asociada: trabajadorCobro,
            url_imagen: imageDataComprobante ? imageDataComprobante.url : imagenComprobanteExistente,
            monto_comprobante: parseFloat(monto),
            moneda_comprobante: moneda
          }]);
      }

      if (tieneFactura && (imageDataFactura || imagenFacturaExistente)) {
        const facturaCode = codigoFactura || codigoFacturaExistente;
        await supabase.from('facturas')
          .upsert([{
            tipo_factura: 'cobro_trabajador',
            codigo_factura: facturaCode,
            fecha_factura: format(fechaCobro, 'yyyy-MM-dd'),
            entidad_asociada: trabajadorCobro,
            a_nombre_de: facturaANombreDe,
            url_imagen: imageDataFactura ? imageDataFactura.url : imagenFacturaExistente,
            monto_factura: parseFloat(monto),
            moneda_factura: moneda
          }]);
      }

      // Recargar datos
      fetchCobros();

      setSnackbar({
        open: true,
        message: editMode ? 'Cobro actualizado con éxito' : 'Cobro agregado con éxito',
        severity: 'success'
      });

      setOpenAddDialog(false);
      resetForm();

    } catch (error) {
      console.error('Error al guardar cobro:', error);
      setSnackbar({
        open: true,
        message: error.message || "Error al procesar la solicitud",
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };// Función para animar la eliminación
  const animateItemDelete = () => {
    setDeleteAnimation(true);

    // Establecer referencia al elemento de la vista detallada si existe
    if (openDetailView && selectedCobro) {
      const detailElement = document.querySelector('.mobile-detail-view');
      if (detailElement) {
        detailElement.style.transition = 'all 0.5s ease';
        detailElement.style.transform = 'scale(0.8)';
        detailElement.style.opacity = '0';
      }
    }

    // Esperar a que termine la animación antes de ejecutar la eliminación
    setTimeout(() => {
      if (selectedCobro) {
        handleDeleteCobro(selectedCobro);
      }
      setDeleteAnimation(false);
    }, 300);
  };

  const handleDeleteCobro = async (cobro, event) => {
    if (event) {
      event.stopPropagation();
    }

    try {
      setLoading(true);

      // Primero obtener el registro actual para saber si necesitamos eliminar archivos
      const { data: currentCobro, error: fetchError } = await supabase
        .from('cobro_trabajadores')
        .select('*')
        .eq('id', cobro.id)
        .single();

      if (fetchError) {
        console.error('Error fetching cobro for deletion:', fetchError);
        throw new Error('Error al obtener información del cobro');
      }

      // Eliminar registro
      const { error } = await supabase
        .from('cobro_trabajadores')
        .delete()
        .eq('id', cobro.id);

      if (error) {
        throw error;
      }

      // Si tiene código de comprobante, intentar eliminar de la colección comprobantes
      if (currentCobro.codigo_de_comprobante_cobro) {
        await supabase.from('comprobantes')
          .delete()
          .eq('codigo_comprobante', currentCobro.codigo_de_comprobante_cobro);
      }

      // Si tiene código de factura, intentar eliminar de la colección facturas
      if (currentCobro.codigo_de_factura_cobro) {
        await supabase.from('facturas')
          .delete()
          .eq('codigo_factura', currentCobro.codigo_de_factura_cobro);
      }

      setSnackbar({
        open: true,
        message: 'Cobro eliminado con éxito',
        severity: 'success'
      });

      fetchCobros();

      // Cerrar vista de detalle si está abierta
      if (openDetailView && selectedCobro && selectedCobro.id === cobro.id) {
        setOpenDetailView(false);
      }

      // Cerrar diálogo de confirmación
      setDeleteConfirmOpen(false);

    } catch (error) {
      console.error('Error al eliminar cobro:', error);
      setSnackbar({
        open: true,
        message: 'Error al eliminar cobro: ' + error.message,
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // VIEW HANDLERS
  const handleOpenDetailView = (cobro) => {
    setSelectedCobro(cobro);
    setOpenDetailView(true);
  };

  const handleOpenImagePreview = (imageUrl, event) => {
    if (event) {
      event.stopPropagation();
    }
    setCurrentImage(imageUrl);
    setImagePreviewOpen(true);
  };

  const handleNextFormSection = () => {
    if (currentFormSection < formSections.length - 1) {
      setCurrentFormSection(prevSection => prevSection + 1);
    }
  };

  const handlePrevFormSection = () => {
    if (currentFormSection > 0) {
      setCurrentFormSection(prevSection => prevSection - 1);
    }
  };

  // Handle swipe down to close dialog
  const handleFormDialogSwipe = (e) => {
    if (e.target.scrollTop === 0 && e.deltaY < -50) {
      setOpenAddDialog(false);
    }
  };

  // Función para manejar la apertura del diálogo de búsqueda flotante
  const handleSearchDialogOpen = () => {
    setSearchDialogOpen(true);
  };

  // Contenido del formulario según la sección actual - REDISEÑADO
  const renderFormContent = () => {
    switch (currentFormSection) {
      case 0: // Información Básica
        return (
          <Box>
            <FormSectionTitle>
              <InfoIcon /> Información Básica
            </FormSectionTitle>

            <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
              <DatePicker
                label="Fecha"
                value={fechaCobro}
                onChange={(newValue) => setFechaCobro(newValue)}
                renderInput={(params) => (
                  <StyledTextField
                    {...params}
                    fullWidth
                    margin="normal"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayIcon color="primary" />
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </LocalizationProvider>

            <StyledFormControl fullWidth>
              <InputLabel>Caja Origen</InputLabel>
              <Select
                value={cajaOrigen}
                onChange={(e) => setCajaOrigen(e.target.value)}
                label="Caja Origen"
              >
                {cajas.map((cajaItem) => (
                  <MenuItem key={cajaItem.caja} value={cajaItem.caja}>
                    {cajaItem.caja}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>

            <StyledFormControl fullWidth>
              <InputLabel>Trabajador</InputLabel>
              <Select
                value={trabajadorCobro}
                onChange={(e) => {
                  setTrabajadorCobro(e.target.value);
                  // Auto-populate facturador field with selected worker
                  setNombreFacturador(e.target.value);
                }}
                label="Trabajador"
              >
                <MenuItem value="" disabled>Seleccionar trabajador</MenuItem>
                {trabajadores.map((trabajador) => (
                  <MenuItem key={trabajador.nombre_del_trabajador} value={trabajador.nombre_del_trabajador}>
                    {trabajador.nombre_del_trabajador}
                  </MenuItem>
                ))}
              </Select>
            </StyledFormControl>

            <StyledTextField
              label="Facturador"
              value={nombreFacturador}
              onChange={(e) => setNombreFacturador(e.target.value)}
              fullWidth
              placeholder="Nombre de quien factura (por defecto el trabajador)"
            />

            <StyledTextField
              label="Descripción (opcional)"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
              multiline
              rows={3}
              fullWidth
              placeholder="Ingrese una descripción detallada..."
            />
          </Box>
        );

      case 1: // Información Financiera
        return (
          <Box>
            <FormSectionTitle>
              <AttachMoneyIcon /> Información Financiera
            </FormSectionTitle>

            {/* Selector de moneda con botones */}
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Moneda
              </Typography>
              <CurrencySelector>
                <CurrencyButton
                  isActive={moneda === 'ARS'}
                  onClick={() => handleMonedaChange('ARS')}
                >
                  ARS
                </CurrencyButton>
                <CurrencyButton
                  isActive={moneda === 'USD'}
                  onClick={() => handleMonedaChange('USD')}
                >
                  USD
                </CurrencyButton>
              </CurrencySelector>
            </Box>

            <StyledTextField
              label="Tipo de Cambio"
              value={tipoCambio}
              onChange={(e) => {
                setTipoCambio(e.target.value);
                updateEquivalentAmounts(monto, moneda, e.target.value);
              }}
              fullWidth
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
            />

            {/* Controles para ajustar el tipo de cambio */}
            <TipoCambioControl>
              <Typography variant="body2" sx={{ flex: 1 }}>Ajustar tipo de cambio:</Typography>
              <TcStepButton color="error" onClick={() => adjustTipoCambio(-5)}>
                -5
              </TcStepButton>
              <TcStepButton onClick={() => adjustTipoCambio(5)}>
                +5
              </TcStepButton>
              <Tooltip title="Actualizar al valor actual">
                <IconButton
                  size="small"
                  onClick={fetchDolarBlue}
                  sx={{ ml: 1, bgcolor: 'rgba(46, 125, 50, 0.1)', color: 'primary.main' }}
                >
                  <RefreshIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </TipoCambioControl>

            <StyledTextField
              label="Monto"
              value={monto}
              onChange={handleMontoChange}
              fullWidth
              type="number"
              InputProps={{
                startAdornment: <InputAdornment position="start">{moneda === 'USD' ? '$' : '$'}</InputAdornment>,
              }}
            />

            {/* Resumen de montos equivalentes */}
            <Box sx={{
              bgcolor: '#f8fdf9',
              borderRadius: 3,
              border: '1px solid #c8e6c9',
              display: 'flex',
              alignItems: 'center',
              px: 2,
              py: 1.5,
              mt: 2
            }}>
              <Grid container>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="caption" color="#2e7d32" fontWeight="bold">
                      Monto ARS:
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      ${montoARS ? parseFloat(montoARS).toFixed(2) : '0.00'}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography variant="caption" color="#2e7d32" fontWeight="bold">
                      Monto USD:
                    </Typography>
                    <Typography variant="body1" fontWeight="medium">
                      ${montoUSD ? parseFloat(montoUSD).toFixed(2) : '0.00'}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        );
      case 2: // Documentación
        return (
          <Box>
            <FormSectionTitle>
              <DescriptionIcon /> Documentación
            </FormSectionTitle>

            <SwitchFormControl
              control={
                <Switch
                  checked={tieneComprobante}
                  onChange={(e) => setTieneComprobante(e.target.checked)}
                  color="primary"
                />
              }
              label="¿Tiene comprobante?"
              labelPlacement="start"
            />

            {tieneComprobante && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <input
                  ref={comprobanteInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleComprobanteChange}
                  style={{ display: 'none' }}
                />

                {!comprobantePreview ? (
                  <FileUploadBox onClick={handleComprobanteUpload}>
                    <CameraAltIcon sx={{ fontSize: 40, color: theme.palette.primary.main, mb: 1 }} />
                    <Typography variant="body1" gutterBottom>
                      Toca para subir una imagen de comprobante
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      JPG, PNG (máx. 5MB)
                    </Typography>
                  </FileUploadBox>
                ) : (
                  <Box sx={{ position: 'relative', mb: 2 }}>
                    <ImagePreviewBox>
                      <Box
                        component="img"
                        src={comprobantePreview}
                        alt="Vista previa del comprobante"
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </ImagePreviewBox>

                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
                      }}
                      onClick={removeComprobante}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}

                {codigoComprobante && (
                  <Box sx={{
                    mt: 1, mb: 2,
                    p: 1.5,
                    backgroundColor: 'rgba(46, 125, 50, 0.05)',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <Typography variant="caption" fontFamily="monospace" sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {codigoComprobante}
                    </Typography>
                    <IconButton size="small" onClick={() => {
                      navigator.clipboard.writeText(codigoComprobante);
                      setSnackbar({
                        open: true,
                        message: 'Código copiado',
                        severity: 'success'
                      });
                    }}>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </motion.div>
            )}

            <SwitchFormControl
              control={
                <Switch
                  checked={tieneFactura}
                  onChange={(e) => setTieneFactura(e.target.checked)}
                  color="primary"
                />
              }
              label="¿Tiene factura?"
              labelPlacement="start"
            />

            {tieneFactura && (
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                <input
                  ref={facturaInputRef}
                  type="file"
                  accept="image/*"
                  onChange={handleFacturaChange}
                  style={{ display: 'none' }}
                />

                <StyledFormControl fullWidth>
                  <InputLabel>A nombre de</InputLabel>
                  <Select
                    value={facturaANombreDe}
                    onChange={(e) => setFacturaANombreDe(e.target.value)}
                    label="A nombre de"
                  >
                    <MenuItem value="">
                      <em>Seleccione un nombre</em>
                    </MenuItem>
                    {nombreFacturas.map((nombre) => (
                      <MenuItem key={nombre} value={nombre}>
                        {nombre}
                      </MenuItem>
                    ))}
                  </Select>
                </StyledFormControl>

                <Box mt={1} mb={2} display="flex" justifyContent="center">
                  <Button
                    size="small"
                    startIcon={<AddIcon />}
                    onClick={() => setAddingNewNombre(true)}
                    sx={{ borderRadius: 16, py: 1, px: 2 }}
                  >
                    Agregar nuevo nombre
                  </Button>
                </Box>

                {addingNewNombre && (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: 'auto' }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                      <StyledTextField
                        value={nuevoNombreFactura}
                        onChange={(e) => setNuevoNombreFactura(e.target.value)}
                        label="Nuevo nombre"
                        fullWidth
                        size="small"
                      />
                      <IconButton
                        color="primary"
                        onClick={handleAgregarNuevoNombre}
                        sx={{ ml: 1 }}
                      >
                        <CheckIcon />
                      </IconButton>
                      <IconButton
                        onClick={() => setAddingNewNombre(false)}
                        sx={{ ml: 0.5 }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </Box>
                  </motion.div>
                )}

                {!facturaPreview ? (
                  <FileUploadBox onClick={handleFacturaUpload}>
                    <CameraAltIcon sx={{ fontSize: 40, color: theme.palette.primary.main, mb: 1 }} />
                    <Typography variant="body1" gutterBottom>
                      Toca para subir una imagen de factura
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      JPG, PNG (máx. 5MB)
                    </Typography>
                  </FileUploadBox>
                ) : (
                  <Box sx={{ position: 'relative', mb: 2 }}>
                    <ImagePreviewBox>
                      <Box
                        component="img"
                        src={facturaPreview}
                        alt="Vista previa de la factura"
                        sx={{
                          maxWidth: '100%',
                          maxHeight: '100%',
                          objectFit: 'contain',
                        }}
                      />
                    </ImagePreviewBox>

                    <IconButton
                      size="small"
                      sx={{
                        position: 'absolute',
                        top: 8,
                        right: 8,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        boxShadow: '0 2px 6px rgba(0,0,0,0.2)',
                      }}
                      onClick={removeFactura}
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}

                {codigoFactura && (
                  <Box sx={{
                    mt: 1, mb: 2,
                    p: 1.5,
                    backgroundColor: 'rgba(46, 125, 50, 0.05)',
                    borderRadius: 2,
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                    <Typography variant="caption" fontFamily="monospace" sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis' }}>
                      {codigoFactura}
                    </Typography>
                    <IconButton size="small" onClick={() => {
                      navigator.clipboard.writeText(codigoFactura);
                      setSnackbar({
                        open: true,
                        message: 'Código copiado',
                        severity: 'success'
                      });
                    }}>
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Box>
                )}
              </motion.div>
            )}
          </Box>
        );
      default:
        return null;
    }
  };

  // Renderización de las tarjetas de cobro para la vista principal
  const renderCobroCard = (cobro) => {
    // Función para animar la eliminación desde la tarjeta
    const animateCardDelete = (e) => {
      e.stopPropagation();
      setDeleteConfirmOpen(true);
      setSelectedCobro(cobro);
    };

    return (
      <CobroCard
        key={cobro.id}
        onClick={() => handleOpenDetailView(cobro)}
        component={motion.div}
        whileHover={{ y: -4 }}
        whileTap={{ scale: 0.98 }}
      >
        <CardContent sx={{ p: 2, pb: 1 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar
                sx={{
                  bgcolor: cobro.moneda_cobro === 'USD' ? 'rgba(255, 193, 7, 0.1)' : 'rgba(46, 125, 50, 0.1)',
                  color: cobro.moneda_cobro === 'USD' ? '#f57c00' : '#2e7d32',
                  mr: 1.5,
                  width: 40,
                  height: 40,
                  fontSize: '0.9rem',
                  fontWeight: 'bold'
                }}
              >
                {cobro.moneda_cobro}
              </Avatar>
              <Box>
                <Typography variant="body1" fontWeight="bold">
                  {cobro.monto_formateado}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  TC: {cobro.tipo_de_cambio_cobro}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2" fontWeight="medium" color="primary">
                {cobro.fecha_formateada}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {cobro.caja_origen_cobro}
              </Typography>
            </Box>
          </Box>

          <CardRow>
            <Typography variant="body2" color="text.secondary">Trabajador:</Typography>
            <Typography variant="body2" fontWeight="medium" noWrap sx={{ maxWidth: '60%', textAlign: 'right' }}>
              {cobro.trabajador_cobro}
            </Typography>
          </CardRow>

          {cobro.nombre_facturador_cobro && cobro.nombre_facturador_cobro !== cobro.trabajador_cobro && (
            <CardRow>
              <Typography variant="body2" color="text.secondary">Facturador:</Typography>
              <Typography variant="body2" fontWeight="medium" noWrap sx={{ maxWidth: '60%', textAlign: 'right' }}>
                {cobro.nombre_facturador_cobro}
              </Typography>
            </CardRow>
          )}

          {cobro.descripción_cobro && (
            <CardRow>
              <Typography variant="body2" color="text.secondary">Descripción:</Typography>
              <Typography variant="body2" fontWeight="medium" noWrap sx={{ maxWidth: '60%', textAlign: 'right' }}>
                {cobro.descripción_cobro}
              </Typography>
            </CardRow>
          )}

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            {cobro.tiene_comprobante_cobro && (
              <DocChip
                icon={<ReceiptIcon />}
                label="Comprobante"
                size="small"
                color="success"
                variant="outlined"
                sx={{ mr: 1 }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenImagePreview(cobro.imagen_comprobante_formateada, e);
                }}
              />
            )}

            {cobro.tiene_factura_cobro && (
              <DocChip
                icon={<DescriptionIcon />}
                label="Factura"
                size="small"
                color="primary"
                variant="outlined"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenImagePreview(cobro.imagen_factura_formateada, e);
                }}
              />
            )}

            <Box sx={{ ml: 'auto', display: 'flex' }}>
              <IconButton
                size="small"
                color="primary"
                onClick={(e) => {
                  e.stopPropagation();
                  handleOpenEditDialog(cobro);
                }}
              >
                <EditIcon fontSize="small" />
              </IconButton>

              <IconButton
                size="small"
                color="error"
                onClick={animateCardDelete}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </CobroCard>
    );
  };

  // RENDER
  return (
    <>
      <MobileContainer sx={{ backgroundColor: '#f8f9fa' }}>
        {/* Barra de búsqueda con botón de filtro separado */}
        <SearchBarContainer disappear={trigger}>
          <SearchBar elevation={1}>
            <InputBase
              placeholder="Buscar cobros..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              sx={{ ml: 1 }}
            />
            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </SearchBar>
          <FilterButton
            startIcon={<FilterAltIcon />}
            onClick={() => setOpenFiltersDrawer(true)}
            variant="outlined"
          >
            Filtros
          </FilterButton>
        </SearchBarContainer>

        {/* Botones flotantes que aparecen al hacer scroll */}
        <Zoom in={trigger && !openDetailView}>
          <FixedSearchContainer>
            <FloatingSearchButton
              color="primary"
              onClick={() => {
                setSearchTerm('');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              <SearchIcon />
            </FloatingSearchButton>
            <FloatingFilterButton
              color="primary"
              onClick={() => setOpenFiltersDrawer(true)}
            >
              <FilterAltIcon />
            </FloatingFilterButton>
          </FixedSearchContainer>
        </Zoom>

        {/* Sumario financiero */}
        <AnimatePresence>
          {summaryVisible && (
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
            >
              <SummaryPanel>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <SummarizeIcon sx={{ mr: 1, color: 'primary.main' }} />
                  <Typography variant="subtitle1" fontWeight="bold">
                    Resumen Financiero
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <IconButton size="small" onClick={() => setSummaryVisible(false)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
                <Divider sx={{ mb: 2 }} />
                <SummaryRow>
                  <Typography variant="body2" color="text.secondary">Total ARS:</Typography>
                  <Typography variant="body1" fontWeight="bold" color="primary.main">
                    {formatCurrency(summaryData.totalARS, 'ARS')}
                  </Typography>
                </SummaryRow>
                <SummaryRow>
                  <Typography variant="body2" color="text.secondary">Total USD:</Typography>
                  <Typography variant="body1" fontWeight="bold" color="#f57c00">
                    {formatCurrency(summaryData.totalUSD, 'USD')}
                  </Typography>
                </SummaryRow>
                <Divider sx={{ my: 1 }} />
                <SummaryRow>
                  <Typography variant="body2" color="text.secondary">Equivalente en ARS:</Typography>
                  <Typography variant="body1" fontWeight="bold" color="primary.main">
                    {formatCurrency(summaryData.totalARSEquivalente, 'ARS')}
                  </Typography>
                </SummaryRow>
                <SummaryRow>
                  <Typography variant="body2" color="text.secondary">Equivalente en USD:</Typography>
                  <Typography variant="body1" fontWeight="bold" color="#f57c00">
                    {formatCurrency(summaryData.totalUSDEquivalente, 'USD')}
                  </Typography>
                </SummaryRow>
              </SummaryPanel>
            </motion.div>
          )}
        </AnimatePresence>
        {/* Filtros activos */}
        {activeFilters.length > 0 && (
          <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap' }}>
            {activeFilters.map((filter, index) => (
              <Chip
                key={index}
                label={`${filter.type === 'trabajador' ? 'Trab: ' :
                  filter.type === 'caja' ? 'Caja: ' :
                    filter.type === 'moneda' ? 'Mon: ' :
                      filter.type === 'fechaDesde' ? 'Desde: ' :
                        filter.type === 'fechaHasta' ? 'Hasta: ' :
                          filter.type === 'tieneComprobante' ? '' :
                            filter.type === 'tieneFactura' ? '' : ''}${filter.value}`}
                onDelete={() => {
                  const newFilters = [...activeFilters];
                  newFilters.splice(index, 1);
                  setActiveFilters(newFilters);

                  // También resetear el estado correspondiente
                  if (filter.type === 'trabajador') setFilterTrabajador('');
                  if (filter.type === 'caja') setFilterCaja('');
                  if (filter.type === 'moneda') setFilterMoneda('');
                  if (filter.type === 'fechaDesde') setFilterFechaDesde(null);
                  if (filter.type === 'fechaHasta') setFilterFechaHasta(null);
                  if (filter.type === 'tieneComprobante') setFilterTieneComprobante(null);
                  if (filter.type === 'tieneFactura') setFilterTieneFactura(null);

                  // Recalcular sumario si aún hay filtros activos
                  if (newFilters.length === 0 && !searchTerm) {
                    setSummaryVisible(false);
                  }
                }}
                size="small"
                sx={{ m: 0.5, borderRadius: 16, py: 1 }}
              />
            ))}
            <Chip
              label="Limpiar"
              onClick={resetFilters}
              size="small"
              color="primary"
              sx={{ m: 0.5, borderRadius: 16, py: 1 }}
            />
          </Box>
        )}

        {/* Lista de cobros */}
        <motion.div
          initial="initial"
          animate="in"
          exit="out"
          variants={pageVariants}
          transition={pageTransition}
        >
          {loading && filteredCobros.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', flexDirection: 'column' }}>
              <CircularProgress color="primary" />
              <Typography variant="body1" sx={{ mt: 2 }}>Cargando cobros...</Typography>
            </Box>
          ) : filteredCobros.length === 0 ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', flexDirection: 'column' }}>
              <Typography variant="body1">No se encontraron cobros</Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Intenta con otros criterios de búsqueda o crea un nuevo cobro
              </Typography>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={handleOpenAddDialog}
                sx={{ mt: 2, borderRadius: 20, px: 3, py: 1 }}
              >
                Nuevo Cobro
              </Button>
            </Box>
          ) : (
            <>
              {filteredCobros
                .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                .map(renderCobroCard)}

              {/* Paginación */}
              {filteredCobros.length > itemsPerPage && (
                <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 4 }}>
                  <Pagination
                    count={Math.ceil(filteredCobros.length / itemsPerPage)}
                    page={page}
                    onChange={(event, value) => setPage(value)}
                    color="primary"
                    showFirstButton
                    showLastButton
                    size="small"
                  />
                </Box>
              )}
            </>
          )}
        </motion.div>

        {/* Botón flotante para agregar */}
        <AddFab
          color="primary"
          aria-label="add"
          onClick={handleOpenAddDialog}
        >
          <AddIcon />
        </AddFab>

        {/* Dialog para agregar/editar cobro */}
        <FullWidthDialog
          open={openAddDialog}
          onClose={() => setOpenAddDialog(false)}
          TransitionComponent={Slide}
          TransitionProps={{ direction: 'up' }}
          fullWidth
          maxWidth="sm"
          keepMounted={false}
        >
          <FormContainer>
            {/* Header - Con capacidad de deslizar hacia abajo para cerrar */}
            <FormHeader
              ref={formDialogRef}
              onWheel={handleFormDialogSwipe}
              component={motion.div}
              whileTap={{ cursor: 'grabbing' }}
              sx={{ cursor: 'grab' }}
            >
              <CloseButton onClick={() => setOpenAddDialog(false)}>
                <CloseIcon />
              </CloseButton>
              <Typography variant="h6" sx={{ ml: 2, fontWeight: 600 }}>
                {editMode ? 'Editar Cobro' : 'Nuevo Cobro'}
              </Typography>
            </FormHeader>

            {/* Selector de pasos */}
            <StepButtonContainer>
              <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                {formSections.map((_, index) => (
                  <StepIndicator
                    key={index}
                    active={currentFormSection === index}
                    onClick={() => setCurrentFormSection(index)}
                    sx={{ cursor: 'pointer' }}
                  />
                ))}
              </Box>
            </StepButtonContainer>

            {/* Contenido del formulario con animación */}
            <FormContent>
              <AnimatePresence mode="wait">
                <motion.div
                  key={currentFormSection}
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  exit={{ opacity: 0, x: -20 }}
                  transition={{ duration: 0.3 }}
                >
                  {renderFormContent()}
                </motion.div>
              </AnimatePresence>
            </FormContent>

            {/* Footer con botones de navegación */}
            <FormFooter>
              {currentFormSection > 0 && (
                <StepButton
                  variant="prev"
                  onClick={handlePrevFormSection}
                  startIcon={<KeyboardArrowLeftIcon />}
                >
                  Anterior
                </StepButton>
              )}
              {currentFormSection < formSections.length - 1 ? (
                <StepButton
                  variant="next"
                  onClick={handleNextFormSection}
                  endIcon={<KeyboardArrowRightIcon />}
                  sx={{ ml: 'auto' }}
                >
                  Siguiente
                </StepButton>
              ) : (
                <StyledSaveButton
                  onClick={handleSubmitForm}
                  disabled={loading}
                  startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                  sx={{ ml: 'auto' }}
                >
                  {editMode ? 'Actualizar' : 'Guardar'}
                </StyledSaveButton>
              )}
            </FormFooter>
          </FormContainer>
        </FullWidthDialog>
        {/* Vista detallada de cobro */}
        <Drawer
          anchor="right"
          open={openDetailView}
          onClose={() => setOpenDetailView(false)}
          PaperProps={{
            sx: {
              width: '100%',
              backgroundColor: 'transparent',
              boxShadow: '0 0 0 rgba(0, 0, 0, 0)', // Sombra inicial nula
              zIndex: 1300,
              transition: 'box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1)', // Curva elástica para sombra
              '& ~ .MuiBackdrop-root': {
                backgroundColor: 'rgba(0, 0, 0, 0)', // Sin color inicial
                backdropFilter: 'blur(0px) brightness(100%)', // Sin blur ni cambio de brillo
                transition: 'backdrop-filter 0.7s cubic-bezier(0.25, 0.8, 0.25, 1)', // Transición elástica
                '&.MuiBackdrop-root.MuiModal-backdrop': {
                  backgroundColor: 'rgba(0, 0, 0, 0.05)', // Gris ultraligero
                  backdropFilter: 'blur(4px) brightness(97%)', // Blur sutil y leve ajuste de brillo
                },
              },
              '& .mobile-detail-view': {
                boxShadow: '0 0 0 rgba(0, 0, 0, 0)', // Sombra inicial del detalle
                transition: 'box-shadow 0.7s cubic-bezier(0.25, 0.8, 0.25, 1)', // Sincronizada con el backdrop
                '&:hover': {
                  boxShadow: '0 5px 25px rgba(0, 0, 0, 0.12)', // Sombra al abrir
                },
              },
            }
          }}
          SlideProps={{
            timeout: 400
          }}
          transitionDuration={400}
        >
          {selectedCobro && (
            <Box sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100%',
              width: '100%',
              background: 'rgba(0, 0, 0, 0.4)', // Fondo aplicado aquí en vez de en el Paper
              backdropFilter: 'blur(2px)', // Efecto blur aquí
              transition: 'background 0.4s ease-in-out, backdrop-filter 0.4s ease-in-out', // Transición suave para el fondo
            }}>
              <MobileDetailView
                className="mobile-detail-view"
                component={motion.div}
                initial={{ opacity: 0, y: 20, scale: 0.95 }} // Cambio en los valores iniciales
                animate={{ opacity: 1, y: 0, scale: 1 }}
                exit={{ opacity: 0, y: 20, scale: 0.95 }}
                transition={{
                  duration: 0.35, // Ligeramente más largo
                  type: 'spring',
                  stiffness: 300, // Reducido para más suavidad
                  damping: 25, // Ajustado
                  bounce: 0.1 // Muy ligero bounce
                }}
              >
                <Box sx={{ position: 'relative' }}>
                  <AppBar
                    position="sticky"
                    color="transparent"
                    elevation={0}
                    sx={{
                      bgcolor: 'white',
                      borderBottom: '1px solid rgba(0,0,0,0.05)',
                      borderTopLeftRadius: 28,
                      borderTopRightRadius: 28,
                    }}
                  >
                    <Toolbar>
                      <IconButton edge="start" onClick={() => setOpenDetailView(false)}>
                        <ArrowBackIcon />
                      </IconButton>
                      <Typography variant="h6" fontWeight="medium" sx={{ ml: 1 }}>
                        Detalles de Cobro
                      </Typography>
                      <Box sx={{ flexGrow: 1 }} />
                      <IconButton
                        color="primary"
                        onClick={() => handleOpenEditDialog(selectedCobro)}
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        color="error"
                        onClick={() => setDeleteConfirmOpen(true)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Toolbar>
                  </AppBar>

                  <Box sx={{ p: 2, overflowY: 'auto', height: 'calc(85vh - 64px)', backgroundColor: '#f8f9fa' }}>
                    {/* Cabecera con monto y fecha */}
                    <DetailSection>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                        <Box>
                          <Typography variant="body2" color="text.secondary">Fecha</Typography>
                          <Typography variant="h6" fontWeight="bold">{selectedCobro.fecha_formateada}</Typography>
                        </Box>
                        <Box sx={{ textAlign: 'right' }}>
                          <Typography variant="body2" color="text.secondary">Monto</Typography>
                          <Typography variant="h6" fontWeight="bold" color={selectedCobro.moneda_cobro === 'USD' ? '#f57c00' : 'primary'}>
                            {selectedCobro.monto_formateado}
                          </Typography>
                        </Box>
                      </Box>

                      <Divider sx={{ my: 1 }} />

                      <DetailRow>
                        <DetailLabel>Trabajador</DetailLabel>
                        <DetailValue>{selectedCobro.trabajador_cobro}</DetailValue>
                      </DetailRow>

                      <DetailRow>
                        <DetailLabel>Facturador</DetailLabel>
                        <DetailValue>{selectedCobro.nombre_facturador_cobro || selectedCobro.trabajador_cobro}</DetailValue>
                      </DetailRow>

                      <DetailRow>
                        <DetailLabel>Caja Origen</DetailLabel>
                        <DetailValue>{selectedCobro.caja_origen_cobro}</DetailValue>
                      </DetailRow>

                      <DetailRow>
                        <DetailLabel>Tipo de Cambio</DetailLabel>
                        <DetailValue>${selectedCobro.tipo_de_cambio_cobro}</DetailValue>
                      </DetailRow>

                      {/* Montos equivalentes */}
                      <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
                        {/* Equivalente en ARS */}
                        <Box
                          sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'rgba(255, 153, 0, 0.08)',
                            p: 1.5,
                            borderRadius: 3,
                          }}
                        >
                          <Typography variant="caption" color="text.secondary" sx={{ mr: 1 }}>
                            Equiv. ARS
                          </Typography>
                          <Typography variant="body2" fontWeight="medium" color="#f57c00" sx={{ ml: 'auto' }}>
                            {formatCurrency(selectedCobro.monto_en_ars_cobro, 'ARS')}
                          </Typography>
                        </Box>

                        {/* Equivalente en USD */}
                        <Box
                          sx={{
                            flex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            backgroundColor: 'rgba(25, 118, 210, 0.08)',
                            p: 1.5,
                            borderRadius: 3,
                          }}
                        >
                          <Typography variant="caption" color="text.secondary" sx={{ mr: 1 }}>
                            Equiv. USD
                          </Typography>
                          <Typography variant="body2" fontWeight="medium" color="#1976d2" sx={{ ml: 'auto' }}>
                            {formatCurrency(selectedCobro.monto_en_usd_cobro, 'USD')}
                          </Typography>
                        </Box>
                      </Box>
                    </DetailSection>

                    {/* Información adicional */}
                    {selectedCobro.descripción_cobro && (
                      <DetailSection>
                        <DetailSectionTitle>
                          <AssignmentIcon /> Descripción
                        </DetailSectionTitle>
                        <Typography variant="body2" sx={{ whiteSpace: 'pre-wrap', p: 1.5, bgcolor: 'rgba(0,0,0,0.02)', borderRadius: 2 }}>
                          {selectedCobro.descripción_cobro}
                        </Typography>
                      </DetailSection>
                    )}

                    {/* Documentos */}
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, mt: 2 }}>
                      {/* Comprobante */}
                      {selectedCobro.tiene_comprobante_cobro && (
                        <DetailSection sx={{ flex: 1, minWidth: '45%' }}>
                          <DetailSectionTitle>
                            <ReceiptIcon /> Comprobante
                          </DetailSectionTitle>

                          <Box
                            sx={{
                              width: '100%',
                              borderRadius: 3,
                              overflow: 'hidden',
                              border: '1px solid rgba(0,0,0,0.1)',
                              mb: 1
                            }}
                            onClick={() => handleOpenImagePreview(selectedCobro.imagen_comprobante_formateada)}
                          >
                            <img
                              src={selectedCobro.imagen_comprobante_formateada}
                              alt="Comprobante"
                              style={{
                                width: '100%',
                                maxHeight: 200,
                                objectFit: 'contain',
                                backgroundColor: '#f9f9f9',
                                padding: 4
                              }}
                            />
                          </Box>

                          {selectedCobro.codigo_de_comprobante_cobro && (
                            <Box sx={{
                              p: 1.5,
                              backgroundColor: 'rgba(46, 125, 50, 0.05)',
                              borderRadius: 2,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center'
                            }}>
                              <Typography variant="caption" fontFamily="monospace" noWrap sx={{ maxWidth: '80%' }}>
                                {selectedCobro.codigo_de_comprobante_cobro}
                              </Typography>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigator.clipboard.writeText(selectedCobro.codigo_de_comprobante_cobro);
                                  setSnackbar({
                                    open: true,
                                    message: 'Código copiado al portapapeles',
                                    severity: 'success'
                                  });
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          )}
                        </DetailSection>
                      )}

                      {/* Factura */}
                      {selectedCobro.tiene_factura_cobro && (
                        <DetailSection sx={{ flex: 1, minWidth: '45%' }}>
                          <DetailSectionTitle>
                            <DescriptionIcon /> Factura
                          </DetailSectionTitle>

                          <Box
                            sx={{
                              width: '100%',
                              borderRadius: 3,
                              overflow: 'hidden',
                              border: '1px solid rgba(0,0,0,0.1)',
                              mb: 1
                            }}
                            onClick={() => handleOpenImagePreview(selectedCobro.imagen_factura_formateada)}
                          >
                            <img
                              src={selectedCobro.imagen_factura_formateada}
                              alt="Factura"
                              style={{
                                width: '100%',
                                maxHeight: 200, objectFit: 'contain',
                                backgroundColor: '#f9f9f9',
                                padding: 4
                              }}
                            />
                          </Box>

                          {selectedCobro.codigo_de_factura_cobro && (
                            <Box sx={{
                              p: 1.5,
                              backgroundColor: 'rgba(46, 125, 50, 0.05)',
                              borderRadius: 2,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              mb: 1
                            }}>
                              <Typography variant="caption" fontFamily="monospace" noWrap sx={{ maxWidth: '80%' }}>
                                {selectedCobro.codigo_de_factura_cobro}
                              </Typography>
                              <IconButton
                                size="small"
                                onClick={() => {
                                  navigator.clipboard.writeText(selectedCobro.codigo_de_factura_cobro);
                                  setSnackbar({
                                    open: true,
                                    message: 'Código copiado al portapapeles',
                                    severity: 'success'
                                  });
                                }}
                              >
                                <ContentCopyIcon fontSize="small" />
                              </IconButton>
                            </Box>
                          )}

                          {selectedCobro.factura_a_nombre_de_cobro && (
                            <Typography variant="body2" color="text.secondary">
                              A nombre de: <b>{selectedCobro.factura_a_nombre_de_cobro}</b>
                            </Typography>
                          )}
                        </DetailSection>
                      )}
                    </Box>
                  </Box>
                </Box>
              </MobileDetailView>
            </Box>
          )}
        </Drawer>

        {/* Diálogo de confirmación de eliminación */}
        <Dialog
          open={deleteConfirmOpen}
          onClose={() => setDeleteConfirmOpen(false)}
          PaperProps={{
            sx: {
              borderRadius: '24px',
              padding: 2,
              maxWidth: '85%'
            }
          }}
        >
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2 }}
          >
            <Box sx={{ p: 1 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Box
                  sx={{
                    width: 48,
                    height: 48,
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'rgba(211, 47, 47, 0.1)',
                    color: 'error.main',
                    mr: 2
                  }}
                >
                  <DeleteIcon fontSize="medium" />
                </Box>
                <Typography variant="h6">¿Eliminar cobro?</Typography>
              </Box>

              <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Esta acción eliminará permanentemente el cobro de {selectedCobro?.monto_formateado} del {selectedCobro?.fecha_formateada}.
              </Typography>

              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <Button
                  variant="outlined"
                  onClick={() => setDeleteConfirmOpen(false)}
                  sx={{ borderRadius: 8, textTransform: 'none' }}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    setDeleteConfirmOpen(false);
                    // Animación de salida antes de eliminar
                    animateItemDelete();
                  }}
                  sx={{ borderRadius: 8, textTransform: 'none' }}
                >
                  Eliminar
                </Button>
              </Box>
            </Box>
          </motion.div>
        </Dialog>

        {/* Dialog para vista previa de imágenes */}
        <Dialog
          open={imagePreviewOpen}
          onClose={() => setImagePreviewOpen(false)}
          maxWidth="lg"
          PaperProps={{
            sx: {
              borderRadius: 3,
              bgcolor: 'rgba(0,0,0,0.9)',
              p: 0
            }
          }}
        >
          <IconButton
            onClick={() => setImagePreviewOpen(false)}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'white',
              bgcolor: 'rgba(0,0,0,0.3)',
              zIndex: 1
            }}
          >
            <CloseIcon />
          </IconButton>

          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '80vh',
              width: '100vw',
              maxWidth: '100%'
            }}
          >
            {currentImage && (
              <motion.img
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.3 }}
                src={currentImage}
                alt="Vista ampliada"
                style={{
                  maxWidth: '100%',
                  maxHeight: '100%',
                  objectFit: 'contain'
                }}
              />
            )}
          </Box>
        </Dialog>

        {/* Drawer para filtros */}
        <SwipeableDrawer
          anchor="bottom"
          open={openFiltersDrawer}
          onClose={() => setOpenFiltersDrawer(false)}
          onOpen={() => setOpenFiltersDrawer(true)}
          disableSwipeToOpen={false}
          swipeAreaWidth={30}
          ModalProps={{
            keepMounted: true,
            sx: {
              '& .MuiBackdrop-root': {
                backgroundColor: 'rgba(0, 0, 0, 0)', // Transparente al inicio
                backdropFilter: 'blur(0px) brightness(100%)', // Sin blur ni cambio de brillo
                transition: 'backdrop-filter 0.6s cubic-bezier(0.4, 0, 0.2, 1)', // Curva suave
                '&.MuiBackdrop-root.MuiModal-backdrop': {
                  backgroundColor: 'rgba(0, 0, 0, 0.08)', // Gris muy sutil
                  backdropFilter: 'blur(6px) brightness(96%)', // Blur intermedio y leve ajuste
                },
              },
            },
          }}
          PaperProps={{
            sx: {
              borderRadius: '28px 28px 0 0',
              maxHeight: '75vh',
              boxShadow: '0 0 0 rgba(0, 0, 0, 0)', // Sombra inicial nula
              transition: 'box-shadow 0.6s cubic-bezier(0.4, 0, 0.2, 1), transform 0.4s ease-out', // Sombra y desplazamiento
              '&:hover': {
                boxShadow: '0 -8px 20px rgba(0, 0, 0, 0.1)', // Sombra dinámica al abrir
              },
            }
          }}
        >
          <SwipableFilterBox>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1.5 }}>
              <Typography variant="h6" fontWeight="medium">Filtrar Cobros</Typography>
              <IconButton onClick={() => setOpenFiltersDrawer(false)}>
                <CloseIcon />
              </IconButton>
            </Box>

            <FormSection>
              <StyledFormControl fullWidth>
                <InputLabel>Trabajador</InputLabel>
                <Select
                  value={filterTrabajador}
                  onChange={(e) => setFilterTrabajador(e.target.value)}
                  label="Trabajador"
                >
                  <MenuItem value="">Todos</MenuItem>
                  {trabajadores.map((trabajador) => (
                    <MenuItem key={trabajador.nombre_del_trabajador} value={trabajador.nombre_del_trabajador}>
                      {trabajador.nombre_del_trabajador}
                    </MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Caja</InputLabel>
                <Select
                  value={filterCaja}
                  onChange={(e) => setFilterCaja(e.target.value)}
                  label="Caja"
                >
                  <MenuItem value="">Todas</MenuItem>
                  {cajas.map((caja) => (
                    <MenuItem key={caja.caja} value={caja.caja}>{caja.caja}</MenuItem>
                  ))}
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Moneda</InputLabel>
                <Select
                  value={filterMoneda}
                  onChange={(e) => setFilterMoneda(e.target.value)}
                  label="Moneda"
                >
                  <MenuItem value="">Todas</MenuItem>
                  <MenuItem value="ARS">ARS</MenuItem>
                  <MenuItem value="USD">USD</MenuItem>
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Comprobante</InputLabel>
                <Select
                  value={filterTieneComprobante === null ? '' : filterTieneComprobante.toString()}
                  onChange={(e) => {
                    const value = e.target.value === '' ? null : e.target.value === 'true';
                    setFilterTieneComprobante(value);
                  }}
                  label="Comprobante"
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="true">Con comprobante</MenuItem>
                  <MenuItem value="false">Sin comprobante</MenuItem>
                </Select>
              </StyledFormControl>

              <StyledFormControl fullWidth>
                <InputLabel>Factura</InputLabel>
                <Select
                  value={filterTieneFactura === null ? '' : filterTieneFactura.toString()}
                  onChange={(e) => {
                    const value = e.target.value === '' ? null : e.target.value === 'true';
                    setFilterTieneFactura(value);
                  }}
                  label="Factura"
                >
                  <MenuItem value="">Todos</MenuItem>
                  <MenuItem value="true">Con factura</MenuItem>
                  <MenuItem value="false">Sin factura</MenuItem>
                </Select>
              </StyledFormControl>

              <LocalizationProvider dateAdapter={AdapterDateFns} locale={es}>
                <Grid container spacing={1.5} sx={{ mt: 0.5 }}>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Desde"
                      value={filterFechaDesde}
                      onChange={(newValue) => setFilterFechaDesde(newValue)}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          fullWidth
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <CalendarTodayIcon color="primary" fontSize="small" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <DatePicker
                      label="Hasta"
                      value={filterFechaHasta}
                      onChange={(newValue) => setFilterFechaHasta(newValue)}
                      renderInput={(params) => (
                        <StyledTextField
                          {...params}
                          fullWidth
                          size="small"
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <InputAdornment position="end">
                                <CalendarTodayIcon color="primary" fontSize="small" />
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </FormSection>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
              <Button
                variant="outlined"
                color="inherit"
                onClick={resetFilters}
                sx={{
                  width: '48%',
                  borderRadius: 20,
                  borderColor: 'rgba(0,0,0,0.2)',
                  color: 'text.secondary'
                }}
              >
                Limpiar
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={applyFilterSelection}
                sx={{ width: '48%', borderRadius: 20 }}
              >
                Aplicar Filtros
              </Button>
            </Box>
          </SwipableFilterBox>
        </SwipeableDrawer>

        {/* Diálogo para búsqueda flotante */}
        <Dialog
          open={searchDialogOpen}
          onClose={() => setSearchDialogOpen(false)}
          PaperProps={{
            sx: {
              borderRadius: 3,
              width: '90%',
              maxWidth: '90%',
              p: 2
            }
          }}
        >
          <Box sx={{ p: 1 }}>
            <Typography variant="subtitle1" fontWeight="bold" gutterBottom>
              Buscar cobro
            </Typography>
            <TextField
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              fullWidth
              variant="outlined"
              placeholder="Buscar..."
              sx={{ mb: 2 }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                variant="contained"
                onClick={() => {
                  setSearchDialogOpen(false);
                  // Si se está buscando algo, mantener la visibilidad del resumen
                  if (searchTerm) {
                    setSummaryVisible(true);
                  }
                }}
                color="primary"
                sx={{ borderRadius: 20 }}
              >
                Buscar
              </Button>
            </Box>
          </Box>
        </Dialog>

        {/* Snackbar para notificaciones */}
        <Snackbar
          open={snackbar.open}
          autoHideDuration={4000}
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          sx={{ mb: 7 }} // Para que aparezca por encima de la navegación
        >
          <Alert
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            severity={snackbar.severity}
            sx={{
              width: '100%',
              borderRadius: 20,
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
            }}
          >
            {snackbar.message}
          </Alert>
        </Snackbar>
      </MobileContainer>
    </>
  );
};

export default MobileCobroTrabajadores;