import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  ArrowDownward,
  ArrowUpward,
  People,
  CompareArrows,
  Search
} from '@mui/icons-material';
import { motion } from 'framer-motion';

// Contenedor principal de la barra de navegación con altura adicional
const NavContainer = styled(Paper)(({ theme, isVisible, isPwa }) => ({
  position: 'fixed',
  bottom: 32, // Subido un poco más (antes 24px)
  left: '50%', // Centrado horizontalmente
  transform: isVisible ? 'translateX(-50%)' : 'translateX(-50%) translateY(100px)', // Animación para ocultar/mostrar
  zIndex: 1000,
  borderRadius: 28, // Bordes redondeados
  boxShadow: '0 3px 16px rgba(0,0,0,0.15)', // Sombra sutil
  backgroundColor: '#2c2c2c', // Fondo oscuro
  width: 'calc(100% - 32px)', // Ancho del 100% menos margen
  maxWidth: 480, // Limitar el ancho máximo
  height: isPwa ? '64px' : '68px', // Altura un poco mayor
  opacity: isVisible ? 1 : 0, // Opacidad controlada
  visibility: isVisible ? 'visible' : 'hidden', // Visibilidad controlada
  transition: 'transform 0.3s ease, opacity 0.3s ease, visibility 0.3s', // Transición suave
  display: 'none',
  [theme.breakpoints.down('sm')]: {
    display: 'flex', // Visible solo en móvil
  },
}));

// Contenedor de los íconos
const NavIcons = styled(Box)({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '100%',
  height: '100%',
});

// Icono de navegación sin efectos visuales al presionar
const NavIcon = styled(Box)(({ theme, active }) => ({
  color: active ? '#4caf50' : 'rgba(255, 255, 255, 0.7)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  position: 'relative',
  width: '20%',
  height: '100%',
  transition: 'color 0.2s ease-out',
  WebkitTapHighlightColor: 'transparent', // Elimina el highlight en dispositivos móviles
  // Elimina cualquier efecto visual al presionar en diferentes navegadores
  '&:active': {
    backgroundColor: 'transparent',
    outline: 'none',
  },
  '&:focus': {
    outline: 'none',
  },
}));

// Contenedor del icono sin animación al tocar
const IconWrapper = styled(Box)(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: active ? '#4caf50' : 'rgba(255, 255, 255, 0.7)',
  position: 'relative',
  // Indicador para el icono activo
  '&::after': active ? {
    content: '""',
    position: 'absolute',
    bottom: -12,
    width: 4,
    height: 4,
    backgroundColor: '#4caf50',
    borderRadius: '50%',
  } : {},
  // Asegura que no haya ningún efecto visual al presionar
  WebkitTapHighlightColor: 'transparent',
  '&:active': {
    backgroundColor: 'transparent',
  },
}));

const MobileNavigation = ({ isVisible = true }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState(0);
  const [isPWA, setIsPWA] = useState(false);

  useEffect(() => {
    // Detectar si estamos en PWA
    const isRunningAsPWA = window.matchMedia('(display-mode: standalone)').matches ||
      window.navigator.standalone;
    setIsPWA(isRunningAsPWA);
  }, []);

  // Rutas con iconos ligeramente más pequeños
  const routes = useMemo(() => [

    {
      path: '/entradas',
      index: 0,
      name: 'Entradas',
      icon: <ArrowDownward sx={{ fontSize: isPWA ? '1.4rem' : '1.5rem' }} />
    },
    {
      path: '/salidas',
      index: 1,
      name: 'Salidas',
      icon: <ArrowUpward sx={{ fontSize: isPWA ? '1.4rem' : '1.5rem' }} />
    },
    {
      path: '/admin/cobro-trabajadores',
      index: 2,
      name: 'Cobros',
      icon: <People sx={{ fontSize: isPWA ? '1.4rem' : '1.5rem' }} />
    },
    {
      path: '/cambios-de-moneda',
      index: 3,
      name: 'Cambios',
      icon: <CompareArrows sx={{ fontSize: isPWA ? '1.4rem' : '1.5rem' }} />
    },
    {
      path: '/buscador-unificado',
      index: 4,
      name: 'Buscar',
      icon: <Search sx={{ fontSize: isPWA ? '1.4rem' : '1.5rem' }} />
    },
  ], [isPWA]);

  useEffect(() => {
    const currentRoute = routes.find(route => location.pathname.startsWith(route.path));
    if (currentRoute) setActiveTab(currentRoute.index);
  }, [location, routes]);

  const handleNavigation = (index) => {
    const targetRoute = routes.find(route => route.index === index);
    if (targetRoute) navigate(targetRoute.path);
    setActiveTab(index);
  };

  return (
    <NavContainer
      elevation={4}
      isVisible={isVisible}
      isPwa={isPWA}
    >
      <NavIcons>
        {routes.map((route) => (
          <NavIcon
            key={route.index}
            active={activeTab === route.index}
            onClick={() => handleNavigation(route.index)}
          >
            {/* Contenedor sin efectos visuales al presionar */}
            <IconWrapper active={activeTab === route.index}>
              {route.icon}
            </IconWrapper>
          </NavIcon>
        ))}
      </NavIcons>
    </NavContainer>
  );
};

export default MobileNavigation;