// src/components/Trabajadores.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
  Container,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';

const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: 12,
  boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  '&:hover': {
    boxShadow: '0 6px 20px rgba(0, 0, 0, 0.15)',
  },
}));

const Trabajadores = () => {
  const [trabajadores, setTrabajadores] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [formData, setFormData] = useState({
    nombre_del_trabajador: '',
    puesto_del_trabajador: '',
    email_del_trabajador: '',
    permisos_del_trabajador: 'Empleado',
  });
  const [editId, setEditId] = useState(null);

  useEffect(() => {
    fetchTrabajadores();
  }, []);

  const fetchTrabajadores = async () => {
    const { data, error } = await supabase.from('trabajadores_empresa').select('*');
    if (error) console.error('Error fetching trabajadores:', error);
    else setTrabajadores(data || []);
  };

  const handleAddTrabajador = async () => {
    const { error } = await supabase.from('trabajadores_empresa').insert({
      nombre_del_trabajador: formData.nombre_del_trabajador,
      puesto_del_trabajador: formData.puesto_del_trabajador,
      email_del_trabajador: formData.email_del_trabajador,
      permisos_del_trabajador: formData.permisos_del_trabajador,
    });
    if (error) alert('Error al agregar trabajador: ' + error.message);
    else {
      fetchTrabajadores();
      setOpenDialog(false);
      setFormData({
        nombre_del_trabajador: '',
        puesto_del_trabajador: '',
        email_del_trabajador: '',
        permisos_del_trabajador: 'Empleado',
      });
    }
  };

  const handleEditTrabajador = async () => {
    const { error } = await supabase.from('trabajadores_empresa').update({
      nombre_del_trabajador: formData.nombre_del_trabajador,
      puesto_del_trabajador: formData.puesto_del_trabajador,
      email_del_trabajador: formData.email_del_trabajador,
      permisos_del_trabajador: formData.permisos_del_trabajador,
    }).eq('id', editId);
    if (error) alert('Error al editar trabajador: ' + error.message);
    else {
      fetchTrabajadores();
      setOpenDialog(false);
      setEditId(null);
      setFormData({
        nombre_del_trabajador: '',
        puesto_del_trabajador: '',
        email_del_trabajador: '',
        permisos_del_trabajador: 'Empleado',
      });
    }
  };

  const handleDeleteTrabajador = async (id) => {
    const { error } = await supabase.from('trabajadores_empresa').delete().eq('id', id);
    if (error) alert('Error al eliminar trabajador: ' + error.message);
    else fetchTrabajadores();
  };

  return (
    <Container maxWidth="lg" sx={{ py: 3, px: 0, minHeight: '100vh' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2, gap: 1 }}>
        <Typography variant="h4" fontWeight="bold" color="#2e7d32" gutterBottom>
          Trabajadores
        </Typography>
        <IconButton onClick={() => setOpenDialog(true)} sx={{ color: '#2e7d32', backgroundColor: '#e8f5e9', borderRadius: '50%', width: 40, height: 40, '&:hover': { backgroundColor: '#c8e6c9' } }}>
          <AddIcon />
        </IconButton>
      </Box>

      <Box sx={{ overflowX: 'auto' }}>
        {trabajadores.map((trabajador) => (
          <StyledCard key={trabajador.id}>
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 2 }}>
              <Box>
                <Typography variant="body1" color="text.primary">
                  {trabajador.nombre_del_trabajador} - {trabajador.puesto_del_trabajador}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Email: {trabajador.email_del_trabajador}
                </Typography>
                <Typography variant="caption" color="text.secondary">
                  Permisos: {trabajador.permisos_del_trabajador}
                </Typography>
              </Box>
              <Box>
                <IconButton
                  onClick={() => {
                    setEditId(trabajador.id);
                    setFormData({
                      nombre_del_trabajador: trabajador.nombre_del_trabajador,
                      puesto_del_trabajador: trabajador.puesto_del_trabajador,
                      email_del_trabajador: trabajador.email_del_trabajador,
                      permisos_del_trabajador: trabajador.permisos_del_trabajador,
                    });
                    setOpenDialog(true);
                  }}
                  sx={{ color: '#2e7d32' }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteTrabajador(trabajador.id)} sx={{ color: '#d32f2f' }}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </CardContent>
          </StyledCard>
        ))}
      </Box>

      <Dialog open={openDialog} onClose={() => { setOpenDialog(false); setEditId(null); }}>
        <DialogContent>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" fontWeight="bold" color="#424242">
              {editId ? 'Editar Trabajador' : 'Nuevo Trabajador'}
            </Typography>
            <IconButton onClick={() => { setOpenDialog(false); setEditId(null); }} sx={{ color: '#d32f2f' }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <TextField
            label="Nombre del Trabajador"
            value={formData.nombre_del_trabajador}
            onChange={(e) => setFormData((prev) => ({ ...prev, nombre_del_trabajador: e.target.value }))}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          />
          <TextField
            label="Puesto"
            value={formData.puesto_del_trabajador}
            onChange={(e) => setFormData((prev) => ({ ...prev, puesto_del_trabajador: e.target.value }))}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          />
          <TextField
            label="Email"
            value={formData.email_del_trabajador}
            onChange={(e) => setFormData((prev) => ({ ...prev, email_del_trabajador: e.target.value }))}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Permisos</InputLabel>
            <Select
              value={formData.permisos_del_trabajador}
              onChange={(e) => setFormData((prev) => ({ ...prev, permisos_del_trabajador: e.target.value }))}
              label="Permisos"
            >
              <MenuItem value="Admin">Admin</MenuItem>
              <MenuItem value="Empleado">Empleado</MenuItem>
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="success"
            onClick={editId ? handleEditTrabajador : handleAddTrabajador}
            sx={{ borderRadius: 12, backgroundColor: '#2e7d32', '&:hover': { backgroundColor: '#1b5e20' } }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Trabajadores;