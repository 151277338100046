// src/contexts/AuthContext.js
import React, { createContext, useState, useEffect, useContext } from 'react';
import { supabase, getUserRole } from '../supabaseClient';

const AuthContext = createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [user, setUser] = useState(null);
  const [userRole, setUserRole] = useState(null);
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [authError, setAuthError] = useState(null);
  const [verifyingPermissions, setVerifyingPermissions] = useState(false);

  useEffect(() => {
    let isActive = true;

    async function loadUser() {
      try {
        // Marcar que estamos verificando permisos
        setVerifyingPermissions(true);

        // Intentar obtener el rol guardado en localStorage primero para respuesta inmediata
        const savedRole = localStorage.getItem('userRole');
        const savedDetailsStr = localStorage.getItem('userDetails');
        let savedDetails = null;

        if (savedDetailsStr) {
          try {
            savedDetails = JSON.parse(savedDetailsStr);
          } catch (e) {
            console.error("Error parsing saved details:", e);
          }
        }

        // Verificar si hay una sesión activa
        const { data: sessionData, error: sessionError } = await supabase.auth.getSession();

        if (sessionError) {
          console.error("Error getting session:", sessionError);
          setAuthError("Error al recuperar la sesión");
          setLoading(false);
          setVerifyingPermissions(false);
          return;
        }

        const currentSession = sessionData.session;

        if (!currentSession) {
          console.log("No se encontró sesión activa");
          // Limpiar localStorage cuando no hay sesión
          localStorage.removeItem('userRole');
          localStorage.removeItem('userDetails');

          if (isActive) {
            setUser(null);
            setUserRole(null);
            setUserDetails(null);
            setLoading(false);
            setVerifyingPermissions(false);
          }
          return;
        }

        // Tenemos una sesión activa
        console.log("Sesión encontrada:", currentSession.user.email);

        if (isActive) {
          setUser(currentSession.user);

          // Si tenemos rol en localStorage, usarlo inmediatamente para evitar espera
          if (savedRole && savedDetails) {
            console.log("Usando rol guardado:", savedRole);
            setUserRole(savedRole);
            setUserDetails(savedDetails);
            setLoading(false); // Desactivar loading inmediatamente

            // Dejar verificando permisos como true porque aún vamos a actualizar
          } else {
            console.log("No hay rol guardado, consultando a la base de datos");
          }
        }

        try {
          // Obtener rol completo (ya sea que tengamos guardado o no)
          // Si tenemos guardado, esto actualizará en segundo plano sin bloquear la interfaz
          const userRoleData = await getUserRole(currentSession.user);

          if (!isActive) return;

          console.log("Datos de rol recuperados:", userRoleData);

          if (userRoleData) {
            setUserRole(userRoleData.role);
            setUserDetails(userRoleData.details);
            // Ya se guardó en localStorage dentro de getUserRole
          } else if (!savedRole) {
            // Solo mostrar advertencia si no teníamos un rol guardado
            console.warn("No se pudieron obtener datos de rol para", currentSession.user.email);

            // Hacer una suposición razonable basada en el email
            if (currentSession.user.email.includes('@gmail.com') ||
              currentSession.user.email.includes('@hotmail.com') ||
              currentSession.user.email.includes('@yahoo.com') ||
              currentSession.user.email.includes('@outlook.com')) {

              console.log("El email parece ser de cliente, asumiendo rol de cliente");
              setUserRole('client');
              setUserDetails({ email: currentSession.user.email });
              localStorage.setItem('userRole', 'client');
              localStorage.setItem('userDetails', JSON.stringify({ email: currentSession.user.email }));
            } else {
              setUserRole('guest');
              setUserDetails({ email: currentSession.user.email });
              localStorage.setItem('userRole', 'guest');
              localStorage.setItem('userDetails', JSON.stringify({ email: currentSession.user.email }));
            }
          }
        } catch (roleError) {
          console.error("Error recuperando rol:", roleError);

          // Si aún no tenemos rol, hacer una última suposición
          if (!userRole && !savedRole) {
            console.log("Error al obtener rol, haciendo suposición basada en email");
            if (currentSession.user.email.includes('@gmail.com') ||
              currentSession.user.email.includes('@hotmail.com') ||
              currentSession.user.email.includes('@yahoo.com') ||
              currentSession.user.email.includes('@outlook.com')) {

              setUserRole('client');
              setUserDetails({ email: currentSession.user.email, fallback: true });
              localStorage.setItem('userRole', 'client');
              localStorage.setItem('userDetails', JSON.stringify({
                email: currentSession.user.email,
                fallback: true
              }));
            } else {
              setUserRole('guest');
              setUserDetails({ email: currentSession.user.email, fallback: true });
            }
          }
        } finally {
          if (isActive) {
            setLoading(false);
            setVerifyingPermissions(false);
          }
        }
      } catch (error) {
        console.error("Error cargando usuario:", error);
        if (isActive) {
          setAuthError("Error al cargar datos de usuario");
          setLoading(false);
          setVerifyingPermissions(false);
        }
      }
    }

    loadUser();

    // Set a timeout to prevent infinite loading
    const timeoutId = setTimeout(() => {
      if (loading || verifyingPermissions) {
        console.log("Auth loading timeout reached, forcing completion");

        if (isActive) {
          setLoading(false);
          setVerifyingPermissions(false);
        }

        // Si tenemos usuario pero no rol, intentar usar localStorage o hacer suposición
        if (user && !userRole) {
          const savedRole = localStorage.getItem('userRole');
          const savedDetails = localStorage.getItem('userDetails');

          if (savedRole) {
            console.log("Using saved role from localStorage due to timeout:", savedRole);
            if (isActive) {
              setUserRole(savedRole);
            }

            if (savedDetails) {
              try {
                if (isActive) {
                  setUserDetails(JSON.parse(savedDetails));
                }
              } catch (e) {
                console.error("Error parsing saved details", e);
              }
            }
          } else if (user.email && (
            user.email.includes('@gmail.com') ||
            user.email.includes('@hotmail.com') ||
            user.email.includes('@yahoo.com') ||
            user.email.includes('@outlook.com'))) {

            // Última suposición basada en email
            console.log("Email looks like client, using client role as fallback");
            if (isActive) {
              setUserRole('client');
              setUserDetails({ email: user.email, timeout: true });
            }
            localStorage.setItem('userRole', 'client');
            localStorage.setItem('userDetails', JSON.stringify({
              email: user.email,
              timeout: true
            }));
          }
        }
      }
    }, 3000); // Reducido a 3 segundos (era 5)

    // Configurar escucha para cambios en la autenticación
    const { data: authListener } = supabase.auth.onAuthStateChange(async (event, newSession) => {
      console.log("Evento de autenticación:", event);

      if (event === 'SIGNED_IN' && newSession) {
        console.log("Usuario inició sesión:", newSession.user.email);

        if (isActive) {
          setUser(newSession.user);
          // Mostrar el estado de verificación de permisos
          setVerifyingPermissions(true);
        }

        try {
          const userRoleData = await getUserRole(newSession.user);
          console.log("Datos de rol en inicio de sesión:", userRoleData);

          if (!isActive) return;

          if (userRoleData) {
            setUserRole(userRoleData.role);
            setUserDetails(userRoleData.details);
            // Ya guardado en getUserRole
          } else {
            // Hacer suposición basada en email
            if (newSession.user.email.includes('@gmail.com') ||
              newSession.user.email.includes('@hotmail.com') ||
              newSession.user.email.includes('@yahoo.com') ||
              newSession.user.email.includes('@outlook.com')) {

              setUserRole('client');
              setUserDetails({ email: newSession.user.email });
              localStorage.setItem('userRole', 'client');
              localStorage.setItem('userDetails', JSON.stringify({
                email: newSession.user.email
              }));
            } else {
              setUserRole('guest');
              setUserDetails({ email: newSession.user.email });
            }
          }
        } catch (error) {
          console.error("Error al obtener rol después de inicio de sesión:", error);

          // Suposición en caso de error
          if (newSession.user.email.includes('@gmail.com') ||
            newSession.user.email.includes('@hotmail.com') ||
            newSession.user.email.includes('@yahoo.com') ||
            newSession.user.email.includes('@outlook.com')) {

            if (isActive) {
              setUserRole('client');
              setUserDetails({ email: newSession.user.email, error: true });
            }
            localStorage.setItem('userRole', 'client');
            localStorage.setItem('userDetails', JSON.stringify({
              email: newSession.user.email,
              error: true
            }));
          }
        } finally {
          if (isActive) {
            setVerifyingPermissions(false);
          }
        }
      } else if (event === 'SIGNED_OUT') {
        console.log("Usuario cerró sesión");
        if (isActive) {
          setUser(null);
          setUserRole(null);
          setUserDetails(null);
        }
        localStorage.removeItem('userRole');
        localStorage.removeItem('userDetails');
      }
    });

    return () => {
      isActive = false;
      clearTimeout(timeoutId);
      authListener?.subscription?.unsubscribe();
    };
  }, []);

  // Funciones de autenticación
  const signIn = async (email, password) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;
      return { success: true, data };
    } catch (error) {
      return { success: false, error };
    }
  };

  const signOut = async () => {
    try {
      localStorage.removeItem('userRole');
      localStorage.removeItem('userDetails');
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      return { success: true };
    } catch (error) {
      console.error("Error al cerrar sesión:", error);
      return { success: false, error };
    }
  };

  // Función para obtener permisos específicos del cliente
  const getClientPermissions = () => {
    if (userRole !== 'client' || !userDetails) {
      return {};
    }

    return {
      codigoProyecto: userDetails.codigo_de_proyecto || null,
      cajaProyecto: userDetails.caja_proyecto || null,
      apellidoCliente: userDetails.apellido_del_cliente || null
    };
  };

  const value = {
    user,
    userRole,
    userDetails,
    loading,
    verifyingPermissions,
    authError,
    signIn,
    signOut,
    getClientPermissions,
    isAdmin: userRole === 'admin',
    isWorker: userRole === 'worker' || userRole === 'admin',
    isClient: userRole === 'client',
    isAuthenticated: !!user
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}