// src/components/Conceptos.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
  Grid,
  IconButton,
  Container,
} from '@mui/material'; // Eliminamos Divider de las importaciones
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

const ConceptDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiPaper-root': {
    borderRadius: 4,
    padding: theme.spacing(2),
    width: '400px',
    maxHeight: '80vh',
    overflowY: 'auto',
    backgroundColor: '#ffffff',
  },
}));

const ConceptItem = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(1),
  borderBottom: `1px solid ${theme.palette.divider}`,
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const Conceptos = () => {
  const [entradasConceptos, setEntradasConceptos] = useState([]);
  const [salidasConceptos, setSalidasConceptos] = useState([]);
  const [openAddEntrada, setOpenAddEntrada] = useState(false);
  const [openAddSalida, setOpenAddSalida] = useState(false);
  const [openEditEntrada, setOpenEditEntrada] = useState(false);
  const [openEditSalida, setOpenEditSalida] = useState(false);
  const [newConceptoEntrada, setNewConceptoEntrada] = useState('');
  const [newConceptoSalida, setNewConceptoSalida] = useState('');
  const [editConceptoEntrada, setEditConceptoEntrada] = useState('');
  const [editConceptoSalida, setEditConceptoSalida] = useState('');
  const [editIdEntrada, setEditIdEntrada] = useState(null);
  const [editIdSalida, setEditIdSalida] = useState(null);

  useEffect(() => {
    fetchConceptosEntradas();
    fetchConceptosSalidas();
  }, []);

  const fetchConceptosEntradas = async () => {
    const { data, error } = await supabase.from('conceptos_de_entradas').select('*');
    if (error) console.log('Error fetching conceptos_de_entradas:', error);
    else setEntradasConceptos(data || []);
  };

  const fetchConceptosSalidas = async () => {
    const { data, error } = await supabase.from('conceptos_de_salidas').select('*');
    if (error) console.log('Error fetching conceptos_de_salidas:', error);
    else setSalidasConceptos(data || []);
  };

  const handleAddConceptoEntrada = async () => {
    if (!newConceptoEntrada.trim()) {
      alert('Por favor, ingresa un concepto válido.');
      return;
    }
    const { error } = await supabase
      .from('conceptos_de_entradas')
      .insert([{ concepto_de_entrada: newConceptoEntrada.trim() }]);
    if (error) {
      console.log('Error adding concepto_de_entradas:', error);
      alert('Error al agregar el concepto: ' + error.message);
    } else {
      fetchConceptosEntradas();
      setOpenAddEntrada(false);
      setNewConceptoEntrada('');
    }
  };

  const handleAddConceptoSalida = async () => {
    if (!newConceptoSalida.trim()) {
      alert('Por favor, ingresa un concepto válido.');
      return;
    }
    const { error } = await supabase
      .from('conceptos_de_salidas')
      .insert([{ concepto_de_salida: newConceptoSalida.trim() }]);
    if (error) {
      console.log('Error adding concepto_de_salidas:', error);
      alert('Error al agregar el concepto: ' + error.message);
    } else {
      fetchConceptosSalidas();
      setOpenAddSalida(false);
      setNewConceptoSalida('');
    }
  };

  const handleEditConceptoEntrada = async () => {
    if (!editConceptoEntrada.trim()) {
      alert('Por favor, ingresa un concepto válido.');
      return;
    }
    const { error } = await supabase
      .from('conceptos_de_entradas')
      .update({ concepto_de_entrada: editConceptoEntrada.trim() })
      .eq('id', editIdEntrada);
    if (error) {
      console.log('Error updating concepto_de_entradas:', error);
      alert('Error al actualizar el concepto: ' + error.message);
    } else {
      fetchConceptosEntradas();
      setOpenEditEntrada(false);
      setEditConceptoEntrada('');
      setEditIdEntrada(null);
    }
  };

  const handleEditConceptoSalida = async () => {
    if (!editConceptoSalida.trim()) {
      alert('Por favor, ingresa un concepto válido.');
      return;
    }
    const { error } = await supabase
      .from('conceptos_de_salidas')
      .update({ concepto_de_salida: editConceptoSalida.trim() })
      .eq('id', editIdSalida);
    if (error) {
      console.log('Error updating concepto_de_salidas:', error);
      alert('Error al actualizar el concepto: ' + error.message);
    } else {
      fetchConceptosSalidas();
      setOpenEditSalida(false);
      setEditConceptoSalida('');
      setEditIdSalida(null);
    }
  };

  const handleDeleteConceptoEntrada = async (id) => {
    const { error } = await supabase.from('conceptos_de_entradas').delete().eq('id', id);
    if (error) console.log('Error deleting concepto_de_entradas:', error);
    else fetchConceptosEntradas();
  };

  const handleDeleteConceptoSalida = async (id) => {
    const { error } = await supabase.from('conceptos_de_salidas').delete().eq('id', id);
    if (error) console.log('Error deleting concepto_de_salidas:', error);
    else fetchConceptosSalidas();
  };

  return (
    <Container maxWidth="lg" sx={{ py: 3, px: 0, backgroundColor: '#ffffff' }}>
      <Typography variant="h4" fontWeight="bold" color="text.primary" gutterBottom>
        Conceptos
      </Typography>
      <Grid container spacing={4}>
        {/* Conceptos de Entrada */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" fontWeight="medium" color="text.primary">
              Conceptos de Entrada
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenAddEntrada(true)}
              sx={{ mt: 1, borderRadius: 4, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#2e7d32' } }}
            >
              Agregar Concepto
            </Button>
          </Box>
          {entradasConceptos.map((concepto) => (
            <ConceptItem key={concepto.id}>
              <Typography variant="body1" color="text.primary">
                {concepto.concepto_de_entrada}
              </Typography>
              <Box>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setEditIdEntrada(concepto.id);
                    setEditConceptoEntrada(concepto.concepto_de_entrada);
                    setOpenEditEntrada(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton color="error" onClick={() => handleDeleteConceptoEntrada(concepto.id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </ConceptItem>
          ))}
        </Grid>

        {/* Conceptos de Salida */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 2 }}>
            <Typography variant="h6" fontWeight="medium" color="text.primary">
              Conceptos de Salida
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenAddSalida(true)}
              sx={{ mt: 1, borderRadius: 4, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#2e7d32' } }}
            >
              Agregar Concepto
            </Button>
          </Box>
          {salidasConceptos.map((concepto) => (
            <ConceptItem key={concepto.id}>
              <Typography variant="body1" color="text.primary">
                {concepto.concepto_de_salida}
              </Typography>
              <Box>
                <IconButton
                  color="primary"
                  onClick={() => {
                    setEditIdSalida(concepto.id);
                    setEditConceptoSalida(concepto.concepto_de_salida);
                    setOpenEditSalida(true);
                  }}
                >
                  <EditIcon />
                </IconButton>
                <IconButton color="error" onClick={() => handleDeleteConceptoSalida(concepto.id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </ConceptItem>
          ))}
        </Grid>
      </Grid>

      {/* Diálogo Agregar Concepto Entrada */}
      <ConceptDialog open={openAddEntrada} onClose={() => setOpenAddEntrada(false)}>
        <DialogTitle>
          <Typography variant="h5" fontWeight="bold" color="text.primary" textAlign="center">
            Agregar Concepto de Entrada
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Concepto"
            value={newConceptoEntrada}
            onChange={(e) => setNewConceptoEntrada(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button onClick={() => setOpenAddEntrada(false)} color="inherit">
              Cancelar
            </Button>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddConceptoEntrada}
              sx={{ borderRadius: 4, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#2e7d32' } }}
            >
              Guardar
            </Button>
          </motion.div>
        </DialogActions>
      </ConceptDialog>

      {/* Diálogo Agregar Concepto Salida */}
      <ConceptDialog open={openAddSalida} onClose={() => setOpenAddSalida(false)}>
        <DialogTitle>
          <Typography variant="h5" fontWeight="bold" color="text.primary" textAlign="center">
            Agregar Concepto de Salida
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Concepto"
            value={newConceptoSalida}
            onChange={(e) => setNewConceptoSalida(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button onClick={() => setOpenAddSalida(false)} color="inherit">
              Cancelar
            </Button>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddConceptoSalida}
              sx={{ borderRadius: 4, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#2e7d32' } }}
            >
              Guardar
            </Button>
          </motion.div>
        </DialogActions>
      </ConceptDialog>

      {/* Diálogo Editar Concepto Entrada */}
      <ConceptDialog open={openEditEntrada} onClose={() => setOpenEditEntrada(false)}>
        <DialogTitle>
          <Typography variant="h5" fontWeight="bold" color="text.primary" textAlign="center">
            Editar Concepto de Entrada
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Concepto"
            value={editConceptoEntrada}
            onChange={(e) => setEditConceptoEntrada(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button onClick={() => setOpenEditEntrada(false)} color="inherit">
              Cancelar
            </Button>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditConceptoEntrada}
              sx={{ borderRadius: 4, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#2e7d32' } }}
            >
              Guardar
            </Button>
          </motion.div>
        </DialogActions>
      </ConceptDialog>

      {/* Diálogo Editar Concepto Salida */}
      <ConceptDialog open={openEditSalida} onClose={() => setOpenEditSalida(false)}>
        <DialogTitle>
          <Typography variant="h5" fontWeight="bold" color="text.primary" textAlign="center">
            Editar Concepto de Salida
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Concepto"
            value={editConceptoSalida}
            onChange={(e) => setEditConceptoSalida(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button onClick={() => setOpenEditSalida(false)} color="inherit">
              Cancelar
            </Button>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEditConceptoSalida}
              sx={{ borderRadius: 4, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#2e7d32' } }}
            >
              Guardar
            </Button>
          </motion.div>
        </DialogActions>
      </ConceptDialog>
    </Container>
  );
};

export default Conceptos;