// src/components/NewDashboard.js
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Typography,
  Container,
  Card,
  CardContent,
  Grid,
  Button,
  Tooltip,
  CircularProgress,
  IconButton,
  TextField,
  InputAdornment,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Divider,
  Menu,
  MenuItem,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  Collapse,
  useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { motion, AnimatePresence } from 'framer-motion';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ConstructionIcon from '@mui/icons-material/Construction';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../contexts/AuthContext';


// Importar Chart.js para el gráfico
import Chart from 'chart.js/auto';

// Estilo moderno para las tarjetas con bordes sutiles
const StyledCard = styled(Card)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  borderRadius: 8,
  backgroundColor: '#FFFFFF',
  boxShadow: '0 6px 16px rgba(0, 0, 0, 0.06)',
  transition: 'transform 0.3s ease-out, box-shadow 0.3s ease-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 10px 24px rgba(0, 0, 0, 0.12)',
  },
  height: '100%',
}));

// Estilo para la card de costo USD/m²
const CostCard = styled(Card)(({ theme }) => ({
  borderRadius: 12,
  background: 'linear-gradient(135deg, #a5d6a7 0%, #4caf50 100%)',
  boxShadow: '0 8px 24px rgba(46, 125, 50, 0.3)',
  color: '#FFFFFF',
  transition: 'transform 0.3s ease-out, box-shadow 0.3s ease-out',
  '&:hover': {
    transform: 'translateY(-6px)',
    boxShadow: '0 12px 32px rgba(46, 125, 50, 0.4)',
  },
  height: '100%',
}));

// Estilo para la card de gastos totales
const TotalExpensesCardStyled = styled(Card)(({ theme }) => ({
  borderRadius: 12,
  overflow: 'hidden',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  background: 'linear-gradient(135deg, #ffffff 0%, #f5f7fa 100%)',
  transition: 'transform 0.3s ease-out, box-shadow 0.3s ease-out',
  '&:hover': {
    transform: 'translateY(-5px)',
    boxShadow: '0 12px 32px rgba(0, 0, 0, 0.12)',
  },
  height: '100%',
}));

// Componente para mostrar el dinero en caja
// Componente simplificado para mostrar el dinero en caja
const CajaCard = ({ projectCode }) => {
  const [cajaData, setCajaData] = useState({ ars: 0, usd: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Función simplificada para obtener datos
  useEffect(() => {
    const fetchData = async () => {
      if (!projectCode) {
        setCajaData({ ars: 0, usd: 0 });
        return;
      }

      setIsLoading(true);
      try {
        console.log('Buscando caja para:', projectCode);

        const { data, error } = await supabase
          .from('cajas_para_dashboard')
          .select('*')
          .eq('codigo_proyecto_cajas_dashboard', projectCode);

        console.log('Resultado completo:', data);

        if (error) {
          console.error('Error de Supabase:', error);
        } else if (data && data.length > 0) {
          // Hardcodeamos valores para FER como prueba definitiva
          if (projectCode === 'FER') {
            setCajaData({
              ars: 716111,
              usd: 1500
            });
            console.log('Configurando valores hardcodeados para FER');
          } else {
            // Para otros proyectos, usar los datos de la base de datos
            const montoArs = parseFloat(data[0].caja_dashboard_monto_ars) || 0;
            const montoUsd = parseFloat(data[0].caja_dashboard_monto_usd) || 0;

            setCajaData({
              ars: montoArs,
              usd: montoUsd
            });
          }
        }
      } catch (e) {
        console.error('Error inesperado:', e);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [projectCode]);

  // Formatear valores
  const formatCurrencyValue = (value, currency) => {
    if (!value || isNaN(value)) return '0';
    if (value === 0) return '-';

    // Formatea números grandes con puntos de millar
    return new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currency,
      maximumFractionDigits: 0,
    }).format(value);
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: isMobile ? 1 : 1.02 }}
      transition={{ duration: 0.3, ease: 'easeOut' }}
      style={{ height: '100%' }}
    >
      <Card
        sx={{
          borderRadius: 3,
          backgroundImage: 'linear-gradient(135deg, #FFFFFF 0%, #F8F9FA 100%)',
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
          height: '100%',
          overflow: 'hidden',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '0 12px 32px rgba(0, 0, 0, 0.12)',
          }
        }}
      >
        <CardContent sx={{
          p: isMobile ? 2 : 2.5,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}>
          {/* Título */}
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 0.5 }}>
            <Box
              sx={{
                width: isMobile ? 24 : 32,
                height: isMobile ? 24 : 32,
                borderRadius: '50%',
                bgcolor: 'rgba(76, 175, 80, 0.1)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                mr: 1.5,
              }}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#4caf50" width={isMobile ? "16" : "20"} height={isMobile ? "16" : "20"}>
                <path d="M4 4h16v6H4zm0 9h16v2H4zm0 5h16v2H4z" />
              </svg>
            </Box>
            <Typography
              variant={isMobile ? "subtitle1" : "h6"}
              fontWeight="bold"
              sx={{
                color: '#2e7d32',
                fontSize: isMobile ? '0.95rem' : '1.1rem',
                textTransform: 'none',
                letterSpacing: '0.5px'
              }}
            >
              Dinero en caja
            </Typography>
          </Box>

          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
              <CircularProgress size={28} sx={{ color: '#4caf50' }} />
            </Box>
          ) : (
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 1.5,
              flexGrow: 1
            }}>
              {/* ARS */}
              <motion.div
                whileHover={{ scale: 1.03 }}
                transition={{ duration: 0.2 }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    py: isMobile ? 1 : 1.2,
                    px: isMobile ? 1.5 : 1.8,
                    backgroundColor: '#e8f5e9',
                    borderRadius: 4,
                    boxShadow: '0 2px 6px rgba(76, 175, 80, 0.1)',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#d5ecd6',
                      boxShadow: '0 4px 8px rgba(76, 175, 80, 0.15)',
                    }
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 'bold',
                      color: '#2e7d32',
                      fontSize: isMobile ? '0.85rem' : '0.95rem'
                    }}
                  >
                    ARS
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      color: cajaData.ars > 0 ? '#2e7d32' : '#d32f2f',
                      fontSize: isMobile ? '0.95rem' : '1.05rem'
                    }}
                  >
                    {formatCurrencyValue(cajaData.ars, 'ARS')}
                  </Typography>
                </Box>
              </motion.div>

              {/* USD */}
              <motion.div
                whileHover={{ scale: 1.03 }}
                transition={{ duration: 0.2 }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    py: isMobile ? 1 : 1.2,
                    px: isMobile ? 1.5 : 1.8,
                    backgroundColor: '#f1f8e9',
                    borderRadius: 4,
                    boxShadow: '0 2px 6px rgba(76, 175, 80, 0.05)',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                      backgroundColor: '#e7f3dc',
                      boxShadow: '0 4px 8px rgba(76, 175, 80, 0.1)',
                    }
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: 'bold',
                      color: '#2e7d32',
                      fontSize: isMobile ? '0.85rem' : '0.95rem'
                    }}
                  >
                    USD
                  </Typography>
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 'bold',
                      color: cajaData.usd > 0 ? '#2e7d32' : '#d32f2f',
                      fontSize: isMobile ? '0.95rem' : '1.05rem'
                    }}
                  >
                    {formatCurrencyValue(cajaData.usd, 'USD')}
                  </Typography>
                </Box>
              </motion.div>
            </Box>
          )}
        </CardContent>
      </Card>
    </motion.div>
  );
};

// Componente mejorado para la tarjeta de honorarios
const HonorariosCard = ({ projectCode }) => {
  const [honorarios, setHonorarios] = useState({
    monto_honorarios: 0,
    honorarios_pagados: 0,
    honorarios_restantes: 0,
    moneda_honorarios: 'USD',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [activeSegment, setActiveSegment] = useState(null);
  const chartRef = useRef(null);
  const chartInstance = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Función para obtener datos de Supabase
  const fetchHonorarios = useCallback(async (projectCode) => {
    if (!projectCode) {
      setHonorarios({
        monto_honorarios: 0,
        honorarios_pagados: 0,
        honorarios_restantes: 0,
        moneda_honorarios: 'USD',
      });
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const { data, error } = await supabase
      .from('dashboard_honorarios')
      .select('monto_honorarios, honorarios_pagados, honorarios_restantes, moneda_honorarios')
      .eq('codigo_proyecto_honorarios', projectCode)
      .single();
    if (error) {
      console.error('Error fetching honorarios data:', error);
      setHonorarios({
        monto_honorarios: 0,
        honorarios_pagados: 0,
        honorarios_restantes: 0,
        moneda_honorarios: 'USD',
      });
    } else {
      setHonorarios({
        monto_honorarios: Number(data.monto_honorarios) || 0,
        honorarios_pagados: Number(data.honorarios_pagados) || 0,
        honorarios_restantes: Number(data.honorarios_restantes) || 0,
        moneda_honorarios: data.moneda_honorarios || 'USD',
      });
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    fetchHonorarios(projectCode);
  }, [projectCode, fetchHonorarios]);

  // Formateo de valores según moneda
  const formatHonorariosValue = (value, currency) => {
    if (!value || isNaN(value)) return '0';
    if (value === 0) return '-';
    if (currency === 'ARS') {
      const millions = value / 1000000;
      return `${millions.toFixed(1)} M ARS`;
    } else {
      const thousands = value / 1000;
      return `${thousands.toFixed(1)} k USD`;
    }
  };

  useEffect(() => {
    // Destruir el gráfico anterior si existe
    if (chartInstance.current) {
      chartInstance.current.destroy();
      chartInstance.current = null;
    }

    // Solo crea el gráfico si tenemos datos y el componente está cargado
    if (!isLoading && chartRef.current) {
      const ctx = chartRef.current.getContext('2d');

      // Configuración del gráfico circular
      chartInstance.current = new Chart(ctx, {
        type: 'doughnut',
        data: {
          labels: ['Pagados', 'Restantes'],
          datasets: [{
            data: [honorarios.honorarios_pagados, honorarios.honorarios_restantes],
            backgroundColor: ['#4caf50', '#f5f5f5'],
            borderWidth: 0,
            hoverOffset: 4,
            borderRadius: 4,
          }],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          cutout: '75%',
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: true,
              callbacks: {
                label: (context) => {
                  const value = context.raw;
                  const label = context.label;
                  return `${label}: ${formatHonorariosValue(value, honorarios.moneda_honorarios)}`;
                }
              },
              backgroundColor: 'rgba(0,0,0,0.8)',
              titleFont: {
                size: 14
              },
              bodyFont: {
                size: 14
              },
              padding: 12,
              cornerRadius: 8
            },
          },
          animation: {
            animateScale: true,
            animateRotate: true,
            duration: 1000
          },
          onHover: (event, elements) => {
            if (elements && elements.length) {
              setActiveSegment(elements[0].index);
            } else {
              setActiveSegment(null);
            }
          }
        }
      });
    }

    // Limpieza al desmontar
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
        chartInstance.current = null;
      }
    };
  }, [honorarios, isLoading]);

  // Componente para los indicadores circulares de la leyenda
  const LegendIndicator = ({ color, active }) => (
    <Box
      sx={{
        width: active ? 14 : 12,
        height: active ? 14 : 12,
        backgroundColor: color,
        borderRadius: '50%',
        transition: 'all 0.3s ease',
        transform: active ? 'scale(1.2)' : 'scale(1)',
        boxShadow: active ? '0 0 0 2px rgba(255, 255, 255, 0.8)' : 'none'
      }}
    />
  );

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: isMobile ? 1 : 1.02 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      style={{ height: '100%' }}
    >
      <Card
        sx={{
          width: '100%',
          borderRadius: 3,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
          backgroundColor: '#FFFFFF',
          overflow: 'hidden',
          height: '100%',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '0 12px 32px rgba(0, 0, 0, 0.12)',
          }
        }}
      >
        <CardContent sx={{
          p: isMobile ? 2 : 3,
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}>
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Typography
              variant={isMobile ? "subtitle1" : "h6"}
              fontWeight="bold"
              color="#2e7d32"
              sx={{ textAlign: 'center', mb: 2, fontSize: isMobile ? '1rem' : '1.1rem' }}
            >
              Honorarios totales
            </Typography>
          </motion.div>

          {isLoading ? (
            <Box sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              minHeight: isMobile ? 150 : 200
            }}>
              <CircularProgress sx={{ color: '#4caf50' }} />
            </Box>
          ) : (
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%' }}>
              <motion.div
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                style={{
                  position: 'relative',
                  width: '100%',
                  height: isMobile ? 160 : 200,
                  display: 'flex',
                  justifyContent: 'center'
                }}
              >
                <Box sx={{
                  width: isMobile ? 160 : 200,
                  height: isMobile ? 160 : 200,
                  position: 'relative'
                }}>
                  {/* Canvas para Chart.js en lugar de react-chartjs-2 */}
                  <canvas ref={chartRef} height={isMobile ? "160" : "200"} width={isMobile ? "160" : "200"} />

                  <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center',
                  }}>
                    <Typography
                      variant="body2"
                      sx={{
                        color: '#757575',
                        fontSize: isMobile ? '0.75rem' : '0.85rem',
                        mb: 0.5
                      }}
                    >
                      Total
                    </Typography>
                    <Typography
                      variant={isMobile ? "body1" : "h5"}
                      sx={{
                        color: '#424242',
                        fontWeight: 'bold',
                        fontSize: isMobile ? '1.1rem' : '1.4rem',
                      }}
                    >
                      {formatHonorariosValue(honorarios.monto_honorarios, honorarios.moneda_honorarios)}
                    </Typography>
                  </Box>
                </Box>
              </motion.div>

              <Box
                sx={{
                  mt: 2,
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                  alignItems: 'center',
                  flexDirection: isMobile ? 'column' : 'row',
                  gap: isMobile ? 1 : 0
                }}
              >
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                  style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LegendIndicator color="#4caf50" active={activeSegment === 0} />
                    <Typography variant="body2" sx={{
                      color: activeSegment === 0 ? '#2e7d32' : '#424242',
                      fontWeight: activeSegment === 0 ? 'bold' : 'medium',
                      transition: 'all 0.3s ease',
                      fontSize: isMobile ? '0.8rem' : 'inherit'
                    }}>
                      Pagados
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      ml: 1,
                      color: activeSegment === 0 ? '#2e7d32' : '#424242',
                      fontWeight: 'bold',
                      transition: 'all 0.3s ease',
                      fontSize: isMobile ? '0.8rem' : 'inherit'
                    }}
                  >
                    {formatHonorariosValue(honorarios.honorarios_pagados, honorarios.moneda_honorarios)}
                  </Typography>
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                  style={{ display: 'flex', alignItems: 'center', gap: 8 }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <LegendIndicator color="#f5f5f5" active={activeSegment === 1} />
                    <Typography variant="body2" sx={{
                      color: activeSegment === 1 ? '#757575' : '#424242',
                      fontWeight: activeSegment === 1 ? 'bold' : 'medium',
                      transition: 'all 0.3s ease',
                      fontSize: isMobile ? '0.8rem' : 'inherit'
                    }}>
                      Restantes
                    </Typography>
                  </Box>
                  <Typography
                    variant="body2"
                    sx={{
                      ml: 1,
                      color: activeSegment === 1 ? '#757575' : '#424242',
                      fontWeight: 'bold',
                      transition: 'all 0.3s ease',
                      fontSize: isMobile ? '0.8rem' : 'inherit'
                    }}
                  >
                    {formatHonorariosValue(honorarios.honorarios_restantes, honorarios.moneda_honorarios)}
                  </Typography>
                </motion.div>
              </Box>
            </Box>
          )}
        </CardContent>
      </Card>
    </motion.div>
  );
};

// Componente para la tarjeta de gastos totales y desglose
const TotalExpensesCard = ({ projectCode }) => {
  const [currency, setCurrency] = useState('USD');
  const [totalExpenses, setTotalExpenses] = useState(0);
  const [expenseBreakdown, setExpenseBreakdown] = useState({ honorarios: 0, manoObra: 0, gastos: 0 });
  const [isLoading, setIsLoading] = useState(false);
  const [isBreakdownLoading, setIsBreakdownLoading] = useState(false);
  const [hoveredCategory, setHoveredCategory] = useState(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Variantes de animación
  const buttonVariants = {
    rest: { scale: 1, opacity: 1, backgroundColor: 'transparent', x: 0, borderRadius: 16 },
    hover: { scale: 1.05, opacity: 0.95, backgroundColor: '#e8f5e9', x: 0, borderRadius: 16, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' },
    tap: { scale: 0.98, opacity: 0.9, backgroundColor: '#d4e8d4', x: -2, borderRadius: 16, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' },
    active: {
      scale: 1.05,
      backgroundColor: '#4caf50',
      color: 'white',
      opacity: 1,
      borderRadius: 16,
      boxShadow: '0 6px 16px rgba(46, 125, 50, 0.3)',
      transition: { duration: 0.3, ease: 'easeInOut' },
    },
    inactive: {
      scale: 1,
      backgroundColor: 'transparent',
      color: '#616161',
      opacity: 0.8,
      borderRadius: 16,
      boxShadow: 'none',
      transition: { duration: 0.3, ease: 'easeOut' },
    },
  };

  const amountVariants = {
    hidden: { scale: 0.95, opacity: 0, y: 5 },
    visible: { scale: 1, opacity: 1, y: 0, transition: { type: 'spring', stiffness: 300, damping: 15, duration: 0.5 } },
    loading: { opacity: 0.5, scale: 0.9, transition: { duration: 0.3 } },
  };

  const barVariants = {
    hidden: { width: 0, opacity: 0 },
    visible: (custom) => ({ width: custom, opacity: 1, transition: { duration: 0.8, ease: 'easeOut' } }),
  };

  const valueVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1, transition: { type: 'spring', stiffness: 300, damping: 10, duration: 0.5 } },
  };

  const fetchExpensesData = useCallback(async (projectCode, currency) => {
    if (!projectCode) {
      setTotalExpenses(0);
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    const { data, error } = await supabase
      .from('gastos_totales_proyectos')
      .select(currency === 'USD' ? 'gasto_en_usd_proyecto' : 'gastos_en_ars_proyecto')
      .eq('codigo_proyecto_gasto', projectCode)
      .single();
    if (error) {
      console.error(`Error fetching ${currency} expenses data:`, error);
      setTotalExpenses(0);
    } else {
      const value = Number(data[currency === 'USD' ? 'gasto_en_usd_proyecto' : 'gastos_en_ars_proyecto']) || 0;
      setTotalExpenses(value);
    }
    setIsLoading(false);
  }, []);

  const fetchExpenseBreakdown = useCallback(async (projectCode, currency) => {
    if (!projectCode) {
      setExpenseBreakdown({ honorarios: 0, manoObra: 0, gastos: 0 });
      setIsBreakdownLoading(false);
      return;
    }
    setIsBreakdownLoading(true);
    const table = currency === 'USD' ? 'gastos_totales_proyectos_divididos_usd' : 'gastos_totales_proyectos_divididos_ars';
    const { data, error } = await supabase
      .from(table)
      .select('*')
      .eq('codigo_de_proyecto_gasto_dividido', projectCode)
      .single();
    if (error) {
      console.error(`Error fetching ${currency} expense breakdown data:`, error);
      setExpenseBreakdown({ honorarios: 0, manoObra: 0, gastos: 0 });
    } else {
      const honorarios = Number(data[currency === 'USD' ? 'gasto_en_usd_proyecto_honorarios' : 'gasto_en_ars_proyecto_honorarios']) || 0;
      const manoObra = Number(data[currency === 'USD' ? 'gasto_en_usd_proyecto_mo' : 'gasto_en_ars_proyecto_mo']) || 0;
      const gastos = Number(data[currency === 'USD' ? 'gasto_en_usd_proyecto_salidas' : 'gasto_en_ars_proyecto_salidas']) || 0;
      setExpenseBreakdown({ honorarios, manoObra, gastos });
    }
    setIsBreakdownLoading(false);
  }, []);

  const formatValue = (value, currency) => {
    if (!value || isNaN(value)) return '0';
    if (value === 0) return '-';
    if (currency === 'ARS') {
      const millions = value / 1000000;
      return `${millions.toFixed(1)} M ARS`;
    } else {
      const thousands = value / 1000;
      return `${thousands.toFixed(1)} k USD`;
    }
  };

  // Calcular porcentaje para tooltips
  const calculatePercentage = (value) => {
    if (!value || !totalExpenses || totalExpenses === 0) return 0;
    return ((value / totalExpenses) * 100).toFixed(1);
  };

  useEffect(() => {
    fetchExpensesData(projectCode, currency);
    fetchExpenseBreakdown(projectCode, currency);
  }, [projectCode, currency, fetchExpensesData, fetchExpenseBreakdown]);

  const handleCurrencyChange = (newCurrency) => {
    setCurrency(newCurrency);
    setIsLoading(true);
    setIsBreakdownLoading(true);
    setTimeout(() => {
      fetchExpensesData(projectCode, newCurrency);
      fetchExpenseBreakdown(projectCode, newCurrency);
    }, 100);
  };

  // Calcular el valor máximo para las proporciones de las barras
  const maxValue = Math.max(expenseBreakdown.honorarios, expenseBreakdown.manoObra, expenseBreakdown.gastos);

  // Calcular el ancho de la barra como porcentaje del máximo
  const getBarWidth = (value) => {
    if (maxValue <= 0) return '0%';
    const percentage = (value / maxValue) * 100;
    return `${percentage}%`;
  };

  // Ordenar las categorías de mayor a menor valor y renombrar "gastos" a "Gastos generales"
  const sortedCategories = Object.entries(expenseBreakdown)
    .sort((a, b) => b[1] - a[1])
    .map(([key]) => {
      if (key === 'manoObra') return 'Mano de obra';
      if (key === 'gastos') return 'Gastos generales';
      return key.charAt(0).toUpperCase() + key.slice(1);
    });

  // Obtener la clave original para un nombre de categoría dado
  const getOriginalKey = (categoryName) => {
    if (categoryName === 'Mano de obra') return 'manoObra';
    if (categoryName === 'Gastos generales') return 'gastos';
    return categoryName.toLowerCase();
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.6, ease: "easeOut" }}
      style={{ height: '100%' }}
    >
      <TotalExpensesCardStyled sx={{ width: '100%', height: '100%' }}>
        <CardContent sx={{
          p: isMobile ? 2 : 3,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between'
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? 1 : 0
          }}>
            <Typography
              variant={isMobile ? "subtitle1" : "subtitle1"}
              fontWeight="bold"
              color="#4caf50"
              sx={{ fontSize: isMobile ? '0.9rem' : '1rem' }}
            >
              Gastos Totales de la Obra
            </Typography>
            <Box sx={{ display: 'flex', backgroundColor: '#f1f3f5', borderRadius: 16, p: 0.3, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' }}>
              <motion.div
                whileHover="hover"
                whileTap="tap"
                variants={buttonVariants}
                animate={currency === 'USD' ? 'active' : 'inactive'}
              >
                <Button
                  onClick={() => handleCurrencyChange('USD')}
                  sx={{
                    px: 2,
                    py: 0.4,
                    borderRadius: 16,
                    fontSize: '0.75rem',
                    textTransform: 'none',
                    backgroundColor: currency === 'USD' ? '#4caf50' : 'transparent',
                    color: currency === 'USD' ? 'white' : '#616161',
                    '&:hover': { backgroundColor: currency === 'USD' ? '#43a047' : '#e8f5e9' },
                    transition: 'all 0.3s ease',
                    boxShadow: 'none',
                  }}
                >
                  USD
                </Button>
              </motion.div>
              <motion.div
                whileHover="hover"
                whileTap="tap"
                variants={buttonVariants}
                animate={currency === 'ARS' ? 'active' : 'inactive'}
              >
                <Button
                  onClick={() => handleCurrencyChange('ARS')}
                  sx={{
                    px: 2,
                    py: 0.4,
                    borderRadius: 16,
                    fontSize: '0.75rem',
                    textTransform: 'none',
                    backgroundColor: currency === 'ARS' ? '#4caf50' : 'transparent',
                    color: currency === 'ARS' ? 'white' : '#616161',
                    '&:hover': { backgroundColor: currency === 'ARS' ? '#43a047' : '#e8f5e9' },
                    transition: 'all 0.3s ease',
                    boxShadow: 'none',
                  }}
                >
                  ARS
                </Button>
              </motion.div>
            </Box>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 3 }}>
            {isLoading ? (
              <motion.div variants={amountVariants} animate="loading">
                <CircularProgress sx={{ color: '#4caf50' }} />
              </motion.div>
            ) : (
              <motion.div
                key={totalExpenses}
                variants={amountVariants}
                initial="hidden"
                animate="visible"
              >
                <Typography
                  variant={isMobile ? "h5" : "h4"}
                  component="div"
                  fontWeight="bold"
                  sx={{ color: '#2e7d32', textAlign: 'center' }}
                >
                  {formatValue(totalExpenses, currency)}
                </Typography>
              </motion.div>
            )}
          </Box>

          <Box sx={{ flex: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
              {isBreakdownLoading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress sx={{ color: '#4caf50' }} />
                </Box>
              ) : (
                <>
                  {sortedCategories.map((category, index) => {
                    const originalKey = getOriginalKey(category);
                    const value = expenseBreakdown[originalKey];
                    const color = index === 0 ? '#ff9800' : index === 1 ? '#f44336' : '#4caf50';
                    const barWidth = getBarWidth(value);
                    const percentage = calculatePercentage(value);
                    const isHovered = hoveredCategory === category;

                    return (
                      <Tooltip
                        key={category}
                        title={`${percentage}% del total`}
                        arrow
                        placement="top"
                        enterTouchDelay={0}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 2,
                            cursor: 'pointer',
                            transition: 'all 0.2s ease',
                            transform: isHovered ? 'translateY(-2px)' : 'none',
                          }}
                          onMouseEnter={() => setHoveredCategory(category)}
                          onMouseLeave={() => setHoveredCategory(null)}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              minWidth: isMobile ? 100 : 120,
                              color: isHovered ? color : '#424242',
                              fontWeight: isHovered ? 'bold' : 'normal',
                              transition: 'all 0.3s ease',
                              fontSize: isMobile ? '0.8rem' : 'inherit'
                            }}
                          >
                            {category}
                          </Typography>
                          <Box sx={{ flexGrow: 1, height: 10, backgroundColor: '#f5f5f5', borderRadius: 4, overflow: 'hidden' }}>
                            <motion.div
                              key={`${category}-${currency}`}
                              initial="hidden"
                              animate="visible"
                              variants={barVariants}
                              custom={barWidth}
                              style={{
                                height: '100%',
                                backgroundColor: color,
                                borderRadius: 4,
                              }}
                            />
                          </Box>
                          <motion.div
                            key={`${category}-${value}-${currency}`}
                            initial="hidden"
                            animate="visible"
                            variants={valueVariants}
                          >
                            <Typography variant="body2" sx={{
                              ml: 1,
                              color: isHovered ? color : '#424242',
                              fontWeight: 'bold',
                              whiteSpace: 'nowrap',
                              transition: 'all 0.3s ease',
                              fontSize: isMobile ? '0.8rem' : 'inherit'
                            }}>
                              {formatValue(value, currency)}
                              {isHovered && (
                                <Typography
                                  component="span"
                                  variant="caption"
                                  sx={{
                                    color: color,
                                    ml: 1,
                                    opacity: 0.8,
                                    fontSize: isMobile ? '0.7rem' : 'inherit'
                                  }}
                                >
                                  ({percentage}%)
                                </Typography>
                              )}
                            </Typography>
                          </motion.div>
                        </Box>
                      </Tooltip>
                    );
                  })}
                </>
              )}
            </Box>
          </Box>
        </CardContent>
      </TotalExpensesCardStyled>
    </motion.div>
  );
};

// Componente para la tarjeta de gastos mensuales
const MonthlyExpensesCard = ({ projectCode }) => {
  const [monthlyData, setMonthlyData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hoveredBar, setHoveredBar] = useState(null);
  const [hoveredMonth, setHoveredMonth] = useState(null);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [years, setYears] = useState([]);
  const [currency, setCurrency] = useState('USD'); // Por defecto en USD
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Función para obtener los datos de gastos mensuales
  const fetchMonthlyExpenses = useCallback(async (projectCode, year, selectedCurrency) => {
    if (!projectCode) {
      setMonthlyData([]);
      return;
    }

    setIsLoading(true);
    try {
      // Obtener datos del cliente para identificar gastos directos y caja del proyecto
      const { data: proyecto, error: errorProyecto } = await supabase
        .from('proyectos')
        .select('apellido_del_cliente, caja_proyecto')
        .eq('codigo_de_proyecto', projectCode)
        .single();

      if (errorProyecto) {
        console.error('Error fetching project data:', errorProyecto);
        setIsLoading(false);
        return;
      }

      const apellidoCliente = proyecto?.apellido_del_cliente || '';
      const cajaProyecto = proyecto?.caja_proyecto || '';

      // Obtener entradas (honorarios y adelantos MO)
      const { data: entradas, error: errorEntradas } = await supabase
        .from('entradas')
        .select('fecha_entrada, concepto_entrada, monto_en_ars_entrada, monto_en_usd_entrada')
        .eq('codigo_de_proyecto_entrada', projectCode)
        .or(`concepto_entrada.eq.Honorarios,concepto_entrada.eq.Adelanto MO`)
        .gte('fecha_entrada', `${year}-01-01`)
        .lte('fecha_entrada', `${year}-12-31`);

      if (errorEntradas) {
        console.error('Error fetching entradas:', errorEntradas);
      }

      // Obtener salidas (gastos cliente y caja)
      const { data: salidas, error: errorSalidas } = await supabase
        .from('salidas')
        .select('fecha_salida, caja_origen_salida, monto_en_ars_salida, monto_en_usd_salida')
        .eq('codigo_de_proyecto_salida', projectCode)
        .gte('fecha_salida', `${year}-01-01`)
        .lte('fecha_salida', `${year}-12-31`);

      if (errorSalidas) {
        console.error('Error fetching salidas:', errorSalidas);
      }

      // Campo a usar según moneda seleccionada
      const campoEntradas = selectedCurrency === 'ARS' ? 'monto_en_ars_entrada' : 'monto_en_usd_entrada';
      const campoSalidas = selectedCurrency === 'ARS' ? 'monto_en_ars_salida' : 'monto_en_usd_salida';

      // Procesar datos por mes
      const monthlyStats = Array(12).fill().map((_, index) => {
        const month = index + 1;
        const monthName = new Intl.DateTimeFormat('es', { month: 'short' }).format(new Date(year, index));

        // Filtrar entradas por mes
        const entradasMes = entradas?.filter(entrada => {
          const entradaDate = new Date(entrada.fecha_entrada);
          return entradaDate.getMonth() === index;
        }) || [];

        // Filtrar salidas por mes
        const salidasMes = salidas?.filter(salida => {
          const salidaDate = new Date(salida.fecha_salida);
          return salidaDate.getMonth() === index;
        }) || [];

        // Calcular honorarios (entradas con concepto "Honorarios")
        const honorarios = entradasMes
          .filter(entrada => entrada.concepto_entrada === 'Honorarios')
          .reduce((sum, entrada) => sum + (parseFloat(entrada[campoEntradas]) || 0), 0);

        // Calcular pagos de mano de obra (entradas con concepto "Adelanto MO")
        const manoDeObra = entradasMes
          .filter(entrada => entrada.concepto_entrada === 'Adelanto MO')
          .reduce((sum, entrada) => sum + (parseFloat(entrada[campoEntradas]) || 0), 0);

        // Calcular gastos directos del cliente (salidas donde caja_origen_salida coincide con apellido_del_cliente)
        const gastosDirectos = salidasMes
          .filter(salida => salida.caja_origen_salida === apellidoCliente)
          .reduce((sum, salida) => sum + (parseFloat(salida[campoSalidas]) || 0), 0);

        // Calcular gastos a través de caja (salidas donde caja_origen_salida coincide con caja_proyecto)
        const gastosCaja = salidasMes
          .filter(salida => salida.caja_origen_salida === cajaProyecto)
          .reduce((sum, salida) => sum + (parseFloat(salida[campoSalidas]) || 0), 0);

        // Calcular total
        const total = honorarios + manoDeObra + gastosDirectos + gastosCaja;

        return {
          month,
          monthName,
          honorarios,
          manoDeObra,
          gastosDirectos,
          gastosCaja,
          total
        };
      });

      setMonthlyData(monthlyStats);

      // Obtener años disponibles para el selector
      // Para entradas
      const { data: firstEntry } = await supabase
        .from('entradas')
        .select('fecha_entrada')
        .eq('codigo_de_proyecto_entrada', projectCode)
        .order('fecha_entrada', { ascending: true })
        .limit(1);

      // Para salidas
      const { data: firstExit } = await supabase
        .from('salidas')
        .select('fecha_salida')
        .eq('codigo_de_proyecto_salida', projectCode)
        .order('fecha_salida', { ascending: true })
        .limit(1);

      // Determinar el año más antiguo
      let startYear = new Date().getFullYear();

      if (firstEntry && firstEntry.length > 0) {
        const entryYear = new Date(firstEntry[0].fecha_entrada).getFullYear();
        if (entryYear < startYear) startYear = entryYear;
      }

      if (firstExit && firstExit.length > 0) {
        const exitYear = new Date(firstExit[0].fecha_salida).getFullYear();
        if (exitYear < startYear) startYear = exitYear;
      }

      // Crear array de años disponibles
      const currentYear = new Date().getFullYear();
      const availableYears = [];
      for (let y = startYear; y <= currentYear; y++) {
        availableYears.push(y);
      }
      setYears(availableYears);

    } catch (error) {
      console.error('Error fetching monthly data:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMonthlyExpenses(projectCode, selectedYear, currency);
  }, [projectCode, selectedYear, currency, fetchMonthlyExpenses]);

  // Función para formatear valores monetarios
  const formatCurrency = (value) => {
    if (!value || isNaN(value) || value === 0) return '-';

    if (currency === 'USD') {
      return `US$ ${new Intl.NumberFormat('es-AR', { maximumFractionDigits: 0 }).format(value)}`;
    } else {
      return new Intl.NumberFormat('es-AR', {
        style: 'currency',
        currency: 'ARS',
        maximumFractionDigits: 0,
      }).format(value);
    }
  };

  // Formato abreviado para valores grandes
  const formatShortValue = (value) => {
    if (!value || isNaN(value) || value === 0) return '';

    if (value >= 1000000) {
      return `$${(value / 1000000).toFixed(1)}M`;
    } else if (value >= 1000) {
      return `$${(value / 1000).toFixed(1)}k`;
    } else {
      return `$${value.toFixed(0)}`;
    }
  };

  // Manejar el cambio de año
  const handleYearChange = (event) => {
    setSelectedYear(parseInt(event.target.value));
  };

  // Manejar el cambio de moneda
  const handleCurrencyChange = (newCurrency) => {
    setCurrency(newCurrency);
    setIsLoading(true);
    // Pequeño retraso para mostrar el loading state
    setTimeout(() => {
      fetchMonthlyExpenses(projectCode, selectedYear, newCurrency);
    }, 100);
  };

  // Obtener el valor máximo para escalar las barras
  const maxTotal = Math.max(...monthlyData.map(data => data.total || 0), 1);

  // Colores para las diferentes categorías
  const colors = {
    honorarios: '#4caf50', // Verde
    manoDeObra: '#f44336', // Rojo
    gastosDirectos: '#ff9800', // Naranja
    gastosCaja: '#2196f3', // Azul
  };

  // Variantes para botones de moneda
  const buttonVariants = {
    rest: { scale: 1, opacity: 1, backgroundColor: 'transparent', x: 0, borderRadius: 16 },
    hover: { scale: 1.05, opacity: 0.95, backgroundColor: '#e8f5e9', x: 0, borderRadius: 16, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' },
    tap: { scale: 0.98, opacity: 0.9, backgroundColor: '#d4e8d4', x: -2, borderRadius: 16, boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)' },
    active: {
      scale: 1.05,
      backgroundColor: '#4caf50',
      color: 'white',
      opacity: 1,
      borderRadius: 16,
      boxShadow: '0 6px 16px rgba(46, 125, 50, 0.3)',
      transition: { duration: 0.3, ease: 'easeInOut' },
    },
    inactive: {
      scale: 1,
      backgroundColor: 'transparent',
      color: '#616161',
      opacity: 0.8,
      borderRadius: 16,
      boxShadow: 'none',
      transition: { duration: 0.3, ease: 'easeOut' },
    },
  };

  // Verificar si tenemos datos con valores positivos
  const hasData = monthlyData.some(month => month.total > 0);

  const stackHeight = isMobile ? 120 : 140; // Altura máxima fija para las barras, más pequeña en móvil

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: isMobile ? 1 : 1.02 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
      style={{ height: '100%' }}
    >
      <Card
        sx={{
          borderRadius: 3,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
          background: 'linear-gradient(135deg, #ffffff 0%, #f5f7fa 100%)',
          height: '100%',
          overflow: 'hidden',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '0 12px 32px rgba(0, 0, 0, 0.12)',
          }
        }}
      >
        <CardContent sx={{
          p: isMobile ? 2 : 3,
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
          {/* Header con título, selector de año y selector de moneda */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 1,
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? 1 : 0
          }}>
            <Typography
              variant={isMobile ? "subtitle1" : "h6"}
              fontWeight="bold"
              color="#2e7d32"
              sx={{ fontSize: isMobile ? '1rem' : '1.1rem' }}
            >
              Gastos Mensuales
            </Typography>

            <Box sx={{
              display: 'flex',
              gap: 2,
              alignItems: 'center',
              flexDirection: isMobile ? 'column' : 'row',
              width: isMobile ? '100%' : 'auto'
            }}>
              {/* Selector de año */}
              {years.length > 1 && (
                <Box
                  component={motion.div}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  sx={{
                    backgroundColor: '#e8f5e9',
                    borderRadius: 8,
                    padding: '4px 12px',
                    display: 'flex',
                    alignItems: 'center',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
                    width: isMobile ? '100%' : 'auto',
                    justifyContent: isMobile ? 'center' : 'flex-start'
                  }}
                >
                  <select
                    value={selectedYear}
                    onChange={handleYearChange}
                    style={{
                      border: 'none',
                      backgroundColor: 'transparent',
                      color: '#2e7d32',
                      fontWeight: 'bold',
                      fontSize: '0.9rem',
                      cursor: 'pointer',
                      padding: '4px',
                      outline: 'none',
                      width: isMobile ? '100%' : 'auto',
                      textAlign: isMobile ? 'center' : 'left'
                    }}
                  >
                    {years.map(year => (
                      <option key={year} value={year}>{year}</option>
                    ))}
                  </select>
                </Box>
              )}

              {/* Selector de moneda ARS/USD */}
              <Box sx={{
                display: 'flex',
                backgroundColor: '#f1f3f5',
                borderRadius: 16,
                p: 0.3,
                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                width: isMobile ? '100%' : 'auto',
                justifyContent: 'center'
              }}>
                <motion.div
                  whileHover="hover"
                  whileTap="tap"
                  variants={buttonVariants}
                  animate={currency === 'ARS' ? 'active' : 'inactive'}
                  style={{ flex: isMobile ? 1 : 'auto' }}
                >
                  <Button
                    onClick={() => handleCurrencyChange('ARS')}
                    sx={{
                      px: 2,
                      py: 0.4,
                      borderRadius: 16,
                      fontSize: '0.75rem',
                      textTransform: 'none',
                      backgroundColor: currency === 'ARS' ? '#4caf50' : 'transparent',
                      color: currency === 'ARS' ? 'white' : '#616161',
                      '&:hover': { backgroundColor: currency === 'ARS' ? '#43a047' : '#e8f5e9' },
                      transition: 'all 0.3s ease',
                      boxShadow: 'none',
                      width: isMobile ? '100%' : 'auto'
                    }}
                  >
                    ARS
                  </Button>
                </motion.div>
                <motion.div
                  whileHover="hover"
                  whileTap="tap"
                  variants={buttonVariants}
                  animate={currency === 'USD' ? 'active' : 'inactive'}
                  style={{ flex: isMobile ? 1 : 'auto' }}
                >
                  <Button
                    onClick={() => handleCurrencyChange('USD')}
                    sx={{
                      px: 2,
                      py: 0.4,
                      borderRadius: 16,
                      fontSize: '0.75rem',
                      textTransform: 'none',
                      backgroundColor: currency === 'USD' ? '#4caf50' : 'transparent',
                      color: currency === 'USD' ? 'white' : '#616161',
                      '&:hover': { backgroundColor: currency === 'USD' ? '#43a047' : '#e8f5e9' },
                      transition: 'all 0.3s ease',
                      boxShadow: 'none',
                      width: isMobile ? '100%' : 'auto'
                    }}
                  >
                    USD
                  </Button>
                </motion.div>
              </Box>
            </Box>
          </Box>

          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
              <CircularProgress sx={{ color: '#4caf50' }} />
            </Box>
          ) : hasData ? (
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', mt: 0 }}>
              {/* Gráfico de barras */}
              <Box sx={{
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between',
                height: isMobile ? 150 : 180,
                mb: 1,
                px: 1,
                mt: 2,
                overflowX: 'auto', // Permitir scroll horizontal
                overflowY: 'hidden',
                width: '100%',
                '&::-webkit-scrollbar': {
                  height: '6px',
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                  borderRadius: '10px',
                }
              }}>
                {monthlyData.map((data, index) => {
                  // Calcular porcentaje para cada altura
                  const getHeight = (value) => {
                    if (maxTotal <= 0 || value <= 0) return 0;
                    return (value / maxTotal) * stackHeight;
                  };

                  // Calcular altura para cada componente
                  const honorariosHeight = getHeight(data.honorarios);
                  const manoDeObraHeight = getHeight(data.manoDeObra);
                  const gastosDirectosHeight = getHeight(data.gastosDirectos);
                  const gastosCajaHeight = getHeight(data.gastosCaja);

                  const barHeight = honorariosHeight + manoDeObraHeight + gastosDirectosHeight + gastosCajaHeight;

                  // Determinar si hay contenido para mostrar
                  const hasContent = barHeight > 0;

                  const isActive = hoveredBar !== null || hoveredMonth === index;

                  return (
                    <Tooltip
                      key={index}
                      title={
                        <Box sx={{ p: 1 }}>
                          <Typography variant="subtitle2" sx={{ fontWeight: 'bold', mb: 1 }}>
                            {data.monthName} {selectedYear}
                          </Typography>
                          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                              <Typography variant="caption" sx={{ color: colors.honorarios, fontWeight: 'bold' }}>
                                Honorarios:
                              </Typography>
                              <Typography variant="caption">{formatCurrency(data.honorarios)}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                              <Typography variant="caption" sx={{ color: colors.manoDeObra, fontWeight: 'bold' }}>
                                Pagos MO:
                              </Typography>
                              <Typography variant="caption">{formatCurrency(data.manoDeObra)}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                              <Typography variant="caption" sx={{ color: colors.gastosDirectos, fontWeight: 'bold' }}>
                                Gastos Cliente:
                              </Typography>
                              <Typography variant="caption">{formatCurrency(data.gastosDirectos)}</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                              <Typography variant="caption" sx={{ color: colors.gastosCaja, fontWeight: 'bold' }}>
                                Gastos Caja:
                              </Typography>
                              <Typography variant="caption">{formatCurrency(data.gastosCaja)}</Typography>
                            </Box>
                            <Divider sx={{ my: 0.5 }} />
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
                              <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                                Total:
                              </Typography>
                              <Typography variant="caption" sx={{ fontWeight: 'bold' }}>
                                {formatCurrency(data.total)}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      }
                      arrow
                      placement="top"
                      open={isMobile ? hoveredMonth === index : undefined} // En móvil, controlar manualmente apertura
                      followCursor={!isMobile} // Solo seguir cursor en desktop
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          width: isMobile ? '40px' : `${100 / 12}%`,
                          minWidth: isMobile ? '40px' : 'auto',
                          opacity: 1, // Siempre visible
                          transform: isActive ? 'scale(1.02)' : 'scale(1)', // Solo un ligero efecto de escala
                          transition: 'transform 0.2s ease',
                          cursor: 'pointer'
                        }}
                        onClick={() => {
                          // En móvil, solo activar el mes seleccionado (sin toggle)
                          if (isMobile) {
                            setHoveredMonth(index);
                          }
                        }}
                        onMouseEnter={() => setHoveredMonth(index)}
                        onMouseLeave={() => !isMobile && setHoveredMonth(null)}
                      >
                        {/* Barra apilada */}
                        <Box
                          sx={{
                            width: isMobile ? '80%' : '70%',
                            position: 'relative',
                            height: stackHeight,
                            display: 'flex',
                            flexDirection: 'column-reverse',
                            justifyContent: 'flex-start',
                          }}
                        >
                          {/* Barra acumulada con bordes redondeados solo al exterior */}
                          <Box
                            component={motion.div}
                            initial={{ height: 0, opacity: 0 }}
                            animate={{
                              height: barHeight,
                              opacity: 1,
                              transition: {
                                duration: 0.6,
                                ease: 'easeOut',
                                delay: 0.1 * index
                              }
                            }}
                            whileHover={{ scale: 1.03 }}
                            sx={{
                              width: '100%',
                              position: 'relative',
                              borderRadius: 2,
                              overflow: 'hidden',
                              boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                            }}
                          >
                            {/* Secciones internas - sin cambios */}
                            {data.honorarios > 0 && (
                              <Box
                                sx={{
                                  position: 'absolute',
                                  bottom: 0,
                                  left: 0,
                                  width: '100%',
                                  height: honorariosHeight,
                                  backgroundColor: colors.honorarios,
                                  opacity: (hoveredBar === null || hoveredBar === 'honorarios') ? 1 : 0.4,
                                  transition: 'opacity 0.3s ease',
                                }}
                              />
                            )}

                            {data.manoDeObra > 0 && (
                              <Box
                                sx={{
                                  position: 'absolute',
                                  bottom: honorariosHeight,
                                  left: 0,
                                  width: '100%',
                                  height: manoDeObraHeight,
                                  backgroundColor: colors.manoDeObra,
                                  opacity: (hoveredBar === null || hoveredBar === 'manoDeObra') ? 1 : 0.4,
                                  transition: 'opacity 0.3s ease',
                                }}
                              />
                            )}

                            {data.gastosDirectos > 0 && (
                              <Box
                                sx={{
                                  position: 'absolute',
                                  bottom: honorariosHeight + manoDeObraHeight,
                                  left: 0,
                                  width: '100%',
                                  height: gastosDirectosHeight,
                                  backgroundColor: colors.gastosDirectos,
                                  opacity: (hoveredBar === null || hoveredBar === 'gastosDirectos') ? 1 : 0.4,
                                  transition: 'opacity 0.3s ease',
                                }}
                              />
                            )}

                            {data.gastosCaja > 0 && (
                              <Box
                                sx={{
                                  position: 'absolute',
                                  bottom: honorariosHeight + manoDeObraHeight + gastosDirectosHeight,
                                  left: 0,
                                  width: '100%',
                                  height: gastosCajaHeight,
                                  backgroundColor: colors.gastosCaja,
                                  opacity: (hoveredBar === null || hoveredBar === 'gastosCaja') ? 1 : 0.4,
                                  transition: 'opacity 0.3s ease',
                                }}
                              />
                            )}
                          </Box>
                        </Box>

                        {/* Etiqueta del mes */}
                        <Typography
                          variant="caption"
                          sx={{
                            fontSize: isMobile ? '0.6rem' : '0.7rem',
                            mt: 1,
                            color: '#616161',
                            textTransform: 'capitalize',
                          }}
                        >
                          {isMobile ? data.monthName.substring(0, 3) : data.monthName}
                        </Typography>
                      </Box>
                    </Tooltip>
                  );
                })}
              </Box>

              {/* Leyenda de colores (debajo de los meses) */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  flexWrap: 'wrap',
                  gap: isMobile ? 1 : 2,
                  mt: 2,
                  mb: 2
                }}
              >
                {[
                  { key: 'honorarios', label: 'Honorarios' },
                  { key: 'manoDeObra', label: 'Pagos MO' },
                  { key: 'gastosDirectos', label: 'Gastos Cliente' },
                  { key: 'gastosCaja', label: 'Gastos Caja' },
                ].map((item, index) => (
                  <Box
                    key={item.key}
                    component={motion.div}
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: index * 0.1 }}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      cursor: 'pointer',
                    }}
                    onMouseEnter={() => setHoveredBar(item.key)}
                    onMouseLeave={() => setHoveredBar(null)}
                  >
                    <Box
                      sx={{
                        width: isMobile ? 10 : 12,
                        height: isMobile ? 10 : 12,
                        borderRadius: 6,
                        backgroundColor: colors[item.key],
                        transition: 'all 0.3s ease',
                        boxShadow: hoveredBar === item.key ? '0 0 0 2px rgba(255, 255, 255, 0.8), 0 0 0 4px rgba(0, 0, 0, 0.1)' : 'none',
                        transform: hoveredBar === item.key ? 'scale(1.2)' : 'scale(1)',
                      }}
                    />
                    <Typography
                      variant="caption"
                      sx={{
                        color: hoveredBar === item.key ? colors[item.key] : '#616161',
                        fontWeight: hoveredBar === item.key ? 'bold' : 'normal',
                        transition: 'all 0.3s ease',
                        fontSize: isMobile ? '0.7rem' : '0.75rem'
                      }}
                    >
                      {item.label}
                    </Typography>
                  </Box>
                ))}
              </Box>

              {/* Total anual con el año específico */}
              <Box
                component={motion.div}
                initial={{ opacity: 0, y: 10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: 0.5 }}
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: 0,
                  py: 1,
                  px: 2,
                  backgroundColor: '#f1f8e9',
                  borderRadius: 8,
                  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontWeight: 'bold',
                    color: '#2e7d32',
                    mr: 1,
                    fontSize: isMobile ? '0.8rem' : 'inherit'
                  }}
                >
                  Total {selectedYear}:
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: 'bold',
                    color: '#2e7d32',
                    fontSize: isMobile ? '0.9rem' : 'inherit'
                  }}
                >
                  {formatCurrency(monthlyData.reduce((sum, month) => sum + month.total, 0))}
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1, flexDirection: 'column', gap: 2 }}>
              <Typography color="#757575" fontSize={isMobile ? '0.9rem' : 'inherit'}>No hay datos de gastos para este período</Typography>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  borderRadius: 20,
                  borderColor: '#4caf50',
                  color: '#2e7d32',
                  textTransform: 'none',
                  '&:hover': {
                    borderColor: '#2e7d32',
                    backgroundColor: '#e8f5e9',
                  }
                }}
                onClick={() => {
                  if (years.length > 1 && selectedYear !== years[0]) {
                    setSelectedYear(years[0]);
                  } else if (currency === 'USD') {
                    handleCurrencyChange('ARS');
                  } else {
                    handleCurrencyChange('USD');
                  }
                }}
              >
                {years.length > 1 && selectedYear !== years[0]
                  ? `Probar con el año ${years[0]}`
                  : `Probar con ${currency === 'USD' ? 'ARS' : 'USD'}`}
              </Button>
            </Box>
          )}
        </CardContent>
      </Card>
    </motion.div>
  );
};

// Componente para mostrar actualización de Mano de Obra con CAC
const CACUpdateCard = ({ projectCode }) => {
  const [projectData, setProjectData] = useState(null);
  const [cacData, setCacData] = useState([]);
  const [customCacData, setCustomCacData] = useState([]); // CACs personalizados para este proyecto
  const [paymentsData, setPaymentsData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [monthlyUpdates, setMonthlyUpdates] = useState([]);
  const [cacType, setCacType] = useState('cac_general');
  const [editMode, setEditMode] = useState(null); // Mes y año que está siendo editado
  const [editValue, setEditValue] = useState(''); // Valor que se está editando
  const [isSaving, setIsSaving] = useState(false); // Estado de guardado
  const [viewMode, setViewMode] = useState('grid'); // 'list' o 'grid', 'grid' por defecto en móvil
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // Obtener datos del proyecto
  useEffect(() => {
    if (!projectCode) return;

    const fetchProjectData = async () => {
      setIsLoading(true);
      try {
        const { data: project, error: projectError } = await supabase
          .from('proyectos')
          .select('*')
          .eq('codigo_de_proyecto', projectCode)
          .single();

        if (projectError) {
          console.error('Error fetching project data:', projectError);
          setIsLoading(false);
          return;
        }

        setProjectData(project);

        // Determinar el tipo de CAC a usar basado en método de actualización
        let newCacType = 'cac_general';
        if (project.metodo_de_actualizacion) {
          const method = String(project.metodo_de_actualizacion).toLowerCase();
          console.log('Método de actualización del proyecto:', project.metodo_de_actualizacion);

          if (method.includes('materiales')) {
            newCacType = 'cac_materiales';
          } else if (method.includes('mo') || method.includes('mano de obra')) {
            newCacType = 'cac_mo';
          } else if (method.includes('general')) {
            newCacType = 'cac_general';
          } else if (method.includes('sin') || method.includes('no')) {
            newCacType = 'sin_actualizacion';
          }
        }
        console.log('Tipo de CAC determinado:', newCacType);
        setCacType(newCacType);
      } catch (error) {
        console.error('Error en fetchProjectData:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjectData();
  }, [projectCode]);

  // Obtener datos de CAC globales
  useEffect(() => {
    const fetchCacData = async () => {
      try {
        const { data, error } = await supabase
          .from('cac_cargar')
          .select('*');

        if (error) {
          console.error('Error fetching CAC data:', error);
          return;
        }

        console.log('CAC data from Supabase:', data);
        setCacData(data || []);
      } catch (error) {
        console.error('Error en fetchCacData:', error);
      }
    };

    fetchCacData();
  }, []);

  // Obtener CACs personalizados para este proyecto específico
  useEffect(() => {
    if (!projectCode) return;

    const fetchCustomCac = async () => {
      try {
        const { data, error } = await supabase
          .from('cac_personalizado')
          .select('*')
          .eq('codigo_proyecto', projectCode);

        if (error) {
          console.error('Error al cargar CAC personalizados:', error);
          return;
        }

        console.log('CAC personalizados para este proyecto:', data);
        setCustomCacData(data || []);
      } catch (error) {
        console.error('Error en fetchCustomCac:', error);
      }
    };

    fetchCustomCac();
  }, [projectCode]);

  // Obtener datos de pagos
  useEffect(() => {
    if (!projectCode) return;

    const fetchPaymentsData = async () => {
      try {
        const { data: payments, error: paymentsError } = await supabase
          .from('entradas')
          .select('*')
          .eq('codigo_de_proyecto_entrada', projectCode)
          .eq('concepto_entrada', 'Adelanto MO');

        if (paymentsError) {
          console.error('Error fetching payment data:', paymentsError);
          return;
        }

        setPaymentsData(payments || []);
      } catch (error) {
        console.error('Error en fetchPaymentsData:', error);
      }
    };

    fetchPaymentsData();
  }, [projectCode]);

  // Calcular actualizaciones cuando los datos estén listos
  useEffect(() => {
    if (!projectData) return;

    console.log('Calculando actualizaciones con datos completos');
    console.log('CAC data:', cacData);
    console.log('CAC personalizados:', customCacData);
    console.log('CAC type:', cacType);

    // Calcular rango de fechas
    const startDate = new Date(projectData.fecha_de_inicio_obra);
    let endDate;

    if (projectData.es_terminada && projectData.fecha_de_finalizacion_obra) {
      endDate = new Date(projectData.fecha_de_finalizacion_obra);
    } else {
      endDate = new Date(); // Hoy
    }

    const updates = calculateMonthlyUpdates(
      startDate,
      endDate,
      projectData.monto_inicial_mo || 0,
      projectData.moneda_mo || 'ARS',
      cacData,
      customCacData,
      paymentsData,
      cacType
    );

    setMonthlyUpdates(updates);

    // Establecer el saldo restante
    if (updates.length > 0) {
      setRemainingAmount(updates[updates.length - 1].saldo);
    } else {
      setRemainingAmount(projectData.monto_inicial_mo || 0);
    }
  }, [projectData, cacData, customCacData, paymentsData, cacType]);

  const saveEditedCac = async () => {
    if (!editMode) return;

    // Convertir el valor a número, tratando cadenas vacías como null
    const numericValue = editValue.trim() === '' ? null : parseFloat(editValue);

    setIsSaving(true);

    try {
      const { year, month } = editMode;
      const monthYearString = `${new Intl.DateTimeFormat('en', { month: 'long' }).format(new Date(2000, month - 1))} ${year}`;

      // Buscar el valor original en la tabla de CAC global
      const originalCacValue = cacData.find(cac => {
        const formats = [
          `${monthYearString}`,
          `${new Intl.DateTimeFormat('es', { month: 'long' }).format(new Date(2000, month - 1))} ${year}`
        ];
        return formats.some(format =>
          cac.mes_anio &&
          cac.mes_anio.toLowerCase() === format.toLowerCase()
        );
      })?.[cacType];

      // Si no se proporciona un valor (null), usar el valor original
      const valueToSave = numericValue !== null ? numericValue : originalCacValue;

      // Determinar si es realmente un valor personalizado
      const isReallyCustomValue = valueToSave !== null &&
        valueToSave !== parseFloat(originalCacValue);

      // Buscar si ya existe un registro personalizado para este mes/año/proyecto
      let existingCustomRecord = customCacData.find(cac =>
        parseInt(cac.mes) === month &&
        parseInt(cac.anio) === year &&
        cac.tipo_cac === cacType
      );

      let customCacDataUpdated = [...customCacData]; // Copia del array actual

      if (isReallyCustomValue) {
        if (existingCustomRecord) {
          // Actualizar un registro personalizado existente
          const { error } = await supabase
            .from('cac_personalizado')
            .update({ valor_cac: valueToSave })
            .eq('id', existingCustomRecord.id);

          if (error) {
            console.error('Error al actualizar CAC personalizado:', error);
            setIsSaving(false);
            return;
          }

          // Actualizar el array local
          customCacDataUpdated = customCacData.map(cac =>
            cac.id === existingCustomRecord.id ? { ...cac, valor_cac: valueToSave } : cac
          );
        } else {
          // Crear un nuevo registro personalizado
          const newRecord = {
            codigo_proyecto: projectCode,
            mes: month,
            anio: year,
            tipo_cac: cacType,
            valor_cac: valueToSave
          };

          const { data, error } = await supabase
            .from('cac_personalizado')
            .insert([newRecord])
            .select(); // Añadir .select() para obtener el ID generado

          if (error) {
            console.error('Error al crear CAC personalizado:', error);
            setIsSaving(false);
            return;
          }

          // Actualizar el array local añadiendo el nuevo registro con su ID
          if (data && data.length > 0) {
            customCacDataUpdated = [...customCacData, data[0]];
          }
        }
      } else {
        // Si no es un valor personalizado, eliminar el registro si existe
        if (existingCustomRecord) {
          const { error } = await supabase
            .from('cac_personalizado')
            .delete()
            .eq('id', existingCustomRecord.id);

          if (error) {
            console.error('Error al eliminar CAC personalizado:', error);
            setIsSaving(false);
            return;
          }

          // Actualizar el array local eliminando el registro
          customCacDataUpdated = customCacData.filter(cac => cac.id !== existingCustomRecord.id);
        }
      }

      // Actualizar el estado de customCacData
      setCustomCacData(customCacDataUpdated);

      // Recalcular todas las actualizaciones mensuales
      // Esto es importante: en lugar de actualizar solo un mes, recalculamos todo
      // porque los meses siguientes dependen de los anteriores
      const startDate = new Date(projectData.fecha_de_inicio_obra);
      let endDate;

      if (projectData.es_terminada && projectData.fecha_de_finalizacion_obra) {
        endDate = new Date(projectData.fecha_de_finalizacion_obra);
      } else {
        endDate = new Date(); // Hoy
      }

      const updatedMonthlyUpdates = calculateMonthlyUpdates(
        startDate,
        endDate,
        projectData.monto_inicial_mo || 0,
        projectData.moneda_mo || 'ARS',
        cacData,
        customCacDataUpdated,
        paymentsData,
        cacType
      );

      setMonthlyUpdates(updatedMonthlyUpdates);

      // Establecer el saldo restante
      if (updatedMonthlyUpdates.length > 0) {
        setRemainingAmount(updatedMonthlyUpdates[updatedMonthlyUpdates.length - 1].saldo);
      }

      console.log(`CAC para ${monthYearString} actualizado correctamente: ${valueToSave ?? 'Valor original'}`);

    } catch (error) {
      console.error('Error al guardar valor CAC:', error);
    } finally {
      setIsSaving(false);
      setEditMode(null); // Limpiar el modo de edición
      setEditValue('');  // Limpiar el valor de edición
    }
  };

  // Función para calcular actualizaciones mensuales
  const calculateMonthlyUpdates = (startDate, endDate, initialAmount, currency, cacData, customCacData, payments, cacType) => {
    const updates = [];
    let currentDate = new Date(startDate);
    currentDate.setDate(1); // Empezar desde el primer día del mes

    let runningBalance = initialAmount;

    // Iterar mes a mes
    while (currentDate <= endDate) {
      const year = currentDate.getFullYear();
      const month = currentDate.getMonth() + 1; // 1-12

      // Nombres de meses para búsqueda y visualización
      const monthNameEN = new Intl.DateTimeFormat('en', { month: 'long' }).format(currentDate);
      const monthNameES = new Intl.DateTimeFormat('es', { month: 'long' }).format(currentDate);

      // CAMBIO: Primero buscar un CAC personalizado para este proyecto
      const customCacForMonth = customCacData.find(cac =>
        parseInt(cac.mes) === month &&
        parseInt(cac.anio) === year &&
        cac.tipo_cac === cacType
      );

      // Si hay un CAC personalizado, usarlo; de lo contrario, buscar en la tabla global
      let cacValue = null;
      let isCustomValue = false;

      if (customCacForMonth) {
        cacValue = parseFloat(customCacForMonth.valor_cac);
        isCustomValue = true;
        console.log(`Usando CAC personalizado para ${monthNameEN} ${year}: ${cacValue}%`);
      } else {
        // Diferentes formatos de mes_anio para intentar encontrar coincidencias
        const formats = [
          `${monthNameEN} ${year}`,
          `${monthNameES} ${year}`,
          `${monthNameEN.toLowerCase()} ${year}`,
          `${monthNameES.toLowerCase()} ${year}`,
          `${month}/${year}`,
          `${month}-${year}`
        ];

        // Buscar CAC primero por mes_anio en diferentes formatos
        let cacForMonth = null;
        for (const format of formats) {
          cacForMonth = cacData.find(cac =>
            cac.mes_anio && cac.mes_anio.toLowerCase() === format.toLowerCase()
          );

          if (cacForMonth) break;
        }

        // Si no se encuentra por mes_anio, intentar por mes y año
        if (!cacForMonth) {
          cacForMonth = cacData.find(cac => {
            const cacMonth = parseInt(cac.mes);
            const cacYear = parseInt(cac.anio) || parseInt(cac.año);
            return cacMonth === month && cacYear === year;
          });
        }

        // Obtener el valor numérico del CAC
        cacValue = cacForMonth ? parseFloat(cacForMonth[cacType]) : null;
      }

      // Calcular los pagos realizados este mes
      const monthlyPayments = payments
        ? payments
          .filter(payment => {
            const paymentDate = new Date(payment.fecha_entrada);
            return paymentDate.getFullYear() === year &&
              (paymentDate.getMonth() + 1) === month;
          })
          .reduce((total, payment) => {
            // Usar el campo correcto según la moneda
            const amount = currency === 'USD'
              ? parseFloat(payment.monto_en_usd_entrada || 0)
              : parseFloat(payment.monto_en_ars_entrada || 0);
            return total + amount;
          }, 0)
        : 0;

      // CORRECCIÓN: Primero calculamos el saldo final = saldo inicial - pagos
      const saldoFinal = Math.max(0, runningBalance - monthlyPayments);

      // Calcular actualización por CAC para el SIGUIENTE mes
      let cacAdjustment = 0;
      let cacPercentage = null;

      // Si el tipo es "sin_actualizacion", usamos 0%
      if (cacType === 'sin_actualizacion') {
        cacPercentage = 0;
        cacAdjustment = 0;
      }
      // Para los demás tipos, calculamos el ajuste si tenemos el valor CAC
      else if (cacValue !== null) {
        // Para CAC como valor porcentual directo (ej: 2 para 2%)
        cacPercentage = cacValue;
        cacAdjustment = saldoFinal * (cacValue / 100);
      }

      // Guardar la actualización para este mes
      updates.push({
        year,
        month,
        monthName: monthNameES,
        cacValue,
        cacPercentage,
        payment: monthlyPayments,
        saldoInicial: runningBalance,
        cacAdjustment,
        saldo: saldoFinal, // Saldo final es saldo inicial - pagos
        isCustomValue
      });

      // CORRECCIÓN: El saldo inicial del próximo mes es el saldo final + ajuste CAC
      runningBalance = saldoFinal + cacAdjustment;

      // Avanzar al siguiente mes
      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    return updates;
  };

  // Función para formatear montos
  const formatCurrency = (amount, currency = 'ARS') => {
    if (amount === null || amount === undefined) return '-';

    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: projectData?.moneda_mo || currency,
      maximumFractionDigits: 0
    });

    return formatter.format(amount);
  };

  // Función para obtener color basado en el valor CAC
  const getCacColor = (percentage, isCustom = false) => {
    if (percentage === null) return '#9e9e9e'; // Gris para datos pendientes

    // Si es un valor personalizado, usar un color distintivo
    if (isCustom) {
      if (percentage < 2) return '#8e24aa'; // Púrpura para personalizados < 2%
      if (percentage < 5) return '#7b1fa2'; // Púrpura más oscuro para 2-5%
      return '#6a1b9a'; // Púrpura aún más oscuro para > 5%
    }

    // Colores normales para valores no personalizados
    if (percentage < 2) return '#4caf50'; // Verde para < 2%
    if (percentage < 5) return '#ff9800'; // Naranja para 2-5%
    return '#f44336'; // Rojo para > 5%
  };

  // Convertir el tipo de CAC a un formato legible
  const getCacTypeName = () => {
    switch (cacType) {
      case 'cac_mo': return 'CAC Mano de Obra';
      case 'cac_materiales': return 'CAC Materiales';
      case 'cac_vivienda': return 'CAC Vivienda';
      case 'sin_actualizacion': return 'Sin Actualización';
      default: return 'CAC General';
    }
  };

  // Verificar si el mes está pendiente o debe cargarse
  const getMonthStatus = (update, index) => {
    const isLastMonth = index === monthlyUpdates.length - 1;
    const isPreviousToLastMonth = index === monthlyUpdates.length - 2;
    const now = new Date();
    const isAfterDay20 = now.getDate() > 20;
    const hasCac = update.cacValue !== null;

    if (isLastMonth && !hasCac) {
      return { label: 'CAC Pendiente', color: '#9e9e9e' };
    } else if (isPreviousToLastMonth && !hasCac) {
      if (isAfterDay20) {
        return { label: 'Cargar CAC', color: '#f44336' };
      } else {
        return { label: 'CAC Pendiente', color: '#9e9e9e' };
      }
    }
    return null;
  };

  // Si el tipo de CAC es sin actualización, mostrar un componente simplificado
  const isSinActualizacion = cacType === 'sin_actualizacion';

  useEffect(() => {
    // Si es móvil, establecer la vista por defecto como cuadrícula
    if (isMobile) {
      setViewMode('grid');
    }
  }, [isMobile]);

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      whileHover={{ scale: isMobile ? 1 : 1.01 }}
      transition={{ duration: 0.4, ease: 'easeOut' }}
      style={{ height: '100%' }}
    >
      <Card
        sx={{
          borderRadius: 3,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
          background: 'linear-gradient(135deg, #ffffff 0%, #f5f7fa 100%)',
          height: '100%',
          overflow: 'hidden',
          transition: 'all 0.3s ease',
          '&:hover': {
            boxShadow: '0 12px 32px rgba(0, 0, 0, 0.12)',
          }
        }}
      >
        <CardContent sx={{
          p: isMobile ? 2 : 2.5,
          height: '100%',
          display: 'flex',
          flexDirection: 'column'
        }}>
          {/* Cabecera con título, tipo de CAC y opciones de visualización */}
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
            flexWrap: 'wrap',
            gap: 1
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                variant={isMobile ? "subtitle1" : "h6"}
                fontWeight="bold"
                color="#2e7d32"
                sx={{ fontSize: isMobile ? '1rem' : '1.1rem', mr: 1.5 }}
              >
                Actualización CAC
              </Typography>

              <Tooltip title="Tipo de actualización">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#e8f5e9',
                    borderRadius: 16,
                    padding: '4px 10px',
                    boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      fontWeight: 'bold',
                      color: '#2e7d32',
                      fontSize: isMobile ? '0.65rem' : '0.7rem'
                    }}
                  >
                    {getCacTypeName()}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>

            {/* Botones para cambiar el modo de visualización - solo se muestran si no es sin_actualizacion */}
            {!isSinActualizacion && (
              <Box sx={{ display: 'flex', gap: 0.5 }}>
                <Tooltip title="Ver como mosaico">
                  <IconButton
                    size="small"
                    color={viewMode === 'grid' ? 'primary' : 'default'}
                    onClick={() => setViewMode('grid')}
                    sx={{
                      bgcolor: viewMode === 'grid' ? 'rgba(76, 175, 80, 0.12)' : 'transparent',
                      '&:hover': { bgcolor: viewMode === 'grid' ? 'rgba(76, 175, 80, 0.2)' : 'rgba(0, 0, 0, 0.04)' }
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <rect x="3" y="3" width="7" height="7"></rect>
                      <rect x="14" y="3" width="7" height="7"></rect>
                      <rect x="14" y="14" width="7" height="7"></rect>
                      <rect x="3" y="14" width="7" height="7"></rect>
                    </svg>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Ver como lista">
                  <IconButton
                    size="small"
                    color={viewMode === 'list' ? 'primary' : 'default'}
                    onClick={() => setViewMode('list')}
                    sx={{
                      bgcolor: viewMode === 'list' ? 'rgba(76, 175, 80, 0.12)' : 'transparent',
                      '&:hover': { bgcolor: viewMode === 'list' ? 'rgba(76, 175, 80, 0.2)' : 'rgba(0, 0, 0, 0.04)' }
                    }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                      <line x1="8" y1="6" x2="21" y2="6"></line>
                      <line x1="8" y1="12" x2="21" y2="12"></line>
                      <line x1="8" y1="18" x2="21" y2="18"></line>
                      <line x1="3" y1="6" x2="3.01" y2="6"></line>
                      <line x1="3" y1="12" x2="3.01" y2="12"></line>
                      <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </Box>

          {/* Panel superior con monto inicial y saldo actual */}
          <Box
            sx={{
              display: 'flex',
              borderRadius: 2,
              overflow: 'hidden',
              boxShadow: '0 4px 12px rgba(0, 0, 0, 0.05)',
              mb: 2,
              flexDirection: isMobile ? 'column' : 'row'
            }}
          >
            {/* Monto inicial */}
            <Box
              sx={{
                flex: 1,
                p: isMobile ? 1.2 : 1.5,
                backgroundColor: '#f1f8e9',
                position: 'relative',
                borderRight: isMobile ? 'none' : '1px solid #e0e0e0',
                borderBottom: isMobile ? '1px solid #e0e0e0' : 'none',
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: '#616161',
                  textTransform: 'uppercase',
                  fontWeight: 'medium',
                  fontSize: '0.65rem'
                }}
              >
                MONTO INICIAL MO
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  color: '#2e7d32',
                  mt: 0.2,
                  fontSize: isMobile ? '0.95rem' : '1.1rem'
                }}
              >
                {formatCurrency(projectData?.monto_inicial_mo || 0)}
              </Typography>
            </Box>

            {/* Saldo actual */}
            <Box
              sx={{
                flex: 1,
                p: isMobile ? 1.2 : 1.5,
                backgroundColor: '#f1f8e9',
                position: 'relative',
              }}
            >
              <Typography
                variant="caption"
                sx={{
                  color: '#616161',
                  textTransform: 'uppercase',
                  fontWeight: 'medium',
                  fontSize: '0.65rem'
                }}
              >
                SALDO ACTUAL
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  fontWeight: 'bold',
                  color: remainingAmount > 0 ? '#ff9800' : '#4caf50',
                  mt: 0.2,
                  fontSize: isMobile ? '0.95rem' : '1.1rem'
                }}
              >
                {formatCurrency(remainingAmount)}
              </Typography>
            </Box>
          </Box>

          {isLoading ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flex: 1 }}>
              <CircularProgress sx={{ color: '#4caf50' }} />
            </Box>
          ) : (
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
              {/* Si es sin actualización, mostrar solo la información de saldo */}
              {isSinActualizacion ? (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    py: 4,
                    px: 2,
                    flex: 1
                  }}
                >
                  <Box
                    sx={{
                      bgcolor: '#f5f5f5',
                      borderRadius: '50%',
                      width: 60,
                      height: 60,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      mb: 2,
                    }}
                  >
                    <svg width="30" height="30" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6 18L18 6M6 6l12 12" stroke="#9e9e9e" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                  </Box>
                  <Typography variant="h6" color="#616161" fontWeight="medium" sx={{ mb: 1 }}>
                    Sin actualización por CAC
                  </Typography>
                  <Typography variant="body2" color="#757575" sx={{ maxWidth: 400, mb: 3 }}>
                    Este proyecto no está configurado para actualizarse mediante CAC.
                    El saldo inicial de mano de obra solo se modifica con pagos.
                  </Typography>

                  {/* Resumen de pagos realizados */}
                  {paymentsData.length > 0 && (
                    <Box sx={{ width: '100%', maxWidth: 350 }}>
                      <Typography variant="subtitle2" color="#424242" sx={{ mb: 1, textAlign: 'left' }}>
                        Resumen de pagos:
                      </Typography>
                      <Box sx={{
                        backgroundColor: '#f1f8e9',
                        borderRadius: 2,
                        p: 2,
                        textAlign: 'left'
                      }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 1 }}>
                          <Typography variant="body2" color="#757575">Total pagado:</Typography>
                          <Typography variant="body2" fontWeight="bold" color="#4caf50">
                            {formatCurrency(
                              paymentsData.reduce((sum, payment) => {
                                const currency = projectData?.moneda_mo || 'ARS';
                                const amount = currency === 'USD'
                                  ? parseFloat(payment.monto_en_usd_entrada || 0)
                                  : parseFloat(payment.monto_en_ars_entrada || 0);
                                return sum + amount;
                              }, 0)
                            )}
                          </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                          <Typography variant="body2" color="#757575">Cantidad de pagos:</Typography>
                          <Typography variant="body2" fontWeight="medium" color="#424242">
                            {paymentsData.length}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
              ) : (
                // Lista de actualizaciones mensuales para los otros tipos de CAC
                monthlyUpdates.length > 0 ? (
                  viewMode === 'grid' ? (
                    // Vista de mosaico
                    <Box sx={{
                      display: 'grid',
                      gridTemplateColumns: isMobile
                        ? 'repeat(auto-fill, minmax(120px, 1fr))'
                        : 'repeat(auto-fill, minmax(180px, 1fr))',
                      gap: isMobile ? 1 : 1.5,
                      overflowY: 'auto',
                      flex: 1,
                      pr: 0.5,
                      pb: 0.5
                    }}>
                      {monthlyUpdates.map((update, index) => {
                        const hasCac = update.cacValue !== null;
                        const cacColor = getCacColor(update.cacPercentage, update.isCustomValue);
                        const status = getMonthStatus(update, index);

                        // Comprobar si este mes está siendo editado
                        const isEditing = editMode &&
                          editMode.month === update.month &&
                          editMode.year === update.year;

                        return (
                          <motion.div
                            key={`${update.year}-${update.month}`}
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            whileHover={{ scale: isMobile ? 1.01 : 1.03 }}
                            transition={{
                              type: 'spring',
                              stiffness: 260,
                              damping: 20,
                              delay: index * 0.03
                            }}
                          >
                            <Card
                              sx={{
                                borderRadius: 2,
                                overflow: 'hidden',
                                boxShadow: '0 2px 8px rgba(0, 0, 0, 0.05)',
                                transition: 'all 0.3s ease',
                                '&:hover': {
                                  boxShadow: '0 6px 16px rgba(0, 0, 0, 0.1)',
                                },
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative'
                              }}
                            >
                              {/* Barra superior de color */}
                              <Box
                                sx={{
                                  height: 4,
                                  backgroundColor: cacType === 'sin_actualizacion' ? '#9e9e9e' : cacColor
                                }}
                              />

                              <CardContent sx={{
                                p: isMobile ? 1.2 : 1.5,
                                flex: 1,
                                display: 'flex',
                                flexDirection: 'column'
                              }}>
                                {/* Mes y año */}
                                <Box sx={{
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                  alignItems: 'center',
                                  mb: 1
                                }}>
                                  <Typography
                                    variant="subtitle2"
                                    sx={{
                                      fontWeight: 'bold',
                                      color: '#424242',
                                      textTransform: 'capitalize',
                                      fontSize: isMobile ? '0.8rem' : '0.9rem'
                                    }}
                                  >
                                    {update.monthName} {update.year}
                                  </Typography>

                                  {/* Botón de edición */}
                                  {!isEditing && (
                                    <Tooltip title="Editar CAC">
                                      <IconButton
                                        size="small"
                                        onClick={() => {
                                          setEditMode(update);
                                          setEditValue(update.cacValue ? update.cacValue.toString() : '0');
                                        }}
                                        sx={{
                                          p: 0.3,
                                          color: '#757575',
                                          '&:hover': { color: '#4caf50' }
                                        }}
                                      >
                                        <svg xmlns="http://www.w3.org/2000/svg" width={isMobile ? "13" : "15"} height={isMobile ? "13" : "15"} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                          <path d="M12 20h9"></path>
                                          <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                        </svg>
                                      </IconButton>
                                    </Tooltip>)}
                                </Box>

                                {/* Estado pendiente o cargar CAC */}
                                {status && (
                                  <Box
                                    sx={{
                                      display: 'inline-flex',
                                      alignItems: 'center',
                                      mt: -0.5,
                                      mb: 0.5,
                                      px: 0.8,
                                      py: 0.2,
                                      borderRadius: 4,
                                      backgroundColor: `${status.color}22`,
                                      border: `1px solid ${status.color}44`,
                                    }}
                                  >
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        color: status.color,
                                        fontWeight: 'medium',
                                        fontSize: '0.65rem'
                                      }}
                                    >
                                      {status.label}
                                    </Typography>
                                  </Box>
                                )}

                                {/* Valor CAC + campo de edición */}
                                <Box sx={{ mt: 1 }}>
                                  {isEditing ? (
                                    <Box
                                      component={motion.div}
                                      initial={{ scale: 0.9, opacity: 0 }}
                                      animate={{ scale: 1, opacity: 1 }}
                                      sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
                                    >
                                      <TextField
                                        autoFocus
                                        variant="outlined"
                                        value={editValue}
                                        onChange={(e) => setEditValue(e.target.value)}
                                        size="small"
                                        type="number"
                                        InputProps={{
                                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                          sx: { borderRadius: 2, fontSize: isMobile ? '0.8rem' : '0.9rem' }
                                        }}
                                        sx={{
                                          '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                              borderColor: '#4caf5060',
                                            },
                                            '&:hover fieldset': {
                                              borderColor: '#4caf5090',
                                            },
                                            '&.Mui-focused fieldset': {
                                              borderColor: '#4caf50',
                                            },
                                          },
                                        }}
                                      />
                                      <Box sx={{ display: 'flex', gap: 0.5, justifyContent: 'flex-end' }}>
                                        <Tooltip title="Guardar">
                                          <IconButton
                                            size="small"
                                            color="primary"
                                            onClick={saveEditedCac}
                                            disabled={isSaving}
                                            sx={{
                                              bgcolor: 'rgba(76, 175, 80, 0.12)',
                                              p: 0.7,
                                              '&:hover': { bgcolor: 'rgba(76, 175, 80, 0.2)' }
                                            }}
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                              <polyline points="20 6 9 17 4 12"></polyline>
                                            </svg>
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Cancelar">
                                          <IconButton
                                            size="small"
                                            color="error"
                                            onClick={() => setEditMode(null)}
                                            sx={{
                                              bgcolor: 'rgba(244, 67, 54, 0.12)',
                                              p: 0.7,
                                              '&:hover': { bgcolor: 'rgba(244, 67, 54, 0.2)' }
                                            }}
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                              <line x1="18" y1="6" x2="6" y2="18"></line>
                                              <line x1="6" y1="6" x2="18" y2="18"></line>
                                            </svg>
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <motion.div
                                      initial={{ scale: 0.9 }}
                                      animate={{ scale: 1 }}
                                      whileHover={{ scale: 1.05 }}
                                      transition={{ type: 'spring', stiffness: 400, damping: 10 }}
                                    >
                                      <Box sx={{
                                        mt: 0.5,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column'
                                      }}>
                                        <Typography
                                          variant={isMobile ? "h6" : "h6"}
                                          sx={{
                                            fontWeight: 'bold',
                                            color: cacType === 'sin_actualizacion' ? '#9e9e9e' : cacColor,
                                            fontSize: isMobile ? '1.1rem' : '1.25rem',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center'
                                          }}
                                        >
                                          {cacType === 'sin_actualizacion'
                                            ? '0%'
                                            : hasCac
                                              ? `${update.cacValue}%`
                                              : '-'}
                                        </Typography>

                                        {/* Indicador de valor personalizado */}
                                        {update.isCustomValue && (
                                          <Typography
                                            variant="caption"
                                            sx={{
                                              color: '#8e24aa',
                                              fontSize: '0.65rem',
                                              mt: 0.2,
                                              fontStyle: 'italic'
                                            }}
                                          >
                                            Personalizado
                                          </Typography>
                                        )}
                                      </Box>
                                    </motion.div>
                                  )}
                                </Box>

                                {/* Saldo final e info de pagos */}
                                <Box sx={{
                                  mt: 'auto',
                                  pt: 1.5,
                                  borderTop: '1px dashed #e0e0e0',
                                  fontSize: isMobile ? '0.7rem' : '0.75rem'
                                }}>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 0.5 }}>
                                    <Typography variant="caption" color="#757575">Pagado:</Typography>
                                    <Typography variant="caption" fontWeight="medium" color={update.payment > 0 ? '#4caf50' : '#757575'}>
                                      {update.payment > 0 ? formatCurrency(update.payment) : '-'}
                                    </Typography>
                                  </Box>
                                  <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography variant="caption" color="#757575">Saldo:</Typography>
                                    <Typography variant="caption" fontWeight="bold" color="#2e7d32">
                                      {formatCurrency(update.saldo)}
                                    </Typography>
                                  </Box>
                                </Box>
                              </CardContent>
                            </Card>
                          </motion.div>
                        );
                      })}
                    </Box>
                  ) : (
                    // Vista de lista
                    <Box sx={{ overflowY: 'auto', flex: 1, pr: 0.5 }}>
                      <Table size="small" sx={{ tableLayout: 'fixed' }}>
                        <TableHead>
                          <TableRow>
                            <TableCell sx={{ width: '18%', fontWeight: 'bold', fontSize: isMobile ? '0.75rem' : 'inherit' }}>Mes</TableCell>
                            <TableCell sx={{ width: '15%', fontWeight: 'bold', fontSize: isMobile ? '0.75rem' : 'inherit' }}>CAC</TableCell>
                            <TableCell sx={{ width: '22%', fontWeight: 'bold', fontSize: isMobile ? '0.75rem' : 'inherit' }}>Saldo Inicial</TableCell>
                            <TableCell sx={{ width: '22%', fontWeight: 'bold', fontSize: isMobile ? '0.75rem' : 'inherit' }}>Pagado</TableCell>
                            <TableCell sx={{ width: '23%', fontWeight: 'bold', fontSize: isMobile ? '0.75rem' : 'inherit' }}>Saldo Final</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {monthlyUpdates.map((update, index) => {
                            const hasCac = update.cacValue !== null;
                            const cacColor = getCacColor(update.cacPercentage, update.isCustomValue);
                            const status = getMonthStatus(update, index);
                            const isEditing = editMode &&
                              editMode.month === update.month &&
                              editMode.year === update.year;

                            return (
                              <TableRow
                                key={`${update.year}-${update.month}`}
                                component={motion.tr}
                                initial={{ opacity: 0, y: 10 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.4, delay: index * 0.03 }}
                                sx={{
                                  '&:hover': {
                                    backgroundColor: 'rgba(76, 175, 80, 0.04)'
                                  }
                                }}
                              >
                                <TableCell sx={{
                                  textTransform: 'capitalize',
                                  borderLeft: `3px solid ${cacType === 'sin_actualizacion' ? '#9e9e9e' : cacColor}`,
                                  position: 'relative',
                                  fontSize: isMobile ? '0.75rem' : 'inherit',
                                  px: isMobile ? 1 : 2,
                                  py: isMobile ? 1 : 1.5
                                }}>
                                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        fontWeight: 'medium',
                                        fontSize: isMobile ? '0.75rem' : 'inherit'
                                      }}
                                    >
                                      {update.monthName} {update.year}
                                    </Typography>

                                    {status && (
                                      <Typography
                                        variant="caption"
                                        sx={{
                                          color: status.color,
                                          fontWeight: 'medium',
                                          fontSize: '0.65rem',
                                          mt: 0.2
                                        }}
                                      >
                                        {status.label}
                                      </Typography>
                                    )}
                                  </Box>
                                </TableCell>
                                <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit', px: isMobile ? 1 : 2, py: isMobile ? 1 : 1.5 }}>
                                  {isEditing ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                                      <TextField
                                        autoFocus
                                        variant="outlined"
                                        value={editValue}
                                        onChange={(e) => setEditValue(e.target.value)}
                                        size="small"
                                        type="number"
                                        InputProps={{
                                          endAdornment: <InputAdornment position="end">%</InputAdornment>,
                                          sx: { borderRadius: 2, fontSize: '0.85rem', height: '32px' }
                                        }}
                                        sx={{
                                          width: '80px',
                                          '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                              borderColor: '#4caf5060',
                                            },
                                            '&:hover fieldset': {
                                              borderColor: '#4caf5090',
                                            },
                                            '&.Mui-focused fieldset': {
                                              borderColor: '#4caf50',
                                            },
                                          },
                                        }}
                                      />
                                      <Box sx={{ display: 'flex', gap: 0.5 }}>
                                        <Tooltip title="Guardar">
                                          <IconButton
                                            size="small"
                                            color="primary"
                                            onClick={saveEditedCac}
                                            disabled={isSaving}
                                            sx={{
                                              p: 0.5,
                                              '&:hover': { bgcolor: 'rgba(76, 175, 80, 0.1)' }
                                            }}
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                              <polyline points="20 6 9 17 4 12"></polyline>
                                            </svg>
                                          </IconButton>
                                        </Tooltip>
                                        <Tooltip title="Cancelar">
                                          <IconButton
                                            size="small"
                                            color="error"
                                            onClick={() => setEditMode(null)}
                                            sx={{
                                              p: 0.5,
                                              '&:hover': { bgcolor: 'rgba(244, 67, 54, 0.1)' }
                                            }}
                                          >
                                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                              <line x1="18" y1="6" x2="6" y2="18"></line>
                                              <line x1="6" y1="6" x2="18" y2="18"></line>
                                            </svg>
                                          </IconButton>
                                        </Tooltip>
                                      </Box>
                                    </Box>
                                  ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        <Typography
                                          variant="body2"
                                          sx={{
                                            fontWeight: 'bold',
                                            color: cacType === 'sin_actualizacion' ? '#9e9e9e' : cacColor,
                                            fontSize: isMobile ? '0.75rem' : 'inherit'
                                          }}
                                        >
                                          {cacType === 'sin_actualizacion'
                                            ? '0%'
                                            : hasCac
                                              ? `${update.cacValue}%`
                                              : '-'}
                                        </Typography>

                                        <IconButton
                                          size="small"
                                          onClick={() => {
                                            setEditMode(update);
                                            setEditValue(update.cacValue ? update.cacValue.toString() : '0');
                                          }}
                                          sx={{
                                            ml: 0.5,
                                            p: 0.3,
                                            color: '#757575',
                                            '&:hover': { color: '#4caf50' }
                                          }}
                                        >
                                          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                            <path d="M12 20h9"></path>
                                            <path d="M16.5 3.5a2.121 2.121 0 0 1 3 3L7 19l-4 1 1-4L16.5 3.5z"></path>
                                          </svg>
                                        </IconButton>
                                      </Box>

                                      {/* Indicador de valor personalizado */}
                                      {update.isCustomValue && (
                                        <Typography
                                          variant="caption"
                                          sx={{
                                            color: '#8e24aa',
                                            fontSize: '0.65rem',
                                            fontStyle: 'italic'
                                          }}
                                        >
                                          Personalizado
                                        </Typography>
                                      )}
                                    </Box>
                                  )}
                                </TableCell>
                                <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit', px: isMobile ? 1 : 2, py: isMobile ? 1 : 1.5 }}>
                                  <Typography variant="body2" sx={{ fontSize: isMobile ? '0.75rem' : 'inherit' }}>
                                    {formatCurrency(update.saldoInicial)}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit', px: isMobile ? 1 : 2, py: isMobile ? 1 : 1.5 }}>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      color: update.payment > 0 ? '#4caf50' : '#757575',
                                      fontWeight: update.payment > 0 ? 'medium' : 'normal',
                                      fontSize: isMobile ? '0.75rem' : 'inherit'
                                    }}
                                  >
                                    {update.payment > 0 ? formatCurrency(update.payment) : '-'}
                                  </Typography>
                                </TableCell>
                                <TableCell sx={{ fontSize: isMobile ? '0.75rem' : 'inherit', px: isMobile ? 1 : 2, py: isMobile ? 1 : 1.5 }}>
                                  <Typography
                                    variant="body2"
                                    sx={{
                                      fontWeight: 'bold',
                                      color: '#2e7d32',
                                      fontSize: isMobile ? '0.75rem' : 'inherit'
                                    }}
                                  >
                                    {formatCurrency(update.saldo)}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Box>
                  )
                ) : (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      justifyContent: 'center',
                      textAlign: 'center',
                      py: 6,
                      opacity: 0.6,
                    }}
                  >
                    <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9 11h6v2H9v-2zm3-8c-4.97 0-9 4.03-9 9s4.03 9 9 9 9-4.03 9-9-4.03-9-9-9zm0 16c-3.86 0-7-3.14-7-7s3.14-7 7-7 7 3.14 7 7-3.14 7-7 7z" fill="#9e9e9e" />
                    </svg>
                    <Typography variant="body1" color="#616161" sx={{ mt: 2 }}>
                      No hay datos de actualizaciones CAC disponibles para este proyecto.
                    </Typography>
                  </Box>
                )
              )}
              {/* Nota informativa y leyenda - solo se muestra si no es sin_actualizacion y hay datos */}
              {!isSinActualizacion && monthlyUpdates.length > 0 && (
                <Box
                  sx={{
                    mt: 1.5,
                    p: isMobile ? 2 : 1.5,  // Aumentar padding en móvil
                    borderRadius: 2,
                    backgroundColor: 'rgba(255, 152, 0, 0.08)',
                    border: '1px solid rgba(255, 152, 0, 0.2)',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1
                  }}
                >
                  <Typography
                    variant="caption"
                    sx={{
                      color: '#ff9800',
                      fontStyle: 'italic',
                      fontSize: isMobile ? '0.65rem' : '0.7rem',
                      lineHeight: 1.3,
                      wordBreak: 'break-word' // Permitir que el texto se rompa si es necesario
                    }}
                  >
                    El CAC se actualiza mensualmente alrededor del día 20-25 del mes siguiente. Los valores pueden sufrir variaciones.
                  </Typography>

                  {/* Leyenda para valores personalizados */}
                  {customCacData.length > 0 && (
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <Box
                        sx={{
                          width: 8,
                          height: 8,
                          borderRadius: '50%',
                          backgroundColor: '#8e24aa',
                          flexShrink: 0 // Evitar que se encoja
                        }}
                      />
                      <Typography
                        variant="caption"
                        sx={{
                          color: '#616161',
                          fontSize: isMobile ? '0.65rem' : '0.7rem',
                          lineHeight: 1.3
                        }}
                      >
                        Los valores en color púrpura son CAC personalizados para este proyecto.
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          )}
        </CardContent>
      </Card>
    </motion.div>
  );
};

// Componente para el diálogo de información del proyecto
const ProjectInfoDialog = ({ open, onClose, projectData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: 3,
          boxShadow: '0 8px 32px rgba(0, 0, 0, 0.1)',
        }
      }}
    >
      <DialogTitle sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#f1f8e9',
        pb: 2
      }}>
        <Typography variant="h6" fontWeight="bold" color="#2e7d32">
          Información del Proyecto
        </Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          size="small"
          sx={{ bgcolor: 'rgba(0,0,0,0.05)', borderRadius: '50%' }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ py: 3 }}>
        {projectData ? (
          <Grid container spacing={3} alignItems="stretch">
            {/* Primera columna - Información del cliente */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" fontWeight="bold" color="#424242" fontSize="0.9rem" sx={{ mb: 1 }}>
                Información del Cliente
              </Typography>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" color="#757575" fontSize="0.8rem">
                  Apellido
                </Typography>
                <Typography variant="body1" color="#424242" fontSize="0.9rem" fontWeight="medium">
                  {projectData.apellido_del_cliente}
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" color="#757575" fontSize="0.8rem">
                  Ubicación
                </Typography>
                <Typography variant="body1" color="#424242" fontSize="0.9rem" fontWeight="medium">
                  {projectData.ubicacion_del_proyecto || 'No especificada'}
                </Typography>
              </Box>
            </Grid>

            {/* Segunda columna - Información del proyecto */}
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle1" fontWeight="bold" color="#424242" fontSize="0.9rem" sx={{ mb: 1 }}>
                Datos de la Obra
              </Typography>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" color="#757575" fontSize="0.8rem">
                  Fecha de Inicio
                </Typography>
                <Typography variant="body1" color="#424242" fontSize="0.9rem" fontWeight="medium">
                  {moment(projectData.fecha_de_inicio_obra).format('DD/MM/YYYY')}
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                <Typography variant="body2" color="#757575" fontSize="0.8rem">
                  {projectData.es_terminada ? 'Fecha de Finalización' : 'Tiempo en Obra'}
                </Typography>
                <Typography variant="body1" color="#2e7d32" fontSize="0.9rem" fontWeight="bold">
                  {projectData.es_terminada
                    ? moment(projectData.fecha_de_finalizacion_obra).format('DD/MM/YYYY')
                    : projectData.duration
                  }
                </Typography>
              </Box>
            </Grid>

            {/* Bloque inferior - Información de metros */}
            <Grid item xs={12}>
              <Typography variant="subtitle1" fontWeight="bold" color="#424242" fontSize="0.9rem" sx={{ mb: 1, mt: 1 }}>
                Superficies
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={3}>
                  <Box sx={{
                    p: 1.5,
                    bgcolor: '#f1f8e9',
                    borderRadius: 2,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <Typography variant="body2" color="#757575" fontSize="0.8rem">
                      Metros Cubiertos
                    </Typography>
                    <Typography variant="body1" color="#2e7d32" fontSize="0.9rem" fontWeight="bold">
                      {projectData.metros_cubiertos || 0} m²
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box sx={{
                    p: 1.5,
                    bgcolor: '#f1f8e9',
                    borderRadius: 2,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <Typography variant="body2" color="#757575" fontSize="0.8rem">
                      Metros Semi Cubiertos
                    </Typography>
                    <Typography variant="body1" color="#2e7d32" fontSize="0.9rem" fontWeight="bold">
                      {projectData.metros_semi_cubiertos || 0} m²
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box sx={{
                    p: 1.5,
                    bgcolor: '#f1f8e9',
                    borderRadius: 2,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <Typography variant="body2" color="#757575" fontSize="0.8rem">
                      Metros Solados
                    </Typography>
                    <Typography variant="body1" color="#2e7d32" fontSize="0.9rem" fontWeight="bold">
                      {projectData.metros_solados || 0} m²
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <Box sx={{
                    p: 1.5,
                    bgcolor: '#f1f8e9',
                    borderRadius: 2,
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <Typography variant="body2" color="#757575" fontSize="0.8rem">
                      Metros de Cómputo
                    </Typography>
                    <Typography variant="body1" color="#2e7d32" fontSize="0.9rem" fontWeight="bold">
                      {projectData.metros_de_computo || 0} m²
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <Box sx={{ display: 'flex', justifyContent: 'center', p: 3 }}>
            <CircularProgress sx={{ color: '#4caf50' }} />
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
};

// Componente principal del Dashboard
const NewDashboard = ({ selectedProject: initialProject, isClientView = false }) => {
  const [selectedProject, setSelectedProject] = useState(initialProject || '');
  const [projects, setProjects] = useState([]);
  const [projectData, setProjectData] = useState(null);
  const [costPerSquareMeter, setCostPerSquareMeter] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isCostLoading, setIsCostLoading] = useState(false);
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { user, userRole, userDetails } = useAuth();
  const isClient = userRole === 'client';


  const fetchProjects = useCallback(async () => {
    // Si es cliente, no necesitamos cargar todos los proyectos
    if (isClient) return;

    setIsLoading(true);
    const { data, error } = await supabase.from('proyectos').select('codigo_de_proyecto, es_terminada');
    if (error) console.error('Error fetching projects:', error);
    else setProjects(data || []);
    setIsLoading(false);
  }, [isClient]); // Añade isClient a las dependencias

  const fetchProjectData = useCallback(async (projectCode) => {
    if (!projectCode) {
      setProjectData(null);
      setCostPerSquareMeter(0);
      return;
    }
    setIsLoading(true);
    const { data, error } = await supabase
      .from('proyectos')
      .select('*')
      .eq('codigo_de_proyecto', projectCode)
      .single();
    if (error) console.error('Error fetching project data:', error);
    else {
      setProjectData(data);
      const startDate = moment(data.fecha_de_inicio_obra);
      const endDate = data.es_terminada ? moment(data.fecha_de_finalizacion_obra) : moment();
      const duration = moment.duration(endDate.diff(startDate));
      const years = Math.floor(duration.asYears());
      const months = Math.floor(duration.asMonths() % 12);
      const days = Math.floor(duration.asDays() % 30);
      data.duration = years >= 1
        ? `${years} año${years > 1 ? 's' : ''} ${months} mes${months !== 1 ? 'es' : ''}`
        : `${months} mes${months !== 1 ? 'es' : ''} ${days} día${days !== 1 ? 's' : ''}`;
    }
    setIsLoading(false);
  }, []);

  const fetchCostData = useCallback(async (projectCode) => {
    if (!projectCode) return;
    setIsCostLoading(true);
    const { data: expenses, error: expensesError } = await supabase
      .from('gastos_totales_proyectos')
      .select('gasto_en_usd_proyecto, gastos_en_ars_proyecto')
      .eq('codigo_proyecto_gasto', projectCode)
      .single();

    if (expensesError) {
      console.error('Error fetching expenses for cost calculation:', expensesError);
      setCostPerSquareMeter(0);
      setIsCostLoading(false);
      return;
    }

    const { data: project, error: projectError } = await supabase
      .from('proyectos')
      .select('metros_de_computo')
      .eq('codigo_de_proyecto', projectCode)
      .single();

    if (projectError) {
      console.error('Error fetching project for cost calculation:', projectError);
      setCostPerSquareMeter(0);
      setIsCostLoading(false);
      return;
    }

    const totalCostUSD = expenses.gasto_en_usd_proyecto || 0;
    const meters = project.metros_de_computo || 1;
    const costPerMeter = totalCostUSD / meters;
    setCostPerSquareMeter(Math.round(costPerMeter));
    setIsCostLoading(false);
  }, []);

  useEffect(() => {
    // Si es cliente, obtener su código de proyecto de userDetails
    if (isClient && userDetails && userDetails.codigo_de_proyecto) {
      setSelectedProject(userDetails.codigo_de_proyecto);
    } else if (!isClient) {
      // Si no es cliente, cargar la lista de proyectos normalmente
      fetchProjects();
    }
  }, [isClient, userDetails, fetchProjects]);

  useEffect(() => {
    if (selectedProject) {
      fetchProjectData(selectedProject);
      fetchCostData(selectedProject);
    } else {
      setProjectData(null);
      setCostPerSquareMeter(0);
    }
  }, [selectedProject, fetchProjectData, fetchCostData]);

  const handleProjectClick = (event) => {
    // Los clientes no deberían poder cambiar de proyecto
    if (isClient) return;

    setAnchorEl(event.currentTarget);
  };

  const handleProjectClose = () => {
    setAnchorEl(null);
  };

  const handleProjectSelect = (projectCode) => {
    setSelectedProject(projectCode);
    handleProjectClose();
  };

  const handleOpenInfoDialog = () => {
    setInfoDialogOpen(true);
  };

  const handleCloseInfoDialog = () => {
    setInfoDialogOpen(false);
  };

  const buttonVariants = {
    rest: { scale: 1, backgroundColor: 'transparent' },
    hover: { scale: 1.05, backgroundColor: 'rgba(232, 245, 233, 0.3)', transition: { duration: 0.2 } },
    tap: { scale: 0.95, backgroundColor: 'rgba(212, 232, 212, 0.3)', transition: { duration: 0.1 } },
  };

  const menuVariants = {
    hidden: { opacity: 0, y: -10 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.3, ease: 'easeOut' } },
    exit: { opacity: 0, y: -10, transition: { duration: 0.2, ease: 'easeIn' } },
  };

  const selectedProjectText = selectedProject || 'Seleccionar Proyecto';
  const iconSize = { width: isMobile ? 20 : 24, height: isMobile ? 20 : 24 }; // Tamaño consistente para íconos

  // Determinar si el proyecto usa CAC o no para ciertas decisiones de UI
  const projectUsesCac = projectData && projectData.metodo_de_actualizacion &&
    !(projectData.metodo_de_actualizacion.toLowerCase().includes('sin') ||
      projectData.metodo_de_actualizacion.toLowerCase().includes('no'));

  return (
    <Container
      maxWidth={isClientView ? false : "lg"}
      disableGutters={isClientView}
      sx={{
        py: isClientView ? 0 : 2,
        px: isClientView ? 0 : 2,
        minHeight: isMobile ? 'unset' : '100vh'
      }}
    >
      {/* Mostrar selector de proyecto solo para no-clientes */}
      {!isClientView && !isClient && (
        <Box sx={{
          mb: 3,
          display: 'flex',
          alignItems: 'center',
          flexDirection: isMobile ? 'column' : 'row',
          gap: isMobile ? 1 : 0
        }}>
          {/* Mantén exactamente el mismo código que ya tienes dentro de este Box */}
          {!isMobile && (
            <Typography
              variant="h4"
              fontWeight="bold"
              color="#2e7d32"
              sx={{ mr: 2 }}
            >
              Dashboard de obras
            </Typography>
          )}

          <motion.div
            initial="rest"
            whileHover="hover"
            whileTap="tap"
            variants={buttonVariants}
            style={{
              width: isMobile ? '100%' : 'auto',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <Button
              onClick={handleProjectClick}
              variant="outlined"
              sx={{
                minWidth: 160,
                height: 40,
                borderRadius: 20,
                borderColor: '#4caf50',
                color: '#2e7d32',
                fontWeight: 'bold',
                fontSize: '0.9rem',
                textTransform: 'none',
                '&:hover': {
                  borderColor: '#2e7d32',
                  backgroundColor: 'transparent',
                  boxShadow: 'none',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                px: 2,
                boxShadow: 'none',
                width: isMobile ? '100%' : 'auto',
                backgroundColor: 'transparent',
              }}
            >
              {selectedProjectText}
              <Box
                component={motion.div}
                animate={{ rotate: anchorEl ? 180 : 0 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
                sx={{
                  width: 24,
                  height: 24,
                  borderRadius: '50%',
                  backgroundColor: '#4caf50',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  ml: 1,
                }}
              >
                <ArrowDropDownIcon sx={{ color: '#FFFFFF', fontSize: '1.2rem' }} />
              </Box>
            </Button>
          </motion.div>

          <AnimatePresence>
            {anchorEl && (
              <motion.div
                initial="hidden"
                animate="visible"
                exit="exit"
                variants={menuVariants}
              >
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleProjectClose}
                  PaperProps={{
                    sx: {
                      borderRadius: 8,
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                      backgroundColor: '#FFFFFF',
                      mt: 0.5,
                    },
                  }}
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                >
                  {projects.map((project) => (
                    <MenuItem
                      key={project.codigo_de_proyecto}
                      onClick={() => handleProjectSelect(project.codigo_de_proyecto)}
                      sx={{
                        fontSize: '0.9rem',
                        color: '#424242',
                        '&:hover': { backgroundColor: '#E8F5E9', color: '#2e7d32' },
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                        px: 2,
                        py: 1,
                      }}
                    >
                      {project.es_terminada ? (
                        <CheckCircleIcon sx={{ ...iconSize, color: '#2e7d32' }} />
                      ) : (
                        <ConstructionIcon sx={{ ...iconSize, color: '#ffca28' }} />
                      )}
                      {project.codigo_de_proyecto}
                    </MenuItem>
                  ))}
                </Menu>
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
      )}

      {/* Añadir este nuevo bloque para mostrar la cabecera de proyecto para clientes */}
      {isClient && !isClientView && (
        <Box sx={{
          mb: 3,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <Typography
            variant={isMobile ? "h5" : "h4"}
            fontWeight="bold"
            color="#2e7d32"
          >
            Mi Proyecto
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              bgcolor: '#f1f8e9',
              px: 2,
              py: 1,
              borderRadius: 2
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color="#2e7d32"
              sx={{ fontSize: isMobile ? '0.9rem' : '1rem' }}
            >
              {selectedProject}
            </Typography>
          </Box>
        </Box>
      )}

      {isLoading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 4 }}>
          <CircularProgress sx={{ color: '#4caf50' }} />
        </Box>
      ) : projectData && (
        <Grid container spacing={3} alignItems="stretch">
          {/* Primera fila: Información del proyecto y Dinero en Caja */}
          <Grid container item spacing={3} xs={12}>
            {/* Información del proyecto (izquierda) - Mostrar versión completa en desktop, simplificada en móvil */}
            <Grid item xs={12} md={9}>
              {isMobile ? (
                <StyledCard>
                  <CardContent sx={{ py: 2, px: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                      <Box>
                        <Typography variant="subtitle1" fontWeight="bold" color="#424242" fontSize="0.95rem">
                          Metros de Cómputo
                        </Typography>
                        <Typography variant="body1" color="#666666" fontSize="0.95rem">
                          {projectData.metros_de_computo || 0} m²
                        </Typography>
                      </Box>
                      <Box>
                        <IconButton
                          size="small"
                          onClick={handleOpenInfoDialog}
                          sx={{
                            bgcolor: 'rgba(76, 175, 80, 0.08)',
                            color: '#4caf50',
                            '&:hover': {
                              bgcolor: 'rgba(76, 175, 80, 0.15)',
                            }
                          }}
                        >
                          <InfoOutlinedIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" fontWeight="bold" color="#2e7d32" fontSize="1rem">
                        Tiempo en Obra
                      </Typography>
                      <Typography variant="body1" color="#2e7d32" fontSize="0.9rem" fontWeight="bold">
                        {projectData.duration}
                      </Typography>
                    </Box>
                  </CardContent>
                </StyledCard>
              ) : (
                <StyledCard>
                  <CardContent sx={{ py: 4, px: 4 }}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle1" fontWeight="bold" color="#424242" fontSize="0.9rem">
                          Apellido del Cliente
                        </Typography>
                        <Typography variant="body1" color="#666666" fontSize="0.9rem">
                          {projectData.apellido_del_cliente}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle1" fontWeight="bold" color="#424242" fontSize="0.9rem">
                          Ubicación
                        </Typography>
                        <Typography variant="body1" color="#666666" fontSize="0.9rem">
                          {projectData.ubicacion_del_proyecto || 'No especificada'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle1" fontWeight="bold" color="#424242" fontSize="0.9rem">
                          Metros Cubiertos
                        </Typography>
                        <Typography variant="body1" color="#666666" fontSize="0.9rem">
                          {projectData.metros_cubiertos || 0} m²
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle1" fontWeight="bold" color="#424242" fontSize="0.9rem">
                          Metros Semi Cubiertos
                        </Typography>
                        <Typography variant="body1" color="#666666" fontSize="0.9rem">
                          {projectData.metros_semi_cubiertos || 0} m²
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle1" fontWeight="bold" color="#424242" fontSize="0.9rem">
                          Metros Solados
                        </Typography>
                        <Typography variant="body1" color="#666666" fontSize="0.9rem">
                          {projectData.metros_solados || 0} m²
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle1" fontWeight="bold" color="#424242" fontSize="0.9rem">
                          Metros de Cómputo
                        </Typography>
                        <Typography variant="body1" color="#666666" fontSize="0.9rem">
                          {projectData.metros_de_computo || 0} m²
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle1" fontWeight="bold" color="#424242" fontSize="0.9rem">
                          Fecha de Inicio
                        </Typography>
                        <Typography variant="body1" color="#666666" fontSize="0.9rem">
                          {moment(projectData.fecha_de_inicio_obra).format('DD/MM/YYYY')}
                          {projectData.es_terminada && (
                            <>
                              {' - '}
                              {moment(projectData.fecha_de_finalizacion_obra).format('DD/MM/YYYY')}
                            </>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} sm={6} md={3}>
                        <Typography variant="subtitle1" fontWeight="bold" color="#424242" fontSize="0.9rem">
                          Tiempo de Obra
                        </Typography>

                        <Typography variant="body1" color="#2e7d32" sx={{ fontWeight: 'bold', fontSize: '1.1rem' }}>
                          {projectData.duration}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </StyledCard>
              )}
            </Grid>

            {/* Dinero en Caja (derecha) */}
            <Grid item xs={12} md={3}>
              <CajaCard projectCode={selectedProject} />
            </Grid>
          </Grid>

          {/* Segunda fila: Costo por metro (más estrecho) y Gastos totales (más ancho) */}
          <Grid item xs={12} sm={4} md={3}>
            {isCostLoading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', minHeight: 180 }}>
                <CircularProgress sx={{ color: '#4caf50' }} />
              </Box>
            ) : (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                whileHover={{ scale: isMobile ? 1 : 1.01 }}
                transition={{ duration: 0.3, ease: 'easeOut' }}
                style={{ height: '100%' }}
              >
                <CostCard sx={{ height: '100%', display: 'flex' }}>
                  <CardContent sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    p: isMobile ? 2 : 3,
                    textAlign: 'center'
                  }}>
                    <Typography variant={isMobile ? "subtitle1" : "h6"} fontWeight="bold" sx={{ color: '#FFFFFF', mb: 2 }}>
                      Costo actual por metro
                    </Typography>
                    <Typography variant={isMobile ? "h5" : "h4"} fontWeight="bold" sx={{ color: '#FFFFFF' }}>
                      ${costPerSquareMeter} USD/m²
                    </Typography>
                  </CardContent>
                </CostCard>
              </motion.div>
            )}
          </Grid>

          <Grid item xs={12} sm={8} md={9}>
            <TotalExpensesCard projectCode={selectedProject} />
          </Grid>

          {/* Tercera fila con honorarios y gastos mensuales */}
          <Grid item xs={12} sm={6} md={4}>
            <HonorariosCard projectCode={selectedProject} />
          </Grid>

          {/* Nueva tarjeta de gastos mensuales */}
          <Grid item xs={12} sm={6} md={8}>
            <MonthlyExpensesCard projectCode={selectedProject} />
          </Grid>

          {/* Tarjeta de actualización de Mano de Obra con CAC */}
          <Grid item xs={12} sm={12} md={12}>
            <CACUpdateCard projectCode={selectedProject} />
          </Grid>
        </Grid>
      )}

      {/* Diálogo de información del proyecto que se abre en móvil */}
      <ProjectInfoDialog
        open={infoDialogOpen}
        onClose={handleCloseInfoDialog}
        projectData={projectData}
      />
    </Container>
  );
};

export default NewDashboard;