import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Container,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Card,
  InputAdornment,
  IconButton,
  useTheme,
  useMediaQuery
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';

const Login = () => {
  const { signIn, user, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [capsLockOn, setCapsLockOn] = useState(false);

  // Redirect if already authenticated
  useEffect(() => {
    if (user && !loading) {
      const from = location.state?.from?.pathname || '/dashboard';
      navigate(from, { replace: true });
    }
  }, [user, loading, navigate, location]);

  // Detect Caps Lock
  const checkCapsLock = (e) => {
    setCapsLockOn(e.getModifierState('CapsLock'));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    // Optional: Add validation here
    if (!email || !password) {
      setError('Por favor complete todos los campos');
      setIsSubmitting(false);
      return;
    }

    const { success, error } = await signIn(email, password);

    if (!success) {
      setError(error.message || 'Error al iniciar sesión');
      setIsSubmitting(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)'
        }}
      >
        <CircularProgress sx={{ color: '#000' }} />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)',
        position: 'relative',
        overflow: 'hidden'
      }}
    >
      {/* Subtle animated dots background */}
      <Box
        component={motion.div}
        animate={{ opacity: [0, 0.03, 0.03, 0] }}
        transition={{ duration: 8, repeat: Infinity, ease: "easeInOut" }}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: `radial-gradient(#000 0.5px, transparent 0.5px), radial-gradient(#000 0.5px, transparent 0.5px)`,
          backgroundSize: '20px 20px',
          backgroundPosition: '0 0, 10px 10px',
          zIndex: 0
        }}
      />

      {/* Animated floating shapes */}
      <Box
        component={motion.div}
        animate={{
          y: [0, -30, 0],
          rotate: [0, 5, 0],
          scale: [1, 1.05, 1]
        }}
        transition={{
          duration: 20,
          repeat: Infinity,
          ease: "easeInOut"
        }}
        sx={{
          position: 'absolute',
          top: '10%',
          left: '15%',
          width: '400px',
          height: '400px',
          borderRadius: '60% 40% 30% 70% / 60% 30% 70% 40%',
          background: 'linear-gradient(135deg, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0) 100%)',
          filter: 'blur(40px)',
          opacity: 0.6,
          zIndex: 0
        }}
      />

      <Box
        component={motion.div}
        animate={{
          y: [0, 40, 0],
          rotate: [0, -8, 0],
          scale: [1, 1.1, 1]
        }}
        transition={{
          duration: 25,
          repeat: Infinity,
          ease: "easeInOut",
          delay: 1
        }}
        sx={{
          position: 'absolute',
          bottom: '10%',
          right: '20%',
          width: '350px',
          height: '350px',
          borderRadius: '30% 70% 70% 30% / 30% 30% 70% 70%',
          background: 'linear-gradient(135deg, rgba(255,255,255,0.3) 0%, rgba(255,255,255,0) 100%)',
          filter: 'blur(40px)',
          opacity: 0.5,
          zIndex: 0
        }}
      />

      {/* Logo posicionado arriba a la izquierda en desktop */}
      <Box
        component={motion.div}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          duration: 0.8,
          ease: "easeOut"
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
          position: isMobile ? 'relative' : 'absolute',
          top: isMobile ? 'auto' : 40,
          left: isMobile ? 'auto' : 40,
          zIndex: 5
        }}
      >
        <motion.div
          whileHover={{
            scale: 1.05,
            transition: { duration: 0.3 }
          }}
        >
          <motion.img
            src="/images/logo.png"
            alt="Nokk"
            style={{
              height: 'auto',
              width: isMobile ? '120px' : '100px'  // Más pequeño en desktop
            }}
          />
        </motion.div>
      </Box>

      <Container
        maxWidth="xl"
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
          position: 'relative',
          zIndex: 1
        }}
      >
        {/* Main content container with left and right sides */}
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            maxWidth: '1200px',
            flexDirection: isMobile ? 'column' : 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
            p: isMobile ? 2 : 0
          }}
        >
          {/* Left side with message - logo moved to top left */}
          <Box
            sx={{
              flex: '1',
              display: 'flex',
              flexDirection: 'column',
              alignItems: isMobile ? 'center' : 'flex-start',
              justifyContent: 'center',
              pr: isMobile ? 0 : 8,
              mb: isMobile ? 5 : 0,
              mt: isMobile ? 5 : 0,
              textAlign: isMobile ? 'center' : 'left'
            }}
          >
            {/* Logo removed from here as it's now positioned absolutely */}

            <Typography
              component={motion.h1}
              variant={isMobile ? "h4" : "h2"}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              sx={{
                fontWeight: 600,
                color: '#111',
                mb: 3,
                letterSpacing: '-0.5px'
              }}
            >
              Construcción eficiente.</Typography>

            <Typography
              component={motion.p}
              variant="body1"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.4 }}
              sx={{
                color: '#666',
                maxWidth: '500px',
                fontSize: '1.1rem',
                lineHeight: 1.6
              }}
            >
              Haz el seguimiento de tu obra al detalle contando con toda la información que necesitás en tiempo real.
            </Typography>
          </Box>

          {/* Right side with login form in a rounded box */}
          <Box
            component={motion.div}
            initial={{ opacity: 0, x: isMobile ? 0 : 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            sx={{
              width: isMobile ? '100%' : '450px',
              position: 'relative'
            }}
          >
            {/* Card with glass effect */}
            <Card
              elevation={0}
              sx={{
                borderRadius: 4,
                p: { xs: 3, md: 5 },
                backdropFilter: 'blur(10px)',
                background: 'rgba(255, 255, 255, 0.8)',
                boxShadow: '0 20px 80px rgba(0, 0, 0, 0.1), 0 4px 20px rgba(0, 0, 0, 0.05)',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                overflow: 'visible',
                position: 'relative'
              }}
            >
              {/* Subtle glow effect */}
              <Box
                component={motion.div}
                animate={{
                  boxShadow: [
                    '0 0 0px 0px rgba(255, 255, 255, 0)',
                    '0 0 40px 10px rgba(255, 255, 255, 0.5)',
                    '0 0 0px 0px rgba(255, 255, 255, 0)'
                  ]
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  repeatType: "reverse"
                }}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  borderRadius: 4,
                  zIndex: -1
                }}
              />

              <Typography
                variant="h5"
                sx={{
                  fontWeight: 600,
                  mb: 1,
                  color: '#111'
                }}
              >
                Iniciar sesión
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  mb: 4,
                  color: '#666'
                }}
              >
                Ingresa tus credenciales para acceder
              </Typography>

              <AnimatePresence>
                {error && (
                  <motion.div
                    initial={{ opacity: 0, y: -10, height: 0 }}
                    animate={{ opacity: 1, y: 0, height: 'auto' }}
                    exit={{ opacity: 0, y: -10, height: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <Alert
                      severity="error"
                      sx={{
                        mb: 3,
                        borderRadius: 2,
                        boxShadow: '0 4px 12px rgba(0,0,0,0.05)'
                      }}
                    >
                      {error}
                    </Alert>
                  </motion.div>
                )}
              </AnimatePresence>

              <form onSubmit={handleSubmit}>
                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.1 }}
                >
                  <TextField
                    label="Email"
                    type="email"
                    fullWidth
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                    disabled={isSubmitting}
                    sx={{
                      mb: 3,
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 2,
                        transition: 'all 0.2s ease-in-out',
                        '&:hover fieldset': {
                          borderColor: '#000',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#000',
                          borderWidth: '1px'
                        }
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#000'
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon sx={{ color: '#999' }} />
                        </InputAdornment>
                      )
                    }}
                  />
                </motion.div>

                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.2 }}
                >
                  <TextField
                    label="Contraseña"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    variant="outlined"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    onKeyDown={checkCapsLock}
                    onKeyUp={checkCapsLock}
                    required
                    disabled={isSubmitting}
                    sx={{
                      mb: capsLockOn ? 1 : 3,
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 2,
                        transition: 'all 0.2s ease-in-out',
                        '&:hover fieldset': {
                          borderColor: '#000',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: '#000',
                          borderWidth: '1px'
                        }
                      },
                      '& .MuiInputLabel-root.Mui-focused': {
                        color: '#000'
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <LockIcon sx={{ color: '#999' }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={togglePasswordVisibility}
                            edge="end"
                          >
                            {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </motion.div>

                {/* Caps Lock warning */}
                <AnimatePresence>
                  {capsLockOn && (
                    <motion.div
                      initial={{ opacity: 0, y: -10, height: 0 }}
                      animate={{ opacity: 1, y: 0, height: 'auto' }}
                      exit={{ opacity: 0, y: -10, height: 0 }}
                      transition={{ duration: 0.2 }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          mb: 2,
                          color: '#FF9800'
                        }}
                      >
                        <KeyboardCapslockIcon sx={{ mr: 1, fontSize: 18 }} />
                        <Typography variant="caption">
                          Bloq Mayús está activado
                        </Typography>
                      </Box>
                    </motion.div>
                  )}
                </AnimatePresence>

                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    mb: 3
                  }}
                >
                  <Typography
                    component={motion.span}
                    whileHover={{
                      color: '#000',
                      transition: { duration: 0.2 }
                    }}
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      cursor: 'pointer',
                      position: 'relative',
                      display: 'inline-block'
                    }}
                  >
                    ¿Olvidaste tu contraseña?
                    <motion.span
                      style={{
                        position: 'absolute',
                        bottom: -2,
                        left: 0,
                        right: 0,
                        height: 1,
                        background: '#000',
                        transformOrigin: 'left',
                        scaleX: 0
                      }}
                      whileHover={{
                        scaleX: 1,
                        transition: { duration: 0.3 }
                      }}
                    />
                  </Typography>
                </Box>

                <motion.div
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: 0.3 }}
                >
                  <Button
                    component={motion.button}
                    whileHover={{
                      scale: 1.02,
                      boxShadow: '0 5px 15px rgba(0,0,0,0.1)'
                    }}
                    whileTap={{ scale: 0.98 }}
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={isSubmitting}
                    sx={{
                      py: 1.5,
                      borderRadius: 2,
                      textTransform: 'none',
                      fontSize: '1rem',
                      fontWeight: 500,
                      background: '#000',
                      color: '#fff',
                      mb: 2,
                      '&:hover': {
                        background: '#000'
                      }
                    }}
                  >
                    {isSubmitting ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      'Iniciar sesión'
                    )}
                  </Button>
                </motion.div>
              </form>

              {/* Registration link */}
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  mt: 3
                }}
              >
                <Typography variant="body2" color="text.secondary">
                  ¿No tienes una cuenta?
                </Typography>
                <Typography
                  component={motion.span}
                  whileHover={{
                    color: '#000',
                    transition: { duration: 0.2 }
                  }}
                  variant="body2"
                  sx={{
                    ml: 1,
                    fontWeight: 500,
                    color: '#666',
                    cursor: 'pointer',
                    position: 'relative',
                    display: 'inline-block'
                  }}
                >
                  Regístrate
                  <motion.span
                    style={{
                      position: 'absolute',
                      bottom: -2,
                      left: 0,
                      right: 0,
                      height: 1,
                      background: '#000',
                      transformOrigin: 'left',
                      scaleX: 0
                    }}
                    whileHover={{
                      scaleX: 1,
                      transition: { duration: 0.3 }
                    }}
                  />
                </Typography>
              </Box>
            </Card>
          </Box>
        </Box>
      </Container>

      {/* Footer con copyright */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 10,
          left: 0,
          right: 0,
          display: 'flex',
          justifyContent: 'center',
          zIndex: 10
        }}
      >
        <Typography variant="caption" color="text.secondary">
          © 2025 Nokk. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;