// Busca el final del archivo, justo antes del último return de cierre
// Añade este código antes del cierre final de SidebarContainer (antes de </SidebarContainer>)

import React, { useState, useEffect } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
  Collapse,
  Divider,
  Box,
  Avatar,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Alert,
  Paper,
  Badge,
  Tooltip,
  CircularProgress
} from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient';
import { useAuth } from '../contexts/AuthContext';
import DashboardIcon from '@mui/icons-material/DashboardOutlined';
import InboxIcon from '@mui/icons-material/MoveToInboxOutlined';
import SendIcon from '@mui/icons-material/SendOutlined';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import AssignmentIcon from '@mui/icons-material/AssignmentOutlined';
import ListAltIcon from '@mui/icons-material/ListAltOutlined';
import TimelineIcon from '@mui/icons-material/TimelineOutlined';
import GroupIcon from '@mui/icons-material/GroupOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined';
import WorkIcon from '@mui/icons-material/WorkOutlined';
import PeopleIcon from '@mui/icons-material/PeopleOutlined';
import BusinessIcon from '@mui/icons-material/BusinessOutlined';
import ConstructionIcon from '@mui/icons-material/ConstructionOutlined';
import BarChartIcon from '@mui/icons-material/BarChartOutlined';
import LogoutIcon from '@mui/icons-material/LogoutOutlined';
import LockIcon from '@mui/icons-material/LockOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircleOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import SavingsIcon from '@mui/icons-material/Savings';

const drawerWidth = 260;

// Updated Sidebar Container with glass effect
const SidebarContainer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  '& .MuiDrawer-paper': {
    width: drawerWidth,
    boxSizing: 'border-box',
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    backdropFilter: 'blur(8px)',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.08)',
    py: 2,
    overflowX: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    borderRight: '1px solid rgba(0, 0, 0, 0.05)',
  },
}));

// Modern user profile container with hover effect
const UserProfileContainer = styled(Paper)(({ theme }) => ({
  margin: theme.spacing(2),
  padding: theme.spacing(1.8),
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  borderRadius: 16,
  background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.8) 0%, rgba(245, 245, 245, 0.9) 100%)',
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.06)',
  border: '1px solid rgba(0, 0, 0, 0.02)',
  transition: 'all 0.3s ease',
  '&:hover': {
    transform: 'translateY(-3px)',
    boxShadow: '0 8px 25px rgba(0, 0, 0, 0.1)',
    background: 'linear-gradient(135deg, rgba(255, 255, 255, 0.95) 0%, rgba(250, 250, 250, 0.95) 100%)',
  },
}));

// Styled avatar with gradient
const StyledAvatar = styled(Avatar)(({ theme }) => ({
  background: 'linear-gradient(135deg, #2e7d32 0%, #66bb6a 100%)',
  width: 40,
  height: 40,
  marginRight: theme.spacing(2),
  boxShadow: '0 4px 12px rgba(46, 125, 50, 0.2)',
  border: '2px solid #ffffff',
}));

// Styled menu item with hover effect
const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
  borderRadius: 8,
  margin: '4px 8px',
  padding: '10px 16px',
  transition: 'all 0.2s ease',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    transform: 'translateX(4px)',
  },
}));

// Logo container
const LogoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(3, 2, 2, 2),
}));

const Sidebar = ({ empresaNombre }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user, userRole, userDetails, signOut } = useAuth();
  const [adminOpen, setAdminOpen] = useState(false);
  const [localEmpresaNombre, setLocalEmpresaNombre] = useState(empresaNombre || 'Nokk');

  // Estados para el perfil de usuario
  const [anchorEl, setAnchorEl] = useState(null);
  const [changePasswordOpen, setChangePasswordOpen] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const menuOpen = Boolean(anchorEl);

  // Check if the user is a client
  const isClient = userRole === 'client';

  useEffect(() => {
    if (empresaNombre) {
      setLocalEmpresaNombre(empresaNombre);
    } else {
      const fetchEmpresa = async () => {
        const { data } = await supabase.from('empresa').select('nombre_de_empresa').limit(1);
        if (data && data.length > 0 && data[0].nombre_de_empresa) {
          setLocalEmpresaNombre(data[0].nombre_de_empresa);
        }
      };
      fetchEmpresa();
    }
  }, [empresaNombre]);

  const handleAdminToggle = () => {
    setAdminOpen(!adminOpen);
  };

  // Funciones para el perfil de usuario
  const handleProfileClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleOpenChangePassword = () => {
    handleMenuClose();
    setChangePasswordOpen(true);
    setPassword('');
    setConfirmPassword('');
    setError('');
    setSuccess('');
  };

  const handleCloseChangePassword = () => {
    setChangePasswordOpen(false);
  };

  const handleChangePassword = async () => {
    if (password !== confirmPassword) {
      setError('Las contraseñas no coinciden');
      return;
    }

    if (password.length < 6) {
      setError('La contraseña debe tener al menos 6 caracteres');
      return;
    }

    setError('');
    setLoading(true);

    try {
      const { error } = await supabase.auth.updateUser({
        password,
      });

      if (error) throw error;

      setSuccess('Contraseña actualizada correctamente');
      setTimeout(() => {
        handleCloseChangePassword();
      }, 2000);
    } catch (error) {
      console.error('Error al cambiar contraseña:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  // Obtener iniciales del usuario para el avatar
  const getInitials = () => {
    if (!user || !user.email) return '?';

    // Intentar obtener iniciales del email y convertir primera letra a mayúscula
    const emailName = user.email.split('@')[0];
    const parts = emailName.split(/[._-]/);
    if (parts.length >= 2) {
      return (parts[0][0].toUpperCase() + parts[1][0].toUpperCase());
    }

    return emailName[0].toUpperCase();
  };

  // Obtener el nombre de usuario con primera letra en mayúscula
  const getUserName = () => {
    if (!user || !user.email) return 'Usuario';

    // Si es cliente y tenemos los detalles, usar apellido_del_cliente
    if (isClient && userDetails && userDetails.apellido_del_cliente) {
      return userDetails.apellido_del_cliente;
    }

    const emailName = user.email.split('@')[0];
    return emailName.charAt(0).toUpperCase() + emailName.slice(1);
  };

  // Añadir función para obtener la ubicación del proyecto
  const getProjectLocation = () => {
    if (isClient && userDetails && userDetails.ubicacion_del_proyecto) {
      return userDetails.ubicacion_del_proyecto;
    }
    return getRoleDisplay(); // Mostrar el rol si no hay ubicación
  };

  // Determinar el rol para mostrar
  const getRoleDisplay = () => {
    switch (userRole) {
      case 'admin':
        return 'Administrador';
      case 'worker':
        return 'Trabajador';
      case 'client':
        return 'Cliente';
      default:
        return 'Usuario';
    }
  };

  // Función para cerrar sesión
  const handleSignOut = async () => {
    console.log("Intentando cerrar sesión...");
    try {
      // Cierra el menú si está abierto
      if (menuOpen) {
        handleMenuClose();
      }

      // Usar directamente la API de Supabase
      const { error } = await supabase.auth.signOut();

      if (error) {
        console.error("Error de Supabase al cerrar sesión:", error);
        throw error;
      }

      console.log("Sesión cerrada exitosamente, redirigiendo...");

      // Redirigir al login y forzar recarga completa
      window.location.replace('/login');
    } catch (err) {
      console.error("Error en el proceso de cierre de sesión:", err);
      alert("Error al cerrar sesión. Verifica la consola para más detalles.");
    }
  };

  // Animation variants for menu items
  const menuItemAnimation = {
    hover: {
      scale: 1.03,
      x: 8,
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 10
      }
    }
  };

  // Animation variants for icons
  const iconAnimation = {
    hover: {
      scale: 1.2,
      y: -3,
      color: '#2e7d32',
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 10
      }
    }
  };

  // Animation for admin toggle icon
  const adminToggleAnimation = {
    initial: { rotate: 0 },
    open: {
      rotate: 180,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    },
    closed: {
      rotate: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut"
      }
    }
  };

  // Animación para el avatar
  const avatarAnimation = {
    hover: {
      scale: 1.1,
      boxShadow: '0 8px 15px rgba(46, 125, 50, 0.3)',
      transition: {
        type: 'spring',
        stiffness: 300,
        damping: 10
      }
    }
  };

  // List item animation
  const listItemAnimation = {
    initial: { x: -20, opacity: 0 },
    animate: i => ({
      x: 0,
      opacity: 1,
      transition: {
        delay: i * 0.05,
        duration: 0.4,
        ease: "easeOut"
      }
    }),
    exit: { x: -20, opacity: 0 }
  };

  // Main menu items con el orden requerido
  const menuItems = [
    { text: 'Cajas', icon: <SavingsIcon />, path: '/dashboard', index: 0 },
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard-obras', index: 1 },
    { text: 'Trabajadores', icon: <BarChartIcon />, path: '/dashboard-trabajadores', index: 2 },
    { text: 'Presupuestos', icon: <AssignmentIcon />, path: '/presupuestos', index: 3 },
    { text: 'Entradas', icon: <InboxIcon />, path: '/entradas', index: 4 },
    { text: 'Salidas', icon: <SendIcon />, path: '/salidas', index: 5 },
    { text: 'Cambios', icon: <CurrencyExchangeIcon />, path: '/cambios-de-moneda', index: 6 },
    { text: 'Cobro socios', icon: <WorkIcon />, path: '/admin/cobro-trabajadores', index: 7 },
    { text: 'Arqueos', icon: <AccountBalanceIcon />, path: '/admin/arqueos', index: 8 },
    { text: 'Buscador Unificado', icon: <SearchIcon />, path: '/buscador-unificado', index: 9 },
  ];

  // Admin menu items
  const adminItems = [
    { text: 'Proyectos', icon: <AssignmentIcon />, path: '/admin/proyectos', index: 0 },
    { text: 'Conceptos', icon: <ListAltIcon />, path: '/admin/conceptos', index: 1 },
    { text: 'Etapas', icon: <TimelineIcon />, path: '/admin/etapas', index: 2 },
    { text: 'Proveedores', icon: <GroupIcon />, path: '/admin/proveedores', index: 3 },
    { text: 'Trabajadores', icon: <PeopleIcon />, path: '/admin/trabajadores', index: 4 },
    { text: 'Caja Empresa', icon: <BusinessIcon />, path: '/admin/caja-empresa', index: 5 },
    { text: 'Índices CAC', icon: <ConstructionIcon />, path: '/admin/indices-cac', index: 6 },
  ];

  // Client menu items
  const clientMenuItems = [
    { text: 'Dashboard', icon: <DashboardIcon />, path: '/dashboard', index: 0 },
    { text: 'Presupuestos', icon: <AssignmentIcon />, path: '/presupuestos', index: 1 },
    { text: 'Entradas', icon: <InboxIcon />, path: '/entradas', index: 2 },
    { text: 'Salidas', icon: <SendIcon />, path: '/salidas', index: 3 },
    { text: 'Cambios', icon: <CurrencyExchangeIcon />, path: '/cambios-de-moneda', index: 4 },
    { text: 'Buscador', icon: <SearchIcon />, path: '/buscador-unificado', index: 5 },
  ];

  return (
    <SidebarContainer variant="permanent">
      {/* Logo en la parte superior */}
      <LogoContainer>
        <Box
          component={motion.div}
          whileHover={{ scale: 1.05 }}
          transition={{ duration: 0.3 }}
        >
          <img
            src="/images/logo.png"
            alt={localEmpresaNombre}
            style={{
              height: '40px',
              maxWidth: '140px',
              objectFit: 'contain'
            }}
          />
        </Box>
      </LogoContainer>

      {/* User profile section */}
      {user && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.4 }}
        >
          <UserProfileContainer elevation={0} onClick={handleProfileClick}>
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
              badgeContent={
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: '50%',
                    backgroundColor: '#4caf50',
                    border: '2px solid white'
                  }}
                />
              }
            >
              <motion.div whileHover="hover" variants={avatarAnimation}>
                <StyledAvatar>{getInitials()}</StyledAvatar>
              </motion.div>
            </Badge>
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 600,
                  fontSize: '0.95rem',
                  color: '#263238'
                }}
              >
                {getUserName()}
              </Typography>
              <Typography
                variant="caption"
                sx={{
                  color: '#546e7a',
                  fontSize: '0.8rem',
                  opacity: 0.9
                }}
              >
                {getProjectLocation()}
              </Typography>
            </Box>
            <motion.div
              animate={{ rotate: menuOpen ? 180 : 0 }}
              transition={{ duration: 0.3 }}
            >
              <ExpandMoreIcon sx={{ fontSize: 20, color: '#78909c' }} />
            </motion.div>
          </UserProfileContainer>
        </motion.div>
      )}

      {/* User profile menu */}
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 4,
          sx: {
            minWidth: 240,
            borderRadius: 3,
            overflow: 'visible',
            backgroundColor: 'rgba(255, 255, 255, 0.95)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 10px 40px rgba(0,0,0,0.1)',
            mt: 1.5,
            '& .MuiMenuItem-root': {
              px: 2,
              py: 1.2,
              borderRadius: 2,
              mx: 0.5,
              my: 0.3,
            },
            ':before': {
              content: '""',
              display: 'block',
              position: 'absolute',
              top: 0,
              left: 18,
              width: 12,
              height: 12,
              bgcolor: 'rgba(255, 255, 255, 0.95)',
              transform: 'translateY(-50%) rotate(45deg)',
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
      >
        <Box sx={{ px: 3, py: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
            <StyledAvatar sx={{ width: 42, height: 42 }}>{getInitials()}</StyledAvatar>
            <Box sx={{ ml: 2 }}>
              <Typography variant="subtitle1" fontWeight="bold" sx={{ lineHeight: 1.2 }}>
                {getUserName()}
              </Typography>
              <Typography variant="caption" color="text.secondary">
                {user?.email}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mt: 1 }}>
            <Typography
              variant="caption"
              sx={{
                display: 'inline-block',
                px: 1.5,
                py: 0.5,
                borderRadius: 1,
                bgcolor: '#e8f5e9',
                color: '#2e7d32',
                fontWeight: 'medium'
              }}
            >
              {getRoleDisplay()}
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ my: 1 }} />

        <StyledMenuItem onClick={handleOpenChangePassword}>
          <ListItemIcon sx={{ color: '#455a64' }}>
            <LockIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="body2">Cambiar contraseña</Typography>
        </StyledMenuItem>

        <StyledMenuItem onClick={handleSignOut} sx={{ color: '#f44336' }}>
          <ListItemIcon sx={{ color: '#f44336' }}>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="body2">Cerrar sesión</Typography>
        </StyledMenuItem>
      </Menu>

      <Divider sx={{ mt: 2, mb: 2, opacity: 0.6 }} />

      {/* Main menu */}
      <List sx={{ px: 1, pt: 1, flex: 1, overflowY: 'auto' }}>
        {isClient ? (
          // Menú para clientes
          clientMenuItems.map((item) => (
            <motion.div
              key={item.text}
              custom={item.index}
              initial="initial"
              animate="animate"
              variants={listItemAnimation}
            >
              <ListItem disablePadding sx={{ mb: 0.8 }}>
                <motion.div
                  whileHover="hover"
                  variants={menuItemAnimation}
                  style={{ width: '100%' }}
                >
                  <ListItemButton
                    component={Link}
                    to={item.path}
                    selected={location.pathname === item.path}
                    sx={{
                      borderRadius: 3,
                      backgroundColor: location.pathname === item.path ? 'rgba(46, 125, 50, 0.08)' : 'transparent',
                      '&:hover': {
                        backgroundColor: location.pathname === item.path ? 'rgba(46, 125, 50, 0.12)' : 'rgba(0, 0, 0, 0.04)',
                        boxShadow: location.pathname === item.path ? '0 4px 12px rgba(46, 125, 50, 0.15)' : '0 4px 12px rgba(0, 0, 0, 0.05)',
                      },
                      ...(location.pathname === item.path && {
                        boxShadow: '0 4px 12px rgba(46, 125, 50, 0.12)',
                      }),
                      minHeight: 48,
                      px: 2.5,
                      py: 1,
                      justifyContent: 'initial',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        minWidth: 36,
                        color: location.pathname === item.path ? '#2e7d32' : '#78909c',
                        mr: 1,
                      }}
                    >
                      <motion.div
                        whileHover="hover"
                        variants={iconAnimation}
                      >
                        {React.cloneElement(item.icon, {
                          sx: {
                            fontSize: '1.4rem',
                          }
                        })}
                      </motion.div>
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      primaryTypographyProps={{
                        fontWeight: location.pathname === item.path ? 600 : 500,
                        fontSize: '0.92rem',
                        color: location.pathname === item.path ? '#2e7d32' : '#37474f',
                      }}
                    />
                    {location.pathname === item.path && (
                      <Box
                        component={motion.div}
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        sx={{
                          width: 6,
                          height: 6,
                          borderRadius: '50%',
                          backgroundColor: '#2e7d32',
                          mr: 1
                        }}
                      />
                    )}
                  </ListItemButton>
                </motion.div>
              </ListItem>
            </motion.div>
          ))
        ) : (
          // Menú para administradores y trabajadores
          <>
            {menuItems.map((item) => (
              <motion.div
                key={item.text}
                custom={item.index}
                initial="initial"
                animate="animate"
                variants={listItemAnimation}
              >
                <ListItem disablePadding sx={{ mb: 0.8 }}>
                  <motion.div
                    whileHover="hover"
                    variants={menuItemAnimation}
                    style={{ width: '100%' }}
                  >
                    <ListItemButton
                      component={Link}
                      to={item.path}
                      selected={location.pathname === item.path}
                      sx={{
                        borderRadius: 3,
                        backgroundColor: location.pathname === item.path ? 'rgba(46, 125, 50, 0.08)' : 'transparent',
                        '&:hover': {
                          backgroundColor: location.pathname === item.path ? 'rgba(46, 125, 50, 0.12)' : 'rgba(0, 0, 0, 0.04)',
                          boxShadow: location.pathname === item.path ? '0 4px 12px rgba(46, 125, 50, 0.15)' : '0 4px 12px rgba(0, 0, 0, 0.05)',
                        },
                        ...(location.pathname === item.path && {
                          boxShadow: '0 4px 12px rgba(46, 125, 50,0.12)',
                        }),
                        minHeight: 48,
                        px: 2.5,
                        py: 1,
                        justifyContent: 'initial',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <ListItemIcon
                        sx={{
                          minWidth: 36,
                          color: location.pathname === item.path ? '#2e7d32' : '#78909c',
                          mr: 1,
                        }}
                      >
                        <motion.div
                          whileHover="hover"
                          variants={iconAnimation}
                        >
                          {React.cloneElement(item.icon, {
                            sx: {
                              fontSize: '1.4rem',
                            }
                          })}
                        </motion.div>
                      </ListItemIcon>
                      <ListItemText
                        primary={item.text}
                        primaryTypographyProps={{
                          fontWeight: location.pathname === item.path ? 600 : 500,
                          fontSize: '0.92rem',
                          color: location.pathname === item.path ? '#2e7d32' : '#37474f',
                        }}
                      />
                      {location.pathname === item.path && (
                        <Box
                          component={motion.div}
                          initial={{ opacity: 0, scale: 0 }}
                          animate={{ opacity: 1, scale: 1 }}
                          sx={{
                            width: 6,
                            height: 6,
                            borderRadius: '50%',
                            backgroundColor: '#2e7d32',
                            mr: 1
                          }}
                        />
                      )}
                    </ListItemButton>
                  </motion.div>
                </ListItem>
              </motion.div>
            ))}

            {/* Admin section */}
            <motion.div
              custom={11}
              initial="initial"
              animate="animate"
              variants={listItemAnimation}
            >
              <ListItem disablePadding sx={{ mb: 0.8, mt: 2 }}>
                <ListItemButton
                  onClick={handleAdminToggle}
                  sx={{
                    borderRadius: 3,
                    backgroundColor: adminOpen ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
                    minHeight: 48,
                    px: 2.5,
                    py: 1,
                    justifyContent: 'initial',
                    display: 'flex',
                    alignItems: 'center',
                    '&:hover': {
                      backgroundColor: 'rgba(0, 0, 0, 0.07)',
                      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.08)',
                    },
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 36,
                      color: adminOpen ? '#2e7d32' : '#78909c',
                      mr: 1,
                    }}
                  >
                    <motion.div
                      whileHover="hover"
                      variants={iconAnimation}
                    >
                      <SettingsIcon sx={{ fontSize: '1.4rem' }} />
                    </motion.div>
                  </ListItemIcon>
                  <ListItemText
                    primary="Admin"
                    primaryTypographyProps={{
                      fontWeight: adminOpen ? 600 : 500,
                      fontSize: '0.92rem',
                      color: adminOpen ? '#2e7d32' : '#37474f',
                    }}
                  />
                  {adminOpen ? (
                    <ExpandLessIcon sx={{ color: '#78909c', fontSize: '1.4rem' }} />
                  ) : (
                    <ExpandMoreIcon sx={{ color: '#78909c', fontSize: '1.4rem' }} />
                  )}
                </ListItemButton>
              </ListItem>
            </motion.div>

            {/* Admin submenu */}
            <Collapse in={adminOpen} timeout="auto" unmountOnExit>
              <List component="div" disablePadding sx={{ pl: 3 }}>
                {adminItems.map((item) => (
                  <motion.div
                    key={item.text}
                    custom={item.index}
                    initial="initial"
                    animate="animate"
                    variants={listItemAnimation}
                  >
                    <ListItem disablePadding sx={{ mb: 0.6 }}>
                      <motion.div
                        whileHover="hover"
                        variants={menuItemAnimation}
                        style={{ width: '100%' }}
                      >
                        <ListItemButton
                          component={Link}
                          to={item.path}
                          selected={location.pathname === item.path}
                          sx={{
                            borderRadius: 3,
                            backgroundColor: location.pathname === item.path ? 'rgba(46, 125, 50, 0.08)' : 'transparent',
                            '&:hover': {
                              backgroundColor: location.pathname === item.path ? 'rgba(46, 125, 50, 0.12)' : 'rgba(0, 0, 0, 0.04)',
                              boxShadow: location.pathname === item.path ? '0 4px 12px rgba(46, 125, 50, 0.15)' : '0 4px 12px rgba(0, 0, 0, 0.05)',
                            },
                            ...(location.pathname === item.path && {
                              boxShadow: '0 4px 12px rgba(46, 125, 50, 0.12)',
                            }),
                            minHeight: 42,
                            px: 2,
                            py: 0.8,
                            justifyContent: 'initial',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 32,
                              color: location.pathname === item.path ? '#2e7d32' : '#90a4ae',
                              mr: 1,
                            }}
                          >
                            <motion.div
                              whileHover="hover"
                              variants={iconAnimation}
                            >
                              {React.cloneElement(item.icon, {
                                sx: {
                                  fontSize: '1.2rem',
                                }
                              })}
                            </motion.div>
                          </ListItemIcon>
                          <ListItemText
                            primary={item.text}
                            primaryTypographyProps={{
                              fontWeight: location.pathname === item.path ? 600 : 400,
                              fontSize: '0.87rem',
                              color: location.pathname === item.path ? '#2e7d32' : '#546e7a',
                            }}
                          />
                        </ListItemButton>
                      </motion.div>
                    </ListItem>
                  </motion.div>
                ))}
              </List>
            </Collapse>
          </>
        )}
      </List>

      {/* Imagen del render del proyecto (mostrar solo para clientes) */}
      {isClient && userDetails && userDetails.render_photo_path && (
        <Box sx={{ mt: 'auto', mb: 2, px: 2 }}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4, duration: 0.5 }}
          >
            <Paper
              elevation={0}
              sx={{
                overflow: 'hidden',
                borderRadius: 3,
                boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
              }}
            >
              <Box
                component="img"
                src={userDetails.render_photo_path}
                alt="Render del proyecto"
                sx={{
                  width: '100%',
                  height: 'auto',
                  display: 'block',
                  transition: 'transform 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                  }
                }}
              />
            </Paper>
          </motion.div>
        </Box>
      )}

      {/* Change password dialog */}
      <Dialog
        open={changePasswordOpen}
        onClose={handleCloseChangePassword}
        fullWidth
        maxWidth="xs"
        PaperProps={{
          elevation: 24,
          sx: {
            borderRadius: 4,
            px: 1,
            background: 'rgba(255, 255, 255, 0.95)',
            backdropFilter: 'blur(10px)',
            boxShadow: '0 24px 50px rgba(0, 0, 0, 0.2)',
          }
        }}
      >
        <DialogTitle sx={{
          fontSize: '1.3rem',
          fontWeight: 600,
          pt: 3
        }}>
          Cambiar contraseña
        </DialogTitle>
        <DialogContent>
          {error && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Alert
                severity="error"
                sx={{
                  mb: 2,
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(211, 47, 47, 0.1)'
                }}
              >
                {error}
              </Alert>
            </motion.div>
          )}

          {success && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <Alert
                severity="success"
                sx={{
                  mb: 2,
                  borderRadius: 2,
                  boxShadow: '0 4px 12px rgba(76, 175, 80, 0.1)'
                }}
              >
                {success}
              </Alert>
            </motion.div>
          )}

          <TextField
            label="Nueva contraseña"
            type="password"
            fullWidth
            margin="normal"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            disabled={loading || success}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                '&.Mui-focused fieldset': {
                  borderColor: '#2e7d32',
                  borderWidth: '1px'
                }
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#2e7d32'
              }
            }}
          />

          <TextField
            label="Confirmar contraseña"
            type="password"
            fullWidth
            margin="normal"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            disabled={loading || success}
            sx={{
              '& .MuiOutlinedInput-root': {
                borderRadius: 2,
                '&.Mui-focused fieldset': {
                  borderColor: '#2e7d32',
                  borderWidth: '1px'
                }
              },
              '& .MuiInputLabel-root.Mui-focused': {
                color: '#2e7d32'
              }
            }}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button
            onClick={handleCloseChangePassword}
            disabled={loading}
            variant="outlined"
            sx={{
              borderRadius: 2,
              textTransform: 'none',
              px: 3,
              borderColor: '#ccc',
              color: '#555',
              '&:hover': {
                borderColor: '#999',
                backgroundColor: 'rgba(0, 0, 0, 0.03)'
              }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleChangePassword}
            variant="contained"
            color="primary"
            disabled={loading || success}
            sx={{
              borderRadius: 2,
              textTransform: 'none',
              backgroundColor: '#2e7d32',
              px: 3,
              boxShadow: '0 4px 12px rgba(46, 125, 50, 0.2)',
              '&:hover': {
                backgroundColor: '#246428',
                boxShadow: '0 6px 16px rgba(46, 125, 50, 0.3)',
              }
            }}
          >
            {loading ? (
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <CircularProgress size={16} sx={{ mr: 1, color: 'white' }} />
                Guardando...
              </Box>
            ) : 'Guardar'}
          </Button>
        </DialogActions>
      </Dialog>
    </SidebarContainer>
  );
};

export default Sidebar;