import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  Box,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useAuth } from '../../contexts/AuthContext';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import EmailIcon from '@mui/icons-material/Email';
import LockIcon from '@mui/icons-material/Lock';
import KeyboardCapslockIcon from '@mui/icons-material/KeyboardCapslock';
import CopyrightIcon from '@mui/icons-material/Copyright';
import { styled } from '@mui/material/styles';

// Styled components
const PageContainer = styled(Box)({
  height: 'calc(var(--vh) * 100)',
  width: '100vw',
  background: '#FFFFFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
  margin: 0,
  marginTop: '-15vh',
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  overflow: 'hidden',
  boxSizing: 'border-box',
  paddingTop: 'env(safe-area-inset-top)',
});

const ContentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  minWidth: '360px',
  padding: '16px',
  margin: 0,
  position: 'relative',
  zIndex: 5,
});

const LogoContainer = styled(motion.div)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: '40px',
  textAlign: 'center',
});

const InputField = styled(TextField)({
  marginBottom: '24px',
  width: '320px',
  '& .MuiInput-root': {
    '&:before': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.2)',
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '2px solid rgba(0, 0, 0, 0.4)',
    },
    '&:after': {
      borderBottom: '2px solid #000000',
    },
  },
  '& .MuiInputBase-input': {
    padding: '12px 0 8px',
    color: '#000000',
    fontFamily: '"Inter", "Manrope", "Poppins", system-ui, sans-serif',
    fontSize: '1rem',
    fontWeight: 400,
  },
  '& .MuiInputLabel-root': {
    fontFamily: '"Inter", "Manrope", "Poppins", system-ui, sans-serif',
    color: '#666666',
    fontSize: '1rem',
    transform: 'translate(0, -1.5px) scale(1)',
    '&.Mui-focused': {
      color: '#000000',
    },
  },
  '& .MuiSvgIcon-root': {
    color: '#666666',
    fontSize: '1.2rem',
  },
});

const LoginButton = styled(Button)({
  height: 48,
  borderRadius: 8,
  textTransform: 'none',
  fontSize: '0.95rem',
  fontWeight: 600,
  backgroundColor: '#000000',
  color: '#ffffff',
  boxShadow: 'none',
  width: '300px',
  marginTop: '20px',
  marginBottom: '16px',
  fontFamily: '"Inter", "Manrope", "Poppins", system-ui, sans-serif',
  letterSpacing: '0.01em',
  '&:hover': {
    backgroundColor: '#333333',
    boxShadow: 'none',
  },
  '&.Mui-disabled': {
    backgroundColor: '#888888',
    color: '#ffffff'
  }
});

const Footer = styled(Box)({
  position: 'absolute',
  bottom: '24px',
  width: '100%',
  textAlign: 'center',
  color: 'rgba(0, 0, 0, 0.5)',
  zIndex: 5,
  fontFamily: '"Inter", "Manrope", "Poppins", system-ui, sans-serif',
});

const NoScroll = () => {
  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.width = '100%';
    document.body.style.height = '100%';
    document.body.style.backgroundColor = '#FFFFFF';

    return () => {
      document.body.style.overflow = originalStyle;
      document.documentElement.style.overflow = '';
      document.body.style.position = '';
      document.body.style.width = '';
      document.body.style.height = '';
      document.body.style.backgroundColor = '';
    };
  }, []);

  return null;
};

const LoginMobile = () => {
  const { signIn, user, loading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [capsLockOn, setCapsLockOn] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  // Establecer el color de la barra de estado a blanco solo en esta página
  useEffect(() => {
    // Crear o actualizar el meta tag theme-color
    let metaThemeColor = document.querySelector('meta[name="theme-color"]');
    if (!metaThemeColor) {
      metaThemeColor = document.createElement('meta');
      metaThemeColor.setAttribute('name', 'theme-color');
      document.head.appendChild(metaThemeColor);
    }
    metaThemeColor.setAttribute('content', '#FFFFFF');

    return () => {
      // Restaurar el color predeterminado al salir (opcional, ajustado en App.js)
      metaThemeColor.setAttribute('content', '#f5f5f5');
    };
  }, []);

  useEffect(() => {
    document.documentElement.classList.add('login-page');
    document.body.classList.add('login-page');
    document.getElementById('root').classList.add('login-page');

    return () => {
      document.documentElement.classList.remove('login-page');
      document.body.classList.remove('login-page');
      document.getElementById('root').classList.remove('login-page');
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogin(true);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (user && !loading) {
      const from = location.state?.from?.pathname || '/dashboard';
      navigate(from, { replace: true });
    }
  }, [user, loading, navigate, location]);

  const checkCapsLock = (e) => {
    setCapsLockOn(e.getModifierState('CapsLock'));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setIsSubmitting(true);

    if (!email || !password) {
      setError('Por favor complete todos los campos');
      setIsSubmitting(false);
      return;
    }

    const { success, error } = await signIn(email, password);

    if (!success) {
      setError(error.message || 'Error al iniciar sesión');
      setIsSubmitting(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 'calc(var(--vh) * 100)',
          width: '100vw',
          background: '#ffffff',
          position: 'fixed',
          top: 0,
          left: 0,
          overflow: 'hidden'
        }}
      >
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
        >
          <CircularProgress sx={{ color: '#000' }} />
        </motion.div>
      </Box>
    );
  }

  return (
    <>
      <NoScroll />
      <PageContainer>
        <ContentContainer>
          <AnimatePresence mode="wait">
            {!showLogin ? (
              <LogoContainer
                key="splash"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.5 }}
              >
                <motion.img
                  src="/images/logo.png"
                  alt="Logo"
                  style={{
                    width: '140px',
                    height: 'auto'
                  }}
                  initial={{ y: 20, opacity: 0 }}
                  animate={{ y: 0, opacity: 1 }}
                  transition={{ delay: 0.3, duration: 0.8 }}
                />
              </LogoContainer>
            ) : (
              <motion.div
                key="login"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
                style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
              >
                <LogoContainer
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5 }}
                >
                  <motion.img
                    src="/images/logo.png"
                    alt="Logo"
                    style={{
                      width: '100px',
                      height: 'auto',
                      marginBottom: '1rem'
                    }}
                    whileHover={{ scale: 1.05 }}
                    transition={{ duration: 0.3 }}
                  />
                </LogoContainer>

                <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <AnimatePresence>
                    {error && (
                      <motion.div
                        initial={{ opacity: 0, y: -10, height: 0 }}
                        animate={{ opacity: 1, y: 0, height: 'auto' }}
                        exit={{ opacity: 0, y: -10, height: 0 }}
                        transition={{ duration: 0.3 }}
                        style={{ width: '320px', marginBottom: '16px' }}
                      >
                        <Alert
                          severity="error"
                          sx={{
                            borderRadius: 2,
                            backgroundColor: 'rgba(211, 47, 47, 0.08)',
                            color: '#d32f2f',
                            py: 0.5,
                            '& .MuiAlert-icon': {
                              fontSize: '1rem',
                              alignItems: 'center',
                              color: '#d32f2f'
                            },
                            '& .MuiAlert-message': {
                              fontSize: '0.8rem',
                              padding: '4px 0',
                              fontFamily: '"Inter", "Manrope", "Poppins", system-ui, sans-serif',
                            }
                          }}
                        >
                          {error}
                        </Alert>
                      </motion.div>
                    )}
                  </AnimatePresence>

                  <motion.div
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4, delay: 0.1 }}
                    style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                  >
                    <InputField
                      placeholder="Email"
                      type="email"
                      variant="standard"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      disabled={isSubmitting}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EmailIcon fontSize="small" />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </motion.div>

                  <motion.div
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4, delay: 0.2 }}
                    style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
                  >
                    <InputField
                      placeholder="Contraseña"
                      type={showPassword ? 'text' : 'password'}
                      variant="standard"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onKeyDown={checkCapsLock}
                      onKeyUp={checkCapsLock}
                      required
                      disabled={isSubmitting}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <LockIcon fontSize="small" />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                              size="small"
                              sx={{ color: 'rgba(0, 0, 0, 0.5)' }}
                            >
                              {showPassword ? <VisibilityOffIcon fontSize="small" /> : <VisibilityIcon fontSize="small" />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </motion.div>

                  <AnimatePresence>
                    {capsLockOn && (
                      <motion.div
                        initial={{ opacity: 0, y: -5, height: 0 }}
                        animate={{ opacity: 1, y: 0, height: 'auto' }}
                        exit={{ opacity: 0, y: -5, height: 0 }}
                        transition={{ duration: 0.2 }}
                        style={{ width: '320px' }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            mb: 1,
                            color: '#FF9800'
                          }}
                        >
                          <KeyboardCapslockIcon sx={{ mr: 0.5, fontSize: 14 }} />
                          <Typography
                            variant="caption"
                            sx={{
                              fontSize: '0.7rem',
                              fontFamily: '"Inter", "Manrope", "Poppins", system-ui, sans-serif',
                            }}
                          >
                            Bloq Mayús está activado
                          </Typography>
                        </Box>
                      </motion.div>
                    )}
                  </AnimatePresence>

                  <motion.div
                    initial={{ opacity: 0, y: 15 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.4, delay: 0.3 }}
                  >
                    <LoginButton
                      type="submit"
                      disabled={isSubmitting}
                      component={motion.button}
                      whileHover={{ y: -2 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      {isSubmitting ? (
                        <CircularProgress size={20} sx={{ color: '#ffffff' }} />
                      ) : (
                        'Iniciar sesión'
                      )}
                    </LoginButton>
                  </motion.div>
                </form>
              </motion.div>
            )}
          </AnimatePresence>
        </ContentContainer>

        <Footer>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 1, duration: 0.5 }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <CopyrightIcon sx={{ fontSize: '0.7rem', mr: 0.5 }} />
            <Typography
              variant="caption"
              sx={{
                fontSize: '0.75rem',
                fontFamily: '"Inter", "Manrope", "Poppins", system-ui, sans-serif',
              }}
            >
              2025 Nokk. All rights reserved.
            </Typography>
          </motion.div>
        </Footer>
      </PageContainer>
    </>
  );
};

export default LoginMobile;