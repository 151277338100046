// src/pages/admin/TestClientSignup.js
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid
} from '@mui/material';
import { supabase } from '../../supabaseClient';

const TestClientSignup = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [projectCode, setProjectCode] = useState('');
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState('');
  const [error, setError] = useState('');
  const [loadingProjects, setLoadingProjects] = useState(true);

  // Cargar proyectos disponibles
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const { data, error } = await supabase
          .from('proyectos')
          .select('codigo_de_proyecto, apellido_del_cliente');

        if (error) throw error;

        setProjects(data || []);
      } catch (err) {
        console.error("Error al cargar proyectos:", err);
      } finally {
        setLoadingProjects(false);
      }
    };

    fetchProjects();
  }, []);

  const handleCreateClient = async () => {
    // Validaciones
    if (!email || !password || !projectCode) {
      setError('Todos los campos son obligatorios');
      return;
    }

    setLoading(true);
    setError('');
    setSuccess('');

    try {
      // 1. Crear el usuario en Auth
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email,
        password,
      });

      if (authError) throw authError;

      // 2. Verificar que el proyecto existe y actualizar su email_del_cliente
      const { data: projectData, error: projectError } = await supabase
        .from('proyectos')
        .select('codigo_de_proyecto')
        .eq('codigo_de_proyecto', projectCode)
        .single();

      if (projectError) throw new Error(`El proyecto ${projectCode} no existe.`);

      // 3. Actualizar el proyecto con el email del cliente
      const { error: updateError } = await supabase
        .from('proyectos')
        .update({ email_del_cliente: email })
        .eq('codigo_de_proyecto', projectCode);

      if (updateError) throw updateError;

      setSuccess(`¡Usuario cliente creado exitosamente! Email: ${email}, Proyecto: ${projectCode}`);
    } catch (err) {
      console.error("Error al crear cliente:", err);
      setError(err.message || 'Ocurrió un error al crear el usuario cliente');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ p: 3, maxWidth: 800, mx: 'auto' }}>
      <Typography variant="h4" gutterBottom sx={{ mb: 3 }}>
        Crear Usuario Cliente de Prueba
      </Typography>

      <Card sx={{ boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)', borderRadius: 2 }}>
        <CardContent sx={{ p: 3 }}>
          {error && <Alert severity="error" sx={{ mb: 3 }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ mb: 3 }}>{success}</Alert>}

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Email del cliente"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
                type="email"
                required
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Contraseña"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                type="password"
                required
                disabled={loading}
                helperText="Mínimo 6 caracteres"
              />
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth margin="normal">
                <InputLabel id="project-select-label">Proyecto</InputLabel>
                <Select
                  labelId="project-select-label"
                  value={projectCode}
                  onChange={(e) => setProjectCode(e.target.value)}
                  disabled={loading || loadingProjects}
                  label="Proyecto"
                >
                  {loadingProjects ? (
                    <MenuItem disabled value="">
                      Cargando proyectos...
                    </MenuItem>
                  ) : (
                    projects.map((project) => (
                      <MenuItem key={project.codigo_de_proyecto} value={project.codigo_de_proyecto}>
                        {project.codigo_de_proyecto} - {project.apellido_del_cliente}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>
            </Grid>
          </Grid>

          <Box sx={{ mt: 3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCreateClient}
              disabled={loading}
              fullWidth
              sx={{
                py: 1.5,
                borderRadius: 2,
                textTransform: 'none',
                fontSize: '1rem'
              }}
            >
              {loading ? <CircularProgress size={24} /> : 'Crear Usuario Cliente'}
            </Button>
          </Box>

          <Divider sx={{ my: 3 }} />

          <Typography variant="caption" color="text.secondary">
            Nota: Esta herramienta es solo para fines de prueba. Crea un usuario cliente asociado a un proyecto existente.
            Después de la creación, podrás iniciar sesión con las credenciales del usuario para probar la vista de cliente.
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export default TestClientSignup;