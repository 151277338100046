// src/pages/admin/InviteUsers.js
import React, { useState } from 'react';
import {
  Container,
  Typography,
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Alert,
  CircularProgress,
  Tabs,
  Tab,
  Snackbar
} from '@mui/material';
import { supabase } from '../../supabaseClient';

const InviteUsers = () => {
  const [tabValue, setTabValue] = useState(0);
  const [email, setEmail] = useState('');
  const [projectCode, setProjectCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setEmail('');
    setProjectCode('');
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      if (tabValue === 0) {
        // Invitar cliente
        // Verificar que el email existe en la tabla proyectos
        const { data: project, error: projectError } = await supabase
          .from('proyectos')
          .select('email_del_cliente')
          .eq('codigo_de_proyecto', projectCode)
          .eq('email_del_cliente', email)
          .single();

        if (projectError || !project) {
          throw new Error('El email no corresponde al proyecto especificado o el proyecto no existe.');
        }

        // Enviar invitación - Normalmente usaríamos inviteUserByEmail pero requiere permisos admin
        // Como alternativa, podemos crear un nuevo usuario o usar confirmSignUp
        // Para este ejemplo, vamos a simular el envío
        console.log(`Simulando invitación para cliente: ${email}`);

        // En una implementación real, aquí enviarías un email o registrarías al usuario
        // const { error } = await supabase.auth.admin.inviteUserByEmail(email);
        // if (error) throw error;
      } else {
        // Invitar trabajador
        // Verificar que el email existe en la tabla trabajadores_empresa
        const { data: worker, error: workerError } = await supabase
          .from('trabajadores_empresa')
          .select('email')
          .eq('email', email)
          .single();

        if (workerError || !worker) {
          throw new Error('El email no corresponde a ningún trabajador registrado en el sistema.');
        }

        // Simular envío de invitación
        console.log(`Simulando invitación para trabajador: ${email}`);

        // En implementación real:
        // const { error } = await supabase.auth.admin.inviteUserByEmail(email);
        // if (error) throw error;
      }

      // Mostrar mensaje de éxito
      setSuccess(true);
      setEmail('');
      setProjectCode('');
    } catch (error) {
      console.error('Error al enviar invitación:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccess(false);
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ mt: 4, mb: 2 }}>
        <Typography variant="h4" gutterBottom>
          Invitar Usuarios
        </Typography>
        <Typography variant="body1" color="text.secondary" paragraph>
          Envía invitaciones a clientes o trabajadores para que puedan acceder al sistema.
        </Typography>
      </Box>

      <Card>
        <CardContent>
          <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 3 }}>
            <Tab label="Invitar Cliente" />
            <Tab label="Invitar Trabajador" />
          </Tabs>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <TextField
              label="Email"
              type="email"
              fullWidth
              margin="normal"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={loading}
            />

            {tabValue === 0 && (
              <TextField
                label="Código de Proyecto"
                fullWidth
                margin="normal"
                value={projectCode}
                onChange={(e) => setProjectCode(e.target.value)}
                required
                disabled={loading}
              />
            )}

            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{ mt: 3 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Enviar Invitación'}
            </Button>
          </form>
        </CardContent>
      </Card>

      <Snackbar
        open={success}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Invitación enviada correctamente"
      />
    </Container>
  );
};

export default InviteUsers;