import React, { useState, useEffect, useCallback, useRef } from 'react';
import { supabase } from '../supabaseClient';
import axios from 'axios';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
  Container,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  IconButton,
  Paper,
  Chip,
  Tooltip,
  InputAdornment,
  Snackbar,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Collapse,
  Grid,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import DownloadIcon from '@mui/icons-material/Download';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import MoneyIcon from '@mui/icons-material/Money';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import DateRangeIcon from '@mui/icons-material/DateRange';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import { format } from 'date-fns';

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 16,
  overflow: 'hidden',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.12)',
    transition: 'box-shadow 0.3s ease-in-out',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
  },
  '&:hover': {
    backgroundColor: 'rgba(46, 125, 50, 0.04)',
    transition: 'background-color 0.2s ease',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '12px 16px',
  fontSize: '0.875rem',
  textAlign: 'center',
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#f8f9fa',
  padding: '14px 16px',
  fontSize: '0.875rem',
  fontWeight: 'bold',
  color: '#2e7d32',
  borderBottom: '2px solid #e8f5e9',
  textAlign: 'center',
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: 'rgba(46, 125, 50, 0.1)',
  },
}));

const AddArqueoDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
  },
  '& .MuiPaper-root': {
    borderRadius: 16,
    padding: theme.spacing(1),
    width: '550px',
    maxHeight: '88vh',
  },
}));

const FilterChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: '#e8f5e9',
  color: '#2e7d32',
  '&:hover': {
    backgroundColor: '#c8e6c9',
  },
  '& .MuiChip-deleteIcon': {
    color: '#2e7d32',
    '&:hover': {
      color: '#1b5e20',
    },
  },
}));

const ExportButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#2e7d32',
  color: 'white',
  padding: '8px 16px',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: '#1b5e20',
  },
  transition: 'background-color 0.3s ease',
}));

const AddButton = styled(IconButton)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#2e7d32',
  width: 40,
  height: 40,
  boxShadow: '0 4px 10px rgba(46, 125, 50, 0.3)',
  '&:hover': {
    backgroundColor: '#1b5e20',
    boxShadow: '0 6px 15px rgba(46, 125, 50, 0.4)',
  },
  transition: 'all 0.3s ease',
}));

// Estilos modernos para el diálogo de arqueos
const ModernTextField = styled(TextField)(({ theme }) => ({
  marginBottom: 16,
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    '&:hover fieldset': {
      borderColor: '#2e7d32',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2e7d32',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#2e7d32',
  },
}));

const ModernSelect = styled(FormControl)(({ theme }) => ({
  marginBottom: 16,
  '& .MuiOutlinedInput-root': {
    borderRadius: 8,
    '&:hover fieldset': {
      borderColor: '#2e7d32',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#2e7d32',
    },
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#2e7d32',
  },
}));

const ActionSelector = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 12,
  paddingBottom: 12,
  borderBottom: '1px dashed #e0e0e0',
}));

const CurrencySelector = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 12,
}));

const SelectorCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '12px 20px',
  backgroundColor: 'white',
  borderRadius: 12,
  boxShadow: '0 2px 8px rgba(0,0,0,0.05)',
  marginBottom: 16,
}));

const SummaryCard = styled(Box)(({ theme, compact }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: compact ? '8px 12px' : '12px 16px',
  backgroundColor: '#f8fdf9',
  borderRadius: 12,
  border: '1px solid #c8e6c9',
  marginTop: compact ? 8 : 16,
}));

const TitleRow = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: 8,
}));

const SectionIcon = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 28,
  height: 28,
  borderRadius: '50%',
  backgroundColor: '#e8f5e9',
  marginRight: 8,
  '& svg': {
    fontSize: 16,
    color: '#2e7d32',
  },
}));

// Función auxiliar para mantener el foco
const setInputFocus = (ref) => {
  if (ref && ref.current) {
    const length = ref.current.value.length;
    ref.current.focus();
    ref.current.setSelectionRange(length, length);
  }
};

// Componente principal
const Arqueos = () => {
  // Referencias para mantener el foco
  const montoInputRef = useRef(null);
  const tipoCambioInputRef = useRef(null);

  const [arqueos, setArqueos] = useState([]);
  const [filteredArqueos, setFilteredArqueos] = useState([]);
  const [cajas, setCajas] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);

  // Paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Filtros
  const [filters, setFilters] = useState({
    caja: '',
    moneda: '',
    fecha_desde: null,
    fecha_hasta: null,
    tipo: '',
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilters, setActiveFilters] = useState([]);

  const [formData, setFormData] = useState({
    fecha_arqueo: new Date().toISOString().split('T')[0],
    caja_arqueo: '',
    moneda_arqueo: 'ARS',
    monto_arqueo: '',
    tipo_de_cambio_arqueo: 900.0, // Valor por defecto mientras se carga la API
  });
  const [editId, setEditId] = useState(null);
  const [action, setAction] = useState('sumar');

  // Notificación
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Convertir fetchArqueos a useCallback para usarlo como dependencia
  const fetchArqueos = useCallback(async () => {
    const { data, error } = await supabase.from('arqueos_caja').select('*');
    if (error) {
      console.error('Error fetching arqueos:', error);
      return;
    }

    // Formatear fechas para mejor visualización
    const formattedData = data.map(item => ({
      ...item,
      fecha_formateada: item.fecha_arqueo ? format(new Date(item.fecha_arqueo), 'dd/MM/yyyy') : '',
      monto_formateado: formatCurrency(item.monto_arqueo, item.moneda_arqueo)
    }));

    setArqueos(formattedData || []);
    setFilteredArqueos(formattedData || []);
  }, []);

  // Convertir applyFilters a useCallback para usarlo como dependencia
  const applyFilters = useCallback(() => {
    let filtered = [...arqueos];

    // Búsqueda global
    if (searchTerm) {
      filtered = filtered.filter(arqueo =>
        Object.values(arqueo).some(value =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Aplicar filtros activos
    activeFilters.forEach(filter => {
      if (filter.type === 'fecha_desde' && filter.value) {
        filtered = filtered.filter(arqueo =>
          new Date(arqueo.fecha_arqueo) >= new Date(filter.value)
        );
      } else if (filter.type === 'fecha_hasta' && filter.value) {
        filtered = filtered.filter(arqueo =>
          new Date(arqueo.fecha_arqueo) <= new Date(filter.value)
        );
      } else if (filter.type === 'tipo') {
        const isPositive = filter.value === 'positivo';
        filtered = filtered.filter(arqueo =>
          isPositive ? arqueo.monto_arqueo >= 0 : arqueo.monto_arqueo < 0
        );
      } else if (filter.value) {
        const fieldMap = {
          caja: 'caja_arqueo',
          moneda: 'moneda_arqueo',
        };
        const field = fieldMap[filter.type];
        filtered = filtered.filter(arqueo => arqueo[field] === filter.value);
      }
    });

    setFilteredArqueos(filtered);
  }, [arqueos, searchTerm, activeFilters]);

  useEffect(() => {
    fetchArqueos();
    fetchCajas();
    fetchDolarBlue();
  }, [fetchArqueos]); // Añadimos fetchArqueos como dependencia

  useEffect(() => {
    applyFilters();
  }, [applyFilters]); // Añadimos applyFilters como dependencia

  const formatCurrency = (amount, currency) => {
    if (!amount) return '';
    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currency === 'USD' ? 'USD' : 'ARS',
      minimumFractionDigits: 2
    });
    return formatter.format(amount);
  };

  const fetchCajas = async () => {
    const { data, error } = await supabase.from('cajas_cambios').select('caja');
    if (error) console.error('Error fetching cajas:', error);
    else setCajas(data || []);
  };

  const fetchDolarBlue = async () => {
    try {
      const response = await axios.get('https://dolarapi.com/v1/dolares/blue');
      const tipoDeCambio = response.data.compra || 900.0; // Usamos el valor de compra del dólar blue
      setFormData((prev) => ({ ...prev, tipo_de_cambio_arqueo: tipoDeCambio }));
    } catch (error) {
      console.error('Error fetching dolar blue:', error);
      setFormData((prev) => ({ ...prev, tipo_de_cambio_arqueo: 900.0 }));
    }
  };

  const addFilter = () => {
    const newFilters = [];

    if (filters.caja) {
      newFilters.push({ type: 'caja', value: filters.caja, label: `Caja: ${filters.caja}` });
    }

    if (filters.moneda) {
      newFilters.push({ type: 'moneda', value: filters.moneda, label: `Moneda: ${filters.moneda}` });
    }

    if (filters.tipo) {
      newFilters.push({ type: 'tipo', value: filters.tipo, label: `Tipo: ${filters.tipo === 'positivo' ? 'Ingresos' : 'Egresos'}` });
    }

    if (filters.fecha_desde) {
      newFilters.push({ type: 'fecha_desde', value: filters.fecha_desde, label: `Desde: ${format(new Date(filters.fecha_desde), 'dd/MM/yyyy')}` });
    }

    if (filters.fecha_hasta) {
      newFilters.push({ type: 'fecha_hasta', value: filters.fecha_hasta, label: `Hasta: ${format(new Date(filters.fecha_hasta), 'dd/MM/yyyy')}` });
    }

    setActiveFilters([...activeFilters, ...newFilters]);

    // Resetear los filtros
    setFilters({
      caja: '',
      moneda: '',
      fecha_desde: null,
      fecha_hasta: null,
      tipo: '',
    });

    setOpenFilters(false);
  };

  const removeFilter = (index) => {
    const updatedFilters = [...activeFilters];
    updatedFilters.splice(index, 1);
    setActiveFilters(updatedFilters);
  };

  const removeAllFilters = () => {
    setActiveFilters([]);
    setSearchTerm('');
  };

  const exportToCSV = () => {
    // Preparar los datos para exportación
    const csvContent = [
      // Encabezados
      ['ID', 'Fecha', 'Caja', 'Moneda', 'Monto', 'Tipo de Cambio', 'Monto ARS', 'Monto USD'],
      // Datos
      ...filteredArqueos.map(arqueo => [
        arqueo.id,
        arqueo.fecha_formateada,
        arqueo.caja_arqueo,
        arqueo.moneda_arqueo,
        arqueo.monto_arqueo,
        arqueo.tipo_de_cambio_arqueo,
        arqueo.monto_ars_arqueo,
        arqueo.monto_usd_arqueo
      ])
    ].map(row => row.join(',')).join('\n');

    // Crear un blob y descargar
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `arqueos_caja_${format(new Date(), 'yyyyMMdd')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Función para calcular el equivalente en tiempo real 
  // que no afecta el estado y por lo tanto no provoca re-renderizados
  const calcularEquivalente = () => {
    if (!formData.monto_arqueo) return { monto: 0, equivalente: 0 };

    const monto = parseFloat(formData.monto_arqueo) || 0;
    const tipoDeCambio = parseFloat(formData.tipo_de_cambio_arqueo) || 1;

    if (formData.moneda_arqueo === 'ARS') {
      return {
        monto: monto,
        equivalente: monto / tipoDeCambio
      };
    } else {
      return {
        monto: monto,
        equivalente: monto * tipoDeCambio
      };
    }
  };

  // Manejadores de input mejorados para mantener el foco
  const handleMontoChange = (e) => {
    const { value } = e.target;
    // Permitimos solo dígitos y punto decimal
    const cleanValue = value.replace(/[^\d.]/g, '');

    // Nos aseguramos de que solo tenga un punto decimal
    const parts = cleanValue.split('.');
    let finalValue = parts[0] || '';

    if (parts.length > 1) {
      finalValue += '.' + parts.slice(1).join('');
    }

    setFormData(prev => ({
      ...prev,
      monto_arqueo: finalValue
    }));

    // Mantener el foco después de actualizar
    setTimeout(() => setInputFocus(montoInputRef), 0);
  };

  const handleTipoCambioChange = (e) => {
    const { value } = e.target;
    // Permitimos solo dígitos y punto decimal
    const cleanValue = value.replace(/[^\d.]/g, '');

    // Nos aseguramos de que solo tenga un punto decimal
    const parts = cleanValue.split('.');
    let finalValue = parts[0] || '';

    if (parts.length > 1) {
      finalValue += '.' + parts.slice(1).join('');
    }

    setFormData(prev => ({
      ...prev,
      tipo_de_cambio_arqueo: finalValue
    }));

    // Mantener el foco después de actualizar
    setTimeout(() => setInputFocus(tipoCambioInputRef), 0);
  };

  const handleTipoDeCambioIncrement = (increment) => {
    setFormData((prev) => ({
      ...prev,
      tipo_de_cambio_arqueo: Math.max(0, parseFloat(prev.tipo_de_cambio_arqueo || 0) + (increment ? 5 : -5)),
    }));
  };

  const handleAddArqueo = async () => {
    if (!formData.caja_arqueo) {
      setSnackbar({
        open: true,
        message: 'Por favor, selecciona una caja',
        severity: 'error'
      });
      return;
    }

    if (!formData.monto_arqueo || parseFloat(formData.monto_arqueo) <= 0) {
      setSnackbar({
        open: true,
        message: 'Por favor, ingresa un monto válido',
        severity: 'error'
      });
      return;
    }

    const monto = Math.abs(parseFloat(formData.monto_arqueo)) || 0;
    let montoArs = 0.0;
    let montoUsd = 0.0;
    const tipoDeCambio = parseFloat(formData.tipo_de_cambio_arqueo) || 900.0;

    if (formData.moneda_arqueo === 'ARS') {
      montoArs = action === 'restar' ? -monto : monto;
      montoUsd = montoArs / tipoDeCambio;
    } else {
      montoUsd = action === 'restar' ? -monto : monto;
      montoArs = montoUsd * tipoDeCambio;
    }

    const { error } = await supabase.from('arqueos_caja').insert({
      fecha_arqueo: formData.fecha_arqueo,
      caja_arqueo: formData.caja_arqueo,
      moneda_arqueo: formData.moneda_arqueo,
      monto_arqueo: action === 'restar' ? -monto : monto,
      tipo_de_cambio_arqueo: tipoDeCambio,
      monto_ars_arqueo: montoArs,
      monto_usd_arqueo: montoUsd,
    });

    if (error) {
      setSnackbar({
        open: true,
        message: 'Error al agregar arqueo: ' + error.message,
        severity: 'error'
      });
    } else {
      fetchArqueos();
      setOpenAddDialog(false);
      resetForm(tipoDeCambio);
      setSnackbar({
        open: true,
        message: 'Arqueo agregado con éxito',
        severity: 'success'
      });
    }
  };

  const handleEditArqueo = async () => {
    if (!formData.caja_arqueo) {
      setSnackbar({
        open: true,
        message: 'Por favor, selecciona una caja',
        severity: 'error'
      });
      return;
    }

    if (!formData.monto_arqueo || parseFloat(formData.monto_arqueo) <= 0) {
      setSnackbar({
        open: true,
        message: 'Por favor, ingresa un monto válido',
        severity: 'error'
      });
      return;
    }

    const monto = Math.abs(parseFloat(formData.monto_arqueo)) || 0;
    let montoArs = 0.0;
    let montoUsd = 0.0;
    const tipoDeCambio = parseFloat(formData.tipo_de_cambio_arqueo) || 900.0;

    if (formData.moneda_arqueo === 'ARS') {
      montoArs = action === 'restar' ? -monto : monto;
      montoUsd = montoArs / tipoDeCambio;
    } else {
      montoUsd = action === 'restar' ? -monto : monto;
      montoArs = montoUsd * tipoDeCambio;
    }

    const { error } = await supabase.from('arqueos_caja').update({
      fecha_arqueo: formData.fecha_arqueo,
      caja_arqueo: formData.caja_arqueo,
      moneda_arqueo: formData.moneda_arqueo,
      monto_arqueo: action === 'restar' ? -monto : monto,
      tipo_de_cambio_arqueo: tipoDeCambio,
      monto_ars_arqueo: montoArs,
      monto_usd_arqueo: montoUsd,
    }).eq('id', editId);

    if (error) {
      setSnackbar({
        open: true,
        message: 'Error al editar arqueo: ' + error.message,
        severity: 'error'
      });
    } else {
      fetchArqueos();
      setOpenEditDialog(false);
      setEditId(null);
      resetForm(tipoDeCambio);
      setSnackbar({
        open: true,
        message: 'Arqueo actualizado con éxito',
        severity: 'success'
      });
    }
  };

  const handleDeleteArqueo = async (id) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este arqueo?')) {
      const { error } = await supabase.from('arqueos_caja').delete().eq('id', id);
      if (error) {
        setSnackbar({
          open: true,
          message: 'Error al eliminar arqueo: ' + error.message,
          severity: 'error'
        });
      } else {
        fetchArqueos();
        setSnackbar({
          open: true,
          message: 'Arqueo eliminado con éxito',
          severity: 'success'
        });
      }
    }
  };

  const resetForm = (tipoDeCambio) => {
    setFormData({
      fecha_arqueo: new Date().toISOString().split('T')[0],
      caja_arqueo: '',
      moneda_arqueo: 'ARS',
      monto_arqueo: '',
      tipo_de_cambio_arqueo: tipoDeCambio || 900.0,
    });
    setAction('sumar');
  };

  const handleEditClick = (arqueo) => {
    setEditId(arqueo.id);
    setFormData({
      fecha_arqueo: new Date(arqueo.fecha_arqueo).toISOString().split('T')[0],
      caja_arqueo: arqueo.caja_arqueo,
      moneda_arqueo: arqueo.moneda_arqueo,
      monto_arqueo: Math.abs(arqueo.monto_arqueo).toString(),
      tipo_de_cambio_arqueo: arqueo.tipo_de_cambio_arqueo,
    });
    setAction(arqueo.monto_arqueo < 0 ? 'restar' : 'sumar');
    setOpenEditDialog(true);
  };

  // Diálogo de arqueo rediseñado más compacto y moderno
  const ArqueoDialogContent = ({ isEdit }) => {
    const { monto, equivalente } = calcularEquivalente();

    return (
      <>
        <DialogTitle sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          p: 2,
          borderBottom: '1px solid #f0f0f0',
        }}>
          <Typography variant="h6" fontWeight="bold" color="#2e7d32">
            {isEdit ? 'Editar Arqueo' : 'Nuevo Arqueo'}
          </Typography>
          <IconButton
            onClick={() => (isEdit ? setOpenEditDialog(false) : setOpenAddDialog(false))}
            sx={{ color: '#d32f2f' }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent sx={{ p: 2 }}>
          <Box sx={{ mb: 2 }}>
            <TitleRow>
              <SectionIcon>
                <DateRangeIcon />
              </SectionIcon>
              <Typography variant="subtitle2" fontWeight="bold" color="#424242">
                Información Básica
              </Typography>
            </TitleRow>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <ModernTextField
                  label="Fecha"
                  type="date"
                  value={formData.fecha_arqueo}
                  onChange={(e) => setFormData({ ...formData, fecha_arqueo: e.target.value })}
                  fullWidth
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  margin="dense"
                />
              </Grid>
              <Grid item xs={6}>
                <ModernSelect fullWidth size="small" margin="dense">
                  <InputLabel>Caja</InputLabel>
                  <Select
                    value={formData.caja_arqueo}
                    onChange={(e) => setFormData({ ...formData, caja_arqueo: e.target.value })}
                    label="Caja"
                  >
                    <MenuItem value="" disabled>Seleccionar caja</MenuItem>
                    {cajas.map((caja) => (
                      <MenuItem key={caja.caja} value={caja.caja}>
                        {caja.caja}
                      </MenuItem>
                    ))}
                  </Select>
                </ModernSelect>
              </Grid>
            </Grid>
          </Box>

          <Box sx={{ mb: 2 }}>
            <TitleRow>
              <SectionIcon>
                <MoneyIcon />
              </SectionIcon>
              <Typography variant="subtitle2" fontWeight="bold" color="#424242">
                Detalles del Arqueo
              </Typography>
            </TitleRow>

            {/* Selector de Acción */}
            <ActionSelector>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                <Chip
                  label="Egreso"
                  variant={action === 'restar' ? 'filled' : 'outlined'}
                  color={action === 'restar' ? 'error' : 'default'}
                  onClick={() => setAction('restar')}
                  icon={<TrendingDownIcon />}
                  sx={{
                    borderRadius: 4,
                    padding: '0 4px',
                    fontSize: '0.8rem',
                    fontWeight: 'medium',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease'
                  }}
                />

                <Switch
                  checked={action === 'sumar'}
                  onChange={(e) => setAction(e.target.checked ? 'sumar' : 'restar')}
                  sx={{
                    '& .MuiSwitch-thumb': {
                      backgroundColor: action === 'sumar' ? '#2e7d32' : '#d32f2f'
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: action === 'sumar' ? '#a5d6a7' : '#ffcdd2'
                    },
                  }}
                />

                <Chip
                  label="Ingreso"
                  variant={action === 'sumar' ? 'filled' : 'outlined'}
                  color={action === 'sumar' ? 'success' : 'default'}
                  onClick={() => setAction('sumar')}
                  icon={<TrendingUpIcon />}
                  sx={{
                    borderRadius: 4,
                    padding: '0 4px',
                    fontSize: '0.8rem',
                    fontWeight: 'medium',
                    cursor: 'pointer',
                    transition: 'all 0.2s ease'
                  }}
                />
              </Box>
            </ActionSelector>

            {/* Selector de Moneda */}
            <CurrencySelector>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: 1.5 }}>
                <Chip
                  label="ARS"
                  variant={formData.moneda_arqueo === 'ARS' ? 'filled' : 'outlined'}
                  color={formData.moneda_arqueo === 'ARS' ? 'primary' : 'default'}
                  onClick={() => setFormData({ ...formData, moneda_arqueo: 'ARS' })}
                  sx={{
                    borderRadius: 4,
                    padding: '0 4px',
                    fontSize: '0.8rem',
                    fontWeight: 'medium',
                    cursor: 'pointer',
                    backgroundColor: formData.moneda_arqueo === 'ARS' ? '#e3f2fd' : 'transparent',
                    color: formData.moneda_arqueo === 'ARS' ? '#1565c0' : '#757575',
                    borderColor: formData.moneda_arqueo === 'ARS' ? '#90caf9' : '#e0e0e0',
                    transition: 'all 0.2s ease'
                  }}
                />

                <Switch
                  checked={formData.moneda_arqueo === 'USD'}
                  onChange={(e) => setFormData({ ...formData, moneda_arqueo: e.target.checked ? 'USD' : 'ARS' })}
                  sx={{
                    '& .MuiSwitch-thumb': {
                      backgroundColor: formData.moneda_arqueo === 'USD' ? '#2e7d32' : '#757575'
                    },
                    '& .MuiSwitch-track': {
                      backgroundColor: formData.moneda_arqueo === 'USD' ? '#c8e6c9' : '#e0e0e0'
                    },
                  }}
                />

                <Chip
                  label="USD"
                  variant={formData.moneda_arqueo === 'USD' ? 'filled' : 'outlined'}
                  color={formData.moneda_arqueo === 'USD' ? 'success' : 'default'}
                  onClick={() => setFormData({ ...formData, moneda_arqueo: 'USD' })}
                  sx={{
                    borderRadius: 4,
                    padding: '0 4px',
                    fontSize: '0.8rem',
                    fontWeight: 'medium',
                    cursor: 'pointer',
                    backgroundColor: formData.moneda_arqueo === 'USD' ? '#e8f5e9' : 'transparent',
                    color: formData.moneda_arqueo === 'USD' ? '#2e7d32' : '#757575',
                    borderColor: formData.moneda_arqueo === 'USD' ? '#a5d6a7' : '#e0e0e0',
                    transition: 'all 0.2s ease'
                  }}
                />
              </Box>
            </CurrencySelector>

            {/* Campos de entrada con referencias para mantener el foco */}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ModernTextField
                  label="Monto"
                  value={formData.monto_arqueo}
                  onChange={handleMontoChange}
                  fullWidth
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoneyIcon sx={{ color: formData.moneda_arqueo === 'USD' ? '#2e7d32' : '#1976d2' }} />
                      </InputAdornment>
                    ),
                    inputRef: montoInputRef, // Referencia para mantener el foco
                  }}
                  margin="dense"
                />
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                  <ModernTextField
                    label="Tipo de Cambio"
                    value={formData.tipo_de_cambio_arqueo}
                    onChange={handleTipoCambioChange}
                    fullWidth
                    size="small"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      ),
                      inputRef: tipoCambioInputRef, // Referencia para mantener el foco
                    }}
                    margin="dense"
                  />
                  <Tooltip title="Aumentar $5">
                    <Button
                      onClick={() => handleTipoDeCambioIncrement(true)}
                      variant="outlined"
                      size="small"
                      sx={{
                        minWidth: '32px',
                        height: '32px',
                        color: '#2e7d32',
                        borderColor: '#2e7d32',
                        borderRadius: 2,
                        p: 0
                      }}
                    >
                      +5
                    </Button>
                  </Tooltip>
                  <Tooltip title="Disminuir $5">
                    <Button
                      onClick={() => handleTipoDeCambioIncrement(false)}
                      variant="outlined"
                      size="small"
                      sx={{
                        minWidth: '32px',
                        height: '32px',
                        color: '#d32f2f',
                        borderColor: '#d32f2f',
                        borderRadius: 2,
                        p: 0
                      }}
                    >
                      -5
                    </Button>
                  </Tooltip>
                </Box>
              </Grid>
            </Grid>
          </Box>

          {/* Resumen dinámico */}
          {parseFloat(formData.monto_arqueo) > 0 && (
            <Box sx={{ mb: 1 }}>
              <TitleRow>
                <SectionIcon>
                  <CompareArrowsIcon />
                </SectionIcon>
                <Typography variant="subtitle2" fontWeight="bold" color="#424242">
                  Resumen
                </Typography>
              </TitleRow>

              <SummaryCard compact>
                <Grid container spacing={1}>
                  <Grid item xs={5}>
                    <Typography variant="caption" fontWeight="medium" color="#666">
                      Operación:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography
                      variant="caption"
                      color={action === 'sumar' ? '#2e7d32' : '#d32f2f'}
                      fontWeight="medium"
                    >
                      {action === 'sumar' ? 'Ingreso' : 'Egreso'}
                    </Typography>
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant="caption" fontWeight="medium" color="#666">
                      Monto {formData.moneda_arqueo}:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="caption" fontWeight="medium">
                      ${monto.toFixed(2)}
                    </Typography>
                  </Grid>

                  <Grid item xs={5}>
                    <Typography variant="caption" fontWeight="medium" color="#666">
                      Equivalente {formData.moneda_arqueo === 'ARS' ? 'USD' : 'ARS'}:
                    </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <Typography variant="caption" fontWeight="medium">
                      ${equivalente.toFixed(2)}
                    </Typography>
                  </Grid>
                </Grid>
              </SummaryCard>
            </Box>
          )}
        </DialogContent>

        <DialogActions sx={{ p: 2, borderTop: '1px solid #f0f0f0', justifyContent: 'center' }}>
          <Button
            variant="contained"
            onClick={isEdit ? handleEditArqueo : handleAddArqueo}
            sx={{
              borderRadius: 8,
              bgcolor: '#2e7d32',
              '&:hover': { bgcolor: '#1b5e20' },
              px: 3,
              py: 0.75,
              fontWeight: 'medium'
            }}
          >
            {isEdit ? 'Actualizar' : 'Guardar'}
          </Button>
        </DialogActions>
      </>
    );
  };

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" fontWeight="bold" color="#2e7d32">
              Arqueos
            </Typography>
            <Tooltip title="Agregar nuevo arqueo">
              <AddButton
                onClick={() => setOpenAddDialog(true)}
                sx={{ ml: 2 }}
              >
                <AddIcon />
              </AddButton>
            </Tooltip>
          </Box>
          <Tooltip title="Exportar a CSV">
            <ExportButton
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={exportToCSV}
            >
              Exportar
            </ExportButton>
          </Tooltip>
        </Box>

        {/* Barra de búsqueda y filtros */}
        <StyledPaper elevation={0} sx={{ mb: 3, p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
            <TextField
              placeholder="Buscar arqueos..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                flex: 1,
                minWidth: 250,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8,
                  '&:hover fieldset': {
                    borderColor: '#2e7d32',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#2e7d32' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<FilterListIcon />}
              onClick={() => setOpenFilters(!openFilters)}
              sx={{
                borderRadius: 8,
                borderColor: '#2e7d32',
                color: '#2e7d32',
                '&:hover': {
                  borderColor: '#1b5e20',
                  backgroundColor: 'rgba(46, 125, 50, 0.04)',
                },
              }}
            >
              Filtros
            </Button>
            {activeFilters.length > 0 && (
              <Button
                variant="text"
                size="small"
                onClick={removeAllFilters}
                sx={{
                  color: '#2e7d32',
                  '&:hover': { backgroundColor: 'rgba(46, 125, 50, 0.04)' },
                }}
              >
                Limpiar filtros
              </Button>
            )}
          </Box>

          <Collapse in={openFilters}>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Caja</InputLabel>
                    <Select
                      value={filters.caja}
                      onChange={(e) => setFilters({ ...filters, caja: e.target.value })}
                      label="Caja"
                    >
                      <MenuItem value="">Todas</MenuItem>
                      {cajas.map((caja) => (
                        <MenuItem key={caja.caja} value={caja.caja}>{caja.caja}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Moneda</InputLabel>
                    <Select
                      value={filters.moneda}
                      onChange={(e) => setFilters({ ...filters, moneda: e.target.value })}
                      label="Moneda"
                    >
                      <MenuItem value="">Todas</MenuItem>
                      <MenuItem value="ARS">ARS</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Tipo</InputLabel>
                    <Select
                      value={filters.tipo}
                      onChange={(e) => setFilters({ ...filters, tipo: e.target.value })}
                      label="Tipo"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      <MenuItem value="positivo">Ingresos</MenuItem>
                      <MenuItem value="negativo">Egresos</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={addFilter}
                    sx={{
                      height: '40px',
                      borderRadius: 8,
                      backgroundColor: '#2e7d32',
                      '&:hover': { backgroundColor: '#1b5e20' },
                    }}
                  >
                    Aplicar Filtros
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Desde"
                    type="date"
                    value={filters.fecha_desde || ''}
                    onChange={(e) => setFilters({ ...filters, fecha_desde: e.target.value })}
                    fullWidth
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Hasta"
                    type="date"
                    value={filters.fecha_hasta || ''}
                    onChange={(e) => setFilters({ ...filters, fecha_hasta: e.target.value })}
                    fullWidth
                    size="small"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Collapse>

          {/* Chips de filtros activos */}
          {activeFilters.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', pt: 2 }}>
              {activeFilters.map((filter, index) => (
                <FilterChip
                  key={index}
                  label={filter.label}
                  onDelete={() => removeFilter(index)}
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
            </Box>
          )}
        </StyledPaper>

        {/* Tabla de arqueos */}
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <StyledPaper elevation={0}>
              <TableContainer sx={{ maxHeight: 'calc(100vh - 250px)' }}>
                <Table stickyHeader aria-label="tabla de arqueos">
                  <TableHead>
                    <TableRow>
                      <StyledTableHeaderCell>Fecha</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Caja</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Operación</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Moneda</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Monto</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Tipo Cambio</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Equivalente ARS/USD</StyledTableHeaderCell>
                      <StyledTableHeaderCell align="center">Acciones</StyledTableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredArqueos
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((arqueo) => (
                        <StyledTableRow key={arqueo.id}>
                          <StyledTableCell>
                            {arqueo.fecha_formateada}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                              <AccountBalanceWalletIcon fontSize="small" sx={{ color: '#1976d2' }} />
                              <Typography>{arqueo.caja_arqueo}</Typography>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            {arqueo.monto_arqueo >= 0 ? (
                              <Chip
                                icon={<TrendingUpIcon />}
                                label="Ingreso"
                                size="small"
                                sx={{ backgroundColor: '#e8f5e9', color: '#2e7d32' }}
                              />
                            ) : (
                              <Chip
                                icon={<TrendingDownIcon />}
                                label="Egreso"
                                size="small"
                                sx={{ backgroundColor: '#ffebee', color: '#d32f2f' }}
                              />
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Chip
                              label={arqueo.moneda_arqueo}
                              size="small"
                              sx={{
                                backgroundColor: arqueo.moneda_arqueo === 'USD' ? '#e3f2fd' : '#f3e5f5',
                                color: arqueo.moneda_arqueo === 'USD' ? '#1565c0' : '#7b1fa2',
                                fontWeight: 'medium'
                              }}
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Typography
                              sx={{
                                color: arqueo.monto_arqueo >= 0 ? '#2e7d32' : '#d32f2f',
                                fontWeight: 'bold'
                              }}
                            >
                              {arqueo.monto_formateado}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            {arqueo.tipo_de_cambio_arqueo.toFixed(2)}
                          </StyledTableCell>
                          <StyledTableCell>
                            {arqueo.moneda_arqueo === 'ARS' ? (
                              <Typography variant="body2" color="text.secondary">
                                USD {arqueo.monto_usd_arqueo.toFixed(2)}
                              </Typography>
                            ) : (
                              <Typography variant="body2" color="text.secondary">
                                ARS {arqueo.monto_ars_arqueo.toFixed(2)}
                              </Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <Tooltip title="Editar">
                                <ActionButton
                                  size="small"
                                  onClick={() => handleEditClick(arqueo)}
                                >
                                  <EditIcon fontSize="small" />
                                </ActionButton>
                              </Tooltip>
                              <Tooltip title="Eliminar">
                                <ActionButton
                                  size="small"
                                  onClick={() => handleDeleteArqueo(arqueo.id)}
                                  sx={{ color: '#d32f2f' }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </ActionButton>
                              </Tooltip>
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={filteredArqueos.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página:"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                sx={{
                  borderTop: '1px solid rgba(224, 224, 224, 1)',
                  '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                    color: '#424242'
                  },
                  '.MuiTablePagination-select': {
                    color: '#2e7d32'
                  }
                }}
              />
            </StyledPaper>
          </motion.div>
        </AnimatePresence>
      </motion.div>

      {/* Diálogos para agregar y editar arqueos */}
      <AddArqueoDialog
        open={openAddDialog}
        onClose={() => setOpenAddDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0,0,0,0.12)',
            overflow: 'hidden',
            maxHeight: '90vh',
          }
        }}
      >
        <ArqueoDialogContent isEdit={false} />
      </AddArqueoDialog>

      <AddArqueoDialog
        open={openEditDialog}
        onClose={() => setOpenEditDialog(false)}
        PaperProps={{
          sx: {
            borderRadius: 3,
            boxShadow: '0 8px 32px rgba(0,0,0,0.12)',
            overflow: 'hidden',
            maxHeight: '90vh',
          }
        }}
      >
        <ArqueoDialogContent isEdit={true} />
      </AddArqueoDialog>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            borderRadius: 2,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default Arqueos;