// src/components/Dashboard.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  Container,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Switch,
  TextField,
  Divider,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import CloseIcon from '@mui/icons-material/Close';

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 12,
  boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
  transition: 'transform 0.3s ease-in-out',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 6px 25px rgba(0, 0, 0, 0.1)',
  },
}));

const TotalCard = styled(Card)(({ theme }) => ({
  borderRadius: 12,
  background: '#fff',
  padding: theme.spacing(2),
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.05)',
}));

const CashCounterDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiPaper-root': {
    borderRadius: 16,
    padding: theme.spacing(2),
    width: '550px',
    maxHeight: '80vh',
    overflow: 'hidden',
  },
}));

const CashCounterButton = styled(Button)(({ theme }) => ({
  borderRadius: '50%',
  minWidth: 0,
  width: 48,
  height: 48,
  padding: 0,
  backgroundColor: '#4caf50',
  '&:hover': {
    backgroundColor: '#2e7d32',
    boxShadow: '0 4px 12px rgba(46, 125, 50, 0.3)',
  },
  '&:active': {
    backgroundColor: '#388e3c',
    boxShadow: '0 2px 8px rgba(56, 142, 60, 0.4)',
  },
}));

const Dashboard = () => {
  const [saldos, setSaldos] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [openCashCounter, setOpenCashCounter] = useState(false);
  const [currency, setCurrency] = useState('ARS');
  const [arsBills, setArsBills] = useState({ 200: '', 500: '', 1000: '', 2000: '', 10000: '', 20000: '' });
  const [usdBills, setUsdBills] = useState({ 1: '', 5: '', 10: '', 20: '', 50: '', 100: '' });

  useEffect(() => {
    fetchSaldos();
    fetchProyectos();
  }, []);

  const fetchSaldos = async () => {
    // Usar la nueva vista caja_saldos_vista
    const { data, error } = await supabase.from('caja_saldos_vista').select('*');
    if (error) console.error('Error fetching saldos:', error);
    else {
      setSaldos(data || []);
    }
  };

  const fetchProyectos = async () => {
    const { data, error } = await supabase
      .from('proyectos')
      .select('apellido_del_cliente, caja_proyecto, caja_proyecto_mo, mostrar_cajas')
      .eq('mostrar_cajas', 'true'); // Solo proyectos activos
    if (error) console.error('Error fetching proyectos:', error);
    else setProyectos(data || []);
  };

  const totalARS = saldos.reduce((sum, saldo) => sum + (saldo.saldo_ars || 0), 0);
  const totalUSD = saldos.reduce((sum, saldo) => sum + (saldo.saldo_usd || 0), 0);

  const groupedSaldos = proyectos.reduce((acc, proyecto) => {
    const apellido = proyecto.apellido_del_cliente;
    if (!acc[apellido]) acc[apellido] = [];
    const cajasRelacionadas = saldos.filter(
      (saldo) => saldo.caja_ars === proyecto.caja_proyecto || saldo.caja_ars === proyecto.caja_proyecto_mo
    );
    if (cajasRelacionadas.length > 0) {
      acc[apellido].push(...cajasRelacionadas);
    }
    return acc;
  }, {});

  const allProjectCajas = proyectos.flatMap((p) => [p.caja_proyecto, p.caja_proyecto_mo]).filter(Boolean);
  const cajasSinProyecto = saldos.filter((saldo) => !allProjectCajas.includes(saldo.caja_ars));

  const calculateCashTotal = () => {
    if (currency === 'ARS') {
      return (
        (parseInt(arsBills[200]) || 0) * 200 +
        (parseInt(arsBills[500]) || 0) * 500 +
        (parseInt(arsBills[1000]) || 0) * 1000 +
        (parseInt(arsBills[2000]) || 0) * 2000 +
        (parseInt(arsBills[10000]) || 0) * 10000 +
        (parseInt(arsBills[20000]) || 0) * 20000
      );
    } else {
      return (
        (parseInt(usdBills[1]) || 0) * 1 +
        (parseInt(usdBills[5]) || 0) * 5 +
        (parseInt(usdBills[10]) || 0) * 10 +
        (parseInt(usdBills[20]) || 0) * 20 +
        (parseInt(usdBills[50]) || 0) * 50 +
        (parseInt(usdBills[100]) || 0) * 100
      );
    }
  };

  const cashTotal = calculateCashTotal();
  const difference = currency === 'ARS' ? cashTotal - totalARS : cashTotal - totalUSD;
  const isCashZero = currency === 'ARS'
    ? Object.values(arsBills).every(val => val === '' || parseInt(val) === 0)
    : Object.values(usdBills).every(val => val === '' || parseInt(val) === 0);

  const handleSaveCount = async () => {
    const { error } = await supabase.from('conteos_efectivo').insert([
      {
        monto_conteo: cashTotal,
        moneda_conteo: currency,
        fecha_conteo: new Date().toISOString(),
        total_esperado: currency === 'ARS' ? totalARS : totalUSD,
        diferencia: difference,
      },
    ]);
    if (error) console.error('Error saving conteo:', error);
    else {
      setArsBills({ 200: '', 500: '', 1000: '', 2000: '', 10000: '', 20000: '' });
      setUsdBills({ 1: '', 5: '', 10: '', 20: '', 50: '', 100: '' });
      setOpenCashCounter(false);
    }
  };

  const handleCloseCashCounter = () => {
    setOpenCashCounter(false);
    setArsBills({ 200: '', 500: '', 1000: '', 2000: '', 10000: '', 20000: '' });
    setUsdBills({ 1: '', 5: '', 10: '', 20: '', 50: '', 100: '' });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 3, px: 0 }}>
      <Box sx={{ mb: 3, ml: 0 }}>
        <Typography variant="h4" fontWeight="bold" color="text.primary">
          Dashboard
        </Typography>
        <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 1 }}>
          Resumen de Saldos por Caja
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 3, ml: 0 }}>
        <TotalCard>
          <CardContent sx={{ display: 'flex', gap: 2 }}>
            <Box>
              <Typography variant="body2" color="text.secondary">
                Total ARS
              </Typography>
              <Typography variant="h5" fontWeight="bold" color="primary.main">
                ${totalARS.toLocaleString()}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body2" color="text.secondary">
                Total USD
              </Typography>
              <Typography variant="h5" fontWeight="bold" color="primary.main">
                ${totalUSD.toLocaleString()}
              </Typography>
            </Box>
          </CardContent>
        </TotalCard>
        <motion.div
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.3 }}
        >
          <CashCounterButton
            variant="contained"
            color="primary"
            onClick={() => setOpenCashCounter(true)}
            aria-label="Contador de Efectivo"
          >
            <PointOfSaleIcon sx={{ fontSize: '1.5rem', color: '#fff' }} />
          </CashCounterButton>
        </motion.div>
      </Box>

      {Object.entries(groupedSaldos).map(([apellido, cajas]) => (
        cajas.length > 0 && (
          <Box key={apellido} sx={{ mb: 3, ml: 0 }}>
            <Typography variant="h6" fontWeight="medium" color="text.primary" gutterBottom>
              {apellido}
            </Typography>
            <Grid container spacing={2}>
              {cajas.map((saldo, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <StyledCard>
                    <CardContent>
                      <Typography variant="subtitle1" fontWeight="medium" color="text.primary" gutterBottom>
                        {saldo.caja_ars}
                      </Typography>
                      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            Saldo ARS
                          </Typography>
                          <Typography variant="h6" fontWeight="bold" color="primary.main">
                            ${saldo.saldo_ars?.toLocaleString() || '0'}
                          </Typography>
                        </Box>
                        <Box>
                          <Typography variant="body2" color="text.secondary">
                            Saldo USD
                          </Typography>
                          <Typography variant="h6" fontWeight="bold" color="primary.main">
                            ${saldo.saldo_usd?.toLocaleString() || '0'}
                          </Typography>
                        </Box>
                      </Box>
                    </CardContent>
                  </StyledCard>
                </Grid>
              ))}
            </Grid>
          </Box>
        )
      ))}

      {cajasSinProyecto.length > 0 && (
        <Box sx={{ mb: 3, ml: 0 }}>
          <Typography variant="h6" fontWeight="medium" color="text.primary" gutterBottom>
            Cajas sin Proyecto
          </Typography>
          <Grid container spacing={2}>
            {cajasSinProyecto.map((saldo, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <StyledCard>
                  <CardContent>
                    <Typography variant="subtitle1" fontWeight="medium" color="text.primary" gutterBottom>
                      {saldo.caja_ars}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Box>
                        <Typography variant="body2" color="text.secondary">
                          Saldo ARS
                        </Typography>
                        <Typography variant="h6" fontWeight="bold" color="primary.main">
                          ${saldo.saldo_ars?.toLocaleString() || '0'}
                        </Typography>
                      </Box>
                      <Box>
                        <Typography variant="body2" color="text.secondary">
                          Saldo USD
                        </Typography>
                        <Typography variant="h6" fontWeight="bold" color="primary.main">
                          ${saldo.saldo_usd?.toLocaleString() || '0'}
                        </Typography>
                      </Box>
                    </Box>
                  </CardContent>
                </StyledCard>
              </Grid>
            ))}
          </Grid>
        </Box>
      )}

      <CashCounterDialog open={openCashCounter} onClose={handleCloseCashCounter}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="h5" fontWeight="bold" color="text.primary" textAlign="center">
            Contador de Efectivo
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleCloseCashCounter}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ textAlign: 'center' }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 1 }}>
              <Typography variant="body1" sx={{ mr: 2, color: currency === 'ARS' ? 'primary.main' : 'text.secondary' }}>
                ARS
              </Typography>
              <motion.div
                initial={{ scale: 1 }}
                animate={{ scale: currency === 'USD' ? 1.1 : 1 }}
                transition={{ duration: 0.3 }}
              >
                <Switch
                  checked={currency === 'USD'}
                  onChange={(e) => setCurrency(e.target.checked ? 'USD' : 'ARS')}
                  color="primary"
                />
              </motion.div>
              <Typography variant="body1" sx={{ ml: 2, color: currency === 'USD' ? 'primary.main' : 'text.secondary' }}>
                USD
              </Typography>
            </Box>
            <Typography variant="body2" fontWeight="medium" sx={{ mb: 0.5 }}>
              Esperado: ${currency === 'ARS' ? totalARS.toLocaleString() : totalUSD.toLocaleString()} {currency}
            </Typography>
            <Typography variant="body2" fontWeight="medium" sx={{ mb: 0.5 }}>
              Contado: ${cashTotal.toLocaleString()} {currency}
            </Typography>
            {!isCashZero && (
              <Typography
                variant="body2"
                fontWeight="bold"
                sx={{ color: difference >= 0 ? 'green' : 'red' }}
              >
                Diferencia: {difference >= 0 ? '+' : ''}${difference.toLocaleString()} {currency}
              </Typography>
            )}
          </Box>
          <Divider sx={{ my: 1 }} />
          <Grid container spacing={1}>
            {currency === 'ARS' ? (
              <>
                {[[200, 500], [1000, 2000], [10000, 20000]].map((pair, index) => (
                  <Grid container item xs={12} key={index} spacing={1}>
                    {pair.map((denomination) => (
                      <Grid item xs={6} key={denomination}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <TextField
                            label={`${denomination} ARS`}
                            type="number"
                            value={arsBills[denomination]}
                            onChange={(e) =>
                              setArsBills({
                                ...arsBills,
                                [denomination]: e.target.value === '' ? '' : Math.max(0, parseInt(e.target.value)),
                              })
                            }
                            inputProps={{ min: 0 }}
                            sx={{ width: '100px' }}
                          />
                          <Typography
                            variant="body2"
                            color="primary.main"
                            fontWeight="medium"
                            sx={{ ml: 1 }}
                          >
                            ${((parseInt(arsBills[denomination]) || 0) * denomination).toLocaleString()} ARS
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </>
            ) : (
              <>
                {[[1, 5], [10, 20], [50, 100]].map((pair, index) => (
                  <Grid container item xs={12} key={index} spacing={1}>
                    {pair.map((denomination) => (
                      <Grid item xs={6} key={denomination}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <TextField
                            label={`${denomination} USD`}
                            type="number"
                            value={usdBills[denomination]}
                            onChange={(e) =>
                              setUsdBills({
                                ...usdBills,
                                [denomination]: e.target.value === '' ? '' : Math.max(0, parseInt(e.target.value)),
                              })
                            }
                            inputProps={{ min: 0 }}
                            sx={{ width: '100px' }}
                          />
                          <Typography
                            variant="body2"
                            color="primary.main"
                            fontWeight="medium"
                            sx={{ ml: 1 }}
                          >
                            ${((parseInt(usdBills[denomination]) || 0) * denomination).toLocaleString()} USD
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                ))}
              </>
            )}
          </Grid>
          <Box sx={{ mt: 2, textAlign: 'center' }}>
            <motion.div whileTap={{ scale: 0.95 }} transition={{ duration: 0.2 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSaveCount}
                sx={{ borderRadius: 2, px: 4, py: 1 }}
              >
                Guardar Conteo
              </Button>
            </motion.div>
          </Box>
        </DialogContent>
      </CashCounterDialog>
    </Container>
  );
};

export default Dashboard;