import React, { useEffect, useState, useRef } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Grid,
  Typography,
  IconButton,
  Container,
  Paper,
  Tooltip,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  InputAdornment,
  Collapse,
  Alert,
  Snackbar,
  CircularProgress,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import DownloadIcon from '@mui/icons-material/Download';
import MoneyIcon from '@mui/icons-material/Money';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CloseIcon from '@mui/icons-material/Close';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { styled } from '@mui/material/styles';
import { format } from 'date-fns';
import { motion, AnimatePresence } from 'framer-motion';
import { v4 as uuidv4 } from 'uuid';

const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 16,
  overflow: 'hidden',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.12)',
    transition: 'box-shadow 0.3s ease-in-out',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
  },
  '&:hover': {
    backgroundColor: 'rgba(46, 125, 50, 0.04)',
    transition: 'background-color 0.2s ease',
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '12px 16px',
  fontSize: '0.875rem',
  textAlign: 'center',
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#f8f9fa',
  padding: '14px 16px',
  fontSize: '0.875rem',
  fontWeight: 'bold',
  color: '#2e7d32',
  borderBottom: '2px solid #e8f5e9',
  textAlign: 'center',
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: 'rgba(46, 125, 50, 0.1)',
  },
}));

const AddChangeDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiPaper-root': {
    borderRadius: 16,
    padding: theme.spacing(2),
    width: '600px',
    maxHeight: '80vh',
    overflowY: 'auto',
  },
}));

const ImagePreviewDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
  '& .MuiPaper-root': {
    borderRadius: 8,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
}));

const FilterChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: '#e8f5e9',
  color: '#2e7d32',
  '&:hover': {
    backgroundColor: '#c8e6c9',
  },
  '& .MuiChip-deleteIcon': {
    color: '#2e7d32',
    '&:hover': {
      color: '#1b5e20',
    },
  },
}));

const ExportButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#2e7d32',
  color: 'white',
  padding: '8px 16px',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: '#1b5e20',
  },
  transition: 'background-color 0.3s ease',
}));

const AddButton = styled(IconButton)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#2e7d32',
  width: 40,
  height: 40,
  boxShadow: '0 4px 10px rgba(46, 125, 50, 0.3)',
  '&:hover': {
    backgroundColor: '#1b5e20',
    boxShadow: '0 6px 15px rgba(46, 125, 50, 0.4)',
  },
  transition: 'all 0.3s ease',
}));

const CurrencyChip = styled(Chip)(({ theme, currency }) => ({
  backgroundColor: currency === 'USD' ? '#e3f2fd' : '#fff3e0',
  color: currency === 'USD' ? '#1976d2' : '#ed6c02',
  fontWeight: 'bold',
  '& .MuiChip-icon': {
    color: currency === 'USD' ? '#1976d2' : '#ed6c02',
  },
}));

const ExchangeIcon = styled(CompareArrowsIcon)(({ theme }) => ({
  color: '#2e7d32',
  fontSize: '1.25rem',
  animation: '$pulse 1.5s infinite ease-in-out',
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
    },
    '50%': {
      transform: 'scale(1.2)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
}));

const FileUploadBox = styled(Box)(({ theme }) => ({
  border: '2px dashed #c8e6c9',
  borderRadius: 8,
  padding: theme.spacing(3),
  textAlign: 'center',
  backgroundColor: '#f1f8e9',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    borderColor: '#2e7d32',
    backgroundColor: '#e8f5e9',
  },
}));

// Componente para imagen
const ImagePreview = ({
  src,
  alt = 'Imagen',
  onClick,
  width = '100%',
  height = '100%',
  showZoom = true,
  thumbSize = 'medium' // 'small', 'medium', 'large'
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  // Mapeo de tamaños
  const sizes = {
    small: { width: 40, height: 40, iconSize: 20 },
    medium: { width: 80, height: 80, iconSize: 36 },
    large: { width: 120, height: 120, iconSize: 48 }
  };

  const size = sizes[thumbSize] || sizes.medium;

  useEffect(() => {
    if (!src) {
      setError(true);
      return;
    }

    console.log("Intentando cargar imagen desde:", src);
    setLoaded(false);
    setError(false);

    const img = new Image();

    img.onload = () => {
      console.log("Imagen cargada correctamente");
      setLoaded(true);
      setError(false);
    };

    img.onerror = () => {
      console.error('Error cargando imagen:', src);
      // Intentar URL directa sin parámetros de caché
      if (src.includes('?')) {
        const directUrl = src.split('?')[0];
        console.log("Intentando URL directa:", directUrl);

        const directImg = new Image();
        directImg.onload = () => {
          console.log("Imagen cargada con URL directa");
          setLoaded(true);
          setError(false);
        };
        directImg.onerror = () => {
          console.error("Error también con URL directa");
          setError(true);
          setLoaded(false);
        };
        directImg.src = directUrl;
      } else {
        setError(true);
        setLoaded(false);
      }
    };

    img.src = src;

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src, retryCount]);

  const handleRetry = (e) => {
    e.stopPropagation();
    setRetryCount(prev => prev + 1);
  };

  if (error) {
    return (
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      >
        <Box
          sx={{
            width: width !== '100%' ? width : size.width,
            height: height !== '100%' ? height : size.height,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f5f5f5',
            borderRadius: 1,
            border: '1px solid #e0e0e0',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <ReceiptIcon sx={{ color: '#9e9e9e', fontSize: size.iconSize }} />
          <Typography variant="caption" sx={{ fontSize: '10px', textAlign: 'center', mt: 0.5 }}>
            Error
          </Typography>

          <Tooltip title="Reintentar cargar imagen">
            <IconButton
              size="small"
              onClick={handleRetry}
              sx={{
                position: 'absolute',
                top: 2,
                right: 2,
                background: 'rgba(255,255,255,0.8)',
                width: 16,
                height: 16
              }}
            >
              <RefreshIcon sx={{ fontSize: 12 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </motion.div>
    );
  }

  if (!loaded) {
    return (
      <Box
        sx={{
          width: width !== '100%' ? width : size.width,
          height: height !== '100%' ? height : size.height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f5f5f5',
          borderRadius: 1,
          border: '1px solid #e0e0e0'
        }}
      >
        <CircularProgress size={size.iconSize / 2} sx={{ color: '#2e7d32' }} />
      </Box>
    );
  }

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      style={{ cursor: 'pointer', position: 'relative' }}
    >
      <Box
        sx={{
          width: width !== '100%' ? width : size.width,
          height: height !== '100%' ? height : size.height,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 1,
          border: '1px solid #e0e0e0'
        }}
        onClick={onClick}
      >
        <Box
          component="img"
          src={src}
          alt={alt}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 0.3s ease',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        />

        {showZoom && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 4,
              right: 4,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 24,
              height: 24,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              }
            }}
          >
            <ZoomInIcon sx={{ fontSize: 16, color: '#2e7d32' }} />
          </Box>
        )}
      </Box>
    </motion.div>
  );
};

// Función para formatear URLs de imagen
const formatImageUrl = (url) => {
  if (!url) return null;

  // Si ya incluye parámetros de caché, retornar la URL tal como está
  if (url.includes('?')) return url;

  // Agregar un parámetro de caché para evitar problemas de caché
  const cacheParam = `t=${new Date().getTime()}`;
  return `${url}?${cacheParam}`;
};

// Función para subir imágenes
const uploadImage = async (file, metadata = {}) => {
  if (!file) return null;

  try {
    // Generar un código único para el comprobante
    const codigo = `CM-${metadata.referencia || 'GEN'}-${format(new Date(), 'yyyyMMdd')}-${uuidv4().substring(0, 6)}`;

    // Crear la ruta del archivo
    const fileExt = file.name.split('.').pop();
    const fileName = `cambio_moneda/${codigo}.${fileExt}`;

    // Subir archivo al bucket de Comprobantes
    const { data, error } = await supabase.storage
      .from('Comprobantes')
      .upload(fileName, file, {
        cacheControl: '3600',
        upsert: false
      });

    if (error) throw error;

    // Obtener URL pública del archivo
    const { data: urlData } = await supabase.storage
      .from('Comprobantes')
      .getPublicUrl(fileName);

    return {
      url: urlData.publicUrl,
      codigo: codigo,
      ruta: fileName
    };
  } catch (error) {
    console.error('Error al subir imagen:', error);
    throw error;
  }
};

const CurrencyChanges = () => {
  const [changes, setChanges] = useState([]);
  const [filteredChanges, setFilteredChanges] = useState([]);
  const [cajas, setCajas] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [openAddChange, setOpenAddChange] = useState(false);
  const [openEditChange, setOpenEditChange] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [currentImage, setCurrentImage] = useState('');

  // Paginación
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Filtros
  const [filters, setFilters] = useState({
    caja: '',
    proveedor: '',
    moneda_inicial: '',
    fecha_desde: null,
    fecha_hasta: null,
    tiene_comprobante: null,
    codigo_comprobante: '',
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilters, setActiveFilters] = useState([]);

  // Estados para nuevos cambios
  const [fechaCambio, setFechaCambio] = useState(new Date());
  const [cajaCambio, setCajaCambio] = useState('');
  const [proveedorCambio, setProveedorCambio] = useState('');
  const [tipoCambioCambio, setTipoCambioCambio] = useState('');
  const [monedaInicialCambio, setMonedaInicialCambio] = useState('ARS');
  const [montoInicialCambio, setMontoInicialCambio] = useState('');
  const [filaEditando, setFilaEditando] = useState(null);

  // Estados para imagen de comprobante
  const [tieneComprobante, setTieneComprobante] = useState(false);
  const [imagenComprobante, setImagenComprobante] = useState(null);
  const [imagenComprobanteURL, setImagenComprobanteURL] = useState('');
  const [uploading, setUploading] = useState(false);

  // Referencia al input de archivo
  const fileInputRef = useRef(null);

  // Notificación
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    const fetchDolarBlue = async () => {
      try {
        const response = await fetch('https://dolarapi.com/v1/dolares/blue');
        if (!response.ok) throw new Error('Error al obtener el dólar blue');
        const data = await response.json();
        setTipoCambioCambio(data.compra.toString());
      } catch (error) {
        console.error('Error fetching dólar blue:', error.message);
        setTipoCambioCambio('');
      }
    };
    fetchDolarBlue();
    fetchChanges();
    fetchCajas();
    fetchProveedores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changes, searchTerm, activeFilters]);

  const fetchChanges = async () => {
    // Ordenar por fecha descendente (más reciente primero)
    const { data, error } = await supabase
      .from('cambios_de_moneda')
      .select('*')
      .order('fecha_cambio', { ascending: false });

    if (error) console.error('Error fetching cambios_de_moneda:', error.message);
    else {
      // Formatear fechas para mejor visualización
      const formattedData = data.map(item => ({
        ...item,
        fecha_formateada: item.fecha_cambio ? format(new Date(item.fecha_cambio), 'dd/MM/yyyy') : '',
        monto_inicial_formateado: formatCurrency(item.monto_inicial_cambio, item.moneda_inicial_cambio),
        monto_final_formateado: formatCurrency(item.monto_final_cambio, item.moneda_final_cambio),
        imagen_comprobante_cambio: item.imagen_comprobante_cambio ? formatImageUrl(item.imagen_comprobante_cambio) : null
      }));
      setChanges(formattedData || []);
      setFilteredChanges(formattedData || []);
    }
  };

  const formatCurrency = (amount, currency) => {
    if (!amount) return '';
    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currency === 'USD' ? 'USD' : 'ARS',
      minimumFractionDigits: 2
    });
    return formatter.format(amount);
  };

  const fetchCajas = async () => {
    const { data, error } = await supabase.from('cajas_cambios').select('caja');
    if (error) console.error('Error fetching cajas_cambios:', error);
    else setCajas(data.map((item) => item.caja) || []);
  };

  const fetchProveedores = async () => {
    const { data, error } = await supabase.from('proveedores_de_cambio_de_moneda').select('proveedor_cambio_de_moneda');
    if (error) console.error('Error fetching proveedores_de_cambio_de_moneda:', error);
    else setProveedores(data.map((item) => item.proveedor_cambio_de_moneda) || []);
  };

  const getMonedaFinal = (monedaInicial) => {
    return monedaInicial === 'ARS' ? 'USD' : 'ARS';
  };

  const getMontoFinal = (monedaInicial, montoInicial, tipoCambio) => {
    const monto = parseFloat(montoInicial) || 0;
    const tipo = parseFloat(tipoCambio) || 0;
    if (monedaInicial === 'ARS' && tipo > 0) return (monto / tipo).toFixed(2);
    if (monedaInicial === 'USD' && tipo > 0) return (monto * tipo).toFixed(2);
    return '';
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Verificar tipo de archivo
      if (!file.type.match('image.*')) {
        setSnackbar({
          open: true,
          message: 'Por favor, selecciona un archivo de imagen válido',
          severity: 'error'
        });
        return;
      }

      // Verificar tamaño (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        setSnackbar({
          open: true,
          message: 'La imagen debe ser menor a 5MB',
          severity: 'error'
        });
        return;
      }

      setImagenComprobante(file);
      setTieneComprobante(true);

      // Crear URL temporal para vista previa
      const previewURL = URL.createObjectURL(file);
      setImagenComprobanteURL(previewURL);
    }
  };

  const handleFileUpload = () => {
    fileInputRef.current.click();
  };

  const removeImage = () => {
    setImagenComprobante(null);
    setImagenComprobanteURL('');
    setTieneComprobante(false);

    // Limpiar el input de archivo
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleAgregarCambio = async () => {
    if (!fechaCambio || !cajaCambio || !proveedorCambio || !tipoCambioCambio || !monedaInicialCambio || !montoInicialCambio) {
      setSnackbar({
        open: true,
        message: 'Por favor, completa todos los campos obligatorios',
        severity: 'error'
      });
      return;
    }

    try {
      setUploading(true);

      // Subir imagen si existe
      let imageData = null;

      if (tieneComprobante && imagenComprobante) {
        try {
          imageData = await uploadImage(imagenComprobante, {
            tipo: 'cambio_moneda',
            referencia: cajaCambio,
            proveedor: proveedorCambio
          });
        } catch (uploadError) {
          console.error('Error al subir la imagen:', uploadError);
          setSnackbar({
            open: true,
            message: 'Error al subir la imagen: ' + uploadError.message,
            severity: 'error'
          });
          setUploading(false);
          return;
        }
      }

      const montoFinalCambio = getMontoFinal(monedaInicialCambio, montoInicialCambio, tipoCambioCambio);
      const monedaFinalCambio = getMonedaFinal(monedaInicialCambio);

      // Insertar cambio en la base de datos
      const { error } = await supabase.from('cambios_de_moneda').insert([
        {
          fecha_cambio: fechaCambio.toISOString(),
          caja_cambio: cajaCambio,
          proveedor_de_cambio: proveedorCambio,
          tipo_de_cambio_cambio: tipoCambioCambio,
          moneda_inicial_cambio: monedaInicialCambio,
          monto_inicial_cambio: montoInicialCambio,
          moneda_final_cambio: monedaFinalCambio,
          monto_final_cambio: montoFinalCambio,
          tiene_comprobante_cambio: tieneComprobante,
          imagen_comprobante_cambio: imageData ? imageData.url : null,
          codigo_de_comprobante_cambio: imageData ? imageData.codigo : null,
          ruta_archivo_comprobante_cambio: imageData ? imageData.ruta : null,
        },
      ]);

      if (error) {
        console.error('Error adding cambio:', error.message);
        setSnackbar({
          open: true,
          message: 'Error al agregar cambio: ' + error.message,
          severity: 'error'
        });
      } else {
        fetchChanges();
        setOpenAddChange(false);
        resetForm();
        setSnackbar({
          open: true,
          message: 'Cambio agregado con éxito',
          severity: 'success'
        });
      }
    } catch (error) {
      console.error('Error en el proceso de agregar cambio:', error);
      setSnackbar({
        open: true,
        message: 'Error al procesar la solicitud',
        severity: 'error'
      });
    } finally {
      setUploading(false);
    }
  };

  const handleEditarCambio = (fila) => {
    setFilaEditando({ ...fila });
    setTieneComprobante(fila.tiene_comprobante_cambio || false);
    setImagenComprobanteURL(fila.imagen_comprobante_cambio || '');
    setOpenEditChange(true);
  };

  const handleEliminarCambio = async (id, rutaArchivo) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este cambio?')) {
      try {
        // Si tiene comprobante, eliminarlo del storage
        if (rutaArchivo) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([rutaArchivo]);
          } catch (deleteError) {
            console.error('Error eliminando archivo del storage:', deleteError);
            // Continuamos aunque falle la eliminación del archivo
          }
        }

        // Eliminar el registro de la base de datos
        const { error } = await supabase.from('cambios_de_moneda').delete().eq('id', id);

        if (error) {
          console.error('Error deleting cambio:', error.message);
          setSnackbar({
            open: true,
            message: 'Error al eliminar cambio: ' + error.message,
            severity: 'error'
          });
        } else {
          fetchChanges();
          setSnackbar({
            open: true,
            message: 'Cambio eliminado con éxito',
            severity: 'success'
          });
        }
      } catch (error) {
        console.error('Error en el proceso de eliminación:', error);
        setSnackbar({
          open: true,
          message: 'Error al procesar la solicitud',
          severity: 'error'
        });
      }
    }
  };

  const handleUpdateCambio = async () => {
    try {
      setUploading(true);

      let imageUrl = filaEditando.imagen_comprobante_cambio;
      let codigoComprobante = filaEditando.codigo_de_comprobante_cambio;
      let rutaArchivo = filaEditando.ruta_archivo_comprobante_cambio;

      // Si se cambió la imagen, subir la nueva
      if (tieneComprobante && imagenComprobante) {
        // Eliminar la imagen anterior si existe
        if (imageUrl && rutaArchivo) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([rutaArchivo]);
          } catch (deleteError) {
            console.error('Error eliminando imagen anterior:', deleteError);
            // Continuamos aunque falle la eliminación
          }
        }

        // Subir la nueva imagen
        try {
          const imageData = await uploadImage(imagenComprobante, {
            tipo: 'cambio_moneda',
            referencia: filaEditando.caja_cambio,
            proveedor: filaEditando.proveedor_de_cambio
          });

          imageUrl = imageData.url;
          codigoComprobante = imageData.codigo;
          rutaArchivo = imageData.ruta;
        } catch (uploadError) {
          console.error('Error al subir la nueva imagen:', uploadError);
          setSnackbar({
            open: true,
            message: 'Error al subir la nueva imagen',
            severity: 'error'
          });
          setUploading(false);
          return;
        }
      } else if (!tieneComprobante) {
        // Si se quitó el comprobante, eliminar la imagen
        if (imageUrl && rutaArchivo) {
          try {
            await supabase.storage
              .from('Comprobantes')
              .remove([rutaArchivo]);
          } catch (deleteError) {
            console.error('Error eliminando imagen:', deleteError);
            // Continuamos aunque falle la eliminación
          }
        }

        imageUrl = null;
        codigoComprobante = null;
        rutaArchivo = null;
      }

      const montoFinalCambio = getMontoFinal(
        filaEditando.moneda_inicial_cambio,
        filaEditando.monto_inicial_cambio,
        filaEditando.tipo_de_cambio_cambio
      );
      const monedaFinalCambio = getMonedaFinal(filaEditando.moneda_inicial_cambio);

      // Actualizar el cambio en la base de datos
      const { error } = await supabase
        .from('cambios_de_moneda')
        .update({
          fecha_cambio: filaEditando.fecha_cambio,
          caja_cambio: filaEditando.caja_cambio,
          proveedor_de_cambio: filaEditando.proveedor_de_cambio,
          tipo_de_cambio_cambio: filaEditando.tipo_de_cambio_cambio,
          moneda_inicial_cambio: filaEditando.moneda_inicial_cambio,
          monto_inicial_cambio: filaEditando.monto_inicial_cambio,
          moneda_final_cambio: monedaFinalCambio,
          monto_final_cambio: montoFinalCambio,
          tiene_comprobante_cambio: tieneComprobante,
          imagen_comprobante_cambio: imageUrl,
          codigo_de_comprobante_cambio: codigoComprobante,
          ruta_archivo_comprobante_cambio: rutaArchivo,
        })
        .eq('id', filaEditando.id);

      if (error) {
        console.error('Error updating cambio:', error.message);
        setSnackbar({
          open: true,
          message: 'Error al actualizar cambio: ' + error.message,
          severity: 'error'
        });
      } else {
        fetchChanges();
        setOpenEditChange(false);
        setSnackbar({
          open: true,
          message: 'Cambio actualizado con éxito',
          severity: 'success'
        });
      }
    } catch (error) {
      console.error('Error en el proceso de actualización:', error);
      setSnackbar({
        open: true,
        message: 'Error al procesar la solicitud',
        severity: 'error'
      });
    } finally {
      setUploading(false);
    }
  };

  const resetForm = () => {
    setFechaCambio(new Date());
    setCajaCambio('');
    setProveedorCambio('');
    setTipoCambioCambio('');
    setMonedaInicialCambio('ARS');
    setMontoInicialCambio('');
    setTieneComprobante(false);
    setImagenComprobante(null);
    setImagenComprobanteURL('');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const applyFilters = () => {
    let filtered = [...changes];

    // Búsqueda global
    if (searchTerm) {
      filtered = filtered.filter(cambio =>
        Object.values(cambio).some(value =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Aplicar filtros activos
    activeFilters.forEach(filter => {
      if (filter.type === 'fecha_desde' && filter.value) {
        filtered = filtered.filter(cambio =>
          new Date(cambio.fecha_cambio) >= new Date(filter.value)
        );
      } else if (filter.type === 'fecha_hasta' && filter.value) {
        filtered = filtered.filter(cambio =>
          new Date(cambio.fecha_cambio) <= new Date(filter.value)
        );
      } else if (filter.type === 'tiene_comprobante' && filter.value !== null) {
        filtered = filtered.filter(cambio =>
          cambio.tiene_comprobante_cambio === filter.value
        );
      } else if (filter.type === 'codigo_comprobante' && filter.value) {
        filtered = filtered.filter(cambio =>
          cambio.codigo_de_comprobante_cambio &&
          cambio.codigo_de_comprobante_cambio.toLowerCase().includes(filter.value.toLowerCase())
        );
      } else if (filter.value) {
        filtered = filtered.filter(cambio => {
          const fieldMap = {
            caja: 'caja_cambio',
            proveedor: 'proveedor_de_cambio',
            moneda_inicial: 'moneda_inicial_cambio',
          };
          const field = fieldMap[filter.type];
          return cambio[field] === filter.value;
        });
      }
    });

    setFilteredChanges(filtered);
  };

  const addFilter = () => {
    const newFilters = [];

    if (filters.caja) {
      newFilters.push({ type: 'caja', value: filters.caja, label: `Caja: ${filters.caja}` });
    }

    if (filters.proveedor) {
      newFilters.push({ type: 'proveedor', value: filters.proveedor, label: `Proveedor: ${filters.proveedor}` });
    }

    if (filters.moneda_inicial) {
      newFilters.push({ type: 'moneda_inicial', value: filters.moneda_inicial, label: `Moneda Inicial: ${filters.moneda_inicial}` });
    }

    if (filters.codigo_comprobante) {
      newFilters.push({
        type: 'codigo_comprobante',
        value: filters.codigo_comprobante,
        label: `Código: ${filters.codigo_comprobante}`
      });
    }

    if (filters.fecha_desde) {
      newFilters.push({ type: 'fecha_desde', value: filters.fecha_desde, label: `Desde: ${format(new Date(filters.fecha_desde), 'dd/MM/yyyy')}` });
    }

    if (filters.fecha_hasta) {
      newFilters.push({ type: 'fecha_hasta', value: filters.fecha_hasta, label: `Hasta: ${format(new Date(filters.fecha_hasta), 'dd/MM/yyyy')}` });
    }

    if (filters.tiene_comprobante !== null) {
      newFilters.push({
        type: 'tiene_comprobante',
        value: filters.tiene_comprobante,
        label: `Comprobante: ${filters.tiene_comprobante ? 'Con comprobante' : 'Sin comprobante'}`
      });
    }

    setActiveFilters([...activeFilters, ...newFilters]);

    // Resetear los filtros
    setFilters({
      caja: '',
      proveedor: '',
      moneda_inicial: '',
      fecha_desde: null,
      fecha_hasta: null,
      tiene_comprobante: null,
      codigo_comprobante: '',
    });

    setOpenFilters(false);
  };

  const removeFilter = (index) => {
    const updatedFilters = [...activeFilters];
    updatedFilters.splice(index, 1);
    setActiveFilters(updatedFilters);
  };

  const removeAllFilters = () => {
    setActiveFilters([]);
    setSearchTerm('');
  };

  const exportToCSV = () => {
    // Preparar los datos para exportación
    const csvContent = [
      // Encabezados
      ['ID', 'Fecha', 'Caja', 'Proveedor', 'Tipo de Cambio', 'Moneda Inicial', 'Monto Inicial', 'Moneda Final', 'Monto Final', 'Tiene Comprobante', 'Código Comprobante'],
      // Datos
      ...filteredChanges.map(cambio => [
        cambio.id,
        cambio.fecha_formateada,
        cambio.caja_cambio,
        cambio.proveedor_de_cambio,
        cambio.tipo_de_cambio_cambio,
        cambio.moneda_inicial_cambio,
        cambio.monto_inicial_cambio,
        cambio.moneda_final_cambio,
        cambio.monto_final_cambio,
        cambio.tiene_comprobante_cambio ? 'Sí' : 'No',
        cambio.codigo_de_comprobante_cambio || ''
      ])
    ].map(row => row.join(',')).join('\n');

    // Crear un blob y descargar
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `cambios_moneda_${format(new Date(), 'yyyyMMdd')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShowImage = (cambio) => {
    // Si es un objeto completo de cambio, extraer la información necesaria
    if (typeof cambio === 'object' && cambio !== null && !Array.isArray(cambio)) {
      setCurrentImage({
        url: cambio.imagen_comprobante_cambio,
        codigo: cambio.codigo_de_comprobante_cambio,
        fecha: cambio.fecha_formateada,
        concepto: `${cambio.moneda_inicial_cambio} a ${cambio.moneda_final_cambio}`
      });
    } else {
      // Si es solo la URL, usarla directamente
      setCurrentImage({ url: cambio });
    }
    setOpenImagePreview(true);
  };

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" fontWeight="bold" color="#2e7d32">
              Cambios de Moneda
            </Typography>
            <Tooltip title="Agregar nuevo cambio">
              <AddButton
                onClick={() => setOpenAddChange(true)}
                sx={{ ml: 2 }}
              >
                <AddIcon />
              </AddButton>
            </Tooltip>
          </Box>
          <Tooltip title="Exportar a CSV">
            <ExportButton
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={exportToCSV}
            >
              Exportar
            </ExportButton>
          </Tooltip>
        </Box>

        {/* Barra de búsqueda y filtros */}
        <StyledPaper elevation={0} sx={{ mb: 3, p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
            <TextField
              placeholder="Buscar cambios..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                flex: 1,
                minWidth: 250,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8,
                  '&:hover fieldset': {
                    borderColor: '#2e7d32',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#2e7d32' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<FilterListIcon />}
              onClick={() => setOpenFilters(!openFilters)}
              sx={{
                borderRadius: 8,
                borderColor: '#2e7d32',
                color: '#2e7d32',
                '&:hover': {
                  borderColor: '#1b5e20',
                  backgroundColor: 'rgba(46, 125, 50, 0.04)',
                },
              }}
            >
              Filtros
            </Button>
            {activeFilters.length > 0 && (
              <Button
                variant="text"
                size="small"
                onClick={removeAllFilters}
                sx={{
                  color: '#2e7d32',
                  '&:hover': { backgroundColor: 'rgba(46, 125, 50, 0.04)' },
                }}
              >
                Limpiar filtros
              </Button>
            )}
          </Box>

          <Collapse in={openFilters}>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Caja</InputLabel>
                    <Select
                      value={filters.caja}
                      onChange={(e) => setFilters({ ...filters, caja: e.target.value })}
                      label="Caja"
                    >
                      <MenuItem value="">Todas</MenuItem>
                      {cajas.map((caja) => (
                        <MenuItem key={caja} value={caja}>{caja}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Proveedor</InputLabel>
                    <Select
                      value={filters.proveedor}
                      onChange={(e) => setFilters({ ...filters, proveedor: e.target.value })}
                      label="Proveedor"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {proveedores.map((prov) => (
                        <MenuItem key={prov} value={prov}>{prov}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Moneda Inicial</InputLabel>
                    <Select
                      value={filters.moneda_inicial}
                      onChange={(e) => setFilters({ ...filters, moneda_inicial: e.target.value })}
                      label="Moneda Inicial"
                    >
                      <MenuItem value="">Todas</MenuItem>
                      <MenuItem value="ARS">ARS</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Comprobante</InputLabel>
                    <Select
                      value={filters.tiene_comprobante === null ? '' : filters.tiene_comprobante}
                      onChange={(e) => {
                        const value = e.target.value === '' ? null : e.target.value === 'true';
                        setFilters({ ...filters, tiene_comprobante: value });
                      }}
                      label="Comprobante"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      <MenuItem value="true">Con comprobante</MenuItem>
                      <MenuItem value="false">Sin comprobante</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <TextField
                    fullWidth
                    size="small"
                    label="Código de comprobante"
                    value={filters.codigo_comprobante || ''}
                    onChange={(e) => setFilters({ ...filters, codigo_comprobante: e.target.value })}
                    placeholder="Buscar por código"
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={addFilter}
                    sx={{
                      height: '40px',
                      borderRadius: 8,
                      backgroundColor: '#2e7d32',
                      '&:hover': { backgroundColor: '#1b5e20' },
                    }}
                  >
                    Aplicar Filtros
                  </Button>
                </Grid>
              </Grid>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Grid container spacing={2} sx={{ mt: 1 }}>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Desde"
                      value={filters.fecha_desde}
                      onChange={(newValue) => setFilters({ ...filters, fecha_desde: newValue })}
                      renderInput={(params) => <TextField size="small" {...params} fullWidth />}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      label="Hasta"
                      value={filters.fecha_hasta}
                      onChange={(newValue) => setFilters({ ...filters, fecha_hasta: newValue })}
                      renderInput={(params) => <TextField size="small" {...params} fullWidth />}
                    />
                  </Grid>
                </Grid>
              </LocalizationProvider>
            </Box>
          </Collapse>

          {/* Chips de filtros activos */}
          {activeFilters.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', pt: 2 }}>
              {activeFilters.map((filter, index) => (
                <FilterChip
                  key={index}
                  label={filter.label}
                  onDelete={() => removeFilter(index)}
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
            </Box>
          )}
        </StyledPaper>

        {/* Tabla de cambios */}
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <StyledPaper elevation={0}>
              <TableContainer sx={{ maxHeight: 'calc(100vh - 250px)' }}>
                <Table stickyHeader aria-label="tabla de cambios">
                  <TableHead>
                    <TableRow>
                      <StyledTableHeaderCell>Fecha</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Caja</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Proveedor</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Tipo Cambio</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Inicial</StyledTableHeaderCell>
                      <StyledTableHeaderCell></StyledTableHeaderCell>
                      <StyledTableHeaderCell>Final</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Comprobante</StyledTableHeaderCell>
                      <StyledTableHeaderCell align="center">Acciones</StyledTableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredChanges
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((cambio) => (
                        <StyledTableRow key={cambio.id}>
                          <StyledTableCell>
                            {cambio.fecha_formateada}
                          </StyledTableCell>
                          <StyledTableCell>{cambio.caja_cambio}</StyledTableCell>
                          <StyledTableCell>{cambio.proveedor_de_cambio}</StyledTableCell>
                          <StyledTableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Typography sx={{ fontWeight: 'bold', color: '#2e7d32' }}>
                                {cambio.tipo_de_cambio_cambio}
                              </Typography>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <CurrencyChip
                                label={cambio.monto_inicial_formateado}
                                currency={cambio.moneda_inicial_cambio}
                                icon={<MoneyIcon />}
                                size="small"
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            <ExchangeIcon />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <CurrencyChip
                                label={cambio.monto_final_formateado}
                                currency={cambio.moneda_final_cambio}
                                icon={<MoneyIcon />}
                                size="small"
                              />
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            {cambio.tiene_comprobante_cambio ? (
                              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <ImagePreview
                                  src={cambio.imagen_comprobante_cambio}
                                  onClick={() => handleShowImage(cambio)}
                                  thumbSize="medium"
                                  alt={`Comprobante ${cambio.codigo_de_comprobante_cambio || ""}`}
                                  sx={{ mb: 1 }}
                                />
                                {cambio.codigo_de_comprobante_cambio && (
                                  <Tooltip title="Código del comprobante (click para copiar)">
                                    <Typography variant="caption" sx={{
                                      fontSize: '0.7rem',
                                      fontFamily: 'monospace',
                                      backgroundColor: '#e8f5e9',
                                      padding: '2px 4px',
                                      borderRadius: '4px',
                                      whiteSpace: 'nowrap',
                                      overflow: 'hidden',
                                      textOverflow: 'ellipsis',
                                      maxWidth: '80px',
                                      cursor: 'pointer',
                                    }} onClick={(e) => {
                                      e.stopPropagation();
                                      // Copiar código al portapapeles
                                      navigator.clipboard.writeText(cambio.codigo_de_comprobante_cambio);
                                      setSnackbar({
                                        open: true,
                                        message: 'Código copiado al portapapeles',
                                        severity: 'success'
                                      });
                                    }}>
                                      {cambio.codigo_de_comprobante_cambio}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Box>
                            ) : (
                              <Typography variant="body2" color="text.secondary">
                                No
                              </Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <Tooltip title="Editar">
                                <ActionButton
                                  size="small"
                                  onClick={() => handleEditarCambio(cambio)}
                                >
                                  <EditIcon fontSize="small" />
                                </ActionButton>
                              </Tooltip>
                              <Tooltip title="Eliminar">
                                <ActionButton
                                  size="small"
                                  onClick={() => handleEliminarCambio(cambio.id, cambio.ruta_archivo_comprobante_cambio)}
                                  sx={{ color: '#d32f2f' }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </ActionButton>
                              </Tooltip>
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={filteredChanges.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página:"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                sx={{
                  borderTop: '1px solid rgba(224, 224, 224, 1)',
                  '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                    color: '#424242'
                  },
                  '.MuiTablePagination-select': {
                    color: '#2e7d32'
                  }
                }}
              />
            </StyledPaper>
          </motion.div>
        </AnimatePresence>
      </motion.div>

      {/* Diálogo para agregar nuevo cambio */}
      <AddChangeDialog open={openAddChange} onClose={() => setOpenAddChange(false)}>
        <DialogContent>
          <Typography variant="h5" fontWeight="bold" color="#2e7d32" gutterBottom textAlign="center">
            Agregar Cambio
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DatePicker
                  label="Fecha"
                  value={fechaCambio}
                  onChange={(newValue) => setFechaCambio(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Caja</InputLabel>
                  <Select value={cajaCambio} onChange={(e) => setCajaCambio(e.target.value)}>
                    {cajas.map((caja) => (
                      <MenuItem key={caja} value={caja}>
                        {caja}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Proveedor</InputLabel>
                  <Select value={proveedorCambio} onChange={(e) => setProveedorCambio(e.target.value)}>
                    {proveedores.map((prov) => (
                      <MenuItem key={prov} value={prov}>
                        {prov}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Tipo de Cambio"
                  value={tipoCambioCambio || ''}
                  onChange={(e) => setTipoCambioCambio(e.target.value)}
                  fullWidth
                  type="number"
                  inputProps={{ step: "0.01" }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Moneda Inicial</InputLabel>
                  <Select value={monedaInicialCambio} onChange={(e) => setMonedaInicialCambio(e.target.value)}>
                    <MenuItem value="ARS">ARS</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Monto Inicial"
                  value={montoInicialCambio}
                  onChange={(e) => setMontoInicialCambio(e.target.value)}
                  fullWidth
                  type="number"
                  inputProps={{ step: "0.01" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Moneda Final"
                  value={getMonedaFinal(monedaInicialCambio)}
                  disabled
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    sx: { backgroundColor: '#f5f5f5' }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Monto Final"
                  value={getMontoFinal(monedaInicialCambio, montoInicialCambio, tipoCambioCambio)}
                  disabled
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    sx: { backgroundColor: '#f5f5f5' }
                  }}
                />
              </Grid>

              {/* Sección para agregar comprobante */}
              <Grid item xs={12}>
                <Box sx={{ mt: 2, mb: 1 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={tieneComprobante}
                        onChange={(e) => setTieneComprobante(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Tiene comprobante"
                  />
                </Box>
              </Grid>

              {tieneComprobante && (
                <Grid item xs={12}>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />

                  {!imagenComprobanteURL ? (
                    <FileUploadBox onClick={handleFileUpload}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <AttachFileIcon sx={{ fontSize: 40, color: '#2e7d32', mb: 1 }} />
                        <Typography variant="body1" gutterBottom>
                          Haz clic para subir una imagen de comprobante
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          JPG, PNG o PDF (máx. 5MB)
                        </Typography>
                      </Box>
                    </FileUploadBox>
                  ) : (
                    <Box sx={{ mt: 2, position: 'relative' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          position: 'relative',
                          width: '100%',
                          height: 200,
                          backgroundColor: '#f8f9fa',
                          borderRadius: 2,
                          overflow: 'hidden',
                          border: '1px solid #e0e0e0',
                        }}
                      >
                        <Box
                          component="img"
                          src={imagenComprobanteURL}
                          alt="Vista previa de comprobante"
                          sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                          }}
                        />
                        <IconButton
                          size="small"
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                          }}
                          onClick={removeImage}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
          <Button
            onClick={() => {
              setOpenAddChange(false);
              resetForm();
            }}
            sx={{
              borderRadius: 8,
              color: '#757575',
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
            }}
          >
            Cancelar
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAgregarCambio}
            disabled={uploading}
            sx={{
              borderRadius: 8,
              backgroundColor: '#2e7d32',
              '&:hover': { backgroundColor: '#1b5e20' },
              px: 3
            }}
          >
            {uploading ? <CircularProgress size={24} color="inherit" /> : 'Guardar Cambio'}
          </Button>
        </DialogActions>
      </AddChangeDialog>

      {/* Diálogo para editar cambio */}
      <AddChangeDialog open={openEditChange} onClose={() => setOpenEditChange(false)}>
        <DialogContent>
          <Typography variant="h5" fontWeight="bold" color="#2e7d32" gutterBottom textAlign="center">
            Editar Cambio
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <DatePicker
                  label="Fecha"
                  value={filaEditando?.fecha_cambio ? new Date(filaEditando.fecha_cambio) : null}
                  onChange={(newValue) =>
                    setFilaEditando({ ...filaEditando, fecha_cambio: newValue?.toISOString() })
                  }
                  renderInput={(params) => <TextField {...params} fullWidth />}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Caja</InputLabel>
                  <Select
                    value={filaEditando?.caja_cambio || ''}
                    onChange={(e) =>
                      setFilaEditando({ ...filaEditando, caja_cambio: e.target.value })
                    }
                  >
                    {cajas.map((caja) => (
                      <MenuItem key={caja} value={caja}>
                        {caja}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Proveedor</InputLabel>
                  <Select
                    value={filaEditando?.proveedor_de_cambio || ''}
                    onChange={(e) =>
                      setFilaEditando({ ...filaEditando, proveedor_de_cambio: e.target.value })
                    }
                  >
                    {proveedores.map((prov) => (
                      <MenuItem key={prov} value={prov}>
                        {prov}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Tipo de Cambio"
                  value={filaEditando?.tipo_de_cambio_cambio || ''}
                  onChange={(e) =>
                    setFilaEditando({ ...filaEditando, tipo_de_cambio_cambio: e.target.value })
                  }
                  fullWidth
                  type="number"
                  inputProps={{ step: "0.01" }}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel>Moneda Inicial</InputLabel>
                  <Select
                    value={filaEditando?.moneda_inicial_cambio || 'ARS'}
                    onChange={(e) =>
                      setFilaEditando({ ...filaEditando, moneda_inicial_cambio: e.target.value })
                    }
                  >
                    <MenuItem value="ARS">ARS</MenuItem>
                    <MenuItem value="USD">USD</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Monto Inicial"
                  value={filaEditando?.monto_inicial_cambio || ''}
                  onChange={(e) =>
                    setFilaEditando({ ...filaEditando, monto_inicial_cambio: e.target.value })
                  }
                  fullWidth
                  type="number"
                  inputProps={{ step: "0.01" }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Moneda Final"
                  value={filaEditando ? getMonedaFinal(filaEditando.moneda_inicial_cambio) : ''}
                  disabled
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    sx: { backgroundColor: '#f5f5f5' }
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Monto Final"
                  value={filaEditando ? getMontoFinal(
                    filaEditando.moneda_inicial_cambio,
                    filaEditando.monto_inicial_cambio,
                    filaEditando.tipo_de_cambio_cambio
                  ) : ''}
                  disabled
                  fullWidth
                  InputProps={{
                    readOnly: true,
                    sx: { backgroundColor: '#f5f5f5' }
                  }}
                />
              </Grid>

              {/* Sección para editar comprobante */}
              <Grid item xs={12}>
                <Box sx={{ mt: 2, mb: 1 }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={tieneComprobante}
                        onChange={(e) => setTieneComprobante(e.target.checked)}
                        color="primary"
                      />
                    }
                    label="Tiene comprobante"
                  />
                </Box>
              </Grid>

              {tieneComprobante && (
                <Grid item xs={12}>
                  <input
                    ref={fileInputRef}
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                  />

                  {!imagenComprobanteURL ? (
                    <FileUploadBox onClick={handleFileUpload}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <AttachFileIcon sx={{ fontSize: 40, color: '#2e7d32', mb: 1 }} />
                        <Typography variant="body1" gutterBottom>
                          Haz clic para subir una imagen de comprobante
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          JPG, PNG o PDF (máx. 5MB)
                        </Typography>
                      </Box>
                    </FileUploadBox>
                  ) : (
                    <Box sx={{ mt: 2, position: 'relative' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          position: 'relative',
                          width: '100%',
                          height: 200,
                          backgroundColor: '#f8f9fa',
                          borderRadius: 2,
                          overflow: 'hidden',
                          border: '1px solid #e0e0e0',
                        }}
                      >
                        <Box
                          component="img"
                          src={imagenComprobanteURL}
                          alt="Vista previa de comprobante"
                          sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                          }}
                        />
                        <IconButton
                          size="small"
                          sx={{
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                            '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                          }}
                          onClick={removeImage}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>
          </LocalizationProvider>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
          <Button
            onClick={() => setOpenEditChange(false)}
            sx={{
              borderRadius: 8,
              color: '#757575',
              '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' }
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={handleUpdateCambio}
            variant="contained"
            color="primary"
            disabled={uploading}
            sx={{
              borderRadius: 8,
              backgroundColor: '#2e7d32',
              '&:hover': { backgroundColor: '#1b5e20' },
              px: 3
            }}
          >
            {uploading ? <CircularProgress size={24} color="inherit" /> : 'Guardar Cambios'}
          </Button>
        </DialogActions>
      </AddChangeDialog>

      {/* Diálogo para vista previa de imagen */}
      <ImagePreviewDialog
        open={openImagePreview}
        onClose={() => setOpenImagePreview(false)}
        maxWidth="md"
        onClick={() => setOpenImagePreview(false)}
      >
        <DialogContent sx={{ p: 0, overflow: 'hidden', position: 'relative' }}>
          {currentImage && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                background: 'transparent',
              }}
            >
              <Box
                component="img"
                src={currentImage.url || currentImage}
                alt="Comprobante"
                sx={{
                  maxWidth: '100%',
                  maxHeight: '75vh',
                  objectFit: 'contain',
                  borderRadius: 1,
                  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
                }}
                onError={(e) => {
                  console.error('Error cargando imagen en diálogo:', e);
                  e.target.src = 'https://via.placeholder.com/400x300?text=Imagen+no+disponible';
                }}
              />

              {currentImage.codigo && (
                <Box sx={{
                  mt: 2,
                  p: 1,
                  backgroundColor: 'rgba(255, 255, 255, 0.9)',
                  borderRadius: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}>
                  <Typography variant="body2" fontWeight="bold" sx={{ mb: 0.5 }}>
                    Código de comprobante
                  </Typography>
                  <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: '#e8f5e9',
                    p: 1,
                    borderRadius: 1,
                    fontFamily: 'monospace'
                  }}>
                    <Typography variant="body2">
                      {currentImage.codigo}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigator.clipboard.writeText(currentImage.codigo);
                        setSnackbar({
                          open: true,
                          message: 'Código copiado al portapapeles',
                          severity: 'success'
                        });
                      }}
                      sx={{ ml: 1 }}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Box>
              )}

              <IconButton
                size="small"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                }}
                onClick={() => setOpenImagePreview(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </DialogContent>
      </ImagePreviewDialog>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            borderRadius: 2,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CurrencyChanges;