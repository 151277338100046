import React, { useState, useEffect, useRef } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Card,
  CardContent,
  Chip,
  Container,
  Dialog,
  DialogContent,
  Tooltip,
  InputAdornment,
  InputBase,
  SwipeableDrawer,
  Fab,
  Divider,
  Snackbar,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  AppBar,
  Toolbar,
  Grid,
  Paper,
  Badge,
  Zoom,
  useScrollTrigger,
  Slide,
  Backdrop
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import SearchIcon from '@mui/icons-material/Search';
import TuneIcon from '@mui/icons-material/Tune';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CloseIcon from '@mui/icons-material/Close';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import FolderIcon from '@mui/icons-material/Folder';
import DownloadIcon from '@mui/icons-material/Download';
import SortIcon from '@mui/icons-material/Sort';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import MoneyIcon from '@mui/icons-material/Money';
import PaymentsIcon from '@mui/icons-material/Payments';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TimelineIcon from '@mui/icons-material/Timeline';
import PersonIcon from '@mui/icons-material/Person';

import { format } from 'date-fns';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import { formatImageUrl } from '../utils/imageUtils';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

// Componentes con estilos mejorados para móvil
const MobileContainer = styled(Container)(({ theme }) => ({
  padding: theme.spacing(1, 1.5, 10, 1.5), // Padding extra abajo para el botón flotante
  maxWidth: '100%',
  backgroundColor: '#f5f5f5',
  minHeight: 'calc(100vh - 64px)',
  marginTop: 0
}));

const SearchBarWrapper = styled(Box)(({ theme, disappear }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(1),
  marginBottom: theme.spacing(2),
  padding: theme.spacing(0, 1),
  width: '100%',
  transition: 'opacity 0.3s ease, transform 0.3s ease',
  opacity: disappear ? 0 : 1,
  transform: disappear ? 'translateY(-10px)' : 'translateY(0)',
  pointerEvents: disappear ? 'none' : 'auto'
}));

const StyledSearchBar = styled(Paper)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: theme.spacing(0.5, 1.5),
  borderRadius: 24,
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  backgroundColor: '#fff',
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: 20,
  color: theme.palette.primary.main,
}));

// Botones flotantes que aparecen al scrollear
const FloatingButtonsContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 76,
  right: 16,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  zIndex: 1200,
}));

const DocTypeToggleContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(1),
  marginBottom: theme.spacing(2),
}));

// Toggle para tipo de documento estilizado
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  backgroundColor: '#f5f5f5',
  borderRadius: 30,
  boxShadow: '0 2px 8px rgba(0,0,0,0.06)',
  '& .MuiToggleButtonGroup-grouped': {
    border: 0,
    borderRadius: 30,
    padding: '6px 16px',
    margin: '4px',
    fontSize: '0.85rem',
    fontWeight: 'medium',
    textTransform: 'none',
    transition: 'all 0.2s ease-in-out',
    '&.Mui-selected': {
      backgroundColor: '#2e7d32',
      color: 'white',
      boxShadow: '0 2px 8px rgba(46, 125, 50, 0.3)',
      '&:hover': {
        backgroundColor: '#1b5e20',
      }
    },
    '&:not(.Mui-selected)': {
      color: 'rgba(0, 0, 0, 0.7)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
      }
    }
  }
}));

const StyledCard = styled(Card)(({ theme }) => ({
  borderRadius: 16,
  overflow: 'hidden',
  transition: 'transform 0.3s, box-shadow 0.3s',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: '#ffffff',
  '&:hover': {
    transform: 'translateY(-4px)',
    boxShadow: '0 12px 20px rgba(0, 0, 0, 0.1)',
  },
}));

const StyledCardMedia = styled(Box)(({ theme }) => ({
  position: 'relative',
  paddingTop: '75%', // Aspect ratio 4:3
  overflow: 'hidden',
  cursor: 'pointer',
  backgroundColor: '#f5f5f5',
}));

const CodeChip = styled(Chip)(({ theme }) => ({
  position: 'absolute',
  bottom: 8,
  right: 8,
  backgroundColor: 'rgba(46, 125, 50, 0.9)',
  color: 'white',
  fontFamily: 'monospace',
  fontWeight: 'bold',
  fontSize: '0.7rem',
  boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
}));

const OriginChip = styled(Chip)(({ theme, origin }) => {
  const colors = {
    entrada: { bg: 'rgba(25, 118, 210, 0.9)', color: 'white' },
    cambio: { bg: 'rgba(46, 125, 50, 0.9)', color: 'white' },
    salida: { bg: 'rgba(211, 47, 47, 0.9)', color: 'white' },
    cobro: { bg: 'rgba(245, 124, 0, 0.9)', color: 'white' },
    default: { bg: 'rgba(97, 97, 97, 0.9)', color: 'white' }
  };

  const style = colors[origin] || colors.default;

  return {
    position: 'absolute',
    top: 8,
    left: 8,
    backgroundColor: style.bg,
    color: style.color,
    fontSize: '0.6rem',
    fontWeight: 'bold',
    boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
    height: '22px',
  };
});

const TypeChip = styled(Chip)(({ theme, docType }) => {
  const style = docType === 'comprobante'
    ? { bg: 'rgba(25, 118, 210, 0.9)', color: 'white' }
    : { bg: 'rgba(211, 47, 47, 0.9)', color: 'white' };

  return {
    position: 'absolute',
    top: 8,
    right: 8,
    backgroundColor: style.bg,
    color: style.color,
    fontSize: '0.6rem',
    fontWeight: 'bold',
    boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
    height: '22px',
  };
});

// Modificado para lista
const ListOriginChip = styled(Chip)(({ theme, origin }) => {
  const colors = {
    entrada: { bg: 'rgba(25, 118, 210, 0.9)', color: 'white' },
    cambio: { bg: 'rgba(46, 125, 50, 0.9)', color: 'white' },
    salida: { bg: 'rgba(211, 47, 47, 0.9)', color: 'white' },
    cobro: { bg: 'rgba(245, 124, 0, 0.9)', color: 'white' },
    default: { bg: 'rgba(97, 97, 97, 0.9)', color: 'white' }
  };

  const style = colors[origin] || colors.default;

  return {
    position: 'absolute',
    top: 8,
    left: 8,
    backgroundColor: style.bg,
    color: style.color,
    fontSize: '0.6rem',
    fontWeight: 'bold',
    boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
    height: '22px',
    maxWidth: '42%'
  };
});

// Modificado para lista
const ListTypeChip = styled(Chip)(({ theme, docType }) => {
  const style = docType === 'comprobante'
    ? { bg: 'rgba(25, 118, 210, 0.9)', color: 'white' }
    : { bg: 'rgba(211, 47, 47, 0.9)', color: 'white' };

  return {
    position: 'absolute',
    top: 38,
    left: 8,
    backgroundColor: style.bg,
    color: style.color,
    fontSize: '0.6rem',
    fontWeight: 'bold',
    boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
    height: '22px',
    maxWidth: '42%'
  };
});

const DownloadButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: 8,
  left: 8,
  backgroundColor: 'rgba(255, 255, 255, 0.9)',
  color: '#2e7d32',
  '&:hover': {
    backgroundColor: 'white',
    transform: 'scale(1.1)',
  },
  boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
  zIndex: 5,
  padding: 6,
  width: 30,
  height: 30,
}));

const DetailBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  backdropFilter: 'blur(5px)',
}));

const DetailContainer = styled(motion.div)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: '#fff',
  borderRadius: '24px 24px 0 0',
  zIndex: theme.zIndex.drawer + 2,
  maxHeight: '85vh',
  overflowY: 'auto',
  boxShadow: '0px -4px 20px rgba(0, 0, 0, 0.15)',
  padding: theme.spacing(3, 2),
}));

const FilterDrawer = styled(SwipeableDrawer)(({ theme }) => ({
  '& .MuiDrawer-paper': {
    borderTopLeftRadius: 24,
    borderTopRightRadius: 24,
    maxHeight: '85vh',
  },
}));

const FilterChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: '#e8f5e9',
  color: '#2e7d32',
  borderRadius: 16,
  height: 32,
  '&:hover': {
    backgroundColor: '#c8e6c9',
  },
  '& .MuiChip-deleteIcon': {
    color: '#2e7d32',
    '&:hover': {
      color: '#1b5e20',
    },
  },
}));

const ScrollTopFab = styled(Fab)(({ theme }) => ({
  position: 'fixed',
  bottom: 70,
  right: 16,
  zIndex: 1000,
}));

// Estilos mejorados para la vista detallada
const DetailInfoSection = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:last-child': {
    marginBottom: 0,
  },
}));

const DetailLabel = styled(Typography)(({ theme }) => ({
  fontSize: '0.7rem',
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(0.5),
  fontWeight: 500,
}));

const DetailValue = styled(Typography)(({ theme }) => ({
  fontSize: '0.95rem',
  color: theme.palette.text.primary,
  fontWeight: 500,
}));

const DetailChip = styled(Chip)(({ theme, color }) => ({
  borderRadius: 8,
  height: 28,
  backgroundColor: color || '#e0e0e0',
  color: '#fff',
  fontWeight: 500,
  fontSize: '0.75rem',
  marginRight: theme.spacing(1),
  marginBottom: theme.spacing(1)
}));

// Define los tipos de origen de documentos
const DOC_ORIGINS = {
  ENTRADA: 'entrada',
  CAMBIO: 'cambio',
  SALIDA: 'salida',
  COBRO: 'cobro'
};

// Define tipos de documentos
const DOC_TYPES = {
  COMPROBANTE: 'comprobante',
  FACTURA: 'factura'
};

// Mapeo de iconos para orígenes
const originIcons = {
  [DOC_ORIGINS.ENTRADA]: <AttachMoneyIcon />,
  [DOC_ORIGINS.CAMBIO]: <MoneyIcon />,
  [DOC_ORIGINS.SALIDA]: <PaymentsIcon />,
  [DOC_ORIGINS.COBRO]: <AccountBalanceIcon />,
};

// Mapeo de colores para orígenes
const originColors = {
  [DOC_ORIGINS.ENTRADA]: '#1976d2', // Azul
  [DOC_ORIGINS.CAMBIO]: '#2e7d32', // Verde
  [DOC_ORIGINS.SALIDA]: '#d32f2f', // Rojo
  [DOC_ORIGINS.COBRO]: '#ed6c02', // Naranja
};

// Mapeo de colores para tipos de documentos
const docTypeColors = {
  [DOC_TYPES.COMPROBANTE]: '#1976d2', // Azul
  [DOC_TYPES.FACTURA]: '#d32f2f', // Rojo
};

const BuscadorUnificadoMobile = () => {
  const [cajasOrigen, setCajasOrigen] = useState([]);
  const [conceptos, setConceptos] = useState([]);
  const [nombreFacturas, setNombreFacturas] = useState([]);
  const [proveedores, setProveedores] = useState([]);
  const [trabajadores, setTrabajadores] = useState([]);
  const [proyectos, setProyectos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [documentos, setDocumentos] = useState([]);
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [isSortMenuOpen, setIsSortMenuOpen] = useState(false);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' o 'list'
  const [tabValue, setTabValue] = useState(0); // 0: Todos, 1: Recientes, 2: Caja, 3: Nombre (solo facturas)
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [documentType, setDocumentType] = useState('todos'); // 'todos', 'comprobantes', 'facturas'
  const [showSearchBarFocus, setShowSearchBarFocus] = useState(false);
  const searchInputRef = useRef(null);

  // Estados para filtros activos
  const [activeFilters, setActiveFilters] = useState([]);

  // Estados para menús y sort
  const [sortBy, setSortBy] = useState('date_desc');

  const [filters, setFilters] = useState({
    codigo: '',
    proyecto: '',
    caja_origen: '',
    proveedor: '',
    trabajador: '',
    nombre_factura: '',
    concepto: '',
    fecha_desde: null,
    fecha_hasta: null,
    origen: '',
    tipo_documento: '',
  });

  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  // Detectar scroll para botones flotantes
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 100,
  });

  // Referencia para la animación del scroll
  const listRef = useRef(null);
  const topRef = useRef(null);
  const searchBarRef = useRef(null);

  useEffect(() => {
    fetchCajasOrigen();
    fetchConceptos();
    fetchNombreFacturas();
    fetchProveedores();
    fetchTrabajadores();
    fetchProyectos();
    // Ejecutar búsqueda inicial para mostrar los últimos documentos
    handleSearchDocumentos();
  }, []);

  // Efecto para cambiar búsqueda según tab seleccionado
  useEffect(() => {
    if (tabValue === 0) {
      // Todos los documentos
      handleSearchDocumentos();
    } else if (tabValue === 1) {
      // Recientes (últimos 30 días)
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30);

      setFilters({
        ...filters,
        fecha_desde: thirtyDaysAgo,
        fecha_hasta: new Date(),
      });

      handleSearchDocumentos();
    }
  }, [tabValue]);

  // Efecto para cuando cambie el tipo de documento
  useEffect(() => {
    // Resetear folder seleccionado y tabs al cambiar tipo
    setSelectedFolder(null);
    if (documentType === 'facturas') {
      setTabValue(prev => prev === 3 ? prev : 0); // Mantener la pestaña Por Nombre sólo si estamos en facturas
    } else if (documentType === 'comprobantes') {
      setTabValue(prev => prev === 3 ? 0 : prev); // Cambiar de Por Nombre a Todos si estábamos en comprobantes
    }
    handleSearchDocumentos();
  }, [documentType]);

  const scrollToTop = () => {
    topRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const focusSearchBar = () => {
    setShowSearchBarFocus(true);
    setTimeout(() => {
      if (searchInputRef.current) {
        searchInputRef.current.focus();
        // Scroll to the search bar
        searchBarRef.current?.scrollIntoView({ behavior: 'smooth' });
      }
    }, 300);
  };

  const fetchCajasOrigen = async () => {
    const { data, error } = await supabase.from('cajas_cambios').select('caja');
    if (error) console.error('Error fetching cajas origen:', error);
    else setCajasOrigen(data?.map((item) => item.caja) || []);
  };

  const fetchConceptos = async () => {
    const { data, error } = await supabase.from('conceptos_de_entradas').select('concepto_de_entrada');
    if (error) console.error('Error fetching conceptos:', error);
    else setConceptos(data?.map((item) => item.concepto_de_entrada) || []);
  };

  const fetchNombreFacturas = async () => {
    const { data, error } = await supabase.from('facturas_a_nombre_de_salidas').select('nombre_factura');
    if (error) console.error('Error fetching nombres de facturas:', error);
    else setNombreFacturas(data?.map((item) => item.nombre_factura) || []);
  };

  const fetchProveedores = async () => {
    const { data, error } = await supabase.from('proveedores_de_servicios').select('proveedor_de_servicios');
    if (error) console.error('Error fetching proveedores:', error);
    else setProveedores(data?.map((prov) => prov.proveedor_de_servicios) || []);
  };

  const fetchTrabajadores = async () => {
    const { data, error } = await supabase.from('trabajadores_empresa').select('nombre_del_trabajador');
    if (error) console.error('Error fetching trabajadores:', error);
    else setTrabajadores(data?.map((trab) => trab.nombre_del_trabajador) || []);
  };

  const fetchProyectos = async () => {
    const { data, error } = await supabase.from('proyectos').select('codigo_de_proyecto');
    if (error) {
      console.error('Error fetching proyectos:', error);
      // Usar los valores disponibles en salidas si no hay tabla de proyectos
      const { data: salidaData } = await supabase
        .from('salidas')
        .select('codigo_de_proyecto_salida')
        .not('codigo_de_proyecto_salida', 'is', null);

      if (salidaData) {
        // Extraer valores únicos
        const uniqueProyectos = [...new Set(salidaData.map(item => item.codigo_de_proyecto_salida))];
        setProyectos(uniqueProyectos.filter(p => p)); // Filtrar valores nulos o undefined
      }
    } else {
      setProyectos(data?.map((item) => item.codigo_de_proyecto) || []);
    }
  };

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
    setSelectedFolder(null); // Resetear carpeta seleccionada al cambiar tabs
  };

  const handleDocTypeChange = (event, newValue) => {
    if (newValue !== null) {
      setDocumentType(newValue);
    }
  };

  const handleSortClick = () => {
    setIsSortMenuOpen(true);
  };

  const handleSortClose = () => {
    setIsSortMenuOpen(false);
  };

  const handleSortSelect = (sortOption) => {
    setSortBy(sortOption);
    setIsSortMenuOpen(false);

    // Reordenar los resultados existentes
    let sortedDocumentos = [...documentos];

    switch (sortOption) {
      case 'date_desc':
        sortedDocumentos.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
        break;
      case 'date_asc':
        sortedDocumentos.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
        break;
      case 'amount_desc':
        sortedDocumentos.sort((a, b) => parseFloat(b.monto) - parseFloat(a.monto));
        break;
      case 'amount_asc':
        sortedDocumentos.sort((a, b) => parseFloat(a.monto) - parseFloat(b.monto));
        break;
      default:
        break;
    }

    setDocumentos(sortedDocumentos);
  };

  // Función para normalizar datos de diferentes orígenes - COMPROBANTES
  const normalizeComprobanteData = (data, origen) => {
    let normalizedData = [];

    switch (origen) {
      // Dentro de la función normalizeFacturaData
      // Modificar el caso DOC_ORIGINS.ENTRADA:

      case DOC_ORIGINS.ENTRADA:
        normalizedData = data.map(item => ({
          id: `entrada-fact-${item.id}`,
          doc_type: DOC_TYPES.FACTURA,
          origen: DOC_ORIGINS.ENTRADA,
          codigo: item.codigo_de_factura_entrada,
          fecha: item.fecha_entrada,
          fecha_formateada: item.fecha_entrada ? format(new Date(item.fecha_entrada), 'dd/MM/yyyy') : '',
          monto: item.monto_entrada,
          moneda: item.moneda_entrada,
          monto_formateado: formatCurrency(item.monto_entrada, item.moneda_entrada),
          imagen: item.imagen_factura_entrada ? formatImageUrl(item.imagen_factura_entrada) : null,
          caja: item.caja_origen_entrada,
          concepto: item.concepto_entrada,
          proyecto: item.codigo_de_proyecto_entrada || '',
          proveedor: 'N/A', // Ahora siempre será "N/A" ya que el campo fue eliminado
          nombre_factura: item.factura_a_nombre_de_entrada || 'N/A',
          trabajador: "N/A",
          // Valores formateados para display
          origen_display: "Entrada",
          tipo_display: "Factura",
          // IDs para uso en la lógica de edición
          tabla_origen: 'entradas',
          id_original: item.id,
          // Campos originales para mantener compatibilidad
          ...item
        }));
        break;

      case DOC_ORIGINS.CAMBIO:
        normalizedData = data.map(item => ({
          id: `cambio-comp-${item.id}`,
          doc_type: DOC_TYPES.COMPROBANTE,
          origen: DOC_ORIGINS.CAMBIO,
          codigo: item.codigo_de_comprobante_cambio,
          fecha: item.fecha_cambio,
          fecha_formateada: item.fecha_cambio ? format(new Date(item.fecha_cambio), 'dd/MM/yyyy') : '',
          monto: item.monto_inicial_cambio,
          moneda: item.moneda_inicial_cambio,
          monto_formateado: formatCurrency(item.monto_inicial_cambio, item.moneda_inicial_cambio),
          imagen: item.imagen_comprobante_cambio ? formatImageUrl(item.imagen_comprobante_cambio) : null,
          caja: item.caja_cambio,
          concepto: `Cambio ${item.moneda_inicial_cambio} → ${item.moneda_final_cambio}`,
          proyecto: '',  // Los cambios de moneda no tienen proyecto
          proveedor: "Casa de cambio",
          nombre_factura: item.factura_a_nombre_de_cambio || "Empresa",
          trabajador: "N/A",
          // Valores formateados para display
          origen_display: "Cambio",
          tipo_display: "Comprobante",
          // IDs para uso en la lógica de edición
          tabla_origen: 'cambios_de_moneda',
          id_original: item.id,
          // Campos originales para mantener compatibilidad
          ...item
        }));
        break;

      case DOC_ORIGINS.SALIDA:
        normalizedData = data.map(item => ({
          id: `salida-comp-${item.id}`,
          doc_type: DOC_TYPES.COMPROBANTE,
          origen: DOC_ORIGINS.SALIDA,
          codigo: item.codigo_de_comprobante_salida,
          fecha: item.fecha_salida,
          fecha_formateada: item.fecha_salida ? format(new Date(item.fecha_salida), 'dd/MM/yyyy') : '',
          monto: item.monto_salida,
          moneda: item.moneda_salida,
          monto_formateado: formatCurrency(item.monto_salida, item.moneda_salida),
          imagen: item.imagen_comprobante_salida ? formatImageUrl(item.imagen_comprobante_salida) : null,
          caja: item.caja_origen_salida,
          concepto: item.concepto_salidas,
          proyecto: item.codigo_de_proyecto_salida || '',
          proveedor: item.proveedor_salida,
          nombre_factura: item.factura_a_nombre_de_salida,
          trabajador: "N/A",
          // Valores formateadas para display
          origen_display: "Salida",
          tipo_display: "Comprobante",
          // IDs para uso en la lógica de edición
          tabla_origen: 'salidas',
          id_original: item.id,
          // Campos originales para mantener compatibilidad
          ...item
        }));
        break;

      case DOC_ORIGINS.COBRO:
        normalizedData = data.map(item => ({
          id: `cobro-comp-${item.id}`,
          doc_type: DOC_TYPES.COMPROBANTE,
          origen: DOC_ORIGINS.COBRO,
          codigo: item.codigo_de_comprobante_cobro,
          fecha: item.fecha,
          fecha_formateada: item.fecha ? format(new Date(item.fecha), 'dd/MM/yyyy') : '',
          monto: item.monto_cobro,
          moneda: item.moneda_cobro,
          monto_formateado: formatCurrency(item.monto_cobro, item.moneda_cobro),
          imagen: item.imagen_comprobante_cobro ? formatImageUrl(item.imagen_comprobante_cobro) : null,
          caja: item.caja_origen_cobro,
          concepto: item.descripción_cobro || "Cobro a trabajador",
          proyecto: '',  // Cobros no tienen proyecto generalmente
          proveedor: "N/A",
          nombre_factura: item.factura_a_nombre_de_cobro || "Empresa",
          trabajador: item.trabajador_cobro || "",
          // Valores formateados para display
          origen_display: "Cobro",
          tipo_display: "Comprobante",
          // IDs para uso en la lógica de edición
          tabla_origen: 'cobro_trabajadores',
          id_original: item.id,
          // Campos originales para mantener compatibilidad
          ...item
        }));
        break;

      default:
        break;
    }

    return normalizedData;
  };
  // Función para normalizar datos de diferentes orígenes - FACTURAS
  const normalizeFacturaData = (data, origen) => {
    let normalizedData = [];

    switch (origen) {
      case DOC_ORIGINS.ENTRADA:
        normalizedData = data.map(item => ({
          id: `entrada-fact-${item.id}`,
          doc_type: DOC_TYPES.FACTURA,
          origen: DOC_ORIGINS.ENTRADA,
          codigo: item.codigo_de_factura_entrada,
          fecha: item.fecha_entrada,
          fecha_formateada: item.fecha_entrada ? format(new Date(item.fecha_entrada), 'dd/MM/yyyy') : '',
          monto: item.monto_entrada,
          moneda: item.moneda_entrada,
          monto_formateado: formatCurrency(item.monto_entrada, item.moneda_entrada),
          imagen: item.imagen_factura_entrada ? formatImageUrl(item.imagen_factura_entrada) : null,
          caja: item.caja_origen_entrada,
          concepto: item.concepto_entrada,
          proyecto: item.codigo_de_proyecto_entrada || '',
          proveedor: item.proveedor_entrada || 'N/A',
          nombre_factura: item.factura_a_nombre_de_entrada || 'N/A',
          trabajador: "N/A",
          // Valores formateados para display
          origen_display: "Entrada",
          tipo_display: "Factura",
          // IDs para uso en la lógica de edición
          tabla_origen: 'entradas',
          id_original: item.id,
          // Campos originales para mantener compatibilidad
          ...item
        }));
        break;

      case DOC_ORIGINS.SALIDA:
        normalizedData = data.map(item => ({
          id: `salida-fact-${item.id}`,
          doc_type: DOC_TYPES.FACTURA,
          origen: DOC_ORIGINS.SALIDA,
          codigo: item.codigo_de_factura_salida,
          fecha: item.fecha_salida,
          fecha_formateada: item.fecha_salida ? format(new Date(item.fecha_salida), 'dd/MM/yyyy') : '',
          monto: item.monto_salida,
          moneda: item.moneda_salida,
          monto_formateado: formatCurrency(item.monto_salida, item.moneda_salida),
          imagen: item.imagen_factura_salida ? formatImageUrl(item.imagen_factura_salida) : null,
          caja: item.caja_origen_salida,
          concepto: item.concepto_salidas,
          proyecto: item.codigo_de_proyecto_salida || '',
          proveedor: item.proveedor_salida,
          nombre_factura: item.factura_a_nombre_de_salida,
          trabajador: "N/A",
          // Valores formateados para display
          origen_display: "Salida",
          tipo_display: "Factura",
          // IDs para uso en la lógica de edición
          tabla_origen: 'salidas',
          id_original: item.id,
          // Campos originales para mantener compatibilidad
          ...item
        }));
        break;

      case DOC_ORIGINS.CAMBIO:
        normalizedData = data.map(item => ({
          id: `cambio-fact-${item.id}`,
          doc_type: DOC_TYPES.FACTURA,
          origen: DOC_ORIGINS.CAMBIO,
          codigo: item.codigo_de_factura_cambio || item.codigo_de_comprobante_cambio,
          fecha: item.fecha_cambio,
          fecha_formateada: item.fecha_cambio ? format(new Date(item.fecha_cambio), 'dd/MM/yyyy') : '',
          monto: item.monto_inicial_cambio,
          moneda: item.moneda_inicial_cambio,
          monto_formateado: formatCurrency(item.monto_inicial_cambio, item.moneda_inicial_cambio),
          imagen: item.imagen_factura_cambio ? formatImageUrl(item.imagen_factura_cambio) : null,
          caja: item.caja_cambio,
          concepto: `Cambio ${item.moneda_inicial_cambio} → ${item.moneda_final_cambio}`,
          proyecto: '',  // Los cambios de moneda no tienen proyecto
          proveedor: "Casa de cambio",
          nombre_factura: item.factura_a_nombre_de_cambio || "Empresa",
          trabajador: "N/A",
          // Valores formateados para display
          origen_display: "Cambio",
          tipo_display: "Factura",
          // IDs para uso en la lógica de edición
          tabla_origen: 'cambios_de_moneda',
          id_original: item.id,
          // Campos originales para mantener compatibilidad
          ...item
        }));
        break;

      case DOC_ORIGINS.COBRO:
        normalizedData = data.map(item => ({
          id: `cobro-fact-${item.id}`,
          doc_type: DOC_TYPES.FACTURA,
          origen: DOC_ORIGINS.COBRO,
          codigo: item.codigo_de_factura_cobro,
          fecha: item.fecha,
          fecha_formateada: item.fecha ? format(new Date(item.fecha), 'dd/MM/yyyy') : '',
          monto: item.monto_cobro,
          moneda: item.moneda_cobro,
          monto_formateado: formatCurrency(item.monto_cobro, item.moneda_cobro),
          imagen: item.imagen_factura_cobro ? formatImageUrl(item.imagen_factura_cobro) : null,
          caja: item.caja_origen_cobro,
          concepto: item.descripción_cobro || "Cobro a trabajador",
          proyecto: '',  // Cobros no tienen proyecto generalmente
          proveedor: "N/A",
          nombre_factura: item.factura_a_nombre_de_cobro || "Empresa",
          trabajador: item.trabajador_cobro || "",
          // Valores formateados para display
          origen_display: "Cobro",
          tipo_display: "Factura",
          // IDs para uso en la lógica de edición
          tabla_origen: 'cobro_trabajadores',
          id_original: item.id,
          // Campos originales para mantener compatibilidad
          ...item
        }));
        break;

      default:
        break;
    }

    return normalizedData;
  };

  const handleSearchDocumentos = async () => {
    setLoading(true);
    try {
      let allDocumentos = [];

      // Determinar qué tipos de documentos incluir
      const includeComprobantes = documentType === 'todos' || documentType === 'comprobantes';
      const includeFacturas = documentType === 'todos' || documentType === 'facturas';

      // Filtro de origen
      const origensABuscar = filters.origen
        ? [filters.origen]
        : [DOC_ORIGINS.ENTRADA, DOC_ORIGINS.CAMBIO, DOC_ORIGINS.SALIDA, DOC_ORIGINS.COBRO];

      // ========== BÚSQUEDA DE COMPROBANTES ==========
      if (includeComprobantes) {
        for (const origen of origensABuscar) {
          let data = [];

          if (origen === DOC_ORIGINS.ENTRADA) {
            // Buscar comprobantes en entradas
            let query = supabase
              .from('entradas')
              .select('*')
              .eq('tiene_comprobante_entrada', true);

            // Aplicar filtros específicos para entradas
            if (filters.codigo) {
              query = query.ilike('codigo_de_comprobante_entrada', `%${filters.codigo}%`);
            }
            if (filters.proyecto) {
              query = query.eq('codigo_de_proyecto_entrada', filters.proyecto);
            }
            if (filters.caja_origen) {
              query = query.eq('caja_origen_entrada', filters.caja_origen);
            }
            if (filters.concepto) {
              query = query.eq('concepto_entrada', filters.concepto);
            }
            if (filters.fecha_desde) {
              const fechaDesdeFmt = format(new Date(filters.fecha_desde), 'yyyy-MM-dd');
              query = query.gte('fecha_entrada', fechaDesdeFmt);
            }
            if (filters.fecha_hasta) {
              const fechaHasta = new Date(filters.fecha_hasta);
              fechaHasta.setDate(fechaHasta.getDate() + 1);
              const fechaHastaFmt = format(fechaHasta, 'yyyy-MM-dd');
              query = query.lt('fecha_entrada', fechaHastaFmt);
            }

            // Ordenar según la selección actual
            switch (sortBy) {
              case 'date_desc':
                query = query.order('fecha_entrada', { ascending: false });
                break;
              case 'date_asc':
                query = query.order('fecha_entrada', { ascending: true });
                break;
              case 'amount_desc':
                query = query.order('monto_entrada', { ascending: false });
                break;
              case 'amount_asc':
                query = query.order('monto_entrada', { ascending: true });
                break;
              default:
                query = query.order('fecha_entrada', { ascending: false });
            }

            const { data: entradasData, error } = await query.limit(50);
            if (error) {
              console.error('Error fetching comprobantes from entradas:', error.message);
            } else {
              data = entradasData;
            }

            // Normalizar y agregar a la lista total
            const normalizedData = normalizeComprobanteData(data, DOC_ORIGINS.ENTRADA);
            allDocumentos = [...allDocumentos, ...normalizedData];
          }

          if (origen === DOC_ORIGINS.CAMBIO) {
            // Buscar comprobantes en cambios de moneda
            try {
              let query = supabase
                .from('cambios_de_moneda')
                .select('*')
                .eq('tiene_comprobante_cambio', true);

              // Aplicar filtros específicos para cambios
              if (filters.codigo) {
                query = query.ilike('codigo_de_comprobante_cambio', `%${filters.codigo}%`);
              }
              if (filters.caja_origen) {
                query = query.eq('caja_cambio', filters.caja_origen);
              }
              if (filters.fecha_desde) {
                const fechaDesdeFmt = format(new Date(filters.fecha_desde), 'yyyy-MM-dd');
                query = query.gte('fecha_cambio', fechaDesdeFmt);
              }
              if (filters.fecha_hasta) {
                const fechaHasta = new Date(filters.fecha_hasta);
                fechaHasta.setDate(fechaHasta.getDate() + 1);
                const fechaHastaFmt = format(fechaHasta, 'yyyy-MM-dd');
                query = query.lt('fecha_cambio', fechaHastaFmt);
              }

              // Ordenar según la selección actual
              switch (sortBy) {
                case 'date_desc':
                  query = query.order('fecha_cambio', { ascending: false });
                  break;
                case 'date_asc':
                  query = query.order('fecha_cambio', { ascending: true });
                  break;
                case 'amount_desc':
                  query = query.order('monto_inicial_cambio', { ascending: false });
                  break;
                case 'amount_asc':
                  query = query.order('monto_inicial_cambio', { ascending: true });
                  break;
                default:
                  query = query.order('fecha_cambio', { ascending: false });
              }

              const { data: cambiosData, error } = await query.limit(50);
              if (error) {
                console.error('Error fetching comprobantes from cambios:', error.message);
              } else {
                data = cambiosData;
              }

              // Normalizar y agregar a la lista total
              const normalizedData = normalizeComprobanteData(data, DOC_ORIGINS.CAMBIO);
              allDocumentos = [...allDocumentos, ...normalizedData];
            } catch (error) {
              console.error('Error with cambios query:', error);
            }
          }

          if (origen === DOC_ORIGINS.SALIDA) {
            // Buscar comprobantes en salidas
            let query = supabase
              .from('salidas')
              .select('*')
              .eq('tiene_comprobante_salida', true);

            // Aplicar filtros específicos para salidas
            if (filters.codigo) {
              query = query.ilike('codigo_de_comprobante_salida', `%${filters.codigo}%`);
            }
            if (filters.proyecto) {
              query = query.eq('codigo_de_proyecto_salida', filters.proyecto);
            }
            if (filters.caja_origen) {
              query = query.eq('caja_origen_salida', filters.caja_origen);
            }
            if (filters.concepto) {
              query = query.eq('concepto_salidas', filters.concepto);
            }
            if (filters.proveedor) {
              query = query.eq('proveedor_salida', filters.proveedor);
            }
            if (filters.fecha_desde) {
              const fechaDesdeFmt = format(new Date(filters.fecha_desde), 'yyyy-MM-dd');
              query = query.gte('fecha_salida', fechaDesdeFmt);
            }
            if (filters.fecha_hasta) {
              const fechaHasta = new Date(filters.fecha_hasta);
              fechaHasta.setDate(fechaHasta.getDate() + 1);
              const fechaHastaFmt = format(fechaHasta, 'yyyy-MM-dd');
              query = query.lt('fecha_salida', fechaHastaFmt);
            }

            // Ordenar según la selección actual
            switch (sortBy) {
              case 'date_desc':
                query = query.order('fecha_salida', { ascending: false });
                break;
              case 'date_asc':
                query = query.order('fecha_salida', { ascending: true });
                break;
              case 'amount_desc':
                query = query.order('monto_salida', { ascending: false });
                break;
              case 'amount_asc':
                query = query.order('monto_salida', { ascending: true });
                break;
              default:
                query = query.order('fecha_salida', { ascending: false });
            }

            const { data: salidasData, error } = await query.limit(50);
            if (error) {
              console.error('Error fetching comprobantes from salidas:', error.message);
            } else {
              data = salidasData;
            }

            // Normalizar y agregar a la lista total
            const normalizedData = normalizeComprobanteData(data, DOC_ORIGINS.SALIDA);
            allDocumentos = [...allDocumentos, ...normalizedData];
          }

          if (origen === DOC_ORIGINS.COBRO) {
            // Buscar comprobantes en cobros de trabajadores
            try {
              let query = supabase
                .from('cobro_trabajadores')
                .select('*')
                .eq('tiene_comprobante_cobro', true);

              // Aplicar filtros específicos para cobros
              if (filters.codigo) {
                query = query.ilike('codigo_de_comprobante_cobro', `%${filters.codigo}%`);
              }
              if (filters.caja_origen) {
                query = query.eq('caja_origen_cobro', filters.caja_origen);
              }
              if (filters.trabajador) {
                query = query.eq('trabajador_cobro', filters.trabajador);
              }
              if (filters.fecha_desde) {
                const fechaDesdeFmt = format(new Date(filters.fecha_desde), 'yyyy-MM-dd');
                query = query.gte('fecha', fechaDesdeFmt);
              }
              if (filters.fecha_hasta) {
                const fechaHasta = new Date(filters.fecha_hasta);
                fechaHasta.setDate(fechaHasta.getDate() + 1);
                const fechaHastaFmt = format(fechaHasta, 'yyyy-MM-dd');
                query = query.lt('fecha', fechaHastaFmt);
              }

              // Ordenar según la selección actual
              switch (sortBy) {
                case 'date_desc':
                  query = query.order('fecha', { ascending: false });
                  break;
                case 'date_asc':
                  query = query.order('fecha', { ascending: true });
                  break;
                case 'amount_desc':
                  query = query.order('monto_cobro', { ascending: false });
                  break;
                case 'amount_asc':
                  query = query.order('monto_cobro', { ascending: true });
                  break;
                default:
                  query = query.order('fecha', { ascending: false });
              }

              const { data: cobrosData, error } = await query.limit(50);
              if (error) {
                console.error('Error fetching comprobantes from cobros:', error.message);
              } else {
                data = cobrosData;
              }

              // Normalizar y agregar a la lista total
              const normalizedData = normalizeComprobanteData(data, DOC_ORIGINS.COBRO);
              allDocumentos = [...allDocumentos, ...normalizedData];
            } catch (error) {
              console.error('Error with cobros query:', error);
            }
          }
        }
      }

      // ========== BÚSQUEDA DE FACTURAS ==========
      if (includeFacturas) {
        for (const origen of origensABuscar) {
          let data = [];

          if (origen === DOC_ORIGINS.ENTRADA) {
            // Buscar facturas en entradas (NUEVO)
            let query = supabase
              .from('entradas')
              .select('*')
              .eq('tiene_factura_entrada', true);

            // Aplicar filtros específicos para entradas
            if (filters.codigo) {
              query = query.ilike('codigo_de_factura_entrada', `%${filters.codigo}%`);
            }
            if (filters.proyecto) {
              query = query.eq('codigo_de_proyecto_entrada', filters.proyecto);
            }
            if (filters.caja_origen) {
              query = query.eq('caja_origen_entrada', filters.caja_origen);
            }
            if (filters.concepto) {
              query = query.eq('concepto_entrada', filters.concepto);
            }
            if (filters.proveedor) {
              query = query.eq('proveedor_entrada', filters.proveedor);
            }
            if (filters.nombre_factura) {
              query = query.eq('factura_a_nombre_de_entrada', filters.nombre_factura);
            }
            if (filters.fecha_desde) {
              const fechaDesdeFmt = format(new Date(filters.fecha_desde), 'yyyy-MM-dd');
              query = query.gte('fecha_entrada', fechaDesdeFmt);
            }
            if (filters.fecha_hasta) {
              const fechaHasta = new Date(filters.fecha_hasta);
              fechaHasta.setDate(fechaHasta.getDate() + 1);
              const fechaHastaFmt = format(fechaHasta, 'yyyy-MM-dd');
              query = query.lt('fecha_entrada', fechaHastaFmt);
            }

            // Ordenar según la selección actual
            switch (sortBy) {
              case 'date_desc':
                query = query.order('fecha_entrada', { ascending: false });
                break;
              case 'date_asc':
                query = query.order('fecha_entrada', { ascending: true });
                break;
              case 'amount_desc':
                query = query.order('monto_entrada', { ascending: false });
                break;
              case 'amount_asc':
                query = query.order('monto_entrada', { ascending: true });
                break;
              default:
                query = query.order('fecha_entrada', { ascending: false });
            }

            const { data: entradasData, error } = await query.limit(50);
            if (error) {
              console.error('Error fetching facturas from entradas:', error.message);
            } else {
              data = entradasData;
            }

            // Normalizar y agregar a la lista total
            const normalizedData = normalizeFacturaData(data, DOC_ORIGINS.ENTRADA);
            allDocumentos = [...allDocumentos, ...normalizedData];
          }

          if (origen === DOC_ORIGINS.SALIDA) {
            // Buscar facturas en salidas
            let query = supabase
              .from('salidas')
              .select('*')
              .eq('tiene_factura_salida', true);

            // Aplicar filtros específicos para salidas
            if (filters.codigo) {
              query = query.ilike('codigo_de_factura_salida', `%${filters.codigo}%`);
            }
            if (filters.proyecto) {
              query = query.eq('codigo_de_proyecto_salida', filters.proyecto);
            }
            if (filters.caja_origen) {
              query = query.eq('caja_origen_salida', filters.caja_origen);
            }
            if (filters.proveedor) {
              query = query.eq('proveedor_salida', filters.proveedor);
            }
            if (filters.nombre_factura) {
              query = query.eq('factura_a_nombre_de_salida', filters.nombre_factura);
            }
            if (filters.concepto) {
              query = query.eq('concepto_salidas', filters.concepto);
            }
            if (filters.fecha_desde) {
              const fechaDesdeFmt = format(new Date(filters.fecha_desde), 'yyyy-MM-dd');
              query = query.gte('fecha_salida', fechaDesdeFmt);
            }
            if (filters.fecha_hasta) {
              const fechaHasta = new Date(filters.fecha_hasta);
              fechaHasta.setDate(fechaHasta.getDate() + 1);
              const fechaHastaFmt = format(fechaHasta, 'yyyy-MM-dd');
              query = query.lt('fecha_salida', fechaHastaFmt);
            }

            // Ordenar según la selección actual
            switch (sortBy) {
              case 'date_desc':
                query = query.order('fecha_salida', { ascending: false });
                break;
              case 'date_asc':
                query = query.order('fecha_salida', { ascending: true });
                break;
              case 'amount_desc':
                query = query.order('monto_salida', { ascending: false });
                break;
              case 'amount_asc':
                query = query.order('monto_salida', { ascending: true });
                break;
              default:
                query = query.order('fecha_salida', { ascending: false });
            }

            const { data: salidasData, error } = await query.limit(50);
            if (error) {
              console.error('Error fetching facturas from salidas:', error.message);
            } else {
              data = salidasData;
            }

            // Normalizar y agregar a la lista total
            const normalizedData = normalizeFacturaData(data, DOC_ORIGINS.SALIDA);
            allDocumentos = [...allDocumentos, ...normalizedData];
          }

          if (origen === DOC_ORIGINS.CAMBIO) {
            // Buscar facturas en cambios de moneda (si existe la tabla y tiene facturas)
            try {
              let query = supabase
                .from('cambios_de_moneda')
                .select('*')
                .eq('tiene_factura_cambio', true);

              // Aplicar filtros específicos para cambios
              if (filters.codigo) {
                query = query.ilike('codigo_de_factura_cambio', `%${filters.codigo}%`);
              }
              if (filters.caja_origen) {
                query = query.eq('caja_cambio', filters.caja_origen);
              }
              if (filters.fecha_desde) {
                const fechaDesdeFmt = format(new Date(filters.fecha_desde), 'yyyy-MM-dd');
                query = query.gte('fecha_cambio', fechaDesdeFmt);
              }
              if (filters.fecha_hasta) {
                const fechaHasta = new Date(filters.fecha_hasta);
                fechaHasta.setDate(fechaHasta.getDate() + 1);
                const fechaHastaFmt = format(fechaHasta, 'yyyy-MM-dd');
                query = query.lt('fecha_cambio', fechaHastaFmt);
              }

              // Ordenar según la selección actual
              switch (sortBy) {
                case 'date_desc':
                  query = query.order('fecha_cambio', { ascending: false });
                  break;
                case 'date_asc':
                  query = query.order('fecha_cambio', { ascending: true });
                  break;
                case 'amount_desc':
                  query = query.order('monto_inicial_cambio', { ascending: false });
                  break;
                case 'amount_asc':
                  query = query.order('monto_inicial_cambio', { ascending: true });
                  break;
                default:
                  query = query.order('fecha_cambio', { ascending: false });
              }

              const { data: cambiosData, error } = await query.limit(50);
              if (error) {
                console.error('Error fetching facturas from cambios:', error.message);
              } else {
                data = cambiosData;
              }

              // Normalizar y agregar a la lista total
              const normalizedData = normalizeFacturaData(data, DOC_ORIGINS.CAMBIO);
              allDocumentos = [...allDocumentos, ...normalizedData];
            } catch (error) {
              console.error('Error with cambios query:', error);
            }
          }

          if (origen === DOC_ORIGINS.COBRO) {
            // Buscar facturas en cobros de trabajadores
            try {
              let query = supabase
                .from('cobro_trabajadores')
                .select('*')
                .eq('tiene_factura_cobro', true);

              // Aplicar filtros específicos para cobros
              if (filters.codigo) {
                query = query.ilike('codigo_de_factura_cobro', `%${filters.codigo}%`);
              }
              if (filters.caja_origen) {
                query = query.eq('caja_origen_cobro', filters.caja_origen);
              }
              if (filters.trabajador) {
                query = query.eq('trabajador_cobro', filters.trabajador);
              }
              if (filters.nombre_factura) {
                query = query.eq('factura_a_nombre_de_cobro', filters.nombre_factura);
              }
              if (filters.fecha_desde) {
                const fechaDesdeFmt = format(new Date(filters.fecha_desde), 'yyyy-MM-dd');
                query = query.gte('fecha', fechaDesdeFmt);
              }
              if (filters.fecha_hasta) {
                const fechaHasta = new Date(filters.fecha_hasta);
                fechaHasta.setDate(fechaHasta.getDate() + 1);
                const fechaHastaFmt = format(fechaHasta, 'yyyy-MM-dd');
                query = query.lt('fecha', fechaHastaFmt);
              }

              // Ordenar según la selección actual
              switch (sortBy) {
                case 'date_desc':
                  query = query.order('fecha', { ascending: false });
                  break;
                case 'date_asc':
                  query = query.order('fecha', { ascending: true });
                  break;
                case 'amount_desc':
                  query = query.order('monto_cobro', { ascending: false });
                  break;
                case 'amount_asc':
                  query = query.order('monto_cobro', { ascending: true });
                  break;
                default:
                  query = query.order('fecha', { ascending: false });
              }

              const { data: cobrosData, error } = await query.limit(50);
              if (error) {
                console.error('Error fetching facturas from cobros:', error.message);
              } else {
                data = cobrosData;
              }

              // Normalizar y agregar a la lista total
              const normalizedData = normalizeFacturaData(data, DOC_ORIGINS.COBRO);
              allDocumentos = [...allDocumentos, ...normalizedData];
            } catch (error) {
              console.error('Error with cobros query:', error);
            }
          }
        }
      }

      // Aplicar filtros activos
      if (activeFilters.length > 0) {
        allDocumentos = applyActiveFilters(allDocumentos);
      }

      // Ordenar todos los documentos juntos según el criterio seleccionado
      switch (sortBy) {
        case 'date_desc':
          allDocumentos.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
          break;
        case 'date_asc':
          allDocumentos.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
          break;
        case 'amount_desc':
          allDocumentos.sort((a, b) => parseFloat(b.monto) - parseFloat(a.monto));
          break;
        case 'amount_asc':
          allDocumentos.sort((a, b) => parseFloat(a.monto) - parseFloat(b.monto));
          break;
        default:
          allDocumentos.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
      }

      setDocumentos(allDocumentos);

    } catch (error) {
      console.error('Error searching documentos:', error);
      setSnackbar({
        open: true,
        message: 'Error al procesar la búsqueda',
        severity: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  // Aplicar filtros activos a documentos (separado para mayor claridad)
  const applyActiveFilters = (docs) => {
    let filtered = [...docs];

    activeFilters.forEach(filter => {
      if (filter.type === 'fecha_desde' && filter.value) {
        filtered = filtered.filter(doc =>
          new Date(doc.fecha) >= new Date(filter.value)
        );
      } else if (filter.type === 'fecha_hasta' && filter.value) {
        filtered = filtered.filter(doc =>
          new Date(doc.fecha) <= new Date(filter.value)
        );
      } else if (filter.type === 'codigo' && filter.value) {
        filtered = filtered.filter(doc =>
          doc.codigo && doc.codigo.toLowerCase().includes(filter.value.toLowerCase())
        );
      } else if (filter.type === 'tipo_documento' && filter.value) {
        filtered = filtered.filter(doc => doc.doc_type === filter.value);
      } else if (filter.type === 'origen' && filter.value) {
        filtered = filtered.filter(doc => doc.origen === filter.value);
      } else if (filter.value) {
        const fieldMap = {
          caja_origen: 'caja',
          proveedor: 'proveedor',
          trabajador: 'trabajador',
          proyecto: 'proyecto',
          nombre_factura: 'nombre_factura',
          concepto: 'concepto',
        };
        const field = fieldMap[filter.type];
        if (field) {
          filtered = filtered.filter(doc => doc[field] === filter.value);
        }
      }
    });

    return filtered;
  };

  const formatCurrency = (amount, currency) => {
    if (!amount) return '';
    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currency === 'USD' ? 'USD' : 'ARS',
      minimumFractionDigits: 2
    });
    return formatter.format(amount);
  };

  const handleDownload = async (e, documento) => {
    // Detener evento completamente
    e.preventDefault();
    e.stopPropagation();

    try {
      // URL de la imagen
      const imageUrl = documento.imagen.split('?')[0];

      // Nombre del archivo - evitamos la duplicación de prefijos
      let fileName;

      // Si el código ya contiene el prefijo del proyecto, lo usamos directamente
      if (documento.codigo && documento.proyecto && documento.codigo.startsWith(documento.proyecto)) {
        fileName = `${documento.codigo}.jpg`;
      } else {
        // Si no, lo añadimos
        const projectCode = documento.proyecto || "";
        const projectPrefix = projectCode ? `${projectCode}_` : "";
        fileName = `${projectPrefix}${documento.codigo || format(new Date(), 'yyyyMMdd')}.jpg`;
      }

      // Añadir origen y tipo al nombre para claridad
      fileName = `${documento.origen_display}_${documento.tipo_display}_${fileName}`;

      // Intentar guardar directamente en el dispositivo usando la File System API (para PWA)
      try {
        // Usar fetch para obtener los datos como blob
        const response = await fetch(imageUrl);
        const blob = await response.blob();

        // Intentar guardar directamente a fotos (sólo funciona en algunos navegadores móviles)
        if (navigator.mediaDevices && window.showSaveFilePicker) {
          const fileHandle = await window.showSaveFilePicker({
            suggestedName: fileName,
            types: [{
              description: 'Images',
              accept: { 'image/jpeg': ['.jpg'] }
            }]
          });
          const writable = await fileHandle.createWritable();
          await writable.write(blob);
          await writable.close();
        } else {
          // Fallback a saveAs de file-saver para otros navegadores
          saveAs(blob, fileName);
        }

        // Notificar éxito
        setSnackbar({
          open: true,
          message: 'Imagen descargada exitosamente',
          severity: 'success'
        });
      } catch (downloadError) {
        console.error('Error con API nativa, usando fallback:', downloadError);

        // Fallback para navegadores que no soportan la API de File System
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        saveAs(blob, fileName);

        setSnackbar({
          open: true,
          message: 'Imagen descargada exitosamente',
          severity: 'success'
        });
      }
    } catch (error) {
      console.error('Error descargando imagen:', error);
      setSnackbar({
        open: true,
        message: 'Error al descargar la imagen',
        severity: 'error'
      });
    }

    return false;
  };

  const handleShowImage = (documento) => {
    // Asegurarnos de que el código tenga el prefijo del proyecto correcto
    const projectCode = documento.proyecto || "";
    let codigoMostrar = documento.codigo;

    if (!codigoMostrar) {
      const projectPrefix = projectCode ? `${projectCode}_` : "";
      codigoMostrar = `${projectPrefix}${format(new Date(), 'yyyyMMdd')}-${Math.floor(Math.random() * 100000)}`;
    }

    setCurrentImage({
      url: documento.imagen,
      codigo: codigoMostrar,
      fecha: documento.fecha_formateada,
      concepto: documento.concepto,
      monto: documento.monto_formateado,
      caja: documento.caja,
      proveedor: documento.proveedor,
      proyecto: documento.proyecto,
      nombre_factura: documento.nombre_factura,
      trabajador: documento.trabajador,
      origen: documento.origen_display,
      tipo_documento: documento.tipo_display,
      origen_code: documento.origen,
      doc_type_code: documento.doc_type
    });
    setOpenPreview(true);
  };

  const resetFilters = () => {
    setFilters({
      codigo: '',
      proyecto: '',
      caja_origen: '',
      proveedor: '',
      trabajador: '',
      nombre_factura: '',
      concepto: '',
      fecha_desde: null,
      fecha_hasta: null,
      origen: '',
      tipo_documento: '',
    });
    setActiveFilters([]);
    setIsFilterDrawerOpen(false);
    handleSearchDocumentos();
  };

  const addFilter = () => {
    const newFilters = [];

    if (filters.origen) {
      newFilters.push({ type: 'origen', value: filters.origen, label: `Origen: ${filters.origen === 'entrada' ? 'Entrada' : filters.origen === 'salida' ? 'Salida' : filters.origen === 'cambio' ? 'Cambio' : 'Cobro'}` });
    }

    if (filters.tipo_documento) {
      newFilters.push({ type: 'tipo_documento', value: filters.tipo_documento, label: `Tipo: ${filters.tipo_documento === DOC_TYPES.COMPROBANTE ? 'Comprobante' : 'Factura'}` });
    }

    if (filters.caja_origen) {
      newFilters.push({ type: 'caja_origen', value: filters.caja_origen, label: `Caja: ${filters.caja_origen}` });
    }

    if (filters.proveedor) {
      newFilters.push({ type: 'proveedor', value: filters.proveedor, label: `Proveedor: ${filters.proveedor}` });
    }

    if (filters.trabajador) {
      newFilters.push({ type: 'trabajador', value: filters.trabajador, label: `Trabajador: ${filters.trabajador}` });
    }

    if (filters.proyecto) {
      newFilters.push({ type: 'proyecto', value: filters.proyecto, label: `Proyecto: ${filters.proyecto}` });
    }

    if (filters.nombre_factura) {
      newFilters.push({ type: 'nombre_factura', value: filters.nombre_factura, label: `A nombre de: ${filters.nombre_factura}` });
    }

    if (filters.concepto) {
      newFilters.push({ type: 'concepto', value: filters.concepto, label: `Concepto: ${filters.concepto}` });
    }

    if (filters.fecha_desde) {
      newFilters.push({ type: 'fecha_desde', value: filters.fecha_desde, label: `Desde: ${format(new Date(filters.fecha_desde), 'dd/MM/yyyy')}` });
    }

    if (filters.fecha_hasta) {
      newFilters.push({ type: 'fecha_hasta', value: filters.fecha_hasta, label: `Hasta: ${format(new Date(filters.fecha_hasta), 'dd/MM/yyyy')}` });
    }

    setActiveFilters([...activeFilters, ...newFilters]);

    // Resetear los filtros
    setFilters({
      codigo: '',
      proyecto: '',
      caja_origen: '',
      proveedor: '',
      trabajador: '',
      nombre_factura: '',
      concepto: '',
      fecha_desde: null,
      fecha_hasta: null,
      origen: '',
      tipo_documento: '',
    });

    setIsFilterDrawerOpen(false);
    handleSearchDocumentos();
  };

  const removeFilter = (index) => {
    const updatedFilters = [...activeFilters];
    updatedFilters.splice(index, 1);
    setActiveFilters(updatedFilters);
    handleSearchDocumentos();
  };

  const removeAllFilters = () => {
    setActiveFilters([]);
    handleSearchDocumentos();
  };

  // Efecto para aplicar filtros activos
  useEffect(() => {
    if (activeFilters.length > 0) {
      handleSearchDocumentos();
    }
  }, [activeFilters]);

  const getFoldersByType = (type) => {
    // Obtiene carpetas únicas basadas en el tipo (caja o nombre)
    const uniqueFolders = [];

    if (type === 'caja') {
      documentos.forEach(doc => {
        if (doc.caja && !uniqueFolders.includes(doc.caja)) {
          uniqueFolders.push(doc.caja);
        }
      });
    } else if (type === 'nombre') {
      // Solo incluir nombre si estamos en facturas
      if (documentType === 'facturas' || documentType === 'todos') {
        documentos.forEach(doc => {
          // Solo include nombres de facturas
          if (doc.doc_type === DOC_TYPES.FACTURA && doc.nombre_factura && !uniqueFolders.includes(doc.nombre_factura)) {
            uniqueFolders.push(doc.nombre_factura);
          }
        });
      }
    }

    return uniqueFolders.sort();
  };

  const handleFolderSelect = (folder, type) => {
    setSelectedFolder(folder);

    if (type === 'caja') {
      setFilters({
        ...filters,
        caja_origen: folder,
        nombre_factura: ''
      });
    } else if (type === 'nombre') {
      setFilters({
        ...filters,
        nombre_factura: folder,
        caja_origen: ''
      });
    }

    handleSearchDocumentos();
  };

  // Función para descargar todas las imágenes filtradas
  const downloadAllImages = async () => {
    const filteredDocumentos = [...documentos];

    // Aplicar filtros si hay una carpeta seleccionada
    let documentosToDownload = filteredDocumentos;
    if (selectedFolder && tabValue === 2) {
      documentosToDownload = filteredDocumentos.filter(
        doc => doc.caja === selectedFolder
      );
    } else if (selectedFolder && tabValue === 3) {
      documentosToDownload = filteredDocumentos.filter(
        doc => doc.nombre_factura === selectedFolder
      );
    }

    if (documentosToDownload.length === 0) {
      setSnackbar({
        open: true,
        message: 'No hay documentos para descargar',
        severity: 'info'
      });
      return;
    }

    try {
      setLoading(true);

      // Crear un nuevo ZIP
      const zip = new JSZip();
      const imagesFolder = zip.folder("documentos");

      // Contador para tracking de progreso
      let completedCount = 0;

      // Función para actualizar la UI cuando se completa todo
      const checkCompletion = () => {
        completedCount++;
        if (completedCount === documentosToDownload.length) {
          zip.generateAsync({ type: "blob" })
            .then(content => {
              saveAs(content, `documentos_${format(new Date(), 'yyyyMMdd')}.zip`);
              setLoading(false);
              setSnackbar({
                open: true,
                message: `${completedCount} documentos descargados exitosamente`,
                severity: 'success'
              });
            });
        }
      };

      // Procesar cada documento
      for (const documento of documentosToDownload) {
        if (!documento.imagen) {
          completedCount++;
          continue;
        }

        try {
          // Limpiar la URL (quitar parámetros de cache si existen)
          const cleanUrl = documento.imagen.split('?')[0];

          // Obtener la imagen
          const response = await fetch(cleanUrl);
          if (!response.ok) {
            console.error(`Error al descargar imagen: ${cleanUrl}`);
            checkCompletion();
            continue;
          }

          const blob = await response.blob();

          // Generar un nombre de archivo descriptivo
          let fileName = `${documento.codigo || 'documento'}.jpg`;

          // Si tiene proyecto, añadirlo al nombre
          if (documento.proyecto) {
            fileName = `${documento.proyecto}_${fileName}`;
          }

          // Añadir origen y tipo al nombre para claridad
          fileName = `${documento.origen_display}_${documento.tipo_display}_${fileName}`;

          // Añadir al ZIP
          imagesFolder.file(fileName, blob);
          checkCompletion();
        } catch (error) {
          console.error(`Error procesando documento ${documento.id}:`, error);
          checkCompletion();
        }
      }
    } catch (error) {
      console.error('Error al crear el archivo ZIP:', error);
      setSnackbar({
        open: true,
        message: 'Error al descargar los documentos',
        severity: 'error'
      });
      setLoading(false);
    }
  };

  // Renderiza la vista de carpetas
  const renderFolders = () => {
    let folders = [];
    let folderType = '';

    if (tabValue === 2) { // Caja
      folders = getFoldersByType('caja');
      folderType = 'caja';
    } else if (tabValue === 3 && (documentType === 'facturas' || documentType === 'todos')) { // Nombre (solo facturas)
      folders = getFoldersByType('nombre');
      folderType = 'nombre';
    } else {
      return null;
    }

    return (
      <Grid container spacing={2} sx={{ mt: 2 }}>
        {folders.map((folder) => (
          <Grid item xs={6} sm={4} key={folder}>
            <motion.div
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              <Card
                sx={{
                  cursor: 'pointer',
                  backgroundColor: folder === selectedFolder ? '#e8f5e9' : 'white',
                  transition: 'all 0.3s ease',
                  boxShadow: folder === selectedFolder ? '0 4px 20px rgba(46, 125, 50, 0.2)' : '0 2px 10px rgba(0,0,0,0.05)',
                  '&:hover': {
                    boxShadow: '0 6px 20px rgba(0,0,0,0.1)',
                  },
                  borderRadius: 4
                }}
                onClick={() => handleFolderSelect(folder, folderType)}
              >
                <CardContent sx={{ textAlign: 'center', p: 2 }}>
                  <FolderIcon sx={{ fontSize: 46, color: '#2e7d32', mb: 1 }} />
                  <Typography variant="subtitle2" noWrap fontWeight={folder === selectedFolder ? 'bold' : 'regular'}>
                    {folder}
                  </Typography>
                </CardContent>
              </Card>
            </motion.div>
          </Grid>
        ))}
      </Grid>
    );
  };

  // Renderiza la vista de grid o lista según la selección
  const renderDocumentos = () => {
    // Si está en vista de carpetas y hay una carpeta seleccionada, mostrar solo documentos de esa carpeta
    let filteredDocumentos = [...documentos];

    if (selectedFolder && tabValue === 2) {
      filteredDocumentos = filteredDocumentos.filter(
        doc => doc.caja === selectedFolder
      );
    } else if (selectedFolder && tabValue === 3) {
      filteredDocumentos = filteredDocumentos.filter(
        doc => doc.nombre_factura === selectedFolder
      );
    }

    // Si no hay documentos
    if (filteredDocumentos.length === 0) {
      return (
        <Box sx={{ textAlign: 'center', py: 6 }}>
          {documentType === 'comprobantes' ?
            <ReceiptIcon sx={{ fontSize: 80, color: '#e0e0e0', mb: 2 }} /> :
            documentType === 'facturas' ?
              <DescriptionIcon sx={{ fontSize: 80, color: '#e0e0e0', mb: 2 }} /> :
              <PictureAsPdfIcon sx={{ fontSize: 80, color: '#e0e0e0', mb: 2 }} />
          }
          <Typography variant="h6" color="text.secondary">
            No se encontraron documentos
          </Typography>
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Intenta con otros filtros o realiza una nueva búsqueda
          </Typography>
        </Box>
      );
    }

    // Vista de grid
    if (viewMode === 'grid') {
      return (
        <Grid container spacing={2} ref={listRef}>
          {filteredDocumentos.map((documento) => (
            <Grid item xs={6} sm={4} key={documento.id}>
              <StyledCard>
                <StyledCardMedia onClick={() => handleShowImage(documento)}>
                  {documento.imagen ? (
                    <Box
                      component="img"
                      src={documento.imagen}
                      alt="Documento"
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  ) : (
                    <Box
                      sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                      }}
                    >
                      {documento.doc_type === DOC_TYPES.COMPROBANTE ?
                        <ReceiptIcon sx={{ fontSize: 60, color: '#9e9e9e' }} /> :
                        <DescriptionIcon sx={{ fontSize: 60, color: '#9e9e9e' }} />}
                    </Box>
                  )}
                  <OriginChip
                    label={documento.origen_display}
                    size="small"
                    origin={documento.origen}
                    icon={originIcons[documento.origen]}
                  />
                  <TypeChip
                    label={documento.tipo_display}
                    size="small"
                    docType={documento.doc_type}
                    icon={documento.doc_type === DOC_TYPES.COMPROBANTE ? <ReceiptIcon /> : <DescriptionIcon />}
                  />
                  {documento.codigo && <CodeChip label={documento.codigo} size="small" />}
                  <DownloadButton onClick={(e) => handleDownload(e, documento)}>
                    <DownloadIcon fontSize="small" />
                  </DownloadButton>
                </StyledCardMedia>
                <CardContent sx={{ p: 2, pt: 1.5 }}>
                  <Typography variant="subtitle2" fontWeight="bold" noWrap>
                    {documento.doc_type === DOC_TYPES.COMPROBANTE ? documento.concepto || 'Sin concepto' : documento.proveedor || 'Sin proveedor'}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
                    <Typography variant="caption" color="text.secondary">
                      {documento.fecha_formateada}
                    </Typography>
                    <Typography variant="caption" fontWeight="bold" color="primary">
                      {documento.monto_formateado}
                    </Typography>
                  </Box>
                  {documento.proyecto && (
                    <Chip
                      label={documento.proyecto}
                      size="small"
                      sx={{ mt: 1, fontSize: '0.6rem', height: 20 }}
                    />
                  )}
                </CardContent>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      );
    }

    // Vista de lista
    return (
      <Box ref={listRef}>
        {filteredDocumentos.map((documento) => (
          <motion.div
            key={documento.id}
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.99 }}
          >
            <Paper
              sx={{
                mb: 2,
                borderRadius: 3,
                position: 'relative',
                overflow: 'hidden',
                backgroundColor: '#fff',
                transition: 'all 0.3s ease',
                '&:hover': {
                  boxShadow: '0 4px 15px rgba(0,0,0,0.1)',
                },
              }}
            >
              <Grid container>
                <Grid item xs={4}>
                  <Box sx={{ position: 'relative', paddingTop: '75%' }}>
                    {documento.imagen ? (
                      <Box
                        component="img"
                        src={documento.imagen}
                        alt="Documento"
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                        onClick={() => handleShowImage(documento)}
                      />
                    ) : (
                      <Box
                        sx={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#f5f5f5',
                        }}
                      >
                        {documento.doc_type === DOC_TYPES.COMPROBANTE ?
                          <ReceiptIcon sx={{ fontSize: 40, color: '#9e9e9e' }} /> :
                          <DescriptionIcon sx={{ fontSize: 40, color: '#9e9e9e' }} />}
                      </Box>
                    )}
                    <ListOriginChip
                      label={documento.origen_display}
                      size="small"
                      origin={documento.origen}
                      icon={originIcons[documento.origen]}
                    />
                    <ListTypeChip
                      label={documento.tipo_display}
                      size="small"
                      icon={documento.doc_type === DOC_TYPES.COMPROBANTE ? <ReceiptIcon /> : <DescriptionIcon />}
                      docType={documento.doc_type}
                    />
                    <DownloadButton onClick={(e) => handleDownload(e, documento)}>
                      <DownloadIcon fontSize="small" />
                    </DownloadButton>
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box
                    sx={{ p: 1.5 }}
                    onClick={() => handleShowImage(documento)}
                  >
                    <Typography variant="subtitle2" fontWeight="bold" noWrap>
                      {documento.doc_type === DOC_TYPES.COMPROBANTE ? documento.concepto || 'Sin concepto' : documento.proveedor || 'Sin proveedor'}
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 0.5 }}>
                      <Typography variant="caption" color="text.secondary">
                        {documento.fecha_formateada}
                      </Typography>
                      <Typography variant="caption" fontWeight="bold" color="primary">
                        {documento.monto_formateado}
                      </Typography>
                    </Box>

                    {documento.proyecto && (
                      <Chip
                        label={documento.proyecto}
                        size="small"
                        sx={{ mt: 1, fontSize: '0.6rem', height: 20 }}
                      />
                    )}

                    {documento.codigo && (
                      <Box sx={{ mt: 1 }}>
                        <Typography variant="caption" sx={{ fontFamily: 'monospace' }}>
                          {documento.codigo}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              </Grid>
            </Paper>
          </motion.div>
        ))}
      </Box>
    );
  };

  // Tab buttons - sin íconos para que quepan todos
  const TabButton = ({ active, label, onClick }) => (
    <Button
      variant={active ? "contained" : "outlined"}
      color="primary"
      onClick={onClick}
      sx={{
        borderRadius: 20,
        minWidth: 'auto',
        flex: 1,
        py: 1,
        px: { xs: 1, sm: 2 },
        mr: 1,
        backgroundColor: active ? '#2e7d32' : 'transparent',
        color: active ? 'white' : '#2e7d32',
        borderColor: '#2e7d32',
        fontSize: '0.75rem',
        whiteSpace: 'nowrap',
        '&:last-child': {
          mr: 0
        }
      }}
    >
      {label}
    </Button>
  );

  return (
    <MobileContainer ref={topRef}>
      {/* Encabezado fijo - sin borde */}
      <AppBar position="fixed" color="inherit" elevation={0} sx={{
        top: 0,
        zIndex: 1099,
        backgroundColor: '#f5f5f5'
      }}>
        <Toolbar sx={{ minHeight: 64, px: 2 }}>
          <Typography variant="h6" sx={{ flexGrow: 1, color: '#2e7d32', fontWeight: 'bold' }}>
            Buscador de Documentos
          </Typography>
        </Toolbar>
      </AppBar>

      {/* Selector de tipo de documentos */}
      <DocTypeToggleContainer>
        <StyledToggleButtonGroup
          value={documentType}
          exclusive
          onChange={handleDocTypeChange}
          aria-label="Tipo de documento"
          size="small"
          fullWidth
        >
          <ToggleButton value="todos" aria-label="Todos">
            Todos
          </ToggleButton>
          <ToggleButton value="comprobantes" aria-label="Comprobantes">
            Comprobantes
          </ToggleButton>
          <ToggleButton value="facturas" aria-label="Facturas">
            Facturas
          </ToggleButton>
        </StyledToggleButtonGroup>
      </DocTypeToggleContainer>

      {/* Barra de búsqueda que desaparece al scrollear */}
      <SearchBarWrapper disappear={trigger && !showSearchBarFocus} ref={searchBarRef}>
        <StyledSearchBar>
          <InputAdornment position="start" sx={{ pl: 1 }}>
            <SearchIcon />
          </InputAdornment>
          <InputBase
            placeholder="Buscar por código, proyecto..."
            value={filters.codigo}
            onChange={(e) => setFilters({ ...filters, codigo: e.target.value })}
            onKeyPress={(e) => e.key === 'Enter' && handleSearchDocumentos()}
            fullWidth
            sx={{ ml: 1 }}
            inputRef={searchInputRef}
          />
          {filters.codigo && (
            <IconButton size="small" onClick={() => setFilters({ ...filters, codigo: '' })}>
              <CloseIcon fontSize="small" />
            </IconButton>
          )}
        </StyledSearchBar>
      </SearchBarWrapper>

      {/* Filtros activos */}
      {activeFilters.length > 0 && (
        <Box sx={{ mb: 2, display: 'flex', flexWrap: 'wrap', gap: 0.5, px: 1 }}>
          {activeFilters.map((filter, index) => (
            <FilterChip
              key={index}
              label={filter.label}
              onDelete={() => removeFilter(index)}
              size="small"
            />
          ))}
          {activeFilters.length > 1 && (
            <FilterChip
              label="Limpiar todos"
              onClick={removeAllFilters}
              size="small"
              color="primary"
            />
          )}
        </Box>
      )}

      {/* Botones de navegación de tabs - sin íconos para que quepan todos */}
      <Box sx={{ display: 'flex', overflowX: 'auto', mb: 2, px: 1, pb: 0.5, maxWidth: '100%' }}>
        <TabButton
          active={tabValue === 0}
          label="Todos"
          onClick={() => handleTabChange(0)}
        />
        <TabButton
          active={tabValue === 1}
          label="Recientes"
          onClick={() => handleTabChange(1)}
        />
        <TabButton
          active={tabValue === 2}
          label="Caja"
          onClick={() => handleTabChange(2)}
        />
        {(documentType === 'facturas' || documentType === 'todos') && (
          <TabButton
            active={tabValue === 3}
            label="Nombre"
            onClick={() => handleTabChange(3)}
          />
        )}
      </Box>

      {/* Carpetas si la vista es por caja o por nombre */}
      {renderFolders()}

      {/* Contador de resultados */}
      {documentos.length > 0 && (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, px: 1 }}>
          <Typography variant="subtitle2" color="text.secondary">
            {documentos.length} documento{documentos.length !== 1 ? 's' : ''}
          </Typography>
          <Box sx={{ display: 'flex', gap: 1 }}>
            <ActionButton onClick={() => setViewMode(viewMode === 'grid' ? 'list' : 'grid')}>
              {viewMode === 'grid' ? <ViewListIcon /> : <ViewModuleIcon />}
            </ActionButton>
          </Box>
        </Box>
      )}

      {/* Lista de documentos */}
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', pt: 4, pb: 4 }}>
          <CircularProgress color="primary" />
        </Box>
      ) : (
        renderDocumentos()
      )}

      {/* Botones flotantes que aparecen al hacer scroll */}
      <Zoom in={trigger}>
        <FloatingButtonsContainer>
          <ActionButton onClick={handleSortClick}>
            <SortIcon />
          </ActionButton>
          <ActionButton onClick={() => setIsFilterDrawerOpen(true)}>
            <TuneIcon />
          </ActionButton>
          <ActionButton
            onClick={downloadAllImages}
            disabled={loading || documentos.length === 0}
          >
            <FileDownloadIcon />
          </ActionButton>
          <ActionButton onClick={focusSearchBar}>
            <SearchIcon />
          </ActionButton>
        </FloatingButtonsContainer>
      </Zoom>

      {/* Botón para volver arriba */}
      <Zoom in={trigger}>
        <ScrollTopFab
          size="small"
          color="primary"
          aria-label="scroll back to top"
          onClick={scrollToTop}
        >
          <KeyboardArrowUpIcon />
        </ScrollTopFab>
      </Zoom>

      {/* Diálogo de vista previa de imágenes mejorado con animación y fondo borroso */}
      <DetailBackdrop open={openPreview} onClick={() => setOpenPreview(false)}>
        {currentImage && (
          <DetailContainer
            initial={{ y: "100%" }}
            animate={{ y: 0 }}
            exit={{ y: "100%" }}
            transition={{ type: "spring", damping: 20, stiffness: 300 }}
            onClick={(e) => e.stopPropagation()}
          >
            {/* Imagen del documento */}
            <Box sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              mb: 3,
              pt: 1
            }}>
              <Box sx={{
                position: 'relative',
                width: '100%',
                maxHeight: '50vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                mb: 2,
                borderRadius: 2,
                overflow: 'hidden'
              }}>
                {currentImage.url ? (
                  <Box
                    component="img"
                    src={currentImage.url}
                    alt="Documento"
                    sx={{
                      maxWidth: '100%',
                      maxHeight: '40vh',
                      objectFit: 'contain',
                      borderRadius: 2
                    }}
                  />
                ) : (
                  <Box sx={{
                    width: '100%',
                    height: '30vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: '#f5f5f5',
                    borderRadius: 2
                  }}>
                    {currentImage.doc_type_code === DOC_TYPES.COMPROBANTE ?
                      <ReceiptIcon sx={{ fontSize: 60, color: '#9e9e9e' }} /> :
                      <DescriptionIcon sx={{ fontSize: 60, color: '#9e9e9e' }} />}
                  </Box>
                )}
              </Box>
            </Box>

            {/* Encabezado con tipo y origen */}
            <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center', gap: 1 }}>
              <DetailChip
                label={currentImage.tipo_documento}
                color={currentImage.doc_type_code === DOC_TYPES.COMPROBANTE ? docTypeColors[DOC_TYPES.COMPROBANTE] : docTypeColors[DOC_TYPES.FACTURA]}
                size="small"
                icon={currentImage.doc_type_code === DOC_TYPES.COMPROBANTE ? <ReceiptIcon fontSize="small" /> : <DescriptionIcon fontSize="small" />}
              />
              <DetailChip
                label={currentImage.origen}
                color={originColors[currentImage.origen_code]}
                size="small"
                icon={originIcons[currentImage.origen_code]}
              />
            </Box>

            {/* Datos principales */}
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <DetailInfoSection>
                  <DetailLabel>Fecha</DetailLabel>
                  <DetailValue>{currentImage.fecha}</DetailValue>
                </DetailInfoSection>
              </Grid>
              <Grid item xs={6}>
                <DetailInfoSection>
                  <DetailLabel>Monto</DetailLabel>
                  <DetailValue sx={{ color: '#2e7d32', fontWeight: 'bold' }}>{currentImage.monto}</DetailValue>
                </DetailInfoSection>
              </Grid>

              <Grid item xs={12}>
                <DetailInfoSection>
                  <DetailLabel>Concepto</DetailLabel>
                  <DetailValue>{currentImage.concepto || 'No especificado'}</DetailValue>
                </DetailInfoSection>
              </Grid>

              <Grid item xs={currentImage.proyecto ? 6 : 12}>
                <DetailInfoSection>
                  <DetailLabel>Caja</DetailLabel>
                  <DetailValue>{currentImage.caja || 'No especificada'}</DetailValue>
                </DetailInfoSection>
              </Grid>

              {currentImage.proyecto && (
                <Grid item xs={6}>
                  <DetailInfoSection>
                    <DetailLabel>Proyecto</DetailLabel>
                    <DetailValue>{currentImage.proyecto}</DetailValue>
                  </DetailInfoSection>
                </Grid>
              )}

              {currentImage.proveedor && currentImage.proveedor !== "N/A" && (
                <Grid item xs={currentImage.nombre_factura && currentImage.nombre_factura !== "N/A" ? 6 : 12}>
                  <DetailInfoSection>
                    <DetailLabel>Proveedor</DetailLabel>
                    <DetailValue>{currentImage.proveedor}</DetailValue>
                  </DetailInfoSection>
                </Grid>
              )}

              {currentImage.nombre_factura && currentImage.nombre_factura !== "N/A" && (
                <Grid item xs={currentImage.proveedor && currentImage.proveedor !== "N/A" ? 6 : 12}>
                  <DetailInfoSection>
                    <DetailLabel>A nombre de</DetailLabel>
                    <DetailValue>{currentImage.nombre_factura}</DetailValue>
                  </DetailInfoSection>
                </Grid>
              )}

              {currentImage.trabajador && currentImage.trabajador !== "N/A" && (
                <Grid item xs={12}>
                  <DetailInfoSection>
                    <DetailLabel>Trabajador</DetailLabel>
                    <DetailValue>{currentImage.trabajador}</DetailValue>
                  </DetailInfoSection>
                </Grid>
              )}

              {currentImage.codigo && (
                <Grid item xs={12}>
                  <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: 'rgba(0,0,0,0.03)',
                    p: 1.5,
                    borderRadius: 2,
                    mt: 1
                  }}>
                    <Typography variant="body2" sx={{ fontFamily: 'monospace', fontWeight: 'medium' }}>
                      {currentImage.codigo}
                    </Typography>
                    <IconButton
                      size="small"
                      onClick={() => {
                        navigator.clipboard.writeText(currentImage.codigo);
                        setSnackbar({
                          open: true,
                          message: 'Código copiado al portapapeles',
                          severity: 'success'
                        });
                      }}
                    >
                      <ContentCopyIcon fontSize="small" />
                    </IconButton>
                  </Box>
                </Grid>
              )}
            </Grid>

            {/* Botones de acción */}
            <Box sx={{ mt: 4, display: 'flex', justifyContent: 'space-between', gap: 2 }}>
              <Button
                variant="outlined"
                color="inherit"
                fullWidth
                onClick={() => setOpenPreview(false)}
                sx={{ borderRadius: 28 }}
              >
                Cerrar
              </Button>

              <Button
                variant="contained"
                color="primary"
                fullWidth
                startIcon={<DownloadIcon />}
                onClick={() => {
                  if (currentImage.url) {
                    const link = document.createElement('a');
                    link.href = currentImage.url.split('?')[0]; // Quitar parámetros de caché
                    link.download = `${currentImage.tipo_documento}_${currentImage.origen}_${currentImage.proyecto ? currentImage.proyecto + '_' : ''}${currentImage.codigo}.jpg`;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);

                    setSnackbar({
                      open: true,
                      message: 'Documento descargado exitosamente',
                      severity: 'success'
                    });
                  }
                }}
                sx={{ borderRadius: 28 }}
              >
                Descargar
              </Button>
            </Box>
          </DetailContainer>
        )}
      </DetailBackdrop>

      {/* Drawer para filtros */}
      <FilterDrawer
        anchor="bottom"
        open={isFilterDrawerOpen}
        onClose={() => setIsFilterDrawerOpen(false)}
        onOpen={() => setIsFilterDrawerOpen(true)}
        swipeAreaWidth={56}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Box sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Filtros</Typography>
            <IconButton onClick={() => setIsFilterDrawerOpen(false)}>
              <CloseIcon />
            </IconButton>
          </Box>

          <FormControl fullWidth margin="normal">
            <InputLabel>Origen</InputLabel>
            <Select
              value={filters.origen}
              onChange={(e) => setFilters({ ...filters, origen: e.target.value })}
              label="Origen"
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value={DOC_ORIGINS.ENTRADA}>Entrada</MenuItem>
              <MenuItem value={DOC_ORIGINS.CAMBIO}>Cambio</MenuItem>
              <MenuItem value={DOC_ORIGINS.SALIDA}>Salida</MenuItem>
              <MenuItem value={DOC_ORIGINS.COBRO}>Cobro</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Tipo de Documento</InputLabel>
            <Select
              value={filters.tipo_documento}
              onChange={(e) => setFilters({ ...filters, tipo_documento: e.target.value })}
              label="Tipo de Documento"
            >
              <MenuItem value="">Todos</MenuItem>
              <MenuItem value={DOC_TYPES.COMPROBANTE}>Comprobante</MenuItem>
              <MenuItem value={DOC_TYPES.FACTURA}>Factura</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Proyecto</InputLabel>
            <Select
              value={filters.proyecto}
              onChange={(e) => setFilters({ ...filters, proyecto: e.target.value })}
              label="Proyecto"
            >
              <MenuItem value="">Todos</MenuItem>
              {proyectos.map((proyecto) => (
                <MenuItem key={proyecto} value={proyecto}>{proyecto}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Caja Origen</InputLabel>
            <Select
              value={filters.caja_origen}
              onChange={(e) => setFilters({ ...filters, caja_origen: e.target.value })}
              label="Caja Origen"
            >
              <MenuItem value="">Todas</MenuItem>
              {cajasOrigen.map((caja) => (
                <MenuItem key={caja} value={caja}>{caja}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Proveedor</InputLabel>
            <Select
              value={filters.proveedor}
              onChange={(e) => setFilters({ ...filters, proveedor: e.target.value })}
              label="Proveedor"
            >
              <MenuItem value="">Todos</MenuItem>
              {proveedores.map((prov) => (
                <MenuItem key={prov} value={prov}>{prov}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Trabajador</InputLabel>
            <Select
              value={filters.trabajador}
              onChange={(e) => setFilters({ ...filters, trabajador: e.target.value })}
              label="Trabajador"
            >
              <MenuItem value="">Todos</MenuItem>
              {trabajadores.map((trab) => (
                <MenuItem key={trab} value={trab}>{trab}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>A Nombre De</InputLabel>
            <Select
              value={filters.nombre_factura}
              onChange={(e) => setFilters({ ...filters, nombre_factura: e.target.value })}
              label="A Nombre De"
            >
              <MenuItem value="">Todos</MenuItem>
              {nombreFacturas.map((nombre) => (
                <MenuItem key={nombre} value={nombre}>{nombre}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl fullWidth margin="normal">
            <InputLabel>Concepto</InputLabel>
            <Select
              value={filters.concepto}
              onChange={(e) => setFilters({ ...filters, concepto: e.target.value })}
              label="Concepto"
            >
              <MenuItem value="">Todos</MenuItem>
              {conceptos.map((conc) => (
                <MenuItem key={conc} value={conc}>{conc}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box sx={{ mt: 2, mb: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <DatePicker
                    label="Desde"
                    value={filters.fecha_desde}
                    onChange={(newValue) => setFilters({ ...filters, fecha_desde: newValue })}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true
                      }
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <DatePicker
                    label="Hasta"
                    value={filters.fecha_hasta}
                    onChange={(newValue) => setFilters({ ...filters, fecha_hasta: newValue })}
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true
                      }
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </LocalizationProvider>

          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
            <Button
              variant="outlined"
              color="inherit"
              onClick={resetFilters}
              sx={{ width: '48%', borderRadius: 28 }}
            >
              Limpiar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={addFilter}
              sx={{ width: '48%', borderRadius: 28 }}
            >
              Aplicar
            </Button>
          </Box>
        </Box>
      </FilterDrawer>

      {/* Dialog para ordenar */}
      <Dialog
        open={isSortMenuOpen}
        onClose={handleSortClose}
        PaperProps={{
          sx: {
            borderRadius: 4,
            maxWidth: '90%',
            width: '90%'
          }
        }}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="h6" gutterBottom>Ordenar por</Typography>

          <Box sx={{ mt: 1 }}>
            <Button
              fullWidth
              sx={{
                justifyContent: 'flex-start',
                py: 1.5,
                borderRadius: 2,
                mb: 1,
                backgroundColor: sortBy === 'date_desc' ? 'rgba(46, 125, 50, 0.1)' : 'transparent',
                color: sortBy === 'date_desc' ? '#2e7d32' : 'inherit',
                fontWeight: sortBy === 'date_desc' ? 'bold' : 'normal'
              }}
              onClick={() => handleSortSelect('date_desc')}
            >
              Fecha (más reciente primero)
            </Button>

            <Button
              fullWidth
              sx={{
                justifyContent: 'flex-start',
                py: 1.5,
                borderRadius: 2,
                mb: 1,
                backgroundColor: sortBy === 'date_asc' ? 'rgba(46, 125, 50, 0.1)' : 'transparent',
                color: sortBy === 'date_asc' ? '#2e7d32' : 'inherit',
                fontWeight: sortBy === 'date_asc' ? 'bold' : 'normal'
              }}
              onClick={() => handleSortSelect('date_asc')}
            >
              Fecha (más antigua primero)
            </Button>

            <Button
              fullWidth
              sx={{
                justifyContent: 'flex-start',
                py: 1.5,
                borderRadius: 2,
                mb: 1,
                backgroundColor: sortBy === 'amount_desc' ? 'rgba(46, 125, 50, 0.1)' : 'transparent',
                color: sortBy === 'amount_desc' ? '#2e7d32' : 'inherit',
                fontWeight: sortBy === 'amount_desc' ? 'bold' : 'normal'
              }}
              onClick={() => handleSortSelect('amount_desc')}
            >
              Monto (mayor primero)
            </Button>

            <Button
              fullWidth
              sx={{
                justifyContent: 'flex-start',
                py: 1.5,
                borderRadius: 2,
                backgroundColor: sortBy === 'amount_asc' ? 'rgba(46, 125, 50, 0.1)' : 'transparent',
                color: sortBy === 'amount_asc' ? '#2e7d32' : 'inherit',
                fontWeight: sortBy === 'amount_asc' ? 'bold' : 'normal'
              }}
              onClick={() => handleSortSelect('amount_asc')}
            >
              Monto (menor primero)
            </Button>
          </Box>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button onClick={handleSortClose} color="primary">
              Cerrar
            </Button>
          </Box>
        </Box>
      </Dialog>

      {/* Snackbar para notificaciones */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ mb: 7 }} // Para que aparezca por encima de la navegación
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            borderRadius: 28,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </MobileContainer>
  );
};

export default BuscadorUnificadoMobile;