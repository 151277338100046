// src/components/CajaEmpresa.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Button,
  Typography,
  Container,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  IconButton,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

// Verificar la estructura de la tabla empresa en Supabase
const PRIMARY_KEY = 'id'; // Cambia esto si la clave primaria es diferente (e.g., 'empresa_id')

const CajaEmpresa = ({ updateEmpresaNombre }) => {
  const [empresa, setEmpresa] = useState(null);
  const [formData, setFormData] = useState({ nombre_de_empresa: '', caja_empresa: '' });
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    fetchEmpresa();
  }, []);

  const fetchEmpresa = async () => {
    const { data, error } = await supabase.from('empresa').select('*').limit(1);
    if (error) console.error('Error fetching empresa:', error);
    else if (data && data.length > 0) {
      setEmpresa(data[0]);
      setFormData({ nombre_de_empresa: data[0].nombre_de_empresa, caja_empresa: data[0].caja_empresa });
    }
  };

  const handleSave = async () => {
    if (empresa) {
      const { error } = await supabase.from('empresa').update({
        nombre_de_empresa: formData.nombre_de_empresa,
        caja_empresa: formData.caja_empresa,
      }).eq(PRIMARY_KEY, empresa[PRIMARY_KEY]);
      if (error) alert('Error al actualizar empresa: ' + error.message);
      else {
        fetchEmpresa();
        if (updateEmpresaNombre) updateEmpresaNombre(formData.nombre_de_empresa); // Actualizar nombre en App.js
      }
    } else {
      const { error } = await supabase.from('empresa').insert({
        nombre_de_empresa: formData.nombre_de_empresa,
        caja_empresa: formData.caja_empresa,
      });
      if (error) alert('Error al agregar empresa: ' + error.message);
      else {
        fetchEmpresa();
        if (updateEmpresaNombre) updateEmpresaNombre(formData.nombre_de_empresa); // Actualizar nombre en App.js
      }
    }
    setOpenDialog(false);
  };

  return (
    <Container maxWidth="lg" sx={{ py: 3, px: 0, minHeight: '100vh' }}>
      <Box sx={{ mb: 2 }}>
        <Typography variant="h4" fontWeight="bold" color="#2e7d32" gutterBottom>
          Caja Empresa
        </Typography>
        {empresa ? (
          <Box sx={{ p: 2, border: '1px solid #ccc', borderRadius: 4 }}>
            <Typography variant="body1">
              Nombre de Empresa: {empresa.nombre_de_empresa}
            </Typography>
            <Typography variant="body1">
              Caja Empresa: {empresa.caja_empresa}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenDialog(true)}
              sx={{ mt: 2 }}
            >
              Editar
            </Button>
          </Box>
        ) : (
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenDialog(true)}
            sx={{ mt: 2 }}
          >
            Agregar Caja Empresa
          </Button>
        )}
      </Box>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogContent>
          <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" fontWeight="bold" color="#424242">
              {empresa ? 'Editar Caja Empresa' : 'Agregar Caja Empresa'}
            </Typography>
            <IconButton onClick={() => setOpenDialog(false)} sx={{ color: '#d32f2f' }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <TextField
            label="Nombre de Empresa"
            value={formData.nombre_de_empresa}
            onChange={(e) => setFormData((prev) => ({ ...prev, nombre_de_empresa: e.target.value }))}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          />
          <TextField
            label="Caja Empresa"
            value={formData.caja_empresa}
            onChange={(e) => setFormData((prev) => ({ ...prev, caja_empresa: e.target.value }))}
            fullWidth
            sx={{ mt: 2, mb: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <Button
            variant="contained"
            color="success"
            onClick={handleSave}
            sx={{ borderRadius: 12, backgroundColor: '#2e7d32', '&:hover': { backgroundColor: '#1b5e20' } }}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default CajaEmpresa;