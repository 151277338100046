import React, { useState, useEffect, useCallback, useRef } from 'react';
import { supabase } from '../supabaseClient';
import axios from 'axios';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
  Container,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Paper,
  Chip,
  Tooltip,
  InputAdornment,
  Snackbar,
  Alert,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
  Collapse,
  Grid,
  CircularProgress,
  Divider,
  Card,
  CardContent,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import esLocale from 'date-fns/locale/es';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import FilterListIcon from '@mui/icons-material/FilterList';
import DownloadIcon from '@mui/icons-material/Download';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PersonIcon from '@mui/icons-material/Person';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import { format, parse } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { formatImageUrl, uploadImage } from '../utils/imageUtils';

// Styled components
const StyledPaper = styled(Paper)(({ theme }) => ({
  borderRadius: 16,
  overflow: 'hidden',
  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    boxShadow: '0 10px 40px rgba(0, 0, 0, 0.12)',
    transition: 'box-shadow 0.3s ease-in-out',
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(0, 0, 0, 0.02)',
  },
  '&:hover': {
    backgroundColor: 'rgba(46, 125, 50, 0.04)',
    transition: 'background-color 0.2s ease',
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: '12px 16px',
  fontSize: '0.875rem',
  textAlign: 'center',
}));

const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  backgroundColor: '#f8f9fa',
  padding: '14px 16px',
  fontSize: '0.875rem',
  fontWeight: 'bold',
  color: '#2e7d32',
  borderBottom: '2px solid #e8f5e9',
  textAlign: 'center',
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: 'rgba(46, 125, 50, 0.1)',
  },
}));

const AddCobroDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
  },
  '& .MuiPaper-root': {
    borderRadius: 16,
    padding: theme.spacing(1.5),
    width: '600px',
    maxHeight: '80vh',
  },
}));

const ImagePreviewDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.9)',
  },
  '& .MuiPaper-root': {
    borderRadius: 12,
    backgroundColor: 'transparent',
    boxShadow: 'none',
    maxWidth: '90vw',
    maxHeight: '90vh',
  },
}));

const FilterChip = styled(Chip)(({ theme }) => ({
  margin: theme.spacing(0.5),
  backgroundColor: '#e8f5e9',
  color: '#2e7d32',
  '&:hover': {
    backgroundColor: '#c8e6c9',
  },
  '& .MuiChip-deleteIcon': {
    color: '#2e7d32',
    '&:hover': {
      color: '#1b5e20',
    },
  },
}));

const ExportButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#2e7d32',
  color: 'white',
  padding: '8px 16px',
  borderRadius: 8,
  '&:hover': {
    backgroundColor: '#1b5e20',
  },
  transition: 'background-color 0.3s ease',
}));

const AddButton = styled(IconButton)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#2e7d32',
  width: 40,
  height: 40,
  boxShadow: '0 4px 10px rgba(46, 125, 50, 0.3)',
  '&:hover': {
    backgroundColor: '#1b5e20',
    boxShadow: '0 6px 15px rgba(46, 125, 50, 0.4)',
  },
  transition: 'all 0.3s ease',
}));

const MontoChip = styled(Chip)(({ theme, isPositive }) => ({
  backgroundColor: isPositive ? '#e8f5e9' : '#ffebee',
  color: isPositive ? '#2e7d32' : '#d32f2f',
  fontWeight: 'bold',
  '& .MuiChip-icon': {
    color: isPositive ? '#2e7d32' : '#d32f2f',
  },
}));

// Selector de moneda mejorado y simplificado
const CurrencySelector = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 25,
  border: '1px solid #e0e0e0',
  overflow: 'hidden',
  width: 'fit-content',
}));

const CurrencyButton = styled(Button)(({ theme, isActive }) => ({
  padding: '4px 16px',
  border: 'none',
  backgroundColor: isActive ? '#2e7d32' : '#f5f5f5',
  color: isActive ? 'white' : '#757575',
  fontWeight: isActive ? 'bold' : 'normal',
  '&:hover': {
    backgroundColor: isActive ? '#1b5e20' : '#e0e0e0',
  },
  borderRadius: 0,
  minWidth: 70,
}));

const FileUploadBox = styled(Box)(({ theme }) => ({
  border: '2px dashed #c8e6c9',
  borderRadius: 8,
  padding: theme.spacing(3),
  textAlign: 'center',
  backgroundColor: '#f1f8e9',
  cursor: 'pointer',
  transition: 'all 0.2s ease',
  '&:hover': {
    borderColor: '#2e7d32',
    backgroundColor: '#e8f5e9',
  },
}));

// Component for image preview
const ImagePreview = ({
  src,
  alt = 'Imagen',
  onClick,
  width = '100%',
  height = '100%',
  showZoom = true,
  thumbSize = 'medium' // 'small', 'medium', 'large'
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [retryCount, setRetryCount] = useState(0);

  // Size mapping
  const sizes = {
    small: { width: 40, height: 40, iconSize: 20 },
    medium: { width: 80, height: 80, iconSize: 36 },
    large: { width: 120, height: 120, iconSize: 48 }
  };

  const size = sizes[thumbSize] || sizes.medium;

  useEffect(() => {
    if (!src) {
      setError(true);
      return;
    }

    console.log("Intentando cargar imagen desde:", src);
    setLoaded(false);
    setError(false);

    const img = new Image();

    img.onload = () => {
      console.log("Imagen cargada correctamente");
      setLoaded(true);
      setError(false);
    };

    img.onerror = () => {
      console.error('Error cargando imagen:', src);
      // Try direct URL without cache params
      if (src.includes('?')) {
        const directUrl = src.split('?')[0];
        console.log("Intentando URL directa:", directUrl);

        const directImg = new Image();
        directImg.onload = () => {
          console.log("Imagen cargada con URL directa");
          setLoaded(true);
          setError(false);
        };
        directImg.onerror = () => {
          console.error("Error también con URL directa");
          setError(true);
          setLoaded(false);
        };
        directImg.src = directUrl;
      } else {
        setError(true);
        setLoaded(false);
      }
    };

    img.src = src;

    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src, retryCount]);

  const handleRetry = (e) => {
    e.stopPropagation();
    setRetryCount(prev => prev + 1);
  };

  if (error) {
    return (
      <motion.div
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onClick}
        style={{ cursor: 'pointer' }}
      >
        <Box
          sx={{
            width: width !== '100%' ? width : size.width,
            height: height !== '100%' ? height : size.height,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: '#f5f5f5',
            borderRadius: 1,
            border: '1px solid #e0e0e0',
            position: 'relative',
            overflow: 'hidden'
          }}
        >
          <ReceiptIcon sx={{ color: '#9e9e9e', fontSize: size.iconSize }} />
          <Typography variant="caption" sx={{ fontSize: '10px', textAlign: 'center', mt: 0.5 }}>
            Error
          </Typography>

          <Tooltip title="Reintentar cargar imagen">
            <IconButton
              size="small"
              onClick={handleRetry}
              sx={{
                position: 'absolute',
                top: 2,
                right: 2,
                background: 'rgba(255,255,255,0.8)',
                width: 16,
                height: 16
              }}
            >
              <RefreshIcon sx={{ fontSize: 12 }} />
            </IconButton>
          </Tooltip>
        </Box>
      </motion.div>
    );
  }

  if (!loaded) {
    return (
      <Box
        sx={{
          width: width !== '100%' ? width : size.width,
          height: height !== '100%' ? height : size.height,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#f5f5f5',
          borderRadius: 1,
          border: '1px solid #e0e0e0'
        }}
      >
        <CircularProgress size={size.iconSize / 2} sx={{ color: '#2e7d32' }} />
      </Box>
    );
  }

  return (
    <motion.div
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      style={{ cursor: 'pointer', position: 'relative' }}
    >
      <Box
        sx={{
          width: width !== '100%' ? width : size.width,
          height: height !== '100%' ? height : size.height,
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 1,
          border: '1px solid #e0e0e0'
        }}
        onClick={onClick}
      >
        <Box
          component="img"
          src={src}
          alt={alt}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            transition: 'transform 0.3s ease',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        />

        {showZoom && (
          <Box
            sx={{
              position: 'absolute',
              bottom: 4,
              right: 4,
              backgroundColor: 'rgba(255, 255, 255, 0.7)',
              borderRadius: '50%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: 24,
              height: 24,
              '&:hover': {
                backgroundColor: 'rgba(255, 255, 255, 0.9)',
              }
            }}
          >
            <ZoomInIcon sx={{ fontSize: 16, color: '#2e7d32' }} />
          </Box>
        )}
      </Box>
    </motion.div>
  );
};

// Main component
const CobroTrabajadores = () => {
  const [cobros, setCobros] = useState([]);
  const [filteredCobros, setFilteredCobros] = useState([]);
  const [trabajadores, setTrabajadores] = useState([]);
  const [cajas, setCajas] = useState([]);
  const [empresa, setEmpresa] = useState(null);
  const [nombreFacturas, setNombreFacturas] = useState([]);
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openFilters, setOpenFilters] = useState(false);
  const [openImagePreview, setOpenImagePreview] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  // Pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Filters
  const [filters, setFilters] = useState({
    trabajador: '',
    caja: '',
    moneda: '',
    fecha_desde: null,
    fecha_hasta: null,
  });

  const [searchTerm, setSearchTerm] = useState('');
  const [activeFilters, setActiveFilters] = useState([]);

  const [formData, setFormData] = useState({
    fecha: new Date().toISOString().split('T')[0],
    caja_origen_cobro: '',
    trabajador_cobro: '',
    nombre_facturador_cobro: '',
    moneda_cobro: 'ARS',
    tipo_de_cambio_cobro: 0.0,
    monto_cobro: '',
    descripción_cobro: '',
    monto_en_ars_cobro: 0.0,
    monto_en_usd_cobro: 0.0,
    tiene_comprobante_cobro: false,
    codigo_de_comprobante_cobro: '',
    imagen_comprobante_cobro: null,
    tiene_factura_cobro: false,
    codigo_de_factura_cobro: '',
    imagen_factura_cobro: null,
    factura_a_nombre_de_cobro: '',
  });

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [editId, setEditId] = useState(null);

  // Estados para archivos
  const [comprobanteFile, setComprobanteFile] = useState(null);
  const [comprobantePreviewUrl, setComprobantePreviewUrl] = useState('');
  const [facturaFile, setFacturaFile] = useState(null);
  const [facturaPreviewUrl, setFacturaPreviewUrl] = useState('');
  const [uploading, setUploading] = useState(false);

  // Referencias para inputs de archivo
  const comprobanteFileRef = useRef(null);
  const facturaFileRef = useRef(null);

  // Notification
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const fetchCobros = async () => {
    const { data, error } = await supabase
      .from('cobro_trabajadores')
      .select('*')
      .order('fecha', { ascending: false });

    if (error) {
      console.error('Error fetching cobros:', error);
      return;
    }

    // Format dates and sort by amount (higher to lower)
    const formattedData = data.map(item => ({
      ...item,
      fecha_formateada: item.fecha ? format(new Date(item.fecha), 'dd/MM/yyyy') : '',
      monto_formateado: formatCurrency(item.monto_cobro, item.moneda_cobro),
      imagen_comprobante_formateada: item.imagen_comprobante_cobro ? formatImageUrl(item.imagen_comprobante_cobro) : null,
      imagen_factura_formateada: item.imagen_factura_cobro ? formatImageUrl(item.imagen_factura_cobro) : null
    }));

    setCobros(formattedData || []);
    setFilteredCobros(formattedData || []);
  };

  const formatCurrency = (amount, currency) => {
    if (!amount) return '';
    const formatter = new Intl.NumberFormat('es-AR', {
      style: 'currency',
      currency: currency === 'USD' ? 'USD' : 'ARS',
      minimumFractionDigits: 2
    });
    return formatter.format(amount);
  };

  const fetchTrabajadores = async () => {
    const { data, error } = await supabase.from('trabajadores_empresa').select('*');
    if (error) console.error('Error fetching trabajadores:', error);
    else setTrabajadores(data || []);
  };

  const fetchCajas = async () => {
    const { data, error } = await supabase.from('cajas_cambios').select('caja');
    if (error) console.error('Error fetching cajas:', error);
    else setCajas(data || []);
  };

  const fetchNombreFacturas = async () => {
    const { data, error } = await supabase.from('facturas_a_nombre_de_salidas').select('nombre_factura');
    if (error) console.error('Error fetching nombres de facturas:', error);
    else setNombreFacturas(data?.map(item => item.nombre_factura) || []);
  };

  const fetchEmpresa = async () => {
    const { data, error } = await supabase.from('empresa').select('*').limit(1);
    if (error) console.error('Error fetching empresa:', error);
    else if (data && data.length > 0) {
      setEmpresa(data[0]);
      // Establecer valor predeterminado para caja origen desde la empresa
      setFormData((prev) => ({
        ...prev,
        caja_origen_cobro: data[0].caja_empresa || '',
        factura_a_nombre_de_cobro: data[0].nombre_empresa || ''
      }));
    }
  };

  const fetchDolarBlue = useCallback(async () => {
    try {
      const response = await axios.get('https://dolarapi.com/v1/dolares/blue');
      const tipoDeCambio = response.data.compra;
      setFormData((prev) => ({
        ...prev,
        tipo_de_cambio_cobro: tipoDeCambio,
      }));
    } catch (error) {
      console.error('Error fetching dolar blue:', error);
    }
  }, []);

  // Generate unique code for comprobante or factura - using sanitized strings
  const generateUniqueCode = (type, trabajador) => {
    const date = format(selectedDate || new Date(), 'yyyyMMdd');
    const prefix = type === 'comprobante' ? 'CT-COM-' : 'CT-FAC-';
    const randomId = uuidv4().substring(0, 6); // Get first 6 chars from UUID

    // Create a sanitized filename without special characters or spaces
    const sanitizedTrabajador = trabajador ?
      trabajador.replace(/[^a-zA-Z0-9]/g, '_').substring(0, 20) : 'Unknown';

    return `${prefix}${date}-${sanitizedTrabajador}-${randomId}`;
  };

  useEffect(() => {
    fetchCobros();
    fetchTrabajadores();
    fetchCajas();
    fetchEmpresa();
    fetchNombreFacturas();
    fetchDolarBlue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cobros, searchTerm, activeFilters]);

  // This function is called in real time as the user types
  const actualizarMontos = useCallback((monto, moneda, tipoDeCambio) => {
    const montoNum = parseFloat(monto) || 0;
    const tipoCambioNum = parseFloat(tipoDeCambio) || 0;

    if (tipoCambioNum === 0) return { monto_en_ars_cobro: 0, monto_en_usd_cobro: 0 };

    return {
      monto_en_ars_cobro: moneda === 'ARS' ? montoNum : montoNum * tipoCambioNum,
      monto_en_usd_cobro: moneda === 'USD' ? montoNum : montoNum / tipoCambioNum,
    };
  }, []);

  const applyFilters = () => {
    let filtered = [...cobros];

    // Global search
    if (searchTerm) {
      filtered = filtered.filter(cobro =>
        Object.values(cobro).some(value =>
          value && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }

    // Apply active filters
    activeFilters.forEach(filter => {
      if (filter.type === 'fecha_desde' && filter.value) {
        filtered = filtered.filter(cobro =>
          new Date(cobro.fecha) >= new Date(filter.value)
        );
      } else if (filter.type === 'fecha_hasta' && filter.value) {
        filtered = filtered.filter(cobro =>
          new Date(cobro.fecha) <= new Date(filter.value)
        );
      } else if (filter.value) {
        filtered = filtered.filter(cobro => {
          const fieldMap = {
            trabajador: 'trabajador_cobro',
            caja: 'caja_origen_cobro',
            moneda: 'moneda_cobro',
          };
          const field = fieldMap[filter.type];
          return cobro[field] === filter.value;
        });
      }
    });

    setFilteredCobros(filtered);
  };

  const addFilter = () => {
    const newFilters = [];

    if (filters.trabajador) {
      newFilters.push({ type: 'trabajador', value: filters.trabajador, label: `Trabajador: ${filters.trabajador}` });
    }

    if (filters.caja) {
      newFilters.push({ type: 'caja', value: filters.caja, label: `Caja: ${filters.caja}` });
    }

    if (filters.moneda) {
      newFilters.push({ type: 'moneda', value: filters.moneda, label: `Moneda: ${filters.moneda}` });
    }

    if (filters.fecha_desde) {
      newFilters.push({ type: 'fecha_desde', value: filters.fecha_desde, label: `Desde: ${format(new Date(filters.fecha_desde), 'dd/MM/yyyy')}` });
    }

    if (filters.fecha_hasta) {
      newFilters.push({ type: 'fecha_hasta', value: filters.fecha_hasta, label: `Hasta: ${format(new Date(filters.fecha_hasta), 'dd/MM/yyyy')}` });
    }

    setActiveFilters([...activeFilters, ...newFilters]);

    // Reset filters
    setFilters({
      trabajador: '',
      caja: '',
      moneda: '',
      fecha_desde: null,
      fecha_hasta: null,
    });

    setOpenFilters(false);
  };

  const removeFilter = (index) => {
    const updatedFilters = [...activeFilters];
    updatedFilters.splice(index, 1);
    setActiveFilters(updatedFilters);
  };

  const removeAllFilters = () => {
    setActiveFilters([]);
    setSearchTerm('');
  };

  const exportToCSV = () => {
    // Prepare data for export
    const csvContent = [
      // Headers
      ['ID', 'Fecha', 'Caja', 'Trabajador', 'Facturador', 'Moneda', 'Monto', 'Descripción', 'Tipo de Cambio', 'Monto ARS', 'Monto USD', 'Comprobante', 'Factura'],
      // Data
      ...filteredCobros.map(cobro => [
        cobro.id,
        cobro.fecha_formateada,
        cobro.caja_origen_cobro,
        cobro.trabajador_cobro,
        cobro.nombre_facturador_cobro || cobro.trabajador_cobro,
        cobro.moneda_cobro,
        cobro.monto_cobro,
        cobro.descripción_cobro,
        cobro.tipo_de_cambio_cobro,
        cobro.monto_en_ars_cobro,
        cobro.monto_en_usd_cobro,
        cobro.tiene_comprobante_cobro ? 'Sí' : 'No',
        cobro.tiene_factura_cobro ? 'Sí' : 'No'
      ])
    ].map(row => row.join(',')).join('\n');

    // Create blob and download
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `cobros_trabajadores_${format(new Date(), 'yyyyMMdd')}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Date handler with new DatePicker
  const handleFechaChange = (date) => {
    setSelectedDate(date);
    setFormData({
      ...formData,
      fecha: format(date, 'yyyy-MM-dd')
    });
  };

  const handleCajaChange = (e) => {
    setFormData({ ...formData, caja_origen_cobro: e.target.value });
  };

  const handleTrabajadorChange = (e) => {
    const trabajadorSeleccionado = e.target.value;
    setFormData({
      ...formData,
      trabajador_cobro: trabajadorSeleccionado,
      // Auto-populate facturador field with selected worker 
      nombre_facturador_cobro: trabajadorSeleccionado
    });
  };

  const handleFacturadorChange = (e) => {
    setFormData({ ...formData, nombre_facturador_cobro: e.target.value });
  };

  const handleDescripcionChange = (e) => {
    setFormData({ ...formData, descripción_cobro: e.target.value });
  };

  const handleNombreFacturaChange = (e) => {
    setFormData({ ...formData, factura_a_nombre_de_cobro: e.target.value });
  };

  // Enhanced currency selector with buttons instead of switch
  const handleMonedaChange = (newMoneda) => {
    // When changing currency, we immediately update amounts
    const { monto_en_ars_cobro, monto_en_usd_cobro } = actualizarMontos(
      formData.monto_cobro,
      newMoneda,
      formData.tipo_de_cambio_cobro
    );

    setFormData({
      ...formData,
      moneda_cobro: newMoneda,
      monto_en_ars_cobro,
      monto_en_usd_cobro
    });
  };

  // Improved handler for amount field with real-time calculations
  const handleMontoChange = (e) => {
    const { value } = e.target;
    // Allow only digits and decimal point
    const cleanValue = value.replace(/[^\d.]/g, '');

    // Ensure only one decimal point
    const parts = cleanValue.split('.');
    let finalValue = parts[0] || '';

    if (parts.length > 1) {
      finalValue += '.' + parts.slice(1).join('');
    }

    // Calculate amounts in real time
    const { monto_en_ars_cobro, monto_en_usd_cobro } = actualizarMontos(
      finalValue,
      formData.moneda_cobro,
      formData.tipo_de_cambio_cobro
    );

    setFormData({
      ...formData,
      monto_cobro: finalValue,
      monto_en_ars_cobro,
      monto_en_usd_cobro
    });
  };

  // Handle exchange rate adjustments
  const adjustTipoCambio = (amount) => {
    const currentValue = parseFloat(formData.tipo_de_cambio_cobro) || 0;
    const newValue = Math.max(0, currentValue + amount);
    setFormData({
      ...formData,
      tipo_de_cambio_cobro: newValue,
      ...actualizarMontos(formData.monto_cobro, formData.moneda_cobro, newValue)
    });
  };

  // Handlers for file uploads
  const handleComprobanteSelect = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // File type validation
    if (!file.type.match('image.*')) {
      setSnackbar({
        open: true,
        message: 'Por favor, selecciona un archivo de imagen válido',
        severity: 'error'
      });
      return;
    }

    // Size validation (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setSnackbar({
        open: true,
        message: 'La imagen debe ser menor a 5MB',
        severity: 'error'
      });
      return;
    }

    setComprobanteFile(file);
    setFormData({
      ...formData,
      tiene_comprobante_cobro: true,
      codigo_de_comprobante_cobro: generateUniqueCode('comprobante', formData.trabajador_cobro)
    });

    // Create temporary URL for preview
    const previewURL = URL.createObjectURL(file);
    setComprobantePreviewUrl(previewURL);
  };

  const handleFacturaSelect = (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // File type validation
    if (!file.type.match('image.*')) {
      setSnackbar({
        open: true,
        message: 'Por favor, selecciona un archivo de imagen válido',
        severity: 'error'
      });
      return;
    }

    // Size validation (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setSnackbar({
        open: true,
        message: 'La imagen debe ser menor a 5MB',
        severity: 'error'
      });
      return;
    }

    setFacturaFile(file);
    setFormData({
      ...formData,
      tiene_factura_cobro: true,
      codigo_de_factura_cobro: generateUniqueCode('factura', formData.trabajador_cobro)
    });

    // Create temporary URL for preview
    const previewURL = URL.createObjectURL(file);
    setFacturaPreviewUrl(previewURL);
  };

  const removeComprobante = () => {
    setComprobanteFile(null);
    setComprobantePreviewUrl('');
    setFormData({
      ...formData,
      tiene_comprobante_cobro: false,
      codigo_de_comprobante_cobro: ''
    });

    // Clear file input
    if (comprobanteFileRef.current) {
      comprobanteFileRef.current.value = '';
    }
  };

  const removeFactura = () => {
    setFacturaFile(null);
    setFacturaPreviewUrl('');
    setFormData({
      ...formData,
      tiene_factura_cobro: false,
      codigo_de_factura_cobro: ''
    });

    // Clear file input
    if (facturaFileRef.current) {
      facturaFileRef.current.value = '';
    }
  };

  // FIXED ADD FUNCTION
  const handleAddCobro = async () => {
    if (!formData.fecha || !formData.caja_origen_cobro || !formData.trabajador_cobro || !formData.monto_cobro) {
      setSnackbar({
        open: true,
        message: 'Por favor, completa todos los campos obligatorios',
        severity: 'error'
      });
      return;
    }

    if (formData.tipo_de_cambio_cobro === 0) {
      setSnackbar({
        open: true,
        message: 'El tipo de cambio no puede ser cero. Por favor ajusta el valor.',
        severity: 'error'
      });
      return;
    }

    try {
      setUploading(true);

      // Data to be saved
      const cobroData = { ...formData };

      // Set facturador if not specified
      if (!cobroData.nombre_facturador_cobro) {
        cobroData.nombre_facturador_cobro = cobroData.trabajador_cobro;
      }

      // Upload comprobante if exists
      if (formData.tiene_comprobante_cobro && comprobanteFile) {
        try {
          // Generate a clean filename without special characters
          const sanitizedTrabajador = formData.trabajador_cobro
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') // Remove accents
            .replace(/[^\w\s]/gi, '')  // Remove special chars
            .replace(/\s+/g, '_');     // Replace spaces with underscores

          const fecha = format(new Date(), 'yyyyMMdd');
          const fileId = uuidv4().substring(0, 8);
          const fileExtension = comprobanteFile.name.split('.').pop();

          const customFilename = `${fecha}_comp_${sanitizedTrabajador}_${fileId}.${fileExtension}`;

          const imageData = await uploadImage(comprobanteFile, {
            tipo: 'cobro_trabajador_comprobante',
            referencia: sanitizedTrabajador,
            codigo: formData.codigo_de_comprobante_cobro || generateUniqueCode('comprobante', sanitizedTrabajador),
            customFilename: customFilename
          });

          if (imageData && imageData.url) {
            cobroData.codigo_de_comprobante_cobro = imageData.codigo || generateUniqueCode('comprobante', sanitizedTrabajador);
            cobroData.imagen_comprobante_cobro = imageData.url;

            // Update or add to comprobantes collection
            await supabase.from('comprobantes')
              .upsert([{
                tipo_comprobante: 'cobro_trabajador',
                codigo_comprobante: cobroData.codigo_de_comprobante_cobro,
                fecha_comprobante: formData.fecha,
                entidad_asociada: formData.trabajador_cobro,
                url_imagen: imageData.url,
                monto_comprobante: formData.monto_cobro,
                moneda_comprobante: formData.moneda_cobro
              }]);
          }
        } catch (uploadError) {
          console.error('Error subiendo comprobante:', uploadError);
          setSnackbar({
            open: true,
            message: 'Error al subir el comprobante: ' + (uploadError.message || 'Error desconocido'),
            severity: 'error'
          });
          setUploading(false);
          return;
        }
      }

      // Upload factura if exists
      if (formData.tiene_factura_cobro && facturaFile) {
        try {
          // Similar sanitization as for comprobante
          const sanitizedTrabajador = formData.trabajador_cobro
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[^\w\s]/gi, '')
            .replace(/\s+/g, '_');

          const fecha = format(new Date(), 'yyyyMMdd');
          const fileId = uuidv4().substring(0, 8);
          const fileExtension = facturaFile.name.split('.').pop();

          const customFilename = `${fecha}_fact_${sanitizedTrabajador}_${fileId}.${fileExtension}`;

          const imageData = await uploadImage(facturaFile, {
            tipo: 'cobro_trabajador_factura',
            referencia: sanitizedTrabajador,
            codigo: formData.codigo_de_factura_cobro || generateUniqueCode('factura', sanitizedTrabajador),
            customFilename: customFilename
          });

          if (imageData && imageData.url) {
            cobroData.codigo_de_factura_cobro = imageData.codigo || generateUniqueCode('factura', sanitizedTrabajador);
            cobroData.imagen_factura_cobro = imageData.url;

            // Update or add to facturas collection
            await supabase.from('facturas')
              .upsert([{
                tipo_factura: 'cobro_trabajador',
                codigo_factura: cobroData.codigo_de_factura_cobro,
                fecha_factura: formData.fecha,
                entidad_asociada: formData.trabajador_cobro,
                a_nombre_de: formData.factura_a_nombre_de_cobro,
                url_imagen: imageData.url,
                monto_factura: formData.monto_cobro,
                moneda_factura: formData.moneda_cobro
              }]);
          }
        } catch (uploadError) {
          console.error('Error subiendo factura:', uploadError);
          setSnackbar({
            open: true,
            message: 'Error al subir la factura: ' + (uploadError.message || 'Error desconocido'),
            severity: 'error'
          });
          setUploading(false);
          return;
        }
      }

      // Insert new record
      const { data, error } = await supabase
        .from('cobro_trabajadores')
        .insert(cobroData)
        .select();

      if (error) {
        setSnackbar({
          open: true,
          message: 'Error al crear cobro: ' + error.message,
          severity: 'error'
        });
      } else {
        fetchCobros();
        setOpenAddDialog(false);
        resetForm();
        setSnackbar({
          open: true,
          message: 'Cobro creado con éxito',
          severity: 'success'
        });
      }
    } catch (error) {
      console.error('Error en el proceso de creación de cobro:', error);
      setSnackbar({
        open: true,
        message: 'Error al procesar la solicitud: ' + (error.message || 'Error desconocido'),
        severity: 'error'
      });
    } finally {
      setUploading(false);
    }
  };

  // FIXED EDIT FUNCTION
  const handleEditCobro = async () => {
    if (!formData.fecha || !formData.caja_origen_cobro || !formData.trabajador_cobro || !formData.monto_cobro) {
      setSnackbar({
        open: true,
        message: 'Por favor, completa todos los campos obligatorios',
        severity: 'error'
      });
      return;
    }

    if (formData.tipo_de_cambio_cobro === 0) {
      setSnackbar({
        open: true,
        message: 'El tipo de cambio no puede ser cero. Por favor ajusta el valor.',
        severity: 'error'
      });
      return;
    }

    try {
      setUploading(true);

      // Data to be saved
      const cobroData = { ...formData };

      // Set facturador if not specified
      if (!cobroData.nombre_facturador_cobro) {
        cobroData.nombre_facturador_cobro = cobroData.trabajador_cobro;
      }

      // Upload comprobante if changed
      if (formData.tiene_comprobante_cobro && comprobanteFile) {
        try {
          // Generate a clean filename without special characters
          const sanitizedTrabajador = formData.trabajador_cobro
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') // Remove accents
            .replace(/[^\w\s]/gi, '')  // Remove special chars
            .replace(/\s+/g, '_');     // Replace spaces with underscores

          const fecha = format(new Date(), 'yyyyMMdd');
          const fileId = uuidv4().substring(0, 8);
          const fileExtension = comprobanteFile.name.split('.').pop();

          const customFilename = `${fecha}_comp_${sanitizedTrabajador}_${fileId}.${fileExtension}`;

          const imageData = await uploadImage(comprobanteFile, {
            tipo: 'cobro_trabajador_comprobante',
            referencia: sanitizedTrabajador,
            codigo: formData.codigo_de_comprobante_cobro || generateUniqueCode('comprobante', sanitizedTrabajador),
            customFilename: customFilename
          });

          if (imageData && imageData.url) {
            // If code wasn't set (previous image removed), generate one
            if (!cobroData.codigo_de_comprobante_cobro) {
              cobroData.codigo_de_comprobante_cobro = imageData.codigo || generateUniqueCode('comprobante', sanitizedTrabajador);
            }

            cobroData.imagen_comprobante_cobro = imageData.url;

            // Update or add to comprobantes collection
            await supabase.from('comprobantes')
              .upsert([{
                tipo_comprobante: 'cobro_trabajador',
                codigo_comprobante: cobroData.codigo_de_comprobante_cobro,
                fecha_comprobante: formData.fecha,
                entidad_asociada: formData.trabajador_cobro,
                url_imagen: imageData.url,
                monto_comprobante: formData.monto_cobro,
                moneda_comprobante: formData.moneda_cobro
              }]);
          }
        } catch (uploadError) {
          console.error('Error subiendo comprobante:', uploadError);
          setSnackbar({
            open: true,
            message: 'Error al subir el comprobante: ' + (uploadError.message || 'Error desconocido'),
            severity: 'error'
          });
          setUploading(false);
          return;
        }
      } else if (!formData.tiene_comprobante_cobro) {
        // If comprobante was removed, set to null
        cobroData.imagen_comprobante_cobro = null;
        cobroData.codigo_de_comprobante_cobro = null;
      }

      // Upload factura if changed (similar to comprobante logic)
      if (formData.tiene_factura_cobro && facturaFile) {
        try {
          const sanitizedTrabajador = formData.trabajador_cobro
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .replace(/[^\w\s]/gi, '')
            .replace(/\s+/g, '_');

          const fecha = format(new Date(), 'yyyyMMdd');
          const fileId = uuidv4().substring(0, 8);
          const fileExtension = facturaFile.name.split('.').pop();

          const customFilename = `${fecha}_fact_${sanitizedTrabajador}_${fileId}.${fileExtension}`;

          const imageData = await uploadImage(facturaFile, {
            tipo: 'cobro_trabajador_factura',
            referencia: sanitizedTrabajador,
            codigo: formData.codigo_de_factura_cobro || generateUniqueCode('factura', sanitizedTrabajador),
            customFilename: customFilename
          });

          if (imageData && imageData.url) {
            // If code wasn't set (previous image removed), generate one
            if (!cobroData.codigo_de_factura_cobro) {
              cobroData.codigo_de_factura_cobro = imageData.codigo || generateUniqueCode('factura', sanitizedTrabajador);
            }

            cobroData.imagen_factura_cobro = imageData.url;

            // Update or add to facturas collection
            await supabase.from('facturas')
              .upsert([{
                tipo_factura: 'cobro_trabajador',
                codigo_factura: cobroData.codigo_de_factura_cobro,
                fecha_factura: formData.fecha,
                entidad_asociada: formData.trabajador_cobro,
                a_nombre_de: formData.factura_a_nombre_de_cobro,
                url_imagen: imageData.url,
                monto_factura: formData.monto_cobro,
                moneda_factura: formData.moneda_cobro
              }]);
          }
        } catch (uploadError) {
          console.error('Error subiendo factura:', uploadError);
          setSnackbar({
            open: true,
            message: 'Error al subir la factura: ' + (uploadError.message || 'Error desconocido'),
            severity: 'error'
          });
          setUploading(false);
          return;
        }
      } else if (!formData.tiene_factura_cobro) {
        // If factura was removed, set to null
        cobroData.imagen_factura_cobro = null;
        cobroData.codigo_de_factura_cobro = null;
      }

      // Update database
      const { error } = await supabase
        .from('cobro_trabajadores')
        .update(cobroData)
        .eq('id', editId);

      if (error) {
        setSnackbar({
          open: true,
          message: 'Error al editar cobro: ' + error.message,
          severity: 'error'
        });
      } else {
        fetchCobros();
        setOpenEditDialog(false);
        setEditId(null);
        resetForm();
        setSnackbar({
          open: true,
          message: 'Cobro actualizado con éxito',
          severity: 'success'
        });
      }
    } catch (error) {
      console.error('Error en el proceso de edición de cobro:', error);
      setSnackbar({
        open: true,
        message: 'Error al procesar la solicitud: ' + (error.message || 'Error desconocido'),
        severity: 'error'
      });
    } finally {
      setUploading(false);
    }
  };

  const handleDeleteCobro = async (id) => {
    if (window.confirm('¿Estás seguro de que deseas eliminar este cobro?')) {
      // First get the current record to know if we need to delete files
      const { data: currentCobro, error: fetchError } = await supabase
        .from('cobro_trabajadores')
        .select('*')
        .eq('id', id)
        .single();

      if (fetchError) {
        console.error('Error fetching cobro for deletion:', fetchError);
        setSnackbar({
          open: true,
          message: 'Error al obtener información del cobro',
          severity: 'error'
        });
        return;
      }

      try {
        // Delete record first
        const { error } = await supabase
          .from('cobro_trabajadores')
          .delete()
          .eq('id', id);

        if (error) {
          setSnackbar({
            open: true,
            message: 'Error al eliminar cobro: ' + error.message,
            severity: 'error'
          });
          return;
        }

        // If has comprobante code, try to delete from comprobantes collection
        if (currentCobro.codigo_de_comprobante_cobro) {
          await supabase.from('comprobantes')
            .delete()
            .eq('codigo_comprobante', currentCobro.codigo_de_comprobante_cobro);
        }

        // If has factura code, try to delete from facturas collection
        if (currentCobro.codigo_de_factura_cobro) {
          await supabase.from('facturas')
            .delete()
            .eq('codigo_factura', currentCobro.codigo_de_factura_cobro);
        }

        fetchCobros();
        setSnackbar({
          open: true,
          message: 'Cobro eliminado con éxito',
          severity: 'success'
        });
      } catch (error) {
        console.error('Error deleting cobro:', error);
        setSnackbar({
          open: true,
          message: 'Error al procesar la solicitud',
          severity: 'error'
        });
      }
    }
  };

  const resetForm = () => {
    setSelectedDate(new Date());
    setFormData({
      fecha: new Date().toISOString().split('T')[0],
      caja_origen_cobro: empresa?.caja_empresa || '',
      trabajador_cobro: '',
      nombre_facturador_cobro: '',
      moneda_cobro: 'ARS',
      tipo_de_cambio_cobro: parseFloat(formData.tipo_de_cambio_cobro) || 0,
      monto_cobro: '',
      descripción_cobro: '',
      monto_en_ars_cobro: 0.0,
      monto_en_usd_cobro: 0.0,
      tiene_comprobante_cobro: false,
      codigo_de_comprobante_cobro: '',
      imagen_comprobante_cobro: null,
      tiene_factura_cobro: false,
      codigo_de_factura_cobro: '',
      imagen_factura_cobro: null,
      factura_a_nombre_de_cobro: empresa?.nombre_empresa || '',
    });

    // Reset file related states
    setComprobanteFile(null);
    setComprobantePreviewUrl('');
    setFacturaFile(null);
    setFacturaPreviewUrl('');

    // Clear file inputs
    if (comprobanteFileRef.current) comprobanteFileRef.current.value = '';
    if (facturaFileRef.current) facturaFileRef.current.value = '';
  };

  const handleEditClick = (cobro) => {
    setEditId(cobro.id);
    // Set selected date for DatePicker
    const date = cobro.fecha ? parse(cobro.fecha, 'yyyy-MM-dd', new Date()) : new Date();
    setSelectedDate(date);

    // Populate form data from cobro
    setFormData({
      fecha: cobro.fecha,
      caja_origen_cobro: cobro.caja_origen_cobro,
      trabajador_cobro: cobro.trabajador_cobro,
      nombre_facturador_cobro: cobro.nombre_facturador_cobro || cobro.trabajador_cobro,
      moneda_cobro: cobro.moneda_cobro,
      tipo_de_cambio_cobro: cobro.tipo_de_cambio_cobro,
      monto_cobro: cobro.monto_cobro.toString(),
      descripción_cobro: cobro.descripción_cobro || '',
      monto_en_ars_cobro: cobro.monto_en_ars_cobro,
      monto_en_usd_cobro: cobro.monto_en_usd_cobro,
      tiene_comprobante_cobro: cobro.tiene_comprobante_cobro || false,
      codigo_de_comprobante_cobro: cobro.codigo_de_comprobante_cobro || '',
      imagen_comprobante_cobro: cobro.imagen_comprobante_cobro,
      tiene_factura_cobro: cobro.tiene_factura_cobro || false,
      codigo_de_factura_cobro: cobro.codigo_de_factura_cobro || '',
      imagen_factura_cobro: cobro.imagen_factura_cobro,
      factura_a_nombre_de_cobro: cobro.factura_a_nombre_de_cobro || empresa?.nombre_empresa || '',
    });

    // Set preview URLs if images exist
    if (cobro.imagen_comprobante_cobro) {
      setComprobantePreviewUrl(formatImageUrl(cobro.imagen_comprobante_cobro));
    }
    if (cobro.imagen_factura_cobro) {
      setFacturaPreviewUrl(formatImageUrl(cobro.imagen_factura_cobro));
    }

    setOpenEditDialog(true);
  };

  const handleShowImage = (imageUrl, title) => {
    setCurrentImage({
      url: imageUrl,
      title: title
    });
    setOpenImagePreview(true);
  };

  // Component for dialog content - modified to call the right functions
  const CobroDialogContent = ({ isEdit }) => (
    <>
      <DialogTitle sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        p: 1.5
      }}>
        <div>
          <Typography variant="h5" fontWeight="bold" color="#2e7d32">
            {isEdit ? 'Editar Cobro' : 'Nuevo Cobro'}
          </Typography>
        </div>
        <IconButton
          onClick={() => (isEdit ? setOpenEditDialog(false) : setOpenAddDialog(false))}
          sx={{ color: '#d32f2f' }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ p: 1.5 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
          <Grid container spacing={1.5}>
            {/* First row: Date and Account */}
            <Grid item xs={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography variant="caption" fontWeight="bold">
                  Fecha
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                  <DatePicker
                    value={selectedDate}
                    onChange={handleFechaChange}
                    format="dd/MM/yyyy"
                    slotProps={{
                      textField: {
                        size: "small",
                        fullWidth: true,
                        variant: "outlined",
                        InputProps: {
                          startAdornment: (
                            <InputAdornment position="start">
                              <CalendarTodayIcon fontSize="small" />
                            </InputAdornment>
                          ),
                        }
                      },
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: 1.5,
                      }
                    }}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>

            <Grid item xs={6}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography variant="caption" fontWeight="bold">
                  Caja Origen
                </Typography>
                <FormControl fullWidth size="small">
                  <Select
                    value={formData.caja_origen_cobro}
                    onChange={handleCajaChange}
                    displayEmpty
                    variant="outlined"
                    sx={{
                      borderRadius: 1.5,
                      height: 40
                    }}
                  >
                    {cajas.map((caja) => (
                      <MenuItem key={caja.caja} value={caja.caja}>
                        {caja.caja}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            {/* Second row: Worker */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography variant="caption" fontWeight="bold">
                  Nombre del Trabajador
                </Typography>
                <FormControl fullWidth size="small">
                  <Select
                    value={formData.trabajador_cobro}
                    onChange={handleTrabajadorChange}
                    displayEmpty
                    variant="outlined"
                    sx={{
                      borderRadius: 1.5,
                      height: 40
                    }}
                  >
                    <MenuItem value="" disabled>
                      <em>Seleccionar trabajador</em>
                    </MenuItem>
                    {trabajadores.map((trabajador) => (
                      <MenuItem key={trabajador.nombre_del_trabajador} value={trabajador.nombre_del_trabajador}>
                        {trabajador.nombre_del_trabajador}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </Grid>

            {/* Facturador row */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography variant="caption" fontWeight="bold">
                  Facturador
                </Typography>
                <TextField
                  value={formData.nombre_facturador_cobro}
                  onChange={handleFacturadorChange}
                  fullWidth
                  size="small"
                  placeholder="Nombre de quien factura (por defecto el trabajador)"
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 1.5
                    }
                  }}
                />
              </Box>
            </Grid>

            {/* Third row: Description */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                <Typography variant="caption" fontWeight="bold">
                  Descripción
                </Typography>
                <TextField
                  value={formData.descripción_cobro}
                  onChange={handleDescripcionChange}
                  fullWidth
                  size="small"
                  placeholder="Ingrese una descripción (opcional)"
                  variant="outlined"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 1.5
                    }
                  }}
                />
              </Box>
            </Grid>

            {/* Fourth row: Currency selector and Exchange rate */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 0.5 }}>
                <CurrencySelector>
                  <CurrencyButton
                    isActive={formData.moneda_cobro === 'ARS'}
                    onClick={() => handleMonedaChange('ARS')}
                  >
                    ARS
                  </CurrencyButton>
                  <CurrencyButton
                    isActive={formData.moneda_cobro === 'USD'}
                    onClick={() => handleMonedaChange('USD')}
                  >
                    USD
                  </CurrencyButton>
                </CurrencySelector>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <Typography variant="body2" color="text.secondary">
                    Tipo de Cambio:
                  </Typography>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                      onClick={() => adjustTipoCambio(-5)}
                      variant="outlined"
                      size="small"
                      sx={{
                        minWidth: 30,
                        borderRadius: '20px 0 0 20px',
                        height: 30,
                        borderColor: '#d32f2f',
                        color: '#d32f2f'
                      }}
                    >
                      -5
                    </Button>
                    <TextField
                      value={formData.tipo_de_cambio_cobro}
                      onChange={(e) => {
                        const newValue = e.target.value.replace(/[^\d.]/g, '');
                        const newCambio = parseFloat(newValue) || 0;
                        setFormData({
                          ...formData,
                          tipo_de_cambio_cobro: newValue,
                          ...actualizarMontos(formData.monto_cobro, formData.moneda_cobro, newCambio)
                        });
                      }}
                      size="small"
                      variant="outlined"
                      sx={{
                        width: 80,
                        '& .MuiOutlinedInput-root': {
                          borderRadius: 0,
                          height: 30
                        }
                      }}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                      }}
                    />
                    <Button
                      onClick={() => adjustTipoCambio(5)}
                      variant="outlined"
                      size="small"
                      sx={{
                        minWidth: 30,
                        borderRadius: '0 20px 20px 0',
                        height: 30,
                        borderColor: '#2e7d32',
                        color: '#2e7d32'
                      }}
                    >
                      +5
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>

            {/* Fifth row: Amount */}
            <Grid item xs={12}>
              <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5, mt: 1 }}>
                <Typography variant="caption" fontWeight="bold">
                  Monto
                </Typography>
                <TextField
                  type="text"
                  value={formData.monto_cobro}
                  onChange={handleMontoChange}
                  fullWidth
                  size="small"
                  placeholder="Ingrese el monto"
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: 1.5
                    }
                  }}
                  autoFocus
                />
              </Box>
            </Grid>

            {/* Sixth row: Amount summary */}
            <Grid item xs={12}>
              <Box sx={{
                bgcolor: '#f8fdf9',
                borderRadius: 1.5,
                border: '1px solid #c8e6c9',
                display: 'flex',
                alignItems: 'center',
                px: 2,
                py: 1.5,
                mt: 0.5
              }}>
                <Grid container>
                  <Grid item xs={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="caption" color="#2e7d32" fontWeight="bold">
                        Monto ARS:
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        ${formData.monto_en_ars_cobro ? parseFloat(formData.monto_en_ars_cobro).toFixed(2) : '0.00'}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                      <Typography variant="caption" color="#2e7d32" fontWeight="bold">
                        Monto USD:
                      </Typography>
                      <Typography variant="body1" fontWeight="medium">
                        ${formData.monto_en_usd_cobro ? parseFloat(formData.monto_en_usd_cobro).toFixed(2) : '0.00'}
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            {/* Seventh row: Comprobante upload section */}
            <Grid item xs={12}>
              <Card variant="outlined" sx={{ mt: 2, borderRadius: 2, borderColor: '#e0e0e0' }}>
                <CardContent sx={{ p: 2 }}>
                  <Typography variant="subtitle1" fontWeight="bold" color="#2e7d32" gutterBottom>
                    Comprobante
                  </Typography>
                  <input
                    ref={comprobanteFileRef}
                    type="file"
                    accept="image/*"
                    onChange={handleComprobanteSelect}
                    style={{ display: 'none' }}
                  />

                  {!comprobantePreviewUrl ? (
                    <FileUploadBox onClick={() => comprobanteFileRef.current.click()}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <ReceiptIcon sx={{ fontSize: 40, color: '#2e7d32', mb: 1 }} />
                        <Typography variant="body1" gutterBottom>
                          Haz clic para subir un comprobante
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          JPG, PNG o PDF (máx. 5MB)
                        </Typography>
                      </Box>
                    </FileUploadBox>
                  ) : (
                    <Box sx={{ position: 'relative' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: 1,
                          mt: 1
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            borderRadius: 2,
                            overflow: 'hidden',
                            border: '1px solid #e0e0e0',
                            height: 150
                          }}
                        >
                          <Box
                            component="img"
                            src={comprobantePreviewUrl}
                            alt="Vista previa del comprobante"
                            sx={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain'
                            }}
                          />
                          <IconButton
                            size="small"
                            sx={{
                              position: 'absolute',
                              top: 8,
                              right: 8,
                              backgroundColor: 'rgba(255, 255, 255, 0.8)',
                              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                            }}
                            onClick={removeComprobante}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Box>

                        {formData.codigo_de_comprobante_cobro && (
                          <Typography variant="caption" sx={{ fontFamily: 'monospace', bgcolor: '#f1f8e9', p: 0.5, borderRadius: 1 }}>
                            {formData.codigo_de_comprobante_cobro}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>

            {/* Eighth row: Factura upload and nombre de factura */}
            <Grid item xs={12}>
              <Card variant="outlined" sx={{ mt: 2, borderRadius: 2, borderColor: '#e0e0e0' }}>
                <CardContent sx={{ p: 2 }}>
                  <Typography variant="subtitle1" fontWeight="bold" color="#2e7d32" gutterBottom>
                    Factura
                  </Typography>

                  <Grid container spacing={2} sx={{ mb: 2 }}>
                    <Grid item xs={12}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
                        <Typography variant="caption" fontWeight="bold">
                          Factura a nombre de
                        </Typography>
                        <FormControl fullWidth size="small">
                          <Select
                            value={formData.factura_a_nombre_de_cobro}
                            onChange={handleNombreFacturaChange}
                            displayEmpty
                            variant="outlined"
                            sx={{
                              borderRadius: 1.5,
                              height: 40
                            }}
                          >
                            <MenuItem value="">
                              <em>Sin especificar</em>
                            </MenuItem>
                            {nombreFacturas.map((nombre) => (
                              <MenuItem key={nombre} value={nombre}>
                                {nombre}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>

                  <input
                    ref={facturaFileRef}
                    type="file"
                    accept="image/*"
                    onChange={handleFacturaSelect}
                    style={{ display: 'none' }}
                  />

                  {!facturaPreviewUrl ? (
                    <FileUploadBox onClick={() => facturaFileRef.current.click()}>
                      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <DescriptionIcon sx={{ fontSize: 40, color: '#2e7d32', mb: 1 }} />
                        <Typography variant="body1" gutterBottom>
                          Haz clic para subir una factura
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          JPG, PNG o PDF (máx. 5MB)
                        </Typography>
                      </Box>
                    </FileUploadBox>
                  ) : (
                    <Box sx={{ position: 'relative' }}>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          gap: 1,
                          mt: 1
                        }}
                      >
                        <Box
                          sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            position: 'relative',
                            borderRadius: 2,
                            overflow: 'hidden',
                            border: '1px solid #e0e0e0',
                            height: 150
                          }}
                        >
                          <Box
                            component="img"
                            src={facturaPreviewUrl}
                            alt="Vista previa de la factura"
                            sx={{
                              maxWidth: '100%',
                              maxHeight: '100%',
                              objectFit: 'contain'
                            }}
                          />
                          <IconButton
                            size="small"
                            sx={{
                              position: 'absolute',
                              top: 8,
                              right: 8,
                              backgroundColor: 'rgba(255, 255, 255, 0.8)',
                              '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                            }}
                            onClick={removeFactura}
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        </Box>

                        {formData.codigo_de_factura_cobro && (
                          <Typography variant="caption" sx={{ fontFamily: 'monospace', bgcolor: '#f1f8e9', p: 0.5, borderRadius: 1 }}>
                            {formData.codigo_de_factura_cobro}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', p: 1.5 }}>
        <Button
          variant="contained"
          color="success"
          onClick={isEdit ? handleEditCobro : handleAddCobro}
          disabled={uploading}
          sx={{
            borderRadius: 8,
            backgroundColor: '#2e7d32',
            '&:hover': { backgroundColor: '#1b5e20' },
            px: 4,
            py: 0.75,
            fontSize: '0.9rem',
            fontWeight: 'bold'
          }}
        >
          {uploading ? <CircularProgress size={24} color="inherit" /> : 'GUARDAR'}
        </Button>
      </DialogActions>
    </>
  );

  return (
    <Container maxWidth="xl" sx={{ py: 3 }}>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="h4" fontWeight="bold" color="#2e7d32">
              Cobro Trabajadores
            </Typography>
            <Tooltip title="Agregar nuevo cobro">
              <AddButton
                onClick={() => setOpenAddDialog(true)}
                sx={{ ml: 2 }}
              >
                <AddIcon />
              </AddButton>
            </Tooltip>
          </Box>
          <Tooltip title="Exportar a CSV">
            <ExportButton
              variant="contained"
              startIcon={<DownloadIcon />}
              onClick={exportToCSV}
            >
              Exportar
            </ExportButton>
          </Tooltip>
        </Box>

        {/* Search bar and filters */}
        <StyledPaper elevation={0} sx={{ mb: 3, p: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', gap: 2 }}>
            <TextField
              placeholder="Buscar cobros..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              sx={{
                flex: 1,
                minWidth: 250,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 8,
                  '&:hover fieldset': {
                    borderColor: '#2e7d32',
                  },
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon sx={{ color: '#2e7d32' }} />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="outlined"
              color="primary"
              startIcon={<FilterListIcon />}
              onClick={() => setOpenFilters(!openFilters)}
              sx={{
                borderRadius: 8,
                borderColor: '#2e7d32',
                color: '#2e7d32',
                '&:hover': {
                  borderColor: '#1b5e20',
                  backgroundColor: 'rgba(46, 125, 50, 0.04)',
                },
              }}
            >
              Filtros
            </Button>
            {activeFilters.length > 0 && (
              <Button
                variant="text"
                size="small"
                onClick={removeAllFilters}
                sx={{
                  color: '#2e7d32',
                  '&:hover': { backgroundColor: 'rgba(46, 125, 50, 0.04)' },
                }}
              >
                Limpiar filtros
              </Button>
            )}
          </Box>

          <Collapse in={openFilters}>
            <Box sx={{ mt: 2 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Trabajador</InputLabel>
                    <Select
                      value={filters.trabajador}
                      onChange={(e) => setFilters({ ...filters, trabajador: e.target.value })}
                      label="Trabajador"
                    >
                      <MenuItem value="">Todos</MenuItem>
                      {trabajadores.map((trabajador) => (
                        <MenuItem key={trabajador.nombre_del_trabajador} value={trabajador.nombre_del_trabajador}>
                          {trabajador.nombre_del_trabajador}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Caja</InputLabel>
                    <Select
                      value={filters.caja}
                      onChange={(e) => setFilters({ ...filters, caja: e.target.value })}
                      label="Caja"
                    >
                      <MenuItem value="">Todas</MenuItem>
                      {cajas.map((caja) => (
                        <MenuItem key={caja.caja} value={caja.caja}>{caja.caja}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Moneda</InputLabel>
                    <Select
                      value={filters.moneda}
                      onChange={(e) => setFilters({ ...filters, moneda: e.target.value })}
                      label="Moneda"
                    >
                      <MenuItem value="">Todas</MenuItem>
                      <MenuItem value="ARS">ARS</MenuItem>
                      <MenuItem value="USD">USD</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={addFilter}
                    sx={{
                      height: '40px',
                      borderRadius: 8,
                      backgroundColor: '#2e7d32',
                      '&:hover': { backgroundColor: '#1b5e20' },
                    }}
                  >
                    Aplicar Filtros
                  </Button>
                </Grid>
              </Grid>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                    <DatePicker
                      label="Desde"
                      value={filters.fecha_desde ? new Date(filters.fecha_desde) : null}
                      onChange={(date) => setFilters({ ...filters, fecha_desde: date ? format(date, 'yyyy-MM-dd') : null })}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                          InputProps: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <CalendarTodayIcon fontSize="small" />
                              </InputAdornment>
                            ),
                          }
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={esLocale}>
                    <DatePicker
                      label="Hasta"
                      value={filters.fecha_hasta ? new Date(filters.fecha_hasta) : null}
                      onChange={(date) => setFilters({ ...filters, fecha_hasta: date ? format(date, 'yyyy-MM-dd') : null })}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                          InputProps: {
                            startAdornment: (
                              <InputAdornment position="start">
                                <CalendarTodayIcon fontSize="small" />
                              </InputAdornment>
                            ),
                          }
                        }
                      }}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Box>
          </Collapse>

          {/* Active filter chips */}
          {activeFilters.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', pt: 2 }}>
              {activeFilters.map((filter, index) => (
                <FilterChip
                  key={index}
                  label={filter.label}
                  onDelete={() => removeFilter(index)}
                  sx={{ mr: 1, mb: 1 }}
                />
              ))}
            </Box>
          )}
        </StyledPaper>

        {/* Cobros table */}
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: 0.2 }}
          >
            <StyledPaper elevation={0}>
              <TableContainer sx={{ maxHeight: 'calc(100vh - 250px)' }}>
                <Table stickyHeader aria-label="tabla de cobros">
                  <TableHead>
                    <TableRow>
                      <StyledTableHeaderCell>Fecha</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Trabajador</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Facturador</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Caja</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Descripción</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Tipo Cambio</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Monto</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Equivalente</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Comprobante</StyledTableHeaderCell>
                      <StyledTableHeaderCell>Factura</StyledTableHeaderCell>
                      <StyledTableHeaderCell align="center">Acciones</StyledTableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredCobros
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((cobro) => (
                        <StyledTableRow key={cobro.id}>
                          <StyledTableCell>
                            {cobro.fecha_formateada}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                              <PersonIcon fontSize="small" sx={{ color: '#2e7d32' }} />
                              <Typography>{cobro.trabajador_cobro}</Typography>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            {cobro.nombre_facturador_cobro || cobro.trabajador_cobro}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                              <AccountBalanceWalletIcon fontSize="small" sx={{ color: '#1976d2' }} />
                              <Typography>{cobro.caja_origen_cobro}</Typography>
                            </Box>
                          </StyledTableCell>
                          <StyledTableCell>
                            {cobro.descripción_cobro || '-'}
                          </StyledTableCell>
                          <StyledTableCell>
                            <Typography sx={{ fontWeight: 'bold', color: '#2e7d32' }}>
                              ${parseFloat(cobro.tipo_de_cambio_cobro).toFixed(2)}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            <MontoChip
                              label={`${cobro.moneda_cobro} ${parseFloat(cobro.monto_cobro).toFixed(2)}`}
                              isPositive={parseFloat(cobro.monto_cobro) >= 0}
                              icon={<AttachMoneyIcon />}
                              size="small"
                            />
                          </StyledTableCell>
                          <StyledTableCell>
                            <Typography variant="body2" color="text.secondary">
                              {cobro.moneda_cobro === 'ARS' ? 'USD' : 'ARS'} {' '}
                              {cobro.moneda_cobro === 'ARS'
                                ? cobro.monto_en_usd_cobro.toFixed(2)
                                : cobro.monto_en_ars_cobro.toFixed(2)}
                            </Typography>
                          </StyledTableCell>
                          <StyledTableCell>
                            {cobro.tiene_comprobante_cobro && cobro.imagen_comprobante_formateada ? (
                              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                                <ImagePreview
                                  src={cobro.imagen_comprobante_formateada}
                                  alt="Comprobante"
                                  thumbSize="small"
                                  onClick={() => handleShowImage(cobro.imagen_comprobante_formateada, "Comprobante")}
                                />
                                {cobro.codigo_de_comprobante_cobro && (
                                  <Tooltip title="Copiar código">
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        cursor: 'pointer',
                                        fontFamily: 'monospace',
                                        fontSize: '0.6rem',
                                        bgcolor: '#e8f5e9',
                                        p: 0.5,
                                        borderRadius: 1,
                                        maxWidth: 60,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                      }}
                                      onClick={() => {
                                        navigator.clipboard.writeText(cobro.codigo_de_comprobante_cobro);
                                        setSnackbar({
                                          open: true,
                                          message: 'Código copiado al portapapeles',
                                          severity: 'success'
                                        });
                                      }}
                                    >
                                      {cobro.codigo_de_comprobante_cobro}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Box>
                            ) : (
                              <Typography variant="body2" color="text.secondary">
                                No
                              </Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell>
                            {cobro.tiene_factura_cobro && cobro.imagen_factura_formateada ? (
                              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 0.5 }}>
                                <ImagePreview
                                  src={cobro.imagen_factura_formateada}
                                  alt="Factura"
                                  thumbSize="small"
                                  onClick={() => handleShowImage(cobro.imagen_factura_formateada, "Factura")}
                                />
                                {cobro.codigo_de_factura_cobro && (
                                  <Tooltip title="Copiar código">
                                    <Typography
                                      variant="caption"
                                      sx={{
                                        cursor: 'pointer',
                                        fontFamily: 'monospace',
                                        fontSize: '0.6rem',
                                        bgcolor: '#e8f5e9',
                                        p: 0.5,
                                        borderRadius: 1,
                                        maxWidth: 60,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap'
                                      }}
                                      onClick={() => {
                                        navigator.clipboard.writeText(cobro.codigo_de_factura_cobro);
                                        setSnackbar({
                                          open: true,
                                          message: 'Código copiado al portapapeles',
                                          severity: 'success'
                                        });
                                      }}
                                    >
                                      {cobro.codigo_de_factura_cobro}
                                    </Typography>
                                  </Tooltip>
                                )}
                              </Box>
                            ) : (
                              <Typography variant="body2" color="text.secondary">
                                No
                              </Typography>
                            )}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                              <Tooltip title="Editar">
                                <ActionButton
                                  size="small"
                                  onClick={() => handleEditClick(cobro)}
                                >
                                  <EditIcon fontSize="small" />
                                </ActionButton>
                              </Tooltip>
                              <Tooltip title="Eliminar">
                                <ActionButton
                                  size="small"
                                  onClick={() => handleDeleteCobro(cobro.id)} sx={{ color: '#d32f2f' }}
                                >
                                  <DeleteIcon fontSize="small" />
                                </ActionButton>
                              </Tooltip>
                            </Box>
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={filteredCobros.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Filas por página:"
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
                sx={{
                  borderTop: '1px solid rgba(224, 224, 224, 1)',
                  '.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows': {
                    color: '#424242'
                  },
                  '.MuiTablePagination-select': {
                    color: '#2e7d32'
                  }
                }}
              />
            </StyledPaper>
          </motion.div>
        </AnimatePresence>
      </motion.div>

      {/* Dialogs for adding and editing cobros */}
      <AddCobroDialog open={openAddDialog} onClose={() => setOpenAddDialog(false)}>
        <CobroDialogContent isEdit={false} />
      </AddCobroDialog>

      <AddCobroDialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <CobroDialogContent isEdit={true} />
      </AddCobroDialog>

      {/* Image preview dialog */}
      <ImagePreviewDialog
        open={openImagePreview}
        onClose={() => setOpenImagePreview(false)}
        maxWidth="md"
        onClick={() => setOpenImagePreview(false)}
      >
        <DialogContent sx={{ p: 0, overflow: 'hidden', position: 'relative' }}>
          {currentImage && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                height: '100%',
                background: 'transparent',
              }}
            >
              <Box
                component="img"
                src={currentImage.url}
                alt={currentImage.title || "Imagen"}
                sx={{
                  maxWidth: '100%',
                  maxHeight: '75vh',
                  objectFit: 'contain',
                  borderRadius: 1,
                  boxShadow: '0 8px 32px rgba(0, 0, 0, 0.15)',
                }}
                onError={(e) => {
                  console.error('Error cargando imagen en diálogo:', e);
                  e.target.src = 'https://via.placeholder.com/400x300?text=Imagen+no+disponible';
                }}
              />

              <IconButton
                size="small"
                sx={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                  backgroundColor: 'rgba(255, 255, 255, 0.8)',
                  '&:hover': { backgroundColor: 'rgba(255, 255, 255, 0.9)' },
                }}
                onClick={() => setOpenImagePreview(false)}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          )}
        </DialogContent>
      </ImagePreviewDialog>

      {/* Snackbar for notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar({ ...snackbar, open: false })}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar({ ...snackbar, open: false })}
          severity={snackbar.severity}
          sx={{
            width: '100%',
            borderRadius: 2,
            boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
          }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default CobroTrabajadores;
