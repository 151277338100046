// src/components/Etapas.js
import React, { useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TextField,
  Typography,
  IconButton,
  Container,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Menu,
  MenuItem as MenuItemMUI,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/material/styles';
import { motion } from 'framer-motion';

const EtapaDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiBackdrop-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
  },
  '& .MuiPaper-root': {
    borderRadius: 4,
    padding: theme.spacing(2),
    width: '400px',
    maxHeight: '80vh',
    overflowY: 'auto',
    backgroundColor: '#ffffff',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
  },
}));

const EtapaCard = styled(Box)(({ theme }) => ({
  backgroundColor: '#f9f9f9',
  borderRadius: 4,
  padding: theme.spacing(1.5),
  marginBottom: theme.spacing(1.5),
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
}));

const SubetapaCard = styled(Box)(({ theme }) => ({
  backgroundColor: '#fafafa',
  borderRadius: 4,
  padding: theme.spacing(1),
  paddingLeft: theme.spacing(2.5),
  marginBottom: theme.spacing(1),
  '&:hover': {
    backgroundColor: '#f0f0f0',
  },
}));

const Etapas = () => {
  const [etapas, setEtapas] = useState([]);
  const [openAddEtapa, setOpenAddEtapa] = useState(false);
  const [openAddSubetapa, setOpenAddSubetapa] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [newEtapa, setNewEtapa] = useState('');
  const [newSubetapa, setNewSubetapa] = useState('');
  const [editValue, setEditValue] = useState('');
  const [editId, setEditId] = useState(null);
  const [selectedEtapaId, setSelectedEtapaId] = useState('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedItemId, setSelectedItemId] = useState(null);

  useEffect(() => {
    fetchEtapas();
  }, []);

  const fetchEtapas = async () => {
    const { data, error } = await supabase.from('etapas_de_obra').select('*');
    if (error) console.log('Error fetching etapas_de_obra:', error);
    else setEtapas(data || []);
  };

  const handleAddEtapa = async () => {
    if (!newEtapa.trim()) {
      alert('Por favor, ingresa un nombre válido para la etapa.');
      return;
    }
    const { error } = await supabase
      .from('etapas_de_obra')
      .insert([{ etapa_de_obra: newEtapa.trim(), subetapa_de_obra: null }]);
    if (error) {
      console.log('Error adding etapa:', error);
      alert('Error al agregar la etapa: ' + error.message);
    } else {
      fetchEtapas();
      setOpenAddEtapa(false);
      setNewEtapa('');
    }
  };

  const handleAddSubetapa = async () => {
    if (!newSubetapa.trim() || !selectedEtapaId) {
      alert('Por favor, ingresa un nombre válido para la subetapa y selecciona una etapa padre.');
      return;
    }
    const etapaPadre = etapas.find(e => e.id === Number(selectedEtapaId));
    if (!etapaPadre) {
      alert('Etapa padre no encontrada.');
      return;
    }
    const { error } = await supabase
      .from('etapas_de_obra')
      .insert([{ etapa_de_obra: etapaPadre.etapa_de_obra, subetapa_de_obra: newSubetapa.trim() }]);
    if (error) {
      console.log('Error adding subetapa:', error);
      alert('Error al agregar la subetapa: ' + error.message);
    } else {
      fetchEtapas();
      setOpenAddSubetapa(false);
      setNewSubetapa('');
      setSelectedEtapaId('');
    }
  };

  const handleEdit = async () => {
    if (!editValue.trim()) {
      alert('Por favor, ingresa un nombre válido.');
      return;
    }
    const isSubetapa = etapas.find(e => e.id === editId)?.subetapa_de_obra !== null;
    const updateData = isSubetapa
      ? { subetapa_de_obra: editValue.trim() }
      : { etapa_de_obra: editValue.trim() };

    const { error } = await supabase
      .from('etapas_de_obra')
      .update(updateData)
      .eq('id', editId);
    if (error) {
      console.log('Error updating etapa/subetapa:', error);
      alert('Error al actualizar: ' + error.message);
    } else {
      fetchEtapas();
      setOpenEditDialog(false);
      setEditValue('');
      setEditId(null);
    }
  };

  const handleDelete = async (id) => {
    const { error } = await supabase.from('etapas_de_obra').delete().eq('id', id);
    if (error) {
      console.log('Error deleting etapa/subetapa:', error);
      alert('Error al eliminar: ' + error.message);
    } else {
      fetchEtapas();
      setAnchorEl(null);
    }
  };

  const etapasPrincipales = etapas.filter(e => !e.subetapa_de_obra || e.subetapa_de_obra.trim() === '');
  const getSubetapas = (etapaNombre) => etapas.filter(e => e.etapa_de_obra === etapaNombre && e.subetapa_de_obra && e.subetapa_de_obra.trim() !== '');

  const handleMenuOpen = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedItemId(id);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setSelectedItemId(null);
  };

  const handleEditClick = (item) => {
    setEditId(item.id);
    setEditValue(item.subetapa_de_obra || item.etapa_de_obra);
    setOpenEditDialog(true);
    handleMenuClose();
  };

  return (
    <Container maxWidth="lg" sx={{ py: 3, px: 0, backgroundColor: '#ffffff' }}>
      <Typography variant="h4" fontWeight="bold" color="text.primary" gutterBottom>
        Etapas
      </Typography>
      <Box sx={{ mb: 2 }}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => setOpenAddEtapa(true)}
          sx={{ mr: 1, borderRadius: 4, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#2e7d32' }, fontSize: '0.9rem', padding: '4px 12px' }}
        >
          Nueva Etapa
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setOpenAddSubetapa(true)}
          sx={{ borderRadius: 4, borderColor: '#4caf50', color: '#4caf50', '&:hover': { borderColor: '#2e7d32', color: '#2e7d32' }, fontSize: '0.9rem', padding: '4px 12px' }}
        >
          Nueva Subetapa
        </Button>
      </Box>

      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {etapasPrincipales.map((etapa) => (
          <Box key={etapa.id} sx={{ minWidth: '250px', flex: '1 1 30%', maxWidth: '33%', p: 1 }}>
            <EtapaCard>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="body2" fontWeight="medium" color="text.primary" sx={{ fontSize: '0.95rem' }}>
                  {etapa.etapa_de_obra}
                </Typography>
                <IconButton size="small" onClick={(e) => handleMenuOpen(e, etapa.id)}>
                  <MoreVertIcon fontSize="small" />
                </IconButton>
              </Box>
            </EtapaCard>
            {getSubetapas(etapa.etapa_de_obra).map((subetapa) => (
              <SubetapaCard key={subetapa.id}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Typography variant="body2" color="text.secondary" sx={{ fontSize: '0.9rem' }}>
                    {subetapa.subetapa_de_obra}
                  </Typography>
                  <IconButton size="small" onClick={(e) => handleMenuOpen(e, subetapa.id)}>
                    <MoreVertIcon fontSize="small" />
                  </IconButton>
                </Box>
              </SubetapaCard>
            ))}
          </Box>
        ))}
      </Box>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
        PaperProps={{
          sx: { borderRadius: 4, boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)' },
        }}
      >
        <MenuItemMUI onClick={() => handleEditClick(etapas.find(e => e.id === selectedItemId))} sx={{ fontSize: '0.9rem' }}>
          Editar
        </MenuItemMUI>
        <MenuItemMUI onClick={() => { handleDelete(selectedItemId); handleMenuClose(); }} sx={{ fontSize: '0.9rem', color: 'error.main' }}>
          Eliminar
        </MenuItemMUI>
      </Menu>

      {/* Diálogo Agregar Etapa */}
      <EtapaDialog open={openAddEtapa} onClose={() => setOpenAddEtapa(false)}>
        <DialogTitle>
          <Typography variant="h5" fontWeight="bold" color="text.primary" textAlign="center">
            Nueva Etapa
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            label="Nombre de la Etapa"
            value={newEtapa}
            onChange={(e) => setNewEtapa(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button onClick={() => setOpenAddEtapa(false)} color="inherit" sx={{ fontSize: '0.9rem', borderRadius: 4 }}>
              Cancelar
            </Button>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddEtapa}
              sx={{ borderRadius: 4, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#2e7d32' }, fontSize: '0.9rem', padding: '4px 12px' }}
            >
              Guardar
            </Button>
          </motion.div>
        </DialogActions>
      </EtapaDialog>

      {/* Diálogo Agregar Subetapa */}
      <EtapaDialog open={openAddSubetapa} onClose={() => setOpenAddSubetapa(false)}>
        <DialogTitle>
          <Typography variant="h5" fontWeight="bold" color="text.primary" textAlign="center">
            Nueva Subetapa
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mt: 2 }}>
            <InputLabel>Etapa Padre</InputLabel>
            <Select
              value={selectedEtapaId}
              onChange={(e) => setSelectedEtapaId(e.target.value)}
              variant="outlined"
            >
              <MenuItem value=""><em>Selecciona una etapa</em></MenuItem>
              {etapasPrincipales.map((etapa) => (
                <MenuItem key={etapa.id} value={etapa.id} sx={{ fontSize: '0.9rem' }}>
                  {etapa.etapa_de_obra}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            label="Nombre de la Subetapa"
            value={newSubetapa}
            onChange={(e) => setNewSubetapa(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button onClick={() => setOpenAddSubetapa(false)} color="inherit" sx={{ fontSize: '0.9rem', borderRadius: 4 }}>
              Cancelar
            </Button>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddSubetapa}
              sx={{ borderRadius: 4, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#2e7d32' }, fontSize: '0.9rem', padding: '4px 12px' }}
            >
              Guardar
            </Button>
          </motion.div>
        </DialogActions>
      </EtapaDialog>

      {/* Diálogo Editar Etapa/Subetapa */}
      <EtapaDialog open={openEditDialog} onClose={() => setOpenEditDialog(false)}>
        <DialogTitle>
          <Typography variant="h5" fontWeight="bold" color="text.primary" textAlign="center">
            Editar {editValue ? (etapas.find(e => e.id === editId)?.subetapa_de_obra ? 'Subetapa' : 'Etapa') : ''}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <TextField
            label={etapas.find(e => e.id === editId)?.subetapa_de_obra ? 'Nombre de la Subetapa' : 'Nombre de la Etapa'}
            value={editValue}
            onChange={(e) => setEditValue(e.target.value)}
            fullWidth
            variant="outlined"
            sx={{ mt: 2 }}
          />
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button onClick={() => setOpenEditDialog(false)} color="inherit" sx={{ fontSize: '0.9rem', borderRadius: 4 }}>
              Cancelar
            </Button>
          </motion.div>
          <motion.div whileHover={{ scale: 1.05 }} transition={{ duration: 0.3 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleEdit}
              sx={{ borderRadius: 4, backgroundColor: '#4caf50', '&:hover': { backgroundColor: '#2e7d32' }, fontSize: '0.9rem', padding: '4px 12px' }}
            >
              Guardar
            </Button>
          </motion.div>
        </DialogActions>
      </EtapaDialog>
    </Container>
  );
};

export default Etapas;